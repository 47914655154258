<template lang="pug">
.c-ad-openhouse
  .c-ad-openhouse__active(v-if='selected.openHouses.length > 0')
    .c-ad-openhouse__top.o-grid.o-grid--align-items-start.o-grid--align-items-center.o-grid--gutter-tiny(:class='{ "o-grid--wrap-nowrap": !isMobile }')
      .o-grid__group
        .c-ad-openhouse__count.o-grid__item.o-grid__item--12.o-grid__item--desktop-4
          h4.o-heading.o-heading--size-4 {{ openHouseCountText }}
        .c-ad-openhouse__actions.o-grid__item.o-grid__item--align-right.o-grid__item--12.o-grid__item--desktop-5
          .o-grid.o-grid--align-items-center.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right(v-if='selected.status !== "RentedOut"')
                button.c-ad-openhouse__action.o-button.o-button--small(:disabled='advertIsLocked' @click='showOpenHouse()' role='button') {{ Dictionary.C.CreateOpenHouse }}
    .c-ad-openhouse__data(v-if='!isMobile')
      vue-good-table(:columns='openHousesColumns', :pagination-options='paginationSettings', :rows='openHouses' styleClass='o-table')
        template(slot='table-row' slot-scope='props')
          template(v-if='props.column.field == "date"')
            span {{ reFormatDate(props.row.startDate) }}
          template(v-if='props.column.field == "time"')
            span {{ reFormatTime(props.row.startDate, props.row.endDate) }}
          template(v-if='props.column.field == "actions" && selected.status !== "RentedOut"')
            .o-grid.o-grid--gutter-horizontal-small
              .o-grid__group
                .o-grid__item.o-grid__item--grow-0
                  button.o-button.o-button--small.o-bodytext.o-bodytext--size-small(@click='showOpenHouse(props.row)') {{ Dictionary.E.Edit }}
                .o-grid__item.o-grid__item--grow-0
                  button.o-button.o-button--small.o-bodytext.o-bodytext--size-small(@click='deleteOpenHouse(props.row.originalIndex)') {{ Dictionary.D.Delete }}
          template(v-else) {{ props.formattedRow[props.column.field] }}
      .c-search-result__loading(v-if='loading')
        c-loading-animation(:overlay='true')
    .c-ad-openhouse__data.c-ad-openhouse__data--mobile(v-else)
      .c-ad-openhouse__item.o-grid.o-grid--gutter-tiny(v-for='item in pageOfItems')
        .o-grid__group
          .o-grid__item.o-grid__item--12
            table.o-table.o-table--v-middle
              thead
                tr
                  td.c-ad-openhouse__column.c-ad-openhouse__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.D.Date }}
                  td.c-ad-openhouse__column.c-ad-openhouse__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.T.Time_et }}
              tbody
                tr
                  td.o-bodytext.o-bodytext--size-small {{ reFormatDate(item.startDate) }}
                  td.o-bodytext.o-bodytext--size-small {{ reFormatTime(item.startDate, item.endDate) }}
          .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right.c-ad-openhouse__applicant-action
            .o-grid.o-grid--gutter-tiny
              .o-grid__group
                .o-grid__item
                  button.c-ad-openhouse__action.o-button.o-button--small(@click='showOpenHouse(item)') {{ Dictionary.E.Edit }}
                .o-grid__item
                  button.c-ad-openhouse__action.o-button.o-button--small(@click='deleteOpenHouse(item.originalIndex)') {{ Dictionary.D.Delete }}
      c-pagination(:items='openHouses' @changePage='onChangePage')
  .c-ad-openhouse__empty(v-else)
    .o-heading.o-heading--size-4 {{ model.openHouseTranslations.openHouseNotFoundHeadline }}
    button.c-ad-openhouse__empty-button.o-button(:disabled='advertIsLocked' @click='showOpenHouse()' role='button') {{ Dictionary.C.CreateOpenHouse }}
  c-modal.c-modal--search(:cornerClose='true' @close='cancelEdit' v-if='showOpenHouseEdit')
    template(slot='header')
      .c-ad-openhouse__modal-header
        .o-heading.o-heading--size-4 {{ openHouseIndex > -1 ? Dictionary.E.EditOpenHouse : Dictionary.C.CreateOpenHouse }}
    template(slot='body')
      .c-ad-openhouse__modal-body
        .o-grid.o-grid--align-items-start.o-grid--align-items-center.o-grid--gutter-tiny
          .o-grid__group
            .o-grid__item
              c-fc-date(:model='dateData' @validate='validate($event, "date")' v-model='openHouseModel.date.value')
            .o-grid__item
              c-fc-time(:model='timeFromData' @validate='validate($event, "timeFrom")' v-model='openHouseModel.timeFrom.value')
            .o-grid__item
              c-fc-time(:model='timeToData' @validate='validate($event, "timeTo")' v-model='openHouseModel.timeTo.value')
    template(slot='footer')
      .c-ad-openhouse__modal-footer
        .o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center
          .o-grid__group
            .o-grid__item.o-grid__item--grow-0
              button.o-link.c-om-offer__cancel(@click='cancelEdit') {{ Dictionary.C.Cancel }}
            .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
              button.o-button.c-om-offer__submit(:disabled='!formIsValid || loading || !dataChanged' @click='handleUpdate') {{ Dictionary.S.Save }}
</template>
<script>
import { mapState } from 'vuex'

import { VueGoodTable } from 'vue-good-table'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllTime from '@/components/form/controlls/form-time'
import _ from 'lodash'
import moment from 'moment'
export default {
  name: 'c-ad-openhouse',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,

      pageOfItems: [],
      resetPaging: false,
      showOpenHouseEdit: false,
      mutableOpenHouse: null,
      validation: {},
      openHouseModel: {
        date: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.D.Date,
          isMandatory: true,
          readOnly: false,
          validation: {
            minDate: moment.utc().format('YYYY-MM-DD')
          }
        },
        timeFrom: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.F.From,
          isMandatory: true,
          readOnly: false,
          validation: {}
        },
        timeTo: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.T.To,
          isMandatory: true,
          readOnly: false,
          validation: {}
        }
      }
    }
  },
  components: {
    'c-fc-date': FormControllDate,
    'c-fc-time': FormControllTime,
    VueGoodTable
  },
  computed: {
    ...mapState('advertManagement', ['selected', 'confirmationTarget']),
    ...mapState('loadingIndicator', ['loading']),
    openHousesColumns() {
      const openHousesColumns = [
        {
          label: this.Dictionary.D.Date,
          field: 'date'
        },
        {
          label: this.Dictionary.T.Time_et,
          field: 'time'
        }
      ]
      if (this.selected && this.selected.status !== 'RentedOut') {
        openHousesColumns.push({
          label: '',
          field: 'actions'
        })
      }

      return openHousesColumns
    },
    advertIsLocked() {
      return ['RentedOut', 'Retired', 'RetiredFromRentedOut'].includes(this.selected.status)
    },
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true)
    },
    dataChanged() {
      return !_.isEqual(
        {
          date: this.openHouseModel.date.value,
          timeFrom: this.openHouseModel.timeFrom.value,
          timeTo: this.openHouseModel.timeTo.value
        },
        {
          date: this.mutableOpenHouse.date,
          timeFrom: this.mutableOpenHouse.timeFrom,
          timeTo: this.mutableOpenHouse.timeTo
        }
      )
    },
    openHouses() {
      return this.selected.openHouses
    },
    openHouseCountText() {
      return this.model.openHouseTranslations && this.model.openHouseTranslations.openHouseResultsCountText
        ? this.model.openHouseTranslations.openHouseResultsCountText.replace('{0}', this.openHouses.length)
        : this.openHouses.length
    },
    dateData() {
      const dateData = Object.assign({}, this.openHouseModel.date)
      return dateData
    },
    timeFromData() {
      const timeFromData = Object.assign({}, this.openHouseModel.timeFrom)

      if (this.openHouseModel.timeTo.value) {
        timeFromData.validation = {
          max: this.openHouseModel.timeTo.value
        }
      }
      return timeFromData
    },
    timeToData() {
      const timeToData = Object.assign({}, this.openHouseModel.timeTo)
      if (this.openHouseModel.timeFrom.value) {
        timeToData.validation = {
          min: this.openHouseModel.timeFrom.value
        }
      }
      return timeToData
    },
    openHouseIndex() {
      return this.mutableOpenHouse && this.mutableOpenHouse.date !== null ? this.getOpenHouseIndex() : -1
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    reFormatTime(start, end) {
      return `${moment.utc(start).format('HH:mm')}-${moment.utc(end).format('HH:mm')}`
      // return `${start}-${end}`
    },
    getOpenHouseIndex() {
      const startDate = moment.utc(`${this.mutableOpenHouse.date} ${this.mutableOpenHouse.timeFrom}`).toDate().toISOString(),
        endDate = moment.utc(`${this.mutableOpenHouse.date} ${this.mutableOpenHouse.timeTo}`).toDate().toISOString(),
        index = this.openHouses.findIndex(x => {
          const start = moment.utc(x.startDate).toDate().toISOString(),
            end = moment.utc(x.endDate).toDate().toISOString()
          return start === startDate && end === endDate
        })
      return index
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    showOpenHouse(openHouseData) {
      if (openHouseData) {
        this.openHouseModel.date.value = moment.utc(openHouseData.startDate).format('YYYY-MM-DD')
        this.openHouseModel.timeFrom.value = moment.utc(openHouseData.startDate).format('HH:mm')
        this.openHouseModel.timeTo.value = moment.utc(openHouseData.endDate).format('HH:mm')
      }
      this.showOpenHouseEdit = true

      this.mutableOpenHouse = JSON.parse(
        JSON.stringify({
          date: this.openHouseModel.date.value,
          timeFrom: this.openHouseModel.timeFrom.value,
          timeTo: this.openHouseModel.timeTo.value
        })
      )
    },
    cancelEdit() {
      this.openHouseModel.date.value = null
      this.openHouseModel.timeFrom.value = null
      this.openHouseModel.timeTo.value = null
      this.mutableOpenHouse = null
      this.showOpenHouseEdit = false
    },
    async handleUpdate() {
      const data = {
        startDate: moment.utc(`${this.openHouseModel.date.value} ${this.openHouseModel.timeFrom.value}`).toDate().toISOString(),
        endDate: moment.utc(`${this.openHouseModel.date.value} ${this.openHouseModel.timeTo.value}`).toDate().toISOString()
      }

      const newOpenHouses = JSON.parse(JSON.stringify(this.openHouses))

      if (this.openHouseIndex !== null && this.openHouseIndex > -1) {
        newOpenHouses[this.openHouseIndex] = data
      } else {
        newOpenHouses.push(data)
      }
      this.$store.dispatch('advertManagement/updateAdvertOpenHousesData', newOpenHouses)

      this.cancelEdit()
    },
    deleteOpenHouse(index) {
      const newOpenHouses = this.openHouses.filter((oh, i) => {
        const valid = oh && i !== index
        return valid
      })
      this.$store.dispatch('advertManagement/updateAdvertOpenHousesData', newOpenHouses)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-ad-openhouse {
  $root: &;
  &__top {
    margin: toRem(20px 0);
  }
  &__empty {
    margin: toRem(50px 0);
    text-align: center;
    &-button {
      margin-top: 20px;
      &:first-child:last-child {
        margin-top: 0;
      }
    }
  }
  &__item {
    padding: 15px;
    border: 1px solid $grey-4;

    & + & {
      margin-top: 10px;
    }
  }
  &__column {
    &--info {
      width: 35%;
    }
  }
  &__modal {
    &-header,
    &-body {
      margin-bottom: 20px;
    }
  }
}
</style>
