<template lang="pug">
.c-um-user
  .c-um-user__top
    button.c-um-user__close(@click='close')
      svg.c-um-user__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-um-user__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-um-user__content.o-transition__content
    h2.c-um-user__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.firstName || selected.lastName ? `${selected.firstName} ${selected.lastName}` : model.userTranslations.newUserHeadline }}
    .c-um-user__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-um-user__form-section
            .c-um-user__form-heading.o-heading.o-heading--size-4 {{ model.userTranslations.userDataHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  c-fc-email(:current='selected.email', :model='mutableData.email' @validate='validate($event, "email")' v-model='mutableData.email.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.firstName' @validate='validate($event, "firstName")' v-model='mutableData.firstName.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.lastName' @validate='validate($event, "lastName")' v-model='mutableData.lastName.value')
                .o-grid__item.o-grid__item--12(v-if='!selected.id')
                  c-fc-password(:model='mutableData.password' @validate='validate($event, "password")' v-model='mutableData.password.value')
          .c-um-user__form-section
            .c-um-user__form-heading.o-heading.o-heading--size-4 {{ model.userTranslations.selectAdministratorsHeadline }}
            c-fc-select.c-um-user__form-list(
              :disabled='!userDataIsValid',
              :model='selectedAdministratorData'
              @validate='validate($event, "selectedAdministrator")'
              v-model='mutableData.selectedAdministrator.value'
            ) 
    .c-um-user__form.c-um-user__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
      .o-grid__group
        .o-grid__item.o-grid__item--12.c-um-user__form-section--border-top(v-if='selected.id')
          button.c-um-user__delete.o-link.o-link--error(@click='handleLock') {{ selected.locked ? model.unlockUserText : model.lockUserText }}
        .o-grid__item.o-grid__item--12.c-um-user__form-section--border-top
          .o-grid.o-grid--gutter-small.o-grid--align-items-center
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-link.c-um-user__cancel(@click='close') {{ Dictionary.C.Cancel }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                button.o-button.c-um-user__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllPassword from '@/components/form/controlls/form-password'
import FormControllEmail from '@/components/form/controlls/form-email'
import FormControllSelect from '@/components/form/controlls/form-select'

export default {
  name: 'c-um-user',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-password': FormControllPassword,
    'c-fc-email': FormControllEmail,
    'c-fc-select': FormControllSelect
  },
  data() {
    return {
      id: this._uid,

      initialSelectedAdministrator: null,
      validation: {},
      mutableData: {
        email: {
          helperText: this.model.userTranslations.emailHelperText,
          placeholderText: this.Dictionary.E.Email,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: this.model.userTranslations.userEmailExistsErrorMessage,
            displayRule: '',
            unique: true
          }
        },
        firstName: {
          helperText: this.model.userTranslations.firstNameHelperText,
          placeholderText: this.Dictionary.F.FirstName,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        lastName: {
          helperText: this.model.userTranslations.lastNameHelperText,
          placeholderText: this.Dictionary.L.LastName,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        password: {
          helperText: this.model.userTranslations.passwordHelperText,
          placeholderText: this.Dictionary.P.Password,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: ''
          }
        },
        selectedAdministrator: {
          helperText: this.model.userTranslations.selectAdministratorshelperText,
          value: null,
          placeholderText: this.Dictionary.A.AdministratorRole,
          isMandatory: true,
          readOnly: false,
          options: [],
          validation: null
        }
      }
    }
  },
  computed: {
    ...mapState('usersManagement', ['selected', 'administrators']),
    ...mapState('loadingIndicator', ['loading']),
    // ...mapGetters('usersManagement', ['submitmodels']),
    formIsValid() {
      return this.userDataIsValid
    },
    userDataIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          if (this.selected.id && (key === 'password' || key === 'email')) {
            return true
          } else {
            return key
          }
        })
      )
    },
    selectedAdministratorData() {
      const selectedAdministratorData = Object.assign({}, this.mutableData.selectedAdministrator)
      selectedAdministratorData.options = [...this.administrators]
      // selectedAdministratorData.options.unshift({
      //   name: this.Dictionary.N.None,
      //   value: 'null'
      // })
      return selectedAdministratorData
    },
    dataChanged() {
      return this.userDataChanged
    },
    userDataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case 'selectedAdministrator':
            different =
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== this.initialSelectedAdministrator
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }

        return different
      })
      // console.log(target)
      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {
    handleLock() {
      this.$store.dispatch('usersManagement/toggleConfirmation', { action: 'lock', targetId: this.selected.id })
    },
    close() {
      this.$store.dispatch('usersManagement/removeSelected')
    },
    submitChanges(closeSelection) {
      if (this.selected.id) {
        if (this.userDataChanged) this.$store.dispatch('usersManagement/patchUser', { data: this.getUserData(), closeSelection })
      } else {
        this.$store.dispatch('usersManagement/createUser', { data: this.getUserData(), closeSelection })
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getUserData() {
      const userData = {}
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'password':
            if (!this.selected.id) {
              userData[key] = this.mutableData[key].value
            }
            break
          case 'selectedAdministrator':
            userData[key] = this.mutableData[key].value === 'null' ? null : this.mutableData[key].value
            break
          default:
            if (
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              !(Array.isArray(this.mutableData[key].value) && this.mutableData[key].value.length === 0)
            ) {
              userData[key] = this.mutableData[key].value
            }
        }
      })
      return userData
    },
    setSelected() {
      Object.keys(this.mutableData).forEach(key => {
        if (this.selected[key] !== undefined && this.selected[key] !== null) {
          switch (key) {
            default:
              this.mutableData[key].value = this.selected[key]
          }
        }
        if (key === 'selectedAdministrator') {
          this.initialSelectedAdministrator = this.administrators.find(administrator => administrator.users.includes(this.selected.id)).id

          this.mutableData[key].value = this.initialSelectedAdministrator
        }
      })
      this.setReadOnly()
    },
    setReadOnly() {
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'email':
          case 'firstName':
          case 'lastName':
            break
          default:
            this.mutableData[key].readOnly = !!this.selected.id
        }
      })
    }
  },
  created() {
    if (this.selected.id) this.setSelected()
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-um-user {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
}
</style>
