<template lang="pug">
.c-mo-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-5
      c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
    //- .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='companyData.options.length > 0')
    //-   c-fc-select(:model='companyData' v-model='company.value')
    .o-grid__item.o-grid__item--desktop-2.o-grid__item--align-right
      button.o-button.c-mo-search__button(@click='handleSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-mo-search__button(:disabled='!search.value && !company.value' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-mo-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      company: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.O.Owner,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('offersManagement', ['selected', 'serverParams'])
  },
  methods: {
    handleSearch() {
      const filter = {
        search: this.search.value,
        filters: {}
      }
      if (this.company.value) {
        filter.filters.companyId = this.company.value
      }
      this.$store.dispatch('offersManagement/searchOffers', filter)
    },
    handleReset() {
      this.search.value = null
      this.company.value = null

      this.handleSearch()
    }
  },
  watch: {
    serverParams: {
      deep: true,
      handler() {
        this.handleSearch()
      }
    },
    selected(newVal) {
      if (!newVal) {
        this.handleSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-mo-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
