/* eslint-disable prettier/prettier */
<template lang="pug">
.c-pm-overview
  .c-pm-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-pm-overview__button(:title='Dictionary.I.ImportProperty' @click='showImport = true') {{ Dictionary.I.ImportProperty }}
    button.o-button.c-pm-overview__button(:title='Dictionary.N.NewProperty' @click='createNew') {{ Dictionary.N.NewProperty }}
  keep-alive
    c-pm-search(:model='model')
  .c-pm-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-pm-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettingsData', :rows='properties' ref='table' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='!props.row.managedExternally')
                button.c-state-action(@click='handleAddResidence(props.row)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-add' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ model.addResidenceText }}
              .o-grid__item.o-grid__item--12(v-if='props.row.managedExternally')
                c-cta.o-button--small(
                  :cta-disabled='syncInProgress',
                  :cta-loading='syncInProgress',
                  :cta-text='Dictionary.S.Synchronize'
                  @click='syncUb4(props.row.companyPropertyNumbersId)'
                )
              .o-grid__item.o-grid__item--grow-0(v-else)
                button.c-state-action(@click='handleDelete(props.row.companyPropertyResidenceNumbersId)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Delete }}

        template(v-else-if='props.column.field == "name"')
          button.c-pm-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ props.row.name || Dictionary.N.NotSpecified }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
  c-import-data(
    :select-model='{ options: companyOptions, helper: model.propertyImportTranslations.importSelectHelper }',
    :show='showImport',
    :translations='{ startHeadline: model.propertyImportTranslations.importStartHeadline, startDescription: model.propertyImportTranslations.importStartDescription, loadingHeadline: model.propertyImportTranslations.importLoadingHeadline, loadingDescription: model.propertyImportTranslations.importLoadingDescription, doneHeadline: model.propertyImportTranslations.importDoneHeadline, doneDescription: model.propertyImportTranslations.importDoneDescription, doneImported: model.propertyImportTranslations.importDoneImportedText, doneFailed: model.propertyImportTranslations.importDoneFailedText }',
    :upload-model='{ validationSize: model.propertyImportTranslations.importUploadValidationSize, validationFormat: model.propertyImportTranslations.importUploadValidationFormat }'
    @close='showImport = false'
    import-type='property'
  )
</template>
<script>
import { mapState } from 'vuex'
import PropertyManagementSearch from '../pm-search'
import { propertyManagementService } from '@/_services'

import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-pm-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      showImport: false,
      columns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'companyPropertyNumbersId'
        },
        {
          label: this.Dictionary.P.PropertyName,
          field: 'name'
        },
        {
          label: this.Dictionary.A.Address,
          field: 'propertyAddress'
        },
        {
          label: this.Dictionary.R.Residences,
          field: 'residences',
          type: 'number'
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-pm-search': PropertyManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('propertyManagement', ['properties', 'page', 'pageSize', 'totalResults', 'types']),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    companyOptions() {
      return (
        this.companies &&
        JSON.parse(JSON.stringify(this.companies)).map(company => {
          company.value = company.id
          return company
        })
      )
    },
    syncInProgress() {
      return this.loading && this.loadingEndpoints.includes('/data/ub/sync/properties')
    }
  },
  methods: {
    syncUb4(id) {
      this.$store.dispatch('propertyManagement/syncUb4', id)
    },
    setSelected(id) {
      this.getInitialData()
      this.$store.dispatch('propertyManagement/setSelected', id)
    },
    createNew() {
      this.getInitialData()
      this.$store.dispatch('propertyManagement/createNew')
    },
    async handleAddResidence(property) {
      try {
        const response = await propertyManagementService.getProperty(property.id)
        if (response && response.status === 200) {
          this.getInitialData()
          this.$store.dispatch('residenceManagement/createNewInProperty', {
            companyId: response.data.company,
            propertyId: response.data.id,
            createNewInProperty: true,
            postalCode: response.data.postalCode,
            commune: response.data.commune,
            street: response.data.street,
            number: response.data.streetNumberFrom
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    handleDelete(id) {
      this.$store.dispatch('propertyManagement/toggleDeleteConfirmation', id)
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    getInitialData() {
      if (this.companies.length === 0) {
        this.$store.dispatch('search/getCompanies')
      }
      if (this.types.length === 0) {
        this.$store.dispatch('propertyManagement/getTypes')
      }
      if (this.facilities.length === 0) {
        this.$store.dispatch('search/getFacilities')
      }
    }
  },
  watch: {
    totalResults(newVal) {
      if (newVal === 0) {
        this.$refs.table.currentPerPage = 10
        this.$refs.table.currentPage = 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-pm-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
