import { membershipOrganizationsService, userService } from '@/_services'
import _ from 'lodash'

export const membershipOrganizations = {
  namespaced: true,
  state: {
    selected: undefined,
    selectedSaved: undefined,
    selectedWaitingListResidencies: [],
    activeWaitingListResidencies: [],
    confirmation: undefined,
    all: undefined
  },
  getters: {
    deleteProcessing: (state, getters, rootState) => {
      if (state.selected && state.selected.id) {
        const associationProcessingEndpoints = [`/data/membership-organizations/${state.selected.id}/association`]
        return rootState.loadingIndicator.loadingEndpoints.some(endpoint => associationProcessingEndpoints.indexOf(endpoint) >= 0)
      } else {
        return true
      }
    },
    associated: state => state.all && state.all.filter(organization => organization.associated),
    notAssociated: state => state.all && state.all.filter(organization => !organization.associated),
    userIsAssociated: (state, getters) =>
      (!!(state.selected && state.selected.sections && state.selected.sections.length === 0) && !getters.paymentRequired) ||
      (state.selected && getters.associated && !!getters.associated.find(pf => pf.id === state.selected.id)),
    paymentRequired: state => state.selected && state.selected.paymentStatus && state.selected.paymentStatus.associationStatus === 'PaymentRequired',
    parametersAnswersChanged: (state, getters) => {
      let answerchanged = false
      Object.keys(getters.submitParameters).forEach(parameterKey => {
        answerchanged =
          answerchanged ||
          (getters.submitParameters[parameterKey] !== getters.savedParameters[parameterKey] &&
            !(getters.submitParameters[parameterKey] === '' && getters.savedParameters[parameterKey] === undefined))
      })
      return Object.keys(getters.submitParameters).length === 0 || answerchanged
    },
    savedParameters: state => {
      const savedAnswers = {}

      state.selectedSaved &&
        state.selectedSaved.sections.forEach(section => {
          section.parameters.forEach(parameter => {
            if (parameter.type === 'Upload') {
              if (parameter.answer === null || (Array.isArray(parameter.answer) && parameter.answer.length > 0)) {
                savedAnswers[parameter.id] = parameter.answer === null ? parameter.answer : parameter.answer[0]
              }
            } else if (
              parameter.answer !== undefined &&
              parameter.answer !== null &&
              parameter.answer !== false &&
              !(Array.isArray(parameter.answer) && parameter.answer.length === 0)
            ) {
              savedAnswers[parameter.id] = parameter.answer.toString()
            } else if (
              // parameter.answer === null ||
              parameter.answer === '' ||
              parameter.answer === false ||
              (Array.isArray(parameter.answer) && parameter.answer.length === 0)
            ) {
              savedAnswers[parameter.id] = ''
            }
            // savedAnswers[parameter.id] = parameter.answer
          })
        })
      return savedAnswers
    },
    submitParameters: (state, getters) => {
      const submitParameters = {}
      state.selected &&
        state.selected.sections &&
        state.selected.sections.forEach((section, sectionKey) => {
          section.parameters.forEach((parameter, parameterKey) => {
            // console.log(parameter.type, parameter.answer)
            if (
              getters.dynamicSections[sectionKey] &&
              getters.dynamicSections[sectionKey].parameters[parameterKey] &&
              getters.dynamicSections[sectionKey].parameters[parameterKey].dependencyMet
            ) {
              if (parameter.type === 'Upload') {
                if (parameter.answer === null || (Array.isArray(parameter.answer) && parameter.answer.length > 0)) {
                  submitParameters[parameter.id] = parameter.answer === null ? parameter.answer : parameter.answer[0]
                }
              } else if (
                parameter.answer !== undefined &&
                parameter.answer !== null &&
                parameter.answer !== false &&
                !(Array.isArray(parameter.answer) && parameter.answer.length === 0)
              ) {
                submitParameters[parameter.id] = parameter.answer.toString()
              } else if (
                // parameter.answer === null ||
                parameter.answer === '' ||
                parameter.answer === false ||
                (Array.isArray(parameter.answer) && parameter.answer.length === 0)
              ) {
                submitParameters[parameter.id] = ''
              }
            }
          })
        })

      return submitParameters
    },
    dynamicSections: state => {
      let sections = []

      if (state.selected && state.selected.sections && state.selected.sections.length) {
        sections = JSON.parse(JSON.stringify(state.selected.sections))

        sections.forEach(section => {
          section.parameters.forEach(parameter => {
            parameter.dependencyMet = false
            if (!parameter.dependentOn || !parameter.dependentOn.length) {
              parameter.dependencyMet = true
            } else {
              parameter.dependencyMet = parameter.dependentOn.some(dependency => {
                let dependencyParameter = {}
                state.selected.sections.some(section => {
                  const target = section.parameters.find(parameter => parameter.options && parameter.options.some(answer => answer.id === dependency))
                  dependencyParameter = target || undefined
                  return !!target
                })
                let dependencySelected = false
                if (dependencyParameter && dependencyParameter.answer) {
                  if (dependencyParameter.answer === dependency) {
                    dependencySelected = true
                  } else if (Array.isArray(dependencyParameter.answer)) {
                    dependencySelected = dependencyParameter.answer.some(a => a === dependency)
                  } else {
                    dependencySelected = dependencyParameter.answer.split(',').some(a => a === dependency)
                  }
                }

                return dependencySelected
              })
            }
          })
          section.dependencyMet = section.parameters.some(parameter => parameter.dependencyMet)
        })
      }
      return sections
    }
  },
  actions: {
    updateAnswer({ commit }, payload) {
      commit('updateAnswer', payload)
    },
    setAll({ commit }, payload) {
      commit('setAll', payload)
    },
    async setSelected({ commit }, { id, isApplication, propertyId }) {
      if (id) {
        try {
          const paymentResponse = await membershipOrganizationsService.getUserAssociationStatus(id)
          if (paymentResponse && paymentResponse.status === 200) {
            const parametersResponse = isApplication
              ? await membershipOrganizationsService.getPropertyParameters(propertyId)
              : await membershipOrganizationsService.getMembershipOrganizationParameters(id)
            if (parametersResponse && parametersResponse.status === 200) {
              commit('setSelected', {
                selected: parametersResponse.data,
                id,
                paymentStatus: paymentResponse.data,
                orderStatus: undefined
              })
            }
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    closeConfirmation({ commit }) {
      commit('closeConfirmation')
    },
    async validateMembershipOrganizationParameters({ commit, getters, state }, payload) {
      if (state.selected.id && Object.keys(getters.submitParameters).length > 0) {
        try {
          const response = await membershipOrganizationsService.validateMembershipOrganizationParameters({
            id: state.selected.id,
            data: getters.submitParameters
          })
          if (response && response.status === 200) {
            if (response.data && response.data.revalidatedApplications.length > 0) {
              commit('setConfirmation', {
                response,
                payload,
                targetId: state.selected.id
              })
            } else {
              this.dispatch('membershipOrganizations/submitApplication', payload)
            }
          }
        } catch (error) {
          // console.log(error)
        }
      } else {
        this.dispatch('membershipOrganizations/submitApplication', payload)
      }
    },
    submitApplication: _.debounce(async function ({ commit, getters, state, rootState }, { isApplication, callback, extendingPayment }) {
      try {
        let parametersResponse
        if (Object.keys(getters.submitParameters).length) {
          const payload = { id: state.selected.id, data: getters.submitParameters, propertyId: rootState.property.propertyId }

          if (isApplication) {
            parametersResponse = await membershipOrganizationsService.setPropertyParameters(payload)
          } else {
            parametersResponse = await membershipOrganizationsService.setMembershipOrganizationParameters(payload)
          }
        }
        if (!parametersResponse || parametersResponse.status === 200) {
          if (!isApplication) {
            this.dispatch('membershipOrganizations/associateMembershipOrganization', extendingPayment)
          }

          commit('setSelectedSaved')

          if (callback) {
            callback()
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }, 250),

    async getResidenceApplications({ commit, state }, { propertyId }) {
      try {
        const response = await membershipOrganizationsService.getResidenceApplications({ id: propertyId })
        if (response) {
          commit('setActiveWaitingListResidencies', response.data)
          const selected = [...response.data, ...state.selectedWaitingListResidencies.filter(id => !response.data.includes(id))]
          commit('setSelectedWaitingListResidencies', selected)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async associateMembershipOrganization({ commit, state }, extendingPayment) {
      try {
        let response
        if (state.selected.paymentStatus.associationStatus === 'PayedExpiring' && extendingPayment) {
          response = await membershipOrganizationsService.extendMembershipOrganizationPayment(state.selected.id)
        } else {
          response = await membershipOrganizationsService.associateMembershipOrganization(state.selected.id)
        }
        if (response && response.status === 200) {
          if (response.data.status === 'PaymentRequired') {
            if (response.data.paymentUrl) {
              window.location.assign(response.data.paymentUrl)
            }
          } else if (response.data.status === 'Success') {
            if (state.all) {
              commit('setAssociated', state.selected.id)
            }
            commit('setConfirmation', {
              response,
              targetId: state.selected.id
            })

            this.dispatch('user/getPensionFundsUserData')
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteAssociation({ commit, state }, payload) {
      try {
        const response = await membershipOrganizationsService.deleteAssociation(payload)

        if (response && response.status === 200) {
          if (state.all) {
            commit('setDeletedAssociation', payload)
          }
          this.dispatch('user/getPensionFundsUserData')
          commit('closeConfirmation')
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async associateMembershipOrganizationProperty({ commit, state }, { propertyId, residencies }) {
      try {
        const response = await membershipOrganizationsService.associateMembershipOrganizationProperty({ id: propertyId, data: residencies })
        if (response && response.status === 200) {
          if (response.data.status === 'PaymentRequired') {
            if (response.data.paymentUrl) {
              window.location.assign(response.data.paymentUrl)
            }
          } else {
            const qualifiedResidences =
              response.data.qualifications &&
              response.data.qualifications.qualifications &&
              response.data.qualifications.qualifications.filter(r => r.isQualified).map(r => r.residenceId)
            const removedAll = response.data.status === 'Success' && response.data.qualifications === null
            if (qualifiedResidences || removedAll) {
              commit('setActiveWaitingListResidencies', removedAll ? [] : qualifiedResidences)
              if (residencies.length > 0) {
                commit('setConfirmation', {
                  qualifiedResidencesCount: removedAll ? 0 : qualifiedResidences.length,
                  qualifications: removedAll ? [] : response.data.qualifications.qualifications,
                  totalResidencesCount: residencies.length,
                  targetId: propertyId
                })
              }
            } else {
              commit('setConfirmation', {
                propertiesOverflow: response.data.qualifications.propertiesOverflow,
                residencesOverflow: response.data.qualifications.residencesOverflow,
                qualifications: response.data.qualifications.qualifications,
                targetId: propertyId
              })
            }
            if (state.all) {
              commit('setAssociated', state.selected.id)
            }
            this.dispatch('user/getPensionFundsUserData')
          }
        }
      } catch (error) {
        commit('setSelectedWaitingListResidencies', state.activeWaitingListResidencies)
      }
    },
    async validateParameters({ commit }, payload) {
      try {
        const response = await userService.revalidateApplications(payload.parameters)
        if (response && response.status === 200) {
          if (response.data && response.data.revalidatedApplications.length > 0) {
            commit('setConfirmation', {
              response,
              callback: payload.callback
            })
          } else {
            payload.callback()
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getOrderStatus({ commit }, payload) {
      try {
        const response = await membershipOrganizationsService.getPaymentStatus(payload)
        if (response && response.status === 200) {
          commit('setOrderStatus', response.data)
        }
      } catch (e) {
        // console.log(e)
      }
    }
  },
  mutations: {
    updateAnswer(state, { id, answer, validationPassed }) {
      const sKey = state.selected.sections.findIndex(element => {
        return !!element.parameters.find(parameter => parameter.id === id)
      })
      const pKey = state.selected.sections[sKey].parameters.findIndex(element => element.id === id)
      state.selected.sections[sKey].parameters[pKey] = Object.assign(state.selected.sections[sKey].parameters[pKey], {
        answer,
        isValid: validationPassed
      })
    },
    setAll(state, payload) {
      if (payload) {
        state.all = Object.assign([], payload)
      }
    },
    setSelected(state, { selected, id, paymentStatus, orderStatus }) {
      const initialData = state.all ? state.all.find(item => item.id === id.toLowerCase()) : { id }
      initialData.paymentStatus = paymentStatus
      initialData.orderStatus = orderStatus
      initialData.sections = selected || undefined
      state.selected = Object.assign({}, initialData)
      state.selectedSaved = Object.assign({}, JSON.parse(JSON.stringify(initialData)))
    },

    setSelectedSaved(state) {
      state.selectedSaved = _.cloneDeep(state.selected)
    },

    setSelectedWaitingListResidencies(state, payload) {
      state.selectedWaitingListResidencies = Object.assign([], payload)
    },
    setActiveWaitingListResidencies(state, payload) {
      state.activeWaitingListResidencies = Object.assign([], payload)
    },
    removeSelected(state) {
      state.selected = undefined
      state.selectedSaved = undefined
    },
    setConfirmation(state, payload) {
      state.confirmation = Object.assign({}, payload)
    },
    setAssociated(state, payload) {
      const target = state.all.find(item => item.id === payload)
      if (target) target.associated = true
    },
    setDeletedAssociation(state, payload) {
      const target = state.all.find(item => item.id === payload)
      if (target) target.associated = false
    },
    setInitial(state, payload) {
      payload.forEach(id => {
        const target = state.all.find(item => item.id === id)
        if (target) target.associated = true
      })
    },
    closeConfirmation(state) {
      state.confirmation = undefined
    },
    setOrderStatus(state, payload) {
      if (state.selected) {
        state.selected = Object.assign(state.selected, { orderStatus: payload })
        state.selectedSaved = Object.assign(state.selectedSaved, { orderStatus: payload })
      }
    }
  }
}
