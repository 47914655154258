import apiClient from '@/_helpers/api-client'
export const propertyManagementService = {
  searchManagedProperties,
  // searchProperties,
  createProperty,
  patchProperty,
  deleteProperty,
  getProperty,
  getTypes,
  getAddresses,
  getAddressSuggestions,
  addMedia,
  removeMedia,
  moveMedia,
  changeMedia,
  importPropertiesFromExcel,
  getPropertyDistribution,
  getPropertyMembershipSuborganizationOwners,
  getPropertyOwners
}

async function searchManagedProperties(data) {
  return await apiClient({
    url: '/search/managed-properties',
    method: 'POST',
    data
  })
}
// async function searchProperties(query) {
//   return await apiClient({
//     url: '/data/properties/',
//     method: 'POST',
//     data: query
//   })
// }
async function createProperty(data) {
  return await apiClient({
    url: '/data/properties',
    method: 'POST',
    data
  })
}
async function patchProperty({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/properties/${id}`,
    method: 'PATCH',
    data
  })
}
async function deleteProperty(id) {
  return await apiClient({
    url: `/data/properties/${id}`,
    method: 'DELETE'
  })
}
async function getProperty(id) {
  return await apiClient({
    url: `/data/properties/${id}`,
    method: 'GET'
  })
}
async function getTypes() {
  return await apiClient({
    url: '/data/property-types',
    method: 'GET'
  })
}
async function getAddresses({ postalCode, streetName, streetNumberFrom, streetNumberTo }) {
  return await apiClient({
    url: `/data/addresses?postalCode=${postalCode}&streetName=${streetName}&streetNumberFrom=${streetNumberFrom}&streetNumberTo=${streetNumberTo}`,
    method: 'GET'
  })
}
async function getAddressSuggestions(suggestion) {
  return await apiClient({
    url: `/data/addresses/autocomplete/${suggestion}`,
    method: 'GET'
  })
}

async function addMedia({ propertyId, type, formData, panoramaLink = false }) {
  const url = panoramaLink ? `/data/properties/${propertyId}/media/${type}/link` : `/data/properties/${propertyId}/media/${type}`
  return await apiClient({
    url,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function removeMedia({ propertyId, mediaId }) {
  return await apiClient({
    url: `/data/properties/${propertyId}/media/${mediaId}`,
    method: 'DELETE'
  })
}

async function moveMedia({ propertyId, data }) {
  return await apiClient({
    url: `/data/properties/${propertyId}/media/reorder`,
    method: 'PATCH',
    data
  })
}

async function changeMedia({ propertyId, mediaId, mediaType }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/properties/${propertyId}/media/${mediaId}`,
    method: 'PATCH',
    data: mediaType
  })
}

async function importPropertiesFromExcel(formData) {
  return await apiClient({
    url: '/data/import/properties/from/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function getPropertyDistribution(id) {
  return await apiClient({
    url: `/data/properties/${id}/owner-turn-distribution`,
    method: 'GET'
  })
}

// Originally implemented with using propertyId, however residenceid is needed to be detailed enough for DSR and Lotte rules exceptions. Therefore residenceId is passed
async function getPropertyMembershipSuborganizationOwners(propertyId, residenceId) {
  return await apiClient({
    url: `/data/properties/${residenceId}/membership-suborganization-owners`,
    method: 'GET'
  })
}
async function getPropertyOwners(id) {
  return await apiClient({
    url: `/data/properties/${id}/owners`,
    method: 'GET'
  })
}
