<template lang="pug">
.c-search-membership-organization
  .o-wrap.o-wrap.o-wrap--max-width-content-wide(:class='{ "o-wrap--padding-side-default": !isMobile }')
    h2.c-search-membership-organization__heading.o-heading.o-heading--size-2 {{ headingText }}
    c-search-result(:disabled='!userIsMember', :hideCards='!userIsMember', :model='searchModel' v-if='!userIsMember')
    c-search-menu(:model='modelWithPreset' v-if='userIsMember')
    c-notification-inline(:can-close='false', :show-icon='false' justify='space' type='crab' v-else)
      .o-grid.o-grid--gutter-normal.o-grid--align-items-center.o-grid--justify-content-space
        .o-grid__group
          .o-grid__item {{ notificationText }}
          .o-grid__item.o-grid__item--grow-0
            .o-button(v-requiresAuthentication:click.stop='{ action: confirmMembership, createdCtaText: this.model.membershipConfirmButtonText }') {{ this.model.membershipConfirmButtonText }}
  c-search-sub-menu(v-if='userIsMember')
  c-search-result(:disabled='!userIsMember', :hideCards='!userIsMember', :model='searchModel' v-if='userIsMember')
  c-mo-subscription(:callback='closeSubscribtion', :model='subscribtionModel' @close='closeSubscribtion' v-if='showApplicationFlow')
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import moSubscription from '@/components/membership-organization-subscription'
export default {
  name: 'c-search-membership-organization',
  data() {
    return {
      searchModel: {},

      id: this._uid,
      modelWithPreset: {}
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-mo-subscription': moSubscription
  },
  computed: {
    ...mapState('user', ['pensionFunds', 'userId']),
    ...mapState('loadingIndicator', ['loadingEndpoints']),
    ...mapGetters('user', ['pensionFundsId']),
    ...mapState('membershipOrganizations', ['selected', 'confirmation']),
    ...mapGetters('membershipOrganizations', ['userIsAssociated', 'paymentRequired']),
    userIsMember() {
      return this.pensionFundsId.includes(this.model.pensionFund.id)
    },
    headingText() {
      return this.model.heading.replace('{0}', this.model.pensionFund.name)
    },
    notificationText() {
      return this.model.text.replace('{0}', this.model.pensionFund.name)
    },
    showApplicationFlow() {
      return (
        !!(this.selected && this.selected.sections && this.selected.sections.length > 0) ||
        (!!this.confirmation && this.userIsAssociated) ||
        this.paymentRequired
      )
    },
    bodyConfirmation() {
      return (this.model.associationConfirmationBody || '').replace('{0}', (this.selected && this.selected.membershipOrganization) || '')
    },
    subscribtionModel() {
      return {
        heading: this.model.associationOverviewHeading,
        ctaText: this.model.associationOverviewCTAText,
        headingConfirmation: this.model.associationConfirmationHeading,
        descriptionConfirmation: this.model.associationConfirmationDescription,
        headingConfirmationWarning: undefined,
        descriptionConfirmationWarning: undefined,
        headingConfirmationError: undefined,
        descriptionConfirmationError: undefined,
        bodyConfirmation: this.bodyConfirmation,
        ctaConfirmation: this.model.associationConfirmationCTAText,
        headingValidation: this.model.membershipOrganizationsOverviewComponentModel && this.model.membershipOrganizationsOverviewComponentModel.validationTitle,
        descriptionValidation:
          this.model.membershipOrganizationsOverviewComponentModel && this.model.membershipOrganizationsOverviewComponentModel.validationDescription,
        qualifiedNotification: undefined,
        applicationPaymentHeading: this.model.associationPaymentHeading,
        paymentHeadline: this.selected.waitingListPaymentHeadline,
        paymentDescription: this.selected.waitingListPaymentDescription,
        paymentTermsPlaceholderText: this.selected.waitingListPaymentTermsPlaceholder,
        paymentTermsHelperText: this.selected.waitingListPaymentTermsHelper
      }
    }
  },
  methods: {
    valueChanged() {
      this.$store.dispatch(`${this.store}/${this.action}`, this.value)
    },
    confirmMembership() {
      const that = this
      const waitForPFData = setInterval(() => {
        const alreadyLoading = this.loadingEndpoints.includes('/data/users/me/membership-organizations')
        if (!alreadyLoading && !this.userIsMember) {
          that.$store.dispatch('membershipOrganizations/setSelected', {
            id: this.model.pensionFund.id
          })
          clearInterval(waitForPFData)
        }
      }, 100)
    },
    closeSubscribtion() {
      this.$store.dispatch('user/getPensionFundsUserData')
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
      this.$store.dispatch('membershipOrganizations/removeSelected')
    }
  },
  created() {
    this.$store.dispatch('membershipOrganizations/setAll', this.model.membershipOrganizationsOverviewComponentModel.membershipOrganizations)
    if (this.userId) {
      this.$store.dispatch('user/getPensionFundsUserData')
    }
    this.modelWithPreset = Object.assign({}, this.model)
    const filterPreset = Object.assign({}, JSON.parse(this.model.filterPreset))
    filterPreset.PropertyOrganizationId = this.model.pensionFund.id

    this.modelWithPreset.filterPreset = JSON.stringify(filterPreset)
    this.$store.commit('search/updateData', { filters: filterPreset })
    this.$store.dispatch('search/getResults')
  },
  watch: {
    userId(newVal) {
      if (newVal) {
        this.$store.dispatch('user/getPensionFundsUserData')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-search-membership-organization {
  padding: 75px;
  background-color: $grey-5;

  &__heading {
    text-align: center;
    margin-bottom: toRem(map-get($wrappadding, 'phone'));

    @include min('desktop') {
      margin-bottom: 35px;
    }
  }
}
</style>
