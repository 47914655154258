<template lang="pug">
.toast
  .toast__wrap.o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default.o-wrap--center-content-x
    transition-group.toast__transition-wrap(name='o-transition__toast' tag='div')
      c-notification-inline.toast__notification(
        :key='`toast-${toast.key}`',
        :type='getType(toast.status)'
        @close='removeToast(toast.key)'
        v-for='toast in toastMessages'
      ) {{ getToastText(toast) }}
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'c-toast',

  data() {
    return {
      id: this._uid,
      timeToDisplay: 10000,
      timeouts: []
    }
  },
  computed: {
    ...mapState('toast', ['toastMessages']),
    toasts() {
      return Object.assign([], this.toastMessages)
    }
  },
  methods: {
    getType(status) {
      let type = ''
      switch (true) {
        case status >= 0 && status < 100:
          type = 'ok'
          break
        case status >= 100 && status < 200:
          type = 'ok'
          break
        case status >= 200 && status < 300:
          type = 'ok'
          break
        case status >= 300 && status < 400:
          type = 'warning'
          break
        case status >= 400 && status <= 500:
          type = 'error'
          break
      }

      return type
    },
    getToastText(toast) {
      const customErrorText = this.Dictionary.ErrorCodesCustom && toast.data.errorCode !== undefined && this.Dictionary.ErrorCodesCustom[toast.data.errorCode]
      const httpErrorText = this.Dictionary.ErrorCodesHttp && toast.status !== undefined && this.Dictionary.ErrorCodesHttp[toast.status]
      const result = customErrorText || httpErrorText || `${toast.status} ${toast.data.detail}`
      return result
    },
    removeToast(key) {
      const timeoutIndex = this.timeouts.findIndex(timeout => {
        return timeout.key === key
      })
      clearTimeout(this.timeouts[timeoutIndex].timeout)
      this.timeouts.splice(timeoutIndex, 1)

      this.$store.dispatch('toast/remove', { key })
    }
  },
  watch: {
    toasts(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const newItem = newVal.filter(x => !oldVal.includes(x))[0]
        const that = this
        const timeout = setTimeout(() => {
          that.removeToast(newItem.key)
        }, this.timeToDisplay)

        this.timeouts.push({ timeout, key: newItem.key })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  top: 7px;
  left: 0;
  width: 100%;
  z-index: z('alert');
  pointer-events: none;
  &__wrap {
    pointer-events: none;
  }
  &__transition-wrap {
    position: relative;
    width: 100%;
    pointer-events: none;
  }
  &__notification {
    transition: all 0.3s;
    width: 100%;
    margin-bottom: 10px;
    pointer-events: all;
  }
}
</style>
