<template lang="pug">
.c-contact-form.o-wrap.o-wrap--max-width-xxlarge.o-wrap--padding-side-no-mobile-padding
  .o-heading.o-heading--size-3 {{ model.formTitle }}
  form.o-grid.o-grid--gutter-small(:key='resetKey')
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-6
        c-fc-text.c-contact-form__input(:model='contactData.name' @validate='validate($event, "name")' v-model='contactData.name.value')
      .o-grid__item.o-grid__item--desktop-6
        c-fc-text.c-contact-form__input(:model='contactData.address' @validate='validate($event, "address")' v-model='contactData.address.value')
      .o-grid__item.o-grid__item--desktop-6
        c-fc-number.c-contact-form__input(:model='contactData.postcode' @validate='validate($event, "postcode")' v-model='contactData.postcode.value')
      .o-grid__item.o-grid__item--desktop-6
        c-fc-text.c-contact-form__input(:model='contactData.city' @validate='validate($event, "city")' v-model='contactData.city.value')
      .o-grid__item.o-grid__item--desktop-6
        c-fc-number.c-contact-form__input(:model='contactData.phone' @validate='validate($event, "phone")' v-model='contactData.phone.value')
      .o-grid__item.o-grid__item--desktop-6
        c-fc-email.c-contact-form__input(:model='contactData.userEmail' @validate='validate($event, "userEmail")' v-model='contactData.userEmail.value')
      .o-grid__item.o-grid__item--12
        c-fc-textbox.c-contact-form__input(:model='contactData.message' @validate='validate($event, "message")' v-model='contactData.message.value')
  .c-contact-form__submit
    button.o-button.o-button--inverted(:disabled='!formIsValid' @click='showSendConfirmation = true') {{ Dictionary.S.Send }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showSendConfirmation = false' v-if='showSendConfirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ model.confirmationHeadline }}
    template(slot='body')
      .o-bodytext {{ model.confirmationText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-cta.o-button--inverted(:cta-text='Dictionary.Y.YesPlease' @click='submitForm')
          .o-grid__item.o-grid__item--12
            button.o-link(@click='showSendConfirmation = false') {{ Dictionary.U.Undo }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='false' @close='resetForm' v-if='submitted')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ model.formSubmittedConfirmationText }}
</template>
<script>
import { contactManagementService } from '@/_services'
import FormControllEmail from '@/components/form/controlls/form-email'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllTextbox from '@/components/form/controlls/form-textbox'
export default {
  name: 'c-contact-form',
  data() {
    return {
      validation: {},
      showSendConfirmation: false,
      submitted: false,
      resetKey: 0,
      contactData: {
        name: {
          helperText: '',
          placeholderText: this.Dictionary.N.Name,
          value: null,
          autocomplete: 'name',
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        userEmail: {
          helperText: '',
          placeholderText: this.Dictionary.E.Email,
          value: null,
          autocomplete: 'email',
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            unique: false
          }
        },
        phone: {
          helperText: this.model.phoneHelperText,
          placeholderText: this.Dictionary.P.Phone,
          value: null,
          autocomplete: 'tel',
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{8}|\\d{12}',
            displayRule: '',
            maxLength: 12,
            minLength: 8
          }
        },
        address: {
          helperText: '',
          placeholderText: this.Dictionary.A.Address,
          value: null,
          autocomplete: 'street-address',
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        postcode: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PostalCode,
          isMandatory: true,
          autocomplete: 'postal-code',
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1000,
            maxValue: 9999
          }
        },
        city: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.C.City,
          isMandatory: true,
          autocomplete: 'home city',
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          },
          inputName: 'city'
        },
        message: {
          helperText: '',
          placeholderText: this.Dictionary.M.Message,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        }
      }
    }
  },
  props: {
    model: {
      type: Object
    }
  },
  components: {
    'c-fc-email': FormControllEmail,
    'c-fc-text': FormControllText,
    'c-fc-number': FormControllNumber,
    'c-fc-textbox': FormControllTextbox
  },
  computed: {
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true)
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    resetForm() {
      this.contactData.name.value = null
      this.contactData.userEmail.value = null
      this.contactData.phone.value = null
      this.contactData.userEmail.value = null
      this.contactData.address.value = null
      this.contactData.postcode.value = null
      this.contactData.city.value = null
      this.contactData.message.value = null
      this.submitted = false
      this.resetKey += 1
    },
    async submitForm() {
      try {
        const formData = Object.keys(this.contactData).reduce((formData, key) => {
          formData.append(key, this.contactData[key].value)
          return formData
        }, new FormData())
        const formResponse = await contactManagementService.sendContactForm(formData)
        if (formResponse && formResponse.status === 204) {
          this.showSendConfirmation = false
          this.submitted = true
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-contact-form {
  &__input {
    & + & {
      margin-top: toRem(10px);
    }
  }
  &__submit {
    margin-top: toRem(20px);
    display: flex;
    justify-content: flex-end;
  }
}
</style>
