<template lang="pug">
.c-filter-input-slider
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model && model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id') {{ labelText }}
    .o-input__wrap.c-filter-input-slider__slider
      v-slider(
        :class='{ "vue-slider--reverse": reverse }',
        :disabled='disabled',
        :dot-attrs='{ "aria-label": labelText }',
        :hide-label='true',
        :id='id',
        :interval='interval',
        :marks='true',
        :max='max',
        :min='min'
        @change='valueChanged'
        ref='inputSlider'
        tooltip-placement='bottom'
        v-model='value'
      )
    label.o-label.o-label--helper(:for='id' v-if='model && model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'c-filter-input-slider',
  data() {
    return {
      id: this._uid,
      min: 0,
      max: 1,
      disabled: false,
      value: 0
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    reverse: {
      type: Boolean
    }
  },
  watch: {
    model: function () {
      this.value = this.filters[this.model.filter]
    }
  },
  computed: {
    ...mapState('search', ['filters', 'facets']),
    labelText() {
      if (this.model && this.model.labelText) {
        if (Array.isArray(this.filters[this.model.filter])) {
          return this.model.labelText.replace('{0}', this.value[0]).replace('{1}', this.value[1])
        } else {
          return this.model.labelText.replace('{0}', this.value)
        }
      } else {
        return this.value
      }
    },
    interval() {
      return this.model.boundInterval || (this.max - this.min) / 10
    }
  },
  methods: {
    valueChanged: _.debounce(function (value) {
      if (Array.isArray(value)) {
        this.$store.dispatch('search/addMinMaxFilter', { filter: this.model.filter, value })
      } else {
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value })
      }

      if (this.modalAfterSelect) {
        this.$store.dispatch('search/toggleSearchModal', true)
      }
    }, 250)
  },
  created() {
    this.min = this.model.boundMin || 0
    this.max = this.model.boundMax || 10
    if (Array.isArray(this.filters[this.model.filter])) {
      const val = []
      if (this.filters[this.model.filter][0] < this.model.boundMin) {
        val[0] = this.model.boundMin
      } else {
        val[0] = this.filters[this.model.filter][0]
      }
      if (this.filters[this.model.filter][1] > this.model.boundMax) {
        val[1] = this.model.boundMax
      } else {
        val[1] = this.filters[this.model.filter][1]
      }
      this.value = val
      this.valueChanged(this.value)
    } else {
      this.value = this.filters[this.model.filter] || this.min
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-slider {
  &__slider {
    margin-top: 5px;
  }
}
</style>
