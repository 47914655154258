<template lang="pug">
.c-my-offers
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-mo-overview')
        component(:is='selected && intialDataReady ? "c-offer-preview" : "c-mo-overview"', :model='model')
  template(v-else)
    c-mo-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-offer-preview.o-transition__overlay(:model='model' v-if='selected && intialDataReady')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2(v-if='model.offerAcceptConfirmationHeadline') {{ model.offerAcceptConfirmationHeadline }}
    template(slot='body')
      .o-bodytext(v-if='model.offerAcceptConfirmationDescription') {{ model.offerAcceptConfirmationDescription }}
      .c-modal__box
        table.o-table.o-table--clean
          tbody
            tr
              td.o-bodytext.o-bodytext--700 {{ Dictionary.F.FirstName }}:
              td {{ firstName || '-' }}
            tr
              td.o-bodytext.o-bodytext--700 {{ Dictionary.L.LastName }}:
              td {{ lastName || '-' }}
            tr
              td.o-bodytext.o-bodytext--700 {{ Dictionary.A.Address }}:
              td {{ address || '-' }}
            tr
              td.o-bodytext.o-bodytext--700.o-bodytext.o-bodytext--700 {{ Dictionary.C.City }}:
              td {{ city || '-' }}
            tr
              td.o-bodytext.o-bodytext--700 {{ Dictionary.P.PostalCode }}:
              td {{ zipCode || '-' }}
            tr(v-if='userHasAllRequiredInfo && (landlinePhone || phoneNoForSms)')
              td.o-bodytext.o-bodytext--700 {{ Dictionary.P.Phone }}:
              td {{ landlinePhone || phoneNoForSms }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12(v-if='userHasAllRequiredInfo')
            button.o-button.o-button--inverted(@click='handleConfirmationYes') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12(v-else)
            a.o-button.o-button--inverted(:href='model.offerAcceptProfileLink') {{ Dictionary.LoginFlow.AddProfileInformation }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import MyOffersOverview from './mo-overview'
import OfferPreview from '@/components/offer-preview'
export default {
  name: 'c-my-offers',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-mo-overview': MyOffersOverview,
    'c-offer-preview': OfferPreview
  },
  computed: {
    ...mapState('offersManagement', ['selected', 'offers', 'confirmationTarget']),
    ...mapGetters('offersManagement', ['intialDataReady']),
    ...mapState('user', ['firstName', 'lastName', 'address', 'city', 'zipCode', 'landlinePhone', 'phoneNoForSms']),
    userHasAllRequiredInfo() {
      return !!this.firstName && !!this.lastName && !!this.address && !!this.city && !!this.zipCode
    }
  },
  methods: {
    handleConfirmationYes() {
      this.$store.dispatch('offersManagement/acceptOffer', this.confirmationTarget.userId)
    },
    handleConfirmationClose() {
      this.$store.dispatch('offersManagement/toggleConfirmation')
    }
  },
  created() {
    this.$store.dispatch('user/getProfileInformation')
  }
}
</script>
<style lang="scss" scoped>
.c-my-offers {
}
</style>
