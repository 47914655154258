<template lang="pug">
button.dev-tools(:class='{ "dev-tools--active": developerMode }' @click='setDevMode')
  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-info' y='0')
  .dev-tools__text DEVELOPER MODE ACTIVE
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'dev-tools',
  computed: {
    ...mapState(['developerMode'])
  },
  methods: {
    setDevMode() {
      this.$store.dispatch('toggleDevMode')
    }
  }
}
</script>
<style lang="scss">
.dev-tools {
  $root: &;

  position: fixed;
  top: 0;
  left: 0;
  background: $ocean-primary;
  padding: 10px;
  border-bottom-right-radius: 30px;

  box-sizing: border-box;
  width: 60px;
  height: 60px;
  z-index: 99999999999;
  border: 0;
  cursor: pointer;
  transition: height 250ms linear;

  &__text {
    width: auto;
    position: absolute;
    top: 50%;
    left: 0px;
    white-space: nowrap;
    transform: rotate(-90deg) translate(-110%, 130%);
    transform-origin: left center;
    color: $crab-darker;
    display: none;
  }

  svg {
    width: 100%;
    height: 40px;
    fill: $white;
    stroke: $white;
    stroke-width: 1px;
  }

  &--active {
    height: 100vh;

    #{$root}__text {
      display: inline-block;
    }

    svg {
      fill: $crab-darker;
      stroke: $crab-darker;
    }
  }
}
</style>
