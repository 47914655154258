<template lang="pug">
.c-form-radiolist
  fieldset.o-input__container
    legend.o-label.o-label--before(:class='[{ "o-label--required": isMandatory }]' v-if='model.labelText') {{ model.labelText }}
    .o-grid.o-grid--direction-column.o-grid--justify-content-center.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0(:key='key' v-for='(option, key) in model.options')
          .o-input__wrap
            input.o-input(
              :aria-labelledby='`${id}-${key}-label ${ariaLabeledBy}`',
              :autocomplete='model.autocomplete || "do-not-autofill"',
              :class='[{ error: $v.value.$error }]',
              :disabled='model.readOnly',
              :id='`${id}-${key}`',
              :name='model.inputName || id',
              :value='option.value'
              @change='setvalue($event.target.value)'
              placeholder=' '
              type='radio'
              v-model='model.value'
            )
            label.o-label(:for='`${id}-${key}`', :id='`${id}-${key}-label`' v-if='option.name') {{ option.name }}
    legend.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    legend.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-radiolist',
  data() {
    return {
      id: this._uid,
      value: ''
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.isMandatory) {
            const valueSlected = this.value !== null && this.value.length > 0
            const requiredOptionSelected = this.hasMandatoryOptions
              ? this.model.options.filter(option => option.required && option.value === this.value).length > 0
              : true
            return valueSlected && requiredOptionSelected
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    isMandatory() {
      return this.model.isMandatory || this.hasMandatoryOptions
    },
    hasMandatoryOptions() {
      return this.model.options && this.model.options.length > 0 && this.model.options.some(option => option.required)
    },
    ariaLabeledBy() {
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue(value) {
      this.value = value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null && this.model.value !== undefined) {
        if (this.model.value.length > 0 && this.model.options.some(option => option.value === this.model.value)) {
          this.$v.value.$touch()
        }
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-radiolist {
}
</style>
