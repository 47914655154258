<template lang="pug">
.c-recipient-details
  .c-recipient-details__top.o-grid.o-grid--justify-content-space.o-grid
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.c-recipient-details__close(@click='close')
          svg.c-recipient-details__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
          .c-recipient-details__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
      .o-grid__item.o-grid__item--grow-0(v-if='isLocal')
        a.c-state-action(:href='`/api/authentication/impersonate/${recipientState.userId}`')
          svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          span.o-bodytext.o-bodytext--size-small {{ Dictionary.I.Imitate }}
  .c-recipient-details__info.o-grid.o-grid--align-items-start.o-grid.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.NumberShort }}
        .c-recipient-details__info-data.o-bodytext.o-bodytext--size-small {{ recipientData.applicantNumber || '-' }}
      .o-grid__item.o-grid__item--8.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.Name }}
        .c-recipient-details__info-data.o-bodytext.o-bodytext--size-small {{ recipientData.firstName }} {{ recipientData.lastName }}
      .o-grid__item.o-grid__item--1.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700
        svg.c-recipient-details__icon.o-svg-icon(
          :class='[getVerificationStatus(recipientData, rewardData.organizationId)]'
          type='image/svg+xml'
          viewbox='0 0 42 34'
        )
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
      .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.C.Category }}
        .c-recipient-details__info-data.o-bodytext.o-bodytext--size-small {{ getApplicantsCategoryInMembershipOrganization() || '-' }}
      .o-grid__item.o-grid__item--2.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.Points }}
        .c-recipient-details__info-data.o-bodytext.o-bodytext--size-small {{ getWaitingListPoints(recipientData, rewardData.organizationId, rewardData.propertyId) || 0 }}
      .o-grid__item.o-grid__item--7.o-grid__item--desktop-2
        .c-recipient-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.W.WaitingListStatus }} {{ Dictionary.R.Residence }}
        .c-recipient-details__info-data.o-bodytext.o-bodytext--size-small {{ getWaitingListStatusResidence() }}

  .c-recipient-details__data
    .c-recipient-details__form-section(v-if='recipientsAdverts.length > 0')
      .c-recipient-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.ActiveApplicationsHeadline }}
      .c-recipient-details__table.o-grid.o-grid--align-items-start.o-grid--justify-content-start
        .o-grid__group(v-for='advertRow in recipientsAdverts')
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-1
            span.o-bodytext.o-bodytext--size-small {{ advertRow.advert.shortId }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            span.o-bodytext.o-bodytext--size-small {{ combineAddress(advertRow.advert.street, advertRow.advert.number, advertRow.advert.letter, advertRow.advert.floor, advertRow.advert.door, advertRow.advert.postalCode) }}
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
            span.o-bodytext.o-bodytext--size-small {{ Dictionary.ApplicationStatus[advertRow.status] }}
      button.o-link(@click='declineApplicantsAdverts') {{ Dictionary.RecipientDetails.CancelActiveApplications }}
    .c-recipient-details__form-section(v-if='recipientsOffers.length > 0')
      .c-recipient-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.ActiveOffersHeadline }}
      .c-recipient-details__table.o-grid.o-grid--align-items-start.o-grid--justify-content-start
        .o-grid__group(v-for='offer in recipientsOffers')
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-1
            span.o-bodytext.o-bodytext--size-small {{ offer.number }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            span.o-bodytext.o-bodytext--size-small {{ offer.residenceAddress }}
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
            span.o-bodytext.o-bodytext--size-small {{ getRecipientsOfferState(offer) }}
      button.o-link(@click='cancelRecipientsOffers') {{ Dictionary.RecipientDetails.CancelActiveOffers }}
    .c-recipient-details__form-section
      .c-recipient-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.InformationHeadline }}
      .o-grid.o-grid--gutter-horizontal-small
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.A.Address }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ recipientData.address }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.E.Email }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ recipientData.email }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.P.Phone }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ recipientData.phone }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.P.PhoneMobile }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ recipientData.mobilePhone }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.I.InternalNote }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ recipientData.notes[rewardAdministrator && rewardAdministrator.id] }}
        template(v-if='targetMembershipOrganization')
          .o-grid__group
            .o-grid__item.o-grid__item--12
              .c-recipient-details__parameters-heading.o-bodytext.o-bodytext--size-large {{ Dictionary.RecipientDetails.MemberOrgInformationHeadline }}
          .o-grid__group
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.O.Organization }}
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-8
              .o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ targetMembershipOrganization.name }}
          .o-grid__group
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.V.VerificationStatus }}
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ getVerificationStatusText() }}
            .o-grid__item.o-grid__item--4
              .c-applicant-status__timestamp.o-bodytext.o-bodytext--size-small(v-if='verificationApprovedDate') {{ Dictionary.V.VerificationTime }}: {{ verificationApprovedDate | formatDate }}
          .o-grid__group
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.D.DocumentStatus }}
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ getDocumentStatusText() }}
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small(v-if='documentApprovedDate') {{ Dictionary.V.VerificationTime }}: {{ documentApprovedDate | formatDate }}
          .o-grid__group
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.Q.Quarantine }}
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.F.From }}: {{ targetMembershipOrganization.quarantineStartDate | formatDate }}
            .o-grid__item.o-grid__item--4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.T.To }}: {{ targetMembershipOrganization.quarantineEndDate | formatDate }}
          .o-grid__group(v-if='targetMembershipOrganization.memberSeniorityDate')
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.M.MemberSeniority }}
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-8
              .o-bodytext.o-bodytext--size-small {{ targetMembershipOrganization.memberSeniorityDate | formatDate }}
          .o-grid__group(v-if='targetMembershipOrganization.listSeniorityDate')
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
              .o-bodytext.o-bodytext--size-small {{ Dictionary.W.WaitingListSeniority }}
            .o-grid__item.o-grid__item--6.o-grid__item--desktop-8
              .o-bodytext.o-bodytext--size-small {{ targetMembershipOrganization.listSeniorityDate | formatDate }}
    .c-recipient-details__form-section(v-if='recipientsMembershipOrganizationParameters.length > 0')
      .c-recipient-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.AffiliationQuestionsHeadline }}
      .c-recipient-details__parameters-section(
        :key='`parameter-section-${parameterSectionIndex}`'
        v-for='(parameterSection, parameterSectionIndex) in recipientsMembershipOrganizationParameters'
      )
        .c-recipient-details__parameters-heading.o-bodytext.o-bodytext--size-large(v-if='parameterSection.name') {{ parameterSection.name }}
        .o-grid.o-grid--gutter-horizontal-small
          .o-grid__group(:key='`parameter-${parameterIndex}`' v-for='(parameter, parameterIndex) in parameterSection.parameters')
            .o-grid__item.o-grid__item--5.o-grid__item--desktop-4
              .o-bodytext.o-bodytext--size-small {{ parameter.question }}
            .o-grid__item.o-grid__item--5.o-grid__item--desktop-4
              .c-recipient-details__parameters-answer.o-bodytext.o-bodytext--size-small(
                v-if='parameter.validationConfig && parameter.validationConfig.displayRule === "######-####"'
                v-sensitiveInfo:4='getAnswerFromParameter(parameter)'
              )
              .c-recipient-details__parameters-answer.o-bodytext.o-bodytext--size-small(v-else) {{ getAnswerFromParameter(parameter) }}
    .c-recipient-details__form-section(v-if='showEdit')
      .c-recipient-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.S.Status }}

      .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
        .o-grid__group
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            c-fc-select(:model='recipientDetails.newState' @validate='validate($event, "newState")' v-model='recipientDetails.newState.value')
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            c-fc-date(
              :model='recipientDetails.newFollowUpDate'
              @validate='validate($event, "newFollowUpDate")'
              v-model='recipientDetails.newFollowUpDate.value'
            )
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
            c-fc-textbox(:model='recipientDetails.newNote' @validate='validate($event, "note")' v-model='recipientDetails.newNote.value')
  .c-recipient-details__form.c-recipient-details__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center.o-grid--justify-content-center(
    v-if='showEdit'
  )
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.o-link.c-recipient-details__cancel(@click='close') {{ Dictionary.C.Cancel }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.c-recipient-details__submit(:disabled='loading || !dataChanged' @click='submitChanges') {{ Dictionary.S.Save }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import { applicantsManagementService, advertManagementService, offersManagementService } from '@/_services'
import moment from 'moment'
export default {
  name: 'c-recipient-details',
  props: {
    model: Object,
    rewardData: {
      type: Object,
      required: true
    },
    isResidenceDetails: {
      type: Boolean,
      default: false,
      required: false
    },
    callbackAfterChanges: {
      type: Function
    }
  },
  data() {
    return {
      id: this._uid,

      recipientsMembershipOrganizationParameters: [],
      recipientsOffers: [],
      recipientsAdverts: [],
      validation: {},
      recipientDetails: {
        newState: {
          helperText: '',
          placeholderText: this.Dictionary.S.Status,
          value: null,
          isMandatory: false,
          options: [
            {
              name: '',
              value: ''
            },
            {
              name: this.Dictionary.RecipientStateInternal.Offered,
              value: 'Offered'
            },
            {
              name: this.Dictionary.RecipientStateInternal.Ended,
              value: 'Ended'
            }
          ],
          validation: null
        },
        newFollowUpDate: {
          initialDate: '',
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.F.FollowUpDate,
          isMandatory: false,
          readOnly: false,
          validation: {
            minDate: moment.utc().format('YYYY-MM-DD')
          },
          key: 0
        },
        newNote: {
          helperText: '',
          placeholderText: this.Dictionary.N.Note,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-date': FormControllDate,
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapGetters(['isLocal']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('applicantsManagement', { recipientData: 'selected' }),
    showEdit() {
      return !this.isResidenceDetails && Object.keys(this.recipientState).length > 0
    },
    targetMembershipOrganization() {
      return (
        this.recipientData.membershipOrganizations.find(membershipOrganization => membershipOrganization.companies.includes(this.rewardData.companyId)) || null
      )
    },
    rewardAdministrator() {
      return this.administrators && this.administrators.find(administrator => administrator.companies.includes(this.rewardData.companyId))
    },
    recipientState() {
      const recipientState =
        this.rewardData.recipientsState && this.rewardData.recipientsState.find(recipient => recipient.userId === this.recipientData.userId)
      return recipientState || {}
    },
    dataChanged() {
      let dataChanged = false
      const target = Object.keys(this.recipientDetails).find(key => {
        let different = false
        switch (key) {
          case 'newNote':
            different =
              this.recipientDetails[key].value !== null &&
              !(this.recipientDetails[key].value === '' && this.recipientState.note === null) &&
              this.recipientDetails[key].value !== this.recipientState.note
            break
          case 'newFollowUpDate':
            different = this.recipientDetails[key].value !== this.recipientDetails[key].initialDate
            break
          case 'newState':
            different =
              this.recipientDetails[key].value !== undefined &&
              this.recipientDetails[key].value !== null &&
              this.recipientDetails[key].value !== this.recipientState.internalState
            break
          default:
            different =
              this.recipientDetails[key].value !== undefined &&
              this.recipientDetails[key].value !== null &&
              this.recipientDetails[key].value !== '' &&
              this.recipientDetails[key].value !== this.recipientState[key]
        }
        // console.log(different, key)
        return different
      })

      dataChanged = !!target
      return dataChanged
    },
    verificationApprovedDate() {
      let date
      if (this.targetMembershipOrganization && this.targetMembershipOrganization.verified) {
        date = this.targetMembershipOrganization.verifiedApprovedDate
          ? this.targetMembershipOrganization.verifiedApprovedDate
          : this.targetMembershipOrganization.verifiedDate
      }
      return date
    },
    documentApprovedDate() {
      let date
      if (this.targetMembershipOrganization && this.targetMembershipOrganization.documentationVerified) {
        date = this.targetMembershipOrganization.documentationApprovedDate
          ? this.targetMembershipOrganization.documentationApprovedDate
          : this.targetMembershipOrganization.documentationVerifiedDate
      }
      return date
    }
  },
  methods: {
    close() {
      this.$store.dispatch('applicantsManagement/removeSelected')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    async getApplicantsParameters() {
      try {
        if (this.rewardData.organizationId) {
          const response = await applicantsManagementService.getApplicantsParameters({
            userId: this.recipientData.userId,
            moId: this.rewardData.organizationId
          })
          if (response && response.status === 200) {
            this.recipientsMembershipOrganizationParameters = response.data
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    setRecipientDetails() {
      Object.keys(this.recipientDetails).forEach(key => {
        switch (key) {
          case 'newFollowUpDate':
            this.recipientDetails[key].value =
              this.recipientState && this.recipientState.followupDate ? this.formatDate(this.recipientState.followupDate, 'YYYY-MM-DD') : null
            this.recipientDetails[key].initialDate =
              this.recipientState && this.recipientState.followupDate ? this.formatDate(this.recipientState.followupDate, 'YYYY-MM-DD') : null
            break
          case 'newNote':
            this.recipientDetails[key].value = this.recipientState && this.recipientState.note ? this.recipientState.note : null
            break
          case 'newState':
            this.recipientDetails[key].value = this.recipientState && this.recipientState.internalState ? this.recipientState.internalState : null
            break
          default:
            this.recipientDetails[key].value = this.recipientState && this.recipientState[key] ? this.recipientState[key] : null
        }
      })
    },
    getPoints(parameter) {
      return this.recipientData.userPointBalance[parameter.id] !== undefined
        ? `${this.recipientData.userPointBalance[parameter.id]} ${this.Dictionary.P.Points}`
        : ''
    },
    getApplicantsCategoryInMembershipOrganization() {
      return (this.recipientData.categories[this.rewardData.organizationId] && this.recipientData.categories[this.rewardData.organizationId].toString()) || ''
    },
    getWaitingListStatusResidence() {
      const residenceApplicationStatus = this.recipientData.applications && this.recipientData.applications[this.rewardData.residenceId || this.rewardData.id]
      return (residenceApplicationStatus && this.Dictionary.ResidenceApplicationStatus[residenceApplicationStatus]) || '-'
    },
    async submitChanges() {
      try {
        const data = {
          users: [this.recipientData.userId]
        }
        if (this.recipientDetails.newState.value !== null) {
          data.newState = this.recipientDetails.newState.value
        }
        if (this.recipientDetails.newFollowUpDate.value !== null) {
          if (this.recipientDetails.newFollowUpDate.value === '') {
            data.removeFollowUpDate = true
          } else {
            data.newFollowUpDate = this.recipientDetails.newFollowUpDate.value
          }
        }
        if (this.recipientDetails.newNote.value !== null) {
          if (this.recipientDetails.newNote.value === '') {
            data.removeNote = true
          } else {
            data.newNote = this.recipientDetails.newNote.value
          }
        }
        const response = await offersManagementService.setRecipientsState(this.rewardData.id, data)
        if (response.status === 200) {
          this.$store.dispatch('offersManagement/setSelected', this.rewardData.id)
          this.callbackAfterChanges && this.callbackAfterChanges()
          this.close()
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getRecipientsOffers() {
      try {
        const response = await offersManagementService.getRecipientsPendingOffers({
          userId: this.recipientData.userId
        })
        if (response && response.status === 200) {
          this.recipientsOffers = response.data.length > 0 && response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getRecipientsOfferState(offer) {
      const offerState = offer.recipientsState.find(s => s.userId === this.recipientData.userId)
      return offerState && this.Dictionary.RecipientState[offerState.state]
    },
    async declineRecipientsOffer(id) {
      try {
        const response = await offersManagementService.declineRecipientsOffer({
          id,
          userId: this.recipientData.userId
        })
        if (response && response.status === 200) {
          const targetOffer = this.recipientsOffers.find(o => o.id !== id)
          Object.assing(targetOffer, response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    cancelRecipientsOffers() {
      this.recipientsOffers.forEach(offer => {
        this.declineRecipientsOffer(offer.id)
      })
    },
    async getApplicantsPendingAdverts() {
      try {
        const response = await advertManagementService.getApplicantsPendingAdverts({
          userId: this.recipientData.userId,
          id: '11111111-1111-1111-1111-111111111111'
        })
        if (response && response.status === 200) {
          this.recipientsAdverts = response.data.length > 0 && response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async declineApplicantsAdverts() {
      try {
        const response = await advertManagementService.declineApplicantsAdverts({
          id: '11111111-1111-1111-1111-111111111111',
          userId: this.recipientData.userId
        })
        if (response && response.status === 204) {
          this.recipientsAdverts = []
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getVerificationStatusText() {
      let initialVerificationStatus = null
      if (this.targetMembershipOrganization) {
        initialVerificationStatus = !this.targetMembershipOrganization.verified
          ? this.Dictionary.U.UnderVerification
          : this.targetMembershipOrganization.verified && this.targetMembershipOrganization.verifiedApprovedDate
          ? this.Dictionary.V.VerifiedApproved
          : this.Dictionary.V.VerifiedRejected
      }

      return initialVerificationStatus
    },
    getDocumentStatusText() {
      let initialDocumentStatus = null
      if (this.targetMembershipOrganization) {
        initialDocumentStatus = !this.targetMembershipOrganization.documentationVerified
          ? this.Dictionary.N.NotVerified
          : this.targetMembershipOrganization.documentationVerified && this.targetMembershipOrganization.documentationApprovedDate
          ? this.Dictionary.V.VerifiedApproved
          : this.Dictionary.V.VerifiedRejected
      }
      return initialDocumentStatus
    }
  },
  created() {
    this.getApplicantsParameters()
    this.getApplicantsPendingAdverts()
    this.getRecipientsOffers()
    if (!this.administrators || this.administrators.length === 0) {
      this.$store.dispatch('usersManagement/getPropertyAdministrators')
    } else {
      this.setRecipientDetails()
    }
  },
  watch: {
    rewardAdministrator() {
      this.setRecipientDetails()
    },
    recipientState() {
      this.setRecipientDetails()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-recipient-details {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      color: inherit;
      padding: 0;
      min-height: 0;
      margin-bottom: 25px;
      box-shadow: none;
    }

    @include max('desktop', -1) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;

      & + #{$root}__info {
        margin-top: 62px;
      }
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }
  &__icon {
    width: 24px;
    height: 20px;
    margin: 0 10px 0 0;
    @include min('desktop') {
      width: 20px;
      height: 20px;
    }

    @include max('desktop', -1) {
      margin: 0 auto;
      display: block;
    }
  }
  &__info {
    padding: toRem(16px 25px);
    background-color: $grey-5;
  }
  &__data {
    margin-top: 20px;

    @include max('desktop', -1) {
      padding: 0 25px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
      border-top: 1px solid $grey-4;

      @include max('desktop', -1) {
        padding: $sectionMargin 25px 0;
      }
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 10px;
      @include min('desktop') {
        margin-bottom: 20px;
      }
    }
  }
  &__table {
    margin-bottom: 10px;
  }

  &__parameters {
    &-heading {
      margin-bottom: 10px;
    }
    &-section {
      margin-bottom: 15px;
    }
    &-answer {
      color: $grey-3;
    }
  }
}
</style>
