<template lang="pug">
.c-filter-input-radiolist
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id' v-if='model && model.labelText') {{ model.labelText }}
    fieldset
      .o-grid.o-grid--direction-column.o-grid--justify-content-center.o-grid--gutter-tiny
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            .o-input__wrap
              input.o-input(
                :checked='valueChecked(0)',
                :id='`${id}-default`',
                :name='model.filter',
                :value='0'
                @change='valueChanged("")'
                placeholder=' '
                type='radio'
                v-model='value'
              )
              label.o-label(:for='`${id}-default`') {{ model.defaultOptionLabelText }}
          .o-grid__item.o-grid__item--grow-0(:key='key' v-for='(option, key) in model.options')
            .o-input__wrap(v-if='option.value === "" && option.specialType === "selectDate"')
              input.o-input(
                :checked='valueChecked(specialTypeValue)',
                :id='`${id}-${key}`',
                :name='model.filter',
                :value='specialTypeValue'
                @change='valueChanged(option.specialType, true)'
                placeholder=' '
                type='radio'
              )
              label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
              input.o-input.c-filter-input-radiolist__special-type(
                :id='`${id}-${key}`',
                :name='model.filter'
                @change='valueChanged("selectDate")'
                placeholder=' '
                type='date'
                v-if='specialType === "selectDate"'
                v-model='specialTypeValue'
              )
            .o-input__wrap(v-else)
              input.o-input(
                :checked='valueChecked(option.value)',
                :id='`${id}-${key}`',
                :name='model.filter',
                :value='option.value'
                @change='valueChanged("")'
                placeholder=' '
                type='radio'
                v-model='value'
              )
              label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
    label.o-label.o-label--helper(:for='id' v-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-filter-input-radiolist',
  data() {
    return {
      id: this._uid,
      value: 0,
      specialType: '',
      specialTypeValue: ''
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('search', ['filters', 'facets']),
    // optionChosen() {
    //   return this.model.options.some(option => {
    //     return option.value === this.filters[this.model.filter]
    //   })
    // },
    selectDate() {
      return this.model.options.some(item => item.specialType === 'selectDate')
    }
  },
  methods: {
    valueChanged(specialType, resetSpecialValue = false) {
      if (specialType) {
        this.specialType = specialType
        if (resetSpecialValue) {
          this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: 0 })
          this.value = ''
          this.specialTypeValue = ''
        } else {
          // this.$store.dispatch('search/removeFilter', { filter: this.model.filter, value: this.oldValue })
          this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: this.specialTypeValue })
          this.value = this.specialTypeValue
        }
      } else {
        this.specialType = ''
        // this.$store.dispatch('search/removeFilter', { filter: this.model.filter, value: this.oldValue })
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: this.value })
        // this.oldValue = this.value
      }
    },
    valueChecked(value) {
      let checked = false

      checked = this.filters[this.model.filter] === value || (value === '0' && !this.optionChosen)

      return checked
    }
  },
  mounted() {
    this.value = this.filters[this.model.filter]
    const optionsDoesNotContainValue = !this.model.options.some(item => item.value === this.filters[this.model.filter])
    if (this.selectDate && optionsDoesNotContainValue && this.value !== 0) {
      this.specialType = 'selectDate'
      this.specialTypeValue = this.value
    }

    // this.oldValue = this.value
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-radiolist {
  &__special-type {
    margin-top: 10px;
  }
}
</style>
