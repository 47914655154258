import { propertyManagementService, ub4SyncService } from '@/_services'
const searchConfiguration = document.getElementById('search-configuration')
  ? JSON.parse(new DOMParser().parseFromString(document.getElementById('search-configuration').text, 'text/html').documentElement.textContent)
  : false
const initialState = {
  properties: [],
  selected: undefined,
  types: searchConfiguration.propertyTypes || [],
  page: 0,
  pageSize: 10,
  totalResults: 0,
  deleteTargetId: undefined
}

const initialSelected = {
  id: undefined,
  media: {
    blueprints: [],
    images: [],
    panoramas: [],
    videos: []
  }
}

export const propertyManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state,
    intialDataReady: (state, _, rootState) => {
      return (
        rootState.search.companies.length > 0 &&
        state.types.length > 0 &&
        rootState.search.facilities.length > 0 &&
        rootState.search.facilityLocations.length > 0
      )
    }
  },
  actions: {
    async setSelected({ commit }, id) {
      if (id) {
        try {
          const response = await propertyManagementService.getProperty(id)
          if (response && response.status === 200) {
            const responseDistribution = await propertyManagementService.getPropertyDistribution(id)
            // const responsePropertyMembershipSuborganizationOwners = await propertyManagementService.getPropertyMembershipSuborganizationOwners(id)
            if (responseDistribution && responseDistribution.status === 200 && responseDistribution.data) {
              response.data.distribution = { ...responseDistribution.data }
            }

            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    async getPropertyDistribution({ commit }, id) {
      if (id) {
        try {
          const response = await propertyManagementService.getPropertyDistribution(id)
          if (response && response.status === 200) {
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    createNew({ commit }) {
      commit('createNew')
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createProperty({ commit }, { data, closeSelection }) {
      try {
        const response = await propertyManagementService.createProperty(data)
        if (response && response.status === 200) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            commit('updateData', {
              selected: {
                id: response.data,
                media: {
                  blueprints: [],
                  images: [],
                  panoramas: [],
                  videos: []
                }
              }
            })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchProperty({ commit, state }, { data, closeSelection }) {
      try {
        const response = await propertyManagementService.patchProperty({ data, id: state.selected.id })
        if (response && response.status === 204) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            data.id = state.selected.id
            data.media = state.selected.media
            commit('updateData', { selected: data })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async syncUb4({ commit }, payload) {
      try {
        const data = [payload]
        const response = await ub4SyncService.postUb4SyncProperties(data)
        if (response && response.status === 200) {
          this.dispatch('propertyManagement/searchManagedProperties', {})
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async searchManagedProperties({ commit }, payload) {
      try {
        payload.pageSize = payload.pageSize || 10000
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = 0
        const response = await propertyManagementService.searchManagedProperties(payload)
        if (response && response.status === 200) {
          commit('updateData', {
            properties: response.data.results,
            page: response.data.page,
            pageSize: response.data.pageSize,
            totalResults: response.data.totalResults
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getTypes({ commit }) {
      try {
        const response = await propertyManagementService.getTypes()
        if (response && response.status === 200) {
          commit('updateData', { types: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },

    async deleteProperty({ commit }, payload) {
      try {
        const response = await propertyManagementService.deleteProperty(payload)
        if (response && response.status === 204) {
          commit('deleteEntry', payload)
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    },
    addMedia({ commit, state }, payload) {
      payload.propertyId = state.selected.id

      const fileTypes = ['images', 'blueprints', 'panoramas', 'panoramas360']
      const formField = !payload.panoramaLink && fileTypes.includes(payload.type) ? 'files' : 'uri'

      let formData = new FormData()

      const batches = []
      let counter = 0

      if (formField === 'uri') {
        formData.append(formField, payload.media)
      } else {
        Object.keys(payload.media).forEach(mediaKey => {
          if (counter <= 4) {
            formData.append(formField, payload.media[mediaKey])
            counter++
          } else {
            batches.push(formData)
            formData = new FormData()
            formData.append(formField, payload.media[mediaKey])
            counter = 1
          }
        })
      }
      batches.push(formData)

      function sendBatch() {
        payload.formData = batches.pop()
        propertyManagementService
          .addMedia(payload)
          .then(response => {
            if (response && response.status === 200) {
              commit('addMedia', { type: payload.type, items: response.data })
              if (batches.length) sendBatch()
            }
          })
          .catch(() => {})
      }

      sendBatch()
      // while(batches.length) {

      //   payload.formData = batch
      //     const response = await propertyManagementService.addMedia(payload)
      //     if (response && response.status === 200) {
      //       commit('addMedia', { type: payload.type, items: response.data })
      //     }
      // }
      // batches.forEach(async function (batch) {
      //   try {

      //   } catch (error) {
      //     // console.log(error)
      //   }
      // })
    },
    async removeMedia({ commit, state }, payload) {
      try {
        payload.propertyId = state.selected.id
        const response = await propertyManagementService.removeMedia(payload)
        if (response && response.status === 204) {
          commit('removeMedia', payload)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    moveMedia({ state }, payload) {
      payload.propertyId = state.selected.id
      // const orderChanged = state.selected.media[payload.mediaCategory].length - 1 !== payload.mediaIndex
      function changeOrder(payload) {
        propertyManagementService
          .moveMedia({ propertyId: state.selected.id, data: payload })
          .then(response => {
            return response
          })
          .catch(error => {
            return error
          })
      }
      function changeCategory(payload) {
        propertyManagementService
          .changeMedia(payload)
          .then(response => {
            if (response && response.status === 204) {
              // console.log(payload.mediaIndex)
              changeOrder({
                targetMedia: payload.mediaId,
                insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
                  ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
                  : null
              })
            }
          })
          .catch(error => {
            return error
          })
      }

      if (payload.mediaType) {
        changeCategory(payload)
      } else {
        changeOrder({
          targetMedia: payload.mediaId,
          insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
            ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
            : null
        })
      }
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    deleteEntry(state, payload) {
      state.properties.splice(state.properties.indexOf(state.properties.find(property => property.id === payload)), 1)
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.deleteTargetId = payload
      } else {
        state.deleteTargetId = undefined
      }
    },
    addMedia(state, payload) {
      if (Array.isArray(payload.items)) {
        payload.items.forEach(item => {
          state.selected.media[payload.type].push(item)
        })
      } else {
        state.selected.media[payload.type].push(payload.items)
      }
    },
    removeMedia(state, payload) {
      if (payload) {
        state.selected.media[payload.type].splice(
          state.selected.media[payload.type].indexOf(state.selected.media[payload.type].find(media => media.id === payload.mediaId)),
          1
        )
      }
    }
  }
}
