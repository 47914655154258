<template lang="pug">
.login-flow
  c-modal(:cornerClose='true', :darkTheme='true' @close='closeLoginFlow' v-if='loginModalShowing')
    div(slot='body')
      c-loading-animation(:center='true', :overlay='true' v-if='loading && loginModalShowing')
      .login-flow
        .login-flow-login(v-if='!showProfileCreated && !showForgotPassword && !showResetPasswordForm')
          h3.login-flow__heading.o-heading--size-3(v-if='!(showCreate && verificationFlow)') {{ Dictionary.LoginFlow.LoginOrCreateProfileHeadline }}
          .login-flow__text(v-if='!(showCreate && verificationFlow)') {{ loginFlowText }}
          .login-start(v-if='!showCreate && !showLogin')
            .login-flow__start-text {{ Dictionary.LoginFlow.LoginOrCreateProfilePreHeadline }}
            .o-input__container(:class='{ tooltip: !this.validEmail(this.email) }')
              .o-input__wrap.tooltip-bottom(:data-tooltip='Dictionary.LoginFlow.EmailHelperText')
                input#emailInput.o-input(
                  autocomplete='username'
                  name='emailInput'
                  placeholder=' '
                  ref='emailInput'
                  tabindex=0
                  title=' '
                  type='email'
                  v-model='email'
                  v-on:keyup.enter='validateUser()'
                )
                label.o-label.o-label--placeholder(for='emailInput') {{ Dictionary.E.Email }}
            button.o-button.o-button--inverted.o-button--full-width(:disabled='!this.validEmail(this.email)' @click='validateUser()') {{ Dictionary.C.Continue }}

          template(v-if='showCreate && verificationFlow')
            h3.login-flow__heading.o-heading--size-3 {{ isFamilyMemberVerification ? model.pfaAuthentication.pfaFamilyProfileCreationHeadline : model.pfaAuthentication.pfaProfileCreationHeadline }}
            .login-flow__text {{ isFamilyMemberVerification ? model.pfaAuthentication.pfaFamilyProfileCreationDescription : model.pfaAuthentication.pfaProfileCreationDescription }}
          c-login-flow-create-user(
            :model='model'
            @resetFlow='resetFlow'
            @showMessage='showMessage'
            v-bind:initialEmail='this.email'
            v-if='showCreate'
            validEmail=validEmail
          )
          c-login-flow-login(
            :model='model'
            @resetFlow='resetFlow'
            @showCreateUser='showCreateUser'
            @showResetPassword='showResetPassword'
            v-bind:initialEmail='this.email'
            v-if='showLogin'
          )
        c-login-flow-profile-created(v-if='showProfileCreated')
        c-login-flow-reset-password(:token='resetPasswordToken' @resetFlow='resetFlow' v-if='showResetPasswordForm')
        c-login-flow-forgot-password(:initialEmail='this.email' v-if='showForgotPassword')
</template>
<script>
import { userService } from '@/_services'
import loginFlowCreateUser from '@/components/login-flow/create-user/index'
import loginFlowLogin from '@/components/login-flow/login/index'
import loginFlowForgotPassword from '@/components/login-flow/forgot-password/index'
import loginFlowResetPassword from '@/components/login-flow/reset-password/index'
import loginFlowProfileCreated from '@/components/login-flow/create-user/profile-created/index'
import { mapState, mapGetters } from 'vuex'
import { getQueryParameters } from '@/_helpers/query-parameters'

export default {
  name: 'c-login-flow',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-login-flow-create-user': loginFlowCreateUser,
    'c-login-flow-login': loginFlowLogin,
    'c-login-flow-profile-created': loginFlowProfileCreated,
    'c-login-flow-forgot-password': loginFlowForgotPassword,
    'c-login-flow-reset-password': loginFlowResetPassword
  },
  data() {
    return {
      email: '',
      showLogin: false,
      showCreate: false,
      showForgotPassword: false,
      showProfileCreated: false,
      showResetPasswordForm: false,
      resetPasswordToken: ''
    }
  },
  computed: {
    ...mapState('user', ['loginModalShowing']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('user', ['verificationFlow']),
    isFamilyMemberVerification() {
      return this.verificationFlow && this.verificationFlow.type === 'family'
    },
    loginFlowText() {
      const verificationFlowText = this.isFamilyMemberVerification
        ? this.model.pfaAuthentication.pfaFamilyLoginDescription
        : this.model.pfaAuthentication.pfaLoginDescription
      return this.verificationFlow ? verificationFlowText : this.Dictionary.LoginFlow.LoginOrCreateProfileText
    }
  },
  methods: {
    async validateUser() {
      if (this.validEmail(this.email)) {
        const response = await userService.validateUser({ email: this.email }),
        userExists = response.data === 102
        
        this.showLogin = userExists
        this.showCreate = !userExists
      }
    },
    showMessage() {
      this.showProfileCreated = true
    },
    showResetPassword() {
      this.showLogin = false
      this.showCreate = false
      this.showProfileCreated = false
      this.showForgotPassword = true
    },
    showLoginForm() {
      this.showLogin = true
      this.showCreate = false
      this.showProfileCreated = false
      this.showForgotPassword = false
      this.showResetPasswordForm = false
    },
    showCreateUser() {
      this.showLogin = false
      this.showCreate = true
      this.showProfileCreated = false
      this.showForgotPassword = false
      this.showResetPasswordForm = false
    },
    resetFlow() {
      this.email = ''
      this.showLogin = false
      this.showCreate = false
      this.showResetPasswordForm = false
      this.showForgotPassword = false
    },
    closeLoginFlow() {
      this.$store.dispatch('user/toggleLoginModal', false)
      this.resetFlow()
    }
  },
  mounted() {
    const queryParameters = getQueryParameters()
    if (queryParameters.get('returnUrl')) {
      const returnUrl = queryParameters.get('returnUrl')
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.$store.dispatch('user/authenticateAndProceed', {
        action: function () {
          window.location.replace(window.location.origin + returnUrl)
        },
        createdCtaText: this.Dictionary.LoginFlow.ProceedToRestrictedArea
      })
    }
    if (queryParameters.get('reset-password-token')) {
      this.$store.dispatch('user/toggleLoginModal', true)
      this.resetPasswordToken = queryParameters.get('reset-password-token')
      this.showResetPasswordForm = true
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
    }
    if (queryParameters.get('tokenm') || queryParameters.get('tokenf')) {
      const type = queryParameters.get('tokenm') ? 'member' : 'family'
      const token = queryParameters.get('tokenm') || queryParameters.get('tokenf')

      this.$store.dispatch('user/authenticateAndProceed', {
        token,
        type: `PFA-${type}`,
        description:
          type === 'member' ? this.model.pfaAuthentication.pfaProfileCreationConfirmation : this.model.pfaAuthentication.pfaFamilyProfileCreationConfirmation
      })
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
    }
  },
  watch: {
    loginModalShowing(newVal) {
      if (newVal && !this.showCreate && !this.showResetPasswordForm) {
        if (localStorage.userEmail) {
          this.email = localStorage.userEmail
          this.showLogin = true
        } else {
          this.resetFlow()
          const that = this
          this.$nextTick(() => {
            that.$refs.emailInput.focus()
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-flow {
  color: $white;

  &__heading {
    font-family: 'Inria Serif', serif;
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__text {
    @include setStyles('18', $s-typography-sizes);
    margin-bottom: toRem(43px);
    text-align: center;
  }
  &__start-text {
    font-family: 'Inria Serif', serif;
    @include setStyles('20', $s-typography-sizes);
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__social-text {
    @include setStyles('14', $s-typography-sizes);
    margin: 0 toRem(20px);
    text-align: center;
  }
  &__social-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: toRem(20px);
    margin-bottom: toRem(38px);
    &:before,
    &:after {
      content: '';
      height: toRem(1px);
      background-color: $white;
      flex-grow: 1;
    }
  }
  &__social-buttons a {
    @include setStyles('13', $s-typography-sizes);
    text-decoration: underline;
    color: $crab-primary;
  }
  &__social-icon {
    width: toRem(35px);
    height: toRem(35px);
    stroke: $crab-primary;
  }
  .o-input__container,
  .o-button {
    margin-bottom: toRem(20px);
  }
  .o-input__container {
    // BEGIN: ToolTip helper styles
    &.tooltip {
      $xy-border: (
        top: t,
        bottom: b,
        left: l,
        right: r
      );
      [data-tooltip] {
        $triangle: 0.3rem;
        $background-color: rgba(51, 51, 51, 0.9);
        cursor: pointer;
        display: inline-block;
        line-height: 1;
        position: relative;

        &::after {
          background-color: $background-color;
          border-radius: $triangle;
          color: #fff;
          content: attr(data-tooltip);
          font-size: 1rem;
          font-size: 85%;
          font-weight: normal;
          line-height: 1.15rem;
          opacity: 0;
          padding: 0.25rem 0.5rem;
          position: absolute;
          text-align: center;
          text-transform: none;
          transition: opacity 0.2s;
          visibility: hidden;
          white-space: nowrap;
          z-index: 1;
        }

        @each $direction, $d in $xy-border {
          &.tooltip-#{$direction} {
            // arrows
            &::before {
              border-style: solid;
              border-width: $triangle;
              content: '';
              opacity: 0;
              position: absolute;
              transition: opacity 0.2s;
              visibility: hidden;
              @if $direction == top {
                border-color: $background-color transparent transparent transparent;
                top: 0;
                left: 50%;
                margin-left: -$triangle;
              } @else if $direction == bottom {
                border-color: transparent transparent $background-color transparent;
                bottom: 0;
                left: 50%;
                margin-left: -$triangle;
              } @else if $direction == left {
                border-color: transparent transparent transparent $background-color;
                top: $triangle;
                right: calc(110% - #{$triangle});
                margin-top: -$triangle;
              } @else if $direction == right {
                border-color: transparent $background-color transparent transparent;
                top: 50%;
                top: $triangle;
                left: calc(110% - #{$triangle});
                margin-top: -$triangle;
              }
            }

            &::after {
              @if $direction == top {
                bottom: 100%;
                left: 50%;
                transform: translate(-50%);
              } @else if $direction == bottom {
                top: 100%;
                left: 50%;
                transform: translate(-50%);
              } @else if $direction == left {
                top: -$triangle;
                right: calc(110% + #{$triangle});
              } @else if $direction == right {
                top: -$triangle;
                left: calc(110% + #{$triangle});
              }
            }
          }
        }

        &.tooltip-mobile {
          @media (max-width: 767px) {
            &::before {
              display: none;
            }

            &:after {
              font-size: 1rem;
              max-width: 20rem;
              position: fixed;
              bottom: auto;
              top: 50%;
              left: 50%;
              text-align: left;
              transform: translate(-50%);
              white-space: normal;
            }
          }
        }

        &:hover::after,
        &[class*='tooltip-']:hover::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  // END: ToolTip helper styles
}
</style>
