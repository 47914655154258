/* eslint-disable prettier/prettier */
<template lang="pug">
.c-cm-overview
  .c-cm-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-cm-overview__button(:title='Dictionary.N.NewCompany' @click='createNew') {{ Dictionary.N.NewCompany }}
  keep-alive
    c-cm-search(:model='model')
  .c-cm-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-cm-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettingsData', :rows='companies' ref='table' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='!props.row.managedExternally')
                button.c-state-action(@click='handleDelete(props.row.id)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Delete }}

        template(v-else-if='props.column.field == "logo"')
          .c-cm-overview__item--logo(
            :key='props.row.updated',
            :style='{ "background-image": props.row.logo || props.row.organizationLogo ? "url(" + (props.row.logo || props.row.organizationLogo) + ")" : false }'
          )
        template(v-else-if='props.column.field == "name"')
          button.c-cm-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ props.row.name }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'

import CompaniesManagementSearch from '../cm-search'

import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-cm-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      columns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'number',
          type: 'number'
        },
        {
          label: this.Dictionary.L.Logo,
          sortable: false,
          field: 'logo',
          width: '150px'
        },
        {
          label: this.Dictionary.C.Cvr,
          field: 'cvr',
          type: 'number'
        },
        {
          label: this.Dictionary.C.CompanyName,
          field: 'name',
          sortFn: this.tableSortAlphabeticaly
        },
        {
          label: this.Dictionary.O.Organization,
          field: 'organization'
        },
        {
          label: this.Dictionary.R.RentModel,
          field: 'rentModel',
          formatFn: this.getRentModel
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-cm-search': CompaniesManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('companiesManagement', ['companies', 'page', 'pageSize', 'totalResults']),
    ...mapState('search', ['organizations']),
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    companyOptions() {
      return JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
    }
  },
  methods: {
    getRentModel(key) {
      return this.Dictionary.RentModels[key]
    },
    setSelected(id) {
      this.getInitialData()
      this.$store.dispatch('companiesManagement/setSelected', id)
    },
    createNew() {
      this.getInitialData()
      this.$store.dispatch('companiesManagement/createNew')
    },
    handleDelete(id) {
      this.$store.dispatch('companiesManagement/toggleDeleteConfirmation', id)
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    getInitialData() {
      if (this.organizations.length === 0) {
        this.$store.dispatch('search/getOrganizations')
      }
    }
  },
  watch: {
    totalResults(newVal) {
      if (newVal === 0) {
        this.$refs.table.currentPerPage = 10
        this.$refs.table.currentPage = 1
      }
    }
  },
  created() {
    this.$store.dispatch('companiesManagement/searchManagedCompanies', {})
  }
}
</script>
<style lang="scss" scoped>
.c-cm-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
    &--logo {
      width: 100%;
      height: 40px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>
