import { ub4SumCodesManagementService } from '@/_services'

const initialState = {
  sumcodes: [],
  selected: undefined,
  selectedCompanyId: undefined,
  selectedSumCodeNumber: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmationTarget: undefined
}

const initialSelected = {
  id: undefined
}

export const ub4SumCodesManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  actions: {
    async setSelected({ commit }, { id, companyId }) {
      // TODO in another story
      if (id) {
        try {
          const response = await ub4SumCodesManagementService.getUser(id)
          if (response && response.status === 200) {
            response.data.companyId = companyId
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    createNew({ commit }) {
      commit('createNew')
    },
    async getUb4SumCodes({ commit }) {
      try {
        const responseSumCodes = await ub4SumCodesManagementService.getUb4SumCodes()
        if (responseSumCodes && responseSumCodes.status === 200) {
          commit('updateData', {
            sumcodes: responseSumCodes.data
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async postUb4SumCode({ commit }, { data }) {
      try {
        const response = await ub4SumCodesManagementService.postUb4SumCode(data)
        if (response && response.status === 204) {
          this.dispatch('ub4SumCodesManagement/getUb4SumCodes')
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteMapping({ commit }, payload) {
      try {
        const response = await ub4SumCodesManagementService.deleteUb4SumCode(payload)
        if (response && response.status === 204) {
          this.dispatch('ub4SumCodesManagement/getUb4SumCodes')
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    }
  }
}
