<template lang="pug">
.c-create-user
  c-notification-inline.c-create-user__error.o-bodytext.o-bodytext--size-small(:canClose='false' size='small' type='error' v-if='displayTermsError') {{ Dictionary.LoginFlow.ReadTermsAndConditions }}
  c-fc-email.c-create-user__input(:model='logInData.email' @validate='validate($event, "email")' v-model='logInData.email.value')
  c-fc-password.c-create-user__input(
    :model='logInData.password'
    @validate='validate($event, "password")'
    ref='createPasswordInputxt'
    v-if='!isFamilyMemberVerification'
    v-model='logInData.password.value'
  )
  .c-create-user__data.o-grid.o-grid--gutter-tiny(v-if='verificationFlow')
    .o-grid__group
      .o-grid__item.o-grid__item--12
        c-fc-text(:model='userData.firstName' @validate='validate($event, "firstName")' v-model='userData.firstName.value')
      .o-grid__item.o-grid__item--12
        c-fc-text(:model='userData.lastName' @validate='validate($event, "lastName")' v-model='userData.lastName.value')
      .o-grid__item.o-grid__item--12
        c-fc-text(:model='userData.address' @validate='validate($event, "address")' v-model='userData.address.value')
      .o-grid__item.o-grid__item--12
        c-fc-number(:model='userData.zipCode' @validate='validate($event, "zipCode")' v-model='userData.zipCode.value')
      .o-grid__item.o-grid__item--12
        c-fc-text(:model='userData.city' @validate='validate($event, "city")' v-model='userData.city.value')
      .o-grid__item.o-grid__item--12
        c-fc-select(:model='countryCodeData' @validate='validate($event, "countryCode")' v-model='userData.countryCode.value')
      .o-grid__item.o-grid__item--4
        c-fc-select(:model='phoneCodeData' @validate='validate($event, "phoneCode")' v-model='userData.phoneCode.value')
      .o-grid__item.o-grid__item--8
        c-fc-number(:model='userData.phoneNoForSms' @validate='validate($event, "phoneNoForSms")' v-model='userData.phoneNoForSms.value')
      .o-grid__item.o-grid__item--12
        c-fc-number(:model='userData.landlinePhone' @validate='validate($event, "landlinePhone")' v-model='userData.landlinePhone.value')
  .o-input__container.c-create-user__input
    .o-input__wrap
      input#createCheckForTermsRead.o-input(@change='createCheckForTermsRead' name='checkbox' placeholder=' ' type='checkbox')
      label.o-label.c-create-user__checkbox-label(for='createCheckForTermsRead')
        span {{ Dictionary.LoginFlow.IAccept }} &nbsp;
        a(
          :href='model.termsAndConditions.url',
          :target='model.termsAndConditions.target'
          @click='displayTermsError = false; termsRead = true'
          v-if='model && model.termsAndConditions'
        ) {{ model.termsAndConditions.text }}
  .c-create-user__submit
    button.c-create-user__button.o-button.o-button--inverted.o-button--full-width(:disabled='!this.formIsValid' @click='createUser()') {{ Dictionary.LoginFlow.CreateUser }}
    button.c-create-user__button.login__link.o-link.o-link--500.o-link--opensans.o-link--crab(@click='$emit("resetFlow")' type='button') {{ Dictionary.LoginFlow.LoginWithDifferentUser }}
</template>
<script>
import FormControllText from '@/components/form/controlls/form-text'
import FormControllEmail from '@/components/form/controlls/form-email'
import FormControllPassword from '@/components/form/controlls/form-password'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllSelect from '@/components/form/controlls/form-select'
import { mapGetters } from 'vuex'
import { userService } from '@/_services'
export default {
  name: 'c-create-user',
  data() {
    return {
      accept: false,
      termsRead: false,
      displayTermsError: false,
      validation: {},
      userCountries: [],
      logInData: {
        email: {
          helperText: '',
          placeholderText: this.Dictionary.E.Email,
          value: this.initialEmail || null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            unique: true
          }
        },
        password: {
          helperText: this.Dictionary.LoginFlow.PasswordHelperText,
          placeholderText: this.Dictionary.P.Password,
          value: null,
          isMandatory: true,
          readOnly: false,
          focus: true,
          validation: {
            errorMessage: '',
            displayRule: ''
          }
        }
      },
      userData: {
        firstName: {
          helperText: '',
          placeholderText: this.Dictionary.F.FirstName,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        lastName: {
          helperText: '',
          placeholderText: this.Dictionary.L.LastName,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        address: {
          helperText: '',
          placeholderText: this.Dictionary.A.Address,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        zipCode: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PostalCode,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1000,
            maxValue: 9999
          }
        },
        city: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.C.City,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        countryCode: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.C.Country,
          isMandatory: false,
          readOnly: false,
          options: [],
          validation: null
        },
        phoneCode: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PhoneCode,
          isMandatory: false,
          readOnly: false,
          options: [],
          validation: null
        },
        phoneNoForSms: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PhoneMobile,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{8}',
            displayRule: '',
            minValue: 10000000,
            maxValue: 99999999
          }
        },
        landlinePhone: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PhoneSecondary,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{8}',
            displayRule: '',
            minValue: 10000000,
            maxValue: 99999999
          }
        }
      }
    }
  },
  props: {
    initialEmail: String,
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-email': FormControllEmail,
    'c-fc-password': FormControllPassword,
    'c-fc-number': FormControllNumber,
    'c-fc-select': FormControllSelect
  },
  computed: {
    ...mapGetters('user', ['verificationFlow']),
    isFamilyMemberVerification() {
      return this.verificationFlow && this.verificationFlow.type === 'family'
    },
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true) && this.accept
    },
    countryCodeData() {
      const countryCodeData = Object.assign({}, this.userData.countryCode)
      countryCodeData.options = this.userCountries.map(country => {
        return { value: country.id, name: country.name }
      })
      return countryCodeData
    },
    phoneCodeData() {
      const phoneCodeData = Object.assign({}, this.userData.phoneCode)
      phoneCodeData.options = this.userCountries.map(country => {
        return { value: country.phoneCode, name: country.phoneCode }
      })
      return phoneCodeData
    }
  },
  methods: {
    async createUser() {
      if (this.formIsValid) {
        const createData = {
          email: this.logInData.email.value,
          culture: 'da-DK'
        }

        if (this.isFamilyMemberVerification) {
          createData.randomPassword = true
        } else {
          createData.password = this.logInData.password.value
        }
        if (this.verificationFlow) {
          this.$store.dispatch('user/createValidatedUser', {
            createData,
            userData: this.getUserData(),
            callback: this
          })
        } else {
          this.$store.dispatch('user/createUser', {
            createData,
            callback: this
          })
        }
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getUserData() {
      const residenceData = {}
      Object.keys(this.userData).forEach(key => {
        if (this.userData[key].value && this.userData[key].value !== '') {
          residenceData[key] = this.userData[key].value
        }
      })
      return residenceData
    },
    createCheckForTermsRead: function (e) {
      if (!this.termsRead) {
        e.target.checked = false
        this.displayTermsError = true
        return
      }

      this.accept = true
      this.displayTermsError = false
    },
    async getCountryData() {
      try {
        const countryResponse = await userService.getCountries()
        if (countryResponse && countryResponse.status === 200) {
          this.userCountries = countryResponse.data
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mounted() {
    if (this.userCountries.length === 0) {
      this.getCountryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.c-create-user {
  &__input,
  &__button,
  &__data {
    & + & {
      margin-top: toRem(10px);
    }
  }

  &__submit {
    margin-top: toRem(20px);
  }

  .login__link {
    @include setStyles('14', $s-typography-sizes);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__checkbox-label,
  &__checkbox-label a {
    color: $white;
  }
  &__label-helper {
    text-align: left;
    color: $ocean-4;
    @include setStyles('13', $s-typography-sizes);
    margin: toRem(5px) 0 toRem(10px);
  }

  &__error {
    margin-bottom: toRem(15px);
  }
}
</style>
