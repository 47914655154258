<template lang="pug">
.c-property-hero
  .c-property-hero__main(v-bind:style='{ "background-image": `url(${media.length && media[0].type != "video" ? media[0].url : ""})` }')
    button.c-property-hero__main-button.o-button.o-button--small.o-button--secondary(@click='toggleHeroModal(heroModalSlide, true)')
      span.o-bodytext.o-bodytext--italic.o-bodytext--inria {{ heroCtaText }}
  c-modal.c-modal--hero.c-property-hero(:outsideClose='false' @close='toggleHeroModal(heroModalSlide, false)' v-if='heroModalShowing')
    .c-property-hero__head(slot='header')
      .c-property-hero__tabs.o-grid.o-grid--justify-content-start.o-grid--gutter-small.o-grid--wrap-nowrap
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0(:key='`hero-carousel-tab-${tabKey}`' v-for='(tabItem, tabKey) in tabs')
            button.c-property-hero__tabs-item(
              :class='{ "c-property-hero__tabs-item--current": isWithinType(tabKey) }'
              @click='setNewSlideFromNav(tabItem.index)'
            )
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${typeIcon(tabItem.type)}`' x='0' y='0')
              span.c-property-hero__tabs-text {{ `${typeText(tabItem.type)}` }}
    .c-property-hero__body(slot='body' v-if='!isMobile')
      VueSlickCarousel.c-property-hero__carousel(
        :draggable='false',
        :fade='true',
        :initialSlide='initialSlide'
        @beforeChange='setNewSlideFromCarousel'
        @init='initCarousel'
        ref='carousel'
      )
        .c-property-hero__carousel-item(:key='`hero-carousel-item-${mediaKey}`' v-for='(mediaItem, mediaKey) in media')
          .c-property-hero__carousel-media--panorama(v-if='mediaItem.type === "panoramas360"')
            img(:src='mediaItem.url')
          .c-property-hero__carousel-media(
            :style='{ backgroundImage: `url(${mediaItem.url})` }'
            v-else-if='mediaItem.type === "picture" || mediaItem.type === "blueprint"'
          )
          .c-property-hero__carousel-media(v-else-if='mediaItem.type === "panoramas"')
            Pano.c-property-hero__carousel-vr(:source='mediaItem.url' v-if='isFile(mediaItem.url)')
            iframe.c-property-hero__carousel-vr(:data-consent-src='mediaItem.url' data-category-consent='cookie_cat_functional' src='' v-else)
            .consent-placeholder(data-category='cookie_cat_functional' onClick='CookieConsent.renew()')
              span {{ Dictionary.Cookies.CookieInformationFunctionalConsentNeeded }}
          .c-property-hero__carousel-media(v-else-if='mediaItem.type === "video"')
            c-video.c-property-hero__carousel-video(:url='mediaItem.url')
        template(#prevArrow='')
          button.c-property-hero__carousel-arrow.c-property-hero__carousel-arrow--prev(
            :aria-label='Dictionary.P.Previous'
            @click.stop='focusAfter'
            data-role='none'
            type='button'
          )
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-left' y='0')
        template(#nextArrow='')
          button.c-property-hero__carousel-arrow.c-property-hero__carousel-arrow--next(
            :aria-label='Dictionary.N.Next'
            @click.stop='focusAfter'
            data-role='none'
            type='button'
          )
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-right' y='0')
      ul.c-property-hero__navigation(ref='navigation')
        li.c-property-hero__navigation-item(
          :class='{ "c-property-hero__navigation-item--current": heroModalSlide === navigationKey }',
          :key='`hero-carousel-item-${navigationKey}`'
          v-for='(navigationItem, navigationKey) in media'
        )
          button(
            :aria-label='`${navigationKey} ${navigationItem.type}`',
            :style='{ backgroundImage: `url(${navigationItem.url})` }'
            @click='setNewSlideFromNav(navigationKey)'
            v-if='navigationItem.type != "video" && navigationItem.type != "panoramas" && navigationItem.type != "panoramas360"'
          )
          button(
            :aria-label='`${navigationKey} ${navigationItem.type}`',
            :style='{ background: `linear-gradient(0deg, rgba(44,72,86,1) 0%, rgba(255,255,255,1) 100%)` }'
            @click='setNewSlideFromNav(navigationKey)'
            v-if='navigationItem.type != "picture" && navigationItem.type != "blueprint"'
          )
            svg.o-svg-icon(type='image/svg+xml' v-if='navigationItem.type !== "picture"' viewbox='0 0 64 64')
              use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${typeIcon(navigationItem.type)}`' x='0' y='0')
    .c-property-hero__body(slot='body' v-else)
      .c-property-hero__mobile-carousel(ref='mobileCarousel')
        .c-property-hero__mobile-carousel-item(
          :class='{ "c-property-hero__mobile-carousel-item--panorama": mediaItem.type === "panoramas360" }',
          :key='`hero-mobile-carousel-item-${mediaKey}`'
          v-for='(mediaItem, mediaKey) in media'
        )
          img(:src='mediaItem.url' v-if='mediaItem.type === "picture" || mediaItem.type === "blueprint" || mediaItem.type === "panoramas360"')
          template(v-else-if='mediaItem.type === "panoramas"')
            Pano.c-property-hero__mobile-carousel-vr(:source='mediaItem.url' v-if='isFile(mediaItem.url)')
            iframe.c-property-hero__mobile-carousel-vr(:data-consent-src='mediaItem.url' data-category-consent='cookie_cat_functional' src='' v-else)
            .consent-placeholder(data-category='cookie_cat_functional' onClick='CookieConsent.renew()')
              span {{ Dictionary.Cookies.CookieInformationFunctionalConsentNeeded }}
          .c-property-hero__mobile-carousel-video(v-else-if='mediaItem.type === "video"')
            c-video(:url='mediaItem.url' aspect-ratio='16/9')
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { Pano } from 'vuejs-vr'

import { mapState } from 'vuex'
export default {
  name: 'c-property-hero',
  components: { VueSlickCarousel, Pano },
  data() {
    return {
      id: this._uid,

      carouselReady: false,
      initialSlide: 0
    }
  },
  props: {
    media: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('property', ['heroModalShowing', 'heroModalSlide', 'heroModalTabs']),
    tabs() {
      const tabs = []
      this.media.forEach((mediaItem, index) => {
        const typeExists = tabs.some(tabItem => tabItem.type === mediaItem.type)

        if (!typeExists) {
          tabs.push({ type: mediaItem.type, index })
        }
      })

      return tabs
    },
    heroCtaText() {
      const hasBlueprint = this.tabs.some(tabItem => tabItem.type === 'blueprint')
      const hasVideo = this.tabs.some(tabItem => tabItem.type === 'video')
      const hasPanorama = this.tabs.some(tabItem => tabItem.type === 'panoramas')
      const text = hasBlueprint
        ? ` & ${this.Dictionary.B.Blueprints.toLowerCase().trim()}`
        : hasVideo
        ? ` & ${this.Dictionary.V.Video.toLowerCase().trim()}`
        : hasPanorama
        ? ` & ${this.Dictionary.Numbers['360deg']}`
        : ''
      return `+ ${this.Dictionary.S.SeeMore} ${this.Dictionary.P.Pictures.toLowerCase().trim()}${text}`
    }
  },
  methods: {
    getSrcForPoster(url) {
      const id = url.split('/embed/').pop()
      return `https://img.youtube.com/vi/${id}/0.jpg`
    },
    toggleHeroModal(slide, showModal = true) {
      // re-load cookieinformation consents for video iframe src tag replacement by sdk
      window.CookieInformation.loadConsent()
      this.$store.dispatch('property/toggleHeroModal', { showing: showModal, slide })
      if (this.isMobile && showModal) {
        this.$nextTick(() => {
          this.alignMobileCarousel()
        })
      } else if (showModal) {
        this.$nextTick(() => {
          this.$refs.carousel.goTo(this.heroModalSlide)
          this.alignNavigation()
        })
      }
    },
    setNewSlide(newSlide) {
      this.$store.commit('property/setHeroModalSlide', newSlide)
    },
    setNewSlideFromCarousel(oldSlide, newSlide) {
      if (this.heroModalSlide === newSlide) return

      this.setNewSlide(newSlide)
      this.alignNavigation()
    },
    setNewSlideFromNav(newSlide) {
      this.setNewSlide(newSlide)

      if (this.isMobile) {
        this.alignMobileCarousel()
      } else {
        this.alignNavigation()
        this.$refs.carousel.goTo(this.heroModalSlide)
      }
    },
    initCarousel() {
      // re-load cookieinformation consents for video iframe src tag replacement by sdk
      window.CookieInformation.loadConsent()
      this.carouselReady = true
      this.$nextTick(() => {
        this.alignNavigation()
      })
    },
    alignNavigation() {
      const navigation = this.$refs.navigation
      const navigationCenter = navigation.offsetWidth / 2
      const activeItem = navigation.children[this.heroModalSlide]
      const activeItemPossitionLeft = activeItem.offsetLeft
      const activeItemCenter = activeItemPossitionLeft + activeItem.offsetWidth / 2
      if (activeItemCenter !== navigationCenter) {
        navigation.scrollLeft = activeItemCenter - navigationCenter
      }
    },
    alignMobileCarousel() {
      const mobileCarousel = this.$refs.mobileCarousel
      const mobileCarouselCenter = mobileCarousel.offsetWidth / 2
      const activeItem = mobileCarousel.children[this.heroModalSlide]
      const activeItemPossitionLeft = activeItem.offsetLeft
      const activeItemPossitionTop = activeItem.offsetTop
      const activeItemCenter = activeItemPossitionLeft + activeItem.offsetWidth / 2
      const horizontal =
        window.screen &&
        ((window.screen.orientation && window.screen.orientation.type === 'landscape-primary') || window.innerHeight > window.innerWidth) &&
        activeItemCenter !== mobileCarouselCenter
      if (horizontal) {
        mobileCarousel.scrollLeft = activeItemCenter - mobileCarouselCenter
      } else {
        mobileCarousel.scrollTop = activeItemPossitionTop - 75
      }
    },
    typeText(type) {
      let text = ''
      switch (type) {
        case 'picture':
          text = this.Dictionary.P.Pictures
          break
        case 'panoramas':
          text = this.Dictionary.P.Panorama
          break
        case 'panoramas360':
          text = this.Dictionary.P.Panorama360
          break
        case 'video':
          text = this.Dictionary.V.Video
          break
        case 'blueprint':
          text = this.Dictionary.F.FloorPlan
          break
      }
      return text
    },
    typeIcon(type) {
      let icon = ''
      switch (type) {
        case 'picture':
          icon = 'image'
          break
        case 'panoramas':
          icon = '360-view'
          break
        case 'panoramas360':
          icon = '360-view'
          break
        case 'video':
          icon = 'circle-play'
          break
        case 'blueprint':
          icon = 'floorplan'
          break
      }
      return icon
    },
    isWithinType(key) {
      const nextTypeStart = key === this.tabs.length - 1 ? key : key + 1
      const slideIsWithinType = this.heroModalSlide >= this.tabs[key].index && this.heroModalSlide < this.tabs[nextTypeStart].index
      const slideisWithinLastType = key === this.tabs.length - 1 && this.heroModalSlide >= this.tabs[this.tabs.length - 1].index
      return slideIsWithinType || slideisWithinLastType
    }
  },
  mounted() {
    this.initialSlide = this.heroModalSlide
    this.$store.dispatch('property/setHeroModalTabs', this.tabs)
  }
}
</script>
<style lang="scss" scoped>
.c-property-hero {
  .slick-slide,
  .slick-slide * {
    outline: none;
    pointer-events: none;
  }

  .slick-current,
  .slick-current * {
    pointer-events: all;
    z-index: 1;
  }

  &__tabs {
    &-text {
      font-family: 'Inria Serif', serif;
      @include setStyles('13', $s-typography-sizes);
      color: $ocean-darker;
      font-weight: 700;
      @include min('desktop') {
        @include setStyles('16', $s-typography-sizes);
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      border: none;
      background: none;
      border-radius: 0;
      padding: 5px;

      @include min('desktop') {
        flex-direction: row;
      }

      svg {
        width: toRem(24px);
        height: toRem(24px);

        @include min('desktop') {
          margin-right: 10px;
        }
      }

      &--current {
        background-color: $grey-5;
      }
    }
  }

  &__head {
    max-width: 80%;
  }

  &__main {
    width: 100%;
    padding-top: 42.7%; // 1440/615 aspect
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
    }
  }

  &__carousel {
    width: 100%;

    &-item {
      img {
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
      }
    }

    &-media {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: calc(100vh - 200px);
      max-height: 75vw;

      .consent-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: $crab-primary;
        background-color: $ocean-primary;
        cursor: pointer;
        padding: toRem(20px);
        border: 1px dotted $crab-primary;
        text-align: center;
        z-index: z('elements');
        span {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &--panorama {
        overflow: auto;
        position: relative;
        scroll-snap-type: x mandatory;

        img {
          position: relative;
          width: auto;
          max-width: unset;
          scroll-snap-align: center;
        }
      }
    }

    &-video,
    &-vr {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: z('elements');
    }

    &-arrow {
      position: relative;
      border: 2px solid transparent;
      height: toRem(52px);
      width: toRem(52px);
      margin: 0;
      padding: 0;
      border-radius: toRem(25px);
      background-color: $ocean-darker;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;
      z-index: 1;
      transition: opacity 0.5s ease;

      &:focus {
        border: 2px solid $crab-darker;
        outline: none;
      }

      &--prev {
        left: toRem(24px);
      }
      &--next {
        right: toRem(24px);
      }

      svg {
        position: absolute;
        fill: $white;
        width: 65%;
        height: 65%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }
  }

  &__mobile-carousel {
    width: 100%;
    max-height: calc(100vh - 75px);
    overflow: auto;

    @include max('desktop', 0, 'landscape') {
      display: flex;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
    }

    &-item {
      position: relative;
      z-index: 1;
      overflow: auto;

      img {
        width: 100%;
        display: block;
      }

      &--panorama {
        position: relative;
        z-index: 1;
        overflow: auto;

        img {
          width: auto;
          max-height: 25vh;
          display: block;

          @include max('desktop', 0, 'landscape') {
            max-height: calc(100vh - 75px);
          }
        }
      }

      & + & {
        @include max('desktop', 0, 'portrait') {
          margin-top: 8px;
        }

        @include max('desktop', 0, 'landscape') {
          margin-left: 8px;
        }
      }

      @include max('desktop', 0, 'landscape') {
        flex: 0 0 auto;
        scroll-snap-align: center;
        margin: 0;

        img {
          width: auto;
          margin: 0 auto;
          height: calc(100vh - 75px);
        }
      }

      iframe {
        height: auto;
      }
    }

    &-video,
    &-vr {
      width: 100%;
      height: 56.25vw;
      display: inline-block;
      @include max('desktop', 0, 'landscape') {
        width: calc((100vh - 75px) * 100 / 56.25);
        height: calc(100vh - 75px);
      }
    }
  }

  &__navigation {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    &-item {
      flex: 0 0 9.5%;
      padding: 4px 3px;
      position: relative;

      svg {
        background-color: $white;
        width: 32px;
        height: 32px;
        padding: 5px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: z('elements');
      }

      button {
        width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 0;
        display: block;
        padding-top: 56.25%; //16:9
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: outline-color 500ms ease-in-out;
        outline: 4px solid transparent;
        outline-offset: 0;

        &:focus {
          outline-color: $ocean-primary;
        }
      }

      &--current button {
        outline-color: $crab-darker;
      }
    }
  }

  .c-modal__close {
    position: absolute;
  }
}
</style>
