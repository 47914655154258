<template lang="pug">
.c-om-overview
  .c-om-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  keep-alive
    c-om-search(:model='model' v-if='showSearch')
  .c-om-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-om-overview__data
    vue-good-table(
      :columns='columns',
      :pagination-options='paginationSettingsData',
      :rows='offers',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-sort-change='onSortChange'
      mode='remote'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "answers"')
          span.c-om-overview__item--answers.o-bodytext.o-bodytext--size-small {{ formatAnswers(props.row) }}
        template(v-if='props.column.field == "selectableAddress"')
          button.c-om-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ props.row.residenceAddress }}
        template(v-if='props.column.field == "actions"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='props.row.hasUnreadMessages')
                .c-om-overview__mail-icon
                  svg.o-svg-icon.o-svg-icon--table(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
                  .c-om-overview__unread-count {{ props.row.unreadMessagesCount }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import OffersManagementSearch from '../om-search'
import { getQueryParameters } from '@/_helpers/query-parameters'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-om-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      showSearch: false,
      offerFromResidenceId: getQueryParameters().get('offerFromResidenceId') || undefined,
      offerId: getQueryParameters().get('offerId') || undefined,
      columns: [
        {
          label: `${this.Dictionary.O.Offer} ${this.Dictionary.N.NumberShort}`,
          field: 'number',
          type: 'number',
          width: '70px'
        },
        {
          label: `${this.Dictionary.R.Residence} ${this.Dictionary.N.NumberShort}`,
          field: 'companyPropertyResidenceNumbersId',
          width: '40px',
          sortable: false
        },
        {
          label: this.Dictionary.A.Address,
          field: 'selectableAddress',
          width: '200px'
        },
        {
          label: this.Dictionary.A.AvailableFrom,
          field: 'availableFrom',
          formatFn: this.formatTheDate,
          width: '90px'
        },
        {
          label: this.Dictionary.C.Created,
          field: 'created',
          formatFn: this.formatTheDate,
          width: '90px'
        },
        {
          label: this.Dictionary.R.Replies,
          field: 'answers',
          width: '70px'
        },
        {
          label: this.Dictionary.R.ResponseDeadline,
          field: 'deadline',
          formatFn: this.formatTheDate,
          width: '90px'
        },
        {
          label: this.Dictionary.F.FollowUpDate,
          field: 'followUp',
          formatFn: this.formatTheDate,
          width: '90px'
        },
        {
          label: this.Dictionary.S.Status,
          field: 'state',
          sortable: false,
          formatFn: this.getStateLabel
        },
        {
          label: this.Dictionary.R.Responsible,
          field: 'responsibleUserId',
          formatFn: this.reFormatResponsible,
          width: '150px',
          sortable: false
        },
        {
          label: this.Dictionary.A.Action,
          field: 'actions',
          sortable: false
        }
      ]
    }
  },
  components: {
    'c-om-search': OffersManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('offersManagement', ['offers', 'page', 'pageSize', 'totalResults', 'serverParams']),
    ...mapState('search', ['companies', 'organizations']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    companyOptions() {
      return JSON.parse(JSON.stringify(this.offers)).map(company => {
        company.value = company.id
        return company
      })
    }
  },
  methods: {
    setSelected(id) {
      this.getInitialData()
      this.$store.dispatch('offersManagement/setSelected', id)
    },
    reFormatResponsible(responsibleId) {
      const responsibleUser = this.allManagedUsers.find(user => user.id === responsibleId)
      return responsibleUser && responsibleUser.email.split('@')[0]
    },
    formatAnswers(item) {
      const total = item.recipients.length,
        responded = item.recipients.filter(r => r.state !== 'OfferReceived').length

      return `${responded} (${total})`
    },
    formatTheDate(value) {
      if (value === '0001-01-01T00:00:00Z') return undefined
      return value ? this.formatDate(value) : ''
    },
    getStateLabel(key) {
      return this.getOfferStates()[key] || key
    },
    getInitialData() {
      // if (this.companies.length === 0) {
      //   this.$store.dispatch('search/getCompanies')
      // }
    },
    onPageChange(params) {
      this.$store.commit('offersManagement/updateParams', { page: params.currentPage })
    },
    onPerPageChange(params) {
      this.$store.commit('offersManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      let field

      switch (params[0].field) {
        case 'answers':
          field = 'recipientsCount'
          break
        case 'selectableAddress':
          field = 'residenceAddress'
          break

        default:
          field = params[0].field
          break
      }

      this.$store.commit('offersManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('offersManagement/updateParams', params)
    }
  },
  created() {
    if (this.organizations.length === 0) {
      this.$store.dispatch('search/getOrganizations')
    }
    if (this.offerFromResidenceId) {
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.$store.dispatch('offersManagement/createNew', this.offerFromResidenceId)
    } else if (this.offerId) {
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.setSelected(this.offerId)
    } else {
      this.showSearch = true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-om-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__mail-icon {
    position: relative;
    width: auto;
    display: inline-block;
  }

  &__unread-count {
    background-color: $ocean-primary;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-20%) translateX(5px);
    color: $white;
    @include setStyles('12', $s-typography-sizes);
    border-radius: toRem(8px);
    padding: toRem(0px 5px);
    min-width: toRem(15px);
  }
  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
    &--answers {
      white-space: nowrap;
    }
    &--logo {
      width: 100%;
      height: 40px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>
