const propertyEl = document.querySelector('[data-property-id]')
const propertyId = (propertyEl && propertyEl.getAttribute('data-property-id')) || undefined

export const property = {
  namespaced: true,
  state: {
    propertyId,
    heroModalShowing: false,
    heroModalSlide: 0,
    heroModalTabs: []
  },
  actions: {
    toggleHeroModal({ commit }, payload) {
      commit('toggleHeroModal', payload)
    },
    setHeroModalTabs({ commit }, payload) {
      commit('setHeroModalTabs', payload)
    },
    toggleHeroModalToTab({ commit, state }, payload) {
      const tab = state.heroModalTabs.find(tab => tab.type === payload)
      commit('toggleHeroModal', { showing: true, slide: tab.index })
    }
  },
  mutations: {
    toggleHeroModal(state, payload) {
      if (payload) {
        state.heroModalShowing = payload.showing
        state.heroModalSlide = payload.slide
        state.heroModalTabs = Object.assign(state.heroModalTabs, payload.tabs)
      } else {
        state.heroModalShowing = !state.heroModalShowing
      }
    },
    setHeroModalTabs(state, payload) {
      state.heroModal = Object.assign(state.heroModalTabs, payload)
    },
    setHeroModalSlide(state, payload) {
      state.heroModalSlide = payload
    }
  }
}
