<template lang="pug">
button.c-state-action(@click='actionCliked' v-if='!requiresAuthentication')
  slot
    svg.o-svg-icon(type='image/svg+xml' v-if='icon' viewbox='0 0 42 34')
      use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${icon}`' x='0' y='0')
    span.o-bodytext.o-bodytext--size-medium.o-bodytext--700.o-bodytext--inria(v-if='text') {{ text }}
button.c-state-action(v-else v-requiresAuthentication:click.stop='{ action: actionCliked }')
  slot
    svg.o-svg-icon(type='image/svg+xml' v-if='icon' viewbox='0 0 42 34')
      use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${icon}`' x='0' y='0')
    span.o-bodytext.o-bodytext--size-medium.o-bodytext--700.o-bodytext--inria(v-if='text') {{ text }}
</template>
<script>
export default {
  name: 'c-state-action',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    store: {
      required: true
    },
    action: {
      required: true
    },
    payload: {},
    text: {
      type: String
    },
    icon: {
      type: String
    },
    requiresAuthentication: {
      type: Boolean
    }
  },
  computed: {},
  methods: {
    actionCliked() {
      let payload = this.payload
      if (this.isJson(this.payload)) payload = JSON.parse(this.payload)
      this.$store.dispatch(`${this.store}/${this.action}`, payload)
    }
  }
}
</script>
<style lang="scss">
.c-state-action {
  display: flex;
  text-decoration: underline;
  background: transparent;
  border: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  svg {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }
}
</style>
