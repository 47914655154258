import apiClient from '@/_helpers/api-client'
export const applicantsManagementService = {
  searchApplicants,
  imitateApplicant,
  getApplicantStatus,
  patchApplicantStatus,
  getApplicantsParameters,
  getAwardedWaitingList,
  getOfferRecipients,
  getAdvertApplicants
}

async function searchApplicants(data) {
  if (data.filters) {
    if (data.filters.companyId) {
      data.filters.companyIds = [data.filters.companyId]
      delete data.filters.companyId
    }
    if (data.filters.verifiedIn) {
      data.search =
        data.search !== undefined && data.search !== null && data.search !== '' ? `${data.search} & ${data.filters.verifiedIn}` : data.filters.verifiedIn
      delete data.filters.verifiedIn
    }
  }
  return await apiClient({
    url: '/search/applicants',
    method: 'POST',
    data
  })
}
async function imitateApplicant(id) {
  return await apiClient({
    url: `/authentication/impersonation/impersonate/${id}`,
    method: 'POST'
  })
}
async function getApplicantStatus(id) {
  return await apiClient({
    url: `/data/management/applicant-status/${id}`,
    method: 'GET'
  })
}
async function patchApplicantStatus({ id, data }) {
  return await apiClient({
    url: `/data/management/applicant-status/${id}`,
    method: 'PATCH',
    data
  })
}
async function getApplicantsParameters({ userId, moId }) {
  return await apiClient({
    url: `/management/parameters/${userId}/membership-organizations/${moId}`,
    method: 'GET'
  })
}

async function getAwardedWaitingList(offerId, state) {
  if (state !== 'Awarded' && state !== 'AwardedExternally') {
    return null
  }

  const response = await apiClient({
    url: `/audits/offers/${offerId}`,
    method: 'POST',
    data: {
      page: 1,
      pageSize: 1,
      action: state
    }
  })

  const contract = response.data.results && response.data.results.length > 0 ? response.data.results[0].contract : null
  return contract && contract.recipientsState ? contract.recipientsState : null
}

async function getOfferRecipients({ organizationId, propertyId, residenceId, offerId, data }) {
  return await apiClient({
    url: `/search/applicants/offers/${organizationId}/${propertyId}/${residenceId}/${offerId}`,
    method: 'POST',
    data
  })
}
async function getAdvertApplicants({ advertId, data }) {
  return await apiClient({
    url: `/search/applicants/adverts/${advertId}`,
    method: 'POST',
    data
  })
}
