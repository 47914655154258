<template lang="pug">
.c-ad-advert
  .c-ad-advert__top
    button.c-ad-advert__close(@click='close')
      svg.c-ad-advert__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-ad-advert__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-ad-advert__content.o-transition__content
    .o-grid.o-grid--gutter-small.o-grid--align-items-start
      .o-grid__group
        .o-grid__item.o-grid__item
          h2.c-ad-advert__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.street ? combinedAddress : model.newAdvertHeadline }}
        .o-grid__item.o-grid__item--align-right.o-grid__item--grow-0
          button.o-button.c-ad-advert__submit(@click='newCopy()' v-if='selected.type === "Advert" && selected.status === "Retired"') {{ Dictionary.C.CreateAdvertCopy }}
    .c-ad-advert__info.o-grid.o-grid--gutter-small.o-grid--align-items-start
      .o-grid__group
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.Number }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ selected.shortId || '-' }}
        .o-grid__item(v-if='selected.companyNumber && selected.propertyNumber && selected.residenceNumber')
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.R.Residence }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ `${selected.companyNumber}-${selected.propertyNumber}-${selected.residenceNumber}` }}
        .o-grid__item(v-if='selected.availableFrom')
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.AvailableFrom }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ selected.availableFrom | formatDate }}
        .o-grid__item(v-if='selected.expiresAt')
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.E.Expires }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ selected.expiresAt | formatDate }}
        .o-grid__item(v-if='selected.status')
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.S.Status }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ getStatusLabel(selected.status) }}
        .o-grid__item(v-if='responsibleData.options.length > 0 && selected.type !== "Advert"')
          c-fc-select(:model='responsibleData' v-model='responsible.value')
      .o-grid__group
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.ActiveDays }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ (selected.statistics && selected.statistics.activeDays) || '-' }}
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.V.Views }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ (selected.statistics && selected.statistics.visits) || '-' }}
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.C.CommunicatedWith }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ (selected.statistics && selected.statistics.totalThreads) || '-' }}
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Applicants }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ (selected.statistics && selected.statistics.totalApplicants) || '-' }}
      .o-grid__group(v-if='selected.subOrganizations')
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.PriorityRight }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small.o-bodytext--italic.o-bodytext--700(v-if='isSpecialSubOrg()') {{ isSpecialSubOrg() }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small(
            :class='{ "o-bodytext--700": (selected.turnGivenToSubOrganizationId && subOrg.subOrganizationId === selected.turnGivenToSubOrganizationId) || (!selected.turnGivenToSubOrganizationId && subOrg.nextSuggestedOwnerTurn) }'
            v-for='subOrg in selected.subOrganizations && selected.subOrganizations.owners'
          ) {{ subOrg.subOrganization.integrationJobId }} {{ subOrg.subOrganization.name }} {{ subOrg.percentages }}%
        .o-grid__item
          .c-ad-advert__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.PrecedenceRule }}
          .c-ad-advert__info-data.o-bodytext.o-bodytext--size-small {{ precedenceRule }}
    c-tabs.c-ad-advert__tabs(:start-tab='0')
      c-tab(:disabled='false', :name='Dictionary.A.Advert' disabled-message='')
        c-ad-data(
          :is-advert='true',
          :model='model',
          :residence-translations='residenceTranslations',
          :selected='selected'
          @datachanged='dataChange = $event'
          @validation='formIsValid = $event'
          ref='advert-data'
        )
          template(slot='side-panel')
            .c-ad-advert__payment(v-if='["PublishedNotScreened", "Published", "Expired", "Reserved", "Draft", undefined].includes(selected.status)')
              .c-ad-advert__payment-top
                .c-ad-advert__payment-heading.o-heading.o-heading--size-4 {{ model.advertHighlight.advertHighlightHeadline }}
                .c-ad-advert__payment-description.o-bodytext.o-bodytext--size-small(v-if='highlightAllowed') {{ model.advertHighlight.advertHighlightDescription }}
                .c-ad-advert__payment-description.o-bodytext.o-bodytext--size-small(v-else) {{ model.advertHighlight.advertHighlightInfoWhenNotAllowed }}
              c-notification-inline.o-bodytext.o-bodytext--size-small(
                :canClose='false',
                :showIcon='false'
                justify='left'
                type='warning'
                v-if='highlightAllowed && !!promotedUntill'
              ) {{ (model.advertHighlight.advertHighlightPromotedText && model.advertHighlight.advertHighlightPromotedText.replace('{0}', promotedUntill)) || promotedUntill }}
              .c-ad-advert__payment-body(v-if='highlightAllowed')
                table.o-table.c-ad-advert__payment-overview
                  tr
                    td {{ promotionSettings && promotionSettings.ItemNameTemplate && promotionSettings.ItemNameTemplate.replace('{0}', selected.shortId || '') }}
                    td.notranslate {{ promotionSettings && promotionSettings.Price | money }}
                  tr.o-bodytext.o-bodytext--700
                    td {{ Dictionary.T.Total }}
                    td.notranslate {{ promotionSettings && promotionSettings.Price | money }}
                  //- tr.o-bodytext.o-bodytext--size-small
                  //-   td {{ Dictionary.O.OfWhichVAT }} 00%
                  //-   td {{ 0 | money }}
                c-fc-checkbox.c-ad-advert__payment(:model='highlightTerms' v-model='highlightTerms.value')
                c-cta.o-button--full-width.c-ad-advert__payment-extend(
                  :cta-disabled='!highlightTerms.value',
                  :cta-loading='loading',
                  :cta-text='Dictionary.P.Prioritize'
                  @click='startHighlightPayment'
                )
          template(slot='footer')
            .c-ad-advert__form.c-ad-advert__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
              .o-grid__group
                .o-grid__item.o-grid__item--12.c-ad-advert__form-section--border-top(v-if='canRetire')
                  button.c-ad-advert__delete.o-link.o-link--error(@click='setState("retire")') {{ Dictionary.R.RetireAdvert }}
                .o-grid__item.o-grid__item--12.c-ad-advert__form-section--border-top
                  .o-grid.o-grid--gutter-small.o-grid--align-items-center
                    .o-grid__group
                      .o-grid__item.o-grid__item--grow-0
                        button.o-link.c-ad-advert__cancel(@click='close') {{ Dictionary.C.Cancel }}
                      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                        a.o-button.c-ad-advert__submit(
                          :href='`${model.previewPageLink}/${selected.shortId}`'
                          target='_blank'
                          v-if='!!selected.status && !selected.advertFromResidence'
                        ) {{ Dictionary.S.ShowExample }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading || (!newAdvert && !dataChanged)'
                          @click='submitChanges(false)'
                          v-if='advertEditable'
                        ) {{ Dictionary.S.Save }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading || !dataChanged'
                          @click='submitComment(false)'
                          v-if='!newAdvert && !advertEditable'
                        ) {{ Dictionary.S.Save }} {{ Dictionary.C.Comment }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading || !selected.publishable || dataChanged'
                          @click='startPublishFlow()'
                          v-if='["Draft", "Rejected"].includes(selected.status) && !isScreener'
                        ) {{ Dictionary.P.PublishAdvert }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading'
                          @click='setState("reserve")'
                          v-if='["PublishedNotScreened", "Published"].includes(selected.status) && !isScreener'
                        ) {{ Dictionary.R.Reserve }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading'
                          @click='setState("renew")'
                          v-if='selected.status === "Expired" && !isScreener'
                        ) {{ Dictionary.R.Renew }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading'
                          @click='setState("release")'
                          v-if='selected.status === "Reserved" && !isScreener'
                        ) {{ Dictionary.R.Republish }}
                        button.o-button.c-ad-advert__submit(
                          :disabled='!formIsValid || loading'
                          @click='setState("rentOutToExternal")'
                          v-if='["PublishedNotScreened", "Published", "DeadlineMet", "Reserved"].includes(selected.status) && !isScreener && hasElevatedRights'
                        ) {{ Dictionary.A.AssignExternal }}
      c-tab(
        :disabled='false',
        :name='Dictionary.A.Applicants'
        disabled-message=''
        v-if='!newAdvert && !["Draft", "Rejected"].includes(selected.status) && !isScreener'
      )
        c-ad-applicants(:model='model')
      c-tab(:disabled='false', :name='Dictionary.O.OpenHouse' disabled-message='' v-if='!newAdvert')
        c-ad-openhouse(:model='model')
      c-tab(:disabled='false', :name='Dictionary.H.History' disabled-message='disable message' v-if='!newAdvert && !isScreener')
        c-ad-history(:model='model')
      c-tab(:disabled='false', :name='messagesTabName' disabled-message='disable message' v-if='!newAdvert && !isScreener')
        c-messages(:model='model', :related-entity='selected.id')
    c-modal.c-modal--confirmation(:cornerClose='true' @close='showPrioritySelect = false' v-if='showPrioritySelect')
      template(slot='header')
        .o-heading.o-heading--size-2(v-if='model.advertPriority && model.advertPriority.prioritySelectHeadline') {{ model.advertPriority.prioritySelectHeadline }}
      template(slot='body')
        .c-ad-advert__priority-description.o-bodytext(v-if='model.advertPriority && model.advertPriority.prioritySelectDescription') {{ model.advertPriority.prioritySelectDescription }}
        .c-ad-advert__priority-selectbox
          .c-ad-advert__priority-select
            c-fc-select(:model='priorityData' v-model='priority.value')
          .o-grid.o-grid.o-grid--gutter-tiny.o-grid--justify-content-center
            .o-grid__group
              .o-grid__item.o-grid__item--6
                .o-bodytext.o-bodytext--size-small(v-if='model.advertPriority && model.advertPriority.prioritySelectSubOrganizations') {{ model.advertPriority.prioritySelectSubOrganizations }}:
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                .o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.ProposedPrecedence }}
            .o-grid__group(v-for='subOrg in selected.subOrganizations && selected.subOrganizations.owners')
              .o-grid__item.o-grid__item--1
                .o-bodytext.o-bodytext--size-small(:class='{ "o-bodytext--700": subOrg.nextSuggestedOwnerTurn }') {{ subOrg.subOrganization.integrationJobId }}
              .o-grid__item.o-grid__item--9
                .o-bodytext.o-bodytext--size-small(:class='{ "o-bodytext--700": subOrg.nextSuggestedOwnerTurn }') {{ subOrg.subOrganization.name }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                .o-bodytext.o-bodytext--size-small(:class='{ "o-bodytext--700": subOrg.nextSuggestedOwnerTurn }') {{ subOrg.percentages }}%
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                .o-bodytext.o-bodytext--size-small.o-bodytext--700(v-if='specialNotification') {{ specialNotification }}

      template(slot='footer')
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12
              button.o-button(:disabled='!priority.value' @click='publishWithSubOrg()') {{ Dictionary.P.PublishAdvert }}
            .o-grid__item.o-grid__item--12
              span.o-link(@click='showPrioritySelect = false') {{ Dictionary.C.Cancel }}
    c-retire-awarded-rentedout(:data='createAdvertOrOfferConfirmation' @close='createAdvertOrOfferConfirmation = false' v-if='createAdvertOrOfferConfirmation')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ResidenceData from '@/components/residence-management/rm-data/index'
import adHistory from './advert-history'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import adApplicants from './advert-applicants'
import adOpenHouse from './advert-openhouse'
import RetireAwardedRentedout from '../../retire-awarded-rentedout'
import moment from 'moment'
import { advertManagementService } from '@/_services'

export default {
  name: 'c-ad-advert',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-ad-data': ResidenceData,
    'c-fc-select': FormControllSelect,
    'c-fc-checkbox': FormControllCheckbox,
    'c-ad-history': adHistory,
    'c-ad-applicants': adApplicants,
    'c-ad-openhouse': adOpenHouse,
    'c-retire-awarded-rentedout': RetireAwardedRentedout
  },
  data() {
    return {
      id: this._uid,

      formIsValid: false,
      dataChange: false,
      hasElevatedRights: false,
      showPrioritySelect: false,
      createAdvertOrOfferConfirmation: false,
      residenceTranslations: { ...this.model.residenceTranslations, ...this.model.residence.residenceTranslations },
      responsible: {
        helperText: '',
        value: null,
        placeholderText: this.Dictionary.R.Responsible,
        readOnly: false,
        isMandatory: false,
        options: [],
        validation: null
      },
      priority: {
        helperText: '',
        value: null,
        placeholderText: this.Dictionary.P.Precedence,
        readOnly: false,
        isMandatory: false,
        options: [],
        validation: null
      },
      highlightTerms: {
        value: null,
        helperText: this.model.advertHighlight.advertHighlightCheckboxHelperText,
        placeholderText: this.model.advertHighlight.advertHighlightCheckboxPlaceholderText,
        isMandatory: true,
        readOnly: false,
        validation: {
          errorMessage: this.model.advertHighlight.advertHighlightCheckboxValidationText
        }
      }
    }
  },
  computed: {
    ...mapState('advertManagement', ['selected', 'promotionSettings']),
    ...mapGetters('advertManagement', ['haveActiveApplicants']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('search', ['companies', 'organizations']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapState('user', ['roles', 'userId']),
    combinedAddress() {
      return this.combineAddress(
        this.selected.street,
        this.selected.number,
        this.selected.letter,
        this.selected.floor,
        this.selected.door,
        this.selected.postalCode,
        this.selected.city
      )
    },
    canRetire() {
      return (
        this.selected.id &&
        !['Retired', 'RetiredFromRentedOut'].includes(this.selected.status) &&
        !this.selected.advertFromResidence &&
        !this.roles.includes('Screener')
      )
    },
    dataChanged() {
      return this.dataChange || this.responsibleValue !== this.selected.userId
    },
    isScreener() {
      return this.roles.includes('Screener')
    },
    newAdvert() {
      return !this.selected.id || !!this.selected.advertFromResidence
    },
    messagesTabName() {
      return `${this.Dictionary.M.Messages}${this.selected.statistics.unreadThreads ? ' (' + this.selected.statistics.unreadThreads + ')' : ''}`
    },
    advertEditable() {
      if (this.newAdvert) {
        return true
      }

      if (this.isScreener) {
        return this.selected.status === 'PublishedNotScreened'
      }

      if (this.selected.type === 'Advert') {
        return ['Draft', 'Rejected', 'PublishedNotScreened', 'Published', 'DeadlineMet', 'Expired'].includes(this.selected.status)
      }

      return !['RentedOut', 'Retired', 'RetiredFromRentedOut'].includes(this.selected.status)
    },
    responsibleData() {
      const responsibleData = Object.assign({}, this.responsible)
      responsibleData.options = JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
        return {
          value: responsible.id,
          name: responsible.email.split('@')[0]
        }
      })
      responsibleData.readOnly = this.isScreener || ['RentedOut', 'Retired', 'RetiredFromRentedOut'].includes(this.selected.status)
      return responsibleData
    },
    priorityData() {
      const priorityData = Object.assign({}, this.priority)
      if (this.selected.subOrganizations && this.selected.subOrganizations.priorityList) {
        priorityData.options = this.selected.subOrganizations.priorityList.map(subOrg => {
          return {
            value: subOrg.id,
            name: `${subOrg.subOrganization.integrationJobId} ${subOrg.subOrganization.name}`
          }
        })
      } else if (this.selected.subOrganizations && this.selected.subOrganizations.owners) {
        priorityData.options = this.selected.subOrganizations.owners.map(subOrg => {
          return {
            value: subOrg.id,
            name: `${subOrg.subOrganization.integrationJobId} ${subOrg.subOrganization.name}`
          }
        })
      }

      return priorityData
    },
    specialNotification() {
      const nextSuggestedPriority =
        this.selected.subOrganizations &&
        this.selected.subOrganizations.priorityList &&
        this.selected.subOrganizations.priorityList.find(owner => owner.nextSuggestedOwnerTurn)

      const specialNotification =
        nextSuggestedPriority && this.Dictionary.P.PriorityRightNotification.replace('{0}', nextSuggestedPriority.subOrganization.name)

      return specialNotification
    },
    responsibleValue() {
      return this.responsible.value
    },
    advertCompany() {
      const advertCompany = this.companies && this.companies.find(c => c.id === this.selected.companyId)
      return advertCompany
    },
    precedenceRule() {
      let precedenceRule = '-'
      if (this.hasElevatedRights) {
        const propertyId = this.selected.propertyId
        const advertMemberOrg = this.organizations.find(mo => mo.id === this.advertCompany.organizationId)

        if (
          advertMemberOrg &&
          advertMemberOrg.propertiesApplyingTheMembershipOrganizationDistributionAlgorithm &&
          advertMemberOrg.propertiesApplyingTheMembershipOrganizationDistributionAlgorithm.includes(propertyId)
        ) {
          precedenceRule = advertMemberOrg.distributionAlgorithmId
        } else {
          const selectedMemberOrgFromOwners =
            this.selected.subOrganizations &&
            this.selected.subOrganizations.owners &&
            this.selected.subOrganizations.owners.find(subOrg => subOrg.subOrganizationId === this.selected.turnGivenToSubOrganizationId)
          const selectedMemberOrgFromPriority =
            this.selected.subOrganizations &&
            this.selected.subOrganizations.priorityList &&
            this.selected.subOrganizations.priorityList.find(subOrg => subOrg.subOrganizationId === this.selected.turnGivenToSubOrganizationId)
          const selectedMemberOrg = selectedMemberOrgFromPriority || selectedMemberOrgFromOwners
          // const subOrg = !!selectedMemberOrg && advertMemberOrg.subOrganizations.find(subOrg => subOrg.id === selectedMemberOrg.subOrganization.id)
          if (selectedMemberOrg) {
            const isSecondary =
              selectedMemberOrg.subOrganization &&
              selectedMemberOrg.subOrganization.propertiesApplyingTheSecondaryDistributionAlgorithm &&
              selectedMemberOrg.subOrganization.propertiesApplyingTheSecondaryDistributionAlgorithm.includes(propertyId)
            precedenceRule = isSecondary
              ? selectedMemberOrg.subOrganization.secondaryDistributionAlgorithmId
              : selectedMemberOrg.subOrganization.primaryDistributionAlgorithmId
          }
        }
      }
      return precedenceRule || '-'
    },
    advertStatus() {
      return this.selected.status
    },
    promotedUntill() {
      let promotedUntill = null
      this.selected.promotions.forEach(promotion => {
        if (promotion.status === 'Accepted') {
          promotedUntill = promotedUntill === null || moment.utc(promotion.end).isAfter(moment.utc(promotedUntill), 'day') ? promotion.end : promotedUntill
        }
      })
      return promotedUntill && this.formatDate(promotedUntill)
    },
    highlightAllowed() {
      return !this.isScreener && ['PublishedNotScreened', 'Published', 'Expired', 'Reserved'].includes(this.selected.status)
    }
  },
  methods: {
    close() {
      this.$store.dispatch('advertManagement/removeSelected')
    },
    getStatusLabel(status) {
      return this.Dictionary.ResidenceAdvertStatus[status] || status
    },
    handleDelete() {
      this.$store.dispatch('advertManagement/toggleDeleteConfirmation', this.selected.id)
    },
    submitChanges(closeSelection) {
      const data = this.$refs['advert-data'].getResidenceData()
      const totalFirstPayment = this.$refs['advert-data'].getTotalFirstPayment()

      if (totalFirstPayment <= 0) {
        const error = { data: { errorCode: 'NegativeRent' }, status: 400 }
        this.$store.dispatch('toast/show', error)
      } else {
        data.userId = this.responsibleValue
        delete data.propertyId // non editable in advert Patch
        if (this.selected.id && !this.selected.advertFromResidence) {
          this.$store.dispatch('advertManagement/toggleConfirmation', {
            id: this.selected.id,
            action: 'change',
            data: { data, closeSelection }
          })
        } else {
          this.$store.dispatch('advertManagement/createAdvert', { data, closeSelection })
        }
      }
    },
    submitComment(closeSelection) {
      if (!this.selected.id || this.selected.advertFromResidence) {
        return
      }
      const data = this.$refs['advert-data'].getResidenceData()
      this.$store.dispatch('advertManagement/toggleConfirmation', {
        id: this.selected.id,
        action: 'change',
        data: {
          data: {
            comment: data.comment
          },
          closeSelection
        }
      })
    },
    publishWithSubOrg() {
      const nextSuggested =
        this.selected.subOrganizations &&
        (this.selected.subOrganizations.owners.find(subOrg => subOrg.nextSuggestedOwnerTurn) ||
          this.selected.subOrganizations.priorityList.find(subOrg => subOrg.nextSuggestedOwnerTurn))
      const turnGivenTo =
        this.selected.subOrganizations &&
        (this.selected.subOrganizations.owners.find(subOrg => subOrg.id === this.priority.value) ||
          this.selected.subOrganizations.priorityList.find(subOrg => subOrg.id === this.priority.value))
      const data = {
        turnGivenToOwnershipId: (turnGivenTo && turnGivenTo.id) || '',
        suggestedOwnershipId: (nextSuggested && nextSuggested.id) || '',
        note: ''
      }
      this.$store.dispatch('advertManagement/publishAdvertWithSubOrg', {
        id: this.selected.id,
        data,
        payload: {
          suggestedSubOrganizationId: nextSuggested.subOrganizationId,
          turnGivenToSubOrganizationId: turnGivenTo.subOrganizationId
        }
      })
    },
    publish() {
      if (this.advertCompany && this.advertCompany.rentModel === 'MemberAdvert' && this.selected.subOrganizations) {
        this.showPrioritySelect = true
      } else {
        this.setState('publish')
      }
    },
    startPublishFlow() {
      this.createAdvertOrOfferConfirmation = {
        returnAction: this.publish,
        residenceAddress: this.selected.address || this.combinedAddress
      }
    },
    setState(state) {
      this.$store.dispatch('advertManagement/toggleConfirmation', { id: this.selected.id, action: state })
    },
    newCopy() {
      this.$store.dispatch('advertManagement/createAdvertCopy', this.selected.id)
    },
    isSpecialSubOrg() {
      const turnGivenInOwners =
        this.selected.subOrganizations.owners &&
        this.selected.subOrganizations.owners.find(owner => owner.subOrganizationId === this.selected.turnGivenToSubOrganizationId)
      const turnGivenInPriority =
        this.selected.subOrganizations.priorityList &&
        this.selected.subOrganizations.priorityList.find(owner => owner.subOrganizationId === this.selected.turnGivenToSubOrganizationId)
      const turnSuggestedInPriority =
        this.selected.subOrganizations.priorityList && this.selected.subOrganizations.priorityList.find(owner => owner.nextSuggestedOwnerTurn)

      const isGiven =
        this.selected.turnGivenToSubOrganizationId &&
        !turnGivenInOwners &&
        !!turnGivenInPriority &&
        `${turnGivenInPriority.subOrganization.integrationJobId} ${turnGivenInPriority.subOrganization.name}`

      const isSuggested =
        !this.selected.turnGivenToSubOrganizationId &&
        turnSuggestedInPriority &&
        `${turnSuggestedInPriority.subOrganization.integrationJobId} ${turnSuggestedInPriority.subOrganization.name}`

      return isGiven || isSuggested
    },
    async startHighlightPayment() {
      try {
        const response = await advertManagementService.postPromotion(this.selected.id)
        if (response && response.status === 200) {
          if (response.data.paymentUrl) {
            window.location.assign(response.data.paymentUrl)
          }
        }
      } catch (error) {}
    }
  },
  created() {
    if (this.selected.subOrganizations) {
      const nextPriorityOwer =
        this.selected.subOrganizations.owners.find(org => org.nextSuggestedOwnerTurn) ||
        this.selected.subOrganizations.priorityList.find(org => org.nextSuggestedOwnerTurn)
      this.priority.value = nextPriorityOwer.id
    }
    this.responsible.value = this.selected.userId !== '00000000-0000-0000-0000-000000000000' ? this.selected.userId : this.userId
    this.hasElevatedRights = this.roles.includes('Property Administrator') || this.roles.includes('System Administrator')
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  },
  watch: {
    // responsibleValue(newVal) {
    //   if (!this.selected.advertFromResidence && newVal !== this.selected.userId) {
    //     this.$store.dispatch('advertManagement/patchAdvert', { data: { userId: newVal }, closeSelection: false })
    //   }
    // },
    advertStatus() {
      this.showPrioritySelect = false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-ad-advert {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  // @include min('desktop') {
  //   position: relative;
  //   z-index: unset;
  //   min-width: 0;
  // }

  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__payment {
    margin-top: toRem(24px);
    &:first-child {
      margin-top: 0;
    }
    &-top {
    }
    &-body {
      margin-top: toRem(32px);
      background-color: $grey-5;
      padding: toRem(24px 36px);
    }
    &-overview {
      // margin-bottom: toRem(20px);
      td {
        padding-bottom: 10px;
      }
    }
    &-extend {
      margin-top: toRem(24px);
    }

    &-heading,
    &-description {
      margin: toRem(0 0 20px);
    }
  }

  &__info {
    background-color: $grey-5;
    padding: 15px 30px 20px;
    margin-bottom: 20px;
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
    @include min('desktop') {
      color: $ocean-darker;
    }
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__priority {
    &-selectbox {
      text-align: left;
    }
    &-description,
    &-select {
      margin-bottom: 20px;
    }
  }
}
</style>
