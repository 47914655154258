<template lang="pug">
.c-form-time
  fieldset.o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :max='model.validation.max || undefined',
        :min='model.validation.min',
        :name='model.inputName || id',
        :ref='id',
        :step='model.step || "any"'
        @keyup='setvalue'
        placeholder=' '
        type='time'
        v-debounce.keyup='250'
        v-model='model.value'
      )
      label.o-label.o-label--placeholder(:class='[{ "o-label--required": model.isMandatory }]', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-time',
  data() {
    return {
      id: this._uid,
      value: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.model.value !== undefined && this.model.value !== null && this.model.value !== ''
          } else {
            return true
          }
        },
        validMinMax: function () {
          const value = !!this.model.value && this.model.value.split(':').map(v => parseFloat(v))
          if (!value) return true

          const minValue = !!this.model.validation && !!this.model.validation.min && this.model.validation.min.split(':').map(v => parseFloat(v))
          const maxValue = !!this.model.validation && !!this.model.validation.max && this.model.validation.max.split(':').map(v => parseFloat(v))
          let minPass = true
          let maxPass = true

          if (minValue) {
            minPass = value[0] > minValue[0] || (value[0] === minValue[0] && value[1] > minValue[1])
          }
          if (maxValue) {
            maxPass = value[0] < maxValue[0] || (value[0] === maxValue[0] && value[1] < maxValue[1])
          }

          return minPass && maxPass
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    },
    validationMin() {
      return this.model.validation && this.model.validation.min
    },
    validationMax() {
      return this.model.validation && this.model.validation.max
    }
  },
  methods: {
    setvalue() {
      this.value = this.model.value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    },
    validationMin() {
      this.setAfterModelChange()
    },
    validationMax() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-time {
}
</style>
