/* eslint-disable prettier/prettier */
<template lang="pug">
.c-report.o-wrap.o-wrap--max-width-xxlarge
  .o-grid.o-grid--justify-content-center.o-grid--gutter-small(
    :class='{ "o-grid--wrap-nowrap": !isMobile, "o-grid--align-items-end": !isMobile, "o-grid--align-items-center": isMobile, "o-grid--direction-column": isMobile }'
  )
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        h4.c-report__heading.o-heading.o-heading--size-4(v-if='model.headline') {{ model.headline }}
        .c-report__description.o-bodytext.o-bodytext--size-small(v-if='model.description') {{ model.description }}
      .o-grid__item.o-grid__item--grow-0
        button.c-report__cta.o-button.o-button--bordered.o-button--small(@click='showConfirmation = true') {{ model.callToActionText }}
    c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showConfirmation = false' v-if='showConfirmation')
      template(slot='header')
        .o-heading.o-heading--size-2 {{ submitted ? model.reportSubmittedHeadline : model.confirmationHeadline }}
      template(slot='body')
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12
              .o-bodytext {{ submitted ? model.reportSubmittedDescription : model.confirmationDescription }}
          .o-grid__group(v-if='!submitted')
            .o-grid__item.o-grid__item--12
              c-fc-text(:model='user.firstName' @validate='validate($event, "firstName")' v-model='user.firstName.value')
            .o-grid__item.o-grid__item--12
              c-fc-text(:model='user.lastName' @validate='validate($event, "lastName")' v-model='user.lastName.value')
            .o-grid__item.o-grid__item--12
              c-fc-email(:model='user.email' @validate='validate($event, "email")' v-model='user.email.value')
            .o-grid__item.o-grid__item--12
              c-fc-text(:model='user.phone' @validate='validate($event, "phone")' v-model='user.phone.value')
            .o-grid__item.o-grid__item--12
              c-fc-textbox(:model='user.reason' @validate='validate($event, "reason")' v-model='user.reason.value')

      template(slot='footer')
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12(v-if='!submitted')
              button.o-button.o-button--inverted(:disabled='!formIsValid || loading' @click='submitReport()') {{ Dictionary.S.Send }}
            .o-grid__item.o-grid__item--12
              span.o-link(@click='showConfirmation = false') {{ submitted ? Dictionary.C.Close : Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'

import { residenceManagementService } from '@/_services'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllEmail from '@/components/form/controlls/form-email'
export default {
  name: 'c-report',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      showConfirmation: false,
      submitted: false,
      validation: {},

      user: {
        firstName: {
          helperText: this.model.firstNameHelperText,
          placeholderText: this.model.firstNamePlaceholder,
          value: null,
          isMandatory: true,
          validation: null
        },
        lastName: {
          helperText: this.model.lastNameHelperText,
          placeholderText: this.model.lastNamePlaceholder,
          value: null,
          isMandatory: true,
          validation: null
        },
        email: {
          helperText: this.model.emailHelperText,
          placeholderText: this.model.emailPlaceholderText || this.Dictionary.E.Email,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 250
          }
        },
        phone: {
          helperText: this.model.phoneHelperText,
          placeholderText: this.model.phonePlaceholder || this.Dictionary.P.Phone,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{8}|\\d{12}',
            displayRule: '',
            maxLength: 12,
            minLength: 8
          }
        },
        reason: {
          helperText: this.model.reasonHelperText,
          value: null,
          placeholderText: this.model.reasonPlaceholder,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        }
      }
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-email': FormControllEmail,
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapState('user', ['userId', 'firstName', 'lastName', 'email', 'phoneNoForSms', 'landlinePhone']),
    ...mapState('loadingIndicator', ['loading']),
    showApplicationFlow() {
      return !!(this.selected && this.selected.sections.length > 0) || !!this.confirmation
    },
    confirmationBody() {
      return (this.model.confirmationBody || '').replace('{0}', (this.selected && this.selected.membershipOrganization) || '')
    },
    formIsValid() {
      const valid = this.validation && Object.values(this.validation).every(key => key === true)
      return valid
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    setPrefill() {
      this.user.firstName.value = this.firstName
      this.user.lastName.value = this.lastName
      this.user.email.value = this.email
      this.user.phone.value = this.landlinePhone || this.phoneNoSms
    },
    async submitReport() {
      try {
        const formData = Object.keys(this.user).reduce((formData, key) => {
          formData.append(key, this.user[key].value)
          return formData
        }, new FormData())
        const reportResponse = await residenceManagementService.reportResidence(this.model.shortId, formData)
        if (reportResponse && reportResponse.status === 204) {
          this.submitted = true
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  created() {
    if (this.userId) {
      this.setPrefill()
    }
  },
  watch: {
    userId() {
      this.setPrefill()
    },
    showConfirmation(newVal) {
      if (newVal) this.submitted = false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-report {
  padding: 40px 15px 45px;
  text-align: center;

  @include min('desktop') {
    padding: 50px 25px 65px;
    text-align: left;
  }

  &__description {
    margin-block-start: 10px;
  }
}
</style>
