<template lang="pug">
.header__profile-button-wrap
  input#profile.header__profile-toggle.u-visually-hidden(@click='profileButtonClick' autocomplete='do-not-autofill' role='button' type='checkbox')
  label.header__profile-button(for='profile')
    span.header__profile-button--icon(v-if='!loggedIn')
      svg.o-svg-icon.o-button__icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
    span.header__profile-button--avatar(:style='`background-image: url(${avatarImage}?cacheBuster=${dataBusterKey})`' v-if='loggedIn && !openMenu')
      span.profile-button__count(v-if='messagesCount > 0') {{ messagesCount }}
    span.header__profile-button--icon-close
      svg.o-svg-icon.o-button__icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
    p {{ loggedIn ? Dictionary.M.MyPage : Dictionary.P.Profile }}
    div(style='white-space: nowrap' v-if='developerMode') {{ roles.toString() }}
  .header__profile(aria-label='Profile menu' role='navigation' v-if='openMenu')
    c-profile-menu(:menu='menu')
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'c-profile-button',
  data() {
    return {
      openMenu: false
    }
  },
  props: {
    menu: Object
  },
  computed: {
    ...mapState(['developerMode']),
    ...mapState('user', ['loginModalShowing', 'userId', 'profileMenu', 'avatar', 'dataBusterKey', 'roles']),
    ...mapState('messages', ['unreadMessagesCount']),
    loggedIn() {
      return !!this.userId
    },
    avatarImage() {
      return `/api/users/${this.userId}/avatar`
    },
    messagesCount() {
      const countMenuItem = this.profileMenu && this.profileMenu.find(menuItem => menuItem.count !== undefined)
      const count = countMenuItem && countMenuItem.count
      return this.unreadMessagesCount !== undefined ? this.unreadMessagesCount : count
    }
  },
  methods: {
    profileButtonClick: function (e) {
      if (this.loggedIn) {
        this.openMenu = !this.openMenu
      } else {
        e.target.checked = false
        this.$store.dispatch('user/toggleLoginModal', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  &__profile-button--avatar {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
  }
}
.profile-button__count {
  background-color: $ocean-primary;
  position: absolute;
  top: toRem(-7px);
  right: toRem(-7px);
  color: $white;
  @include setStyles('12', $s-typography-sizes);
  border-radius: toRem(8px);
  padding: toRem(0px 5px);
  min-width: toRem(15px);
}
.header__profile-toggle:focus + label {
  outline: 1px solid $ocean-primary;
}
</style>
