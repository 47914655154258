<template lang="pug">
.property-tags__tags-top(v-if='showTags && updated')
  .property-tags__tag-top.o-tag.o-tag--ocean(v-if='tags.isNew') {{ Dictionary.N.New_en }}
  .property-tags__tag-top.o-tag.o-tag--crab(v-for='tag in tags.types') {{ tag }}
  .property-tags__tag-top.o-tag.o-tag--kelp(v-for='tag in tags.propType') {{ tag }}
  .property-tags__tag-top.o-tag.o-tag--coral(v-if='tags.waitingList') {{ Dictionary.W.WaitingList }}
  .property-tags__tag-top.o-tag.o-tag--coral(v-if='tags.interestList') {{ Dictionary.I.InterestList }}
  .property-tags__tag-top.o-tag.o-tag--coral(v-if='tags.membersOnly') {{ Dictionary.M.MembersOnly }}
  .property-tags__tag-top.o-tag.o-tag--kelp(v-if='tags.availableNow') {{ Dictionary.A.AvailableNow }}
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'c-property-tags',
  data() {
    return {
      tags: {},
      updated: false
    }
  },
  props: {
    property: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState('search', ['propertiesByCompanies']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
  },
  methods: {
    showWaitingList() { return this.tags.waitingList },
    showTags() {
      return this.tags.isNew || (this.tags.types && this.tags.types.length > 0) || this.tags.waitingList || this.tags.availableNow
    },
    getPropertyType() {
      if (this.property.$type === 'Residence') {
        const typeItm = this.propertyTypes.find(t => t.id === this.property.propertyType)
        if (typeItm) {
          return typeItm.name
        }
      }

      return `${this.property.residencesCount} ${this.Dictionary.Search.ResidenciesInProperty}`
    },
    setTags() {
      this.updated = true

      this.tags.isNew = moment.utc(this.property.created).isAfter(moment.utc().subtract(8, 'days'))
      this.tags.waitingList = this.property.$type === 'Property' && !this.propertiesByCompanies
      this.tags.interestList = this.property.$type === 'Project'
      this.tags.membersOnly = this.property.$type === 'Residence' && this.property.membersOnly
      this.tags.availableNow =
        this.property.$type === 'Residence' &&
        (this.property.availableFrom == null || moment.utc(this.property.availableFrom) < moment.utc().add(14, 'days')) &&
        (this.property.availableTo == null || moment.utc(this.property.availableTo) > moment.utc())
      if (this.property.$type === 'Residence') {
        this.tags.types =
          this.property.types &&
          this.property.types.length > 0 &&
          this.property.types.map(type => {
            const target = this.residenceTypes.find(residenceType => residenceType.id === type)
            return target && target.name
          })
      }
      if (this.property.$type === 'Property') {
        const propType = this.property.propertyType && this.propertyTypes.find(pType => pType.id === this.property.propertyType)

        this.tags.types = this.property.residenceCommonTypes &&
          this.property.residenceCommonTypes.map(type => {
            const target = this.residenceTypes.find(residenceType => residenceType.id === type)
            return target && target.name
          })

        if (!Array.isArray(this.tags.types)) {
          this.tags.types = []
        }

        // this.tags.types.push(propType && propType.name)
        this.tags.propType = [propType && propType.name]

      }
    }
  },
  mounted() {
    this.setTags()
  }
}
</script>

<style lang="scss" scoped>
.property-tags {
  &__tags-top {
    display: flex;
    margin-bottom: 7px;
    min-height: 18px;
    flex-wrap: wrap;
    padding-right: 20px;
  }

  &__tags-bottom {
    display: flex;
    margin-top: toRem(15px);
    flex-wrap: wrap;
  }
}
</style>
