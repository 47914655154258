/* eslint-disable prettier/prettier */
<template lang="pug">
.c-advert-apply
  .c-advert-apply__applyBefore.o-bodytext.o-bodytext--size-medium(v-if='model.applyBeforeDate') {{ applicationApplyBeforeText }}
  button.o-button.o-button--inverted(
    :disabled='applyAction === "Disabled" || applyAction === "Cancel"'
    v-requiresAuthentication:click.stop='{ action: handleApplication, createdCtaText: model.newUserConfirmationCTAText }'
  ) {{ model.ctaText }}
  .c-advert-apply__cancel(v-if='applyAction === "Cancel"')
    .c-advert-apply__cancel-description.o-bodytext.o-bodytext--size-small {{ model.cancelDescription }}
    button.c-advert-apply__cancel-cta.o-link.o-link--crab.o-link--opensans.o-link--500(@click='handleApplication(false)')
      span.o-bodytext.o-bodytext--size-small {{ model.cancelCTAText }}
  c-mo-subscription(:callback='setApplication', :model='subscribtionModel', :property-application='true' @close='closeSubscribtion' v-if='showApplicationFlow')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { applicationsManagementService } from '@/_services'
import moSubscription from '@/components/membership-organization-subscription'
export default {
  name: 'c-advert-apply',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      applyAction: 'Apply'
    }
  },
  components: {
    'c-mo-subscription': moSubscription
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('membershipOrganizations', ['selected', 'confirmation']),
    ...mapGetters('membershipOrganizations', ['userIsAssociated']),
    showApplicationFlow() {
      return !!(this.selected && this.selected.sections.length > 0 && this.applyAction === 'Apply') || !!this.confirmation
    },
    confirmationBody() {
      return (this.model.confirmationBody || '').replace('{0}', (this.selected && this.selected.membershipOrganization) || '')
    },
    subscribtionModel() {
      return {
        heading: this.model.applicationOverviewHeading,
        ctaText: this.model.applicationOverviewCTAText,
        headingConfirmation: this.model.confirmationHeading,
        descriptionConfirmation: this.model.confirmationDescription,
        bodyConfirmation: this.confirmationBody,
        ctaConfirmation: this.model.confirmationCTAText
      }
    },
    applicationApplyBeforeText() {
      return this.model.applyBeforeDate
        ? this.model.applicationApplyBeforeText
          ? this.model.applicationApplyBeforeText.replace('{0}', this.formatDate(this.model.applyBeforeDate))
          : this.formatDate(this.model.applyBeforeDate)
        : ''
    }
  },
  methods: {
    handleApplication() {
      if (this.model.isMembershipAdvert && !this.userIsAssociated && this.applyAction === 'Apply') {
        this.$store.dispatch('membershipOrganizations/setSelected', { id: this.model.organization, propertyId: this.model.propertyId, isApplication: true })
      } else {
        this.setApplication()
      }
    },
    closeSubscribtion() {
      this.$store.dispatch('membershipOrganizations/removeSelected')
    },
    async setApplication() {
      try {
        let applicationResponse
        if (this.applyAction === 'Apply') {
          applicationResponse = await applicationsManagementService.applyForResidenceAdvert(this.model.residenceId)
        } else {
          applicationResponse = await applicationsManagementService.cancelForResidenceAdvert(this.model.residenceId)
        }
        if (applicationResponse && applicationResponse.status === 200) {
          if (this.applyAction === 'Apply') {
            this.$store.commit('membershipOrganizations/setConfirmation', {
              response: applicationResponse,
              targetId: this.selected && this.selected.id
            })
          }
          this.applyAction = applicationResponse.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getApplicationStatusForResidenceAdvert() {
      try {
        const response = await applicationsManagementService.getApplicationStatusForResidenceAdvert(this.model.residenceId)
        if (response && response.status === 200) {
          this.applyAction = response.data
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  created() {
    this.applyAction = this.model.applyAction
  },
  watch: {
    userIsAssociated(newVal) {
      if (newVal) {
        this.setApplication()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-advert-apply {
  text-align: center;
  &__applyBefore {
    margin-bottom: 20px;
    color: $white;
  }
  &__cancel {
    text-align: center;
    margin-top: 10px;
    &-description {
      color: $white;
    }
    &-cta {
      display: block;
      margin: 5px auto 0;
    }
  }
}
</style>
