<template lang="pug">
.c-messages-search
  .o-grid.o-grid--gutter-small.o-grid--align-items-top
    .o-grid__group
      template(v-if='hasElevatedRights')
        .o-grid__item.o-grid__item--12(v-if='!specificRelatedEntities')
          c-fc-vselect(:model='filters.user' v-model='filters.user.value')
        .o-grid__item.o-grid__item--12(v-if='!specificRelatedEntities')
          c-fc-vselect(:model='filters.companyId' v-model='filters.companyId.value')
        .o-grid__item.o-grid__item--6(v-if='!specificRelatedEntities')
          c-fc-select(:model='filters.type' v-model='filters.type.value')
      .o-grid__item.o-grid__item--6
        c-fc-select(:model='filters.status' v-model='filters.status.value')
      .o-grid__item
        c-fc-text(:model='filters.search' @submit='postSearch' v-model='filters.search.value')
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right.c-messages-search__align-self--center
        button.o-button(:disabled='loading' @click='postSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
      .o-grid__item.o-grid__item--grow-0.c-messages-search__align-self--center
        button.o-link(
          :disabled='(!filters.search.value && !filters.status.value && !filters.companyId.value && !filters.user.value && !filters.type.value) || loading'
          @click='handleReset'
          role='button'
          v-debounce.keyup='500'
        ) {{ Dictionary.R.Reset }}
</template>
<script>
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import FormControllText from '@/components/form/controlls/form-text'
import { getQueryParameters } from '@/_helpers/query-parameters'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-messages-search',
  data() {
    return {
      id: this._uid,
      typeBuster: 0,
      filters: {
        search: {
          helperText: '',
          placeholderText: 'Søg emne, adresse, dato m.m.',
          value: null,
          isMandatory: false,
          validation: null
        },
        companyId: {
          helperText: '',
          value: (getQueryParameters().get('companyId') && getQueryParameters().get('companyId').split(',')) || '',
          placeholderText: this.Dictionary.O.Owner,
          settings: {
            clearable: false,
            multiple: true,
            reduce: option => option.value
          },
          options: []
        },
        user: {
          helperText: '',
          value: (getQueryParameters().get('user') && getQueryParameters().get('user').split(',')) || '',
          placeholderText: this.Dictionary.R.Responsible,
          settings: {
            clearable: false,
            multiple: true,
            reduce: option => option.value
          },
          options: []
        },
        type: {
          helperText: '',
          value: this.getTypevalue(),
          placeholderText: this.Dictionary.T.Type,
          isMandatory: false,
          options: [
            {
              value: '',
              name: this.Dictionary.A.All
            },
            {
              value: JSON.stringify({ type: 'ResidenceAdvert' }),
              name: this.Dictionary.A.Advert
            },
            {
              value: JSON.stringify({ type: 'Offer' }),
              name: `${this.Dictionary.O.Offer}`
            },
            {
              value: JSON.stringify({ type: 'Offer', relatedEntityStates: ['Finished'] }),
              name: `${this.Dictionary.O.Offer} ${this.Dictionary.OfferStates.Finished}`
            },
            {
              value: JSON.stringify({ type: 'Offer', relatedEntityStates: ['Published', 'Changed'] }),
              name: `${this.Dictionary.O.Offer} ${this.Dictionary.OfferStates.Published}`
            },
            {
              value: JSON.stringify({ type: 'Offer', relatedEntityStates: ['Awarded', 'AwardedExternally'] }),
              name: `${this.Dictionary.O.Offer} ${this.Dictionary.OfferStates.Awarded}`
            }
          ],
          validation: null
        },
        status: {
          helperText: '',
          value: getQueryParameters().get('status') || '',
          placeholderText: this.Dictionary.S.Status,
          isMandatory: false,
          options: [
            {
              value: '',
              name: this.Dictionary.A.All
            },
            {
              value: 'Read',
              name: this.Dictionary.R.Read_Past
            },
            {
              value: 'Unread',
              name: this.Dictionary.U.Unread_Past
            }
          ],
          validation: null
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-vselect': FormControllVSelect
  },
  props: {},
  computed: {
    ...mapState('search', ['companies']),
    ...mapState('user', ['userId']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapState('messages', { messagesFilters: 'filters' }),
    ...mapGetters('user', ['hasElevatedRights']),
    dataReady() {
      return this.companies.length > 0 && this.allManagedUsers.length > 0
    },
    specificRelatedEntities() {
      return !!this.messagesFilters.relatedEntities
    }
  },
  methods: {
    getTypevalue() {
      const urlType = (this.messagesFilters && this.messagesFilters.type) || getQueryParameters().get('type')
      const urlRelatedEntityStates =
        (this.messagesFilters && this.messagesFilters.relatedEntityState) ||
        (getQueryParameters().get('relatedEntityStates') && getQueryParameters().get('relatedEntityStates').split(','))
      let value = ''
      if (urlType) {
        value = {}
        value.type = urlType
        if (urlRelatedEntityStates) {
          value.relatedEntityStates = urlRelatedEntityStates
        }

        return JSON.stringify(value)
      } else {
        return value
      }
    },
    postSearch() {
      const data = {}
      Object.keys(this.filters).forEach(key => {
        if (key === 'type') {
          const typevalue = this.filters[key].value.length > 0 ? JSON.parse(this.filters[key].value) : ''
          data.type = (typevalue && typevalue.type) || ''
          data.relatedEntityStates = (typevalue && typevalue.relatedEntityStates) || ''
        } else {
          data[key] = this.filters[key].value
        }
      })
      this.$store.dispatch('messages/postSearch', data)
    },
    handleReset() {
      this.filters.search.value = null
      this.filters.companyId.value = ''

      this.filters.type.value = ''
      this.filters.status.value = ''

      if (this.hasElevatedRights && !this.filters.user.value && !this.specificRelatedEntities) {
        this.filters.user.value = [this.userId]
      } else {
        this.filters.user.value = ''
      }

      this.postSearch()
    },
    setMultiSelect() {
      this.filters.user.options =
        this.allManagedUsers &&
        JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
          return {
            value: responsible.id,
            name: responsible.email.split('@')[0]
          }
        })

      this.filters.companyId.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
    }
  },
  created() {
    if (this.hasElevatedRights) {
      if (!this.companies.length) this.$store.dispatch('search/getCompanies')
      if (!this.allManagedUsers.length) this.$store.dispatch('usersManagement/getManagedUsers')
      if (this.dataReady) this.setMultiSelect()
      if (!this.filters.user.value && !this.specificRelatedEntities) {
        this.filters.user.value = [this.userId]
      }
    }

    this.postSearch()
  },
  watch: {
    dataReady() {
      this.setMultiSelect()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-messages-search {
  &__align-self--center {
    align-self: center;
  }
}
</style>
