<template lang="pug">
.c-my-waitinglists
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ confirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleConfirmation') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
  .c-my-waitinglists__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-list' y='0')
    h1 {{ model.headline }}
  .c-my-waitinglists__filter.o-grid.o-grid--gutter-tiny.o-grid--align-items-center
    .o-grid__group
      .c-my-waitinglists__options.o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-if='signups.length > 0')
        c-fc-select.c-my-waitinglists__filter-options(:model='filterData' v-model='filter.value')
        //- .c-my-waitinglists__option
        //-   input#my-waitinglists-filter-1.o-input(name='my-waitinglists-filter' type='radio' v-model='filter' value='all')
        //-   label.o-label(for='my-waitinglists-filter-1') {{ Dictionary.Filter.All }}
        //- .c-my-waitinglists__option(:key='filterItem.id' v-for='filterItem in signupsPensionFunds')
        //-   input.o-input(:id='filterItem.id', :value='filterItem.id' name='my-waitinglists-filter' type='radio' v-model='filter')
        //-   label.o-label(:for='filterItem.id') {{ filterItem.name }} ({{ filterCount(filterItem) }})
  .c-my-waitinglists__filter-last.o-grid.o-grid--gutter-tiny.o-grid--align-items-center(v-if='signups.length > 0')
    .o-grid__group
      .c-my-waitinglists__options.o-grid__item.o-grid__item--12.o-grid__item--desktop-6
        .c-my-waitinglists__checkbox
          input#my-waitinglists-filter-2.o-input(name='my-waitinglists-filter-activeonly' type='checkbox' v-model='activeOnly')
          label.o-label(for='my-waitinglists-filter-2') {{ Dictionary.Waitinglist.WaitingListShowOnlyActive }}
  .c-my-waitinglists__container.o-grid.o-grid--direction-row.o-grid--align-items-stretch.o-grid--justify-content-start.o-grid--gutter-small(v-if='!mapShowing')
    transition-group.o-grid__group(mode='out-in' name='o-transition__fade' v-if='dataReady')
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(:key='`property-${property.id}`' v-for='property in filteredSignups')
        .c-my-waitinglists__wrap
          .c-my-waitinglists__userpassive(v-if='property.waitingListStatus !== "Active" || property.userInQuarantine')
            .c-my-waitinglists__userpassive__wrap
              .o-bodytext.o-bodytext--size-small {{ property.userInQuarantine ? quarantineMessage(property) : passiveOnWaitingListMessage(property) }}
              .o-grid.o-grid--justify-content-center.o-grid--gutter-small
                .o-grid__group
                  .o-grid__item.o-grid__item--12
                    button.o-button.o-button--small.o-button--secondary(
                      @click='handleSetActiveConfirmation($event, property.id)'
                      v-if='!property.userInQuarantine && property.waitingListStatus !== "Active"'
                    ) {{ Dictionary.Waitinglist.WaitingListButtonMakeMeActive }}
                  .o-grid__item.o-grid__item--12
                    button.o-link.o-link--small.o-link--inverted(@click='handleDeleteConfirmation($event, property.id)') {{ Dictionary.Waitinglist.WaitingListLinkRemoveMeFromList }}
          c-property-card(:disabled='property.waitingListStatus !== "Active"', :key='property.id', :property='property', :showWaitingListStatus='true')
            .property-card__list(slot='waitinglistIndex')
              .property-card__list-text {{ signUpCountText(property) }}
              .property-card__list-count(v-if='property.waitingListPosition && property.waitingListPosition !== "none"') {{ Dictionary.Waitinglist.WaitingListYourPosition }} {{ property.waitingListPosition }}
              .property-card__list-count(v-else)
                c-loading-animation(:inline='true', :showText='false' offsetTop='0')
          .c-my-waitinglists__waitingliststatus
            .c-my-waitinglists__checkbox.o-grid.o-grid--direction-row.o-grid--justify-content-end
              .o-grid__group
                label.c-my-waitinglists__label-checkbox.o-label(:for='property.id')
                  span {{ Dictionary.Waitinglist.WaitingListActiveOnWaitingList }}
                input.o-input.o-input--switch(
                  :checked='property.waitingListStatus === "Active"',
                  :id='property.id',
                  :value='property.id'
                  @click='handleSetPassiveConfirmation($event, property.id)'
                  name='setActiveOnList'
                  placeholder=' '
                  type='checkbox'
                )
                label.o-label(:for='property.id')
    .c-search-result__loading(v-else)
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import FormControllSelect from '@/components/form/controlls/form-select'
export default {
  name: 'c-my-waitinglists',
  props: {
    model: Object
  },
  data() {
    return {
      filter: {
        helperText: '',
        value: '',
        readOnly: false,
        placeholderText: this.Dictionary.P.PensionFund,
        options: []
      },
      activeOnly: false,
      mapModel: [],
      id: this._uid
    }
  },
  components: {
    'c-fc-select': FormControllSelect
  },
  computed: {
    ...mapState('search', ['mapShowing', 'mapFavorites', 'facilities']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('user', ['pensionFunds']),
    ...mapState('signupsManagement', ['signups', 'confirmationTarget']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    signupsPensionFundsOptions() {
      const signupsPensionFunds = this.signups.filter(item => item.name).map(application => {
        const applicationName = `${application.propertyOrganizationName || application.propertyCompanyName} ${this.filterCount(
          application.propertyOrganizationId
        )}`
        return { value: application.propertyOrganizationId, name: applicationName }
      })
      const unique = [...new Map(signupsPensionFunds.map(item => [item.value, item])).values()]

      return unique
    },
    filterData() {
      const filterData = Object.assign({}, this.filter)
      const options = [{ name: this.Dictionary.Filter.All, value: '' }].concat(this.signupsPensionFundsOptions)
      filterData.options = options

      return filterData
    },
    filteredSignups() {
      let filteredSignups = this.signups.filter(a => a.name)

      if (this.filter.value) {
        filteredSignups = this.signups.filter(a => a.name && a.propertyOrganizationId === this.filter.value)
      }

      filteredSignups.forEach(a => {
        const quarantine = this.pensionFunds.find(pf => pf.id === a.propertyOrganizationId)
        a.userInQuarantine = !!quarantine && !!quarantine.quarantineEndDate && moment.utc(quarantine.quarantineEndDate) > moment.utc()

        return a
      })

      if (this.activeOnly) {
        filteredSignups = filteredSignups.filter(a => a.waitingListStatus === 'Active')
      }

      return filteredSignups
    },
    confirmationTargetData() {
      return this.confirmationTarget && this.signups.find(application => application.id === this.confirmationTarget.id)
    },
    confirmationHeadlineText() {
      return this.confirmationTargetData && this.confirmationTargetData.name
    },
    confirmationDescriptionText() {
      let description
      if (this.confirmationTargetData) {
        switch (this.confirmationTarget.action) {
          case 'SetActive':
            description = this.model.setActive
            break
          case 'SetPassive':
            description = this.confirmationTargetData.noSeniorityToPassiveMembers ? this.model.setPassiveNoSeniorityAdded : this.model.setPassiveSeniorityAdded
            break
          case 'Delete':
            description = this.model.deleteWaitingList
            break
        }
      }

      return description && description.replace('{pensionfund}', this.confirmationTargetData ? this.confirmationTargetData.propertyOrganizationName : '')
    },
    dataReady() {
      return this.propertyTypes.length > 0 && this.residenceTypes.length > 0 && this.facilities.length > 0
    },
    activeOnList() {
      return true
    }
  },
  methods: {
    filterCount(id) {
      return this.signups.filter(a => a.propertyOrganizationId === id).length
    },
    passiveOnWaitingListMessage(property) {
      let passiveOnWaitingListMessage
      if (property.noSeniorityToPassiveMembers) {
        passiveOnWaitingListMessage = this.model.passiveOnWaitingListMessageNoSeniorityAdded
      } else {
        passiveOnWaitingListMessage = this.model.passiveOnWaitingListMessageSeniorityAdded
      }

      return passiveOnWaitingListMessage
    },
    quarantineMessage(property) {
      const passiveOnWaitingListMessage =
        this.model.quarantineOnMembershipOrganizationMessage &&
        this.model.quarantineOnMembershipOrganizationMessage.replace('{pensionfund}', property.propertyOrganizationName)

      return passiveOnWaitingListMessage
    },
    handleSetPassiveConfirmation(event, id) {
      event.preventDefault()

      this.$store.dispatch('signupsManagement/toggleConfirmation', {
        id,
        action: 'SetPassive'
      })
    },
    handleSetActiveConfirmation(event, id) {
      event.preventDefault()

      this.$store.dispatch('signupsManagement/toggleConfirmation', {
        id,
        action: 'SetActive'
      })
    },
    handleDeleteConfirmation(event, id) {
      event.preventDefault()

      this.$store.dispatch('signupsManagement/toggleConfirmation', {
        id,
        action: 'Delete'
      })
    },
    handleConfirmationClose() {
      this.$store.dispatch('signupsManagement/toggleConfirmation')
    },
    handleConfirmation() {
      this.$store.dispatch('signupsManagement/handleConfirmation')
    },
    filterFavorites(item) {
      return this.filter === 'all' || item.data.$type.toLowerCase() === this.filter
    },
    getInitialData() {
      if (this.propertyTypes.length === 0) {
        this.$store.dispatch('propertyManagement/getTypes')
      }
      if (this.residenceTypes.length === 0) {
        this.$store.dispatch('residenceManagement/getTypes')
      }
      if (this.facilities.length === 0) {
        this.$store.dispatch('search/getFacilities')
      }
    },
    signUpCountText(property) {
      const residencesCount = property.waitingListResidences ? property.waitingListResidences.length : 0
      return this.Dictionary.Waitinglist.WaitingListSignedUpFor.replace('{0}', residencesCount).replace(
        '{1}',
        residencesCount > 1 ? this.Dictionary.R.Residences.toLowerCase() : this.Dictionary.R.Residence.toLowerCase()
      )
    }
  },
  created() {
    this.getInitialData()
    this.$store.dispatch('signupsManagement/getMySignups')
    this.$store.dispatch('user/getPensionFundsUserData')
  }
}
</script>
<style lang="scss" scoped>
.property-card {
  &__list-text {
    line-height: 26px;
  }
}

.c-my-waitinglists {
  height: 100%;

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    width: 100%;

    @include min('desktop') {
      width: auto;
    }
  }

  &__option {
    outline: 1px solid $ocean-primary;
    margin: 0;
    white-space: nowrap;

    & + & {
      border-left: 1px solid $ocean-primary;
    }

    .o-input[type='radio'] {
      &:checked {
        + label {
          background-color: $ocean-primary;
          color: $white;
        }
      }

      + label {
        padding: 6px 14px;
        font-family: 'Inria Serif', serif;
        background-color: $white;
        color: $ocean-primary;
        line-height: toRem(26px);
        font-weight: 700;

        &::before,
        &:after {
          display: none;
        }
      }

      &:focus + label {
        outline: 1px solid $ocean-primary;
        outline-offset: 3px;
      }
    }
  }

  &__filter {
    margin-bottom: toRem(15px);

    @include min('desktop') {
      margin-bottom: toRem(40px);
    }

    &-options {
      width: 100%;
    }
  }

  &__filter-last {
    margin-bottom: toRem(10px);
    margin-left: toRem(2px);

    @include min('desktop') {
      margin-bottom: toRem(25px);
    }
  }

  &__map-button {
    @include min('desktop') {
      margin-left: auto;
    }
  }

  &__wrap {
    position: relative;
  }

  &__label-checkbox {
    margin-right: toRem(12px);
    line-height: toRem(24px);
  }

  &__unavailiable {
    padding: 45px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($white, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    &-cta {
      margin-top: 20px;
      background-color: transparent;
      border: none;
      text-decoration: underline;
    }
  }

  &__userpassive {
    padding: toRem(110px) toRem(65px);
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($white, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    &-cta {
      margin-top: 20px;
      background-color: transparent;
      border: none;
      text-decoration: underline;
    }

    &__wrap {
      color: $white;
      padding: toRem(28px) toRem(24px);
      background-color: $ocean-primary;

      .o-bodytext {
        margin-bottom: toRem(20px);
      }
    }
  }

  &__waitingliststatus {
    border: toRem(1px) solid $grey-4;
    border-top: none;
    background-color: $grey-5;
    padding: 16px;
    position: relative;
  }

  &__container {
    min-height: 280px;
    position: relative;
  }

  &__input {
    @include max('desktop', -1) {
      border: none;
      background-image: url(map-get($sprites, 'icon-sorting'));
      background-position: 5px center;
      padding: 6px 14px 6px 30px;
      line-height: 24px;

      &:focus,
      &:hover {
        background-color: $grey-5;
        outline: none;
        border: none;
      }
    }
  }
}
</style>
