<template lang="pug">
ul.c-messages-threads
  transition-group(name='o-transition__fade-up' v-if='results.length > 0')
    li.c-messages-threads__item(
      :class='{ active: (thread.id && thread.id) === (all.activeThread && all.activeThread.id), new: thread.hasUnreadMessages && thread.lastMessage && !userRespondedToThread(thread.lastMessage.sender.id), respond: thread.lastMessage && userRespondedToThread(thread.lastMessage.sender.id) }',
      :key='thread.id'
      @click='selectThread(thread.id)'
      v-for='thread in results'
    )
      .o-grid.o-grid--justify-content-start.o-grid--gutter-none
        .o-grid__group
          .o-grid__item.o-grid__item--8.o-grid__item--grow-0
            .c-messages-threads__thread-head
            h3.o-bodytext.o-bodytext--600.o-bodytext--size-medium {{ thread.title }}
            .o-bodytext.o-bodytext--size-small {{ thread.lastMessage && thread.lastMessage.sender.name }}
          .o-grid__item.o-grid__item--3.o-grid__item--align-right.o-grid__item--grow-0
            .c-messages-threads__thread-info
              .o-bodytext.o-bodytext--size-xsmall {{ thread.lastMessage && formatDate(thread.lastMessage.created) }}
              svg.o-svg-icon(type='image/svg+xml' v-if='thread.hasAttachments' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paperclip' y='0')
          .o-grid__item.o-grid__item--12
            .c-messages-threads__thread-preview.o-bodytext.o-bodytext--size-small {{ thread.lastMessage && messagePreview(thread.lastMessage.body) }}
  li.c-messages-threads__loading(v-if='loading && results.length === 0')
    c-loading-animation
  li.c-messages-threads__item(v-else-if='results.length === 0')
    h3.o-bodytext.o-bodytext--600.o-bodytext--size-medium {{ Dictionary.T.ThreadsNotFound }}
  scroll-loader(:loader-method='loadMore' v-if='!noMoreThreads')
    c-loading-animation
</template>
<script>
// TODO Fix If its USER that has responded to thread class when we have global id
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'c-messages-threads',
  data() {
    return {}
  },
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('user', ['userId', 'email']),
    ...mapState('messages', ['page', 'results', 'totalResults']),
    ...mapGetters('messages', ['all']),
    noMoreThreads() {
      return this.results.length === 0 || this.results.length === this.totalResults
    }
  },
  methods: {
    loadMore() {
      if (this.results.length === 0 || this.results.length !== this.totalResults) {
        this.$store.dispatch('messages/getResultsPage', { page: this.page + 1, override: false })
      }
    },
    selectThread(id) {
      this.$store.dispatch('messages/selectThread', id)
    },
    userRespondedToThread(id) {
      return id === this.userId
    },
    messagePreview(body) {
      const span = document.createElement('span')
      span.innerHTML = body
      return span.textContent || span.innerText
    },
    formatDate(d) {
      return moment.utc(d).local().format('DD.MM.YYYY, HH:mm')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-messages-threads {
  height: 100%;

  &__item {
    padding: 20px 15px 20px 25px;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: $grey-6;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $ocean-primary;
      }
    }

    &.new {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 28px;
        left: 8px;
        width: 8px;
        height: 8px;
        background-color: $ocean-primary;
        border-radius: 50%;
      }
    }

    &.respond {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 25px;
        left: 5px;
        background-image: url(map-get($sprites, 'icon-arrow-answered'));
        background-size: 100%;
        background-position: center;
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
      }
    }

    & + & {
      border-top: 1px solid $grey-4;
    }
  }
  &__thread-head {
  }
  &__thread-info {
    color: $grey-2;
    text-align: right;

    svg {
      width: 14px;
      height: 16px;
      fill: $grey-2;
    }
  }
  &__thread-preview {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $grey-2;
  }
}
</style>
