<template lang="pug">
.c-messages-sorting.o-grid.o-grid--justify-content-space.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--grow-0(v-if='filterOptions.length > 1')
      .c-messages-sorting__grid.o-grid.o-grid--justify-content-start.o-grid--align-items-center.o-grid--gutter-tiny.o-grid--wrap-nowrap
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            span.o-bodytext.o-bodytext--size-small.o-bodytext--inria {{ Dictionary.R.Role }}:
          .c-messages-sorting__options.o-grid__item.o-grid__item--grow-0
            .c-messages-sorting__option(:key='key' v-for='(option, key) in filterOptions')
              input.o-input(:id='`${id}-${key}`', :name='option.value', :value='option.value' placeholder=' ' store='messages' type='radio' v-model='value')
              label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
    .o-grid__item.o-grid__item--grow-0.c-messages-sorting__show-archived
      input#show-archived.o-input.o-input--switch(
        :checked='filters.includeArchived'
        @change='showArchivedThreads'
        name='showArchivedThreads'
        placeholder=' '
        type='checkbox'
        value='1'
      )
      label.o-label(for='show-archived')
        span {{ Dictionary.S.ShowArchived }}
    .o-grid__item.o-grid__item--5.o-grid__item--align-right
      c-state-sorting(
        :options='sortingOptions'
        action='setSorting'
        default-label-text='Sortér'
        order-by='orderBy'
        order-direction='orderDirection'
        store='messages'
      )
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-messages-sorting',
  data() {
    return {
      id: this._uid,
      value: '',
      sortingOptions: [
        { value: { orderBy: '', orderDirection: 'ASC' }, labelText: this.Dictionary.Sorting.OldestFirst },
        { value: { orderBy: '', orderDirection: 'DESC' }, labelText: this.Dictionary.Sorting.NewestFirst }
      ]
    }
  },
  computed: {
    ...mapState('messages', ['filters', 'hasApplicantRole', 'hasPublisherRole']),
    filterOptions() {
      const filterOptions = [{ value: null, labelText: 'Alle' }]
      if (this.hasPublisherRole) {
        filterOptions.push({ value: 'Publisher', labelText: 'Udlejer' })
      }
      if (this.hasApplicantRole) {
        filterOptions.push({ value: 'Applicant', labelText: 'Lejer' })
      }
      return filterOptions
    }
  },
  props: {},
  methods: {
    showArchivedThreads(event) {
      this.$store.dispatch('messages/postSearch', { includeArchived: event.target.checked })
    }
  },
  created() {
    this.value = this.filterOptions[0].value
  },
  watch: {
    value(newVal) {
      this.$store.dispatch('messages/postSearch', { role: newVal })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-messages-sorting {
  height: 100%;

  &__grid {
    height: 100%;
  }

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__option {
    outline: toRem(1px) solid $ocean-primary;
    margin: 0;

    & + & {
      border-left: toRem(1px) solid $ocean-primary;
    }

    .o-input[type='radio'] {
      &:checked {
        + label {
          background-color: $crab-primary;
          color: $ocean-primary;

          @include min('desktop') {
            background-color: $ocean-primary;
            color: $white;
          }
        }
      }
      + label {
        padding: toRem(6px) toRem(14px);
        font-family: 'Inria Serif', serif;
        background-color: $white;
        color: $ocean-primary;

        &::before,
        &:after {
          display: none;
        }
      }
    }
  }

  &__show-archived {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    .o-label {
      font-family: 'Inria Serif', serif;
      line-height: 26px;
      display: flex;

      span {
        width: calc(100% - 40px);
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
