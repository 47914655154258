<template lang="pug">
.c-filter-input-checklist
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id' v-if='model && model.labelText') {{ model.labelText }}
    fieldset
      .o-grid.o-grid--direction-column.o-grid--justify-content-center.o-grid--gutter-tiny
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0(:key='key' v-for='(option, key) in model.options')
            .o-input__wrap
              input.o-input(
                :checked='valueChecked(option.value)',
                :id='`${id}-${key}`',
                :name='model.filter',
                :value='option.value'
                @change='valueChanged'
                placeholder=' '
                type='checkbox'
              )
              label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
    label.o-label.o-label--helper(:for='id' v-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-filter-input-checklist',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('search', ['filters'])
  },
  methods: {
    valueChanged(event) {
      if (event.target.checked) {
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: event.target.value })
      } else {
        this.$store.dispatch('search/removeFilter', { filter: this.model.filter, value: event.target.value })
      }
    },
    valueChecked(value) {
      let checked = false
      if (Array.isArray(this.filters[this.model.filter])) {
        checked = this.filters[this.model.filter].indexOf(value) > -1
      } else {
        checked = this.filters[this.model.filter] === value
      }

      return checked
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-checklist {
  fieldset {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .o-grid {
    overflow: hidden;
  }
}
</style>
