/* eslint-disable prettier/prettier */
<template lang="pug">
.c-administrators-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      component(:is='selected ? "c-adm-administrator" : "c-adm-overview"', :key='(selected && selected.id) || "overview"', :model='model')
  template(v-else)
    c-adm-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-adm-administrator.o-transition__overlay(:model='model' v-if='selected')
</template>
<script>
import { mapState } from 'vuex'

import AdministratorsManagementOverview from './adm-overview'
import AdministratorsManagementUser from './adm-administrator'
export default {
  name: 'c-administrators-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      key: Math.floor(Math.random() * 9999)
    }
  },
  components: {
    'c-adm-overview': AdministratorsManagementOverview,
    'c-adm-administrator': AdministratorsManagementUser
  },
  computed: {
    ...mapState('administratorsManagement', ['selected'])
  },
  watch: {
    selected() {
      this.key = Math.floor(Math.random() * 9999999999)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-administrators-management {
}
</style>
