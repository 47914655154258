<template lang="pug">
.c-tab(v-if='isActive')
  slot
</template>
<script>
export default {
  name: 'c-tab',
  data() {
    return {
      id: this._uid,
      isActive: false
    }
  },
  props: {
    name: { required: true },
    selected: { default: false },
    disabled: { default: false },
    disabledMessage: {}
  },
  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-')
    }
  },

  mounted() {
    this.isActive = this.selected
  }
}
</script>
<style lang="scss" scoped>
.c-tab {
}
</style>
