<template lang="pug">
.c-loading-animation(
  :class='{ "c-loading-animation--overlay": overlay, "c-loading-animation--grey": grey, "c-loading-animation--center": center, "c-loading-animation--inline": inline }'
)
  .c-loading-animation__container
    .c-loading-animation__loader(:style='{ "margin-top": offsetTop + "px" }')
      svg.loader__box(version='1.1' viewBox='0 0 227 298' x='0px' xmlns='http://www.w3.org/2000/svg' y='0px')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 218.6,200.7 218.6,97.3 113.5,9.8 113.5,61.4')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 113.5,199.5 113.5,149 166,105.2 113.5,61.4')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 113.5,199.5 113.5,149 61,105.2 113.5,61.4')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 113.5,199.5 166,156.9 166,105.2')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 113.5,199.5 61,156.9 61,105.2')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 218.6,200.7 166,156.9')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 8.4,200.7 61,156.9')
        polyline.c-loading-animation__loader-line(:style='{ stroke: color }' points='113.5,288.2 8.4,200.7 8.4,97.3 113.5,9.8')
      .c-loading-animation__loader-text(:style='{ color: color }' v-if='showText') {{ Dictionary.L.Loading }}
</template>
<script>
export default {
  name: 'c-loading-animation',
  props: {
    overlay: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    offsetTop: {
      type: String,
      default: '30'
    },
    inline: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    showText: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-loading-animation {
  $loader-width: 100px;
  $loader-color: $ocean-primary;
  $loader-time: 3.5;
  $loader-thickness: 3;
  $loader-no-of-lines: 8;
  $root: &;

  &--overlay {
    position: absolute;
    z-index: z('elements');
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
  }

  &--grey {
    background-color: rgba(233, 233, 233, 0.8);
  }

  &--center {
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }

  &--inline {
    #{$root}__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;

      svg {
        width: 20px;
        height: 26px;
      }

      &-text {
        margin-left: 10px;
        width: auto;
      }
    }
  }

  &__container {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__loader {
    width: $loader-width;
    display: block;
    margin: 0 auto;
    svg {
      width: 100px;
      height: 131px;
    }
    &-line {
      fill: none;
      stroke: $loader-color;
      stroke-width: $loader-thickness;
      stroke-miterlimit: 10;
      stroke-dasharray: 500;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dashoffset: 500;
      animation: loader-animation ($loader-time * 1s) linear infinite;
      @for $i from 0 through $loader-no-of-lines {
        &:nth-child(#{$i}) {
          animation-delay: #{calc($i / 10) * $loader-time * 1s};
        }
      }
    }
    &-text {
      position: relative;
      text-align: center;
      color: $loader-color;
      font-weight: 700;
      &::after {
        position: absolute;
        content: '';
        animation: loader-text-animation 2s linear infinite;
      }
    }
  }

  @keyframes loader-animation {
    0%,
    100% {
      stroke-dashoffset: 500;
    }
    50% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes loader-text-animation {
    0% {
      content: '';
    }
    33% {
      content: '.';
    }
    66% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }
}
</style>
