import apiClient from '@/_helpers/api-client'
export const searchService = {
  getSuggestions,
  postSearch,
  postSearchLocations,
  getSearchAgents,
  postSearchAgent,
  patchSearchAgent,
  deleteSearchAgent,
  getCompanies,
  getFacilities,
  getFacilityLocations,
  getOrganizations,
  getPropertyTypes,
  getResidenceTypes,
  getIntegrationJobs,
  getPropertiesByCompanies,
  getPropertiesByCompaniesResidences,
  postAdvertReindex,
  postOfferReindex,
  postAdvertApplicantsReindex,
  postOfferApplicantsReindex,
  postApplicantReindex
}

async function getSuggestions(query) {
  return await apiClient({
    url: `/search/suggestions/${query}`,
    method: 'GET'
  })
}

async function postSearch(data) {
  return await apiClient({
    url: '/search',
    method: 'POST',
    data
  })
}
async function postSearchLocations(data) {
  return await apiClient({
    url: '/search/properties',
    method: 'POST',
    data
  })
}
async function getSearchAgents() {
  return await apiClient({
    url: '/search/agents',
    method: 'GET'
  })
}

async function postSearchAgent(data) {
  return await apiClient({
    url: '/search/agents',
    method: 'POST',
    data
  })
}
async function patchSearchAgent(id, data) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/search/agents/${id}`,
    method: 'PATCH',
    data
  })
}

async function deleteSearchAgent(id) {
  return await apiClient({
    url: `/search/agents/${id}`,
    method: 'DELETE'
  })
}

async function getCompanies() {
  return await apiClient({
    url: '/data/companies',
    method: 'GET'
  })
}

async function getFacilities() {
  return await apiClient({
    url: '/data/facilities',
    method: 'GET'
  })
}
async function getFacilityLocations() {
  return await apiClient({
    url: '/data/facility-locations',
    method: 'GET'
  })
}
async function getPropertyTypes() {
  return await apiClient({
    url: '/data/property-types',
    method: 'GET'
  })
}
async function getResidenceTypes() {
  return await apiClient({
    url: '/data/residence-types',
    method: 'GET'
  })
}
async function getOrganizations() {
  return await apiClient({
    url: '/data/membership-organizations',
    method: 'GET'
  })
}
async function getIntegrationJobs() {
  return await apiClient({
    url: '/data/management/integrationjobs',
    method: 'GET'
  })
}
async function getPropertiesByCompanies(data) {
  return await apiClient({
    url: '/search/properties-by-companies',
    method: 'POST',
    data
  })
}
async function getPropertiesByCompaniesResidences(propertyId, data) {
  return await apiClient({
    url: `/search/properties-by-companies/${propertyId}/residences`,
    method: 'POST',
    data
  })
}
async function postAdvertReindex(advertId) {
  return await apiClient({
    url: `/search/indexes/advert/reindex?id=${advertId}`,
    method: 'POST'
  })
}
async function postOfferReindex(offerId) {
  return await apiClient({
    url: `/search/indexes/offers/reindex?id=${offerId}`,
    method: 'POST'
  })
}
async function postAdvertApplicantsReindex(advertId) {
  return await apiClient({
    url: `/search/indexes/applicant/rebuild/advert/${advertId}`,
    method: 'POST'
  })
}
async function postOfferApplicantsReindex(offerId) {
  return await apiClient({
    url: `/search/indexes/applicant/rebuild/offer/${offerId}`,
    method: 'POST'
  })
}
async function postApplicantReindex(applicantId) {
  return await apiClient({
    url: `/search/indexes/applicant/reindex?applicantId=${applicantId}`,
    method: 'POST'
  })
}
