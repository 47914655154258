<template lang="pug">
.c-tab-navigation(:class='[{ "c-tab-navigation--clickable": clickable }]')
  button.c-tab-navigation__tab(
    :class='[{ "c-tab-navigation__tab--active": active === tab }, { "c-tab-navigation__tab--clickable": clickable }]',
    :key='`tab-navigation-${id}-${tab}`',
    :tabindex='clickable ? "" : "-1"'
    @click='clickable ? $emit("clicked", tab) : ""'
    v-for='tab in total'
  )
</template>
<script>
export default {
  name: 'c-tab-navigation',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    total: {
      required: true
    },
    active: {
      required: true
    },
    clickable: {
      default: false
    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.c-tab-navigation {
  $root: &;
  display: flex;
  justify-content: center;
  align-items: center;

  &__tab {
    pointer-events: none;
    background: $grey-5;
    flex: 0 1 toRem(90px);
    margin: 0 toRem(4px);
    border: none;
    height: toRem(3px);
    &--active {
      background: $crab-darker;
    }

    #{$root}--clickable & {
      pointer-events: all;
      cursor: pointer;
    }
  }
}
</style>
