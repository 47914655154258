<template lang="pug">
.c-applicant-history
  .c-applicant-history__form-section
    .c-applicant-history__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.historyTabHeadline }}
  .c-applicant-history__form-section
    //- c-tabs.c-ap-applicant-history__tabs(:start-tab='0')
    //-   c-tab(:disabled='false', :name='Dictionary.A.ApplicantsVariant' disabled-message='disable message')
    .c-applicant-history__filters.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
          c-fc-date(:model='applicantStartData' @validate='validate($event, "applicantStart")' v-model='applicantAudits.applicantStart.value')
        .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
          c-fc-date(:model='applicantStopData' @validate='validate($event, "applicantStop")' v-model='applicantAudits.applicantStop.value')
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
          c-fc-select(:model='applicantAudits.type' @validate='validate($event, "type")' v-model='applicantAudits.type.value')
        .o-grid__item.o-grid__item--desktop-2
          button.o-button.c-applicant-history__filters__button(@click='onPageChange({ currentPage: 1 })' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
        .o-grid__item.o-grid__item--desktop-1
          button.o-link.c-applicant-history__filters__button(
            :disabled='!applicantAudits.applicantStart.value && !applicantAudits.applicantStop.value && !applicantAudits.type.value'
            @click='handleApplicantAuditsReset'
            role='button'
            v-debounce.keyup='500'
          ) {{ Dictionary.R.Reset }}
    vue-good-table(
      :columns='userAuditsColumns',
      :pagination-options='paginationSettingsData',
      :rows='userAuditsData',
      :sort-options='{ enabled: false }',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-sort-change='onSortChange'
      mode='remote'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
        template(v-if='props.column.field == "message"')
          span.c-applicant-history__message {{ props.row.message }}
        template(v-else-if='props.column.field == "applicantNumber"')
          button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.applicantNumber }}
        template(v-else-if='props.column.field == "owner"')
          span.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small {{ reformatOwner(props.row) }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
      //- c-tab(:disabled='false', :name='Dictionary.S.SignUps' disabled-message='disable message')
      //-   .c-applicant-history__filters.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
      //-     .o-grid__group
      //-       .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
      //-         c-fc-date(
      //-           :model='applicationsAudits.applicationStart'
      //-           @validate='validate($event, "applicationStart")'
      //-           v-model='applicationsAudits.applicationStart.value'
      //-         )
      //-       .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
      //-         c-fc-date(
      //-           :model='applicationsAudits.applicationStop'
      //-           @validate='validate($event, "applicationStop")'
      //-           v-model='applicationsAudits.applicationStop.value'
      //-         )
      //-       .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
      //-         c-fc-text(
      //-           :model='applicationsAudits.applicationSearch'
      //-           @submit='handleApplicationAuditsSearch()'
      //-           v-model='applicationsAudits.applicationSearch.value'
      //-         )
      //-       .o-grid__item.o-grid__item--desktop-2
      //-         button.o-button.c-applicant-history__filters__button(@click='handleApplicationAuditsSearch()' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
      //-       .o-grid__item.o-grid__item--desktop-1
      //-         button.o-link.c-applicant-history__filters__button(
      //-           :disabled='!applicationsAudits.applicationStart.value && !applicationsAudits.applicationStop.value && !applicationsAudits.applicationSearch.value'
      //-           @click='handleApplicationAuditsReset'
      //-           role='button'
      //-           v-debounce.keyup='500'
      //-         ) {{ Dictionary.R.Reset }}
      //-   vue-good-table(
      //-     :columns='userApplicationsColumns',
      //-     :pagination-options='{ enabled: pageSize !== userApplications.length, perPage: 10 }',
      //-     :rows='userApplications'     
      //-     styleClass='o-table'
      //-   )
      //-     template(slot='emptystate') {{ model.noResultsText }}
      //-     template(slot='table-row' slot-scope='props') 
      //-       template(v-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
      //-       template(v-else-if='props.column.field == "applicantNumber"')
      //-         button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.applicantNumber }}
      //-       template(v-else) {{ props.formattedRow[props.column.field] }}
      //-     template(slot='pagination-bottom' slot-scope='props')
      //-       TablePagination(
      //-         :page-changed='props.pageChanged',
      //-         :page-size='10',
      //-         :per-page-changed='props.perPageChanged',
      //-         :total='userApplications.length'
      //-       )
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import { auditsService } from '@/_services'
import moment from 'moment'

export default {
  name: 'c-applicant-history',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-date': FormControllDate,
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    VueGoodTable
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      totalResults: 0,
      type: '',
      userAudits: [],
      userAuditsServerParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10
      },
      userAuditsColumns: [
        {
          label: this.Dictionary.D.DateTime,
          field: 'created',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.T.Type,
          field: 'action',
          formatFn: this.reFormatAction
        },
        {
          label: this.Dictionary.A.Action,
          field: 'message'
        },
        {
          label: this.Dictionary.P.Performer,
          field: 'performerId',
          formatFn: this.reFormatPerformer
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'owner'
        }
      ],
      // userApplicationsColumns: [
      //   {
      //     label: this.Dictionary.D.DateTime,
      //     field: 'created',
      //     formatFn: this.reFormatDate,
      //     width: '110px'
      //   },
      //   {
      //     label: this.Dictionary.T.Type,
      //     field: 'firstName'
      //   },
      //   {
      //     label: this.Dictionary.P.Performer,
      //     field: 'address'
      //   },
      //   {
      //     label: this.Dictionary.A.Action,
      //     field: 'email'
      //   },
      //   {
      //     label: this.Dictionary.O.Owner,
      //     field: 'mobilePhone'
      //   }
      // ],
      applicantAudits: {
        applicantStart: {
          helperText: null,
          value: null,
          placeholderText: this.Dictionary.F.From,
          isMandatory: false,
          readOnly: false,
          validation: {},
          key: 0
        },
        applicantStop: {
          helperText: null,
          value: null,
          placeholderText: this.Dictionary.T.To,
          isMandatory: false,
          readOnly: false,
          validation: {
            maxDate: moment.utc().format('YYYY-MM-DD')
          },
          key: 0
        },
        type: {
          helperText: null,
          placeholderText: this.Dictionary.T.Type,
          value: null,
          isMandatory: false,
          readOnly: false,
          options: [
            { name: this.Dictionary.UserAuditActions.Condition, value: 'State' },
            { name: this.Dictionary.UserAuditActions.Accept, value: 'Accept' },
            { name: this.Dictionary.UserAuditActions.Refusal, value: 'Refusal' },
            { name: this.Dictionary.UserAuditActions.Verification, value: 'Verification' },
            { name: this.Dictionary.UserAuditActions.Deleted, value: 'Deleted' },
            { name: this.Dictionary.UserAuditActions.Changed, value: 'Changed' },
            { name: this.Dictionary.UserAuditActions.Renewal, value: 'Renewal' },
            { name: this.Dictionary.UserAuditActions.Completed, value: 'Completed' },
            { name: this.Dictionary.UserAuditActions.Payments, value: 'Payments' },
            { name: this.Dictionary.UserAuditActions.Email, value: 'Email' },
            { name: this.Dictionary.UserAuditActions.SMS, value: 'SMS' },
            { name: this.Dictionary.UserAuditActions.Advert, value: 'Advert' },
            { name: this.Dictionary.UserAuditActions.Documentation, value: 'Documentation' },
            { name: this.Dictionary.UserAuditActions.Anonymize, value: 'Anonymize' },
            { name: this.Dictionary.UserAuditActions.WaitingList, value: 'WaitingList' }
          ],
          validation: null
        }
      }
      // applicationsAudits: {
      //   applicationStart: {
      //     helperText: null,
      //     value: null,
      //     placeholderText: this.Dictionary.F.From,
      //     isMandatory: false,
      //     readOnly: false,
      //     validation: {},
      //     key: 0
      //   },
      //   applicationStop: {
      //     helperText: null,
      //     value: null,
      //     placeholderText: this.Dictionary.T.To,
      //     isMandatory: false,
      //     readOnly: false,
      //     validation: {
      //       maxDate: moment.utc().format('YYYY-MM-DD')
      //     },
      //     key: 0
      //   },
      //   applicationSearch: {
      //     helperText: null,
      //     placeholderText: this.Dictionary.S.SearchResidence,
      //     value: null,
      //     isMandatory: false,
      //     validation: null
      //   }
      // }
    }
  },
  computed: {
    ...mapState('applicantsManagement', ['selected']),
    ...mapState('usersManagement', ['users']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('search', ['organizations', 'companies']),
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.userAuditsServerParams.page })
      return paginationSettingsData
    },
    applicantStartData() {
      const applicantStart = Object.assign({}, this.applicantAudits.applicantStart)
      applicantStart.validation.maxDate = this.applicantAudits.applicantStop.value || moment.utc().format('YYYY-MM-DD')
      return applicantStart
    },
    applicantStopData() {
      const applicantStopData = Object.assign({}, this.applicantAudits.applicantStop)
      applicantStopData.validation.minDate = this.applicantAudits.applicantStart.value
      return applicantStopData
    },
    // applicationStartData() {
    //   const applicationStartData = Object.assign({}, this.applicationsAudits.applicationStart)
    //   applicationStartData.validation.maxDate = this.applicationsAudits.applicationStop.value || moment.utc().format('YYYY-MM-DD')
    //   return applicationStartData
    // },
    // applicationStopData() {
    //   const applicationStopData = Object.assign({}, this.applicationsAudits.applicationStop)
    //   applicationStopData.validation.minDate = this.applicationsAudits.applicationStart.value
    //   return applicationStopData
    // },
    userAuditsData() {
      const userAuditsData = Object.assign([], this.userAudits)
      const missingPerformers = []
      userAuditsData.forEach(audit => {
        const performer = this.users.find(u => u.id === audit.performerId)

        if (performer) {
          audit.performerName = performer && `${performer.firstName} ${performer.lastName}`
        } else {
          if (!missingPerformers.includes(audit.performerId)) {
            missingPerformers.push(audit.performerId)
          }
        }
        if (audit.payload !== null) {
          const membershipOrganization = this.organizations.find(o => o.id === audit.payload)

          audit.membershipOrganization = membershipOrganization && membershipOrganization.name
        }
      })
      if (missingPerformers.length > 0) {
        this.$store.dispatch('usersManagement/getUsersStatus', { ids: missingPerformers, keepRecords: true })
      }
      return userAuditsData
    }
    // userApplicationsAuditsData() {
    //   const userApplicationsAuditsData = Object.assign([], this.userApplications)
    //   const missingPerformers = []
    //   userApplicationsAuditsData.forEach(audit => {
    //     const performer = this.users.find(u => u.id === audit.performerId)

    //     if (performer) {
    //       audit.performerName = performer && `${performer.firstName} ${performer.lastName}`
    //     } else {
    //       missingPerformers.push(audit.performerId)
    //     }
    //     if (audit.payload !== null) {
    //       const membershipOrganization = this.organizations.find(o => o.id === audit.payload)

    //       audit.membershipOrganization = membershipOrganization && membershipOrganization.name
    //     }
    //   })
    //   if (missingPerformers.length > 0) {
    //     this.$store.dispatch('usersManagement/getUsersStatus', { ids: missingPerformers, keepRecords: true })
    //   }
    //   return userApplicationsAuditsData
    // }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value, 'DD-MM-YYYY HH:mm:ss') : ''
    },
    reFormatAction(value) {
      return value ? this.Dictionary.UserAuditActions[value] : value
    },
    reFormatPerformer(value) {
      if (value === '11111111-1111-1111-1111-111111111111') {
          return 'system@findbolig.nu'
      }
      const user = this.users.find(user => user.id === value)
      const userName = user && ((user.firstName && user.lastName) || user.email)
      return userName || value
    },
    reformatOwner(value) {
      if (value && value.organizationId) {
        const org = this.organizations.find(org => org.id === value.organizationId)
        const orgName = org && org.name
        return orgName || value
      } else if (value && value.companyId) {
        const company = this.companies.find(company => company.id === value.companyId)
        const companyName = company && company.name
        return companyName || value
      } else {
        return null
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleApplicantAuditsReset() {
      this.applicantAudits.applicantStart.value = null
      this.applicantAudits.applicantStop.value = null
      this.applicantAudits.type.value = null

      this.onPageChange({ currentPage: 1 })
    },
    async handleApplicantAuditsSearch() {
      try {
        const data = {
          page: this.userAuditsServerParams.page,
          pageSize: this.userAuditsServerParams.perPage
        }
        if (this.applicantAudits.type.value) {
          data.action = this.applicantAudits.type.value
        }
        if (this.applicantAudits.applicantStart.value) {
          data.from = moment.utc(this.applicantAudits.applicantStart.value).toDate().toISOString()
        }
        if (this.applicantAudits.applicantStop.value) {
          data.to = moment.utc(this.applicantAudits.applicantStop.value).add(1, 'days').toDate().toISOString()
        }
        const response = await auditsService.getAuditsUsers(this.selected.userId, data)
        if (response && response.status === 200) {
          this.userAudits = response.data.results
          this.totalResults = response.data.totalResults
        }
      } catch (error) {
        // console.log(error)
      }
    },
    // handleApplicationAuditsReset() {
    //   this.applicationsAudits.applicationStart.value = null
    //   this.applicationsAudits.applicationStop.value = null
    //   this.applicationsAudits.applicationSearch.value = null
    //   this.handleApplicationAuditsSearch()
    // },
    // async handleApplicationAuditsSearch() {
    //   try {
    //     const parameters = {
    //       UserId: this.selected.userId,
    //       // Action: this.applicationsAudits.type.value,
    //       From: this.applicationsAudits.applicationStart.value,
    //       To: this.applicationsAudits.applicationStop.value
    //     }

    //     let query = ''

    //     Object.keys(parameters).forEach((k, i) => {
    //       if (parameters[k]) {
    //         const param = i > 0 ? `&${k}=${parameters[k]}` : `${k}=${parameters[k]}`
    //         query = query + param
    //       }
    //     })
    //     const response = await auditsService.getAuditsApplications(query)
    //     if (response && response.status === 200) {
    //       this.userApplications = response.data
    //     }
    //   } catch (error) {
    //     // console.log(error)
    //   }
    // },
    getInitialData() {
      if (this.organizations.length === 0) {
        this.$store.dispatch('search/getOrganizations')
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign(this.userAuditsServerParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.handleApplicantAuditsSearch()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 })
      this.handleApplicantAuditsSearch()
    },

    onSortChange(params) {
      this.updateParams({
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.handleApplicantAuditsSearch()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.handleApplicantAuditsSearch()
    }
  },
  created() {
    this.getInitialData()
    this.handleApplicantAuditsSearch()
  }
}
</script>
<style lang="scss" scoped>
.c-applicant-history {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  &__cancel {
    text-decoration: underline;
    display: block;
  }

  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
  &__filters {
    padding: toRem(16px 20px);
    background-color: $grey-5;
    &__button {
      width: 100%;
      min-height: 52px;
    }
  }

  &__message {
    white-space: pre-wrap;
  }
}
</style>
