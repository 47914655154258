<template lang="pug">
.c-ub4-sc-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
      c-fc-number(:model='searchSumCodeNumberData' v-model='sumcodeNo')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-5(v-if='searchCompanyIdData.options.length > 0')
      c-fc-select(:model='searchCompanyIdData' v-model='companyId')
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-ub4-sc-search__button(:disabled='!companyId && !sumcodeNo' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllNumber from '@/components/form/controlls/form-number'
export default {
  name: 'c-ub4-sc-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      searchSumCodeNumber: {
        helperText: this.model.searchHelperText,
        value: null,
        placeholderText: this.model.searchPlaceholderText,
        isMandatory: false,
        validation: {
          errorMessage: '',
          mask: '',
          displayRule: ''
        }
      },
      searchCompanyId: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.O.Owner,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-number': FormControllNumber
  },
  computed: {
    ...mapState('ub4SumCodesManagement', ['selectedCompanyId', 'selectedSumCodeNumber']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('search', ['companies', 'organizations']),
    searchCompanyIdData() {
      const searchCompanyIdData = Object.assign({}, this.searchCompanyId)
      searchCompanyIdData.value = this.companyId || null
      searchCompanyIdData.options = this.administrators.map(admin => {
        const adminGroup = { group: true, label: admin.name, options: [] }
        admin.companies.forEach(adminCompanyId => {
          const company = this.companies.find(company => company.id === adminCompanyId)
          if (company) {
            adminGroup.options.push({
              name: company.name,
              value: adminCompanyId
            })
          }
        })
        return adminGroup
      })
      return searchCompanyIdData
    },
    companyId: {
      get() {
        return this.selectedCompanyId
      },
      set(value) {
        this.$store.commit('ub4SumCodesManagement/updateData', { selectedCompanyId: value })
      }
    },
    searchSumCodeNumberData() {
      const searchSumCodeNumberData = Object.assign({}, this.searchSumCodeNumber)
      searchSumCodeNumberData.value = this.sumcodeNo || null
      return searchSumCodeNumberData
    },
    sumcodeNo: {
      get() {
        return this.selectedSumCodeNumber
      },
      set(value) {
        this.$store.commit('ub4SumCodesManagement/updateData', { selectedSumCodeNumber: value })
      }
    }
  },
  methods: {
    handleReset() {
      this.companyId = null
      this.sumcodeNo = null
    }
  },
  created() {
    if (this.administrators.length === 0) this.$store.dispatch('usersManagement/getPropertyAdministrators')
    if (this.companies.length === 0) this.$store.dispatch('search/getCompanies')
    if (this.organizations.length === 0) this.$store.dispatch('search/getOrganizations')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-sc-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
