<template lang="pug">
.c-cm-company
  .c-cm-company__top
    button.c-cm-company__close(@click='close')
      svg.c-cm-company__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-cm-company__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-cm-company__content
    h2.c-cm-company__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.name ? selected.name : model.companyTranslations.newCompanyHeadline }}
    c-tabs.c-rm-residence__tabs(:start-tab='0')
      c-tab(:name='model.companyTranslations.companyTabName || "company"')
        .c-cm-company__form.o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-8
              .c-cm-company__form-section
                .c-cm-company__form-heading.o-heading.o-heading--size-4 {{ model.companyTranslations.ownerSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
                      c-fc-text(:model='mutableData.name' @validate='validate($event, "name")' v-model='mutableData.name.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
                      c-fc-number(:model='mutableData.number' @validate='validate($event, "number")' v-model='mutableData.number.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-12
                      c-fc-textbox(:model='mutableData.notes' @validate='validate($event, "notes")' v-model='mutableData.notes.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-12
                      c-fc-number(:model='mutableData.cvr' @validate='validate($event, "cvr")' v-model='mutableData.cvr.value')
              .c-cm-company__form-section
                .c-cm-company__form-heading.o-heading.o-heading--size-4 {{ model.companyTranslations.aboutSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12
                      c-fc-select(:model='mutableData.rentModel' @validate='validate($event, "rentModel")' v-model='mutableData.rentModel.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-select(:model='organizationsData' @validate='validate($event, "organizationId")' v-model='mutableData.organizationId.value') 
                    .o-grid__item.o-grid__item--12
                      c-fc-checkbox(:model='mutableData.screenAdverts' @validate='validate($event, "screenAdverts")' v-model='mutableData.screenAdverts.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-checkbox(:model='mutableData.displayContactDetails' @validate='validate($event, "displayContactDetails")' v-model='mutableData.displayContactDetails.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-select(
                        :model='administratorsData'
                        @validate='validate($event, "propertyAdministratorId")'
                        v-model='mutableData.propertyAdministratorId.value'
                      ) 
        .c-cm-company__form.c-cm-company__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
          .o-grid__group
            .o-grid__item.o-grid__item--12.c-cm-company__form-section--border-top(v-if='selected.id')
              button.c-cm-company__delete.o-link.o-link--error(@click='handleDelete') {{ model.companyTranslations.deleteCompanyText }}
            .o-grid__item.o-grid__item--12.c-cm-company__form-section--border-top
              .o-grid.o-grid--gutter-small.o-grid--align-items-center
                .o-grid__group
                  .o-grid__item.o-grid__item--grow-0
                    button.o-link.c-cm-company__cancel(@click='close') {{ Dictionary.C.Cancel }}
                  .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                    button.o-button.c-cm-company__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(false)' v-if='selected.id') {{ Dictionary.S.Save }}
                    button.o-button.c-cm-company__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
      c-tab(:disabled='!selected.id', :disabled-message='model.companyTranslations.logoDisableReason', :name='model.companyTranslations.logoTabName || "logo"')
        .o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-8
              c-media-upload-section(
                :list='logoItems',
                :max-items='1',
                :state-management='mediaStateManagement',
                :translations='sectionTranslations()'
                group='logo'
                media-type='image'
                ref='section-logo'
              )
</template>
<script>
import { mapState } from 'vuex'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllUpload from '@/components/form/controlls/form-upload'
import MediaUploadSection from '@/components/media-upload/mu-section'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
export default {
  name: 'c-cm-company',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-number': FormControllNumber,
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-textbox': FormControllTextBox,
    'c-fc-upload': FormControllUpload,
    'c-media-upload-section': MediaUploadSection,
    'c-fc-checkbox': FormControllCheckbox
  },
  data() {
    return {
      id: this._uid,

      streetAutosuggest: {
        show: false,
        results: []
      },
      mediaStateManagement: {
        store: 'companiesManagement',
        actionAdd: 'addMedia',
        actionRemove: 'removeMedia',
        actionMove: 'moveMedia'
      },
      validation: {},
      mutableData: {
        name: {
          helperText: this.model.companyTranslations.companyNameHelperText,
          placeholderText: this.Dictionary.C.CompanyName,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        number: {
          helperText: this.model.companyTranslations.companyNumberHelperText,
          placeholderText: this.Dictionary.C.CompanyNumber,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        cvr: {
          helperText: this.model.companyTranslations.companyCvrHelperText,
          placeholderText: this.Dictionary.C.Cvr,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 10000000,
            maxValue: 99999999
          }
        },
        notes: {
          helperText: this.model.companyTranslations.companyNoteHelperText,
          value: null,
          placeholderText: this.Dictionary.N.Notes,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        rentModel: {
          helperText: this.model.companyRentModelHelperText,
          value: null,
          placeholderText: this.Dictionary.R.RentModel,
          isMandatory: true,
          options: [
            {
              name: this.Dictionary.RentModels.Advert,
              value: 'Advert'
            },
            {
              name: this.Dictionary.RentModels.MemberAdvert,
              value: 'MemberAdvert'
            },
            {
              name: this.Dictionary.RentModels.WaitingList,
              value: 'WaitingList'
            }
          ],
          validation: null
        },
        organizationId: {
          helperText: this.model.companyOrganizationHelperText,
          value: null,
          placeholderText: this.Dictionary.O.Organization,
          isMandatory: false,
          options: [],
          validation: null
        },
        propertyAdministratorId: {
          helperText: this.model.companyTranslations.administratorHelperText,
          value: null,
          placeholderText: this.Dictionary.A.Administrator,
          isMandatory: true,
          options: [],
          validation: null
        },
        screenAdverts: {
          value: true,
          helperText: this.model.companyTranslations.screenAdvertsHelperText,
          placeholderText: this.model.companyTranslations.screenAdvertsPlaceholderText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        displayContactDetails: {
          value: false,
          helperText: this.model.companyTranslations.showContactInfoHelperText,
          placeholderText: this.model.companyTranslations.showContactInfoPlaceholderText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
      }
    }
  },
  computed: {
    ...mapState('companiesManagement', ['selected']),
    ...mapState('administratorsManagement', ['administrators']),
    ...mapState('search', ['organizations']),
    ...mapState('loadingIndicator', ['loading']),
    // ...mapGetters('companiesManagement', ['submitmodels']),
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true)
    },
    dataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }
        // console.log(different, key)
        return different
      })

      dataChanged = !!target
      return dataChanged
    },
    organizationsData() {
      const organizationsData = this.mutableData.organizationId
      this.organizations.forEach(organization => {
        organizationsData.options.push({ name: organization.name, value: organization.id })
      })
      return organizationsData
    },
    administratorsData() {
      const administratorsData = this.mutableData.propertyAdministratorId
      this.administrators.forEach(administrator => {
        administratorsData.options.push({ name: administrator.name, value: administrator.id })
      })
      return administratorsData
    },
    logoItems() {
      return this.selected.logo ? [{ path: this.selected.logo }] : []
    }
  },
  methods: {
    handleDelete() {
      this.$store.dispatch('companiesManagement/toggleDeleteConfirmation', this.selected.id)
    },
    close() {
      this.$store.dispatch('companiesManagement/removeSelected')
    },
    submitChanges(closeSelection) {
      if (this.selected.id) {
        this.$store.dispatch('companiesManagement/patchCompany', { data: this.getCompanyData(), closeSelection })
      } else {
        this.$store.dispatch('companiesManagement/createCompany', { data: this.getCompanyData(), closeSelection: true })
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getCompanyData() {
      const companyData = {}
      Object.keys(this.mutableData).forEach(key => {
        if (
          this.mutableData[key].value !== undefined &&
          this.mutableData[key].value !== null &&
          !(Array.isArray(this.mutableData[key].value) && this.mutableData[key].value.length === 0)
        ) {
          switch (key) {
            default:
              companyData[key] = this.mutableData[key].value
          }
        }
      })
      return companyData
    },
    setSelected() {
      Object.keys(this.mutableData).forEach(key => {
        if (this.selected[key] !== undefined) {
          switch (key) {
            default:
              this.mutableData[key].value = this.selected[key]
          }
        }
      })
    },
    sectionTranslations() {
      return {
        title: this.model.companyTranslations.logoTitle,
        description: this.model.companyTranslations.logoDescription,
        upload: this.model.companyTranslations.logoAdd,
        validationSize: this.model.companyTranslations.validationMessageSize,
        validationFormat: this.model.companyTranslations.validationMessageFormat,
        validationDimensions: this.model.companyTranslations.validationMessageDimensions
      }
    }
  },
  created() {
    this.setSelected()
    if (this.administrators.length === 0) {
      this.$store.dispatch('administratorsManagement/getPropertyAdministrators')
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-cm-company {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
