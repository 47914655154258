<template lang="pug">
.search-map-component {{ model.text }}
</template>

<script>
export default {
  name: 'search-map',

  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-map-component {
  color: $ocean-primary;
  text-align: center;
  border: 1px solid $crab-primary;
  padding: toRem(20px);
}
</style>
