<template lang="pug">
.c-video(:class='{ "c-video--16-9": aspectRatio === "16/9" }')
  iframe(:data-consent-src='url' data-category-consent='cookie_cat_functional' src='')
  .consent-placeholder(data-category='cookie_cat_functional' onClick='CookieConsent.renew()')
    span {{ Dictionary.Cookies.CookieInformationFunctionalConsentNeeded }}
</template>
<script>
export default {
  name: 'c-video',
  props: {
    url: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: String
    }
  },
  mounted() {},
  destroyed() {}
}
</script>
<style lang="scss">
.c-video {
  &--16-9 {
    padding-top: 56.25%; //16:9
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .consent-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: $crab-primary;
    background-color: $ocean-primary;
    cursor: pointer;
    padding: toRem(20px);
    border: 1px dotted $crab-primary;
    text-align: center;
    span {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
