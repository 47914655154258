<template lang="pug">
.c-om-r-add
  .c-om-r-add__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.recipientsSearchHeadline }}
  .c-om-r-add-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--6.o-grid__item--desktop-2(v-if='categoryData.options.length > 0')
        c-fc-select(:model='categoryData' v-model='searchData.category.value')
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
        c-fc-text(:model='searchData.search' @submit='doSearch()' v-model='searchData.search.value')
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-2
        c-fc-checkbox(:model='searchData.isApplicant' v-model='searchData.isApplicant.value')
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-2
        c-fc-checkbox(:model='searchData.isActive' v-model='searchData.isActive.value')
      .o-grid__item.o-grid__item--desktop-2
        button.o-button.c-om-r-add-search__button(@click='doSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
      .o-grid__item.o-grid__item--desktop-1
        button.o-link.c-om-r-add-search__button(:disabled='!searchHasActiveFilters' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
  .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--8
        .c-om-r-add__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.o-button--inverted.o-button--small(
          :disabled='addActiveRecipientsSelection.length === 0'
          @click='handleAdd'
          role='button'
          v-debounce.keyup='500'
        ) {{ Dictionary.A.AddChosen }} {{ addActiveRecipientsSelection.length }}
  .c-om-r-add__data(v-if='!isMobile')
    vue-good-table(
      :columns='columns',
      :pagination-options='paginationSettingsData',
      :row-style-class='getApplicantsRowClass',
      :rows='availableApplicants',
      :select-options='{ enabled: true, selectOnCheckboxOnly: true, selectionInfoClass: "o-table__selection-info", selectionText: Dictionary.R.RowsSelected, clearSelectionText: Dictionary.D.Deselect }',
      :sort-options='{ enabled: true, initialSortBy: { field: "points", type: "desc" } }',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-row-click='multiselectRowClick($event, "recipipents-search-table")'
      @on-selected-rows-change='addActiveRecipientsSelectionChanged'
      @on-sort-change='onSortChange'
      mode='remote'
      ref='recipipents-search-table'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "fullName"')
          button.c-om-r-add__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ getUserName(props.row) }}
        template(v-else-if='props.column.field == "category"')
          span {{ getApplicantsCategoryInMembershipOrganization(props.row) }}
        template(v-else-if='props.column.field == "points"')
          span {{ getWaitingListPoints(props.row, selectedMutable.organizationId, selectedMutable.propertyId) }}
        template(v-else-if='props.column.field == "verificationStatus"')
          svg.c-om-r-add__icon.o-svg-icon(:class='[getVerificationStatus(props.row, selectedMutable.organizationId)]' type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
        template(v-else-if='props.column.field == "recentAnswer"')
          span {{ getRecentAnswerStatus(props.row) }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'

import { VueGoodTable } from 'vue-good-table'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import { applicantsManagementService } from '@/_services'
import moment from 'moment'
export default {
  name: 'c-om-r-add',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      addActiveRecipientsSelection: [],
      initialCategories: [],
      lastSelected: null,
      columns: [
        {
          label: this.Dictionary.N.Name,
          field: 'fullName',
          sortable: true
        },
        {
          label: '',
          field: 'verificationStatus',
          width: '48px',
          sortable: false
        },
        {
          label: this.Dictionary.C.Category,
          field: 'category',
          sortable: false
        },
        {
          label: this.Dictionary.P.Points,
          field: 'points',
          sortable: true
        },
        {
          label: `${this.Dictionary.W.WaitingListStatus} ${this.Dictionary.R.Residence}`,
          field: 'applications',
          formatFn: this.getWaitingListStatusResidence,
          sortable: false
        },
        {
          label: `${this.Dictionary.W.WaitingListStatus} ${this.Dictionary.C.Company}`,
          field: 'activeCompanyIds',
          formatFn: this.getWaitingListStatusCompany,
          sortable: false
        },
        {
          label: this.Dictionary.L.LatestReply,
          field: 'recentAnswer',
          sortable: false
        }
      ],
      searchData: {
        search: {
          helperText: this.model.recipientsSearchHelperText,
          placeholderText: this.model.recipientsSearchPlaceholder,
          value: null,
          isMandatory: false,
          validation: null
        },
        category: {
          helperText: this.model.recipientsCategoryHelperText,
          value: null,
          placeholderText: this.Dictionary.C.Category,
          isMandatory: false,
          options: [],
          validation: null
        },
        isApplicant: {
          value: true,
          helperText: this.model.recipientsApplicantHelperText,
          placeholderText: this.model.recipientsApplicantPlaceholderText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        isActive: {
          value: true,
          helperText: this.model.recipientsActiveHelperText,
          placeholderText: this.model.recipientsActivePlaceholderText,
          isMandatory: false,
          readOnly: false,
          validation: null
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-checkbox': FormControllCheckbox,
    VueGoodTable
  },
  computed: {
    ...mapState('applicantsManagement', ['selected', 'applicants', 'facets', 'totalResults', 'serverParams']),
    ...mapState('offersManagement', ['selectedMutable']),
    ...mapState('search', ['companies', 'organizations']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('residenceManagement', { selectedResidence: 'selected', types: 'types' }),
    offerOrganization() {
      const offerOrganization = this.organizations && this.organizations.find(o => o.id === this.selectedMutable.organizationId)
      return offerOrganization
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    // applicantWithOpenOfferCannotReceiveNewOffers() {
    //   return this.offerOrganization && this.offerOrganization.applicantWithOpenOfferCannotReceiveNewOffers
    // },
    searchHasActiveFilters() {
      return !!this.searchData.search.value || !!this.searchData.category.value || !!this.searchData.isApplicant.value || !!this.searchData.isActive.value
    },
    searchResultsCountText() {
      return this.model.recipientsResultsText ? this.model.recipientsResultsText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    categoryData() {
      const categoryData = Object.assign({}, this.searchData.category)
      categoryData.options = this.initialCategories.map(category => {
        return { value: category.key, name: category.key }
      })
      categoryData.options.push({
        value: 'null',
        name: this.Dictionary.R.Reset,
        hidden: true
      })
      return categoryData
    },
    initialCategoriesData() {
      return this.facets.categories_sm
    },
    isStudentResidence() {
      return this.selectedMutable.onlyForStudents
    },
    availableApplicants() {
      return this.applicants.map(a => {
        a.vgtSelected = false
        const alreadySelected = this.selectedMutable.recipients.includes(a.userId)
        const appliedOnResidence = !!a.applications && !!a.applications[this.selectedMutable.residenceId]
        const isPassiveOnResidence = appliedOnResidence && a.applications[this.selectedMutable.residenceId] === 'Passive'
        const isActiveOnCompany = !!a.activeCompanyIds && a.activeCompanyIds.includes(this.selectedMutable.companyId)
        const isPassive = isPassiveOnResidence || (!appliedOnResidence && !isActiveOnCompany)

        const hasOtherOffersInMo =
          !!this.offerOrganization &&
          !!this.offerOrganization.applicantWithOpenOfferCannotReceiveNewOffers &&
          !!a.membershipOrganizationsWithOpenOffers &&
          a.membershipOrganizationsWithOpenOffers.includes(this.offerOrganization.id)
        const isInQuarantine = !!this.offerOrganization && !!a.quarantineIn && a.quarantineIn.includes(this.offerOrganization.id)

        let awaitingStartOfStudy = false
        const awaitingStartOfStudyRuleEnabled = !!this.offerOrganization && this.offerOrganization.earliestStartingPointForReceivingOffersForStudents
        const monthsBeforeStudyStartBeforeReceivingOffers =
          this.offerOrganization && this.offerOrganization.monthsBeforeStudyStartBeforeReceivingOffers
            ? this.offerOrganization.monthsBeforeStudyStartBeforeReceivingOffers
            : 0

        if (this.isStudentResidence && awaitingStartOfStudyRuleEnabled) {
          const startOfStudyMonth = moment.utc([moment.utc(a.educationStartDate).year(), moment.utc(a.educationStartDate).month()])
          const currentStartMonth = moment.utc([moment.utc().year(), moment.utc().month()])
          awaitingStartOfStudy =
            a.educationStartDate === null || startOfStudyMonth.diff(currentStartMonth, 'months', true) > monthsBeforeStudyStartBeforeReceivingOffers
        }

        // console.log(
        //   'has other offers',
        //   hasOtherOffersInMo,
        //   'is in Quarantine',
        //   isInQuarantine,
        //   'awaiting study',
        //   awaitingStartOfStudy,
        //   'study start',
        //   moment.utc(a.educationStartDate).format('YYYY-MM-DD'),
        //   'max study start',
        //   moment.utc().subtract(monthsBeforeStudyStartBeforeReceivingOffers, 'months').format('YYYY-MM-DD')
        // )
        if (hasOtherOffersInMo || isInQuarantine || isPassive || awaitingStartOfStudy || alreadySelected) {
          a.vgtDisabled = true
        }
        return a
      })
    }
  },
  methods: {
    getApplicantsRowClass(row) {
      return row && row.vgtDisabled ? 'opaque' : ''
    },
    getWaitingListStatusResidence(value) {
      const status = value && value[this.selectedMutable.residenceId] && value[this.selectedMutable.residenceId]
      return status === 'Active' ? this.Dictionary.A.Active : status === 'Passive' ? this.Dictionary.P.Passive : ''
    },
    getWaitingListStatusCompany(value) {
      return value && value.includes(this.selectedMutable.companyId) ? this.Dictionary.A.Active : this.Dictionary.P.Passive
    },
    getApplicantsCategoryInMembershipOrganization(value) {
      return (value.categories[this.selectedMutable.organizationId] && value.categories[this.selectedMutable.organizationId].toString()) || ''
    },
    addActiveRecipientsSelectionChanged(value) {
      this.addActiveRecipientsSelection = value.selectedRows.filter(row => !row.vgtDisabled).map(row => row.userId)
      value.selectedRows.forEach(row => this.$set(row, 'vgtSelected', !row.vgtDisabled))
    },
    getRecentAnswerStatus(value) {
      if (this.selectedMutable.recipientsState.length > 0) {
        const userState = this.selectedMutable.recipientsState.find(state => state.userId === value.userId) || null
        if (userState !== null) {
          const accepted = userState.accepted
          const declined = userState.declined
          if (accepted !== null) {
            return `${this.Dictionary.RecipientState.OfferAccepted} ${moment.utc(accepted).format('YYYY-MM-DD')}`
          } else if (declined !== null) {
            return `${this.Dictionary.RecipientState.OfferDeclined} ${moment.utc(declined).format('YYYY-MM-DD')}`
          } else {
            return moment.utc(this.selectedMutable.deadline).format('YYYY-MM-DD')
          }
        }
      }
      return ''
    },
    setSelected(id) {
      this.$store.dispatch('applicantsManagement/setSelected', { id })
    },
    doSearch() {
      if (this.serverParams.page === 1) {
        this.handleSearch()
      } else {
        this.$store.commit('applicantsManagement/updateParams', { page: 1 })
      }
    },
    handleSearch() {
      this.addActiveRecipientsSelection = []
      const filter = {
        search: this.searchData.search.value,
        filters: {
          companyIds: [this.selectedMutable.companyId]
        }
      }
      // if (this.applicantWithOpenOfferCannotReceiveNewOffers) {
      //   filter.search = `${filter.search ? filter.search + ' AND ' : ''}-membership_organization_open_offers_ids_any_sm:(${this.offerOrganization.id})`
      // }
      if (this.initialCategories.length === 0) {
        filter.facets = ['categories']
      }
      if (this.searchData.isActive.value) {
        filter.filters.activeCompanyIds = [this.selectedMutable.companyId]
      }
      if (this.searchData.isApplicant.value) {
        filter.filters.residences = [this.selectedMutable.residenceId]
      }
      if (this.searchData.category.value && this.searchData.category.value !== 'null') {
        filter.filters.categories = this.searchData.category.value
      }
      this.$store.dispatch('applicantsManagement/searchApplicants', filter)
    },
    handleReset() {
      this.searchData.search.value = null
      this.searchData.category.value = null
      this.searchData.isApplicant.value = null
      this.searchData.isActive.value = null
      this.handleSearch()
    },
    handleAdd() {
      this.$emit('change', this.addActiveRecipientsSelection)
    },
    onPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { page: params.currentPage })
    },
    onPerPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      let fieldName = params[0].field
      if (fieldName === 'points') {
        fieldName = 'PropertyPoints_' + this.selectedMutable.propertyId
      }

      this.$store.commit('applicantsManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: fieldName
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('applicantsManagement/updateParams', params)
    },
    async getFacets() {
      try {
        const responseApplicantsCategories = await applicantsManagementService.searchApplicants({
          filters: {
            companyIds: [this.selectedMutable.companyId]
          },
          facets: ['categories'],
          page: 0,
          pageSize: 0
        })

        if (responseApplicantsCategories && responseApplicantsCategories.status === 200) {
          this.$store.commit('applicantsManagement/updateData', { facets: responseApplicantsCategories.data.facets })
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  created() {
    this.getFacets()
  },
  watch: {
    initialCategoriesData() {
      if (this.initialCategories.length === 0) {
        this.initialCategories =
          (this.facets.categories_sm && this.facets.categories_sm.length > 0 && JSON.parse(JSON.stringify(this.facets.categories_sm))) || []
      }
    },
    serverParams: {
      deep: true,
      handler() {
        this.handleSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-om-r-add {
  &__data {
    position: relative;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__icon {
    @include min('desktop') {
      width: 28px;
      height: 20px;
    }
  }
  &-search {
    padding: toRem(16px 20px);
    background-color: $grey-5;

    &__button {
      width: 100%;
      min-height: 52px;
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
