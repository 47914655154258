/* eslint-disable prettier/prettier */
<template lang="pug">
.c-adm-overview
  .c-adm-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-adm-overview__button(:title='Dictionary.N.NewUsers' @click='createNew') {{ model.addUserText }}
  .c-adm-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-adm-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettings', :rows='administrators' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "name"')
          button.c-adm-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ props.row.name }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import AdministtratorsManagementSearch from '../adm-search'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-adm-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      pageOfItems: [],
      resetPaging: false,
      columns: [
        {
          label: this.Dictionary.N.Name,
          field: 'name'
        },
        {
          label: this.Dictionary.C.Companies,
          field: 'companies',
          formatFn: this.reFormatCompanies
        },
        {
          label: this.Dictionary.C.Created,
          field: 'created',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.U.Updated,
          field: 'updated',
          formatFn: this.reFormatDate,
          width: '110px'
        }
      ]
    }
  },
  components: {
    'c-adm-search': AdministtratorsManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('administratorsManagement', ['administrators']),
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    totalResults() {
      return this.administrators.length
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    reFormatCompanies(value) {
      return value ? value.length : ''
    },
    setSelected(id) {
      this.$store.commit('administratorsManagement/updateData', { selected: this.administrators.find(user => user.id === id) })
    },
    createNew() {
      this.$store.dispatch('administratorsManagement/createNew')
    }
  },
  created() {
    this.$store.dispatch('administratorsManagement/getPropertyAdministrators')
  }
}
</script>
<style lang="scss" scoped>
.c-adm-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
