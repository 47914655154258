import Vue from 'vue'
import Vuex from 'vuex'

import { loadingIndicator } from './loadingIndicator.module'
import { toast } from './toast.module'
import { user } from './user.module'
import { search } from './search.module'
import { messages } from './messages.module'
import { advertManagement } from './advertManagement.module'
import { property } from './property.module'
import { membershipOrganizations } from './membershipOrganizations.module'
import { propertyManagement } from './propertyManagement.module'
import { companiesManagement } from './companiesManagement.module'
import { offersManagement } from './offersManagement.module'
import { residenceManagement } from './residenceManagement.module'
import { applicationsManagement } from './applicationsManagement.module'
import { signupsManagement } from './signupsManagement.module'
import { usersManagement } from './usersManagement.module'
import { administratorsManagement } from './administratorsManagement.module'
import { applicantsManagement } from './applicantsManagement.module'
import { ub4PropertyManagement } from './ub4PropertyManagement.module'
import { ub4FacilitiesManagement } from './ub4FacilitiesManagement.module'
import { ub4SumCodesManagement } from './ub4SumCodesManagement.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    developerMode: false
  },
  getters: {
    isLocal: () => {
      return ['http://cm.findbolig.local', 'https://cm.findbolig.aksdc1.devtest.sh', 'https://cd.findbolig.aksdc1.devtest.sh'].includes(window.location.origin)
    }
  },
  mutations: {
    TOGGLE_DEV_MODE(state) {
      state.developerMode = !state.developerMode
    }
  },
  actions: {
    toggleDevMode({ commit }) {
      commit('TOGGLE_DEV_MODE')
    }
  },
  modules: {
    loadingIndicator,
    toast,
    user,
    search,
    messages,
    advertManagement,
    property,
    membershipOrganizations,
    propertyManagement,
    companiesManagement,
    offersManagement,
    residenceManagement,
    applicationsManagement,
    signupsManagement,
    usersManagement,
    administratorsManagement,
    applicantsManagement,
    ub4PropertyManagement,
    ub4FacilitiesManagement,
    ub4SumCodesManagement
  }
})
