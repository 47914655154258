<template lang="pug">
c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='false' @close='close' v-if='showComponent')
  template(slot='header')
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
    .o-heading.o-heading--size-2 {{ Dictionary.R.RetireAwardedRentedOutConfirmationHeadline }}
  template(slot='body')
    .o-bodytext {{ Dictionary.R.RetireAwardedRentedOutConfirmationDescription }}
      template
        table.o-table.o-table--v-middle.c-modal__box
          thead
            tr
              th {{ Dictionary.T.Type }}
              th {{ Dictionary.N.Number }}
              th {{ Dictionary.A.Address }}
              th {{ Dictionary.S.Status }}
          tbody(v-if='advertsToRetire.length')
            tr(v-for='advert in advertsToRetire')
              td {{ Dictionary.A.Advert }}
              td {{ advert.number }}
              td {{ advert.address }}
              td {{ getAdvertStatuses()[advert.status] }}
          tbody(v-if='offersToRetire.length')
            tr(v-for='offer in offersToRetire')
              td {{ Dictionary.O.Offer }}
              td {{ offer.number }}
              td {{ offer.residenceAddress }}
              td {{ Dictionary.OfferStates[offer.state] }}
  template(slot='footer')
    .o-grid.o-grid--justify-content-center.o-grid--gutter-small
      .o-grid__group
        .o-grid__item.o-grid__item--12
          button.o-button.o-button--inverted(:disabled='retireInProgress' @click='handleCreateNewYes()') {{ Dictionary.Y.YesPlease }}
        .o-grid__item.o-grid__item--12
          span.o-link(@click='close') {{ Dictionary.C.Cancel }}
</template>
<script>
import { advertManagementService, offersManagementService } from '@/_services'
export default {
  name: 'c-retire-awarded-rentedout',
  data() {
    return {
      showComponent: false,
      advertsToRetire: [],
      offersToRetire: [],
      retireInProgress: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    close() {
      this.showComponent = false
      this.advertsToRetire = []
      this.offersToRetire = []
      this.$emit('close')
    },
    async getAdvertsAndOffers() {
      try {
        const payload = {
          pageSize: 1000,
          page: 0
        }
        const advertsResponse = await advertManagementService.searchAdverts({
          ...payload,
          ...{ filters: { status: ['RentedOut'], residenceId: this.data.residenceId, address: this.data.residenceAddress } }
        })

        this.advertsToRetire = advertsResponse.data.results

        if (!this.data.residenceAddress) {
          const offersResponse = await offersManagementService.searchOffers({
            ...payload,
            ...{ filters: { state: ['Awarded', 'AwardedExternally'], residenceId: this.data.residenceId } }
          })

          if (offersResponse.data.results.length) {
            this.offersToRetire = offersResponse.data.results
          }
        }

        if (this.advertsToRetire.length || this.offersToRetire.length) {
          this.showComponent = true
        } else {
          this.handleAction()
          this.close()
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async handleCreateNewYes() {
      try {
        this.retireInProgress = true
        for (let index = 0; index < this.advertsToRetire.length; index++) {
          await advertManagementService.retireRentedOutAdvert(this.advertsToRetire[index].id)
        }
        for (let index = 0; index < this.offersToRetire.length; index++) {
          await offersManagementService.retireAwardedOffer(this.offersToRetire[index].id)
        }
        this.handleAction()
        this.close()
      } catch (error) {
        this.retireInProgress = false
      }
    },

    handleAction() {
      if (this.data.returnUrl) {
        window.location.assign(this.data.returnUrl)
      } else if (this.data.returnAction) this.data.returnAction()
    }
  },
  beforeMount() {
    this.getAdvertsAndOffers()
  }
}
</script>
