<template lang="pug">
.c-filter-input-select
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model && model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id' v-if='model && model.labelText') {{ model.labelText }}
    .o-input__wrap
      v-select(
        :class='{ "vs--always-open": model.settings.alwaysOpen }',
        :clearable='model.settings && model.settings.clearable',
        :disabled='model.readOnly',
        :dropdown-should-open='showDropdown',
        :multiple='model.settings && model.settings.multiple',
        :options='selectOptions',
        :placeholder='Dictionary.Search.SelectValue',
        :reduce='model.settings && model.settings.reduce',
        :value='value'
        @input='setSelected'
        label='name'
      ) 
      label.o-label.o-label--placeholder(:for='id' v-if='model && model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--helper(:for='id' v-if='model && model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-filter-input-select',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      type: Object
    }
  },
  computed: {
    ...mapState('search', ['filters']),
    value() {
      return this.filters[this.model.filter]
    },
    selectOptions() {
      let options = []
      if (this.model.boundMin !== undefined && this.model.boundMax !== undefined) {
        for (let i = this.model.boundMax; i >= this.model.boundMin; i--) {
          options.push({
            value: i,
            name: i
          })
        }
      } else if (this.model.options.length > 0) {
        options = this.model.options
      }

      return options
    }
  },
  methods: {
    setSelected(val) {
      if (this.model.settings && this.model.settings.reduce) {
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: val || null })
      } else {
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: (val && val.value) || null })
      }
    },
    showDropdown(VueSelect) {
      if (this.model.settings && this.model.settings.alwaysOpen) {
        return true
      } else {
        return VueSelect.open
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-select {
}
</style>
