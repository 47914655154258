<template lang="pug">
.c-filter-input-vselect
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      v-select(
        :clearable='model.settings.clearable',
        :disabled='model.readOnly',
        :dropdown-should-open='showDropdown',
        :multiple='model.settings.multiple',
        :options='model.options',
        :placeholder='Dictionary.Search.SelectValue',
        :reduce='model.settings.reduce'
        label='name'
        v-model='model.value'
      ) 
      label.o-label.o-label--placeholder(:class='{ "o-label--required": model.isMandatory }', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-vselect',
  data() {
    return {
      id: this._uid,
      value: ''
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue(value) {
      this.value = value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    },
    showDropdown(VueSelect) {
      if (this.model.settings.alwaysOpen) {
        return true
      } else {
        return VueSelect.open
      }
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-vselect {
}
</style>
