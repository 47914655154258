import { ub4FacilitiesManagementService } from '@/_services'

const initialState = {
  facilities: [],
  selected: undefined,
  selectedFacilityId: undefined,
  selectedFacilityNumber: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmationTarget: undefined
}

const initialSelected = {
  id: undefined
}

export const ub4FacilitiesManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    selectedFacility: (state, _, rootState) => {
      const facility = rootState.search.facilities.find(f => f.id === state.selectedFacilityId)
      return facility
    }
  },
  actions: {
    async setSelected({ commit }, { id, companyId }) {
      // TODO in another story
      if (id) {
        try {
          const response = await ub4FacilitiesManagementService.getUser(id)
          if (response && response.status === 200) {
            response.data.companyId = companyId
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    createNew({ commit }) {
      commit('createNew')
    },
    async getUb4Facilities({ commit }) {
      try {
        const responseFacilities = await ub4FacilitiesManagementService.getUb4Facilities()
        if (responseFacilities && responseFacilities.status === 200) {
          commit('updateData', {
            facilities: responseFacilities.data
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async postUb4Facility({ commit }, { data }) {
      try {
        const response = await ub4FacilitiesManagementService.postUb4Facility(data)
        if (response && response.status === 204) {
          this.dispatch('ub4FacilitiesManagement/getUb4Facilities')
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteMapping({ commit }, payload) {
      try {
        const response = await ub4FacilitiesManagementService.deleteUb4Facility(payload)
        if (response && response.status === 204) {
          this.dispatch('ub4FacilitiesManagement/getUb4Facilities')
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    }
  }
}
