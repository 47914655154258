<template lang="pug">
.c-om-recipients
  .c-om-recipients__active(v-if='allRecipientsIds.length > 0 || stateAfter("Finished")')
    .c-om-recipients__top.o-grid.o-grid--align-items-start.o-grid--wrap-nowrap.o-grid--align-items-center.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item
          .c-om-recipients__count.o-heading.o-heading--size-4 {{ activeRecipientsCountText }}
        .o-grid__item
          .c-om-recipients__options.o-grid__item.o-grid__item--grow-0
            .c-om-recipients__option(:key='key' v-for='(option, key) in recipientsFilterOptions')
              input.o-input(
                :id='`${id}-${key}`',
                :name='option.value',
                :value='option.value'
                placeholder=' '
                store='messages'
                type='radio'
                v-model='onlyRecipientsWithNewMessages'
              )
              label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
        .o-grid__item.o-grid__item--align-right.o-grid__item--grow-0
          .o-grid--align-items-start.o-grid--align-items-center.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                c-cta.o-button--small(:cta-disabled='syncInProgress', :cta-loading='syncInProgress', :cta-text='Dictionary.U.Update' @click='syncRecipients')
              .o-grid__item.o-grid__item--grow-0
                button.c-om-recipients__action.o-button.o-button--small(
                  :disabled='activeRecipientsSelection.length === 0 || selectContainsUnsaved'
                  @click='activeRecipientsSelection.length > 1 ? (showMultiUserStatusChange = true) : setSelectedRecipient(activeRecipientsSelection[0])'
                  role='button'
                ) {{ Dictionary.C.ChangeStatus }}
              .o-grid__item.o-grid__item--grow-0
                button.c-om-recipients__action.o-button.o-button--small(
                  :disabled='activeRecipientsSelection.length === 0'
                  @click='startSendMessageFlow()'
                  role='button'
                ) {{ Dictionary.S.SendMessage }}
              .o-grid__item.o-grid__item--grow-0(v-if='stateBefore("Finished")')
                button.c-om-recipients__action.o-button.o-button--small(@click='startAddRecipientFlow' role='button') {{ Dictionary.A.AddRecipients }}
              .o-grid__item.o-grid__item--grow-0(v-if='selectedMutable.state === "Finished"')
                button.c-om-recipients__action.o-button.o-button--small(:disabled='activeRecipientsSelection.length !== 1' @click='awardOffer' role='button') {{ Dictionary.A.AwardResidence }}
              .o-grid__item.o-grid__item--grow-0(v-if='stateAfter("Finished")')
                button.c-om-recipients__action.o-button.o-button--small(
                  :disabled='activeRecipientsSelection.length !== 1 || selectContainsAwarded'
                  @click='starAssignNextFlow'
                  role='button'
                ) {{ Dictionary.A.AwardResidenceToNext }}
      .o-grid__group
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-3.o-grid__item--align-right
          c-fc-vselect(:model='replyFilter' v-model='replyFilter.value') 
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
          c-fc-vselect(:model='internalStateFilter' v-model='internalStateFilter.value') 
    .c-om-recipients__data
      vue-good-table(
        :columns='activeRecipientsColumns',
        :pagination-options='paginationSettingsData',
        :row-style-class='getApplicantsRowClass',
        :rows='recipientsData',
        :select-options='{ enabled: true, selectOnCheckboxOnly: true, selectionInfoClass: "custom-class", selectionText: "rows selected", clearSelectionText: "clear", disableSelectInfo: true }',
        :sort-options='{ enabled: true }',
        :totalRows='totalResults'
        @on-page-change='onPageChange'
        @on-per-page-change='onPerPageChange'
        @on-row-click='multiselectRowClick($event, "recipipents-table")'
        @on-selected-rows-change='activeRecipientsSelectionChanged'
        @on-sort-change='onSortChange'
        mode='remote'
        ref='recipipents-table'
        styleClass='o-table'
      )
        template(slot='table-row' slot-scope='props')
          template(v-if='props.column.field == "fullName"')
            button.c-om-recipients--name.o-bodytext.o-bodytext--size-small(
              :disabled='props.row.applicantDeletedDate'
              @click='setSelectedRecipient(props.row.userId)'
            ) {{ getUserName(props.row) }} {{ props.row.notYetIndexed ? '*' : '' }}
          template(v-else-if='props.column.field == "reply"')
            span {{ getRecipientReply(props.row) }}
          template(v-else-if='props.column.field == "verificationStatus"')
            svg.c-om-recipients__icon.o-svg-icon(
              :class='[props.row.verificationStatus === "Verified" ? "o-svg-icon--success" : props.row.verificationStatus === "Rejected" ? "o-svg-icon--error" : "o-svg-icon--neutral"]'
              type='image/svg+xml'
              viewbox='0 0 42 34'
            )
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          template(v-else-if='props.column.field == "actions"')
            .c-om-recipients__recipient-actions
              button.o-link(:title='props.row.offerNote || ``' @click='setSelectedRecipient(props.row.userId)' v-if='!!props.row.offerNote')
                svg.c-om-recipients__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-pen' y='0')
              .o-link(v-if='!!getDocumentVerificationStatusClass(props.row)')
                svg.c-om-recipients__icon.o-svg-icon(:class='[getDocumentVerificationStatusClass(props.row)]' type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-list' y='0')
              button.o-link(@click='startSendMessageFlow(props.row.userId)' v-if='!!props.row.offersWithUnreadMessages')
                svg.c-om-recipients__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
              button.o-link(@click='startDeleteRecipientFlow(props.row.userId)' v-if='!props.row.offerReply || props.row.offerReply === "OfferNotReceived"')
                svg.c-om-recipients__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-cross' y='0')
          template(v-else) {{ props.formattedRow[props.column.field] }}
      .c-search-result__loading(v-if='loading')
        c-loading-animation(:overlay='true')
  .c-om-recipients__empty(v-else)
    .o-heading.o-heading--size-4 {{ model.offerRecipientsTab.recipientsNotAssignedHeadline }}
    button.c-om-recipients__empty-button.o-button(@click='startAddRecipientFlow' role='button') {{ Dictionary.A.AddRecipients }}
  c-modal.c-modal--search(:cornerClose='true' @close='handleClose' v-if='showAddRecipientFlow')
    template(slot='body')
      transition(mode='out-in' name='o-transition__fade')
        c-om-r-search(:model='model.offerRecipientsSearch' @change='addNewRecipientsIds')
  c-modal.c-modal--search(:cornerClose='true' @close='handleClose' v-if='selectedApplicant')
    template(slot='body')
      transition(mode='out-in' name='o-transition__fade')
        c-recipient-details(:callback-after-changes='getRecipients', :reward-data='selectedMutable')
  c-messages-overlay
  c-users-status-change(
    :state-target-id='selectedMutable.id',
    :users='activeRecipientsSelection'
    @close='showMultiUserStatusChange = false'
    state-type='offer'
    v-if='showMultiUserStatusChange'
  )
</template>
<script>
import { mapState } from 'vuex'
import omRecipientsSearch from './recipients-search'
import cRecipientDetails from '@/components/recipient-details'
import { applicantsManagementService, searchService } from '@/_services'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
export default {
  name: 'c-om-recipients',
  props: {
    model: {
      type: Object
    },
    perPage: {
      default: 4
    },
    stateBefore: {
      type: Function
    },
    stateAfter: {
      type: Function
    }
  },
  data() {
    return {
      id: this._uid,
      pageOfItems: [],
      resetPaging: false,
      totalResults: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: 'none'
          }
        ],
        page: 1,
        perPage: 10
      },
      onlyRecipientsWithNewMessages: false,
      showMultiUserStatusChange: false,
      activeRecipientsSelection: [],
      indexedRecipientsData: [],
      recipientsThreads: [],
      indexedRecipientsIds: [],
      showAddRecipientFlow: false,
      recipientsFilterOptions: [
        { value: false, labelText: this.Dictionary.A.All },
        { value: true, labelText: this.Dictionary.W.WithUnansweredMessages }
      ],
      replyFilter: {
        helperText: null,
        value: null,
        placeholderText: this.Dictionary.R.Reply,
        isMandatory: false,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: [
          { value: 'OfferAccepted', name: this.Dictionary.RecipientState.OfferAccepted },
          { value: 'OfferReceived', name: this.Dictionary.RecipientState.OfferReceived },
          { value: 'OfferNotReceived', name: this.Dictionary.RecipientState.OfferNotReceived },
          { value: 'OfferDeclined', name: this.Dictionary.RecipientState.OfferDeclined }
        ],
        validation: null
      },
      internalStateFilter: {
        helperText: null,
        value: null,
        placeholderText: this.Dictionary.S.Status,
        isMandatory: false,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: [
          {
            value: 'Ended',
            name: this.Dictionary.RecipientStateInternal.Ended
          },
          {
            value: 'Offered',
            name: this.Dictionary.RecipientStateInternal.Offered
          },
          {
            value: 'JumpedOff',
            name: this.Dictionary.RecipientStateInternal.JumpedOff
          },
          {
            value: 'Won',
            name: this.Dictionary.RecipientStateInternal.Won
          }
        ],
        validation: null
      },
      activeRecipientsColumns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'applicantNumber'
        },
        {
          label: this.Dictionary.N.Name,
          field: 'fullName'
        },
        {
          label: '',
          field: 'verificationStatus',
          width: '48px',
          sortable: false
        },
        {
          label: this.Dictionary.C.Category,
          field: 'categories',
          formatFn: this.getApplicantsCategoryInMembershipOrganization,
          sortable: false
        },
        {
          label: this.Dictionary.P.Points,
          field: 'points',
          type: 'number'
        },
        {
          label: this.Dictionary.F.FollowUpDate,
          field: 'offerFollowUpDate',
          formatFn: this.reFormatDate,
          sortable: true
        },
        {
          label: this.Dictionary.I.InvitationExpired,
          field: 'offerInvitationExpiredDate',
          formatFn: this.reFormatDate,
          sortable: false
        },
        {
          label: this.Dictionary.R.Reply,
          field: 'reply',
          sortable: true
        },
        {
          label: this.Dictionary.S.Status,
          field: 'offerInternalState',
          sortable: true,
          formatFn: this.reFormatState
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        }
      ]
    }
  },
  components: {
    'c-om-r-search': omRecipientsSearch,
    'c-recipient-details': cRecipientDetails,
    'c-fc-vselect': FormControllVSelect,
    VueGoodTable
  },
  computed: {
    ...mapState('offersManagement', ['selectedMutable', 'selected']),
    ...mapState('applicantsManagement', { selectedApplicant: 'selected', applicantsPool: 'applicantsPool' }),
    ...mapState('messages', ['activeThread']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    syncInProgress() {
      return this.loading && this.loadingEndpoints.includes(`/search/indexes/offer/reindex?id=${this.selected.id}`)
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    activeRecipientsCountText() {
      const total = this.totalResults || 0
      return this.model && this.model.offerRecipientsTab.recipientsTabCountText
        ? this.model.offerRecipientsTab.recipientsTabCountText.replace('{0}', total)
        : total
    },
    selectContainsAwarded() {
      return this.activeRecipientsSelection.some(aId => aId === this.selectedMutable.winnerId)
    },
    selectContainsUnsaved() {
      return this.activeRecipientsSelection.some(aId => !this.selected.recipients.includes(aId))
    },
    offerContainsUnsavedRecipients() {
      return this.allRecipientsIds.some(aId => !this.selected.recipients.includes(aId))
    },
    selectedOfferId() {
      return this.selected.id
    },
    allRecipientsIds: {
      get: function () {
        return this.selectedMutable.recipients
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { recipients: value })
      }
    },
    recipientsData: {
      get: function () {
        let recipientsData = this.indexedRecipientsData.filter(recipient => this.allRecipientsIds.includes(recipient.userId))

        const newNotInIndex = this.allRecipientsIds.filter(x => !this.indexedRecipientsIds.includes(x))

        if (newNotInIndex.length) {
          newNotInIndex.forEach(rId => {
            const targetApplicant = !!this.applicantsPool.length && this.applicantsPool.find(a => a.userId === rId)
            if (targetApplicant) {
              const targetApplicantStateInOffer = this.selectedMutable.recipientsState.find(state => state.userId === targetApplicant.userId)
              const recipientData = {
                applicantNumber: targetApplicant.applicantNumber,
                categories: targetApplicant.categories[this.selectedMutable.organizationId],
                documentationVerificationStatus: null,
                firstName: targetApplicant.firstName,
                fullName: targetApplicant.fullName,
                lastName: targetApplicant.lastName,
                email: targetApplicant.email,
                offerFollowUpDate: targetApplicantStateInOffer ? targetApplicantStateInOffer.followUpDate : null,
                offerInternalState: targetApplicantStateInOffer ? targetApplicantStateInOffer.internalState : null,
                offerNote: targetApplicantStateInOffer ? targetApplicantStateInOffer.note : null,
                offerReply: targetApplicantStateInOffer ? targetApplicantStateInOffer.state : null,
                offersWithUnreadMessages: null,
                points: this.getWaitingListPoints(targetApplicant, this.selectedMutable.organizationId, this.selectedMutable.propertyId),
                status: targetApplicant.applications[this.selectedMutable.residenceId],
                userId: targetApplicant.userId,
                verificationStatus: null,
                notYetIndexed: true
              }
              recipientsData.push(recipientData)
            }
          })
        }

        recipientsData = recipientsData.map(rd => {
          if (!rd) return rd
          const targetRecipientDataInOffer =
            this.selectedMutable.recipientsState.length && this.selectedMutable.recipientsState.find(state => state.userId === rd.userId)
          if (targetRecipientDataInOffer) {
            rd.offerFollowUpDate = targetRecipientDataInOffer.followupDate
            rd.offerInternalState = rd.userId === this.selectedMutable.winnerId ? 'Won' : targetRecipientDataInOffer.internalState
            rd.offerNote = targetRecipientDataInOffer.note
            rd.vgtDisabled = rd.applicantDeletedDate != null
          }
          return rd
        })

        return recipientsData
      },
      set: function (value) {
        this.indexedRecipientsData = value
      }
    },
    replyFilterValue() {
      return this.replyFilter.value
    },
    internalStateFilterValue() {
      return this.internalStateFilter.value
    }
  },
  methods: {
    async syncRecipients() {
      try {
        await searchService.postOfferReindex(this.selectedMutable.id)
      } catch (error) {
        // eslint-disable-next-line
        console.warn('Could not reindex offer', error)
      }
      try {
        const response = await searchService.postOfferApplicantsReindex(this.selected.id)
        if (response && response.status === 204) {
          this.getRecipients()
        }
      } catch (error) {
        // eslint-disable-next-line
        console.warn('Could not reindex aplicants on offer', error)
      }
    },
    getApplicantsRowClass(row) {
      return row && row.vgtDisabled ? 'opaque' : ''
    },
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    reFormatState(value) {
      return value ? this.Dictionary.RecipientStateInternal[value] : ''
    },
    async setSelectedRecipient(id) {
      const targetApplicant = this.applicantsPool.find(a => a.userId === id)
      if (!targetApplicant) {
        const promises = [this.addApplicantToPool(id)]
        await Promise.all(promises)
      }
      this.$store.dispatch('applicantsManagement/setSelected', { id })
    },
    async addApplicantToPool(id) {
      try {
        const data = {
          pageSize: 1,
          page: 0,
          filters: {
            userId: id
          }
        }
        const responseApplicants = await applicantsManagementService.searchApplicants(data)

        if (responseApplicants && responseApplicants.status === 200) {
          this.$store.commit('applicantsManagement/updatePool', responseApplicants.data.results)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getApplicantsCategoryInMembershipOrganization(value) {
      return (value && value.toString()) || ''
    },
    getRecipientReply(recipient) {
      if (!recipient.offerReply) return
      if (moment.utc().isAfter(moment.utc(this.selected.deadline)) && recipient.offerReply === 'OfferReceived') {
        return this.Dictionary.RecipientState.OfferNoAnswer
      } else {
        return this.Dictionary.RecipientState[recipient.offerReply]
      }
    },
    getDocumentVerificationStatusClass(applicant) {
      const isVerified = applicant.documentationVerifiedIn && applicant.documentationVerifiedIn.includes(this.selectedMutable.organizationId)
      const isRejected = applicant.documentationRejectedIn && applicant.documentationRejectedIn.includes(this.selectedMutable.organizationId)
      return isVerified ? 'o-svg-icon--success' : isRejected ? 'o-svg-icon--error' : null
    },
    handleClose() {
      this.showAddRecipientFlow = false
      if (this.selectedApplicant) {
        this.$store.dispatch('applicantsManagement/removeSelected')
      }
    },
    activeRecipientsSelectionChanged(value) {
      this.activeRecipientsSelection = value.selectedRows.map(row => row.userId)
    },
    startAddRecipientFlow() {
      this.showAddRecipientFlow = true
    },
    startDeleteRecipientFlow(userId) {
      this.allRecipientsIds.splice(this.allRecipientsIds.indexOf(userId), 1)
    },
    startSendMessageFlow(userId) {
      const target = userId && this.recipientsThreads.find(t => t.userId === userId)
      if (target && target.thread) {
        this.$store.dispatch('messages/selectThread', target.thread.id)
      } else {
        const usersIds = userId ? [userId] : this.activeRecipientsSelection
        const users = usersIds.map(id => {
          const user = this.recipientsData.find(user => user.userId === id)
          return { name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email, id: user.userId }
        })
        this.$store.dispatch('messages/selectThreadRelatedToEntity', {
          id: this.selectedMutable.id,
          users,
          name: this.selectedMutable.residenceAddress
        })
      }
    },
    addNewRecipientsIds(value) {
      this.allRecipientsIds = [...this.allRecipientsIds, ...value]
      this.showAddRecipientFlow = false
    },
    awardOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', {
        id: this.selectedMutable.id,
        action: 'awardOffer',
        user: this.recipientsData.find(r => r.userId === this.activeRecipientsSelection[0]),
        payload: this.activeRecipientsSelection[0]
      })
    },
    starAssignNextFlow() {
      this.$store.dispatch('offersManagement/toggleConfirmation', {
        id: this.selectedMutable.id,
        action: 'assignNextOffer',
        user: this.recipientsData.find(r => r.userId === this.activeRecipientsSelection[0]),
        payload: this.activeRecipientsSelection[0]
      })
    },
    async getRecipients() {
      try {
        const orderBy = this.serverParams.sort[0].field === 'reply' ? 'offerReply' : this.serverParams.sort[0].field
        const data = {
          pageSize: this.serverParams.perPage,
          page: this.serverParams.page - 1
        }
        if (this.serverParams.sort[0].type !== 'none') {
          data.orderBy = orderBy
          data.orderDirection = this.serverParams.sort[0].type
        }
        data.filters = {}
        if (this.onlyRecipientsWithNewMessages) {
          data.filters.offersWithUnreadMessages = this.selectedMutable.id
        }
        if (this.replyFilterValue && this.replyFilterValue.length) {
          data.filters.offerReply = this.replyFilterValue
        }
        if (this.internalStateFilterValue && this.internalStateFilterValue.length) {
          data.filters.offerInternalState = this.internalStateFilterValue
        }

        const responseRecipients = await applicantsManagementService.getOfferRecipients({
          organizationId: this.selectedMutable.organizationId,
          propertyId: this.selectedMutable.propertyId,
          residenceId: this.selectedMutable.residenceId,
          offerId: this.selectedMutable.id,
          data
        })
        if (responseRecipients && responseRecipients.status === 200) {
          const mergedResults = [...this.indexedRecipientsIds, ...responseRecipients.data.results.map(r => r.userId)]
          this.indexedRecipientsIds = mergedResults.filter((id, index) => mergedResults.indexOf(id) === index)
          this.recipientsData = responseRecipients.data.results
          this.totalResults = responseRecipients.data.totalResults

          if (this.totalResults === 0) {
            this.serverParams.perPage = 10
            this.serverParams.page = 1
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage
      this.getRecipients()
    },
    onPerPageChange(params) {
      this.serverParams.page = 1
      this.serverParams.perPage = params.currentPerPage
      this.getRecipients()
    },
    onSortChange(params) {
      this.serverParams.sort[0] = params[0]
      this.getRecipients()
    }
  },
  created() {
    // this.getRecipientsData()
    // this.getRecipientsThreads(this.selectedMutable.recipients)

    this.getRecipients()
  },
  watch: {
    activeThread(newVal) {
      if (!newVal) return
      let target = this.recipientsThreads.find(t => t.thread.id === newVal.id)

      if (target) {
        target = Object.assign(target, { userId: target.userId, thread: newVal })
      }
    },
    onlyRecipientsWithNewMessages() {
      this.getRecipients()
    },
    replyFilterValue() {
      this.serverParams.page = 1
      this.getRecipients()
    },
    internalStateFilterValue() {
      this.serverParams.page = 1
      this.getRecipients()
    },
    offerContainsUnsavedRecipients(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.getRecipients()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-om-recipients {
  &__top {
    margin: toRem(20px 0);
  }
  &__action {
    & + & {
      margin-left: 10px;
    }
  }

  &__recipient-actions {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include min('desktop') {
      justify-content: start;
    }
  }

  &__empty {
    margin: toRem(50px 0);
    text-align: center;
    &-button {
      margin-top: 20px;
      &:first-child:last-child {
        margin-top: 0;
      }
    }
  }
  &__count {
    font-family: 'Inria Serif', serif;
    font-weight: 700;
  }

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__icon {
    @include min('desktop') {
      width: 28px;
      height: 20px;
    }
  }

  &__data {
    position: relative;
  }

  &__option {
    outline: toRem(1px) solid $ocean-primary;
    margin: 0;

    & + & {
      border-left: toRem(1px) solid $ocean-primary;
    }

    .o-input[type='radio'] {
      &:checked {
        + label {
          background-color: $crab-primary;
          color: $ocean-primary;

          @include min('desktop') {
            background-color: $ocean-primary;
            color: $white;
          }
        }
      }
      + label {
        padding: toRem(6px) toRem(14px);
        font-family: 'Inria Serif', serif;
        background-color: $white;
        color: $ocean-primary;
        min-height: 100%;
        display: flex;
        align-items: center;

        &::before,
        &:after {
          display: none;
        }
      }
    }
  }

  &__modal {
    &--search {
      ::v-deep .c-modal__container {
        width: 1040px;
        padding: 45px;
      }
    }
  }

  &--name {
    background: transparent;
    border: none;
    text-decoration: underline;
    text-align: left;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
