/* eslint-disable prettier/prettier */
<template lang="pug">
.c-residence-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-rm-overview')
        component(:is='activeComponent', :model='model')
  template(v-else)
    c-rm-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-rm-residence.o-transition__overlay(:model='model' v-if='selectedResidence && intialDataReady')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleDeleteConfirmationClose' v-if='deleteTargetId')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ deleteConfirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ deleteConfirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleDeleteConfirmationDelete') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleDeleteConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import ResidenceManagementOverview from './rm-overview'
import ResidenceManagementResidence from './rm-residence'
export default {
  name: 'c-residence-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-rm-overview': ResidenceManagementOverview,
    'c-rm-residence': ResidenceManagementResidence
  },
  computed: {
    ...mapState('residenceManagement', { selectedResidence: 'selected', deleteTargetId: 'deleteTargetId', residences: 'residences' }),
    ...mapGetters('propertyManagement', ['intialDataReady']),
    activeComponent() {
      let activeComponent = 'c-rm-overview'
      if (this.intialDataReady) {
        activeComponent = this.selectedResidence ? 'c-rm-residence' : activeComponent
      }
      return activeComponent
    },
    deleteTargetData() {
      return this.residences.find(target => target.id === this.deleteTargetId)
    },
    deleteConfirmationHeadlineText() {
      return this.deleteTargetData
        ? this.model.deleteResidenceConfirmationHeadline.replace('{0}', this.combinedAddress)
        : this.model.deleteResidenceConfirmationHeadline
    },
    deleteConfirmationDescriptionText() {
      return this.deleteTargetData
        ? this.model.deleteResidenceConfirmationDescription.replace('{0}', this.combinedAddress)
        : this.model.deleteResidenceConfirmationDescription
    },
    combinedAddress() {
      return this.combineAddress(
        this.deleteTargetData.street,
        this.deleteTargetData.number,
        this.deleteTargetData.letter,
        this.deleteTargetData.floor,
        this.deleteTargetData.door,
        this.deleteTargetData.postalCode,
        this.deleteTargetData.city
      )
    }
  },
  methods: {
    handleDeleteConfirmationDelete() {
      this.$store.dispatch('residenceManagement/deleteResidence', this.deleteTargetId)
    },
    handleDeleteConfirmationClose() {
      this.$store.dispatch('residenceManagement/toggleDeleteConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-residence-management {
}
</style>
