<template lang="pug">
.c-show-more(
  :class='{ "c-show-more--expanded": expanded }',
  :style='{ "max-height": `${expanded ? contentHeight + ctaHeight : collapsedHeight}px`, "padding-bottom": `${expanded ? ctaHeight : 0}px` }'
)
  .c-show-more__content.o-bodytext--size-medium(:class='{ "o-richtext": richText }' ref='content' v-html='description')
  .c-show-more__cta-wrap(:class='{ "c-show-more__cta-wrap--hidden": !shouldCollapse }', :style='{ "max-height": `${ctaWrapHeight}px` }' ref='ctaWrap')
    button.c-show-more__cta(:aria-expanded='expanded' @click='expanded = !expanded' ref='cta' role='button')
      svg.o-svg-icon(type='image/svg+xml' viewBox='0 0 64 64')
        use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${expanded ? "collapse" : "expand"}`' x='0' y='0')
      span.o-bodytext.o-bodytext--inria.o-bodytext--size-medium.o-bodytext--700 {{ ctaText }}
</template>
<script>
import _ from 'lodash'
export default {
  name: 'c-show-more',
  data() {
    return {
      id: this._uid,
      expanded: true,
      contentLineHeight: undefined,
      contentHeight: undefined,
      ctaHeight: undefined,
      shouldCollapse: false
    }
  },
  props: {
    maxLines: {
      type: Number,
      default: 12
    },
    description: {
      type: String
    },
    richText: {
      type: Boolean
    }
  },
  computed: {
    ctaText() {
      return this.expanded ? this.Dictionary.S.ShowLess : this.Dictionary.S.ShowMore
    },
    ctaWrapHeight() {
      return this.expanded ? this.ctaHeight + 10 : 0
    },
    collapsedHeight() {
      return (this.maxLines - 3) * this.contentLineHeight + 50
    }
  },
  methods: {
    getSizes: _.debounce(function () {
      if (this.$refs.content) {
        const contentStyle = window.getComputedStyle(this.$refs.content)
        this.contentHeight = parseInt(contentStyle.getPropertyValue('height').replace(/[^0-9]/g, ''), 10)
        this.contentLineHeight = parseInt(contentStyle.getPropertyValue('line-height').replace(/[^0-9]/g, ''), 10)
        this.shouldCollapse = this.contentHeight > this.collapsedHeight
        this.ctaHeight = this.shouldCollapse ? this.$refs.cta.offsetHeight : 0
        this.expanded = !this.shouldCollapse
      }
    })
  },
  mounted() {
    // const font = new FontFaceObserver('Open Sans')
    const that = this
    const waitForFont = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(waitForFont)
        that.getSizes()
      }
    }, 100)
    window.addEventListener('resize', () => {
      that.getSizes()
    })
  }
}
</script>
<style lang="scss" scoped>
.c-show-more {
  $root: &;
  $transtionSpeed: 250ms;
  overflow: hidden;
  transition: max-height $transtionSpeed linear;
  position: relative;

  &__cta {
    padding: 5px;
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 auto 5px;
    text-align: center;
    transform: translateY(-110%);
    text-decoration: underline;
    z-index: 1;
    border: none;
    background-color: transparent;

    #{$root}--expanded & {
      transform: translateY(0);
    }

    cursor: pointer;

    svg {
      display: block;
      margin: 0 auto;
      width: toRem(24px);
      height: toRem(24px);
    }

    &-wrap {
      position: relative;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      &:after {
        content: '';
        background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 50%);
        height: toRem(100px);
        margin-top: toRem(-100px);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: opacity $transtionSpeed linear;

        #{$root}--expanded & {
          opacity: 0;
        }
      }
      &--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__content {
    padding: 0 0 10px;
  }
}
</style>
