<template lang="pug">
.filter-input-type
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id' v-if='model && model.labelText') {{ model.labelText }}
    fieldset
      .o-grid.o-grid--justify-content-start.o-grid--gutter-tiny
        .o-grid__group
          .o-grid__item.o-grid__item--3(:key='key' v-for='(option, key) in model.options')
            .o-input__wrap.filter-input-type__item
              input.o-input(
                :checked='valueChecked(option.value)',
                :id='`${id}-${key}`',
                :name='model.filter',
                :value='option.value'
                @change='valueChanged'
                placeholder=' '
                type='checkbox'
              )
              label.o-label.filter-input-type__label(:for='`${id}-${key}`', :style='{ backgroundImage: option.icon ? `url(${option.icon}` : null }') {{ option.labelText }}
    label.o-label.o-label--helper(:for='id' v-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'filter-input-type',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('search', ['filters'])
  },
  methods: {
    valueChanged(event) {
      if (event.target.checked) {
        this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: event.target.value })
      } else {
        this.$store.dispatch('search/removeFilter', { filter: this.model.filter, value: event.target.value })
      }
    },
    valueChecked(value) {
      let checked = false
      if (Array.isArray(this.filters[this.model.filter])) {
        checked = this.filters[this.model.filter].indexOf(value) > -1
      } else {
        checked = this.filters[this.model.filter] === value
      }

      return checked
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-input-type {
  .o-input {
    + label {
      &:before,
      &:after {
        display: none;
      }
    }

    &:checked {
      + label {
        background-color: $crab-primary;
      }
    }

    &:focus {
      + label {
        outline: 1px solid $ocean-primary;
      }
    }
  }

  &__item {
    height: 100%;
  }

  &__label {
    border: 1px solid $ocean-primary;
    border-radius: 3px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 45px;
    background-position: center 10px;
    padding: 50px 10px 10px;
    @include setStyles('12', $s-typography-sizes);
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
