import apiClient from './api-client'

export function setupInterceptors(baseURL, { dispatch }) {
  apiClient.defaults.baseURL = baseURL

  const req = {
    pending: config => {
      dispatch('loadingIndicator/show', config.url)
    },
    done: config => {
      dispatch('loadingIndicator/hide', config.url)
    },
    error: error => {
      if (error.response.status === 401) {
        location.reload()
      } else {
        dispatch('loadingIndicator/hide', error.response.config.url)
        dispatch('toast/show', error.response)
      }
    }
  }

  apiClient.interceptors.request.use(
    config => {
      req.pending(config)

      return config
    },
    error => {
      req.error(error)
      return Promise.reject(error)
    }
  )

  apiClient.interceptors.response.use(
    ({ data, status, config }) => {
      req.done(config)

      return Promise.resolve({ data, status })
    },
    error => {
      req.error(error)
      return Promise.reject(error)
    }
  )
}
