<template lang="pug">
.header__impersonate-button-wrap(v-if='impersonator')
  a.header__impersonate-profile-button(:title='Dictionary.S.StopImpersonation' href='/api/authentication/impersonate/quit')
    span.header__impersonate-profile-button--avatar
      svg.o-svg-icon.o-button__icon.header__impersonate-profile-button--icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-unlock' y='0')
    span.header__impersonate-profile-button--name {{ getUserInfo() }}
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'c-impersonate-button',
  computed: {
    ...mapState('user', ['impersonator', 'email', 'firstName', 'lastName'])
  },
  methods: {
    getUserInfo() {
      return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.email
    }
  },
  created() {
    if (this.impersonator) {
      this.$store.dispatch('user/getProfileInformation')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  &__impersonate-button-wrap {
    display: none;
    @include min('desktop') {
      display: flex;
    }
  }
  &__impersonate-profile-button {
    position: relative;
    height: 64px;
    width: 120px;
    right: 0;
    z-index: 20;
    width: 65px;
    color: $ocean-darker;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inria Serif', serif;
    cursor: pointer;
    flex-direction: column;
    font-weight: bold;

    @include min('desktop') {
      position: absolute;
      height: 80px;
      right: 175px;
      z-index: 20;
    }
    &--link {
      color: $ocean-darker;
      display: block;
      text-align: center;
      text-decoration: none;
    }
    &--icon {
      display: block;
      height: 28px;
    }
    &--name {
      text-align: center;
    }
  }
  &__impersonate-profile-button--avatar {
    display: block;
    height: toRem(30px);
    width: toRem(30px);
    border-radius: toRem(15px);
    background-color: $crab-primary;
    .o-svg-icon {
      stroke: $ocean-primary;
      width: toRem(20px);
      height: toRem(20px);
      margin: toRem(4px) auto;
    }
  }
}
</style>
