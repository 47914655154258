/* eslint-disable prettier/prettier */
<template lang="pug">
.c-applicants-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-ap-overview')
        component(
          :is='selected && selected.userId ? "c-ap-applicant" : selected === undefined ? "c-ap-overview" : ""',
          :key='(selected && selected.userId) || "overview"',
          :model='model'
        )
  template(v-else)
    c-ap-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-ap-applicant.o-transition__overlay(:model='model' v-if='selected && selected.userId')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTargetId')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ confirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            a.o-button.o-button--inverted(:href='`/api/authentication/impersonate/${confirmationTargetId}`') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'

import ApplicantsManagementOverview from './ap-overview'
import ApplicantsManagementApplicant from './ap-applicant'

export default {
  name: 'c-applicants-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      urlApplicantId: null
    }
  },
  components: {
    'c-ap-overview': ApplicantsManagementOverview,
    'c-ap-applicant': ApplicantsManagementApplicant
  },
  computed: {
    ...mapState('applicantsManagement', ['selected', 'confirmationTargetId', 'applicants']),
    targetData() {
      return this.applicants.find(target => target.userId === this.confirmationTargetId)
    },
    confirmationHeadlineText() {
      const target = this.targetData.firstName || this.targetData.lastName ? `${this.targetData.firstName} ${this.targetData.lastName}` : this.targetData.email
      return this.Dictionary.I.ImitateConfirmationHeadline && this.Dictionary.I.ImitateConfirmationHeadline.replace('{0}', target)
    },
    confirmationDescriptionText() {
      const target = this.targetData.firstName || this.targetData.lastName ? `${this.targetData.firstName} ${this.targetData.lastName}` : this.targetData.email
      return this.Dictionary.I.ImitateConfirmationDescription && this.Dictionary.I.ImitateConfirmationDescription.replace('{0}', target)
    }
  },
  methods: {
    handleConfirmationClose() {
      this.$store.dispatch('applicantsManagement/toggleConfirmation')
    }
  },
  created() {
    if (this.selected) {
      this.$store.dispatch('applicantsManagement/selectFromUrl')
    }
  }
}
</script>
