<template lang="pug">
.c-state-sorting
  select.c-state-sorting__input.o-input.o-input--small.o-input--inria(
    :id='id',
    :name='orderBy'
    @change='valueChanged'
    placeholder=' '
    v-if='options.length > 2'
    v-model='value'
  )
    option(:value='defaultValue' disabled hidden selected) {{ defaultLabelText }}
    option(:value='option.value' v-for='option in options') {{ option.labelText }}
  .o-input__wrap(v-else-if='options.length === 2')
    input.c-state-sorting__checkbox(
      :aria-labelledby='`${id}-checkbox-label sorting`',
      :id='`${id}-checkbox`',
      :name='id'
      placeholder=' '
      type='checkbox'
      v-model='toggle'
    )
    label.o-label.c-state-sorting__label(:for='`${id}-checkbox`', :id='`${id}-checkbox-label`')
      span {{ selectedOption.labelText }}
      svg.o-svg-icon(:class='{ "c-state-sorting__flip": value.orderDirection === "ASC" }' type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-sort' y='0')
</template>
<script>
export default {
  name: 'c-state-sorting',
  data() {
    return {
      id: this._uid,
      value: '',
      defaultValue: '',
      toggle: false
    }
  },
  props: {
    store: {
      required: true
    },
    orderBy: {
      required: true
    },
    orderDirection: {
      required: true
    },
    action: {
      required: true
    },
    options: {
      required: true
    },
    defaultLabelText: {
      required: true
    }
  },
  computed: {
    storeOrderBy() {
      return this.$store.state[this.store][this.orderBy]
    },
    selectedOption() {
      return this.toggle ? this.options[0] : this.options[1]
    },
    storeOrderDirection() {
      return this.$store.state[this.store][this.orderDirection]
    }
  },
  methods: {
    valueChanged() {
      this.$store.dispatch(`${this.store}/${this.action}`, this.value)
    }
  },
  mounted() {
    this.value = {
      orderBy: this.storeOrderBy === '' ? this.value.orderBy : this.storeOrderBy,
      orderDirection: this.storeOrderDirection === '' ? this.value.orderDirection : this.storeOrderDirection
    }
    this.defaultValue = this.value
  },
  watch: {
    toggle(newVal) {
      this.value = newVal ? this.options[0].value : this.options[1].value
      this.valueChanged()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-state-sorting {
  &__input {
    @include max('desktop', -1) {
      border: none;
      background-image: url(map-get($sprites, 'icon-sorting'));
      background-position: 5px center;
      padding: 6px 14px 6px 30px;
      line-height: 24px;

      &:focus,
      &:hover {
        background-color: $grey-5;
        outline: none;
        border: none;
      }
    }
  }
  &__checkbox {
    display: none;
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &__flip {
    transform: rotate(180deg);
  }
}
</style>
