/* eslint-disable prettier/prettier */
<template lang="pug">
.c-um-overview
  .c-um-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-um-overview__button(v-if='roles.includes(`System Administrator`)' :title='model.addSysadminText' @click='createNew(`admin`)') {{ model.addSysadminText }}
    button.o-button.c-um-overview__button(:title='Dictionary.N.NewUsers' @click='createNew(`user`)') {{ model.addUserText }}
  keep-alive
    c-um-search(:model='model')
  .c-um-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-um-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettings', :rows='filteredUsers' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='hasElevatedRights')
                button.c-state-action(@click='handleImitate(props.row.id)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.I.Imitate }}
              .o-grid__item.o-grid__item--grow-0
                button.c-state-action(@click='handleLock(props.row.id)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${!props.row.locked ? "lock" : "unlock"}`' x='0' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ !props.row.locked ? model.lockUserText : model.unlockUserText }}

        template(v-else-if='props.column.field == "email"')
          button.c-um-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ props.row.email }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'

import UsersManagementSearch from '../um-search'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-um-overview',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,

      showUsersImport: false,
      showWarningsImport: false,
      hasElevatedRights: false,
      columns: [
        {
          label: this.Dictionary.F.FirstName,
          field: 'firstName'
        },
        {
          label: this.Dictionary.L.LastName,
          field: 'lastName'
        },
        {
          label: this.Dictionary.E.Email,
          field: 'email'
        },
        {
          label: this.Dictionary.L.Locked,
          field: 'locked',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-um-search': UsersManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('usersManagement', ['users', 'selectedAdministrator']),
    ...mapState('user', ['roles']),
    ...mapState('loadingIndicator', ['loading']),
    filteredUsers() {
      if (this.selectedAdministrator) {
        const filteredUsers = this.users.filter(user => {
          if (this.selectedAdministrator.users.includes(user.id)) {
            return user
          }
          return false
        })
        return filteredUsers
      } else {
        return this.users
      }
    },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.filteredUsers.length) : this.filteredUsers.length
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    setSelected(id) {
      this.$store.commit('usersManagement/updateData', { selected: this.users.find(user => user.id === id) })
    },
    createNew(type) {
      this.$store.commit('usersManagement/createNew', type)
    },
    handleLock(id) {
      this.$store.dispatch('usersManagement/toggleConfirmation', { action: 'lock', targetId: id })
    },
    handleImitate(id) {
      this.$store.dispatch('usersManagement/toggleConfirmation', { action: 'imitate', targetId: id })
    }
  },
  created() {
    this.$store.dispatch('usersManagement/getManagedUsers')
    this.hasElevatedRights = this.roles.includes('Supporter') || this.roles.includes('System Administrator')
  }
}
</script>
<style lang="scss" scoped>
.c-um-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
