<template lang="pug">
.c-slick-carousel
  VueSlickCarousel(:ref='reference' @beforeChange='setSlideCounts' v-bind='settings')
    slot
    template(#prevArrow='arrowOptions')
      button.c-slick-carousel-arrow.c-slick-carousel-arrow--prev(:disabled='currentSlide === 0', :title='Dictionary.P.Previous' data-role='none' type='button')
        svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-left' y='0')
    template(#nextArrow='arrowOptions')
      button.c-slick-carousel-arrow.c-slick-carousel-arrow--next(
        :disabled='currentSlide + slidesToShow >= slidesCount',
        :title='Dictionary.N.Next'
        data-role='none'
        type='button'
      )
        svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-right' y='0')
        span {{ arrowOptions.currentSlide }} {{ arrowOptions.slideCount }}
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'c-slick-carousel',
  components: { VueSlickCarousel },
  data() {
    return {
      previousSlide: 0,
      currentSlide: 0,
      nextDisabled: false,
      windowWidth: window.innerWidth
    }
  },
  props: {
    settings: {
      type: Object
    },
    reference: {
      type: String
    },
    goto: String,
    slidesCount: Number
  },
  computed: {
    slidesToShow() {
      let slidesToShow = this.settings.slidesToShow
      this.settings.responsive.forEach(item => {
        if (this.windowWidth <= item.breakpoint) {
          slidesToShow = item.settings.slidesToShow
          this.currentSlide = 0
        }
      })
      return Math.round(slidesToShow)
    }
  },
  methods: {
    setSlideCounts(oldSlideIndex, newSlideIndex) {
      this.currentSlide = newSlideIndex
      this.previousSlide = oldSlideIndex
    }
  },
  watch: {
    goto: function () {
      if (this.reference) {
        this.$refs[this.reference].goTo(this.goto)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  destroyed() {
    window.removeEventListener('resize', window)
  }
}
</script>

<style lang="scss" scoped>
.c-slick-carousel {
  &-arrow {
    position: relative;
    border: 2px solid transparent;
    height: toRem(40px);
    width: toRem(40px);
    margin: 0;
    padding: 0;
    border-radius: toRem(20px);
    background-color: $ocean-darker;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    z-index: 1;
    transition: opacity 0.5s ease;

    &:disabled {
      background-color: $grey-3;
      display: none;
    }

    &:focus {
      border: 2px solid $ocean-primary;
    }

    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }

    svg {
      position: absolute;
      fill: $white;
      width: 65%;
      height: 65%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>

<style lang="scss">
.c-slick-carousel {
  .slick-slider {
    padding-bottom: 40px;
  }

  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: toRem(20px);
    width: 100%;

    li {
      flex: 1;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        height: toRem(3px);
        width: 100%;
        background-color: $grey-4;
      }

      &.slick-active {
        &:before {
          background-color: $ocean-primary;
        }
      }
    }
    button {
      display: none;
    }
  }
}
</style>
