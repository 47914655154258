<template lang="pug">
.search-menu
  .search-menu__wrap.o-wrap.o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default(
    :class='{ "search-menu__wrap--thin": !isMobile && model.maxVisibleSections }'
  )
    .o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-space
      .o-grid__group
        .search-menu__section.search-menu__section--typeahead.o-grid__item.o-grid__item--8.o-grid__item--desktop-3(v-if='!model.hideLocation')
          c-filter-typeahead(
            :autofocusSearchInput='model.autofocusSearchInput',
            :modalOnClick='isMobile',
            :show-tags='true'
            noSearchOnMapButton=false
            placeholder='Søg kommune, by, vejnavn'
          )
        .search-menu__separator.o-grid__item.o-grid__item--grow-0(v-if='!model.hideLocation')
          .search-menu__separator-item
        .search-menu__section.search-menu__section--filters.o-grid__item.o-grid__item--grow-0.o-grid__item--desktop-5(v-if='!isMobile')
          .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item(v-if='sectionEnabled("SectionRent", 1)')
                c-filter-section(:dropdown='true', :model='{ filters: ["Rent"], headingText: Dictionary.Search.RentMax }')
                  .search-menu__filter-item
                    c-filter-input-number(
                      :model='{ filter: "Rent", headingText: Dictionary.Search.RentHeadingText, placeholderText: Dictionary.Search.RentPlaceholderText, labelText: Dictionary.Search.RentLabelText, helperText: Dictionary.Search.RentHelperText }'
                    )
              .o-grid__item(v-if='sectionEnabled("SectionRooms", 2)')
                c-filter-section(:dropdown='true', :model='{ filters: ["Rooms"], headingText: Dictionary.Search.RoomsMin }')
                  .search-menu__filter-item
                    c-filter-input-slider(
                      :model='{ filter: "Rooms", headingText: Dictionary.Search.RoomsHeadingText, labelText: Dictionary.Search.RoomsLabelText, helperText: Dictionary.Search.RoomsHelperText, boundMax: 10, boundMin: 0 }',
                      :reverse='true'
                    )
              .o-grid__item(v-if='sectionEnabled("SectionSize", 3)')
                c-filter-section(:dropdown='true', :model='{ filters: ["Area"], headingText: Dictionary.Search.AreaHeadingText }')
                  .search-menu__filter-item
                    c-filter-input-slider(
                      :model='{ filter: "Area", headingText: Dictionary.Search.AreaHeadingText, labelText: Dictionary.Search.AreaLabelText, helperText: Dictionary.Search.AreaHelperText, boundMax: 500, boundMin: 0, boundInterval: 25 }',
                      :reverse='true'
                    )
              .o-grid__item(v-if='sectionEnabled("SectionType", 4)')
                c-filter-section(
                  :dropdown='true',
                  :model='{ filters: ["PropertyType", "Types", "Floor", "PropertyYear"], headingText: Dictionary.Search.PropertyTypes }'
                )
                  .search-menu__filter-item(v-if='!model.hideType')
                    c-filter-input-type(:model='propertyTypeModel')
                  .search-menu__filter-item(v-if='!model.hideSubType && residenceTypesModel.options.length > 0')
                    c-filter-input-checklist(:model='residenceTypesModel') 
                  .search-menu__filter-item(v-if='!model.hideFloor')
                    c-filter-input-slider(
                      :model='{ filter: "Floor", headingText: Dictionary.Search.FloorHeadingText, labelText: Dictionary.Search.MinMaxFloor, helperText: Dictionary.Search.FloorHelperText, boundMin: -3, boundMax: 30, boundInterval: 1 }'
                    )
                  .search-menu__filter-item(v-if='!model.hideYear')
                    c-filter-input-select(:model='propertyYearModel')
              .o-grid__item(v-if='sectionEnabled("SectionDuration", 5)')
                c-filter-section(
                  :dropdown='true',
                  :model='{ filters: ["AvailableFrom", "RentalPeriod", "OpenHouseDate"], headingText: Dictionary.Search.Duration }'
                )
                  .search-menu__filter-item
                    c-filter-input-radiolist(
                      :model='{ filter: "AvailableFrom", headingText: Dictionary.Search.TakeOverDateHeadingText, labelText: Dictionary.Search.TakeOverDateLabelText, helperText: Dictionary.Search.TakeOverDateHelperText, defaultOptionLabelText: Dictionary.E.EverythingIsOfInterest, options: [ { value: 1, labelText: Dictionary.TakeOverDate.now }, { value: 2, labelText: Dictionary.TakeOverDate.oneToThreeMonths }, { value: "", labelText: Dictionary.TakeOverDate.selectDate, specialType: "selectDate" }, ], }'
                    )
                  .search-menu__filter-item
                    c-filter-input-radiolist(
                      :model='{ filter: "RentalPeriod", headingText: Dictionary.Search.RentalPeriodHeadingText, labelText: Dictionary.Search.RentalPeriodLabelText, helperText: Dictionary.Search.RentalPeriodHelperText, defaultOptionLabelText: Dictionary.E.EverythingIsOfInterest, options: [ { value: "TwoToTwelveMonth", labelText: Dictionary.RentalPeriods.TwoToTwelveMonth }, { value: "OneToTwoYears", labelText: Dictionary.RentalPeriods.OneToTwoYears }, { value: "TwoOrMoreYears", labelText: Dictionary.RentalPeriods.TwoOrMoreYears }, { value: "Unlimited", labelText: Dictionary.RentalPeriods.Unlimited }, ], }'
                    )
                  .search-menu__filter-item(v-if='!model.hideOpenHouseDate')
                    c-filter-input-checklist(:model='OpenHouseDateModel')
              .o-grid__item(v-if='sectionEnabled("SectionCompany", 6)')
                c-filter-section(:dropdown='true', :model='{ filters: ["PropertyOrganizationId"], headingText: Dictionary.Search.CompanySection }')
                  .search-menu__filter-item(v-if='!model.hideCompany')
                    c-filter-input-select(:model='propertyOrganizationModel')
              .o-grid__item(v-if='sectionEnabled("SectionFacilities", 7)')
                c-filter-section(
                  :dropdown='true',
                  :model='{ filters: ["PropertyFacilities", "Facilities"], headingText: Dictionary.Search.FacilitiesSection }'
                )
                  .search-menu__filter-item(v-if='!model.hidePropertyFacilities')
                    c-filter-input-checklist(:model='propertyFacilitiesModel')
                  .search-menu__filter-item(v-if='!model.hideResidenceFacilities')
                    c-filter-input-checklist(:model='residenceFacilitiesModel')
        .search-menu__section.search-menu__section--filter-toggle.o-grid__item.o-grid__item--4.o-grid__item--desktop-1(
          v-if='!allFiltersDisabled && (isMobile || model.maxVisibleSections)'
        )
          .search-menu__filters-overlay(@click='toggleSearchModal')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-filter' y='0')
            span {{ searchFiltersText }}
        .search-menu__separator.o-grid__item.o-grid__item--grow-0(v-if='!allFiltersDisabled')
          .search-menu__separator-item
        .search-menu__section.search-menu__section--search-agent.o-grid__item.o-grid__item--grow-0.o-grid__item--desktop-2(
          v-requiresAuthentication:click.stop='{ action: postSearchAgent, createdCtaText: Dictionary.SearchAgent.ProceedToCreateSearchAgent }'
        )
          svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-bell' y='0')
          span.o-bodytext.o-bodytext--size-medium {{ Dictionary.SearchAgent.CreateSearchAsAgent }}
        c-modal.c-modal--confirmation(
          :cornerClose='true',
          :darkTheme='true',
          :stickyFooter='true'
          @close='showSearchAgentErrorOverlay = !showSearchAgentErrorOverlay'
          v-if='showSearchAgentErrorOverlay'
        )
          template(slot='header')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
            .o-heading.o-heading--size-2 {{ Dictionary.SearchAgent.NoSearchFiltersModalHeadline }}
</template>
<script>
import moment from 'moment'

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-search-menu',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: this._uid,
      showSearchAgentErrorOverlay: false,
      currentYear: moment.utc().format('YYYY')
    }
  },
  computed: {
    ...mapState('search', ['filters', 'mapShowing', 'facilityLocations', 'organizations']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapGetters('search', ['activeFilters', 'preparedFiltersSearch', 'propertyFacilities', 'membershipOrganizationsInfo', 'residenceFacilities']),
    searchFiltersText() {
      return 'Søgfiltre ' + `(${this.activeFilters ? Object.keys(this.activeFilters).length : 0})`
    },
    allFiltersDisabled() {
      return this.enabledFiltersSections.length === 0
    },
    enabledFiltersSections() {
      const sections = []
      if (!this.model.hideRent) sections.push('SectionRent')
      if (!this.model.hideRooms) sections.push('SectionRooms')
      if (!this.model.hideSize) sections.push('SectionSize')
      if (!this.model.hideType || !this.model.hideSubType || !this.model.hideFloor || !this.model.hideYear) sections.push('SectionType')
      if (!this.model.hideCompany) sections.push('SectionCompany')
      if (!this.model.hideTakeover || !this.model.hideDuration) sections.push('SectionDuration')
      if (!this.model.hidePropertyFacilities || !this.model.hideResidenceFacilities) sections.push('SectionFacilities')
      return sections
    },
    propertyTypeModel() {
      const propertyTypeModel = {
        filter: 'PropertyType',
        headingText: this.Dictionary.Search.TypeHeadingText,
        labelText: this.Dictionary.Search.TypeLabelText,
        helperText: this.Dictionary.Search.TypeHelperText,
        options: this.propertyTypes.map(pt => {
          return { labelText: pt.name, value: pt.id, icon: pt.icon }
        })
      }
      return propertyTypeModel
    },
    propertyOrganizationModel() {
      const propertyOrganizationModel = {
        filter: 'PropertyOrganizationId',
        headingText: this.Dictionary.Search.CompanyHeadingText,
        labelText: this.Dictionary.Search.CompanyLabelText,
        helperText: this.Dictionary.Search.CompanyHelperText,
        options: Object.keys(this.membershipOrganizationsInfo)
          .filter(mo => Object.keys(this.membershipOrganizationsInfo[mo].companies).length > 0)
          .map(mo => {
            return { name: this.membershipOrganizationsInfo[mo].name, value: this.membershipOrganizationsInfo[mo].id }
          }),
        settings: {
          multiple: true,
          alwaysOpen: true,
          reduce: option => option.value
        }
      }
      return propertyOrganizationModel
    },
    residenceTypesModel() {
      const residenceTypesModel = {
        filter: 'Types',
        headingText: this.Dictionary.Search.SubTypeHeadingText,
        labelText: this.Dictionary.Search.SubTypeLabelText,
        helperText: this.Dictionary.Search.SubTypeHelperText,
        options: this.residenceTypes.map(rt => {
          return { labelText: rt.name, value: rt.id }
        })
      }
      return residenceTypesModel
    },
    OpenHouseDateModel() {
      const OpenHouseDateModel = {
        filter: 'OpenHouseDate',
        headingText: this.Dictionary.Search.OpenHouseHeadingText,
        labelText: this.Dictionary.Search.OpenHouseLabelText,
        helperText: this.Dictionary.Search.OpenHouseHelperText,
        options: [{ labelText: this.Dictionary.O.OpenHouse, value: '[NOW;*]' }]
      }
      return OpenHouseDateModel
    },
    propertyFacilitiesModel() {
      const propertyFacilitiesModel = {
        filter: 'PropertyFacilities',
        headingText: this.Dictionary.Search.PropertyFacilitiesHeadingText,
        labelText: this.Dictionary.Search.PropertyFacilitiesLabelText,
        helperText: this.Dictionary.Search.PropertyFacilitiesHelperText,
        options:
          this.facilityLocations.length > 0 &&
          this.propertyFacilities.map(pf => {
            return { labelText: pf.name, value: pf.id }
          })
      }
      return propertyFacilitiesModel
    },
    residenceFacilitiesModel() {
      const residenceFacilitiesModel = {
        filter: 'Facilities',
        headingText: this.Dictionary.Search.FacilitiesHeadingText,
        labelText: this.Dictionary.Search.FacilitiesLabelText,
        helperText: this.Dictionary.Search.FacilitiesHelperText,
        options:
          this.facilityLocations.length > 0 &&
          this.residenceFacilities.map(rf => {
            return { labelText: rf.name, value: rf.id }
          })
      }
      return residenceFacilitiesModel
    },
    propertyYearModel() {
      const propertyYearModel = {
        filter: 'PropertyYear',
        headingText: this.Dictionary.Search.YearHeadingText,
        placeholderText: this.Dictionary.Search.YearPlaceholderText,
        labelText: this.Dictionary.Search.YearLabelText,
        helperText: this.Dictionary.Search.YearHelperText,
        boundMin: 1900,
        boundMax: this.currentYear,
        settings: {
          reduce: option => option.value
        }
      }

      return propertyYearModel
    },
    searchAgentName() {
      const agentNameValues = []
      let locationCategoriesValuesCount = 0
      let activeFilterValuesCount = 0
      const agentName = []
      Object.keys(this.activeFilters).forEach(category => {
        if (this.activeFilters[category]) {
          switch (category) {
            case 'Commune':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${this.Dictionary.C.Commune}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'City':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${this.Dictionary.C.City}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'Place':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${this.Dictionary.P.Place}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'StreetAddress':
            case 'Street':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${category === 'StreetAddress' ? this.Dictionary.S.Street : this.Dictionary.S.StreetName}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'PropertyName':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${this.Dictionary.P.PropertyName}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'PostalCodeName':
            case 'PostalCodeAndPostalCodeName':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value} ${this.Dictionary.P.PostalCode}`)
                locationCategoriesValuesCount += 1
              })
              break
            case 'PostalCode':
              agentNameValues.push(`${this.activeFilters[category]}`)
              locationCategoriesValuesCount += 1
              break
            case 'Types':
              this.activeFilters[category].forEach(value => {
                const type = this.residenceTypes.find(t => t.id === value)
                if (type) {
                  agentNameValues.push(`${type.name}`)
                }
              })
              break
            case 'Rent':
              agentNameValues.push(`${this.Dictionary.M.Max} ${this.Dictionary.R.Rent} ${this.activeFilters[category]}${this.Dictionary.C.Currency}`)
              break
            case 'Rooms':
              agentNameValues.push(`${this.Dictionary.M.Min} ${this.activeFilters[category]} ${this.Dictionary.R.Rooms}`)
              break
            case 'Area':
              agentNameValues.push(`${this.Dictionary.M.Min} ${this.activeFilters[category]} ${this.Dictionary.Search.m2}`)
              break
            case 'PropertyType':
              this.activeFilters[category].forEach(value => {
                const type = this.propertyTypes.find(t => t.id === value)
                if (type) {
                  agentNameValues.push(`${type.name}`)
                }
              })
              break
            case 'Facilities':
              this.activeFilters[category].forEach(value => {
                const facility = this.residenceFacilities.find(f => f.id === value)
                if (facility) {
                  agentNameValues.push(`${facility.name}`)
                }
              })
              break
            case 'PropertyFacilities':
              this.activeFilters[category].forEach(value => {
                const facility = this.propertyFacilities.find(f => f.id === value)
                if (facility) {
                  agentNameValues.push(`${facility.name}`)
                }
              })
              break
            case 'OpenHouseDate':
              agentNameValues.push(`${this.Dictionary.O.OpenHouse}`)
              break
            case 'Floor':
              agentNameValues.push(`${this.Dictionary.F.Floor} ${this.activeFilters[category][0]}/${this.activeFilters[category][1]}`)
              break
            case 'AvailableFrom':
              if (this.activeFilters[category] === 1) {
                agentNameValues.push(`${this.Dictionary.T.TakeoverDate} ${this.Dictionary.TakeOverDate.now}`)
              } else if (this.activeFilters[category] === 2) {
                agentNameValues.push(`${this.Dictionary.T.TakeoverDate} ${this.Dictionary.TakeOverDate.oneToThreeMonths}`)
              } else if (this.activeFilters[category]) {
                agentNameValues.push(`${this.Dictionary.T.TakeoverDate} ${this.activeFilters[category]}`)
              }
              break
            case 'RentalPeriod':
              if (this.activeFilters[category] === 'TwoToTwelveMonth') {
                agentNameValues.push(`${this.Dictionary.R.RentalPeriod} ${this.Dictionary.RentalPeriods.TwoToTwelveMonth}`)
              } else if (this.activeFilters[category] === 'OneToTwoYears') {
                agentNameValues.push(`${this.Dictionary.R.RentalPeriod} ${this.Dictionary.RentalPeriods.OneToTwoYears}`)
              } else if (this.activeFilters[category] === 'TwoOrMoreYears') {
                agentNameValues.push(`${this.Dictionary.R.RentalPeriod} ${this.Dictionary.RentalPeriods.TwoOrMoreYears}`)
              } else if (this.activeFilters[category] === 'Unlimited') {
                agentNameValues.push(`${this.Dictionary.R.RentalPeriod} ${this.Dictionary.RentalPeriods.Unlimited}`)
              } else if (this.activeFilters[category]) {
                agentNameValues.push(`${this.Dictionary.R.RentalPeriod} ${this.activeFilters[category]}`)
              }
              break
            case 'PropertyOrganizationId':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${this.membershipOrganizationsInfo.find(mo => mo.id === value).name}`)
              })
              break
            case 'PropertyYear':
              agentNameValues.push(`${this.activeFilters[category]}`)
              break
            case 'Number':
              agentNameValues.push(`${this.activeFilters[category]}`)
              break
            case 'Door':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value}`)
              })
              break
            case 'Letter':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value}`)
              })
              break
            case 'Created':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value}`)
              })
              break
            case 'Updated':
              this.activeFilters[category].forEach(value => {
                agentNameValues.push(`${value}`)
              })
              break
          }

          activeFilterValuesCount += 1
        }
      })

      if (locationCategoriesValuesCount > 1 && activeFilterValuesCount > 3) {
        for (let i = 0; i < 3; ++i) {
          if (i === 0) {
            agentName.push(`${agentNameValues[i]} ${this.Dictionary.E.Etcetera}`)
          } else if (agentNameValues[locationCategoriesValuesCount - 1 + i]) {
            agentName.push(`${agentNameValues[locationCategoriesValuesCount - 1 + i]}`)
          }
        }
      } else if (activeFilterValuesCount > 0) {
        const maxCount = activeFilterValuesCount < 3 ? activeFilterValuesCount : 3
        for (let i = 0; i < maxCount; ++i) {
          agentName.push(agentNameValues[i])
        }
      } else {
        agentName.push(this.Dictionary.S.SearchAgent)
      }

      return agentName.join(', ').substring(0, 100)
    }
  },
  methods: {
    sectionEnabled(section, lineNumber) {
      return this.enabledFiltersSections.includes(section) && (this.model.maxVisibleSections ? this.model.maxVisibleSections >= lineNumber : true)
    },
    toggleSearchModal(state) {
      this.$store.dispatch('search/toggleSearchModal', state)
    },
    postSearchAgent() {
      if (Object.keys(this.activeFilters).length > 0) {
        this.$store.dispatch('search/postSearchAgent', { searchAgentName: this.searchAgentName, redirectUrl: `${this.Language}/profile/search-agents` })
      } else {
        this.showSearchAgentErrorOverlay = true
      }
    }
  },
  created() {
    if (this.model.filterPreset) {
      this.$store.dispatch('search/setSearchPresets', JSON.parse(this.model.filterPreset))
    }

    if (this.mapShowing) {
      this.$store.dispatch('search/getAllResults')
    } else {
      this.$store.dispatch('search/getResults')
    }
  }
}
</script>
<style lang="scss" scoped>
.search-menu {
  background-color: $ocean-primary;

  &__wrap {
    padding-top: 35px;
    padding-bottom: 40px;

    @include max('desktop') {
      padding-top: toRem(map-get($wrappadding, 'phone'));
      padding-bottom: toRem(map-get($wrappadding, 'phone'));
    }

    &--thin {
      padding-top: toRem(map-get($wrappadding, 'phone'));
      padding-bottom: toRem(map-get($wrappadding, 'phone'));
    }
  }

  &__separator {
    align-self: normal;
    &-item {
      position: relative;
      width: 20px;
      height: 100%;
      &:after {
        content: '';
        background-color: $ocean-1;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0 10px;
      }

      @include max('desktop') {
        width: 10px;
      }
      @include max('tablet', -1) {
        display: none;
      }
    }
  }

  &__section {
    &--filters {
      .o-grid {
        min-height: 100%;
      }
      @include max('desktop') {
        width: 45% !important;
        flex-grow: 1;
      }
    }
    &--typeahead {
      @include max('desktop') {
        width: 23% !important;
        max-width: 23% !important;
      }
      @include max('tablet', -1) {
        width: 66.6666666667% !important;
        flex: 1 1 66.6666666667%;
        max-width: 66.6666666667% !important;
      }
    }
    &--filter-toggle {
      text-align: center;
      cursor: pointer;
      color: $crab-primary;
      @include setStyles('12', $s-typography-sizes);

      svg {
        margin: 0 auto;
        display: block;
        width: 40px;
        height: 40px;
        fill: $crab-primary;
      }
    }
    &--search-agent {
      color: $white;
      @include setStyles('15', $s-typography-sizes);
      display: flex;
      flex-wrap: nowrap;
      cursor: pointer;
      align-self: flex-start;
      @include max('desktop') {
        margin-right: 20px;
        .o-bodytext {
          display: none;
        }
      }
      @include max('tablet', -1) {
        margin-right: 0;
        .o-bodytext {
          display: inline-block;
        }
      }
      svg {
        width: 25px;
        height: 25px;
        fill: $white;
        stroke: $white;
        flex: 0 0 auto;
        margin-right: 5px;
      }
    }
  }

  &__filter-item {
    & + & {
      margin-top: 20px;
    }
  }
}
</style>
