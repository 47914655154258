<template lang="pug">
.c-adm-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-11(v-if='administratorsData.options.length > 0')
      c-fc-select(:model='administratorsData' v-model='administrator')
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-adm-search__button(:disabled='!selectedAdministrator' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-adm-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      propertyAdministrator: {
        helperText: this.model.administratorSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.P.PropertyAdministrator,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('administratorsManagement', ['administrators', 'selectedAdministrator']),
    administratorsData() {
      const administratorsData = Object.assign({}, this.propertyAdministrator)
      administratorsData.options = this.administrators.map(a => {
        a.value = a.id
        return a
      })
      administratorsData.value = this.administrator && this.administrator.id
      return administratorsData
    },
    administrator: {
      get() {
        return this.selectedAdministrator
      },
      set(value) {
        const admin = value && this.administrators.find(administrator => administrator.id === value)
        this.$store.commit('administratorsManagement/updateData', { selectedAdministrator: admin })
      }
    }
  },
  methods: {
    handleReset() {
      this.administrator = null
    }
  },
  created() {
    // if (!this.companies.length) this.$store.dispatch('propertyManagement/getCompanies')
  }
}
</script>
<style lang="scss" scoped>
.c-adm-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
