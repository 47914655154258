<template lang="pug">
header.header(:class='{ "header--unpinned": scrolled }' v-on='handleScroll()')
  slot
  dev-tools(v-if='isLocal')
</template>
<script>
import devTools from '@/components/dev-tools'
export default {
  name: 'c-header',
  data() {
    return {
      limitPosition: 80,
      scrolled: false,
      lastPosition: 0
    }
  },
  components: {
    'dev-tools': devTools
  },
  computed: {
    isLocal() {
      return ['http://cm.findbolig.local', 'https://cm.findbolig.aksdc1.devtest.sh', 'https://cd.findbolig.aksdc1.devtest.sh'].includes(window.location.origin)
    }
  },
  methods: {
    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = false
      }
      this.lastPosition = window.scrollY
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    document.body.classList.add('app-ready')
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    document.body.classList.remove('app-ready')
  }
}
</script>
