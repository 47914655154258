export const toast = {
  namespaced: true,
  state: {
    toastMessages: []
  },
  actions: {
    show({ commit }, payload) {
      commit('show', payload)
    },
    remove({ commit }, payload) {
      commit('remove', payload)
    }
  },
  mutations: {
    show(state, payload) {
      payload.key = state.toastMessages.length
      state.toastMessages.unshift(payload)
    },
    remove(state, payload) {
      let index = -1
      if (payload.key !== undefined) {
        index = state.toastMessages.findIndex(toast => {
          return toast.key === payload.key
        })
      } else if (payload.url) {
        index = state.toastMessages.findIndex(toast => {
          return toast.config && toast.config.url === payload.url
        })
      }
      if (index > -1) {
        state.toastMessages.splice(index, 1)
      }
    }
  }
}
