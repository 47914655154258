import { signupsManagementService, searchService } from '@/_services'

const initialState = {
  signups: [],
  selected: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmationTarget: undefined
}

export const signupsManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state
  },
  actions: {
    async handleConfirmation({ commit, state }) {
      try {
        let response
        switch (state.confirmationTarget.action) {
          case 'SetActive':
            response = await signupsManagementService.setAllPropertySignupsToActive(state.confirmationTarget.id)
            break

          case 'SetPassive':
            response = await signupsManagementService.setAllPropertySignupsToPassive(state.confirmationTarget.id)
            break

          case 'Delete':
            response = await signupsManagementService.deleteAllPropertySignups(state.confirmationTarget.id)
            break
        }

        if (response && response.status === 204) {
          this.dispatch('signupsManagement/getMySignups')
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getUserWaitingListPosition({ commit }, id) {
      try {
        const response = await signupsManagementService.getUserWaitingListPosition(id)
        if (response) {
          if (response.status === 200) commit('addSignup', { id, waitingListPosition: response.data })
          if (response.status === 204) commit('addSignup', { id, waitingListPosition: 'none' })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getMySignups({ commit, rootState }) {
      try {
        const response = await signupsManagementService.getMySignups()
        if (response && response.status === 200) {
          commit('updateData', {
            totalResults: response.data.totalResults,
            signups: []
          })

          const propertySignups = []
          response.data.forEach(signup => {
            const targetProperty = propertySignups.find(propertySignup => propertySignup.id === signup.propertyId)

            if (targetProperty) {
              targetProperty.signups.push(signup)
            } else {
              propertySignups.push({
                id: signup.propertyId,
                signups: [signup],
                waitingListStatus: signup.status,
                waitingListPosition: null
              })
            }
          })

          const propertyIds = propertySignups.map(signup => signup.id)

          const payload = {
            filters: { propertyId: propertyIds },
            mixedResults: true,
            pageSize: propertySignups.length
          }

          const searchResponse = await searchService.postSearch(payload)
          if (searchResponse && searchResponse.status === 200) {
            const signupsProperties = searchResponse.data.results
            signupsProperties
              .filter(x => x.$type === 'Property')
              .forEach(property => {
                const pf = rootState.user.pensionFunds.find(pf => pf.id === property.propertyOrganizationId)
                property.noSeniorityToPassiveMembers = pf && pf.waitingListSeniorityIsNotAwardedToPassiveMembers
                const targetSignup = propertySignups.find(a => a.id === property.id)

                property.waitingListStatus = targetSignup && targetSignup.waitingListStatus
                property.waitingListResidences = targetSignup && targetSignup.signups
                property.waitingListPosition = targetSignup && targetSignup.waitingListPosition

                commit('addSignup', property)
              })
          }

          propertyIds.forEach(id => {
            this.dispatch('signupsManagement/getUserWaitingListPosition', id)
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    deleteEntry(state, payload) {
      state.signups.splice(state.signups.indexOf(state.signups.find(signup => signup.id === payload)), 1)
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    addSignup(state, payload) {
      const target = state.signups.find(a => a.id === payload.id)
      if (target) {
        Object.assign(target, payload)
      } else {
        state.signups.push(payload)
      }
    },
    toggleConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    }
  }
}
