<template lang="pug">
.c-property-management-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-5
      c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-4(v-if='companies.length > 0')
      c-fc-select(:model='companyData' v-model='company.value')
    .o-grid__item.o-grid__item--desktop-2
      button.o-button.c-property-management-search__button(@click='handleSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-property-management-search__button(:disabled='!search.value && !company.value' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-property-management-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      company: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.O.Owner,
        isMandatory: false,
        options: this.companies,
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('search', ['companies']),
    companyData() {
      const companyData = Object.assign({}, this.company)
      companyData.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
      return companyData
    }
  },
  methods: {
    handleSearch() {
      const filter = {
        search: this.search.value,
        filters: { companyId: this.company.value }
      }
      this.$store.dispatch('propertyManagement/searchManagedProperties', filter)
    },
    handleReset() {
      this.search.value = null
      this.company.value = null
      this.handleSearch()
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    this.handleSearch()
  }
}
</script>
<style lang="scss" scoped>
.c-property-management-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
