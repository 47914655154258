<template lang="pug">
.c-form-email
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='id',
        :value='value'
        @blur='onBlur'
        @focus='onFocus'
        @keyup='handleKeyUp'
        @valuechange='handleValueChange'
        placeholder=' '
        type='email'
        v-debounce.valuechange='250'
        v-mask='this.model.validation && this.model.validation.displayRule'
      )
      label.o-label.o-label--placeholder(:class='[{ "o-label--required": model.isMandatory }]', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { userService } from '@/_services'
// import _ from 'lodash'
export default {
  name: 'c-form-email',
  data() {
    return {
      id: this._uid,
      value: undefined,
      waiting: false
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    current: {
      type: String
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        isValidRegex(value) {
          return this.validEmail(value)
        },
        exists(value) {
          if (this.model.validation.unique && value && value !== this.current && this.$v.value.isValidRegex) {
            return this.checkEmail()
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    existsValue() {
      return this.$v.value.exists
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    async checkEmail() {
      try {
        const response = await userService.validateUser({ email: this.value })
        return response.data !== 102
      } catch (error) {
        // console.log(error)
      }
    },
    handleKeyUp(event) {
      if (!event.isTrusted) return
      if (event.key === 'Enter') {
        this.$emit('submit', this.$v.value.$model)
      }
    },
    handleValueChange(event) {
      this.value = event.target.value

      this.$v.value.$touch()
      this.$emit('change', this.$v.value.$model)
      this.$emit('validate', !this.$v.$invalid)
    },
    setAfterModelChange() {
      if (this.value !== this.model.value) {
        // console.log('validate model change', this.value, this.model.value)
        this.value = this.model.value
        if (this.value !== null && this.value.length > 0) {
          this.$v.value.$touch()
        }
        this.$emit('validate', !this.$v.$invalid)
      }
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    },
    existsValue() {
      this.$emit('validate', !this.$v.$invalid)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-email {
}
</style>
