<template lang="pug">
.c-am-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    //- .o-grid__item.o-grid__item--12.o-grid__item--desktop-5
    //-   c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-11(v-if='applicationStatus.options.length > 0')
      c-fc-select(:model='applicationStatus' v-model='applicationStatus.value')
    //- .o-grid__item.o-grid__item--5.o-grid__item--desktop-2.c-am-search__search
    //-   button.o-button.c-am-search__button(@click='handleSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--5.o-grid__item--desktop-1.c-am-search__reset
      button.o-link.c-am-search__button(:disabled='!search.value && !applicationStatus.value' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-am-search',
  props: {
    model: Object,
    statusOptions: Array
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      applicationStatus: {
        helperText: this.model.statusSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.S.Status,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('applicationsManagement', ['applications']),
    selectedStatus() {
      return this.applicationStatus.value
    }
  },
  methods: {
    handleSearch() {
      const payload = {
        search: this.search.value,
        filters: { id: this.applications.map(application => application.id) },
        mixedResults: false
      }
      this.$store.dispatch('applicationsManagement/searchApplications', payload)
    },
    handleReset() {
      this.search.value = null
      this.applicationStatus.value = null
      // this.handleSearch()
    }
  },
  created() {
    this.applicationStatus.options = this.statusOptions
  },
  watch: {
    selectedStatus() {
      this.$emit('status', this.selectedStatus)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-am-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }

  &__search {
    order: 1;

    @include max('desktop', -1) {
      order: 2;
      margin-left: auto;
    }
  }

  &__reset {
    order: 2;

    @include max('desktop', -1) {
      order: 1;
      text-align: left;

      button {
        display: inline-block;
        width: auto;
      }
    }
  }
}
</style>
