<template lang="pug">
.c-advert-history
  .c-advert-history__form-section
    .c-advert-history__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations && model.applicanStatusTranslations.historyTabHeadline }}
  .c-advert-history__form-section
    .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-10
          c-fc-textbox(:model='historyAudits.historyAddNote')
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-2
          button.o-button.c-advert-history__filters__button(:disabled='loading' @click='submitNewNote()' role='button' v-debounce.keyup='500') {{ Dictionary.S.Save }}
  .c-advert-history__form-section
    .c-advert-history__filters.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
          c-fc-date(:model='historyStartData' @validate='validate($event, "historyStart")' v-model='historyAudits.historyStart.value')
        .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
          c-fc-date(:model='historyStopData' @validate='validate($event, "historyStop")' v-model='historyAudits.historyStop.value')
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
          c-fc-select(:model='historyAudits.type' @validate='validate($event, "type")' v-model='historyAudits.type.value')
        .o-grid__item.o-grid__item--desktop-2
          button.o-button.c-advert-history__filters__button(:disabled='loading' @click='onPageChange({ currentPage: 1 })' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
        .o-grid__item.o-grid__item--desktop-1
          button.o-link.c-advert-history__filters__button(
            :disabled='!historyAudits.historyStart.value && !historyAudits.historyStop.value && !historyAudits.type.value'
            @click='handleHistoryAuditsReset'
            role='button'
            v-debounce.keyup='500'
          ) {{ Dictionary.R.Reset }}
    vue-good-table(
      :columns='advertAuditsColumnsData',
      :pagination-options='paginationSettingsData',
      :rows='advertAuditsData',
      :sort-options='{ enabled: false }',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-sort-change='onSortChange'
      mode='remote'
      ref='table'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
        template(v-if='props.column.field == "message"')
          span.c-advert-history__message {{ props.row.message }}
        template(v-else-if='props.column.field == "historyNumber"')
          button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.historyNumber }}
        template(v-else-if='props.column.field == "owner"')
          span.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small {{ reformatOwner(props.row) }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import { auditsService } from '@/_services'
import moment from 'moment'

export default {
  name: 'c-advert-history',
  props: {
    model: {
      type: Object,
      required: true,
      perPage: {
        default: 10
      }
    }
  },
  components: {
    'c-fc-date': FormControllDate,
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-textbox': FormControllTextBox,
    VueGoodTable
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      pageOfItems: [],
      totalResults: 0,
      type: '',
      advertAudits: [],
      advertAuditsServerParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10
      },
      advertAuditsColumns: [
        {
          label: this.Dictionary.D.DateTime,
          field: 'created',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.T.Type,
          field: 'action',
          formatFn: this.reFormatAction
        },
        {
          label: this.Dictionary.A.Action,
          field: 'message'
        },
        {
          label: this.Dictionary.P.Performer,
          field: 'performerId',
          formatFn: this.reFormatPerformer
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'owner'
        }
      ],
      historyAudits: {
        historyAddNote: {
          helperText: null,
          value: null,
          placeholderText: this.Dictionary.A.Action,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        historyStart: {
          helperText: null,
          value: null,
          placeholderText: this.Dictionary.F.From,
          isMandatory: false,
          readOnly: false,
          validation: {},
          key: 0
        },
        historyStop: {
          helperText: null,
          value: null,
          placeholderText: this.Dictionary.T.To,
          isMandatory: false,
          readOnly: false,
          validation: {
            maxDate: moment.utc().format('YYYY-MM-DD')
          },
          key: 0
        },
        type: {
          helperText: null,
          placeholderText: this.Dictionary.T.Type,
          value: null,
          isMandatory: false,
          readOnly: false,
          options: [
            { name: this.Dictionary.AdvertAuditActions.Custom, value: 'Custom' },
            { name: this.Dictionary.AdvertAuditActions.ChangedResponsible, value: 'ChangedResponsible' },
            { name: this.Dictionary.AdvertAuditActions.ChangedStatus, value: 'ChangedStatus' },
            { name: this.Dictionary.AdvertAuditActions.Approved, value: 'Approved' },
            { name: this.Dictionary.AdvertAuditActions.Rejected, value: 'Rejected' }
          ],
          validation: null
        }
      }
    }
  },
  computed: {
    ...mapState('usersManagement', ['users']),
    ...mapState('user', ['userId', 'firstName', 'lastName', 'email']),
    ...mapGetters('user', ['hasElevatedRights']),
    ...mapState('advertManagement', ['selected']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('search', ['companies', 'organizations']),
    advertAuditsColumnsData() {
      const advertAuditsColumnsData = Object.assign([], this.advertAuditsColumns)
      if (!this.hasElevatedRights) {
        advertAuditsColumnsData.splice(4, 1)
      }
      return advertAuditsColumnsData
    },
    mutablePageSize: {
      get: function () {
        return this.pageSize
      },
      set: function (value) {
        this.$store.commit('historysManagement/updateData', { pageSize: value })
      }
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, {
        setCurrentPage: this.advertAuditsServerParams.page,
        enabled: this.totalResults > 0
      })
      return paginationSettingsData
    },
    historyStartData() {
      const historyStart = Object.assign({}, this.historyAudits.historyStart)
      historyStart.validation.maxDate = this.historyAudits.historyStop.value || moment.utc().format('YYYY-MM-DD')
      return historyStart
    },
    historyStopData() {
      const historyStopData = Object.assign({}, this.historyAudits.historyStop)
      historyStopData.validation.minDate = this.historyAudits.historyStart.value
      return historyStopData
    },

    advertAuditsData() {
      const advertAuditsData = Object.assign([], this.advertAudits)
      const missingPerformers = []
      advertAuditsData.forEach(audit => {
        const performer =
          this.userId === audit.performerId ? { firstname: this.firstName, lastName: this.lastName } : this.users.find(u => u.id === audit.performerId)

        if (!performer) {
          missingPerformers.push(audit.performerId)
        }
      })
      if (missingPerformers.length > 0) {
        this.$store.dispatch('usersManagement/getUsersStatus', { ids: missingPerformers, keepRecords: true })
      }
      return advertAuditsData
    }
  },
  methods: {
    async submitNewNote() {
      try {
        const data = {
          companyId: this.selected.companyId,
          message: this.historyAudits.historyAddNote.value,
          organizationId: this.selected.membershipOrganizationId,
          ownerId: this.selected.userId
        }
        const response = await auditsService.postAuditsAdvetNote(this.selected.id, data)

        if (response && response.status === 204) {
          this.historyAudits.historyAddNote.value = null
          this.handleAdvertAuditsSearch()
        }
      } catch (error) {
        // console.log(error)
      }
    },
    reFormatDate(value) {
      return value ? this.formatDate(value, 'DD-MM-YYYY HH:mm:ss') : ''
    },
    reFormatAction(value) {
      return this.Dictionary.AdvertAuditActions[value] || value
    },
    reFormatPerformer(value) {
      let userName
      if (this.userId === value) {
        userName = (this.firstName && this.lastName) || this.email
      } else {
        const user = this.users.find(user => user.id === value)
        userName = user && ((user.firstName && user.lastName) || user.email)
      }
      return userName || value
    },
    reformatOwner(value) {
      if (value && value.organizationId) {
        const org = this.organizations.find(org => org.id === value.organizationId)
        const orgName = org && org.name
        return orgName || value
      } else if (value && value.companyId) {
        const company = this.companies.find(company => company.id === value.companyId)
        const companyName = company && company.name
        return companyName || value
      } else {
        return null
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleHistoryAuditsReset() {
      this.historyAudits.historyStart.value = null
      this.historyAudits.historyStop.value = null
      this.historyAudits.type.value = null

      this.onPageChange({ currentPage: 1 })
    },
    async handleAdvertAuditsSearch() {
      try {
        const data = {
          page: this.advertAuditsServerParams.page,
          pageSize: this.advertAuditsServerParams.perPage
        }
        if (this.historyAudits.type.value) {
          data.action = this.historyAudits.type.value
        }
        if (this.historyAudits.historyStart.value) {
          data.from = moment.utc(this.historyAudits.historyStart.value).toDate().toISOString()
        }
        if (this.historyAudits.historyStop.value) {
          data.to = moment.utc(this.historyAudits.historyStop.value).add(1, 'days').toDate().toISOString()
        }
        const response = await auditsService.getAuditsAdverts(this.selected.id, data)
        if (response && response.status === 200) {
          this.advertAudits = response.data.results
          this.totalResults = response.data.totalResults
        }
        if (this.totalResults === 0) {
          this.updateParams({ perPage: 10, page: 1 })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getInitialData() {},
    updateParams(newProps) {
      this.serverParams = Object.assign(this.advertAuditsServerParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.handleAdvertAuditsSearch()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 })
      this.handleAdvertAuditsSearch()
    },

    onSortChange(params) {
      this.updateParams({
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.handleAdvertAuditsSearch()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.handleAdvertAuditsSearch()
    }
  },
  watch: {
    totalResults(newVal) {
      if (newVal === 0) {
        this.$refs.table.currentPerPage = 10
        this.$refs.table.currentPage = 1
      }
    }
  },
  created() {
    this.getInitialData()
    this.handleAdvertAuditsSearch()
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-advert-history {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  &__cancel {
    text-decoration: underline;
    display: block;
  }

  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
  &__filters {
    padding: toRem(16px 20px);
    background-color: $grey-5;
    &__button {
      width: 100%;
      min-height: 52px;
    }
  }

  &__message {
    white-space: pre-wrap;
  }
}
</style>
