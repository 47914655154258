<template lang="pug">
.c-messages-inbox.o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--gutter-none.o-grid--wrap-nowrap.o-grid--fixed-height
  .o-grid__group
    .o-grid__item.o-grid__item--grow-0
      .c-messages-inbox__header
        h1.o-heading.o-heading--size-2 {{ Dictionary.I.Inbox }}
    .o-grid__item.o-grid__item--grow-0
      .c-messages-inbox__search
        cMessagesSearch
    .o-grid__item.o-grid__item--grow-0
      .c-messages-inbox__sorting
        cMessagesSorting
    .o-grid__item.o-grid__item--overflow-auto
      .c-messages-inbox__threads
        cMessagesThreads
</template>
<script>
import cMessagesSearch from '../messages-search'
import cMessagesSorting from '../messages-sorting'
import cMessagesThreads from '../messages-threads'
export default {
  name: 'c-messages-inbox',
  components: { cMessagesSearch, cMessagesSorting, cMessagesThreads },
  props: {}
}
</script>
<style lang="scss" scoped>
.c-messages-inbox {
  height: 100%;

  &__header,
  &__search,
  &__sorting {
    color: $white;
    background-color: $ocean-primary;
    @include min('phoneplus') {
      background-color: transparent;
      color: inherit;
    }
  }

  &__header {
    padding: 25px 25px 10px;
    text-align: center;

    @include min('phoneplus') {
      padding: 10px 25px 20px;
      border-bottom: 1px solid $grey-4;
      text-align: initial;
    }
  }

  &__search,
  &__sorting {
    padding: 15px 25px;
    border-bottom: 1px solid $grey-4;
  }

  &__threads {
    min-height: 0;
    overflow: auto;
  }
}
</style>
