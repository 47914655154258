<template lang="pug">
.c-form-checkbox
  fieldset.o-input__container
    legend.o-label.o-label--before(:class='[{ "o-label--required": model.isMandatory }]' v-if='model.labelText') {{ model.labelText }}
    .o-grid.o-grid--direction-column.o-grid--justify-content-center.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0
          .o-input__wrap
            input.o-input(
              :aria-labelledby='`${id}-checkbox-label ${ariaLabeledBy}`',
              :autocomplete='model.autocomplete || "do-not-autofill"',
              :class='[{ error: $v.value.$error }]',
              :disabled='model.readOnly',
              :id='`${id}-checkbox`',
              :key='`${id}-checkbox`',
              :name='model.inputName || id',
              :value='!!model.value'
              @change='setvalue($event.target)'
              placeholder=' '
              type='checkbox'
              v-model='value'
            )
            label.o-label(:for='`${id}-checkbox`', :id='`${id}-checkbox-label`' v-if='model.placeholderText')
              span(:class='[{ "o-label--required": model.isMandatory }]') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-checkbox',
  data() {
    return {
      id: this._uid,
      value: false,
      returnValue: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value === true
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue() {
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.value ? this.returnValue : false)
    },
    setAfterModelChange() {
      this.returnValue = this.model.returnValue ? this.model.returnValue : true
      this.value = !!this.model.value
      if (this.model.value !== null) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-checkbox {
}
</style>
