<template lang="pug">
.c-ub4-fc-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-if='showFCSearch')
      c-fc-number(:model='searchFacilityNumberData' v-model='facilityNo')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-5(v-if='searchFacilityData.options.length > 0')
      c-fc-select(:model='searchFacilityData' v-model='facilityId')
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-ub4-fc-search__button(:disabled='!facilityId && !facilityNo' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllNumber from '@/components/form/controlls/form-number'
export default {
  name: 'c-ub4-fc-search',
  props: {
    model: Object,
    showFCSearch: Boolean
  },
  data() {
    return {
      id: this._uid,
      searchFacilityNumber: {
        helperText: this.model.searchHelperText,
        value: null,
        placeholderText: this.model.searchPlaceholderText,
        isMandatory: false,
        validation: {
          errorMessage: '',
          mask: '',
          displayRule: ''
        }
      },
      searchFacility: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.F.Facility,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-number': FormControllNumber
  },
  computed: {
    ...mapState('ub4FacilitiesManagement', ['selectedFacilityId', 'selectedFacilityNumber']),
    ...mapGetters('search', ['residenceFacilities', 'propertyFacilities']),
    ...mapState('search', ['facilities']),
    searchFacilityData() {
      const searchFacilityData = Object.assign({}, this.searchFacility)
      searchFacilityData.value = this.selectedFacilityId || null
      searchFacilityData.options = [
        { group: true, label: this.Dictionary.R.ResidenceFacilities, options: this.residenceFacilities },
        { group: true, label: this.Dictionary.P.PropertyFacilities, options: this.propertyFacilities }
      ]
      return searchFacilityData
    },
    facilityId: {
      get() {
        return this.selectedFacilityId
      },
      set(value) {
        this.$store.commit('ub4FacilitiesManagement/updateData', { selectedFacilityId: value })
      }
    },
    searchFacilityNumberData() {
      const searchFacilityNumberData = Object.assign({}, this.searchFacilityNumber)
      searchFacilityNumberData.value = this.facilityNo || null
      return searchFacilityNumberData
    },
    facilityNo: {
      get() {
        return this.selectedFacilityNumber
      },
      set(value) {
        this.$store.commit('ub4FacilitiesManagement/updateData', { selectedFacilityNumber: value })
      }
    }
  },
  methods: {
    handleReset() {
      this.facilityId = null
      this.facilityNo = null
    }
  },
  created() {
    if (this.facilities.length === 0) this.$store.dispatch('search/getFacilities')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-fc-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
