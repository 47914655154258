<template lang="pug">
.c-ap-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='companies.length > 0')
      c-fc-select(:model='companyData' v-model='company.value')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
      c-fc-text(:model='search' @submit='triggerSearch' v-model='search.value')
    .o-grid__item.o-grid__item--desktop-2
      button.o-button.c-ap-search__button(@click='triggerSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-ap-search__button(:disabled='!search.value && !company.value' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import { getQueryParameters } from '@/_helpers/query-parameters'
export default {
  name: 'c-ap-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      company: {
        helperText: this.model.ownerSelectHelperText,
        value: getQueryParameters().get('companyId') || null,
        placeholderText: this.Dictionary.O.Owner,
        isMandatory: false,
        options: this.companies,
        validation: null
      },
      verifiedIn: {
        helperText: '',
        value: getQueryParameters().get('verifiedIn'),
        placeholderText: '',
        isMandatory: false,
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('search', ['companies']),
    ...mapGetters('applicantsManagement', ['applicantsCategories']),
    ...mapState('applicantsManagement', ['serverParams']),
    companyData() {
      const companyData = Object.assign({}, this.company)
      companyData.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
      return companyData
    }
  },
  methods: {
    handleSearch() {
      const filter = {
        search: this.search.value,
        facets: ['categories'],
        filters: {},
        includedDeleted: true
      }
      if (this.company.value) {
        filter.filters.companyId = this.company.value
      }
      if (this.verifiedIn.value) {
        filter.filters.verifiedIn = this.verifiedIn.value
      }

      this.$store.dispatch('applicantsManagement/searchApplicants', filter)
    },
    triggerSearch() {
      if (this.serverParams.page !== 1) {
        this.$store.commit('applicantsManagement/updateParams', {
          page: 1,
          sort: [
            {
              field: '',
              type: ''
            }
          ],
        })
      } else {
        this.handleSearch()
      }
    },
    handleReset() {
      this.search.value = null
      this.company.value = null
      this.verifiedIn.value = null
      window.history.pushState({}, document.title, window.location.pathname)
      this.$store.commit('applicantsManagement/updateParams', {
        page: 1,
        sort: [
          {
            field: '',
            type: ''
          }
        ]
      })
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    this.handleSearch()
  },
  watch: {
    serverParams: {
      deep: true,
      handler() {
        this.handleSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-ap-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
