import apiClient from '@/_helpers/api-client'
export const signupsManagementService = {
  getMySignups,
  getUserPropertySignups,
  getPropertySignups,
  getUserWaitingListPosition,
  deleteAllPropertySignups,
  setAllPropertySignupsToActive,
  setAllPropertySignupsToPassive
}

async function getMySignups() {
  return await apiClient({
    url: '/data/residence-applications',
    method: 'GET'
  })
}
async function getUserPropertySignups({ userId, propertyId }) {
  return await apiClient({
    url: `/data/residence-applications/${userId}/${propertyId}`,
    method: 'GET'
  })
}
async function getPropertySignups(propertyId) {
  return await apiClient({
    url: `/data/residence-applications/${propertyId}`,
    method: 'GET'
  })
}
async function getUserWaitingListPosition(propertyId) {
  return await apiClient({
    url: `/search/waiting-lists/applicants/position-for-property/${propertyId}`,
    method: 'GET'
  })
}
async function deleteAllPropertySignups(propertyId) {
  return await apiClient({
    url: `/data/residence-applications/property/${propertyId}`,
    method: 'DELETE'
  })
}
async function setAllPropertySignupsToActive(propertyId) {
  return await apiClient({
    url: `/data/residence-applications/property/${propertyId}/set-active`,
    method: 'PUT'
  })
}
async function setAllPropertySignupsToPassive(propertyId) {
  return await apiClient({
    url: `/data/residence-applications/property/${propertyId}/set-passive`,
    method: 'PUT'
  })
}
