<template lang="pug">
a.property-card(:class='{ "property-card--disabled": disabled }', :href='!linkAction && cardLink' @click='cardClicked' @focus='focusOnTab' target='_blank')
  .property-card__top
    c-property-tags(:property='property')
    .property-card__name.notranslate {{ name }}
    .property-card__address.notranslate {{ address }}
    .property-card__specs
      .property-card__spec {{ getPropertyType() }}
      .property-card__spec {{ rooms }} {{ Dictionary.R.RoomsShort }}
      .property-card__spec {{ area }} {{ Dictionary.Search.m2 }}
      .property-card__spec(v-if='property.$type === "Residence" && !tags.availableNow') {{ Dictionary.F.FreeFrom }}: {{ property.availableFrom | formatDate }}
  .property-card__image-container
    img.property-card__image(:alt='property.street', :src='image' @load='imageLoaded = true' v-show='imageLoaded')
    .property-card__no-image(v-if='!imageLoaded')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-image' y='0')
      .property-card__no-image-text {{ Dictionary.N.NoImages }}
    c-property-labels.property-card__labels(:open-houses='property.openHouses || []', :renovation='property.underRenovation')
  .property-card__bottom.o-grid.o-grid--wrap-nowrap
    .o-grid__group
      .o-grid__item.o-grid__item--7.property-card__price-container
        .property-card__rent
          span {{ Dictionary.R.Rent }}&nbsp;
          span.notranslate {{ rent }}
        .property-card__account(v-if='property.$type === "Residence"')
          span.notranslate {{ Dictionary.A.Account }}&nbsp;
          span.notranslate {{ property.aconto | money }}
      .o-grid__item.o-grid__item--5.o-grid__item--align-right.property-card__owner
        img.property-card__owner-logo(
          :src='property.organizationLogo'
          @load='organizationLogoLoaded = true'
          v-if='property.organizationLogo'
          v-show='organizationLogoLoaded'
        )
        img.property-card__owner-logo(:src='property.companyLogo' @load='companyLogoLoaded = true' v-else-if='property.companyLogo' v-show='companyLogoLoaded')
        .property-card__owner-name(
          v-if='(!property.organizationLogo && !property.companyLogo) || ((property.organizationLogo || property.companyLogo) && !organizationLogoLoaded && !companyLogoLoaded)'
        ) {{ property.propertyCompanyName }}
    slot(name='waitinglistIndex')
      .o-grid__item.o-grid__item--12
        .property-card__list(v-if='property.$type === "Property" && property.listSize > 0')
          .property-card__list-text {{ property.membersOnly ? Dictionary.Search.WaitingListForMembers : Dictionary.Search.WaitingListForAll }}
          .property-card__list-count {{ property.listSize }} {{ Dictionary.Search.WaitingListCount }}
        .property-card__list(v-if='property.$type === "Project" && property.listSize > 0')
          .property-card__list-text {{ Dictionary.Search.InterestList }}
          .property-card__list-count {{ property.listSize }} {{ Dictionary.Search.InterestListCount }}
    .property-card__tags-bottom(v-if='facilities.length > 0')
      .property-card__tag-bottom.o-tag.o-tag--grey(v-for='facility in facilities') {{ facility }}
  slot(name='cardActions')
  c-favorites-button.property-card__favorite(:favorite-id='property.id', :property-type='property.$type')
</template>
<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'c-property-card',
  data() {
    return {
      address: '',
      area: '',
      cardLink: '',
      image: '',
      tags: {},
      name: '',
      rent: '',
      rooms: '',
      imageLoaded: false,
      organizationLogoLoaded: false,
      companyLogoLoaded: false
    }
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showWaitingListStatus: {
      type: Boolean,
      default: false
    },
    linkAction: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters('search', ['residenceFacilities', 'propertyFacilities']),
    ...mapState('search', ['propertiesByCompanies']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    facilities() {
      let facilities = []
      if (this.property.$type === 'Residence' && this.property.facilities) {
        facilities = this.property.facilities.slice(0, 3).map(fId => {
          const residenceFacility = this.residenceFacilities.find(f => f.id === fId)
          const propertyFacility = this.propertyFacilities.find(f => f.id === fId)
          const facility = residenceFacility || propertyFacility
          return (facility && facility.name) || fId
        })
      }
      return facilities
    }
  },
  methods: {
    focusOnTab(e) {
      if (e.target.closest('.slick-slide')) {
        this.$emit('focusedOnTab', e.target.closest('.slick-slide').dataset.index.toString())
      }
    },
    cardClicked(e) {
      this.$emit('action')
      // if (this.isMobile) {
      //  e.preventDefault()
      //  window.location.href = this.cardLink
      //  }
    },
    getPropertyType() {
      if (this.property.$type === 'Residence') {
        const typeItm = this.propertyTypes.find(t => t.id === this.property.propertyType)
        if (typeItm) {
          return typeItm.name
        }
      }

      return `${this.property.residencesCount} ${this.Dictionary.Search.ResidenciesInProperty}`
    },
    setTags() {
      this.tags.isNew = moment.utc(this.property.created).isAfter(moment.utc().subtract(8, 'days'))
      this.tags.waitingList = this.property.$type === 'Property' && !this.propertiesByCompanies
      this.tags.interestList = this.property.$type === 'Project'
      this.tags.membersOnly = this.property.$type === 'Residence' && this.property.membersOnly
      this.tags.availableNow =
        this.property.$type === 'Residence' &&
        (this.property.availableFrom == null || moment.utc(this.property.availableFrom) < moment.utc().add(14, 'days')) &&
        (this.property.availableTo == null || moment.utc(this.property.availableTo) > moment.utc())
      if (this.property.$type === 'Residence') {
        this.tags.types =
          this.property.types &&
          this.property.types.length > 0 &&
          this.property.types.map(type => {
            const target = this.residenceTypes.find(residenceType => residenceType.id === type)
            return target && target.name
          })
      }
      if (this.property.$type === 'Property') {
        const propType = this.property.propertyType && this.propertyTypes.find(pType => pType.id === this.property.propertyType)

        this.tags.types = this.property.residenceCommonTypes &&
          this.property.residenceCommonTypes.map(type => {
            const target = this.residenceTypes.find(residenceType => residenceType.id === type)
            return target && target.name
          })

        if (!Array.isArray(this.tags.types)) {
          this.tags.types = []
        }

        // this.tags.types.push(propType && propType.name)
        this.tags.propType = [propType && propType.name]
      }
    }
  },
  mounted() {
    const residenceMediaItem = this.property.media
    const propertyMediaItem = this.property.propertyMedia
    const residneceImage =
      residenceMediaItem &&
      ((residenceMediaItem.images && residenceMediaItem.images.length > 0 && residenceMediaItem.images[0]) ||
        (residenceMediaItem.blueprints && residenceMediaItem.blueprints.length > 0 && residenceMediaItem.blueprints[0]))
    const propertyImage =
      propertyMediaItem &&
      ((propertyMediaItem.images && propertyMediaItem.images.length > 0 && propertyMediaItem.images[0]) ||
        (propertyMediaItem.blueprints && propertyMediaItem.blueprints.length > 0 && propertyMediaItem.blueprints[0]))

    this.image = residneceImage || propertyImage
    
    this.cardLink = this.property.uri ? `/${this.Language}${this.property.uri}` : null
    this.rent =
      this.property.$type === 'Residence'
        ? this.$options.filters.money(this.property.rent)
        : this.$options.filters.money(this.property.minRent, this.property.maxRent)
    this.rooms =
      this.property.$type === 'Residence'
        ? `${this.property.rooms}`
        : this.property.minRooms !== this.property.maxRooms
        ? `${this.property.minRooms} - ${this.property.maxRooms}`
        : this.property.minRooms
    this.area =
      this.property.$type === 'Residence'
        ? `${this.property.area}`
        : this.property.minArea !== this.property.maxArea
        ? `${this.property.minArea} - ${this.property.maxArea}`
        : this.property.minArea
    this.name =
      this.property.$type === 'Residence'
        ? this.combineAddress(this.property.street, this.property.number, this.property.letter, this.property.floor, this.property.door)
        : this.property.name
    this.address = this.property.$type === 'Residence' ? `${this.property.postalCode} ${this.property.city}` : this.property.propertyAddress
  }
}
</script>

<style lang="scss" scoped>
.property-card {
  $root: &;
  border: 1px solid $grey-4;
  background-color: $white;
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;

  &--grey {
    background-color: $grey-5;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__top,
  &__bottom,
  &__actions {
    padding: 16px;
    position: relative;
  }
  &__bottom-waitinglist {
    border-top: toRem(1px) solid $grey-4;
    background-color: $grey-5;
    padding: 16px;
    position: relative;
  }

  &__tags-bottom {
    display: flex;
    margin-top: toRem(15px);
    flex-wrap: wrap;
  }

  &__name {
    @include setStyles('18', $s-typography-sizes);
    color: $ocean-darker;
    font-family: 'Inria Serif', serif;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &__address {
    @include setStyles('13', $s-typography-sizes);
    color: $grey-2;
    font-family: 'Inria Serif', serif;
    margin-bottom: 4px;
  }

  &__specs {
    @include setStyles('13', $s-typography-sizes);
    color: $ocean-darker;
    display: flex;
  }

  &__spec {
    position: relative;
    padding-right: 8px;
    margin-right: 8px;

    &:after {
      content: '';
      height: 11px;
      width: 1px;
      background-color: $grey-4;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__image-container {
    background-color: $grey-6;
    overflow: hidden;
    padding-top: 45%; // 180/400 aspect ratio
    display: flex;
    position: relative;
  }

  &__no-image,
  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  &__labels {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  &__label-checkbox {
    margin-right: toRem(12px);
    line-height: toRem(24px);
  }

  &__rent {
    @include setStyles('16', $s-typography-sizes);
    color: $ocean-darker;
    font-weight: 700;
  }

  &__account {
    @include setStyles('13', $s-typography-sizes);
    color: $ocean-darker;
  }

  &__owner {
    // position: absolute;
    // right: 16px;
    // top: 16px;
    @include setStyles('12', $s-typography-sizes);
    color: $grey-1;
    font-family: 'Inria Serif', serif;
    font-style: italic;
    text-align: right;
  }

  &__owner-logo {
    max-height: toRem(24px);
    max-width: 100%;
  }

  &__owner-name {
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    border-top: toRem(1px) solid $grey-4;
    padding: toRem(12px) 0;
    margin-top: toRem(10px);
  }
  &__list-text {
    @include setStyles('14', $s-typography-sizes);
    color: $ocean-darker;
    font-weight: 700;
  }
  &__list-count {
    color: $grey-1;
    @include setStyles('14', $s-typography-sizes);
  }

  &__no-image svg {
    height: toRem(80px);
  }
  &__no-image-text {
    @include setStyles('14', $s-typography-sizes);
    color: $ocean-darker;
    font-weight: 700;
    text-align: center;
    margin-top: toRem(10px);
  }
  &__favorite {
    position: absolute;
    right: toRem(13px);
    top: toRem(13px);
  }
  &__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__actions {
    background-color: $grey-5;
  }
}
</style>
