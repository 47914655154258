import Vue from 'vue'
import moment from 'moment'
import cpr from 'danish-ssn'
import { isMobile } from 'mobile-device-detect'

const objToForm = function (obj, allowNull, formData = new FormData(), subKey) {
  const data = formData

  Object.keys(obj).forEach(key => {
    const targetKey = subKey ? subKey + '.' + key : key
    const value = obj[key]

    if (value && (value[0] instanceof File || value instanceof File)) {
      // Handle Files Array
      if (value.length > 1) {
        value.forEach((file, fileKey) => {
          data.append(targetKey + '.' + fileKey, file)
        })
      } else {
        data.append(targetKey, value[0])
      }
    } else if (value || (allowNull && value !== undefined)) {
      if (Array.isArray(value) || value === null || typeof value !== 'object') {
        data.append(targetKey, value)
      } else if (typeof value === 'object') {
        objToForm(value, allowNull, data, key)
      }
    }
  })

  if (!subKey) {
    return data
  }
}

Vue.mixin({
  data() {
    return {
      windowWidth: window.innerWidth,
      paginationSettings: {
        enabled: true,
        mode: 'pages',
        perPageDropdown: [10, 20, 50, 10000],
        dropdownAllowAll: false,
        nextLabel: window.dictionary.N.Next,
        prevLabel: window.dictionary.P.Previous,
        rowsPerPageLabel: window.dictionary.R.RowsPerPage,
        ofLabel: window.dictionary.O.Of.toLowerCase(),
        pageLabel: window.dictionary.P.Page, // for 'pages' mode
        allLabel: window.dictionary.A.All
      },
      sliderSettings: {
        arrows: true,
        dots: true,
        slidesToShow: 6.2,
        slideFocus: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 1940,
            settings: {
              slidesToShow: 2.3,
              slidesToScroll: 1,
              slideFocus: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              slideFocus: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              slideFocus: false,
              arrows: false,
              infinite: true,
              centerMode: true,
              centerPadding: '40px'
            }
          }
        ]
      }
    }
  },
  methods: {
    getAdvertStatuses(selectOptions = false) {
      if (selectOptions) {
        return [
          {
            name: this.Dictionary.ResidenceAdvertStatus.Draft,
            value: 'Draft'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.Rejected,
            value: 'Rejected'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.PublishedNotScreened,
            value: 'PublishedNotScreened'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.Published,
            value: 'Published'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.DeadlineMet,
            value: 'DeadlineMet'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.Reserved,
            value: 'Reserved'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.RentedOut,
            value: 'RentedOut'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.Expired,
            value: 'Expired'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.Retired,
            value: 'Retired'
          },
          {
            name: this.Dictionary.ResidenceAdvertStatus.RetiredFromRentedOut,
            value: 'RetiredFromRentedOut'
          }
        ]
      } else {
        return {
          Draft: this.Dictionary.ResidenceAdvertStatus.Draft,
          Rejected: this.Dictionary.ResidenceAdvertStatus.Rejected,
          PublishedNotScreened: this.Dictionary.ResidenceAdvertStatus.PublishedNotScreened,
          Published: this.Dictionary.ResidenceAdvertStatus.Published,
          DeadlineMet: this.Dictionary.ResidenceAdvertStatus.DeadlineMet,
          Reserved: this.Dictionary.ResidenceAdvertStatus.Reserved,
          RentedOut: this.Dictionary.ResidenceAdvertStatus.RentedOut,
          Expired: this.Dictionary.ResidenceAdvertStatus.Expired,
          Retired: this.Dictionary.ResidenceAdvertStatus.Retired,
          RetiredFromRentedOut: this.Dictionary.ResidenceAdvertStatus.RetiredFromRentedOut
        }
      }
    },
    getAdvertApplicantsStatuses(selectOptions = false) {
      if (selectOptions) {
        return [
          { value: 'New', name: this.Dictionary.ApplicationStatus.New },
          { value: 'Interested', name: this.Dictionary.ApplicationStatus.Interested },
          { value: 'FollowUp', name: this.Dictionary.ApplicationStatus.FollowUp },
          { value: 'ShowingBooked', name: this.Dictionary.ApplicationStatus.ShowingBooked },
          { value: 'Finished', name: this.Dictionary.ApplicationStatus.Finished },
          { value: 'Offered', name: this.Dictionary.ApplicationStatus.Offered },
          { value: 'RentedOut', name: this.Dictionary.ApplicationStatus.RentedOut },
          { value: 'Cancelled', name: this.Dictionary.ApplicationStatus.Cancelled },
          { value: 'Deleted', name: this.Dictionary.ApplicationStatus.Deleted }
        ]
      } else {
        return {
          New: this.Dictionary.ApplicationStatus.New,
          Interested: this.Dictionary.ApplicationStatus.Interested,
          FollowUp: this.Dictionary.ApplicationStatus.FollowUp,
          ShowingBooked: this.Dictionary.ApplicationStatus.ShowingBooked,
          Finished: this.Dictionary.ApplicationStatus.Finished,
          Offered: this.Dictionary.ApplicationStatus.Offered,
          RentedOut: this.Dictionary.ApplicationStatus.RentedOut,
          Cancelled: this.Dictionary.ApplicationStatus.Cancelled,
          Deleted: this.Dictionary.ApplicationStatus.Deleted
        }
      }
    },
    tableSortAlphabeticaly(x, y, col, rowX, rowY) {
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2

      return x.localeCompare(y, 'da-DK')
    },
    getOfferStates(selectOptions = false) {
      if (selectOptions) {
        return [
          { name: this.Dictionary.OfferStates.Draft, value: 'Draft' },
          { name: this.Dictionary.OfferStates.Published, value: 'Published' },
          { name: this.Dictionary.OfferStates.Changed, value: 'Changed' },
          { name: this.Dictionary.OfferStates.Finished, value: 'Finished' },
          { name: this.Dictionary.OfferStates.Awarded, value: 'Awarded' },
          { name: this.Dictionary.OfferStates.AwardedExternally, value: 'AwardedExternally' },
          { name: this.Dictionary.OfferStates.Released, value: 'Released' },
          { name: this.Dictionary.OfferStates.RetiredFromAwarded, value: 'RetiredFromAwarded' }
        ]
      } else {
        return {
          Draft: this.Dictionary.OfferStates.Draft,
          Published: this.Dictionary.OfferStates.Published,
          Changed: this.Dictionary.OfferStates.Changed,
          Finished: this.Dictionary.OfferStates.Finished,
          Awarded: this.Dictionary.OfferStates.Awarded,
          AwardedExternally: this.Dictionary.OfferStates.AwardedExternally,
          Released: this.Dictionary.OfferStates.Released,
          RetiredFromAwarded: this.Dictionary.OfferStates.RetiredFromAwarded
        }
      }
    },
    getDate(addRemove, addRemoveCount, addRemoveType, format = 'DD-MM-YYYY') {
      if (addRemove && addRemoveType && addRemoveCount) {
        if (addRemove === 'add') {
          return moment.utc().add(addRemoveCount, addRemoveType).format(format)
        } else if (addRemove === 'remove') {
          return moment.utc().subtract(addRemoveCount, addRemoveType).format(format)
        }
      } else {
        return moment.utc().format(format)
      }
    },
    combineAddress(street, number, letter, floor, door, postalCode, city) {
      const numberData = Array.isArray(number) ? (number[1] !== null ? `${number[0]}-${number[1]}` : number[0] ? number[0] : '') : number
      let floorTranslation
      if (floor !== undefined && floor !== null) {
        floorTranslation = this.Dictionary.Floors[floor] !== undefined ? this.Dictionary.Floors[floor] : floor
      }
      return `${street} ${numberData}${letter || ''}${floorTranslation !== undefined ? `, ${floorTranslation}` : ''}${
        floorTranslation && door ? `${floor > 0 ? '.' : ''} ${door}` : ''
      }${postalCode ? ', ' + postalCode : ''}${city ? ' ' + city : ''}`
    },
    readImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = evt => {
          const img = new Image()
          img.onload = () => {
            resolve(img)
          }
          img.src = evt.target.result
        }

        reader.onerror = evt => {
          reject(evt)
        }

        reader.readAsDataURL(file)
      })
    },
    validCpr(val) {
      if (!val) return true
      const value = val.replace('-', '')
      if (value.length === 10) {
        if (value === '1111111111' || value === '111111') {
          return true
        } else {
          const crpObject = cpr(value)
          return crpObject.valid
          // if (this.validCpr) this.checkAge()
        }
      } else {
        return false
      }
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validPassword(password) {
      const re = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[%$#=!^&*()"?<>/\\`'_+@|:\-;~{.}[,\]]).*$/
      return re.test(password)
    },
    areArraysEqualSets(a1, a2) {
      const superSet = {}
      for (const i of a1) {
        const e = i + typeof i
        superSet[e] = 1
      }

      for (const i of a2) {
        const e = i + typeof i
        if (!superSet[e]) {
          return false
        }
        superSet[e] = 2
      }

      for (const e in superSet) {
        if (superSet[e] === 1) {
          return false
        }
      }

      return true
    },
    arraysContainsAllArrayEnties(arr, target) {
      return target.every(v => arr.includes(v))
    },
    isFile(path) {
      return /\.[^./]+$/.test(path)
    },
    isJson(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    formatDate(date, format = 'DD-MM-YYYY') {
      const formattedDate = moment.utc(date).local().format(format)
      return formattedDate
    },
    objectToFormData(obj, allowNull) {
      const formData = objToForm(obj, allowNull)
      // const object = {}
      // formData.forEach((value, key) => (object[key] = value))
      // console.log(object)
      return formData
    },
    getAnswerFromParameter(parameter) {
      let answer = ''
      if (parameter.answer) {
        switch (parameter.type) {
          case 'CheckboxList':
            answer = parameter.options
              .filter(option => parameter.answer.includes(option.id))
              .map(answer => answer.name)
              .toString()
            break
          case 'Dropdown':
          case 'RadioButtonList':
            answer =
              (parameter.options.find(option => parameter.answer === option.id) && parameter.options.find(option => parameter.answer === option.id).name) || '-'
            break
          case 'Checkbox':
            answer = this.Dictionary.Y.Yes
            break
          case 'Upload':
            answer = Array.isArray(parameter.answer) ? parameter.answer.map(answer => answer.name).toString() : parameter.answer.split(',')[1]
            break
          case 'Date':
            answer = this.formatDate(parameter.answer)
            break
          default:
            answer = parameter.answer
        }
      }
      return answer
    },
    multiselectRowSetLastSelectedRow(e) {
      if (e.target.type !== 'checkbox') {
        this.lastSelected = null
        window.removeEventListener('click', this.multiselectRowSetLastSelectedRow)
      }
    },
    multiselectRowClick(params, targetRef) {
      const isCheckbox = params.event.target.type === 'checkbox'
      const isShiftKey = params.event.shiftKey
      if (isCheckbox) {
        window.addEventListener('click', this.multiselectRowSetLastSelectedRow)
        if (this.lastSelected !== null && isShiftKey) {
          const filteredRows = this.$refs[targetRef].filteredRows[0]
          if (params.pageIndex < this.lastSelected) {
            filteredRows.children.forEach((child, index) => {
              if (index < this.lastSelected && index > params.pageIndex) {
                child.vgtSelected = !child.vgtSelected
              }
            })
          } else if (params.pageIndex > this.lastSelected) {
            filteredRows.children.forEach((child, index) => {
              if (index > this.lastSelected && index < params.pageIndex) {
                child.vgtSelected = !child.vgtSelected
              }
            })
          }
        }
        this.lastSelected = params.pageIndex
      }
    },
    getUserName(userData) {
      if (userData.fullName) return userData.fullName
      return userData.firstName && userData.firstName
        ? `${userData.firstName} ${userData.lastName}`
        : userData.email
        ? userData.email
        : userData.applicantNumber // If user data is present
        ? this.Dictionary.N.NoEmail
        : this.Dictionary.N.NoUser
    },
    getVerificationStatus(applicant, orgId) {
      const isApproved = applicant.verifiedIn && applicant.verifiedIn.includes(orgId)
      const isVerified = isApproved || (applicant.verifiedNotApprovedIn && applicant.verifiedNotApprovedIn.includes(orgId))
      return isApproved ? 'o-svg-icon--success' : isVerified ? 'o-svg-icon--error' : 'o-svg-icon--neutral'
    },
    getWaitingListPoints(applicant, orgId, propId) {
      if (applicant.points) {
        return applicant.points
      }

      const moPoints = (applicant.membershipOrganizationPoints && applicant.membershipOrganizationPoints[orgId]) || 0
      const propertyPoints = (applicant.propertyPoints && applicant.propertyPoints[propId]) || 0
      return propertyPoints || moPoints
    }
  },
  computed: {
    isMobile() {
      return isMobile && this.windowWidth < 1024
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }
})
