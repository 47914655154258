<template lang="pug">
c-tabs.c-rm-data__tabs(:start-tab='0', :sub-tabs='isAdvert')
  c-tab(:name='residenceTranslations.aboutTabName || "about"')
    .c-rm-data__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-rm-data__form-section(v-if='isAdvert')
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  c-fc-textbox(:model='mutableData.comment' @validate='validate($event, "comment")' v-model='mutableData.comment.value')
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.propertyDataSectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12(v-if='hasElevatedRights')
                  c-fc-select(:model='companyData' @validate='validate($event, "companyId")' v-model='mutableData.companyId.value')
                .o-grid__item.o-grid__item--12(v-if='hasElevatedRights')
                  c-fc-select(:model='propertyData' @validate='validate($event, "property")' v-model='mutableData.propertyId.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='!advertFromPublisher')
                  c-fc-number(:model='mutableData.companyNumber' @validate='validate($event, "companyNumber")' v-model='mutableData.companyNumber.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='!advertFromPublisher')
                  c-fc-number(:model='mutableData.propertyNumber' @validate='validate($event, "propertyNumber")' v-model='mutableData.propertyNumber.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='hasElevatedRights')
                  c-fc-number(:model='mutableData.residenceNumber' @validate='validate($event, "residenceNumber")' v-model='mutableData.residenceNumber.value')
                .o-grid__item.o-grid__item--12
                  c-fc-text(:model='mutableData.title' @validate='validate($event, "title")' v-model='mutableData.title.value')
                .o-grid__item.o-grid__item--12
                  c-fc-wysiwyg(:model='mutableData.description' @validate='validate($event, "description")' v-model='mutableData.description.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
                  c-fc-select(:model='propertyTypeData' @validate='validate($event, "propertyType")' v-model='mutableData.propertyType.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
                  c-fc-number(:model='yearData' @validate='validate($event, "year")' v-model='mutableData.year.value')
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.addressSectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  .c-rm-data__autosuggest-wrap
                    c-fc-text(
                      :model='mutableData.street'
                      @blur='handleStreetBlur'
                      @change='handleStreetChange'
                      @focus='handleStreetFocus'
                      @validate='validate($event, "street")'
                      v-debounce.keyup='500'
                      v-model='mutableData.street.value'
                    )
                    .c-rm-data__autosuggest.o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--padding-micro(
                      ref='street-typeaheadList'
                      v-if='streetAutosuggest.show && streetAutosuggest.results.length > 0'
                    )
                      .o-grid__group
                        .o-grid__item(:key='key' v-for='(autoSuggestResult, key) in streetAutosuggest.results')
                          .c-rm-data__autosuggest-item
                            button.c-rm-data__autosuggest-item-name(@click='selectStreetAutoSuggestion(autoSuggestResult)' @mousedown.prevent) {{ streetSuggestionText(autoSuggestResult) }}
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
                  c-fc-number(:model='mutableData.number' @validate='validate($event, "number")' v-model='mutableData.number.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
                  c-fc-text(:model='mutableData.letter' @validate='validate($event, "letter")' v-model='mutableData.letter.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
                  c-fc-select(:model='floorData' @validate='validate($event, "floor")' v-model='mutableData.floor.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
                  c-fc-text(:model='mutableData.door' @validate='validate($event, "door")' v-model='mutableData.door.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
                  c-fc-number(:model='mutableData.postalCode' @validate='validate($event, "postalCode")' v-model='mutableData.postalCode.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
                  c-fc-text(:model='mutableData.city' @validate='validate($event, "city")' v-model='mutableData.city.value')
                .o-grid__item.o-grid__item--6
                  c-fc-number(:model='mutableData.location.latitude' @validate='validate($event, "latitude")' v-model='mutableData.location.latitude.value')
                .o-grid__item.o-grid__item--6
                  c-fc-number(:model='mutableData.location.longitude' @validate='validate($event, "longitude")' v-model='mutableData.location.longitude.value')
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.residenceInfoSectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--6
                  c-fc-number.c-rm-data__form-input(:model='mutableData.rooms' @validate='validate($event, "rooms")' v-model='mutableData.rooms.value')
                  c-fc-number.c-rm-data__form-input(:model='mutableData.area' @validate='validate($event, "area")' v-model='mutableData.area.value')
                .o-grid__item.o-grid__item--6
                  c-fc-checklist(
                    :model='residenceTypesData'
                    @validate='validate($event, "residenceType")'
                    v-if='residenceTypesData.options.length > 0'
                    v-model='mutableData.residenceTypes.value'
                  )
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-date(:model='availableFromData' @validate='validate($event, "availableFrom")' v-model='mutableData.availableFrom.value')
                  .o-label.o-label--error(v-if='selected.managedExternally && !isCurrentTurnOwner') {{ currentTurnOwnerText }}
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-if='!isAdvert')
                  c-fc-date(:model='availableToData' @validate='validate($event, "availableTo")' v-model='mutableData.availableTo.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-if='isAdvert')
                  c-fc-select(:model='mutableData.rentalPeriod' @validate='validate($event, "rentalPeriod")' v-model='mutableData.rentalPeriod.value')
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.economySectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--6
                  c-fc-number(:model='mutableData.monthsOfPrepaid' @validate='validate($event, "monthsOfPrepaid")' v-model='mutableData.monthsOfPrepaid.value')
                .o-grid__item.o-grid__item--6
                  c-fc-number(:model='mutableData.monthsOfDeposit' @validate='validate($event, "monthsOfDeposit")' v-model='mutableData.monthsOfDeposit.value')
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4.o-heading--opensans.o-heading--500 {{ rentSectionTotalText }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-tiny.o-grid--align-items-center
              template(v-for='(rent, rentKey) in mutableData.rents')
                .o-grid__group(
                  v-if='rent.isPartOfRent.value && rent.description.value !== "MigratedPrePayment" && rent.description.value !== "MigratedDeposit"'
                )
                  .o-grid__item.o-grid__item--6
                    c-fc-text(
                      :key='rentEntryBuster',
                      :model='rent.description'
                      @validate='validate($event, `rentEntry-${rentKey}-description`)'
                      v-model='rent.description.value'
                    )
                  .o-grid__item.o-grid__item--4
                    c-fc-number(
                      :key='rentEntryBuster',
                      :model='rent.amount'
                      @validate='validate($event, `rentEntry-${rentKey}-amount`)'
                      v-model='rent.amount.value'
                    )
                  .o-grid__item.o-grid__item--2(v-if='!rent.description.isMandatory')
                    button.c-rm-data__delete.o-link.o-link--error(
                      :disabled='selected.managedExternally || (isAdvert && !advertEditable)'
                      @click='handleRentDelete(rentKey)'
                    ) {{ Dictionary.D.DeleteLine }}
                  .o-grid__item.o-grid__item--12
                    c-fc-checkbox(
                      :key='rentEntryBuster',
                      :model='rent.isNotPartOfDeposit'
                      @validate='validate($event, `rentEntry-${rentKey}-isNotPartOfDeposit`)'
                      v-model='rent.isNotPartOfDeposit.value'
                    )
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  button.o-link(:disabled='selected.managedExternally || (isAdvert && !advertEditable)' @click='handleRentAdd()') {{ Dictionary.A.AddLine }}
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4.o-heading--opensans.o-heading--500 {{ utilitiesSectionTotalText }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-tiny.o-grid--align-items-center
              template(v-for='(utility, utilityKey) in mutableData.rents')
                .o-grid__group(
                  v-if='!utility.isPartOfRent.value && utility.description.value !== "MigratedPrePayment" && utility.description.value !== "MigratedDeposit"'
                )
                  .o-grid__item.o-grid__item--6
                    c-fc-text(
                      :key='rentEntryBuster',
                      :model='utility.description'
                      @validate='validate($event, `rentEntry-${utilityKey}-description`)'
                      v-model='utility.description.value'
                    )
                  .o-grid__item.o-grid__item--4
                    c-fc-number(
                      :key='rentEntryBuster',
                      :model='utility.amount'
                      @validate='validate($event, `rentEntry-${utilityKey}-amount`)'
                      v-model='utility.amount.value'
                    )
                  .o-grid__item.o-grid__item--2
                    button.c-rm-data__delete.o-link.o-link--error(
                      :disabled='selected.managedExternally || (isAdvert && !advertEditable)'
                      @click='handleRentDelete(utilityKey)'
                    ) {{ Dictionary.D.DeleteLine }}
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  button.o-link(:disabled='selected.managedExternally || (isAdvert && !advertEditable)' @click='handleUtilitiesAdd()') {{ Dictionary.A.AddLine }}
          .c-rm-data__form-section(v-show='isAdvert && !advertFromPublisher')
            .c-rm-data__form-heading.o-heading.o-heading--size-4.o-heading--opensans.o-heading--500 {{ correctionRentSectionTotalText }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-tiny.o-grid--align-items-center
              .o-grid__group
                .o-grid__item.o-grid__item--6
                  c-fc-text(
                    :model='mutableData.correctionRentDescription'
                    @validate='validate($event, "correctionRentDescription")'
                    v-model='mutableData.correctionRentDescription.value'
                  )
                .o-grid__item.o-grid__item--4
                  c-fc-number(
                    :model='mutableData.correctionRentAmount'
                    @validate='validate($event, "correctionRentAmount")'
                    v-model='mutableData.correctionRentAmount.value'
                  )
          .c-rm-data__form-section.c-rm-data__finantial-summary
            .o-bodytext.o-bodytext--size-large {{ residenceTranslations.firstPaymentHeadline }}
            table.c-rm-data__finantial-summary-table
              tr
                td.o-bodytext--size-small {{ residenceTranslations.firstPaymentPrepaidRentText }}
                td.notranslate {{ totalPrepaid | money }}
              tr
                td.o-bodytext--size-small {{ residenceTranslations.firstPaymentDepositText }}
                td {{ totalDeposit | money }}
              tr(v-if='mutableData.correctionRentDescription.value && mutableData.correctionRentAmount.value' v-show='isAdvert && !advertFromPublisher')
                td.o-bodytext--size-small {{ mutableData.correctionRentDescription.value }}
                td.notranslate {{ mutableData.correctionRentAmount.value | money }}
              tr
                td.o-bodytext--size-small {{ residenceTranslations.firstMonthlyPaymentText }}
                td.notranslate {{ totalUtilitiesAndRent | money }}
              tr
                td(colspan='2')
                  .separator
              tr
                td.o-bodytext--size-small {{ residenceTranslations.firstPaymentTotalText }}
                td.notranslate {{ totalFirstPayment | money }}
          .c-rm-data__form-section.c-rm-data__finantial-summary(v-if='selectedResidence && selectedResidence.financialsModel.rentIncreaseWarning !== null')
            .o-bodytext.o-bodytext--size-large {{ this.Dictionary.N.Notification }}
            table.c-rm-data__finantial-summary-table
              tr.o-bodytext.o-bodytext--size-xsmall.o-bodytext--italic.disclaimer {{ selectedResidence.financialsModel.rentIncreaseWarning }}
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.contractualRelationshipSectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  c-fc-select(
                    :model='mutableData.handoverCondition'
                    @validate='validate($event, "handoverCondition")'
                    v-model='mutableData.handoverCondition.value'
                  )
                .o-grid__item.o-grid__item--12
                  c-fc-select(:model='mutableData.renovationType' @validate='validate($event, "renovationType")' v-model='mutableData.renovationType.value')
                .o-grid__item.o-grid__item--12
                  c-fc-select(:model='mutableData.rentalCondition' @validate='validate($event, "rentalCondition")' v-model='mutableData.rentalCondition.value')
                .o-grid__item.o-grid__item--12
                  c-fc-select(:model='mutableData.furnished' @validate='validate($event, "furnished")' v-model='mutableData.furnished.value')
          .c-rm-data__form-section
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.facilitiesSectionHeadline }}
                  c-fc-checklist(
                    :model='facilitiesData'
                    @validate='validate($event, "facilities")'
                    v-if='facilitiesData.options.length > 0'
                    v-model='mutableData.facilities.value'
                  )
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-show='isAdvert')
                  .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.propertyFacilitiesSectionHeadline }}
                  c-fc-checklist(
                    :model='propertyFacilitiesData'
                    @validate='validate($event, "propertyFacilities")'
                    v-if='propertyFacilitiesData.options.length > 0'
                    v-model='mutableData.propertyFacilities.value'
                  )
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(v-show='isAdvert')
                  .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.propertyEnergyRankSectionHeadline }}
                  c-fc-radiolist(
                    :model='mutableData.energyAssessment'
                    @validate='validate($event, "energyAssessment")'
                    v-model='mutableData.energyAssessment.value'
                  )
          .c-rm-data__form-section
            .c-rm-data__form-heading.o-heading.o-heading--size-4 {{ residenceTranslations.settingsSectionHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12(v-if='!isAdvert')
                  c-fc-checkbox(
                    :model='mutableData.excludeFromSearch'
                    @validate='validate($event, "excludeFromSearch")'
                    v-model='mutableData.excludeFromSearch.value'
                  )
                template(v-if='isAdvert && selected.companyRentModel === "MemberAdvert"')
                  .o-grid__item.o-grid__item--12
                    c-fc-radiolist(:model='mutableData.type' @validate='validate($event, "type")' v-model='mutableData.type.value')
                  .o-grid__item.o-grid__item--6(v-if='mutableData.type.value === "MembershipAdvertBasedOnResidence"')
                    c-fc-date(
                      :key='applyBeforeDateBuster',
                      :model='applyBeforeDateData'
                      @validate='validate($event, "applyBeforeDate")'
                      v-model='mutableData.applyBeforeDate.value'
                    )
              .o-grid__group(v-if='isAdvert')
                .o-grid__item.o-grid__item--6
                  c-fc-number(
                    :model='mutableData.maxNumberOfApplicants'
                    @validate='validate($event, "maxNumberOfApplicants")'
                    v-model='mutableData.maxNumberOfApplicants.value'
                  )
        .o-grid__item.o-grid__item--desktop-4
          slot(name='side-panel')
    slot(name='footer')
      .c-rm-data__form.c-rm-data__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
        .o-grid__group
          .o-grid__item.o-grid__item--12.c-rm-data__form-section--border-top(v-if='selected.id && !selected.managedExternally && !isAdvert')
            button.c-rm-data__delete.o-link.o-link--error(@click='handleDelete') {{ residenceTranslations.deleteResidenceText }}
          .o-grid__item.o-grid__item--12.c-rm-data__form-section--border-top
            .o-grid.o-grid--gutter-small.o-grid--align-items-center
              .o-grid__group
                .o-grid__item.o-grid__item--grow-0
                  button.o-link.c-rm-data__cancel(@click='close') {{ Dictionary.C.Cancel }}
                .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                  button.o-button.c-rm-data__submit(
                    :disabled='!formIsValid || loading || (!selected.advertFromResidence && !dataChanged)'
                    @click='submitChanges(false)'
                  ) {{ Dictionary.S.Save }}
                  button.o-button.c-rm-data__submit(
                    :disabled='!formIsValid || loading || (!selected.advertFromResidence && !dataChanged)'
                    @click='submitChanges(true)'
                  ) {{ Dictionary.S.SaveAndClose }}
    .o-grid.o-grid--gutter-normal.o-grid--no-overflow(v-if='!formIsValid')
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
          .o-label.o-label--error {{ Dictionary.P.PleaseFillInMandatoryFields }}
  c-tab(
    :disabled='!selected.id || selected.type > 1',
    :disabled-message='residenceTranslations.MediaDisableReason',
    :name='residenceTranslations.mediaTabName || "media"'
    v-if='showMedia'
  )
    .o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          c-media-upload(:media='selected.media', :state-management='mediaStateManagement', :translations='model.mediaTranslations || model.media')
  slot(name='tab')
</template>
<script>
import _ from 'lodash'

import { mapState } from 'vuex'
import FormControllChecklist from '@/components/form/controlls/form-checklist'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllRadioList from '@/components/form/controlls/form-radiolist'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllWysiwyg from '@/components/form/controlls/form-wysiwyg'
import MediaUpload from '@/components/media-upload'
import { propertyManagementService, residenceManagementService } from '@/_services'
import moment from 'moment'

export default {
  name: 'c-rm-data',
  props: {
    model: {
      type: Object,
      required: true
    },
    residenceTranslations: {
      type: Object,
      required: true
    },
    selected: {
      type: Object,
      required: true
    },
    isAdvert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'c-fc-checklist': FormControllChecklist,
    'c-fc-date': FormControllDate,
    'c-fc-checkbox': FormControllCheckbox,
    'c-fc-radiolist': FormControllRadioList,
    'c-fc-number': FormControllNumber,
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-textbox': FormControllTextBox,
    'c-fc-wysiwyg': FormControllWysiwyg,
    'c-media-upload': MediaUpload
  },
  data() {
    return {
      id: this._uid,
      selectedResidence: undefined,
      targetStore: this.isAdvert ? 'advertManagement' : 'residenceManagement',
      streetAutosuggest: {
        show: false,
        results: []
      },
      validation: {},
      rentEntryBuster: 0,
      addressBuster: 0,
      applyBeforeDateBuster: 0,
      companyProperties: [],
      defaultRent: {
        description: {
          helperText: '',
          value: null,
          isMandatory: false,
          placeholderText: this.residenceTranslations && this.residenceTranslations.rentEntryPlaceholderText,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        amount: {
          helperText: '',
          placeholderText: 'DKK',
          value: null,
          isMandatory: false,
          readOnly: false,
          step: '.01',
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: -99999
          }
        },
        isPartOfRent: {
          value: true,
          helperText: '',
          placeholderText: this.residenceTranslations && this.residenceTranslations.waitingListLabelText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        isNotPartOfDeposit: {
          value: false,
          helperText: '',
          placeholderText: this.residenceTranslations && this.residenceTranslations.notIncludedIntoRentDepositLabel,
          isMandatory: false,
          readOnly: false,
          validation: null
        }
      },
      mutableData: {
        comment: {
          helperText: this.residenceTranslations && this.residenceTranslations.advertCommentHelperText,
          value: null,
          placeholderText: this.Dictionary.C.Comment,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        type: {
          value: null,
          helperText: this.residenceTranslations && this.residenceTranslations.advertTypeHelperText,
          labelText: this.Dictionary.A.AdvertType,
          isMandatory: false,
          readOnly: false,
          options: [
            { value: 'AdvertBasedOnResidence', name: this.Dictionary.AdvertTypes.AdvertBasedOnResidence },
            { value: 'MembershipAdvertBasedOnResidence', name: this.Dictionary.AdvertTypes.MembershipAdvertBasedOnResidence }
          ],
          validation: null
        },
        applyBeforeDate: {
          helperText: this.residenceTranslations && this.residenceTranslations.advertApplyBeforeHelperText,
          value: null,
          placeholderText: this.Dictionary.A.ApplyBefore,
          isMandatory: false,
          readOnly: false,
          validation: null,
          key: 0
        },
        maxNumberOfApplicants: {
          helperText: this.residenceTranslations && this.residenceTranslations.advertMaxNumberOfApplicantsHelperText,
          placeholderText: this.Dictionary.M.MaxNumberOfApplicants,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        companyId: {
          helperText: this.residenceTranslations && this.residenceTranslations.ownerHelperText,
          value: null,
          placeholderText: this.Dictionary.O.Owner,
          isMandatory: true,
          readOnly: false,
          options: this.companies,
          validation: null
        },
        propertyId: {
          helperText: this.residenceTranslations && this.residenceTranslations.propertyHelperText,
          value: null,
          placeholderText: this.Dictionary.P.Property,
          isMandatory: true,
          readOnly: false,
          options: this.companyProperties,
          validation: null
        },
        companyNumber: {
          helperText: this.residenceTranslations && this.residenceTranslations.companyNumberHelperText,
          placeholderText: this.Dictionary.C.CompanyNumber,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        propertyNumber: {
          helperText: this.residenceTranslations && this.residenceTranslations.propertyNumberHelperText,
          placeholderText: this.Dictionary.P.PropertyNumber,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        residenceNumber: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceNumberHelperText,
          placeholderText: this.Dictionary.R.ResidenceNumber,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        title: {
          helperText: this.residenceTranslations && this.residenceTranslations.titleHelperText,
          placeholderText: this.Dictionary.T.Title,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        description: {
          helperText: this.residenceTranslations && this.residenceTranslations.descriptionHelperText,
          value: null,
          placeholderText: this.Dictionary.D.Description,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        street: {
          helperText: this.residenceTranslations && this.residenceTranslations.addressHelperText,
          value: null,
          placeholderText: this.Dictionary.S.Street,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 200
          },
          autocomplete: 'do-not-autofill'
        },
        number: {
          helperText: this.residenceTranslations && this.residenceTranslations.streetNumberHelperText,
          value: null,
          placeholderText: this.Dictionary.N.Number,
          isMandatory: true,
          readOnly: true,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          },
          autocomplete: 'do-not-autofill'
        },
        door: {
          helperText: this.residenceTranslations && this.residenceTranslations.sideHelperText,
          value: null,
          placeholderText: this.Dictionary.D.Door,
          isMandatory: false,
          readOnly: true,
          validation: null
        },
        floor: {
          helperText: this.residenceTranslations && this.residenceTranslations.floorHelperText,
          value: null,
          placeholderText: this.Dictionary.F.Floor,
          isMandatory: false,
          readOnly: true,
          options: [],
          validation: null
        },
        letter: {
          helperText: this.residenceTranslations && this.residenceTranslations.letterHelperText,
          value: null,
          placeholderText: this.Dictionary.L.Letter,
          isMandatory: false,
          readOnly: true,
          validation: {
            displayRule: 'A',
            mask: '[a-zA-Z]'
          }
        },
        postalCode: {
          helperText: this.residenceTranslations && this.residenceTranslations.postNumberHelperText,
          value: null,
          placeholderText: this.Dictionary.P.PostalCode,
          isMandatory: true,
          readOnly: true,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1000,
            maxValue: 9999
          }
        },
        city: {
          helperText: this.residenceTranslations && this.residenceTranslations.cityHelperText,
          value: null,
          placeholderText: this.Dictionary.C.City,
          readOnly: true,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        location: {
          latitude: {
            helperText: this.residenceTranslations && this.residenceTranslations.latitudeHelperText,
            placeholderText: this.Dictionary.L.Latitude,
            value: null,
            isMandatory: false,
            readOnly: false,
            validation: {
              errorMessage: '',
              mask: '',
              displayRule: '',
              minValue: -90,
              maxValue: 90
            }
          },
          longitude: {
            helperText: this.residenceTranslations && this.residenceTranslations.longitudeHelperText,
            placeholderText: this.Dictionary.L.Longitude,
            value: null,
            isMandatory: false,
            readOnly: false,
            validation: {
              errorMessage: '',
              mask: '',
              displayRule: '',
              minValue: -180,
              maxValue: 180
            }
          }
        },
        propertyType: {
          helperText: this.residenceTranslations && this.residenceTranslations.propertyTypeHelperText,
          value: null,
          placeholderText: this.Dictionary.P.PropertyType,
          isMandatory: false,
          readOnly: true,
          options: [],
          validation: null
        },
        year: {
          helperText: '',
          placeholderText: this.Dictionary.Y.YearOfConstruction,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 0,
            maxValue: moment.utc().add(5, 'y').format('YYYY')
          }
        },
        residenceTypes: {
          // on adverts its "residenceTypes" , on residence its "types"
          helperText: this.residenceTranslations && this.residenceTranslations.residenceTypeHelperText,
          value: null,
          labelText: this.Dictionary.R.ResidenceType,
          isMandatory: false,
          readOnly: true,
          options: [],
          validation: null
        },
        rooms: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceInfoRoomsHelperText,
          value: null,
          placeholderText: this.Dictionary.R.Rooms,
          readOnly: false,
          isMandatory: true,
          validation: {
            errorMessage: '',
            displayRule: '',
            minValue: 1,
            maxValue: 20
          }
        },
        area: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceInfoSizeHelperText,
          placeholderText: this.Dictionary.A.Area,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 1,
            maxValue: 9999
          }
        },
        availableFrom: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceInfoAvailabilityFromHelperText,
          value: null,
          placeholderText: this.Dictionary.A.AvailableFrom,
          isMandatory: false,
          readOnly: false,
          validation: {}
        },
        availableTo: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceInfoAvailabilityToHelperText,
          value: null,
          placeholderText: this.Dictionary.A.AvailableTo,
          isMandatory: false,
          readOnly: false,
          validation: {}
        },
        rentalPeriod: {
          helperText: this.residenceTranslations && this.residenceTranslations.residenceInfoRentalPeriodHelperText,
          value: null,
          placeholderText: this.Dictionary.R.RentalPeriod,
          isMandatory: false,
          readOnly: false,
          options: [
            { value: 'TwoToTwelveMonth', name: this.Dictionary.RentalPeriods.TwoToTwelveMonth },
            { value: 'OneToTwoYears', name: this.Dictionary.RentalPeriods.OneToTwoYears },
            { value: 'TwoOrMoreYears', name: this.Dictionary.RentalPeriods.TwoOrMoreYears },
            { value: 'Unlimited', name: this.Dictionary.RentalPeriods.Unlimited }
          ],
          validation: null
        },
        monthsOfPrepaid: {
          helperText: this.residenceTranslations && this.residenceTranslations.prepaidRentHelperText,
          placeholderText: `${this.Dictionary.P.PrepaidRent} (${this.Dictionary.N.NumberOfMonthsRent})`,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 3
          }
        },
        monthsOfDeposit: {
          helperText: this.residenceTranslations && this.residenceTranslations.depositHelperText,
          placeholderText: `${this.Dictionary.D.Deposit} (${this.Dictionary.N.NumberOfMonthsRent})`,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 3
          }
        },
        rents: [],
        correctionRentDescription: {
          helperText: '',
          placeholderText: this.residenceTranslations.correctionRentEntryPlaceholderText,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        correctionRentAmount: {
          helperText: '',
          placeholderText: 'DKK',
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: -99999
          }
        },
        handoverCondition: {
          helperText: this.residenceTranslations && this.residenceTranslations.takeoverStateHelperText,
          value: null,
          placeholderText: this.Dictionary.T.TakeoverState,
          isMandatory: false,
          readOnly: false,
          options: [
            {
              value: 'AsSeen',
              name: this.Dictionary.HandoverCondition.AsSeen
            },
            {
              value: 'NewlyRenovated',
              name: this.Dictionary.HandoverCondition.NewlyRenovated
            },
            {
              value: 'WellMaintained',
              name: this.Dictionary.HandoverCondition.WellMaintained
            }
          ],
          validation: null
        },
        renovationType: {
          helperText: this.residenceTranslations && this.residenceTranslations.renovationsHelperText,
          value: null,
          placeholderText: this.Dictionary.M.Modernization,
          isMandatory: false,
          readOnly: false,
          options: [
            {
              value: 'NotRenovated',
              name: this.Dictionary.RenovationTypes.NotRenovated
            },
            {
              value: 'Paragraph_5_1',
              name: this.Dictionary.RenovationTypes.Paragraph51
            },
            {
              value: 'Paragraph_5_2',
              name: this.Dictionary.RenovationTypes.Paragraph52
            },
            {
              value: 'Paragraph_62B',
              name: this.Dictionary.RenovationTypes.Paragraph62B
            },
            {
              value: 'RenovatedByCommuneAndState',
              name: this.Dictionary.RenovationTypes.RenovatedByCommuneAndState
            }
          ],
          validation: null
        },
        rentalCondition: {
          helperText: this.residenceTranslations && this.residenceTranslations.fixedRentHelperText,
          value: null,
          placeholderText: this.Dictionary.R.RentalConditions,
          isMandatory: false,
          readOnly: false,
          options: [
            {
              value: 'DeterminedByCost',
              name: this.Dictionary.RentalCondition.DeterminedByCost
            },
            {
              value: 'RentedValue',
              name: this.Dictionary.RentalCondition.RentedValue
            },
            {
              value: 'ByMarketRent',
              name: this.Dictionary.RentalCondition.ByMarketRent
            },
            {
              value: 'BalanceRent',
              name: this.Dictionary.RentalCondition.BalanceRent
            },
            {
              value: 'YouthHousing',
              name: this.Dictionary.RentalCondition.YouthHousing
            }
          ],
          validation: null
        },
        furnished: {
          helperText: this.residenceTranslations && this.residenceTranslations.furnishedHelperText,
          value: null,
          placeholderText: this.Dictionary.F.Furnished,
          isMandatory: false,
          readOnly: false,
          options: [
            {
              value: 'Furnished',
              name: this.Dictionary.FurnishedTypes.Furnished
            },
            {
              value: 'NotFurnished',
              name: this.Dictionary.FurnishedTypes.NotFurnished
            },
            {
              value: 'PartiallyFurnished',
              name: this.Dictionary.FurnishedTypes.PartiallyFurnished
            }
          ],
          validation: null
        },
        excludeFromSearch: {
          value: false,
          helperText: this.residenceTranslations && this.residenceTranslations.excludeFromSearchHelperText,
          placeholderText: this.residenceTranslations && this.residenceTranslations.excludeFromSearchLabelText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        facilities: {
          helperText: this.residenceTranslations && this.residenceTranslations.facilitiesHelperText,
          placeholderText: 'Boligfaciliteter',
          value: null,
          readOnly: false,
          isMandatory: false,
          options: [],
          validation: null
        },
        propertyFacilities: {
          helperText: this.residenceTranslations && this.residenceTranslations.propertyFacilitiesHelperText,
          placeholderText: 'Ejendomsfaciliteter',
          value: null,
          readOnly: false,
          isMandatory: false,
          options: [],
          validation: null
        },
        energyAssessment: {
          helperText: this.residenceTranslations && this.residenceTranslations.propertyEnergyRankHelperText,
          value: null,
          placeholderText: this.Dictionary.E.EnergyMark,
          isMandatory: false,
          readOnly: false,
          options: [
            { name: 'A2020', value: 'A2020' },
            { name: 'A2015', value: 'A2015' },
            { name: 'A2010', value: 'A2010' },
            { name: 'B', value: 'B' },
            { name: 'C', value: 'C' },
            { name: 'D', value: 'D' },
            { name: 'E', value: 'E' },
            { name: 'F', value: 'F' },
            { name: 'G', value: 'G' },
            { name: this.Dictionary.N.NotSpecified, value: null }
          ],
          validation: null
        }
      }
    }
  },
  computed: {
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('user', ['roles']),
    // ...mapGetters('residenceManagement', ['submitmodels']),
    formIsValid() {
      const valid = this.validation && Object.values(this.validation).every(key => key === true)
      return valid
    },
    advertFromPublisher() {
      return this.isAdvert && (!this.selected.type || this.selected.type === 'Advert')
    },
    advertFromAdministrators() {
      return this.isAdvert && (this.selected.type === 'AdvertBasedOnResidence' || this.selected.type === 'MembershipAdvertBasedOnResidence')
    },
    isScreener() {
      return this.roles.includes('Screener')
    },
    advertEditable() {
      if (!this.isAdvert) return false
      if (!this.selected.id || this.selected.id === '00000000-0000-0000-0000-000000000000') return true
      if (this.isScreener) return this.selected.status === 'PublishedNotScreened'

      return ['Draft', 'Rejected', 'PublishedNotScreened', 'Published', 'DeadlineMet', 'Expired'].includes(this.selected.status)
    },
    showMedia() {
      return (this.advertEditable || !this.isAdvert) && this.selected.id && this.selected.id !== '00000000-0000-0000-0000-000000000000'
    },
    hasElevatedRights() {
      return !this.isAdvert || this.advertFromAdministrators
    },
    mediaStateManagement() {
      const mediaStateManagement = {
        store: this.targetStore,
        actionAdd: 'addMedia',
        actionRemove: 'removeMedia',
        actionMove: 'moveMedia'
      }

      return mediaStateManagement
    },
    selectedCompanyId() {
      return this.mutableData.companyId.value
    },
    selectedCompany() {
      return this.companies.find(c => c.id === this.mutableData.companyId.value)
    },
    selectedPropertyId() {
      return this.mutableData.propertyId.value
    },
    selectedProperty() {
      const selectedProperty = this.companyProperties.length > 0 ? this.companyProperties.find(property => property.id === this.selectedPropertyId) : undefined
      return selectedProperty
    },
    companyData() {
      const companyData = Object.assign({}, this.mutableData.companyId)
      companyData.options =
        (this.companies &&
          this.companies.map(company => {
            company.value = company.id
            return company
          })) ||
        []
      return companyData
    },
    propertyData() {
      const propertyData = Object.assign({}, this.mutableData.propertyId)
      propertyData.options =
        (this.companyProperties &&
          this.companyProperties.map(property => {
            property.value = property.id
            return property
          })) ||
        []
      return propertyData
    },
    floorData() {
      const floorData = Object.assign({}, this.mutableData.floor)
      floorData.options.push({
        value: '',
        name: this.Dictionary.N.NotSpecified
      })
      let i
      for (i = -3; i < 30; i++) {
        floorData.options.push({
          value: i,
          name: i === 0 ? this.Dictionary.Floors['0'] : `${i}`
        })
      }
      floorData.isMandatory = this.mutableData.door.value

      return floorData
    },
    availableToData() {
      const availableToData = Object.assign({}, this.mutableData.availableTo)
      availableToData.validation.minDate = this.mutableData.availableFrom.value
      return availableToData
    },
    availableFromData() {
      const availableFromData = Object.assign({}, this.mutableData.availableFrom)
      availableFromData.validation.maxDate = this.mutableData.availableTo.value
      return availableFromData
    },
    applyBeforeDateData() {
      const applyBeforeDateData = Object.assign({}, this.mutableData.applyBeforeDate)
      if (this.mutableData.type.value === 'MembershipAdvertBasedOnResidence') {
        applyBeforeDateData.isMandatory = true
      } else {
        applyBeforeDateData.readOnly = true
        applyBeforeDateData.isMandatory = false
      }
      return applyBeforeDateData
    },
    advertTypeValue() {
      return this.mutableData.type.value
    },
    propertyTypeData() {
      const propertyTypeData = Object.assign({}, this.mutableData.propertyType)
      propertyTypeData.isMandatory = this.isAdvert
      propertyTypeData.options = this.propertyTypes.map(type => {
        type.value = type.id
        return type
      })
      return propertyTypeData
    },
    yearData() {
      const yearData = Object.assign({}, this.mutableData.year)
      return yearData
    },
    residenceTypesData() {
      const residenceTypesData = Object.assign({}, this.mutableData.residenceTypes)
      residenceTypesData.options = this.residenceTypes.map(type => {
        return { value: type.id, name: type.name }
      })
      return residenceTypesData
    },
    rentSectionTotalText() {
      return (
        this.residenceTranslations &&
        this.residenceTranslations.rentSectionTotalText.replace('{0}', this.totalRentPerMonth > 0 ? this.$options.filters.money(this.totalRentPerMonth) : '')
      )
    },
    utilitiesSectionTotalText() {
      return (
        this.residenceTranslations &&
        this.residenceTranslations.utilitiesSectionTotalText.replace(
          '{0}',
          this.totalUtilitiesPerMonth > 0 ? this.$options.filters.money(this.totalUtilitiesPerMonth) : ''
        )
      )
    },
    correctionRentSectionTotalText() {
      return (
        this.residenceTranslations &&
        this.residenceTranslations.correctionRentSectionTotalText &&
        this.residenceTranslations.correctionRentSectionTotalText.replace(
          '{0}',
          this.mutableData.correctionRentAmount.value ? this.$options.filters.money(this.mutableData.correctionRentAmount.value) : ''
        )
      )
    },
    residenceFacilities() {
      const residenceFacilities = this.facilities.filter(facility => {
        const location = facility.location || facility.facilityLocationId
        if (location === '55909e38-b10f-4126-8cc8-526268bebd54') {
          facility.value = facility.id
          return facility
        }
        return false
      })

      return residenceFacilities || []
    },
    facilitiesData() {
      const facilitiesData = this.mutableData.facilities
      facilitiesData.options = this.residenceFacilities
      return facilitiesData
    },
    propertyFacilities() {
      const propertyFacilities = this.facilities.filter(facility => {
        const location = facility.location || facility.facilityLocationId
        if (location === 'a39cc085-3043-48fd-a16a-7646fda3ec1a') {
          facility.value = facility.id
          return facility
        }
        return false
      })

      return propertyFacilities || []
    },
    propertyFacilitiesData() {
      const facilitiesData = this.mutableData.propertyFacilities
      facilitiesData.options = this.propertyFacilities
      return facilitiesData
    },
    combinedSelectedFacilities() {
      const set = new Set([...this.mutableData.facilities.value, ...this.mutableData.propertyFacilities.value])
      const result = [...set]
      return result.sort()
    },
    addressData() {
      const addressData = {
        street: this.mutableData.street.value,
        streetNumber: this.mutableData.number.value,
        floor: this.mutableData.floor.value,
        houseLetter: this.mutableData.letter.value,
        postalCode: this.mutableData.postalCode.value,
        longitude: this.mutableData.location.longitude,
        latitude: this.mutableData.location.latitude
      }
      return addressData
    },
    totalRentPerMonth() {
      let totalRentPerMonth = 0
      this.mutableData.rents.forEach(rent => {
        if (rent.description.value === 'MigratedPrePayment' || rent.description.value === 'MigratedDeposit') return
        if (rent.isPartOfRent.value) {
          totalRentPerMonth = totalRentPerMonth + parseFloat(rent.amount.value)
        }
      })
      return totalRentPerMonth
    },
    totalUtilitiesPerMonth() {
      let totalRentPerMonth = 0
      this.mutableData.rents.forEach(rent => {
        if (rent.description.value === 'MigratedPrePayment' || rent.description.value === 'MigratedDeposit') return
        if (!rent.isPartOfRent.value) {
          totalRentPerMonth = totalRentPerMonth + parseFloat(rent.amount.value)
        }
      })
      return totalRentPerMonth
    },
    depositPerMonth() {
      let totalNotIncludedDeposit = 0
      this.mutableData.rents.forEach(rent => {
        if (rent.isPartOfRent.value) {
          totalNotIncludedDeposit = totalNotIncludedDeposit + parseFloat(rent.amount.value)
        }
      })
      return totalNotIncludedDeposit
    },
    totalPrepaid() {
      const migratedPrePayment = this.selected.rents && this.selected.rents.find(r => r.description === 'MigratedPrePayment')
      const totalPrepaid = this.mutableData.monthsOfPrepaid.value ? this.mutableData.monthsOfPrepaid.value * this.depositPerMonth : 0
      return (migratedPrePayment && migratedPrePayment.amount.Amount) || totalPrepaid
    },
    totalDeposit() {
      const migratedDeposit = this.selected.rents && this.selected.rents.find(r => r.description === 'MigratedDeposit')
      const totalDeposit = this.mutableData.monthsOfDeposit.value ? this.mutableData.monthsOfDeposit.value * this.depositPerMonth : 0
      return (migratedDeposit && migratedDeposit.amount.Amount) || totalDeposit
    },
    totalUtilitiesAndRent() {
      let notIncludedInFirstPayment = 0

      this.mutableData.rents.forEach(rent => {
        if (rent.isNotPartOfDeposit.value) {
          notIncludedInFirstPayment = notIncludedInFirstPayment + parseFloat(rent.amount.value)
        }
      })
      return this.totalUtilitiesPerMonth + this.totalRentPerMonth - notIncludedInFirstPayment
    },
    totalFirstPayment() {
      return this.totalPrepaid + this.totalDeposit + this.totalUtilitiesAndRent + (this.mutableData.correctionRentAmount.value || 0)
    },
    selectedResidenceTypes() {
      return this.selected.residenceTypes || this.selected.types
    },
    dataChanged() {
      let dataChanged = false
      // console.clear()
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case 'facilities':
            if (
              (this.mutableData[key] && this.mutableData[key].value === null) ||
              (this.selected[key] === undefined && this.mutableData[key].value.length === 0) ||
              (this.combinedSelectedFacilities.every(v => this.selected[key].includes(v)) &&
                this.selected[key].every(v => this.combinedSelectedFacilities.includes(v)))
            ) {
              different = false
            } else {
              different = true
            }
            break
          case 'residenceTypes':
            if (
              !this.mutableData[key].value ||
              (this.mutableData[key].value.length === 0 && this.selectedResidenceTypes.length === 0) || // none selected
              (this.mutableData[key].value.every(v => this.selectedResidenceTypes.includes(v)) &&
                this.selectedResidenceTypes.every(v => this.mutableData[key].value.includes(v)))
            ) {
              different = false
            } else {
              different = true
            }
            break
          case 'floor':
            different =
              !this.mutableData[key].readOnly &&
              this.mutableData[key].value !== null &&
              ((this.mutableData[key].value === '' && this.selected[key] !== null) ||
                (this.mutableData[key].value !== '' && parseInt(this.mutableData[key].value) !== this.selected[key]))
            break
          case 'handoverCondition':
          case 'renovationType':
          case 'rentalCondition':
          case 'furnished':
            different = this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
            break
          case 'rentalPeriod':
          case 'year':
            different = !this.mutableData[key].readOnly && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
            break
          case 'availableFrom':
          case 'availableTo':
          case 'applyBeforeDate':
            different =
              !this.mutableData[key].readOnly &&
              !(_.isEmpty(this.mutableData[key].value) && _.isEmpty(this.selected[key])) &&
              this.mutableData[key].value !== this.formatDate(this.selected[key], 'YYYY-MM-DD')
            break
          case 'rents':
            different =
              (this.mutableData[key].filter(r => r.amount.value) &&
                this.mutableData[key].filter(r => r.amount.value).length !== (this.selected[key] && this.selected[key].length)) ||
              this.mutableData[key]
                .filter(r => r.amount.value)
                .find((rent, index) => {
                  const rentDescriptionDifferent = rent.description.value !== this.selected[key][index].description
                  const rentValueDifferent = rent.amount.value !== this.selected[key][index].amount.Amount
                  const rentIsPartOfRentDifferent = rent.isPartOfRent.value !== this.selected[key][index].isPartOfRent
                  const rentisNotPartOfDepositDifferent = !rent.isNotPartOfDeposit.value !== this.selected[key][index].isPartOfDeposit
                  // if (rentDescriptionDifferent || rentValueDifferent || rentIsPartOfRentDifferent || rentisNotPartOfDepositDifferent) {
                  //   console.log(rentDescriptionDifferent, rentValueDifferent, rentIsPartOfRentDifferent, rentisNotPartOfDepositDifferent)
                  // }
                  return !!(rentDescriptionDifferent || rentValueDifferent || rentIsPartOfRentDifferent || rentisNotPartOfDepositDifferent)
                }) ||
              false
            break
          case 'location':
            different =
              this.mutableData[key].latitude.value !== (this.selected[key] && this.selected[key].latitude) ||
              this.mutableData[key].longitude.value !== (this.selected[key] && this.selected[key].longitude)
            break
          case 'city':
          case 'commune':
          case 'companyId':
          case 'propertyFacilities':
            break
          case 'propertyType':
          case 'companyNumber':
          case 'propertyNumber':
            if (this.isAdvert) {
              different =
                this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
            }
            break
          case 'maxNumberOfApplicants':
            if (!this.mutableData[key].value && !this.selected[key] && this.mutableData[key].value !== 0) {
              different = false
            } else {
              different = this.mutableData[key].value !== this.selected[key]
            }
            break
          case 'excludeFromSearch':
            if (!this.isAdvert) {
              different =
                this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
            }
            break
          case 'correctionRentAmount':
            different =
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== (this.selected[key] && this.selected[key].Amount)
            break
          case 'correctionRentDescription':
            different = _.isEmpty(this.mutableData[key].value) ? !_.isEmpty(this.selected[key]) : this.mutableData[key].value !== this.selected[key]
            break
          default:
            different =
              !this.mutableData[key].readOnly &&
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== this.selected[key]
        }
        // if (different) {
        //   console.log(key, this.mutableData[key].value, this.selected[key])
        // }

        return different
      })

      dataChanged = !!target
      return dataChanged
    },
    selectedType() {
      return this.selected.type
    },
    isCurrentTurnOwner() {
      const isCurrentTurnOwner = !!this.selected.nextOwnerTurn && this.selected.nextOwnerTurn.companyId === this.selected.companyId
      return isCurrentTurnOwner
    },
    currentTurnOwnerText() {
      const currentTurnOwnerText =
        this.selected.managedExternally &&
        this.residenceTranslations &&
        this.residenceTranslations.residenceInfoCurrentTurnOwnerText &&
        !!this.selected.nextOwnerTurn &&
        this.residenceTranslations.residenceInfoCurrentTurnOwnerText.replace('{0}', this.selected.nextOwnerTurn.name)
      return currentTurnOwnerText
    }
  },
  methods: {
    handleDelete() {
      this.$store.dispatch(`${this.targetStore}/toggleDeleteConfirmation`, this.selected.id)
    },
    handleRentDelete(rentkey) {
      Object.keys(this.validation).forEach(key => {
        if (key.includes('rentEntry-')) delete this.validation[key]
      })
      this.mutableData.rents.splice(rentkey, 1)
      this.rentEntryBuster++
    },
    handleRentAdd(isFirstRent = false) {
      const defaultRent = JSON.parse(JSON.stringify(this.defaultRent))
      defaultRent.description.placeholderText = this.residenceTranslations && this.residenceTranslations.rentEntryPlaceholderText
      defaultRent.isNotPartOfDeposit.placeholderText = this.residenceTranslations && this.residenceTranslations.notIncludedIntoRentDepositLabel
      if (isFirstRent) {
        defaultRent.description.isMandatory = true
        defaultRent.amount.isMandatory = true
      }
      this.mutableData.rents.push(defaultRent)
    },
    handleUtilitiesAdd() {
      const defaultUtility = JSON.parse(JSON.stringify(this.defaultRent))
      defaultUtility.description.placeholderText = this.residenceTranslations && this.residenceTranslations.utilitiesEntryPlaceholderText
      defaultUtility.isPartOfRent.value = false
      defaultUtility.isNotPartOfDeposit.value = false
      this.mutableData.rents.push(defaultUtility)
    },
    close() {
      this.$store.dispatch(`${this.targetStore}/removeSelected`)
    },
    submitChanges(closeSelection) {
      if (this.selected.id && !this.selected.advertFromResidence) {
        this.$store.dispatch(`${this.targetStore}/patch${this.isAdvert ? 'Advert' : 'Residence'}`, { data: this.getResidenceData(), closeSelection })
      } else {
        this.$store.dispatch(`${this.targetStore}/create${this.isAdvert ? 'Advert' : 'Residence'}`, { data: this.getResidenceData(), closeSelection })
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleStreetFocus() {
      this.streetAutosuggest.show = true
      // this.getAddressSuggestions(this.mutableData.street.value)
    },
    handleStreetChange: _.debounce(function () {
      if (this.mutableData.street.value) {
        this.getAddressSuggestions(this.mutableData.street.value)
      }
    }, 250),
    handleStreetBlur() {
      setTimeout(() => {
        this.streetAutosuggest.show = false
      }, 100)
    },
    streetSuggestionText(suggestion) {
      const text = this.combineAddress(
        suggestion.street,
        suggestion.streetNumber,
        suggestion.letter,
        suggestion.floor,
        suggestion.door,
        suggestion.postalCode,
        suggestion.postalName
      )
      const streetSuggestionText = suggestion.street ? text : suggestion.suggestion

      return streetSuggestionText
    },
    async getAddressSuggestions(address) {
      try {
        const response = await propertyManagementService.getAddressSuggestions(address)
        this.streetAutosuggest.results = response.data
      } catch (error) {
        // console.log(error)
      }
    },
    async getResidence() {
      try {
        const response = await residenceManagementService.getResidencePageModel(this.selected.residenceId)

        if (response && response.status === 200) {
          this.selectedResidence = response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    selectStreetAutoSuggestion(suggestion) {
      if (suggestion.street) {
        this.mutableData.street.value = suggestion.street
        if (suggestion.streetNumber) {
          const streetLetter = suggestion.streetNumber.match(/[a-zA-Z]+|[0-9]+/g)
          this.mutableData.number.value = streetLetter[0] || null
          this.mutableData.letter.value = streetLetter[1] || null
        }
        if (suggestion.floor) {
          this.mutableData.floor.value = suggestion.floor === 'st' ? '0' : suggestion.floor
        } else {
          this.mutableData.floor.value = this.Dictionary.N.NotSpecified
        }
        if (suggestion.door) {
          this.mutableData.door.value = suggestion.door
        } else {
          this.mutableData.door.value = null
        }
        if (suggestion.postalCode) {
          this.mutableData.postalCode.value = suggestion.postalCode
        } else {
          this.mutableData.postalCode.value = null
        }
        if (suggestion.latitude) {
          this.mutableData.location.latitude.value = suggestion.latitude
        } else {
          this.mutableData.location.latitude.value = null
        }
        if (suggestion.longitude) {
          this.mutableData.location.longitude.value = suggestion.longitude
        } else {
          this.mutableData.location.longitude.value = null
        }
        this.streetAutosuggest.show = false
        document.activeElement.blur()
      } else {
        // console.log(suggestion)
        this.mutableData.street.value = suggestion.suggestion
        this.getAddressSuggestions(this.mutableData.street.value)
      }
    },
    getPrefilledData: _.debounce(async function () {
      if (this.addressData.postalCode && this.addressData.postalCode >= 1000 && this.addressData.street && this.addressData.streetNumber) {
        try {
          const response = await propertyManagementService.getAddresses({
            postalCode: this.addressData.postalCode,
            streetName: this.addressData.street,
            streetNumberFrom: this.addressData.houseLetter ? `${this.addressData.streetNumber}${this.addressData.houseLetter}` : this.addressData.streetNumber,
            streetNumberTo: this.addressData.houseLetter ? `${this.addressData.streetNumber}${this.addressData.houseLetter}` : this.addressData.streetNumber
          })
          if (response && response.status === 200) {
            this.mutableData.city.value = response.data.city
          }
        } catch (error) {
          // console.log(error)
        }
      }
    }, 250),
    getResidenceData() {
      const residenceData = {}
      Object.keys(this.mutableData)
        .filter(key => !this.mutableData[key].readOnly || ['number', 'letter', 'floor', 'door', 'postalCode'].includes(key))
        .forEach(key => {
          switch (key) {
            case 'rents':
              if (this.getResidenceDataRentsIsWritable(this.mutableData[key])) {
                residenceData[key] = this.getResidenceDataRents(this.mutableData[key])
              }
              break
            case 'correctionRentAmount':
            case 'correctionRentDescription':
              if (this.isAdvert) {
                if (this.mutableData.correctionRentAmount.value && this.mutableData.correctionRentDescription.value) {
                  residenceData.correctionRentAmount = { Amount: this.mutableData[key].value, isoCode: 208 }
                  residenceData.correctionRentDescription = this.mutableData.correctionRentDescription.value
                } else {
                  residenceData.correctionRentAmount = null
                  residenceData.correctionRentDescription = null
                }
              }
              break
            case 'location':
              if (!this.mutableData[key].latitude || !this.mutableData[key].longitude.value) return
              residenceData[key] = {}
              residenceData[key].latitude = this.mutableData[key].latitude.value
              residenceData[key].longitude = this.mutableData[key].longitude.value
              break
            case 'number':
            case 'floor':
            case 'postalCode':
              if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
                residenceData[key] = this.mutableData[key].value === '' ? null : parseInt(this.mutableData[key].value)
              }
              break
            case 'letter':
            case 'door':
              if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
                residenceData[key] = this.mutableData[key].value
              }
              break
            case 'residenceTypes':
              if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
                if (this.isAdvert) {
                  residenceData[key] = this.mutableData[key].value
                } else {
                  residenceData.types = this.mutableData[key].value
                }
              }
              break
            case 'commune':
            case 'city':
            case 'companyId':
            case 'propertyFacilities':
            case 'companyNumber':
            case 'propertyNumber':
              break
            case 'propertyType':
            case 'year':
            case 'type':
            case 'rentalPeriod':
            case 'energyAssessment':
              if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
                if (this.isAdvert) {
                  residenceData[key] = this.mutableData[key].value
                }
              }
              break
            case 'maxNumberOfApplicants':
              if (this.isAdvert) {
                residenceData[key] = this.mutableData[key].value === '' ? null : this.mutableData[key].value
              }
              break
            case 'applyBeforeDate':
              if (this.isAdvert && this.mutableData.type.value === 'MembershipAdvertBasedOnResidence') {
                residenceData[key] = this.mutableData[key].value
              }
              break
            case 'availableFrom':
            case 'monthsOfPrepaid':
            case 'monthsOfDeposit':
              residenceData[key] = this.mutableData[key].value === '' ? null : this.mutableData[key].value
              break
            case 'facilities':
              residenceData[key] = this.combinedSelectedFacilities
              break
            case 'propertyId':
              if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
                residenceData[key] = this.mutableData[key].value
              }
              break
            default:
              if (
                !this.mutableData[key].readOnly &&
                this.mutableData[key].value !== undefined &&
                this.mutableData[key].value !== null &&
                !(Array.isArray(this.mutableData[key].value) && this.mutableData[key].value.length === 0)
              ) {
                residenceData[key] = this.mutableData[key].value
              }
          }
        })
      return residenceData
    },
    getTotalFirstPayment() {
      return this.totalFirstPayment
    },
    getResidenceDataRentsIsWritable(obj) {
      const writableRents = obj.filter(r => {
        const rentKeys = Object.keys(r)
        const writableRentKeys = rentKeys.filter(p => {
          return !r[p].readOnly
        })
        return writableRentKeys.length > 0
      })
      return writableRents.length > 0
    },
    getResidenceDataRents(obj) {
      return obj
        .filter(r => r.amount.value && !r.readOnly)
        .map(rent => {
          return {
            amount: {
              Amount: rent.amount.value,
              isoCode: 208
            },
            description: rent.description.value,
            isPartOfDeposit: rent.isPartOfRent.value ? !rent.isNotPartOfDeposit.value : rent.isNotPartOfDeposit.value,
            isPartOfRent: !!rent.isPartOfRent.value
          }
        })
    },
    setSelected() {
      Object.keys(this.mutableData).forEach(key => {
        if (
          (this.selected[key] !== undefined && this.selected[key] !== null) ||
          key === 'maxNumberOfApplicants' ||
          key === 'propertyFacilities' ||
          key === 'residenceTypes' ||
          key === 'correctionRentAmount' ||
          key === 'correctionRentDescription'
        ) {
          switch (key) {
            case 'facilities':
              this.mutableData[key].value =
                (this.selected.facilities &&
                  Object.assign(
                    [],
                    this.selected.facilities.filter(v => this.residenceFacilities.filter(f => f.id === v).length > 0)
                  )) ||
                null
              break
            case 'propertyFacilities':
              this.mutableData[key].value =
                (this.selected.facilities &&
                  Object.assign(
                    [],
                    this.selected.facilities.filter(v => this.propertyFacilities.filter(f => f.id === v).length > 0)
                  )) ||
                null
              break
            case 'residenceTypes':
              if (this.selected[key]) {
                this.mutableData[key].value = Object.assign([], this.selected[key])
              } else if (this.selected.types && this.selected.types.length > 0) {
                this.mutableData[key].value = Object.assign([], this.selected.types)
              }
              break
            case 'floor':
            case 'handoverCondition':
            case 'renovationType':
            case 'rentalCondition':
            case 'furnished':
              this.mutableData[key].value = this.selected[key].toString()
              break
            case 'availableFrom':
            case 'availableTo':
            case 'applyBeforeDate':
              this.mutableData[key].value = this.formatDate(this.selected[key], 'YYYY-MM-DD')
              break
            case 'rents':
              this.mutableData[key] = this.selected[key].map(rent => {
                const rentData = JSON.parse(JSON.stringify(this.defaultRent))
                rentData.amount.value = rent.amount.Amount
                rentData.description.value = rent.description
                rentData.isNotPartOfDeposit.value = !rent.isPartOfDeposit
                rentData.isPartOfRent.value = rent.isPartOfRent
                rentData.isNotPartOfDeposit.placeholderText = this.residenceTranslations && this.residenceTranslations.notIncludedIntoRentDepositLabel
                if (!rent.isPartOfRent) {
                  rentData.description.placeholderText = this.residenceTranslations && this.residenceTranslations.utilitiesEntryPlaceholderText
                } else {
                  rentData.description.placeholderText = this.residenceTranslations && this.residenceTranslations.rentEntryPlaceholderText
                }
                return rentData
              })
              break
            case 'maxNumberOfApplicants':
              if (this.isAdvert) {
                this.mutableData[key].value = this.selected.id
                  ? this.selected[key]
                  : this.model.advertsSettings && this.model.advertsSettings.maxNumberOfApplicants
              }
              break
            case 'correctionRentAmount':
              this.mutableData[key].value = (this.selected[key] && this.selected[key].Amount) || null
              break
            case 'correctionRentDescription':
              this.mutableData[key].value = this.selected[key] || null
              break
            case 'location':
              this.mutableData[key].latitude.value = this.selected[key].latitude
              this.mutableData[key].longitude.value = this.selected[key].longitude
              break
            default:
              this.mutableData[key].value = this.selected[key]
          }
          // console.log(key, this.mutableData[key].value, this.selected[key])
        }
      })

      if (!this.selected.rents || !this.selected.rents.some(r => r.isPartOfRent)) {
        this.handleRentAdd(true)
      } else {
        const firstRent = this.mutableData.rents.find(r => r.isPartOfRent.value)
        firstRent.description.isMandatory = true
        firstRent.amount.isMandatory = true
      }
      if (!this.selected.rents || !this.selected.rents.some(r => !r.isPartOfRent)) {
        this.handleUtilitiesAdd()
      }

      this.setReadOnly()
      this.mutableMedia = JSON.parse(JSON.stringify(this.selected.media))
    },
    setReadOnly() {
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'title':
          case 'description':
          case 'maxNumberOfApplicants':
          case 'rentalPeriod':
          case 'correctionRentDescription':
          case 'correctionRentAmount':
          case 'area':
          case 'rooms':
          case 'energyAssessment':
          case 'applyBeforeDate':
          case 'excludeFromSearch':
          case 'propertyType':
          case 'residenceTypes':
            this.mutableData[key].readOnly = this.isAdvert && !this.advertEditable
            break
          case 'year':
            this.mutableData[key].readOnly = (this.isAdvert && !this.advertEditable) || !this.isAdvert
            break
          case 'comment':
            this.mutableData[key].readOnly = false
            break
          case 'companyNumber':
          case 'propertyNumber':
            this.mutableData[key].readOnly = true
            break
          case 'residenceNumber':
            this.mutableData[key].readOnly = this.advertFromAdministrators || this.selected.managedExternally || (this.isAdvert && !this.advertEditable)
            this.mutableData[key].isMandatory = !this.isAdvert
            break
          case 'type':
            this.mutableData[key].readOnly =
              this.isAdvert &&
              !this.selected.advertFromResidence &&
              ((this.selected.type === 'AdvertBasedOnResidence' && this.selected.status !== 'Draft') ||
                (this.selected.type === 'MembershipAdvertBasedOnResidence' && ['RentedOut', 'Retired', 'RetiredFromRentedOut'].includes(this.selected.status)))
            break
          case 'street':
            this.mutableData[key].readOnly =
              this.advertFromAdministrators || (this.isAdvert && !!this.selected.id && !['Draft', 'Rejected'].includes(this.selected.status))
            break
          case 'city':
          case 'number':
          case 'door':
          case 'floor':
          case 'letter':
          case 'postalCode':
            break
          case 'companyId':
          case 'propertyId':
            this.mutableData[key].readOnly =
              this.advertFromAdministrators ||
              !!this.selected.managedExternally ||
              !!this.selected.createNewInProperty ||
              (this.isAdvert && !this.advertEditable)
            break
          case 'location':
            Object.keys(this.mutableData[key]).forEach(subKey => {
              this.mutableData[key][subKey].readOnly = this.isAdvert && !this.advertEditable
            })
            break
          case 'availableFrom':
            this.mutableData[key].readOnly = (this.selected.managedExternally && !this.isCurrentTurnOwner) || (this.isAdvert && !this.advertEditable)
            break
          case 'rents':
            this.mutableData[key].forEach(rent => {
              Object.keys(rent).forEach(subKey => {
                rent[subKey].readOnly = this.selected.managedExternally || (this.isAdvert && !this.advertEditable)
              })
            })
            break
          default:
            this.mutableData[key].readOnly = this.selected.managedExternally || (this.isAdvert && !this.advertEditable)
        }
      })
    },
    async getCompanyProperties() {
      let resetReadOnly = false
      if (!this.mutableData.propertyId.readOnly) {
        this.mutableData.propertyId.readOnly = true
        resetReadOnly = true
      }
      if (this.isAdvert && this.advertFromAdministrators) {
        this.companyProperties = [
          {
            id: this.selected.propertyId,
            name: this.selected.propertyName,
            year: this.selected.year,
            postalCode: this.selected.postalCode,
            street: this.selected.street,
            streetNumberFrom: this.selected.number,
            type: this.selected.propertyType
          }
        ]
        return
      }
      try {
        const response = await propertyManagementService.searchManagedProperties({
          filters: {
            companyId: this.selectedCompanyId
          },
          pageSize: 10000
        })
        this.companyProperties = response.data.results
        this.fillDataFromSelectedProperty()
        if (resetReadOnly) {
          this.mutableData.propertyId.readOnly = false
        }
      } catch (error) {
        // console.log(error)
      }
    },
    fillDataFromSelectedProperty() {
      if (this.selectedProperty) {
        if (!this.mutableData.street.value) this.mutableData.street.value = this.selectedProperty.street
        if (!this.mutableData.number.value) this.mutableData.number.value = this.selectedProperty.streetNumberFrom

        // if (selectedProperty.location) {
        //   this.mutableData.location.latitude.value = selectedProperty.location.latitude
        //   this.mutableDatalocation.longitude.value = selectedProperty.location.longitude
        // }
        this.mutableData.year.value = this.selectedProperty.year
        this.mutableData.propertyType.value = this.selectedProperty.type
        this.mutableData.postalCode.value = this.selectedProperty.postalCode
        this.mutableData.companyNumber.value =
          (this.selectedProperty.companyPropertyNumbersId && this.selectedProperty.companyPropertyNumbersId.split('-')[0]) || this.selected.companyNumber
        this.mutableData.propertyNumber.value = this.selectedProperty.number || this.selected.propertyNumber
      } else {
        this.mutableData.propertyId.value = null
      }
    }
  },
  created() {
    this.setSelected()
    this.$emit('validation', this.formIsValid)
    this.$emit('datachanged', this.dataChanged)
    if (this.isAdvert && this.hasElevatedRights) {
      this.getResidence()
    }
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.setSelected()
      }
    },
    addressData() {
      this.getPrefilledData()
    },
    selectedCompanyId() {
      this.getCompanyProperties()
    },
    selectedPropertyId() {
      if (this.companyProperties && this.companyProperties.length) {
        this.fillDataFromSelectedProperty()
      }
    },
    formIsValid(newVal) {
      this.$emit('validation', newVal)
    },
    dataChanged(newVal) {
      this.$emit('datachanged', newVal)
    },
    advertTypeValue() {
      this.applyBeforeDateBuster++
    },
    advertEditable() {
      this.setReadOnly()
    },
    selectedType() {
      this.setReadOnly()
    },
    isCurrentTurnOwner() {
      this.setReadOnly()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-rm-data {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
    min-height: 100%;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__finantial-summary {
    background-color: $grey-5;
    padding: 15px 20px;
    &-table {
      width: 100%;
      .separator {
        padding: 0;
        background-color: $grey-3;
        height: 1px;
      }
      tr {
        &.disclaimer {
          display: flex;
          flex-wrap: nowrap;
          padding-top: 10px;
          &:before {
            content: '*';
            padding-right: 5px;
          }
        }
      }
      td {
        padding: 5px 0;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          text-align: right;
          width: 40%;
        }
      }
    }
  }

  &__form-input {
    & + & {
      margin-top: toRem(20px);
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
