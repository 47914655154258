<template lang="pug">
nav.profile-navigation(aria-label='Profile menu' role='navigation')
  .profile-navigation__wrapper
    ul.profile-navigation__inbox(role='menu')
      li(role='menuitem' v-for='group in profileMenu')
        a.profile-link(:href='`${group.url}`', :title='`${group.label}`' v-if='group.label')
          svg.o-svg-icon.profile-link--icon(type='image/svg+xml' v-if='group.icon' viewbox='0 0 64 64')
            use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${group.icon}`' x='0' y='0')
          span.profile-link--text {{ group.label }}
          span.profile-link--count(:key='countKey' v-if='getCount(group.count) > 0') {{ getCount(group.count) }}
        span(v-if='group.headline') {{ group.headline }}
        ul.profile-navigation__main(role='menu' v-if='group.links')
          li(:key='group.links.label' role='menuitem' v-for='item in group.links')
            a.profile-link(:class='{ "link-active": url_current == `${item.url}` }', :href='`${item.url}`', :title='`${item.label}`')
              svg.o-svg-icon.profile-link--icon(type='image/svg+xml' v-if='item.icon' viewbox='0 0 64 64')
                use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${item.icon}`' x='0' y='0')
              span.profile-link--text(v-bind:class='{ "profile-link--no-icon": !item.icon }') {{ item.label }}
              span.profile-link--count(:key='countKey' v-if='getCount(item.count) > 0') {{ getCount(item.count) }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-profile-menu',
  data() {
    return {
      countKey: 0
    }
  },
  computed: {
    ...mapGetters('user', ['profileMenu']),
    ...mapState('messages', ['unreadMessagesCount']),
    url_current: function () {
      return window.location.pathname
    }
  },
  methods: {
    getCount(count) {
      return this.unreadMessagesCount !== undefined && count !== undefined ? this.unreadMessagesCount : count
    }
  },
  watch: {
    profileMenu: {
      deep: true,
      handler() {
        this.countKey++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-navigation {
  background-color: $grey-5;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  padding: toRem(28px 28px);
  z-index: z('header');
  font-family: 'Inria Serif';
  font-size: toRem(14px);
  letter-spacing: 0;
  line-height: toRem(20px);
  color: $ocean-darker;
  @include min('desktop') {
    height: auto;
    width: toRem(320px);
    padding: toRem(15px 15px 12px 16px);
    overflow-y: visible;
  }
  // For "my pages" left menu - show all no scroll
  .u-visually-hidden--desktop & {
    .profile-navigation__wrapper {
      max-height: initial;
      overflow-y: visible;
    }
  }
  &__wrapper {
    @include min('desktop') {
      max-height: calc(100vh - 80px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding-block-end: toRem(12px);
    }
  }
  .profile-link {
    color: $ocean-darker;
    text-decoration: none;
    font-family: 'Inria Serif';
    @include setStyles('20', $s-typography-sizes);
    font-weight: bold;
    letter-spacing: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: toRem(8px 6px);
    border-radius: toRem(2px);
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: $white;
    }
    &:focus {
      outline-offset: 0px;
    }
    &.link-active {
      background-color: $white;
      .profile-link--no-icon {
        text-decoration: none;
      }
    }
    @include min('desktop') {
      @include setStyles('18', $s-typography-sizes);
    }
    &--icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: toRem(12px);
      width: toRem(26px);
      height: toRem(26px);
      stroke: $ocean-darker;
      stroke-width: 0.2px;
      @include min('desktop') {
        margin-right: toRem(7px);
        width: toRem(24px);
        height: toRem(24px);
      }
    }
    &--count {
      color: $white;
      height: toRem(26px);
      width: toRem(26px);
      background-color: $ocean-primary;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      font-family: 'Open Sans';
      font-size: toRem(12px);
      font-weight: 600;
      letter-spacing: 0;
      line-height: toRem(26px);
      margin-left: toRem(10px);
    }
    &--no-icon {
      text-decoration: underline;
      font-family: 'Inria Serif';
      font-size: toRem(15px);
      font-weight: bold;
      letter-spacing: 0;
      line-height: toRem(21px);
    }
  }
  &__inbox,
  &__main {
    margin-bottom: toRem(28px);
    li {
      margin: toRem(8px 0);
      padding: toRem(0 1px);
    }
  }
  &__inbox {
    margin-bottom: 0;
    > li:last-child {
      border-top: 1px solid #d1d1d1;
      margin-bottom: 0;
      padding-top: toRem(20px);
      .profile-link {
        &--text {
          font-family: 'Inria Serif';
          font-size: toRem(15px);
          font-weight: bold;
          letter-spacing: 0;
          line-height: toRem(21px);
        }
        &--icon {
          vertical-align: text-bottom;
          svg {
            vertical-align: top;
          }
        }
      }
    }
  }
}
</style>
