import apiClient from '@/_helpers/api-client'
import { toFormData } from 'axios'
export const userService = {
  validateUser,
  createUser,
  authenticateUser,
  getProfileInformation,
  setProfileInformation,
  deleteProfile,
  getUserData,
  getAvatar,
  getCountries,
  getNotifications,
  setNotifications,
  getFavorites,
  postFavoriteProperties,
  deleteFavoriteProperties,
  getUserMenu,
  postFavoriteResidence,
  deleteFavoriteResidence,
  postFavoriteProject,
  deleteFavoriteProject,
  getUserEducation,
  setUserEducation,
  resetPassword,
  updatePassword,
  getPensionFundsUserData,
  logOut,
  revalidateApplications
}

// async function getAll({ id, parameters }) {
//   return apiClient.get(`/api/v4/aedsfull/${id}`, { params: parameters })
// }

async function validateUser({ email }) {
  return await apiClient({
    url: `/users/${email}/exists`,
    method: 'GET'
  })
}

async function createUser(data) {
  return await apiClient({
    url: '/authentication/signup',
    method: 'POST',
    data
  })
}

async function authenticateUser({ email, password }) {
  return await apiClient({
    url: '/authentication/login',
    method: 'POST',
    data: {
      email,
      password
    }
  })
}

async function getProfileInformation() {
  return await apiClient({
    url: '/users/profiles/me',
    method: 'GET'
  })
}

async function setProfileInformation(data) {
  return await apiClient({
    url: '/users/profile',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: toFormData(data)
  })
}
async function deleteProfile() {
  return await apiClient({
    url: '/users/me',
    method: 'DELETE'
  })
}
async function getUserData() {
  return await apiClient({
    url: '/users/me',
    method: 'GET'
  })
}
async function getAvatar(id) {
  return await apiClient({
    url: `/users/${id}/avatar`,
    method: 'GET'
  })
}
async function getCountries() {
  return await apiClient({
    url: '/profiles/countries',
    method: 'GET'
  })
}
async function getNotifications() {
  return await apiClient({
    url: '/profiles/notifications',
    method: 'GET'
  })
}
async function setNotifications(data) {
  return await apiClient({
    url: '/profiles/notifications',
    method: 'PATCH',
    data
  })
}
async function getFavorites() {
  return await apiClient({
    url: '/favorites',
    method: 'GET'
  })
}
async function postFavoriteProperties(data) {
  return await apiClient({
    url: '/favorites/properties',
    method: 'POST',
    data
  })
}
async function deleteFavoriteProperties(data) {
  return await apiClient({
    url: '/favorites/properties',
    method: 'DELETE',
    data
  })
}
async function postFavoriteResidence(data) {
  return await apiClient({
    url: '/favorites/residences',
    method: 'POST',
    data
  })
}
async function deleteFavoriteResidence(data) {
  return await apiClient({
    url: '/favorites/residences',
    method: 'DELETE',
    data
  })
}
async function postFavoriteProject(data) {
  return await apiClient({
    url: '/favorites/projects',
    method: 'POST',
    data
  })
}
async function deleteFavoriteProject(data) {
  return await apiClient({
    url: '/favorites/projects',
    method: 'DELETE',
    data
  })
}
async function getUserMenu() {
  return await apiClient({
    url: '/website/profile-menu',
    method: 'GET'
  })
}
async function getUserEducation() {
  return await apiClient({
    url: '/users/profiles/education',
    method: 'GET'
  })
}
async function getPensionFundsUserData() {
  return await apiClient({
    url: '/data/users/me/membership-organizations',
    method: 'GET'
  })
}
async function revalidateApplications(data) {
  return await apiClient({
    url: '/data/users/parameters/revalidate-applications',
    method: 'POST',
    data
  })
}
async function setUserEducation(data) {
  return await apiClient({
    url: '/users/profiles/education',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: toFormData(data)
  })
}

async function resetPassword({ email }) {
  return await apiClient({
    url: '/users/password/reset',
    method: 'POST',
    data: {
      email
    }
  })
}

async function updatePassword(password, token) {
  return await apiClient({
    url: '/users/password',
    method: 'PUT',
    data: {
      password,
      token
    }
  })
}

async function logOut(refreshPage = true) {
  const xhttp = new XMLHttpRequest()
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200 && refreshPage) {
      window.location.reload()
    }
  }
  xhttp.open('GET', '/api/signout', true)
  xhttp.send()
}
