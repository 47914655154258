import apiClient from '@/_helpers/api-client'
export const applicationsManagementService = {
  getApplicationStatusForResidenceAdvert,
  applyForResidenceAdvert,
  cancelForResidenceAdvert
}

async function getApplicationStatusForResidenceAdvert(residenceId) {
  return await apiClient({
    url: `/data/applications/residences/${residenceId}`,
    method: 'GET'
  })
}
async function applyForResidenceAdvert(residenceId) {
  return await apiClient({
    url: `/data/applications/residences/${residenceId}`,
    method: 'POST'
  })
}
async function cancelForResidenceAdvert(residenceId) {
  return await apiClient({
    url: `/data/applications/residences/${residenceId}`,
    method: 'DELETE'
  })
}
