<template lang="pug">
.c-applicant-details
  .c-applicant-details__top.o-grid.o-grid--justify-content-space.o-grid
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.c-applicant-details__close(@click='close')
          svg.c-applicant-details__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
          .c-applicant-details__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
      .o-grid__item.o-grid__item--grow-0(v-if='isLocal')
        a.c-state-action(:href='`/api/authentication/impersonate/${applicantState.userId}`')
          svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          span.o-bodytext.o-bodytext--size-small {{ Dictionary.I.Imitate }}

  .c-applicant-details__info.o-grid.o-grid--align-items-start.o-grid.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
        .c-applicant-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.NumberShort }}
        .c-applicant-details__info-data.o-bodytext.o-bodytext--size-small {{ applicantData.applicantNumber || '-' }}
      .o-grid__item.o-grid__item--8.o-grid__item--desktop-2
        .c-applicant-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.Name }}
        .c-applicant-details__info-data.o-bodytext.o-bodytext--size-small {{ applicantData.firstName }} {{ applicantData.lastName }}

      template(v-if='isPropertyAdmin && targetMembershipOrganization')
        .o-grid__item.o-grid__item--1.o-grid__item--desktop-2
          .c-applicant-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700
          svg.c-applicant-details__icon.o-svg-icon(
            :class='[getVerificationStatus(applicantData, targetMembershipOrganization.id)]'
            type='image/svg+xml'
            viewbox='0 0 42 34'
          )
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
        .o-grid__item.o-grid__item--3.o-grid__item--desktop-2
          .c-applicant-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.C.Category }}
          .c-applicant-details__info-data.o-bodytext.o-bodytext--size-small {{ getApplicantsCategoryInMembershipOrganization() || '-' }}
        .o-grid__item.o-grid__item--2.o-grid__item--desktop-2
          .c-applicant-details__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.Points }}
          .c-applicant-details__info-data.o-bodytext.o-bodytext--size-small {{ getWaitingListPoints(applicantData, targetMembershipOrganization.id, selected.propertyId) || 0 }}

  .c-applicant-details__data
    .c-applicant-details__form-section(v-if='applicantsAdverts.length > 0')
      .c-applicant-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.ActiveApplicationsHeadline }}
      .c-applicant-details__table.o-grid.o-grid--align-items-start.o-grid--justify-content-start
        .o-grid__group(v-for='advertRow in applicantsAdverts')
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-1
            span.o-bodytext.o-bodytext--size-small {{ advertRow.advert.shortId }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-5
            span.o-bodytext.o-bodytext--size-small {{ combineAddress(advertRow.advert.street, advertRow.advert.number, advertRow.advert.letter, advertRow.advert.floor, advertRow.advert.door, advertRow.advert.postalCode) }}
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-5
            span.o-bodytext.o-bodytext--size-small {{ Dictionary.ApplicationStatus[advertRow.status] }}
      button.o-link(@click='declineApplicantsAdverts') {{ Dictionary.RecipientDetails.CancelActiveApplications }}
    .c-applicant-details__form-section(v-if='isPropertyAdmin && applicantsOffers.length > 0')
      .c-applicant-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.ActiveOffersHeadline }}
      .c-applicant-details__table.o-grid.o-grid--align-items-start.o-grid--justify-content-start
        .o-grid__group(v-for='offer in applicantsOffers')
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-1
            span.o-bodytext.o-bodytext--size-small {{ offer.number }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-5
            span.o-bodytext.o-bodytext--size-small {{ offer.residenceAddress }}
          .o-grid__item.o-grid__item--3.o-grid__item--desktop-5
            span.o-bodytext.o-bodytext--size-small {{ getApplicantsOfferState(offer) }}
      button.o-link(@click='cancelApplicantsOffers') {{ Dictionary.RecipientDetails.CancelActiveOffers }}
    .c-applicant-details__form-section
      .c-applicant-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.InformationHeadline }}
      .o-grid.o-grid--gutter-horizontal-small
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.A.Address }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ applicantData.address }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.E.Email }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ applicantData.email }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.P.Phone }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ applicantData.phone }}
        .o-grid__group
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.P.PhoneMobile }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ applicantData.mobilePhone }}
        .o-grid__group(v-if='isPropertyAdmin && targetMembershipOrganization')
          .o-grid__item.o-grid__item--4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.I.InternalNote }}
          .o-grid__item.o-grid__item--8
            .o-bodytext.o-bodytext--size-small {{ applicantData.notes[rewardAdministrator && rewardAdministrator.id] }}
        .o-grid__group(v-if='isPropertyAdmin && targetMembershipOrganization')
          .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.Q.Quarantine }}
          .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.F.From }}: {{ targetMembershipOrganization.quarantineStartDate | formatDate }}
          .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.T.To }}: {{ targetMembershipOrganization.quarantineEndDate | formatDate }}
        .o-grid__group(v-if='isPropertyAdmin && targetMembershipOrganization && targetMembershipOrganization.memberSeniorityDate')
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.M.MemberSeniority }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-8
            .o-bodytext.o-bodytext--size-small {{ targetMembershipOrganization.memberSeniorityDate | formatDate }}
        .o-grid__group(v-if='isPropertyAdmin && targetMembershipOrganization && targetMembershipOrganization.listSeniorityDate')
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            .o-bodytext.o-bodytext--size-small {{ Dictionary.W.WaitingListSeniority }}
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-8
            .o-bodytext.o-bodytext--size-small {{ targetMembershipOrganization.listSeniorityDate | formatDate }}
    .c-applicant-details__form-section(v-if='isPropertyAdmin && applicantsMembershipOrganizationParameters.length > 0')
      .c-applicant-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.RecipientDetails.AffiliationQuestionsHeadline }}
      .c-applicant-details__parameters-section(
        :key='`parameter-section-${parameterSectionIndex}`'
        v-for='(parameterSection, parameterSectionIndex) in applicantsMembershipOrganizationParameters'
      )
        .c-applicant-details__parameters-heading.o-bodytext.o-bodytext--size-large(v-if='parameterSection.name') {{ parameterSection.name }}
        .o-grid.o-grid--gutter-horizontal-small
          .o-grid__group(:key='`parameter-${parameterIndex}`' v-for='(parameter, parameterIndex) in parameterSection.parameters')
            .o-grid__item.o-grid__item--5.o-grid__item--desktop-4
              .o-bodytext.o-bodytext--size-small {{ parameter.question }}
            .o-grid__item.o-grid__item--5.o-grid__item--desktop-4
              .c-applicant-details__parameters-answer.o-bodytext.o-bodytext--size-small(
                v-if='parameter.validationConfig && parameter.validationConfig.displayRule === "######-####"'
                v-sensitiveInfo:4='getAnswerFromParameter(parameter)'
              )
              .c-applicant-details__parameters-answer.o-bodytext.o-bodytext--size-small(
                    :href='`/api/data/management/applicant-status/${applicantData.userId}/parameters/${parameter.id}/file`',
                    :is='parameter.type === "Upload" && parameter.answer ? "a" : "div"'
                    download
                    v-else
                  ) {{ getAnswerFromParameter(parameter) }}

    .c-applicant-details__form-section(v-if='applicantState')
      .c-applicant-details__form-heading.o-heading.o-heading--size-4 {{ Dictionary.S.Status }}

      .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
        .o-grid__group
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
            c-fc-select(:model='applicantDetailsNewStatusData' @validate='validate($event, "newStatus")' v-model='applicantDetails.newStatus.value')
          .o-grid__item.o-grid__item--6.o-grid__item--desktop-4.c-applicant-details__followUpDate
            c-fc-date(
              :model='applicantDetailsNewFollowUpDateData'
              @validate='validate($event, "newFollowUpDate")'
              v-model='applicantDetails.newFollowUpDate.value'
            )
            button.c-state-action.c-applicant-details__state-action(@click='deleteFollowUpDate(applicantState)' v-if='applicantState.status === "Deleted" && applicantState.followUpDate')
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')

          .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
            c-fc-textbox(:model='applicantDetailsNewNoteData' @validate='validate($event, "newNote")' v-model='applicantDetails.newNote.value')
  .c-applicant-details__form.c-applicant-details__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center.o-grid--justify-content-center
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.o-link.c-applicant-details__cancel(@click='close') {{ Dictionary.C.Cancel }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.c-applicant-details__submit(:disabled='loading || !dataChanged' @click='submitChanges') {{ Dictionary.S.Save }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import { applicantsManagementService, advertManagementService, offersManagementService } from '@/_services'
import moment from 'moment'
export default {
  name: 'c-applicant-details',
  props: {
    model: Object,
    applicantData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: this._uid,
      applicantsMembershipOrganizationParameters: [],
      applicantsOffers: [],
      applicantsAdverts: [],
      validation: {},
      applicantDetails: {
        newStatus: {
          helperText: '',
          placeholderText: this.Dictionary.S.Status,
          value: null,
          isMandatory: false,
          options: [
            { value: 'New', name: this.Dictionary.ApplicationStatus.New },
            { value: 'Interested', name: this.Dictionary.ApplicationStatus.Interested },
            { value: 'FollowUp', name: this.Dictionary.ApplicationStatus.FollowUp },
            { value: 'ShowingBooked', name: this.Dictionary.ApplicationStatus.ShowingBooked },
            { value: 'Finished', name: this.Dictionary.ApplicationStatus.Finished },
            { value: 'Offered', name: this.Dictionary.ApplicationStatus.Offered },
            { value: 'RentedOut', name: this.Dictionary.ApplicationStatus.RentedOut, hidden: true },
            { value: 'Cancelled', name: this.Dictionary.ApplicationStatus.Cancelled, hidden: true },
            { value: 'Deleted', name: this.Dictionary.ApplicationStatus.Deleted, hidden: true }
          ],
          validation: null
        },
        newFollowUpDate: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.F.FollowUpDate,
          isMandatory: false,
          readOnly: false,
          validation: {
            minDate: moment.utc().format('YYYY-MM-DD')
          },
          key: 0
        },
        newNote: {
          helperText: '',
          placeholderText: this.Dictionary.N.Note,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-date': FormControllDate,
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapGetters(['isLocal']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('advertManagement', ['selected']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('user', ['roles']),
    isPropertyAdmin() {
      return this.roles.includes('Property Administrator')
    },
    targetMembershipOrganization() {
      return (
        this.applicantData.membershipOrganizations.find(membershipOrganization => membershipOrganization.companies.includes(this.selected.companyId)) || null
      )
    },
    rewardAdministrator() {
      return this.administrators && this.administrators.find(administrator => administrator.companies.includes(this.selected.companyId))
    },
    applicantState() {
      const applicantState = this.selected.applicantsData.find(a => a.userId === this.applicantData.userId)
      return applicantState
    },
    applicantDetailsNewStatusData() {
      const applicantDetailsNewStatusData = Object.assign({}, this.applicantDetails.newStatus)
      applicantDetailsNewStatusData.readOnly = this.applicantDetails.newStatus.value === 'Deleted' || this.applicantDetails.newStatus.value === 'RentedOut'
      return applicantDetailsNewStatusData
    },
    applicantDetailsNewFollowUpDateData() {
      const applicantDetailsNewFollowUpDateData = Object.assign({}, this.applicantDetails.newFollowUpDate)
      applicantDetailsNewFollowUpDateData.readOnly = this.applicantDetails.newStatus.value === 'Deleted'
      return applicantDetailsNewFollowUpDateData
    },
    applicantDetailsNewNoteData() {
      const applicantDetailsNewNoteData = Object.assign({}, this.applicantDetails.newNote)
      applicantDetailsNewNoteData.readOnly = this.applicantDetails.newStatus.value === 'Deleted'
      return applicantDetailsNewNoteData
    },
    dataChanged() {
      let dataChanged = false
      const target = Object.keys(this.applicantDetails).find(key => {
        let different = false
        switch (key) {
          case 'newNote':
            different =
              this.applicantDetails[key].value !== null &&
              !(this.applicantDetails[key].value === '' && this.applicantState.note === null) &&
              this.applicantDetails[key].value !== this.applicantState.note
            break
          case 'newFollowUpDate':
            different =
              this.applicantDetails[key].value !== undefined &&
              this.applicantDetails[key].value !== null &&
              this.applicantDetails[key].value !== '' &&
              this.applicantDetails[key].value !== this.formatDate(this.applicantState.followUpDate, 'YYYY-MM-DD')
            break
          default:
            different =
              this.applicantDetails[key].value !== undefined &&
              this.applicantDetails[key].value !== null &&
              this.applicantDetails[key].value !== '' &&
              this.applicantDetails[key].value !== this.applicantState[key]
        }
        // console.log(different, key)
        return different
      })

      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {

    async deleteFollowUpDate(){
      try {
        const data = {
          users: [this.applicantData.userId]
        }
        data.removeFollowUpDate = true
        const response = await advertManagementService.setApplicationsState(this.selected.id, data)
         if (response.status === 204) {
          this.$store.dispatch('advertManagement/getAdvertApplicantsData')
          this.close()
        }
      } catch (error) {
        // console.log(error)
      }
    },
    close() {
      this.$store.dispatch('applicantsManagement/removeSelected')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    async getApplicantsParameters() {
      try {
        if (this.targetMembershipOrganization && this.targetMembershipOrganization.id) {
          const response = await applicantsManagementService.getApplicantsParameters({
            userId: this.applicantData.userId,
            moId: this.targetMembershipOrganization.id
          })
          if (response && response.status === 200) {
            this.applicantsMembershipOrganizationParameters = response.data
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    setApplicantDetails() {
      Object.keys(this.applicantDetails).forEach(key => {
        switch (key) {
          case 'newFollowUpDate':
            this.applicantDetails[key].value =
              this.applicantState && this.applicantState.followUpDate ? this.formatDate(this.applicantState.followUpDate, 'YYYY-MM-DD') : null
            break
          case 'newNote':
            this.applicantDetails[key].value = this.applicantState && this.applicantState.note ? this.applicantState.note : null
            break
          case 'newStatus':
            this.applicantDetails[key].value = this.applicantState && this.applicantState.status ? this.applicantState.status : null
            break
          default:
            this.applicantDetails[key].value = this.applicantState && this.applicantState[key] ? this.applicantState[key] : null
        }
      })
    },
    getPoints(parameter) {
      return this.applicantData.userPointBalance[parameter.id] !== undefined
        ? `${this.applicantData.userPointBalance[parameter.id]} ${this.Dictionary.P.Points}`
        : ''
    },
    getApplicantsCategoryInMembershipOrganization() {
      const memberOrgId = this.targetMembershipOrganization && this.targetMembershipOrganization.id
      const category = memberOrgId && this.applicantData.categories[memberOrgId] && this.applicantData.categories[memberOrgId].toString()
      return category || ''
    },
    async submitChanges() {
      try {
        const data = {
          users: [this.applicantData.userId]
        }
        if (this.applicantDetails.newStatus.value !== null) {
          data.newStatus = this.applicantDetails.newStatus.value
        }
        if (this.applicantDetails.newFollowUpDate.value !== null) {
          if (this.applicantDetails.newFollowUpDate.value === '') {
            data.removeFollowUpDate = true
          } else {
            data.newFollowUpDate = this.applicantDetails.newFollowUpDate.value
          }
        }
        if (this.applicantDetails.newNote.value !== null) {
          if (this.applicantDetails.newNote.value === '') {
            data.removeNote = true
          } else {
            data.newNote = this.applicantDetails.newNote.value
          }
        }
        const response = await advertManagementService.setApplicationsState(this.selected.id, data)
        if (response.status === 204) {
          this.$store.dispatch('advertManagement/getAdvertApplicantsData')
          this.close()
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getRecipientsPendingOffers() {
      try {
        const response = await offersManagementService.getRecipientsPendingOffers({
          userId: this.applicantData.userId
        })
        if (response && response.status === 200) {
          this.applicantsOffers = response.data.length > 0 && response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getApplicantsOfferState(offer) {
      const offerState = offer.recipientsState.find(s => s.userId === this.applicantData.userId)
      return offerState && this.Dictionary.RecipientState[offerState.state]
    },
    async declineRecipientsOffer(id) {
      try {
        const response = await offersManagementService.declineRecipientsOffer({
          id,
          userId: this.applicantData.userId
        })
        if (response && response.status === 200) {
          const targetOffer = this.applicantsOffers.find(o => o.id !== id)
          Object.assing(targetOffer, response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    cancelApplicantsOffers() {
      this.applicantsOffers.forEach(offer => {
        this.declineRecipientsOffer(offer.id)
      })
    },
    async getApplicantsPendingAdverts() {
      try {
        const response = await advertManagementService.getApplicantsPendingAdverts({
          userId: this.applicantData.userId,
          id: this.selected.id
        })
        if (response && response.status === 200) {
          this.applicantsAdverts = response.data.length > 0 && response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async declineApplicantsAdverts() {
      try {
        const response = await advertManagementService.declineApplicantsAdverts({
          id: this.selected.id,
          userId: this.applicantData.userId
        })
        if (response && response.status === 204) {
          this.applicantsAdverts = []
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  created() {
    this.getApplicantsParameters()
    this.getApplicantsPendingAdverts()
    if (this.isPropertyAdmin) {
      this.getRecipientsPendingOffers()
      if (!this.administrators || this.administrators.length === 0) {
        this.$store.dispatch('usersManagement/getPropertyAdministrators')
      }
    }

    this.setApplicantDetails()
  },
  watch: {
    rewardAdministrator() {
      this.setApplicantDetails()
    },
    applicantState() {
      this.setApplicantDetails()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-applicant-details {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__followUpDate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    button{
      flex: 0 0 24px;
      margin-left: 5px;
    }
    > div{
      flex: 1 0 auto;
    }
  }
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      color: inherit;
      padding: 0;
      min-height: 0;
      margin-bottom: 25px;
      box-shadow: none;
    }

    @include max('desktop', -1) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;

      & + #{$root}__info {
        margin-top: 62px;
      }
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }
  &__icon {
    width: 24px;
    height: 20px;
    margin: 0 10px 0 0;
    @include min('desktop') {
      width: 20px;
      height: 20px;
    }

    @include max('desktop', -1) {
      margin: 0 auto;
      display: block;
    }
  }
  &__info {
    padding: toRem(16px 25px);
    background-color: $grey-5;
  }
  &__data {
    margin-top: 20px;

    @include max('desktop', -1) {
      padding: 0 25px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
      border-top: 1px solid $grey-4;

      @include max('desktop', -1) {
        padding: $sectionMargin 25px 0;
      }
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 10px;
      @include min('desktop') {
        margin-bottom: 20px;
      }
    }
  }
  &__table {
    margin-bottom: 10px;
  }

  &__parameters {
    &-heading {
      margin-bottom: 10px;
    }
    &-section {
      margin-bottom: 15px;
    }
    &-answer {
      color: $grey-3;
    }
  }
}
</style>
