export function getQueryParameters() {
  const uri = window.location.search.substring(1)
  const params = new URLSearchParams(uri)
  return params
}

export function deleteQueryParameter(parameterKey) {
  const uri = window.location.search.substring(1)
  const params = new URLSearchParams(uri)
  params.delete(parameterKey)
  const parameters = params.toString().length > 0 ? '?' + params.toString() : ''
  window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + parameters)
}

export function setQueryParameters(filters, setInUrl = true) {
  const uri = window.location.search.substring(1)
  const params = new URLSearchParams(uri)
  Object.keys(filters).forEach(key => {
    if (Array.isArray(filters[key]) && filters[key].length > 0 && !(key === 'Floor' && JSON.stringify(filters[key]) === '[-3,30]')) {
      const escapedCommaArray = key === 'Floor' ? filters[key] : filters[key].map(value => value.replaceAll(',', '/,'))
      params.set(key, escapedCommaArray.toString())
    } else if (filters[key] > 0 || (typeof filters[key] === 'string' && filters[key].length > 0)) {
      params.set(key, filters[key])
    } else {
      params.delete(key)
    }
  })
  const parameters = params.toString().length > 0 ? '?' + params.toString() : ''
  if (setInUrl) {
    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + parameters)
    return undefined
  } else {
    return parameters
  }
}

export function setAgentId(id) {
  if (id) {
    const pathName = window.location.pathname
    const newPathName = pathName.replace(/\/[^/]*$/, `/${id}`)
    window.history.replaceState({}, document.title, window.location.origin + newPathName + window.location.search)
  } else {
    const backUrl = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))
    window.location.replace(window.location.origin + backUrl + window.location.search)
  }
}
