import apiClient from '@/_helpers/api-client'
export const administratorsManagementService = {
  getPropertyAdministrators,
  createPropertyAdministrator,
  getPropertyAdministrator,
  patchPropertyAdministrator,
  deletePropertyAdministrator
}

async function getPropertyAdministrators() {
  return await apiClient({
    url: '/data/property-administrators',
    method: 'GET'
  })
}
async function createPropertyAdministrator(data) {
  return await apiClient({
    url: '/data/property-administrators',
    method: 'POST',
    data
  })
}
async function getPropertyAdministrator(id) {
  return await apiClient({
    url: `/data/property-administrators/${id}`,
    method: 'GET'
  })
}
async function patchPropertyAdministrator({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/property-administrators/${id}`,
    method: 'PATCH',
    data
  })
}
async function deletePropertyAdministrator(id) {
  return await apiClient({
    url: `/data/property-administrators/${id}`,
    method: 'DELETE'
  })
}
