<template lang="pug">
c-modal.c-import-data(:cornerClose='true' @close='handleClose' v-if='show')
  template(slot='body')
    .o-grid.o-grid--justify-content-center.o-grid--gutter-small
      .o-grid__group(v-if='loading')
        .o-grid__item.o-grid__item--12
          .c-import-data__heading.o-heading.o-heading--size-2 {{ translations.loadingHeadline }}
        .o-grid__item.o-grid__item--12
          .c-import-data__description.o-bodytext(v-html='translations.loadingDescription')
        .o-grid__item.o-grid__item--12
          c-loading-animation
      .o-grid__group(v-else-if='results.length > 0')
        .o-grid__item.o-grid__item--12
          .c-import-data__heading.o-heading.o-heading--size-2 {{ translations.doneHeadline }}
        .o-grid__item.o-grid__item--12
          .c-import-data__description.o-bodytext(v-html='translations.doneDescription')
        .o-grid__item.o-grid__item--12
          c-notification-inline.c-import-data__result.o-bodytext.o-bodytext--size-small(
            :canClose='false'
            justify='left'
            type='ok'
            v-if='validResults.length > 0'
          ) {{ validResultsText }}
        .o-grid__item.o-grid__item--12
          c-notification-inline.c-import-data__result.o-bodytext.o-bodytext--size-small(
            :canClose='false'
            justify='left'
            type='error'
            v-if='errorResults.length > 0'
          ) {{ errorResultsText }}
      .o-grid__group(v-else='!loading && results.length === 0')
        .o-grid__item.o-grid__item--12
          .c-import-data__heading.o-heading.o-heading--size-2 {{ translations.startHeadline }}
        .o-grid__item.o-grid__item--12
          .c-import-data__description.o-bodytext(v-html='translations.startDescription')
        .o-grid__item.o-grid__item--12
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--8
                c-fc-select(:model='selectData' @validate='validate($event, "select")' v-model='mutableData.select.value')
              .o-grid__item.o-grid__item--4
                input.o-input.u-visually-hidden(
                  :accept='uploadModel.acceptableFormat || acceptableFormats.toString()',
                  :id='`${id}-upload`',
                  :name='`${id}-upload`'
                  @change='filesChange($event.target.files, $event.target)'
                  placeholder=' '
                  ref='uploadInput'
                  type='file'
                )
                label.c-import-data__upload.o-button(:for='`${id}-upload`') {{ Dictionary.S.SelectFile }}
              .o-grid__item.o-grid__item--12(v-if='errorFiles.length > 0')
                label.c-import-data__line-item(:id='`${id}-uploadError`', :key='key' v-for='(errorFile, key) in errorFiles')
                  svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
                  .c-import-data__line-item-info
                    .c-import-data__line-item-name.o-bodytext.o-bodytext--size-small {{ errorFile.file.name }}
                    .c-import-data__line-item-error.o-bodytext.o-bodytext--size-xsmall {{ errorMessage(errorFile) }}
                  button.c-import-data__file-action(:aria-label='Dictionary.D.DeleteUploadedFile' @click='fileRemoveClicked(key)')
                    svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
  template(slot='footer')
    button.c-import-data__cancel(
      :class='[{ "o-link c-import-data__cancel--link": results.length === 0 }, { "o-button": results.length > 0 }]'
      @click='handleClose'
    ) {{ results.length > 0 ? Dictionary.C.Close : Dictionary.C.Cancel }}
</template>
<script>
import FormControllSelect from '@/components/form/controlls/form-select'
import { propertyManagementService, residenceManagementService } from '@/_services'
export default {
  name: 'c-import-data',
  data() {
    return {
      id: this._uid,
      acceptableFormats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      maxFileSize: 20971520,
      errorFiles: [],
      validFiles: [],
      validation: {},
      loading: false,
      results: [],
      mutableData: {
        select: {
          helperText: this.selectModel.helper,
          placeholderText: this.Dictionary.O.Owner,
          value: null,
          isMandatory: true,
          readOnly: false,
          options: [],
          validation: null
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect
  },
  props: {
    show: {
      default: false,
      required: true
    },
    translations: {
      required: true
    },
    selectModel: {
      type: Object,
      required: true
    },
    uploadModel: {
      type: Object,
      required: true
    },
    importType: {
      required: true
    }
  },
  computed: {
    selectData() {
      const selectData = Object.assign({}, this.mutableData.select)
      selectData.options = this.selectModel.options
      return selectData
    },
    inputIsValid() {
      return !!this.mutableData.select.value && this.validFiles.length > 0
    },
    validResults() {
      return this.results
        .filter(result => result.results.every(innerResult => innerResult.type === 'Success' || innerResult.type === 'Warning'))
        .map(result => result.rowIndex)
    },
    errorResults() {
      return this.results.filter(result => result.results.some(innerResult => innerResult.type === 'Error')).map(result => result.rowIndex)
    },
    validResultsText() {
      return this.translations.doneImported.replace('{0}', this.validResults.length)
    },
    errorResultsText() {
      return this.translations.doneFailed.replace('{0}', this.errorResults.length).replace('{1}', this.errorResults.toString())
    }
  },
  methods: {
    async submitFile() {
      const formData = new FormData()
      formData.append('companyId', this.mutableData.select.value)
      formData.append('file', this.validFiles[0])
      let service

      switch (this.importType) {
        case 'property':
          service = propertyManagementService.importPropertiesFromExcel
          break
        case 'residence':
          service = residenceManagementService.importResidencesFromExcel
          break
        case 'warning':
          service = residenceManagementService.importRentWarningsFromExcel
          break
      }

      this.loading = true
      try {
        const response = await service(formData)
        if (response.status === 200) {
          this.loading = false
          this.results = response.data
        }
      } catch (error) {
        this.loading = false
        this.mutableData.select.value = null
        this.errorFiles = []
        this.validFiles = []
        this.results = []
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleClose() {
      this.mutableData.select.value = null
      this.errorFiles = []
      this.validFiles = []
      this.results = []
      this.$emit('close')
    },
    filesChange(fileList, target) {
      this.errorFiles = []
      this.validFiles = []
      fileList.forEach(file => {
        const fileTypeOK = this.acceptableFormats.includes(file.type)
        const fileSizeOK = file.size <= (this.uploadModel.maxSize || this.maxFileSize)
        const fileIsValid = fileTypeOK && fileSizeOK

        if (fileIsValid) {
          this.validFiles.push(file)
        } else {
          const failedFile = { fileSizeOK, fileTypeOK, file }

          this.errorFiles.push(failedFile)
        }
      })

      if (target) target.value = ''
    },
    errorMessage(errorFile) {
      const errorMessage = []
      if (!errorFile.fileSizeOK) {
        errorMessage.push(
          this.uploadModel.validationSize &&
            this.uploadModel.validationSize
              .replace('{0}', this.$options.filters.prettyBytes(errorFile.file.size))
              .replace('{1}', this.$options.filters.prettyBytes(this.uploadModel.maxSize || this.maxFileSize))
        )
      }
      if (!errorFile.fileTypeOK) {
        errorMessage.push(this.uploadModel.validationFormat && this.uploadModel.validationFormat.replace('{0}', errorFile.file.type).replace('{1}', '.xlsx'))
      }

      return errorMessage.toString()
    },
    fileRemoveClicked(index) {
      this.errorFiles.splice(index, 1)
      this.$refs.uploadInput.value = ''
    }
  },
  watch: {
    inputIsValid(newVal) {
      if (newVal) {
        this.submitFile()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-import-data {
  &__heading,
  &__description {
    text-align: center;
  }

  &__description {
    margin-bottom: 10px;
    a {
      color: red;
    }
  }
  &__cancel {
    margin: 40px auto 0;

    display: block;

    &--link {
      border: none;
      background-color: transparent;
    }
  }
  &__result {
    text-align: center;
    & + & {
      margin-top: 10px;
    }
  }
  &__upload {
    min-width: 100%;
    min-height: 53px;
  }

  &__file-action {
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
  }

  &__line-item {
    background-color: $grey-5;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    & + & {
      margin-top: 5px;
    }
    // #{$root}__file-list--uploaded & {
    //   display: inline-flex;
    //   border: none;
    //   padding: 10px 15px;
    //   margin: 5px;

    //   &:last-child {
    //     border-bottom: none;
    //   }
    // }

    &-info {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      margin: 0 5px;
      max-width: calc(100% - 65px);

      // #{$root}__file-list--uploaded & {
      //   margin: 0 0 0 5px;
      //   max-width: calc(100% - 25px);
      // }
    }
    &-name {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: $ocean-primary;

      // #{$root}__file-list--uploaded & {
      //   max-width: unset;
      // }
    }

    &-error {
      flex: 1 0 100%;
      color: $error-red;
      white-space: pre-wrap;
    }

    &-cta {
      border: none;
      background-color: transparent;
    }

    svg {
      vertical-align: middle;
      flex: 0 0 25px;
      &.type {
        width: 25px;
        height: 25px;
      }
      &.close {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      &.error {
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
        fill: $error-red;
        align-self: flex-start;
      }
    }
  }
}
</style>
