/* eslint-disable prettier/prettier */
<template lang="pug">
.c-users-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-um-overview')
        component(
          :is='selected === "admin" ? "c-um-sysadmin" : selected ? "c-um-user" : "c-um-overview"',
          :key='(selected && selected.id) || "overview"',
          :model='model'
        )
  template(v-else)
    c-um-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-um-user.o-transition__overlay(:model='model' v-if='selected')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationHeadline }}
    template(slot='body')
      .o-bodytext {{ confirmationDescription }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-cta.o-button--inverted(
              :cta-disabled='waitingForImpersonate',
              :cta-loading='waitingForImpersonate',
              :cta-text='Dictionary.Y.YesPlease'
              @click='handleLockConfirmationYes'
            )
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'

import UserManagementOverview from './um-overview'
import UserManagementUser from './um-user'
import UserManagementSysadmin from './um-sysadmin'
export default {
  name: 'c-users-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      waitingForImpersonate: false
    }
  },
  components: {
    'c-um-overview': UserManagementOverview,
    'c-um-user': UserManagementUser,
    'c-um-sysadmin': UserManagementSysadmin
  },
  computed: {
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    ...mapState('usersManagement', ['selected', 'users', 'confirmation']),
    confirmationTargetData() {
      return this.users.find(target => target.id === this.confirmation.targetId)
    },
    confirmationHeadline() {
      return this.confirmation.action === 'lock' ? this.lockConfirmationHeadlineText : this.imitateConfirmationHeadlineText
    },
    confirmationDescription() {
      return this.confirmation.action === 'lock' ? this.lockConfirmationDescriptionText : this.imitateConfirmationDescriptionText
    },
    lockConfirmationHeadlineText() {
      return !this.confirmationTargetData.locked
        ? this.model.lockUserConfirmationHeadline &&
            this.model.lockUserConfirmationHeadline.replace('{0}', `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`)
        : this.model.unlockUserConfirmationHeadline &&
            this.model.unlockUserConfirmationHeadline.replace('{0}', `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`)
    },
    lockConfirmationDescriptionText() {
      return !this.confirmationTargetData.locked
        ? this.model.lockUserConfirmationDescription &&
            this.model.lockUserConfirmationDescription.replace('{0}', `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`)
        : this.model.unlockUserConfirmationDescription &&
            this.model.unlockUserConfirmationDescription.replace('{0}', `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`)
    },
    imitateConfirmationHeadlineText() {
      const target =
        this.confirmationTargetData.firstName || this.confirmationTargetData.lastName
          ? `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`
          : this.confirmationTargetData.email
      return this.Dictionary.I.ImitateConfirmationHeadline && this.Dictionary.I.ImitateConfirmationHeadline.replace('{0}', target)
    },
    imitateConfirmationDescriptionText() {
      const target =
        this.confirmationTargetData.firstName || this.confirmationTargetData.lastName
          ? `${this.confirmationTargetData.firstName} ${this.confirmationTargetData.lastName}`
          : this.confirmationTargetData.email
      return this.Dictionary.I.ImitateConfirmationDescription && this.Dictionary.I.ImitateConfirmationDescription.replace('{0}', target)
    }
  },
  methods: {
    handleLockConfirmationYes() {
      if (this.confirmation.action === 'lock') {
        this.$store.dispatch('usersManagement/toggleUserLock', { id: this.confirmation.targetId, lock: !this.confirmationTargetData.locked })
      } else if (this.confirmation.action === 'imitate') {
        this.waitingForImpersonate = true
        window.location.assign(`/api/authentication/impersonate/${this.confirmation.targetId}`)
      }
    },
    handleConfirmationClose() {
      this.$store.dispatch('usersManagement/toggleConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-users-management {
}
</style>
