<template lang="pug">
.search-sub-menu.o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default
  .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-end.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-4.search-sub-menu__placeholder
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-4.o-heading.o-heading--size-3.search-sub-menu__count(
        :class='{ "c-search-result__count--hidden": mapShowing && getDeepResultsCount() <= 0 }'
        v-if='!mapShowing || !isMobileOnly'
      ) {{ resultsCountText }}
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-4.o-grid.o-grid--justify-content-end.o-grid--align-items-end.o-grid--gutter-tiny.search-sub-menu__actions
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            c-state-toggle(
              :options='[ { labelText: Dictionary.Search.MapView, icon: "pin" }, { labelText: Dictionary.Search.ListView, icon: "list" }, ]'
              action='toggleMap'
              property='mapShowing'
              store='search'
            )
          .o-grid__item.o-grid__item--grow-0(v-if='!mapShowing')
            c-state-sorting(
              :default-label-text='Dictionary.S.Sort',
              :options='sortingOptions'
              action='setSorting'
              order-by='orderBy'
              order-direction='orderDirection'
              store='search'
            )
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-search-sub-menu',
  data() {
    return {
      sortingOptions: [
        { value: { orderBy: 'Created', orderDirection: 'DESC', mixedResults: true }, labelText: this.Dictionary.I.InitialSorting },
        // { value: { orderBy: 'Number', orderDirection: 'ASC' }, labelText: `${this.Dictionary.N.Number} ${this.Dictionary.A.Ascending.toLowerCase()}` },
        // { value: { orderBy: 'Number', orderDirection: 'DESC' }, labelText: `${this.Dictionary.N.Number} ${this.Dictionary.D.Descending.toLowerCase()}` },
        // { value: { orderBy: 'Floor', orderDirection: 'ASC' }, labelText: `${this.Dictionary.F.Floor} ${this.Dictionary.A.Ascending.toLowerCase()}` },
        // { value: { orderBy: 'Floor', orderDirection: 'DESC' }, labelText: `${this.Dictionary.F.Floor} ${this.Dictionary.D.Descending.toLowerCase()}` },
        // { value: { orderBy: 'Door', orderDirection: 'ASC' }, labelText: `${this.Dictionary.D.Door} ${this.Dictionary.A.Ascending.toLowerCase()}` },
        // { value: { orderBy: 'Door', orderDirection: 'DESC' }, labelText: `${this.Dictionary.D.Door} ${this.Dictionary.D.Descending.toLowerCase()}` },
        {
          value: { orderBy: 'Created', orderDirection: 'ASC', mixedResults: false },
          labelText: `${this.Dictionary.N.Newest} ${this.Dictionary.A.Ascending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Created', orderDirection: 'DESC', mixedResults: false },
          labelText: `${this.Dictionary.N.Newest} ${this.Dictionary.D.Descending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Area', orderDirection: 'ASC', mixedResults: false },
          labelText: `${this.Dictionary.A.Area} ${this.Dictionary.A.Ascending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Area', orderDirection: 'DESC', mixedResults: false },
          labelText: `${this.Dictionary.A.Area} ${this.Dictionary.D.Descending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Rent', orderDirection: 'ASC', mixedResults: false },
          labelText: `${this.Dictionary.R.Rent} ${this.Dictionary.A.Ascending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Rent', orderDirection: 'DESC', mixedResults: false },
          labelText: `${this.Dictionary.R.Rent} ${this.Dictionary.D.Descending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Rooms', orderDirection: 'ASC', mixedResults: false },
          labelText: `${this.Dictionary.R.Rooms} ${this.Dictionary.A.Ascending.toLowerCase()}`
        },
        {
          value: { orderBy: 'Rooms', orderDirection: 'DESC', mixedResults: false },
          labelText: `${this.Dictionary.R.Rooms} ${this.Dictionary.D.Descending.toLowerCase()}`
        }
        // { value: { orderBy: 'Updated', orderDirection: 'ASC' }, labelText: `${this.Dictionary.U.Updated} ${this.Dictionary.A.Ascending.toLowerCase()}` },
        // { value: { orderBy: 'Updated', orderDirection: 'DESC' }, labelText: `${this.Dictionary.U.Updated} ${this.Dictionary.D.Descending.toLowerCase()}` },
        // { value: { orderBy: 'Year', orderDirection: 'ASC' }, labelText: `${this.Dictionary.Y.Year} ${this.Dictionary.A.Ascending.toLowerCase()}` },
        // { value: { orderBy: 'Year', orderDirection: 'DESC' }, labelText: `${this.Dictionary.Y.Year} ${this.Dictionary.D.Descending.toLowerCase()}` }
      ]
    }
  },
  computed: {
    ...mapState('search', ['results', 'mapResults', 'resultsCount', 'mapShowing', 'propertiesByCompanies']),
    resultsCountText() {
      if (this.mapShowing) {
        return `${this.getDeepResultsCount()} ${this.Dictionary.H.Housing.toLowerCase()}`
      } else {
        return `${this.resultsCount} ${this.propertiesByCompanies ? this.Dictionary.P.Properties.toLowerCase() : this.Dictionary.H.Housing.toLowerCase()}`
      }
    }
  },
  methods: {
    getDeepResultsCount: function () {
      let totalResults = 0
      this.mapResults.forEach(result => {
        totalResults += result.totalResults
      })
      return totalResults
    }
  },
  mounted() {
    // if (Object.keys(this.facets).length === 0 && this.facets.constructor === Object) this.$store.dispatch('search/getFacets')
  }
}
</script>
<style lang="scss" scoped>
.search-sub-menu {
  background-color: $white;
  @include min('desktop') {
    background-color: transparent;
    padding-top: 25px;
    padding-bottom: 25px;
    border: none;
  }

  &__placeholder {
    display: none;
    @include max('desktop', -1) {
      display: none;
    }
  }
  &__count {
    color: $ocean-darker;
    text-align: center;
    @include max('desktop', -1) {
      order: 2;
    }

    &--hidden {
      opacity: 0;
    }
  }
  &__actions {
    &::after {
      content: '';
      display: block;
      border-bottom: 1px solid $grey-4;
      margin: 0 -15px;
      padding: 0 15px;
      @include min('desktop') {
        display: none;
      }
    }

    .o-grid__item:last-child {
      margin-right: auto;
      @include min('desktop') {
        margin-right: 0;
      }
    }
  }
}
</style>
