<template lang="pug">
.c-om-details
  .o-grid.o-grid--align-items-starto-grid.o-grid--gutter-horizontal-normal
    .o-grid__group
      .o-grid__item.o-grid__item--7
        .c-om-details__form-section
          .c-om-details__form-heading.o-heading.o-heading--size-4 {{ model.offerTextHeadline }}
          c-fc-wysiwyg(:model='offerTextData' @validate='validate($event, "offerText")' v-model='offerTextValue')
        .c-om-details__form-section
          .c-om-details__form-heading.o-heading.o-heading--size-4 {{ model.offerShowingHeadline }}
          c-fc-wysiwyg(:model='showingTextData' @validate='validate($event, "showingText")' v-model='showingTextValue')
        .c-om-details__form-section
          .c-om-details__form-heading.o-heading.o-heading--size-4 {{ model.offerCorrectionRentHeadline }}
          .o-grid.o-grid--align-items-starto-grid.o-grid--gutter-horizontal-normal
            .o-grid__group 
              |
              |
              .o-grid__item.o-grid__item--8
                c-fc-text(
                  :model='correctionRentDescriptionData'
                  @validate='validate($event, "correctionRentDescription")'
                  v-model='correctionRentDescriptionValue'
                )
              .o-grid__item.o-grid__item--4
                c-fc-number(:model='correctionRentAmountData' @validate='validate($event, "correctionRentAmount")' v-model='correctionRentAmountValue')
        .c-om-details__form-section 
          |
          |
          .c-om-details__form-heading.o-heading.o-heading--size-4 {{ model.offerFinancialAndContractualNoteHeadline }}
          c-fc-textbox(:model='offerFinancialNoteData' @validate='validate($event, "offerFinancialNote")' v-model='offerFinancialNoteValue')
      .o-grid__item.o-grid__item--5(v-if='selectedResidence')
        .c-om-details__info
          .c-om-details__form-section
            .c-om-details__form-heading.o-bodytext.o-bodytext--size-large.o-bodytext--700 {{ model.offerEconomyHeadline }}
          .c-om-details__form-section
            table.c-om-details__finance-table
              tbody
                tr.c-om-details__finance-row.c-om-details__finance-row--first
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.M.MonthlyRentIncUtilities }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700.notranslate {{ totalUtilitiesAndRent | money }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.R.Rent }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalRentPerMonth | money }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.U.Utilities }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalUtilitiesPerMonth | money }}
          .c-om-details__form-section(v-if='utilityList.length')
            table.c-om-details__finance-table
              tbody
                tr.c-om-details__finance-row.c-om-details__finance-row--first
                  td.c-om-details__finance-text.c-om-details__finance-row--first
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.U.Utilities }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalUtilitiesPerMonth | money }}
                tr(:key='utility.key' v-for='utility in utilityList')
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ utility.description }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ utility.amount.Amount | money }}
          .c-om-details__form-section
            table.c-om-details__finance-table
              tbody
                tr.c-om-details__finance-row.c-om-details__finance-row--first
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.F.FirstPayment }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700.notranslate {{ totalFirstPayment | money }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.P.PrepaidRent }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalPrepaid | money }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Deposit }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalDeposit | money }}
                tr(v-if='correctionRentDescriptionData.value && correctionRentAmountData.value')
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ correctionRentDescriptionData.value }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ correctionRentAmountData.value | money }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.F.FirstMonthlyRent }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ totalUtilitiesAndRent | money }}
          .c-om-details__form-section
            table.c-om-details__finance-table
              tbody
                tr.c-om-details__finance-row.c-om-details__finance-row--first
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.C.Contractual }}
                  td.c-om-details__finance-price
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.T.TakeoverState }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small {{ takeOverStateLabels[selectedResidence.handoverCondition] }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ Dictionary.M.Modernization }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small {{ modernizationStateLabels[selectedResidence.renovationType] }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ this.Dictionary.R.RentalConditions }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small {{ rentalConditionStateLabels[selectedResidence.rentalCondition] }}
                tr
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small {{ this.Dictionary.F.Furnished }}
                  td.c-om-details__finance-price
                    span.o-bodytext.o-bodytext--size-small {{ furnishedStatelabels[selectedResidence.furnished] }}
          .c-om-details__form-section(v-if='selectedRes.financialsModel.rentIncreaseWarning !== null')
            table.c-om-details__finance-table
              tbody
                tr.c-om-details__finance-row.c-om-details__finance-row--first
                  td.c-om-details__finance-text
                    span.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ this.Dictionary.N.Notification }}
                  td.c-om-details__finance-price
                tr
                  td.c-om-details__finance-disclaimer
                    span.o-bodytext.o-bodytext--size-xsmall.o-bodytext--italic {{ selectedRes.financialsModel.rentIncreaseWarning }}
                  td.c-om-details__finance-price
</template>
<script>
import { mapState } from 'vuex'
import { residenceManagementService } from '@/_services'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllWysiwyg from '@/components/form/controlls/form-wysiwyg'
export default {
  name: 'c-om-details',
  props: {
    model: Object,
    stateBefore: {
      type: Function
    },
    stateAfter: {
      type: Function
    },
    validate: {
      type: Function
    }
  },
  data() {
    return {
      id: this._uid,
      validation: {},
      selectedRes: undefined,
      offerDetails: {
        offerText: {
          helperText: this.model.offerTextHelperText,
          placeholderText: this.model.offerTextPlaceholderText,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        showingText: {
          helperText: this.model.offerShowingHelperText,
          placeholderText: this.model.offerShowingPlaceholderText,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        correctionRentDescription: {
          helperText: this.model.offerCorrectionRentDescriptionHelperText,
          placeholderText: this.model.offerCorrectionRentDescriptionPlaceholderText,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        correctionRentAmount: {
          helperText: this.model.offerCorrectionRentAmountHelperText,
          placeholderText: 'DKK',
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: -99999
          }
        },
        offerFinancialNote: {
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        }
      },
      takeOverStateLabels: {
        AsSeen: this.Dictionary.HandoverCondition.AsSeen,
        NewlyRenovated: this.Dictionary.HandoverCondition.NewlyRenovated,
        WellMaintained: this.Dictionary.HandoverCondition.WellMaintained
      },
      modernizationStateLabels: {
        NotRenovated: this.Dictionary.RenovationTypes.NotRenovated,
        Paragraph_5_1: this.Dictionary.RenovationTypes.Paragraph51,
        Paragraph_5_2: this.Dictionary.RenovationTypes.Paragraph52,
        Paragraph_62B: this.Dictionary.RenovationTypes.Paragraph62B,
        RenovatedByCommuneAndState: this.Dictionary.RenovationTypes.RenovatedByCommuneAndState
      },
      rentalConditionStateLabels: {
        DeterminedByCost: this.Dictionary.RentalCondition.DeterminedByCost,
        RentedValue: this.Dictionary.RentalCondition.RentedValue,
        ByMarketRent: this.Dictionary.RentalCondition.ByMarketRent,
        BalanceRent: this.Dictionary.RentalCondition.BalanceRent,
        YouthHousin: this.Dictionary.RentalCondition.YouthHousin
      },
      furnishedStatelabels: {
        Furnished: this.Dictionary.FurnishedTypes.Furnished,
        NotFurnished: this.Dictionary.FurnishedTypes.NotFurnished,
        PartiallyFurnished: this.Dictionary.FurnishedTypes.PartiallyFurnished
      }
    }
  },
  components: {
    'c-fc-textbox': FormControllTextBox,
    'c-fc-text': FormControllText,
    'c-fc-number': FormControllNumber,
    'c-fc-wysiwyg': FormControllWysiwyg
  },
  computed: {
    ...mapState('offersManagement', { selectedMutableOffer: 'selectedMutable', selectedOffer: 'selected' }),
    ...mapState('residenceManagement', { selectedResidence: 'selected' }),
    ...mapState('loadingIndicator', ['loading']),
    offerTextData() {
      const offerTextData = Object.assign({}, this.offerDetails.offerText)
      offerTextData.value = this.offerTextValue
      offerTextData.readOnly = this.stateAfter('Changed')
      return offerTextData
    },
    offerTextValue: {
      get: function () {
        return this.selectedMutableOffer.offerText || ''
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { offerText: value })
      }
    },
    showingTextData() {
      const showingTextData = Object.assign({}, this.offerDetails.showingText)
      showingTextData.value = this.showingTextValue
      showingTextData.readOnly = this.stateAfter('Changed')
      return showingTextData
    },
    showingTextValue: {
      get: function () {
        return this.selectedMutableOffer.showingText || ''
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { showingText: value })
      }
    },
    correctionRentDescriptionValue: {
      get: function () {
        return this.selectedMutableOffer.correctionRentDescription || null
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { correctionRentDescription: value || null })
      }
    },
    correctionRentDescriptionData() {
      const correctionRentDescriptionData = Object.assign({}, this.offerDetails.correctionRentDescription)
      correctionRentDescriptionData.value = this.correctionRentDescriptionValue
      correctionRentDescriptionData.readOnly = this.stateAfter('Changed')
      return correctionRentDescriptionData
    },
    correctionRentAmountValue: {
      get: function () {
        return (this.selectedMutableOffer.correctionRentAmount && this.selectedMutableOffer.correctionRentAmount.Amount) || null
      },
      set: function (value) {
        const amount = value
          ? {
              Amount: value,
              isoCode: 208
            }
          : null
        this.$store.commit('offersManagement/updateMutableData', {
          correctionRentAmount: amount
        })
      }
    },
    correctionRentAmountData() {
      const correctionRentAmountData = Object.assign({}, this.offerDetails.correctionRentAmount)
      correctionRentAmountData.value = this.correctionRentAmountValue
      correctionRentAmountData.readOnly = this.stateAfter('Changed')
      return correctionRentAmountData
    },

    offerFinancialNoteValue: {
      get: function () {
        return this.selectedMutableOffer.financialAndContractualNotes || null
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { financialAndContractualNotes: value || null })
      }
    },

    offerFinancialNoteData() {
      const offerFinancialNoteData = Object.assign({}, this.offerDetails.offerFinancialNote)
      offerFinancialNoteData.value = this.offerFinancialNoteValue
      return offerFinancialNoteData
    },
    totalRentPerMonth() {
      let totalRentPerMonth = 0
      this.selectedResidence.rents.forEach(rent => {
        if (rent.isPartOfRent) {
          totalRentPerMonth = totalRentPerMonth + rent.amount.Amount
        }
      })
      return totalRentPerMonth
    },
    totalUtilitiesPerMonth() {
      let totalUtilitiesPerMonth = 0
      this.selectedResidence.rents.forEach(rent => {
        if (!rent.isPartOfRent) {
          totalUtilitiesPerMonth = totalUtilitiesPerMonth + rent.amount.Amount
        }
      })
      return totalUtilitiesPerMonth
    },
    totalNotIncludedDeposit() {
      let totalNotIncludedDeposit = 0
      this.selectedResidence.rents.forEach(rent => {
        if (rent.isPartOfRent && !rent.isPartOfDeposit) {
          totalNotIncludedDeposit = totalNotIncludedDeposit + rent.amount.Amount
        }
      })
      return totalNotIncludedDeposit
    },
    totalPrepaid() {
      return this.selectedResidence.monthsOfPrepaid ? this.selectedResidence.monthsOfPrepaid * (this.totalRentPerMonth - this.totalNotIncludedDeposit) : 0
    },
    totalDeposit() {
      return this.selectedResidence.monthsOfDeposit ? this.selectedResidence.monthsOfDeposit * (this.totalRentPerMonth - this.totalNotIncludedDeposit) : 0
    },
    totalUtilitiesAndRent() {
      return this.totalUtilitiesPerMonth + this.totalRentPerMonth
    },
    totalFirstPayment() {
      return this.totalPrepaid + this.totalDeposit + this.totalUtilitiesAndRent + (this.correctionRentAmountValue || 0)
    },
    utilityList() {
      return this.selectedResidence.rents.filter(x => !x.isPartOfRent)
    }
  },
  methods: {
    async getResidence() {
      try {
        const response = await residenceManagementService.getResidencePageModel(this.selectedOffer.residenceId)

        if (response && response.status === 200) {
          this.selectedRes = response.data
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  created() {
    this.$store.dispatch('residenceManagement/setSelected', { id: this.selectedMutableOffer.residenceId, companyId: this.selectedMutableOffer.companyId })
    this.getResidence()
  }
}
</script>
<style lang="scss" scoped>
.c-om-details {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__info {
    background-color: $grey-5;
    padding: 15px 30px 20px;
    margin-bottom: 20px;
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__finance {
    &-table {
      margin: 0;
      width: 100%;
    }
    &-row {
      td {
        padding-top: 5px;
      }
      &--first {
        td {
          padding-top: 0;
          padding-bottom: 10px;
          font-weight: 700;
        }
      }
    }
    &-disclaimer {
      > span {
        display: flex;
        flex-wrap: nowrap;
        &:before {
          content: '*';
          padding-right: 5px;
        }
      }
    }
    &-price {
      text-align: right;
    }
  }
}
</style>
