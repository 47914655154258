<template lang="pug">
.c-property-labels
  .c-property-labels__label(v-if='openHouseText') {{ openHouseText }}
  .c-property-labels__label(v-if='renovation') {{ Dictionary.U.UnderRenovation }}
</template>
<script>
import moment from 'moment'
export default {
  name: 'c-property-labels',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    openHouses: {
      type: Array
    },
    renovation: {
      type: Boolean
    }
  },
  computed: {
    openHouse() {
      let earlyerstOh

      const hasOpenHouses = this.openHouses && this.openHouses.length > 0
      if (hasOpenHouses) {
        this.openHouses.forEach(oh => {
          if (moment.utc().isBefore(oh.startDate)) {
            if (!earlyerstOh || moment.utc(oh.startDate).isBefore(earlyerstOh)) {
              earlyerstOh = oh
            }
          }
        })
      }

      return earlyerstOh
    },
    openHouseText() {
      let openHouseText = ''
      if (this.openHouse) {
        const openHouseModel = {
          date: this.formatDate(this.openHouse.startDate),
          from: moment.utc(this.openHouse.startDate).format('HH:mm'),
          to: moment.utc(this.openHouse.endDate).format('HH:mm')
        }
        openHouseText = this.Dictionary.O.OpenHouseLabel.replace('{date}', openHouseModel.date)
          .replace('{from}', openHouseModel.from)
          .replace('{to}', openHouseModel.to)
          .replace('{weekDay}', this.Dictionary.WeekDays[moment.utc(this.openHouse.startDate).isoWeekday()])
      }
      return openHouseText
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.c-property-labels {
  width: 100%;

  &__label {
    text-align: center;
    @include setStyles('14', $s-typography-sizes);
    padding: 4px;
    color: $white;
    background-color: $ocean-primary;
    font-family: 'Inria Serif', serif;
    height: 28px;
    white-space: nowrap;
  }
}
</style>
