<template lang="pug">
.c-profile-memberships
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      c-pm-organization(:model='model' v-if='selected')
      c-pm-overview(v-else)
        slot(name='pm-overview-heading' slot='pm-overview-heading')
        slot(name='pm-overview-description' slot='pm-overview-description')
  template(v-else)
    c-pm-overview
      slot(name='pm-overview-heading' slot='pm-overview-heading')
      slot(name='pm-overview-description' slot='pm-overview-description')
    transition(name='o-transition__slide-fade-right')
      c-pm-organization.o-transition__overlay(:model='model' v-if='selected')
  c-modal.c-profile-memberships__confirmation(
    :cornerClose='true',
    :darkTheme='revalidatedApplications === null'
    @close='closeConfirmation'
    v-if='confirmation'
  )
    template(slot='body')
      .c-profile-memberships__confirmation-body(v-if='associationRemoval')
        h3.c-profile-memberships__confirmation-heading.o-heading.o-heading--size-3(v-if='model.associationRemovalTitle') {{ model.associationRemovalTitle }}
        .c-profile-memberships__confirmation-text(v-html='associationRemovalTextFilled' v-if='associationRemovalTextFilled')
      .c-profile-memberships__confirmation-body(v-else-if='revalidatedApplications')
        h3.c-profile-memberships__confirmation-heading.o-heading.o-heading--size-3(v-if='model.validationTitle') {{ model.validationTitle }}
        .c-profile-memberships__confirmation-text(v-html='validationTextFilled' v-if='validationTextFilled')
        template(v-for='membershipOrganization in revalidatedApplications')
          .c-profile-memberships__confirmation-organization.o-grid.o-grid--align-items-center.o-grid--gutter-small.o-grid--wrap-nowrap
            .o-grid__group
              .o-grid__item.o-grid__item--4(v-if='getLogo(membershipOrganization.membershipOrganizationId)')
                img.c-profile-memberships__confirmation-logo.c-profile-memberships__confirmation-logo--small(
                  :src='getLogo(membershipOrganization.membershipOrganizationId)'
                  v-if='getLogo(membershipOrganization.membershipOrganizationId)'
                )
              .o-grid__item.o-grid__item--grow-0
                span.o-bodytext.o-bodytext--size-xsmall.o-bodytext--700 {{ membershipOrganization.membershipOrganizationName }}
          table.o-table.o-table--v-middle.c-profile-memberships__confirmation-table
            thead
              tr
                th {{ Dictionary.P.Picture }}
                th {{ Dictionary.P.PropertyName }}
                th {{ Dictionary.A.Address }}
                th {{ Dictionary.W.WaitingList }}
            tbody
              tr(v-for='property in membershipOrganization.unsubscribedPropertyApplications')
                td
                  img.c-profile-memberships__confirmation-logo.c-profile-memberships__confirmation-logo--small(
                    :src='property.propertyImage'
                    v-if='property.propertyImage'
                  )
                td {{ property.propertyName }}
                td {{ property.propertyAddress }}
                td {{ property.numberOfUnsubscribedApplications }}
      .c-profile-memberships__confirmation-body(slot='body' v-else)
        svg.o-svg-icon.c-profile-memberships__confirmation-checkmark(type='image/svg+xml' viewbox='0 0 35 35')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-checkmark' y='0')
          slot(name='pm-overview-confirmation-heading')
        h3.c-profile-memberships__confirmation-heading.o-heading.o-heading--size-3(v-if='model.verificationTitle') {{ model.verificationTitle }}
        .c-profile-memberships__confirmation-box
          img.c-profile-memberships__confirmation-logo(:src='confirmationTarget.logo')
          .c-profile-memberships__confirmation-text(v-html='confirmationTextFilled' v-if='confirmationTextFilled')
          a.o-button(:href='confirmationTarget.detailsLink' v-if='confirmationTarget.detailsLink') {{ Dictionary.S.ShowResidences }}
    template(slot='footer')
      .c-profile-memberships__confirmation-footer(v-if='revalidatedApplications || associationRemoval')
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12
              c-cta.o-button.o-button--inverted(
                :cta-disabled='associationRemoval && deleteProcessing',
                :cta-loading='associationRemoval && deleteProcessing',
                :cta-text='Dictionary.A.Approve'
                @click='proceedToAction'
              )
            .o-grid__item.o-grid__item--12
              span.o-link(@click='closeConfirmation') {{ Dictionary.C.Cancel }}
      .c-profile-memberships__confirmation-footer(v-else)
        button.c-profile-memberships__confirmation-skip.o-link.o-link--opensans.o-link--500.c-profile-memberships__confirmation-skip(
          @click='closeConfirmation'
        ) {{ Dictionary.S.Skip }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ProfileMembershipsOrganization from '@/components/profile/profile-memberships/pm-organization'
import ProfileMembershipsOverview from '@/components/profile/profile-memberships/pm-overview'

export default {
  name: 'c-profile-memberships',

  data() {
    return {}
  },
  components: {
    'c-pm-overview': ProfileMembershipsOverview,
    'c-pm-organization': ProfileMembershipsOrganization
  },
  props: {
    model: {
      required: true
    }
  },
  computed: {
    ...mapState('membershipOrganizations', ['selected', 'all', 'confirmation']),
    ...mapGetters('membershipOrganizations', ['deleteProcessing']),
    confirmationTextFilled() {
      return this.model.verificationDescription && this.confirmationTarget
        ? this.model.verificationDescription.replace('{0}', this.confirmationTarget.name)
        : undefined
    },
    associationRemovalTextFilled() {
      return this.model.associationRemovalDescription && this.confirmationTarget
        ? this.model.associationRemovalDescription.replace('{0}', this.confirmationTarget.name)
        : undefined
    },
    validationTextFilled() {
      let countApplications = 0
      this.revalidatedApplications.forEach(a => {
        a.unsubscribedPropertyApplications.forEach(p => (countApplications = countApplications + p.numberOfUnsubscribedApplications))
      })
      return this.model.validationDescription && this.model.validationDescription.replace('{0}', countApplications)
    },
    confirmationTarget() {
      return this.confirmation && this.all.find(organization => organization.id === this.confirmation.targetId)
    },
    revalidatedApplications() {
      return (this.confirmation.response && this.confirmation.response.data && this.confirmation.response.data.revalidatedApplications) || null
    },
    associationRemoval() {
      return this.confirmation.action === 'associationRemoval'
    }
  },
  methods: {
    getLogo(moId) {
      const targetMo = this.all.find(organization => organization.id === moId)
      return targetMo && targetMo.logo
    },
    closeConfirmation() {
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
    },
    submitApplication() {
      this.$store.dispatch('membershipOrganizations/submitApplication', this.confirmation.payload)
    },
    proceedToAction() {
      if (this.revalidatedApplications) {
        this.submitApplication()
      } else if (this.associationRemoval) this.deleteAssociation()
    },
    deleteAssociation() {
      this.$store.dispatch('membershipOrganizations/deleteAssociation', this.confirmation.targetId)
    }
  },
  created() {
    this.$store.dispatch('membershipOrganizations/setAll', this.model.membershipOrganizations)
  }
}
</script>
<style lang="scss" scoped>
.c-profile-memberships {
  $root: &;
  &__organization {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('header');
    width: 100%;
    height: 100%;
    @include min('desktop') {
      position: relative;
      z-index: unset;
      min-width: 0;
    }
  }

  &__organization {
    margin-bottom: 10px;
  }

  &__confirmation {
    text-align: center;

    &-checkmark {
      width: toRem(29px);
      height: toRem(26px);
      fill: $white;
      display: block;
      margin: 0 auto 20px;
    }
    &-heading {
      margin-bottom: 20px;
      &::last-child {
        margin-bottom: 0;
      }
    }

    &-organization {
      margin-bottom: toRem(10px);
      overflow: hidden;
    }
    &-logo {
      max-height: 80px;
      margin: 0 auto 10px;
      display: block;
      max-width: 150px;

      & + #{$root}__confirmation-heading {
        margin-top: 38px;
      }

      &--small {
        max-height: 50px;
        margin: 0 auto;
      }
    }
    &-table {
      margin-bottom: 10px;
    }
    &-text {
      margin-bottom: 25px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-box {
      background-color: $white;
      color: $ocean-primary;
      padding: toRem(22px) toRem(35px) toRem(40px);
    }
    &-body {
      @include max('desktop', -1) {
        margin: 0 -20px;
      }
    }

    &-footer {
      margin-top: 25px;
      &::first-child {
        margin-top: 0;
      }
      &-skip {
        background: transparent;
        border: none;
      }
    }
  }
}
</style>
