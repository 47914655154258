<template lang="pug">
.c-rm-residence
  .c-rm-residence__top
    button.c-rm-residence__close(@click='close')
      svg.c-rm-residence__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-rm-residence__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-rm-residence__content.o-transition__content
    h2.c-rm-residence__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.street ? combinedAddress : model.residenceTranslations.newResidenceHeadline }}
    c-rm-data(
      :model='model',
      :residence-translations='model.residenceTranslations',
      :selected='selected'
      @datachanged='dataChanged = $event'
      @validation='formIsValid = $event'
    )
      template(slot='tab')
        c-tab(
          :disabled='false',
          :disabled-message='model.residenceTranslations.waitingListDisableReason',
          :name='model.residenceTranslations.waitingListTabName'
          v-if='selected.id && selected.propertyId && selected.companyId'
        )
          vue-good-table(
            :columns='applicantColumns',
            :pagination-options='paginationSettingsData',
            :rows='applicants',
            :sort-options='{ enabled: true, initialSortBy: { field: "points", type: "desc" } }',
            :totalRows='applicantTotalResults'
            @on-column-filter='onColumnFilter'
            @on-page-change='onPageChange'
            @on-per-page-change='onPerPageChange'
            @on-sort-change='onSortChange'
            mode='remote'
            styleClass='o-table'
          )
            template(slot='emptystate') {{ model.noResultsText }}
            template(slot='table-row' slot-scope='props')
              template(v-if='props.column.field == "fullName"')
                button.c-rm-residence__applicant-name.o-bodytext.o-bodytext--size-small(@click='setSelectedApplicant(props.row.userId)') {{ getUserName(props.row) }}
              template(v-else-if='props.column.field == "category"')
                span {{ getApplicantsCategoryInMembershipOrganization(props.row) }}
              template(v-else-if='props.column.field == "points"')
                span {{ getWaitingListPoints(props.row, selected.organizationId, selected.propertyId) }}
              template(v-else-if='props.column.field == "verificationStatus"')
                svg.c-rm-residence__applicant-icon.o-svg-icon(
                  :class='[getVerificationStatus(props.row, selected.organizationId)]'
                  type='image/svg+xml'
                  viewbox='0 0 42 34'
                )
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
              template(v-else) {{ props.formattedRow[props.column.field] }}
        c-tab(
          :disabled='false',
          :disabled-message='model.residenceTranslations.ownersTabName',
          :name='model.residenceTranslations.ownersTabName'
          v-if='selected.managedExternally'
        )
          c-rm-owners(:model='model')
      c-modal.c-modal--search(:cornerClose='true' @close='handleCloseApplicant' v-if='applicantSelected')
        template(slot='body')
          transition(mode='out-in' name='o-transition__fade')
            c-recipient-details(:is-residence-details='true', :reward-data='selected')
</template>
<script>
import { mapState } from 'vuex'
import ResidenceData from '../rm-data'
import ResidenceOwners from '../rm-owners'
import cRecipientDetails from '@/components/recipient-details'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-rm-residence',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-rm-data': ResidenceData,
    'c-rm-owners': ResidenceOwners,
    'c-recipient-details': cRecipientDetails,
    VueGoodTable
  },
  data() {
    return {
      applicantColumns: [
        {
          label: this.Dictionary.N.Name,
          field: 'fullName',
          sortable: true
        },
        {
          label: '',
          field: 'verificationStatus',
          width: '48px',
          sortable: false
        },
        {
          label: this.Dictionary.N.Number,
          field: 'applicantNumber',
          sortable: true
        },
        {
          label: this.Dictionary.C.Category,
          field: 'category',
          sortable: false
        },
        {
          label: this.Dictionary.P.Points,
          field: 'points',
          sortable: true
        },
        {
          label: `${this.Dictionary.W.WaitingListStatus} ${this.Dictionary.R.Residence}`,
          field: 'applications',
          formatFn: this.getWaitingListStatusResidence,
          sortable: false
        },
        {
          label: `${this.Dictionary.W.WaitingListStatus} ${this.Dictionary.C.Company}`,
          field: 'activeCompanyIds',
          formatFn: this.getWaitingListStatusCompany,
          sortable: false
        }
      ],

      id: this._uid,
      formIsValid: false,
      dataChanged: false
    }
  },
  computed: {
    ...mapState('residenceManagement', ['selected']),
    ...mapState('applicantsManagement', {
      applicantSelected: 'selected',
      applicants: 'applicants',
      applicantTotalResults: 'totalResults',
      applicantsServerParams: 'serverParams'
    }),
    ...mapState('loadingIndicator', ['loading']),
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.applicantsServerParams.page })
      return paginationSettingsData
    },
    combinedAddress() {
      return this.combineAddress(
        this.selected.street,
        this.selected.number,
        this.selected.letter,
        this.selected.floor,
        this.selected.door,
        this.selected.postalCode,
        this.selected.city
      )
    }
  },
  methods: {
    reFormatPercentage(value) {
      return value ? `${value.toFixed()} %` : ''
    },
    getWaitingListStatusResidence(value) {
      return value[this.selected.id] && this.Dictionary.ResidenceApplicationStatus[value[this.selected.id]]
    },
    getWaitingListStatusCompany(value) {
      return value && value.includes(this.selected.companyId) ? this.Dictionary.A.Active : this.Dictionary.P.Passive
    },
    getApplicantsCategoryInMembershipOrganization(value) {
      return (value.categories[this.selected.organizationId] && value.categories[this.selected.organizationId].toString()) || ''
    },
    setSelectedApplicant(id) {
      this.$store.dispatch('applicantsManagement/setSelected', { id })
    },
    handleCloseApplicant() {
      this.$store.dispatch('applicantsManagement/removeSelected')
    },
    handleDelete() {
      this.$store.dispatch('residenceManagement/toggleDeleteConfirmation', this.selected.id)
    },
    close() {
      this.$store.dispatch('residenceManagement/removeSelected')
      this.$store.commit('applicantsManagement/reset')
    },
    updateParams(newProps) {
      this.serverParams = Object.assign(this.applicantsServerParams, newProps)
    },

    onPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { page: params.currentPage })
    },
    onPerPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      let fieldName = params[0].field
      if (fieldName === 'points') {
        fieldName = 'PropertyPoints_' + this.selected.propertyId + ',' + this.selected.id
      }

      this.$store.commit('applicantsManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: fieldName
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('applicantsManagement/updateParams', params)
    },
    handleApplicantsSearch() {
      const data = {
        filters: {
          activeCompanyIds: [this.selected.companyId],
          companyIds: [this.selected.companyId],
          residences: this.selected.id
        }
      }

      this.$store.dispatch('applicantsManagement/searchApplicants', data)
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  },
  created() {
    if (this.selected.id) {
      this.$store.commit('applicantsManagement/updateParams', { page: 1 })
    }
  },
  watch: {
    applicantsServerParams: {
      deep: true,
      handler() {
        this.handleApplicantsSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-rm-residence {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      @include min('desktop') {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__finantial-summary {
    background-color: $grey-5;
    padding: 15px 20px;
    &-table {
      width: 100%;
      .separator {
        padding: 0;
        background-color: $grey-3;
        height: 1px;
      }
      td {
        padding: 5px 0;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          text-align: right;
          width: 40%;
        }
      }
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
  &__applicant {
    &-name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
    &-icon {
      @include min('desktop') {
        width: 28px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__owners {
    &-description {
      margin-top: 15px;
    }
  }
}
</style>
