<template lang="pug">
.c-mu-section
  .c-mu-section__title.o-heading.o-heading--size-4 {{ translations.title }}
  .c-mu-section__description.o-bodytext.o-bodytext--size-small {{ translations.description }}
  c-notification-inline.c-mu-section__information(
    :canClose='false',
    :html='translations.information'
    justify='left'
    size='small'
    v-if='translations.information'
  )
  template(v-if='mediaType === "zip"')
    c-zip.c-mu-section__input(@unziped='handleUnziped')
    label.c-mu-section__line-item(:id='`${id}-uploadError`', :key='key' v-for='(errorFile, key) in errorFiles')
      svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .c-mu-section__line-item-info
        .c-mu-section__line-item-name.o-bodytext.o-bodytext--size-small {{ errorFile.file.name }}
        //- .c-mu-section__line-item-size.o-bodytext.o-bodytext--size-xsmall ({{ errorFile.file.size | prettyBytes(1, false, "MB") }})
        .c-mu-section__line-item-error.o-bodytext.o-bodytext--size-xsmall {{ errorMessage(errorFile) }}
  template(v-if='mediaType === "panoramas"')
    .o-grid.o-grid--gutter-small.o-grid--align-items-end.c-mu-section__input
      .o-grid__group
        .o-grid__item
          .o-input__container
            label.o-label.o-label--before(:for='`${id}-panoramaLink`' v-if='translations && translations.label') {{ translations.label }}
            .o-input__wrap
              input.o-input(:id='`${id}-panoramaLink`' name='panoramaInput' placeholder=' ' type='text' v-model='panoramaLink')
              label.o-label.o-label--placeholder(:for='`${id}-panoramaLink`' v-if='translations && translations.placeholder') {{ translations.placeholder }}
        .o-grid__item.o-grid__item--grow-0
          button.o-button.c-mu-section__video-item-upload(@click='panoramaLinkAdd')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
  template(v-if='mediaType === "videos"')
    .o-grid.o-grid--gutter-small.o-grid--align-items-end.c-mu-section__input
      .o-grid__group
        .o-grid__item
          .o-input__container
            label.o-label.o-label--before(:for='`${id}-videoYoutube`' v-if='translations && translations.labelYoutube') {{ translations.labelYoutube }}
            .o-input__wrap
              input.o-input(:id='`${id}-videoYoutube`' name='videoInput' placeholder=' ' type='text' v-model='youtubeId')
              label.o-label.o-label--placeholder(:for='`${id}-videoYoutube`' v-if='translations && translations.placeholderYoutube') {{ translations.placeholderYoutube }}
        .o-grid__item.o-grid__item--grow-0
          button.o-button.c-mu-section__video-item-upload(@click='videosAdd("youtube")')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
    .o-grid.o-grid--gutter-small.o-grid--align-items-end.c-mu-section__input
      .o-grid__group
        .o-grid__item
          .o-input__container
            label.o-label.o-label--before(:for='`${id}-videoVimeo`' v-if='translations && translations.labelVimeo') {{ translations.labelVimeo }}
            .o-input__wrap
              input.o-input(:id='`${id}-videoVimeo`' name='videoInput' placeholder=' ' type='text' v-model='vimeoId')
              label.o-label.o-label--placeholder(:for='`${id}-videoVimeo`' v-if='translations && translations.placeholderVimeo') {{ translations.placeholderVimeo }}
        .o-grid__item.o-grid__item--grow-0
          button.o-button.c-mu-section__video-item-upload(@click='videosAdd("vimeo")')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
  .c-mu-section__list(v-if='mediaType !== "zip"')
    .o-grid.o-grid--gutter-small(v-if='mediaType === "videos"')
      draggable.o-grid__group(
        :disabled='disableDraggable',
        :group='group',
        :list='list'
        @change='handleChange(mediaType, $event)'
        @start='dragging = true'
        draggable='.c-mu-section__draggable'
      )
        .o-grid__item.o-grid__item--12.c-mu-section__draggable(
          :class='[{ "c-mu-section__draggable--disabled": disableDraggable }]',
          :key='item.id'
          v-for='(item, key) in list'
        )
          .c-mu-section__line-item
            svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-play' y='0')
            .c-mu-section__line-item-info
              a.c-mu-section__line-item-name.o-bodytext.o-bodytext--size-small(:href='item.path' target='blank') {{ item.path }}
            button.c-mu-section__line-item-cta(:aria-label='`${Dictionary.D.Delete} ${item.path}`' @click='filesRemove(item.id)')
              svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
    .o-grid.o-grid--gutter-small(v-else)
      draggable.o-grid__group.c-mu-section__draggable-container(
        :class='[{ "c-mu-section__draggable-container--empty": list && list.length === 0 }]',
        :disabled='disableDraggable',
        :emptyInsertThreshold='100',
        :group='{ name: group, put: true }',
        :list='list'
        @change='handleChange(mediaType, $event)'
        @start='dragging = true'
        draggable='.c-mu-section__draggable'
      )
        .o-grid__item.o-grid__item--6.c-mu-section__draggable(
          :class='[{ "c-mu-section__draggable--disabled": disableDraggable }]',
          :key='item.id'
          v-for='(item, key) in list'
        )
          .c-mu-section__media-item.c-mu-section__media-item--file(
            :class='[{ "c-mu-section__media-item--360": !itemIsFile(item.path) }]',
            :style='{ "background-image": itemIsFile(item.path) ? `url(${item.path})` : null }'
          )
            .c-mu-section__media-item-wrap(v-if='!itemIsFile(item.path)')
              .c-mu-section__upload-icon
                svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-360-view' y='0')
              .o-bodytext.o-bodytext--size-xxsmall {{ item.path }}
            .c-mu-section__media-counter.o-bodytext--size-small.o-bodytext--700(v-if='!singleItem') {{ key + 1 }}
            button.c-mu-section__media-delete(@click='filesRemove(item.id)')
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-cross' y='0')
          .c-mu-section__media-first-helper.o-bodytext.o-bodytext--size-xsmall.o-bodytext--inria.o-bodytext--italic(
            v-if='!singleItem && key === 0 && translations.firstHelper'
          ) {{ translations.firstHelper }}

      .c-mu-section__footer.o-grid.o-grid--gutter-small(v-if='!singleItem ? true : list && list.length < 1')
        .o-grid__group
          .o-grid__item.o-grid__item--12.o-input__container
            input.u-visually-hidden.o-input(
              :accept='acceptableFormats.toString()',
              :id='`${id}-fileUpload`'
              @change='filesAdd($event.target.files, $event.target)'
              multiple='multiple'
              name='fileInput'
              placeholder=' '
              type='file'
            )
            label.o-label.c-mu-section__media-item.c-mu-section__media-item--upload(:for='`${id}-fileUpload`')
              .c-mu-section__media-item-wrap.c-mu-section__media-item-wrap--upload
                .c-mu-section__upload-icon
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-camera' y='0')
                .o-bodytext {{ translations.upload }}
            label.c-mu-section__line-item(:id='`${id}-uploadError`', :key='key' v-for='(errorFile, key) in errorFiles')
              svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
              .c-mu-section__line-item-info
                .c-mu-section__line-item-name.o-bodytext.o-bodytext--size-small {{ errorFile.file.name }}
                //- .c-mu-section__line-item-size.o-bodytext.o-bodytext--size-xsmall ({{ errorFile.file.size | prettyBytes(1, false, 'MB') }})
                .c-mu-section__line-item-error.o-bodytext.o-bodytext--size-xsmall {{ errorMessage(errorFile) }}
    .c-mu-section__media-helper.o-bodytext.o-bodytext--size-xsmall.o-bodytext--inria.o-bodytext--italic(v-if='translations.helper && list && list.length > 0') {{ translations.helper }}
</template>
<script>
import cZip from '@/components/zip/index'
import draggable from 'vuedraggable'
export default {
  name: 'c-mu-section',
  components: {
    draggable,
    cZip
  },
  data() {
    return {
      id: this._uid,
      acceptableFormats: ['image/png', 'image/jpg', 'image/jpeg'],
      maxFileSize: 31457280,
      minFileSize: 5120,
      maxFileDimensions: 25000,
      minFileDimensions: 100,
      dragging: false,
      panoramaLink: '',
      youtubeId: '',
      vimeoId: '',
      errorFiles: []
    }
  },
  props: {
    list: {
      type: Array
    },
    mediaType: {
      type: String,
      required: true
    },
    translations: {
      type: Object
    },
    stateManagement: {
      type: Object,
      required: true
    },
    group: {
      type: String
    },
    scrollTo: {
      type: String
    },
    maxItems: {
      type: Number
    }
  },
  computed: {
    disableDraggable() {
      return this.singleItem
    },
    singleItem() {
      return this.maxItems === 1
    }
  },
  methods: {
    itemIsFile(path) {
      return this.isFile(path)
    },
    errorMessage(errorFile) {
      const errorMessage = []
      if (!errorFile.fileSizeOK) {
        errorMessage.push(
          this.translations.validationSize &&
            this.translations.validationSize
              .replace('{0}', this.$options.filters.prettyBytes(errorFile.file.size))
              .replace('{1}', this.$options.filters.prettyBytes(this.minFileSize))
              .replace('{2}', this.$options.filters.prettyBytes(this.maxFileSize))
        )
      }
      if (!errorFile.fileTypeOK) {
        errorMessage.push(
          this.translations.validationFormat &&
            this.translations.validationFormat.replace('{0}', errorFile.file.type).replace('{1}', this.acceptableFormats.toString())
        )
      }
      if (!errorFile.fileDimensionsOK) {
        errorMessage.push(
          this.translations.validationDimensions &&
            this.translations.validationDimensions
              .replaceAll('{0}', `${errorFile.image.width}px x ${errorFile.image.height}px`)
              .replaceAll('{1}', this.minFileDimensions)
              .replaceAll('{2}', this.maxFileDimensions)
        )
      }

      return errorMessage.toString()
    },
    async validateFiles(fileList) {
      this.errorFiles = []
      const validFiles = []

      const cloneFileList = [...fileList]

      while (cloneFileList.length) {
        const file = cloneFileList.pop()
        const fileTypeOK = this.acceptableFormats.includes(file.type)
        const fileSizeOK = file.size <= this.maxFileSize && file.size >= this.minFileSize
        const image = await this.readImage(file)
        const fileDimensionsOK =
          image.width <= this.maxFileDimensions &&
          image.width >= this.minFileDimensions &&
          image.height <= this.maxFileDimensions &&
          image.height >= this.minFileDimensions
        const fileIsValid = fileTypeOK && fileSizeOK && fileDimensionsOK

        if (fileIsValid) {
          validFiles.push(file)
        } else {
          const failedFile = { fileSizeOK, fileTypeOK, fileDimensionsOK, file, image }

          this.errorFiles.push(failedFile)
        }
      }
      return validFiles
    },
    async filesAdd(fileList, target) {
      const type = this.mediaType === 'zip' ? 'images' : this.mediaType
      const validatedList = await this.validateFiles(fileList)
      if (validatedList.length > 0) {
        this.$store.dispatch(`${this.stateManagement.store}/${this.stateManagement.actionAdd}`, { type, media: validatedList })
      }

      if (target) target.value = ''

      this.scrollToSection()
    },
    scrollToSection() {
      if (this.scrollTo) {
        window.scrollTo({
          top: this.$parent.$refs['section-images'][0].$el.offsetTop - 100,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    filesRemove(id) {
      this.$store.dispatch(`${this.stateManagement.store}/${this.stateManagement.actionRemove}`, { type: this.mediaType, mediaId: id })
    },
    getVideoProvider(url) {
      const isYoutube = /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/.test(url)
      const isVimeo = /^(https?:\/\/)?((www\.)?vimeo\.com)\/.+$/.test(url)
      return isYoutube ? 'youtube' : isVimeo ? 'vimeo' : undefined
    },
    videosAdd(provider) {
      const url =
        provider === 'youtube'
          ? `https://www.youtube.com/embed/${this.youtubeId}`
          : provider === 'vimeo'
          ? `https://player.vimeo.com/video/${this.vimeoId}`
          : undefined

      this.$store.dispatch(`${this.stateManagement.store}/${this.stateManagement.actionAdd}`, { type: this.mediaType, media: url })
    },
    panoramaLinkAdd() {
      this.$store.dispatch(`${this.stateManagement.store}/${this.stateManagement.actionAdd}`, {
        type: this.mediaType,
        media: this.panoramaLink,
        panoramaLink: true
      })
    },
    handleUnziped(files) {
      this.filesAdd(files)
    },
    handleChange(mediaCategory, e) {
      this.dragging = false
      const mediaType = mediaCategory.substring(0, mediaCategory.length - 1)
      if (e.added) {
        const mediaIndex = e.added.newIndex
        const mediaId = this.list[mediaIndex].id

        this.handleCategoryChange(mediaId, mediaCategory, mediaIndex, mediaType)
      } else if (e.moved) {
        const mediaIndex = e.moved.newIndex
        const mediaId = this.list[mediaIndex].id

        this.handlePosition(mediaId, mediaCategory, mediaIndex)
      }
    },
    handlePosition(mediaId, mediaCategory, mediaIndex) {
      this.$store
        .dispatch(`${this.stateManagement.store}/${this.stateManagement.actionMove}`, { mediaId, mediaCategory, mediaIndex })
        .then(() => {})
        .catch(() => {})
    },
    handleCategoryChange(mediaId, mediaCategory, mediaIndex, mediaType) {
      this.$store
        .dispatch(`${this.stateManagement.store}/${this.stateManagement.actionMove}`, { mediaId, mediaIndex, mediaCategory, mediaType })
        .then(() => {})
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.c-mu-section {
  padding-bottom: 30px;
  border-bottom: 1px solid $grey-5;

  &:last-child {
    border-bottom: none;
  }
  &__title {
  }
  &__description {
    margin-top: 15px;
  }
  &__information {
    margin-top: 15px;
  }

  &__list {
    margin-top: 25px;
  }

  &__draggable:not(.c-mu-section__draggable--disabled) {
    cursor: pointer;

    &:hover {
      > div:first-child {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__footer {
    margin-top: 20px;
  }

  &__draggable-container {
    &--empty {
      position: relative;
      min-height: 195px;
      &:before,
      &:after {
        content: '';
        display: block;
        width: calc(100% - 20px);
        height: calc(100px - 20px);
        position: absolute;
        padding-top: 175px;
        pointer-events: none;
      }
      &:before {
        content: '';
        display: block;
        width: calc(100% - 20px);
        height: calc(100px - 20px);
        margin: 10px;
        outline: 2px solid $white;
        outline-offset: -2px;
        background: $grey-5;
      }
      &:after {
        top: 10px;
        left: 10px;
        outline: 2px dashed $grey-3;
        outline-offset: -2px;
        background-image: url(map-get($sprites, 'icon-image'));
        background-position: center;
        background-repeat: no-repeat;
        background-size: 55px;
      }
    }
  }

  &__media {
    &-delete {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(-50%, -50%);
      border: none;
      background: $ocean-primary;
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
        display: block;
        fill: $white;
        stroke: $white;
      }
    }

    &-helper {
      margin-top: 30px;
      padding-top: 10px;
      text-align: center;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 20%;
        height: 1px;
        background-color: $grey-4;
        transform: translateY(-10px);
      }
    }

    &-first-helper {
      margin-top: 5px;
    }

    &-counter {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $crab-primary;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      min-width: 100%;
      // min-height: 100%;
      padding-top: 56.25%; //16:9
      position: relative;
      display: block;
      border: 1px solid $grey-3;

      &--file {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &--360 {
        background-color: $grey-5;
      }

      &--dummy {
        outline: 2px solid $white;
        outline-offset: -2px;
        background: $grey-5;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          outline: 2px dashed $grey-3;
          outline-offset: -2px;
        }

        svg {
          width: 55px;
          height: 55px;
          fill: $ocean-primary;
        }
      }
      &--upload {
        background: $ocean-primary;
        color: $white;
        padding: 30px;
        margin-bottom: 10px;
      }

      &-wrap {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 100%;

        .o-bodytext {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          max-width: 80%;
        }
        svg {
          width: 45px;
          height: 45px;
          fill: $ocean-primary;
          display: block;
        }

        &--upload {
          flex-direction: row;
          svg {
            fill: $white;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__input {
    margin-top: 20px;

    + label {
      margin-top: 10px;
    }
  }

  &__line-item {
    background-color: $grey-5;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    & + & {
      margin-top: 5px;
    }
    // #{$root}__file-list--uploaded & {
    //   display: inline-flex;
    //   border: none;
    //   padding: 10px 15px;
    //   margin: 5px;

    //   &:last-child {
    //     border-bottom: none;
    //   }
    // }

    &-info {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      margin: 0 5px;
      max-width: calc(100% - 65px);

      // #{$root}__file-list--uploaded & {
      //   margin: 0 0 0 5px;
      //   max-width: calc(100% - 25px);
      // }
    }
    &-name {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: $ocean-primary;

      // #{$root}__file-list--uploaded & {
      //   max-width: unset;
      // }
    }

    &-error {
      flex: 1 0 100%;
      color: $error-red;
      white-space: pre-wrap;
    }

    &-cta {
      border: none;
      background-color: transparent;
    }

    svg {
      vertical-align: middle;
      flex: 0 0 25px;
      &.type {
        width: 25px;
        height: 25px;
      }
      &.close {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      &.error {
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
        fill: $error-red;
        align-self: flex-start;
      }
    }
  }

  &__video-item {
    &-upload {
      background: $ocean-primary;
      color: $white;
      position: relative;
      height: 53px;
      width: 100%;

      svg {
        width: 40px;
        height: 40px;
        fill: $white;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
