<template lang="pug">
#map.single-property-map
  .o-wrap.o-wrap--padding-side-default.o-wrap--max-width-content-wide
    h2.o-heading--size-2.o-heading.single-property-map__headline {{ headline }}
  .single-property-map__container
    GmapMap(
      :center={ lat: 55.58, lng: 9.656 },
      :options={ zoomControl: true, mapTypeControl: false, draggable: true, fullscreenControl: false, streetViewControl: false },
      :zoom='13'
      ref='mapObj'
    )
      GmapMarker(:clickable='false', :draggable='false', :icon='getMarker()', :position='{ lat: lat, lng: lng }')
    details.c-gmap__poi
      summary.c-gmap__poi-legend I n&aelig;romr&aring;det
      fieldset.c-gmap__poi-fieldset(data-poi='')
        label.c-gmap__poi-label(v-for='(poiType, key) in poiTypes')
          input(:id='poiType.type', :value='poiType.type' @change='setPoi(poiType.type)' hidden='' type='checkbox' v-model='checkedPoiTypes')
          i.c-gmap__poi-icon(:style='getIcon(poiType)')
          span.c-gmap__poi-text {{ poiType.text }}
</template>

<script>
export default {
  name: 'c-single-property-map',

  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
    headline: {
      type: String,
      required: false
    }
  },

  data: function () {
    return {
      gMap: '',
      mapIsLoaded: false,
      styledMapType: {},
      checkedPoiTypes: [],
      poiTypes: [
        {
          type: 'poi.school',
          icon: 'background-image: var(--ico-school);',
          iconActive: 'background-image: var(--ico-school-active);',
          text: this.Dictionary.Map.Schools
        },
        {
          type: 'poi.business',
          icon: 'background-image: var(--ico-business);',
          iconActive: 'background-image: var(--ico-business-active);',
          text: this.Dictionary.Map.Shops
        },
        {
          type: 'transit',
          icon: 'background-image: var(--ico-transport);',
          iconActive: 'background-image: var(--ico-transport-active);',
          text: this.Dictionary.Map.Transportation
        },
        {
          type: 'poi.park',
          icon: 'background-image: var(--ico-park);',
          iconActive: 'background-image: var(--ico-park-active);',
          text: this.Dictionary.Map.Landscape
        },
        {
          type: 'poi.medical',
          icon: 'background-image: var(--ico-health);',
          iconActive: 'background-image: var(--ico-health-active);',
          text: this.Dictionary.Map.Health
        },
        {
          type: 'poi.sports_complex',
          icon: 'background-image: var(--ico-sport);',
          iconActive: 'background-image: var(--ico-sport-active);',
          text: this.Dictionary.Map.Sport
        }
      ]
    }
  },

  methods: {
    getIcon(poiType) {
      return this.checkedPoiTypes.indexOf(poiType.type) === -1 ? poiType.icon : poiType.iconActive
    },
    setPoi: function () {
      this.styledMapType.styles.forEach((type, key) => {
        if (this.checkedPoiTypes.indexOf(type.featureType) >= 0) {
          this.styledMapType.styles[key].stylers[0].visibility = 'on'
        } else {
          this.styledMapType.styles[key].stylers[0].visibility = 'off'
        }
      })
      this.gMap.setOptions({ styles: this.styledMapType.styles })
    },
    getMarker: function () {
      if (this.mapIsLoaded) {
        const iconSrc =
          '<svg version="1.1" id="Icons_expanded" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 42 34" style="enable-background:new 0 0 42 34;" xml:space="preserve"><style type="text/css">.st0{fill:#2C4856;}.st1{fill:#FFFFFF;}</style><path class="st0" d="M31.4,12.9c0-5.7-4.6-10.3-10.3-10.3S10.7,7.3,10.7,12.9c0,1.8,0.5,3.5,1.3,5.1c1.8,4.1,6.7,6.9,8,12.7c0.2,0.5,0.7,0.8,1.2,0.7c0.3-0.1,0.6-0.4,0.7-0.7c1.4-5.8,6.2-8.7,8-12.7C30.9,16.5,31.4,14.7,31.4,12.9z"/><path class="st1" d="M21,9l3.2,2.8v4.5h-6.4v-4.5L21,9 M21,7.7l-4.1,3.7v6h8.4v-6L21,7.7z"/></svg>'

        return {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(iconSrc),
          size: new window.google.maps.Size(200, 200),
          scaledSize: new window.google.maps.Size(50, 50),
          anchor: new window.google.maps.Point(25, 50),
          labelOrigin: new window.google.maps.Point(25, 50)
        }
      }
    }
  },

  computed: {},

  mounted() {
    this.$refs.mapObj.$mapPromise
      .then(map => {
        this.gMap = map
        this.mapIsLoaded = true
        map.setCenter(new window.google.maps.LatLng(this.lat, this.lng))

        this.styledMapType = new window.google.maps.StyledMapType(
          [
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.medical',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.park',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.school',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.sports_complex',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit',
              stylers: [{ visibility: 'off' }]
            }
          ],
          { name: 'Custom Map' }
        )

        this.gMap.mapTypes.set('styled-map', this.styledMapType)
        this.gMap.setMapTypeId('styled-map')
      })
      .catch(() => {
        // console.log('err', err)
      })
  }
}
</script>

<style lang="scss" scoped>
.single-property-map {
  display: block;
  margin: toRem(20px) 0;

  @include min('desktop') {
    margin: toRem(60px) 0;
  }

  &__headline {
    display: block;
    margin-bottom: toRem(20px);

    @include min('desktop') {
      text-align: center;
    }
  }

  &__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    position: relative;

    @include min('desktop') {
      padding-bottom: 45%;
    }

    .vue-map-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.c-gmap__poi {
  --poi-m: 1rem;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  font-size: 13px;
  padding: 0.65em 1em;
  position: absolute;
  right: var(--poi-m);
  top: var(--poi-m);
  width: 150px;
}
.c-gmap__poi-fieldset {
  border: 0;
  margin: 1em 0 0 0;
  padding: 0;
}
.c-gmap__poi-icon {
  --bgsz: var(--icon-size, 80%);
  --m: 0 0.5em 0 0;
  --w: var(--size, 1.5rem);
  // background-color: var(--lbl-c, currentColor);
  background-image: var(--ico-transport);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: var(--w);
  margin: var(--m);
  mask: no-repeat center center/var(--bgsz) var(--icon);
  -webkit-mask: no-repeat center center/var(--bgsz) var(--icon);
  width: var(--w);
}
.c-gmap__poi-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0.25em 0;
}
.c-gmap__poi-legend {
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  user-select: none;
}
.c-gmap__poi-text {
  color: var(--lbl-c);
  user-select: none;
}
.c-gmap__poi-label > input:checked ~ .c-gmap__poi-icon,
.c-gmap__poi-label > input:checked ~ .c-gmap__poi-text {
  --lbl-c: var(--lbl-c--checked);
}
</style>
