import apiClient from '@/_helpers/api-client'
export const offersManagementService = {
  searchOffers,
  getAllOffers,
  getOffersCompany,
  getOffersIds,
  getOffer,
  getOfferPosition,
  createOffer,
  patchOffer,
  deleteOffer,
  publishOffer,
  finishOffer,
  awardOffer,
  awardExternallyOffer,
  retireAwardedOffer,
  releaseOffer,
  extendOffer,
  setRecipientsState,
  acceptOffer,
  declineOffer,
  getRecipientsPendingOffers,
  declineRecipientsOffer,
  cancelAwardedOffer
}

async function searchOffers(data) {
  return await apiClient({
    url: '/search/offers',
    method: 'POST',
    data
  })
}
async function getAllOffers() {
  return await apiClient({
    url: '/data/offers',
    method: 'GET'
  })
}
async function getOffersCompany({ companyId, residenceId }) {
  return await apiClient({
    url: `/offers/company/${companyId}/for/${residenceId}`,
    method: 'GET'
  })
}
async function getOffersIds() {
  return await apiClient({
    url: '/data/offers/ids',
    method: 'GET'
  })
}
async function getOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}`,
    method: 'GET'
  })
}
async function getOfferPosition(id) {
  return await apiClient({
    url: `/search/waiting-lists/applicants/position-on-offer/${id}`,
    method: 'GET'
  })
}
async function createOffer(data) {
  return await apiClient({
    url: '/data/offers',
    method: 'POST',
    data
  })
}
async function patchOffer({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/offers/${id}`,
    method: 'PATCH',
    data
  })
}
async function deleteOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}`,
    method: 'DELETE'
  })
}
async function publishOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/publish`,
    method: 'POST'
  })
}
async function finishOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/finish`,
    method: 'POST'
  })
}
async function awardOffer({ id, userId }) {
  return await apiClient({
    url: `/data/offers/${id}/award/${userId}`,
    method: 'POST'
  })
}
async function awardExternallyOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/award-externally`,
    method: 'POST'
  })
}
async function releaseOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/release`,
    method: 'POST'
  })
}
async function extendOffer({data, id}) {
  return await apiClient({
    headers:{
      'Content-Type': 'text/plain'
    },
    url: `/data/offers/${id}/extend`,
    method: 'POST',
    data
  })
}
async function retireAwardedOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/retire`,
    method: 'POST'
  })
}
async function setRecipientsState(id, data) {
  return await apiClient({
    url: `/data/offers/${id}/recipients`,
    method: 'PUT',
    data
  })
}

async function acceptOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/accept`,
    method: 'POST'
  })
}
async function declineOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/decline`,
    method: 'POST'
  })
}

async function getRecipientsPendingOffers({ userId }) {
  return await apiClient({
    url: `/data/offers/pending/for/${userId}`,
    method: 'GET'
  })
}

async function declineRecipientsOffer({ id, userId }) {
  return await apiClient({
    url: `/data/offers/${id}/for/${userId}`,
    method: 'DELETE'
  })
}

async function cancelAwardedOffer(id) {
  return await apiClient({
    url: `/data/offers/${id}/cancel-awarded`,
    method: 'POST'
  })
}
