<template lang="pug">
.c-form-upload
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model && model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :accept='acceptableFormats && acceptableFormats.toString()',
        :aria-labelledby='ariaLabeledBy',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='this.model.filter'
        @change='filesChange($event.target.name, $event.target.files); inputFileCount = $event.target.files.length'
        placeholder=' '
        ref='uploadInput'
        type='file'
      )
      label.o-label.o-label--placeholder(:class='{ "o-label--required": model.isMandatory }', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
  ul.c-form-upload__file-list.c-form-upload__file-list--upload(v-if='value && value.length > 0')
    li.c-form-upload__file(:class='{ "c-form-upload__file--invalid": !$v.value.fileIsValid }', :key='`file-${index}`' v-for='(file, index) in value')
      template(v-if='$v.value.fileIsValid')
        svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
          use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${fileIcon(file)}`' x='0' y='0')
        component.c-form-upload__file-info(:href='model.fileUrl', :is='model.fileUrl ? "a" : "div"' download)
          .c-form-upload__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
          .c-form-upload__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'KB') }})
        button.c-form-upload__file-cta(:aria-label='Dictionary.D.DeleteUploadedFile' @click='fileRemoveClicked(index)')
          svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
      template(v-else)
        svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
        .c-form-upload__file-info
          .c-form-upload__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
          .c-form-upload__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'MB') }})
          .c-form-upload__file-error.o-bodytext.o-bodytext--size-xsmall {{ file.size > model.validation.maxAllowedSize ? Dictionary.M.Max + ' ' + maxFileSizeClean : Dictionary.W.WrongFormat }}
        button.c-form-upload__file-cta(:aria-label='Dictionary.D.DeleteUploadedFile' @click='fileRemoveClicked(index)')
          svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
</template>
<script>
export default {
  name: 'c-form-upload',
  data() {
    return {
      id: this._uid,
      inputFileCount: 0,
      value: null,
      initialvalue: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    acceptableFormats() {
      return this.model.validation.allowedMimeTypes
    },
    maxFileSizeClean() {
      return this.$options.filters.prettyBytes(this.model.validation.maxAllowedSize)
    },
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        fileIsValid(value) {
          return value === null || (value && value.length === 0) || (value && value.length > 0 && this.fileIsValid(value[0]))
        }
      }
    }
  },
  methods: {
    filesChange(fieldName, fileList) {
      if (!fileList.length) return
      this.value = []
      this.value.push(fileList[0])
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      if (!this.$v.$invalid) {
        this.$emit('change', this.$v.value.$model)
      }
    },
    fileRemoveClicked() {
      this.value = null
      this.$refs.uploadInput.value = ''
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.value)
    },
    fileIcon(file) {
      let icon = ''
      switch (file.type) {
        case 'image/jpeg':
          icon = 'paper'
          break
        case 'image/jpg':
          icon = 'paper'
          break
        case 'image/png':
          icon = 'paper'
          break
        case 'application/pdf':
          icon = 'pdf'
          break
        default:
          icon = 'paper'
      }
      return icon
    },
    fileIsValid(file) {
      const typeIsValid = (!this.acceptableFormats || this.acceptableFormats.includes(file.type)) && file.size <= this.model.validation.maxAllowedSize
      return typeIsValid
    },
    setAfterModelChange() {
      if (!Array.isArray(this.model.value) && this.model.value !== null && this.model.value.length > 0) {
        this.initialvalue = this.model.value
        const valueData = this.model.value.split(',')
        this.value = []
        this.value.push({
          type: valueData[0],
          name: valueData[1],
          size: valueData[2]
        })
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-upload {
  $root: &;
  &__file-list {
    margin-top: 10px;
  }
  &__file {
    background-color: $grey-5;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
    }

    &--invalid {
      background-color: rgba($error-red, 0.07);
      border: 1px solid $error-red;
      &:last-child {
        border: 1px solid $error-red;
      }
    }

    #{$root}__file-list--uploaded & {
      display: inline-flex;
      border: none;
      padding: 10px 15px;
      margin: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    &-info {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      margin: 0 5px;
      max-width: calc(100% - 65px);

      #{$root}__file-list--uploaded & {
        margin: 0 0 0 5px;
        max-width: calc(100% - 25px);
      }
    }
    &-name {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(80% - 20px);

      #{$root}__file-list--uploaded & {
        max-width: unset;
      }
    }
    &-size {
      margin-left: 5px;
      flex: 0 0 20%;
    }

    &-error {
      flex: 1 0 100%;
      color: $error-red;
    }

    &-cta {
      border: none;
      background-color: transparent;
    }

    svg {
      vertical-align: middle;
      flex: 0 0 25px;
      &.type {
        width: 25px;
        height: 25px;
      }
      &.close {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      &.error {
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
        fill: $error-red;
        align-self: flex-start;
      }
    }
  }
}
</style>
