<template lang="pug">
button.favorites-button(
  :ref='`favorite-cta-${id}`',
  :title='Dictionary.F.FavoriteButton'
  role='button'
  v-bind:class='{ saved: isFavorite }'
  v-if='itemId && propertyType && (!userId || isApplicant)'
  v-requiresAuthentication:click.stop='{ action: addFavorite, createdCtaText: "Proceed to save favorite" }'
)
  svg.o-svg-icon.favorites-button__icon.js-favorites-icon(type='image/svg+xml' v-bind:style='{ width: `${size}px`, height: `${size}px` }' viewbox='0 0 42 34')
    use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${iconName}`' title='Save' x='0' y='0')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-favorites-button',
  data() {
    return {
      saved: false,
      id: this._uid
    }
  },
  props: {
    favoriteId: {
      type: String
    },
    iconName: {
      type: String,
      default: 'heart-full'
    },
    size: {
      default: 22
    },
    propertyType: {
      type: String
    }
  },
  computed: {
    ...mapState('property', ['propertyId']),
    ...mapState('user', ['userId']),
    ...mapGetters('user', ['favoritesIds', 'isApplicant']),
    itemId() {
      return this.favoriteId || this.propertyId
    },
    isFavorite() {
      return this.favoritesIds.includes(this.itemId)
    }
  },
  methods: {
    addFavorite() {
      if (this.itemId)
        if (!this.isFavorite) {
          this.$store.dispatch('user/saveFavorite', {
            payload: this.itemId,
            type: this.propertyType
          })
        } else {
          this.$store.dispatch('user/removeFavorite', {
            payload: this.itemId,
            type: this.propertyType
          })
        }
    },
    doAnimation: function (element) {
      //  Inject animation container
      document
        .querySelector('body')
        .insertAdjacentHTML(
          'beforeend',
          '<div id="animation-container" class="animation__container js-animation-container"><div class="animation__top-container js-animation-top"><span class="animation__icon-container js-animation-icon-container"></span></div></div>'
        )

      const current = element
      const currentPos = current.getBoundingClientRect()
      const iconForAnimation = element.querySelector('.js-favorites-icon').cloneNode(true)
      const animationContainer = document.querySelectorAll('.js-animation-container')[document.querySelectorAll('.js-animation-container').length - 1]
      const animationTopContainer = animationContainer.querySelector('.js-animation-top')
      const animationIconContainer = animationContainer.querySelector('.js-animation-icon-container')
      const distinationContainer = document.querySelector('.header__profile-button span')
      const distination = distinationContainer.getBoundingClientRect()

      animationIconContainer.appendChild(iconForAnimation)

      animationContainer.style.left = currentPos.left + 'px'
      animationContainer.style.top = distination.top + 'px'
      animationContainer.style.right = document.body.clientWidth - distination.right + 'px'
      animationContainer.style.height = currentPos.top + currentPos.height - distination.top + 'px'

      const offsetX = animationContainer.offsetWidth - animationContainer.querySelector('.js-animation-top').offsetWidth
      const offsetY = animationContainer.offsetHeight - animationTopContainer.offsetHeight
      animationTopContainer.style.transform = 'translateX(-' + offsetX + 'px)'
      animationIconContainer.style.transform = 'translateY(' + offsetY + 'px)'

      setTimeout(function () {
        animationTopContainer.classList.add('-active')
        setTimeout(function () {
          animationContainer.remove()
        }, 1500)
      }, 10)
    }
  },
  watch: {
    isFavorite(newValue) {
      if (newValue) {
        this.doAnimation(this.$refs[`favorite-cta-${this.id}`])
      }
    }
  }
}
</script>
<style lang="scss">
.favorites-button {
  display: block;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: -10px;
  z-index: calc(#{z('elements')} - 1);

  svg {
    stroke: $ocean-primary;
    stroke-width: 1;
    fill: none;
    pointer-events: none;
  }

  &.saved {
    svg {
      fill: $ocean-primary;
    }
  }
  &__icon {
    display: block;
  }
}

.animation {
  &__container {
    position: fixed;
    display: block;
    width: auto;
    height: auto;
    z-index: 999999;
    backdrop-filter: $ocean-primary;
  }
  &__top-container,
  &__container {
    top: 0;
    right: 0;
    pointer-events: none;
  }
  &__top-container {
    position: absolute;
    width: toRem(22px);
    height: toRem(22px);
    opacity: 0;
    &.-active {
      opacity: 1;
      transition-property: transform;
      transition-duration: 1.2s;
      transition-timing-function: cubic-bezier(0.66, 0.33, 0.79, 0.42);
      transform: translateX(0px) !important;

      .animation__icon-container {
        transition-property: transform;
        transition-duration: 1.2s;
        transition-timing-function: cubic-bezier(0.24, 0.57, 0.35, 0.7);
        transform: translateY(0px) !important;
      }
      svg {
        filter: drop-shadow(4px 4px 3px $grey-1);
        animation: enlargeToSmall 1.2s ease-in-out 1 forwards;
      }
    }
  }
  &__icon-container {
    width: toRem(22px);
    height: toRem(22px);
    opacity: 1;
    position: absolute;
  }
}

@keyframes enlargeToSmall {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  45% {
    opacity: 1;
    transform: scale(1.8);
  }

  99% {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0;
    transform: scale(0.5);
  }
}
</style>
