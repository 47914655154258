import apiClient from '@/_helpers/api-client'
export const ub4FacilitiesManagementService = {
  getUb4Facilities,
  postUb4Facility,
  deleteUb4Facility
}

async function getUb4Facilities() {
  return await apiClient({
    url: '/data/ub/facilities',
    method: 'GET'
  })
}

async function postUb4Facility(data) {
  return await apiClient({
    url: '/data/ub/facilities',
    method: 'POST',
    data
  })
}

async function deleteUb4Facility({ facilityId, facilityNo }) {
  return await apiClient({
    url: `/data/ub/facilities/${facilityId}/${facilityNo}`,
    method: 'DELETE'
  })
}
