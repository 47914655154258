<template lang="pug">
.c-form-number
  fieldset.o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :max='model.validation.maxValue || undefined',
        :min='model.validation.minValue || 0',
        :name='model.inputName || id',
        :step='model.step || "any"'
        @keyup='setvalue'
        placeholder=' '
        type='number'
        v-debounce.keyup='250'
        v-model.number='model.value'
      )
      label.o-label.o-label--placeholder(:class='[{ "o-label--required": model.isMandatory }]', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { between } from 'vuelidate/lib/validators'
export default {
  name: 'c-form-number',
  data() {
    return {
      id: this._uid,
      value: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.model.value !== undefined && this.model.value !== null && this.model.value !== ''
          } else {
            return true
          }
        },
        between:
          this.model.validation.minValue !== undefined &&
          this.model.validation.maxValue !== undefined &&
          between(this.model.validation.minValue, this.model.validation.maxValue)
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue() {
      this.value = this.model.value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-number {
}
</style>
