<template lang="pug">
.highlighted-result-slider(
  :class='[`highlighted-result-slider--${theme}`, { "highlighted-result-slider--search": !!searchSlider }]'
  v-if='promotedResults && promotedResults.length > 0'
)
  .highlighted-result-slider__headline(v-if='headline') {{ headline }}
  slider(:goto='currentSlide', :slidesCount='promotedResults.length', :theme='theme' reference='highlightedSlider' v-bind:settings='settingsForSlider')
    .slick-slide-wrap(v-for='slide in promotedResults')
      c-property-card(:facilities='facilities', :property='slide' @focusedOnTab='gotoTabbedSlide')
</template>

<script>
import slider from '@/components/slider/index'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-highlighted-results-slider',
  props: {
    headline: String,
    searchSlider: { type: Boolean, default: false },
    maxResults: {
      type: Number,
      default: 30
    },
    theme: {
      type: String,
      default: 'white'
    }
  },
  components: { slider },
  data() {
    return {
      currentSlide: ''
    }
  },
  computed: {
    ...mapState('search', ['facilities', 'orderBy', 'orderDirection', 'promotedResults', 'maxPromotedResults']),
    ...mapGetters('search', ['preparedFiltersSearch']),
    settingsForSlider() {
      const sliderSettings = Object.assign({}, this.sliderSettings)
      sliderSettings.slidesToShow = 3.12
      const largest = sliderSettings.responsive.find(screen => screen.breakpoint === 1940)
      largest.settings.slidesToShow = 3.12

      const tablet = sliderSettings.responsive.find(screen => screen.breakpoint === 1024)
      tablet.settings.slidesToShow = 1
      tablet.settings.slidesToScroll = 1

      return sliderSettings
    },
    maxSlidesCount: {
      get: function () {
        return this.maxPromotedResults
      },
      set: function (value) {
        this.$store.commit('search/updateData', { maxPromotedResults: value || 30 })
      }
    }
  },
  methods: {
    gotoTabbedSlide(page) {
      if (page < this.promotedResults.length) {
        this.currentSlide = page
      }
    }
  },
  created() {
    this.maxSlidesCount = this.maxResults
  }
}
</script>

<style lang="scss">
.highlighted-result-slider {
  $self: &;
  $rightArrowOffset: 44px;
  $rigthArrowPossitionDekstopMega: calc((((100% - $rightArrowOffset) / 6.2) * 6) - 2px);
  $rigthArrowPossitionDekstop: calc((((100% - $rightArrowOffset) / 3.2) * 3) - 8px);
  $rigthArrowPossitionTablet: calc((((100% - $rightArrowOffset) / 2) * 2) - 8px);
  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: toRem(-22px);
    width: 100%;
    padding-right: toRem(30px);
    padding-left: toRem(30px);
    @include min('phoneplus', +1) {
      padding-right: toRem(54px);
      padding-left: toRem(54px);
    }
    @include min('desktop', +1) {
      padding-right: calc(100% + 10px - #{$rigthArrowPossitionDekstop} - 2px);
      padding-left: toRem(54px);
    }
    @include min('desktop-mega', +1) {
      padding-right: calc(100% + 10px - #{$rigthArrowPossitionDekstopMega} - 2px);
      padding-left: toRem(54px);
    }

    li {
      flex: 1;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        height: toRem(3px);
        // width: 100%;
        background-color: $grey-4;
        transition: background-color 500ms linear;
      }

      &.slick-active {
        &:before {
          background-color: $ocean-primary;
          // transition-delay: 125ms;
        }
      }
    }
    button {
      display: none;
    }
  }

  .slick-slider {
    margin-bottom: 40px;
    padding-bottom: 0;

    @include min('tablet') {
      margin: 0px auto 44px;
    }
  }
  .slick-track {
    display: flex !important;
  }

  .slick-prev {
    left: 4px;
    @include min('tablet') {
      left: 8px;
    }
  }

  .slick-next {
    right: 4px;
    @include min('tablet', +1) {
      right: 8px;
    }
    @include min('desktop', +1) {
      left: $rigthArrowPossitionDekstop;
      right: auto;
    }
    @include min('desktop-mega', +1) {
      left: $rigthArrowPossitionDekstopMega;
      right: auto;
    }
  }

  &--search {
    .slick-dots {
      @include min('desktop', +1) {
        padding-right: calc(100% + 10px - #{$rigthArrowPossitionDekstopMega} - 2px);
        padding-left: toRem(54px);
      }
    }
    .slick-next {
      @include min('desktop', +1) {
        left: $rigthArrowPossitionDekstopMega;
        right: auto;
      }
    }
  }

  .slick-slide {
    height: inherit !important;
    pointer-events: none;

    a {
      pointer-events: all;
    }
    > div,
    &-wrap {
      height: 100%;
    }
  }

  &--grey {
    .slick-dots li::before {
      background-color: $white;
    }
  }
}
</style>

<style lang="scss" scoped>
.highlighted-result-slider {
  $self: &;
  &__headline {
    @include setStyles('20', $s-typography-sizes);
    font-weight: 700;
    color: $grey-1;
    text-align: center;
    margin-bottom: toRem(25px);
    font-family: 'Inria Serif', serif;

    @include min('tablet') {
      @include setStyles('30', $s-typography-sizes);
    }
  }
  &--grey,
  &--white {
    padding-top: toRem(56px);
    padding-bottom: toRem(56px);
  }

  &--grey {
    background-color: $grey-5;
    #{$self}__headline {
      color: $ocean-darker;
    }
  }
  &--search {
    padding-top: toRem(20px);
    padding-bottom: toRem(20px);
    overflow: hidden;
    @include min('desktop', +1) {
      max-width: 1440px;
      margin: 0 auto;
      padding-left: 50px;
    }
  }
}
</style>
