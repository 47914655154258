/* eslint-disable prettier/prettier */
<template lang="pug">
.c-ub4-property-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-ub4-pm-overview')
        component(:is='selected ? "c-ub4-pm-property" : "c-ub4-pm-overview"', :model='model')
  template(v-else)
    c-ub4-pm-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-ub4-pm-property.o-transition__overlay(:model='model' v-if='selected')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleDeleteConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ deleteConfirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ deleteConfirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleDeleteConfirmationDelete') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleDeleteConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'

import Ub4PropertyManagementOverview from './ub4-pm-overview'
import Ub4PropertyManagementProperty from './ub4-pm-property'
export default {
  name: 'c-ub4-property-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-ub4-pm-overview': Ub4PropertyManagementOverview,
    'c-ub4-pm-property': Ub4PropertyManagementProperty
  },
  computed: {
    ...mapState('ub4PropertyManagement', ['selected', 'confirmationTarget', 'ownerships']),
    deleteConfirmationHeadlineText() {
      return this.confirmationTarget
        ? this.model.deleteMappingConfirmationHeadline.replace('{0}', this.confirmationTarget.company.name)
        : this.model.deleteMappingConfirmationHeadline
    },
    deleteConfirmationDescriptionText() {
      return this.confirmationTarget
        ? this.model.deleteMappingConfirmationDescription.replace('{0}', this.confirmationTarget.company.name)
        : this.model.deleteMappingConfirmationDescription
    }
  },
  methods: {
    handleDeleteConfirmationDelete() {
      this.$store.dispatch('ub4PropertyManagement/deleteMapping', {
        companyNo: this.confirmationTarget.companyNo,
        propertyNo: this.confirmationTarget.propertyNo,
        companyId: this.confirmationTarget.company.id
      })
    },
    handleDeleteConfirmationClose() {
      this.$store.dispatch('ub4PropertyManagement/toggleDeleteConfirmation')
    }
  },
  created() {
    this.$store.dispatch('usersManagement/getManagedUsers')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-property-management {
}
</style>
