<template lang="pug">
.profile-notifications
  .profile-notifications__heading.o-heading.o-heading--size-2.o-heading--profile-page.o-heading--profile-page-center
    h1 {{ model.headline }}
  .o-grid.o-grid--gutter-large
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .o-input__wrap.profile-notifications__checkbox-wrap
          label.profile-notifications__checkbox-label(for='switchcheckbox1') {{ model.newMessageLabel }}
          input#switchcheckbox1.o-input.o-input--switch(name='switchcheckbox' placeholder=' ' type='checkbox' v-model='mutableState.newItemsInInbox' value='1')
          label.o-label(for='switchcheckbox1')
        .o-input__wrap.profile-notifications__checkbox-wrap
          label.profile-notifications__checkbox-label(for='switchcheckbox2') {{ model.newResidenceLabel }}
          input#switchcheckbox2.o-input.o-input--switch(
            name='switchcheckbox2'
            placeholder=' '
            type='checkbox'
            v-model='mutableState.newItemsInSearchAgent'
            value='1'
          )
          label.o-label(for='switchcheckbox2')
        .o-input__wrap.profile-notifications__checkbox-wrap
          label.profile-notifications__checkbox-label(for='switchcheckbox3') {{ model.savedFavoritesLabel }}
          input#switchcheckbox3.o-input.o-input--switch(
            name='switchcheckbox3'
            placeholder=' '
            type='checkbox'
            v-model='mutableState.changesToFavorites'
            value='1'
          )
          label.o-label(for='switchcheckbox3')
        .profile-notifications__group-headline {{ model.methodTitle }}
        .o-input__wrap
          input#checkbox1.o-input(name='checkbox' placeholder=' ' type='checkbox' v-model='mutableState.sendEmail' value='1')
          label.o-label.profile-notifications__label(for='checkbox1') {{ model.emailLabel }}
        .o-input__wrap
          input#checkbox2.o-input(name='checkbox2' placeholder=' ' type='checkbox' v-model='mutableState.sendSms' value='1')
          label.o-label.profile-notifications__label(for='checkbox2') {{ model.smsLabel }}
        .o-input__wrap.profile-notifications__sms-container(v-show='mutableState.sendSms && allowPhoneInput')
          label.o-label.profile-notifications__label.profile-notifications__sms-label(for='smsInput') {{ Dictionary.D.DanishNumber }}
          .o-input__wrap.profile-notifications__sms-wrap
            input#smsInput.o-input(
              name='smsInput'
              placeholder=' '
              type='text'
              v-debounce.keyup='100'
              v-mask='\'########\''
              v-model='mutableState.phoneNoForSms'
            )
            label.o-label.o-label--placeholder(for='smsInput') {{ Dictionary.A.AddNumber }}
        .o-label.profile-notifications__label.profile-notifications__sms-label.profile-notifications__edit-phone(
          v-if='!allowPhoneInput && mutableState.sendSms'
        )
          .profile-notifications__edit-phone-text {{ model.phoneNumberLabel }}
          .profile-notifications__edit-phone-number(@click='confirmationModal = true') +45 {{ mutableState.phoneNoForSms }}
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 32')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-edit' y='0')
        .profile-notifications__group-headline {{ model.frequencyTitle }}
        .o-input__wrap
          input#radio2.o-input(name='radio' placeholder=' ' type='radio' v-bind:value='2' v-model='mutableState.frequency')
          label.o-label.profile-notifications__label(for='radio2') {{ model.asapLabel }}
        .o-input__wrap
          input#radio1.o-input(name='radio' placeholder=' ' type='radio' v-bind:value='1' v-model='mutableState.frequency')
          label.o-label.profile-notifications__label(for='radio1') {{ model.dailyLabel }}
        .profile-notifications__save-actions
          button.o-button(:disabled='!this.formIsValid || !dataChanged' @click='submitData') {{ Dictionary.S.Save }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='confirmationModal = !confirmationModal' v-if='confirmationModal')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ model.phoneNumberPopupTitle }}
    template(slot='body')
      .o-bodytext {{ model.phoneNumberPopupText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='approvePhoneChange') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='confirmationModal = !confirmationModal') {{ Dictionary.R.RegretAction }}
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'c-profile-notifications',
  props: {
    model: Object
  },
  data() {
    return {
      allowPhoneInput: false,
      confirmationModal: false,
      mutableState: {}
    }
  },
  computed: {
    ...mapState('user', ['notifications']),
    formIsValid() {
      if (
        this.mutableState.sendSms &&
        ((this.mutableState.phoneNoForSms && this.mutableState.phoneNoForSms.length !== 8) || !this.mutableState.phoneNoForSms)
      ) {
        return false
      } else {
        return true
      }
    },
    dataChanged() {
      const same = _.isEqual(
        JSON.parse(JSON.stringify(this.mutableState).replaceAll('""', null)),
        JSON.parse(JSON.stringify(this.notifications).replaceAll('""', null))
      )
      return !same
    }
  },
  methods: {
    submitData() {
      this.$store.dispatch('user/setNotifications', this.mutableState)
    },
    approvePhoneChange() {
      this.allowPhoneInput = true
      this.confirmationModal = false
    }
  },
  created() {
    this.$store.dispatch('user/getNotifications')
    this.mutableState = Object.assign({}, this.notifications)
  },
  watch: {
    notifications: {
      deep: true,
      handler() {
        this.mutableState = Object.assign({}, this.notifications)
        if (this.notifications.phoneNoForSms === '') {
          this.allowPhoneInput = true
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-notifications {
  &__save-actions {
    justify-content: flex-end;
    display: flex;
    margin-top: toRem(50px);
    border-top: toRem(1px) solid $grey-4;
    padding-top: toRem(20px);
  }
  &__clear {
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    line-height: toRem(54px);
    font-weight: 700;
    color: $ocean-primary;
  }
  &__checkbox-wrap {
    padding: toRem(14px 0);
    border-bottom: toRem(1px) solid $grey-4;
    display: flex;
    justify-content: space-between;
  }
  &__checkbox-label {
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
  }
  &__group-headline {
    margin-top: toRem(35px);
    font-weight: 700;
    color: $ocean-primary;
  }
  &__label {
    margin-top: toRem(15px);
  }
  &__sms-container {
    display: flex;
    justify-content: space-between;
  }
  &__sms-wrap {
    max-width: toRem(224px);
  }
  &__sms-label {
    margin-left: toRem(28px);
  }
  &__edit-phone {
    display: flex;

    svg {
      height: toRem(15px);
      width: toRem(15px);
      margin-left: toRem(7px);
    }
  }
  &__edit-phone-text {
    line-height: toRem(26px);
  }
  &__edit-phone-number {
    display: flex;
    background-color: $ocean-4;
    padding: toRem(5px) toRem(8px);
    border-radius: toRem(3px);
    margin-left: toRem(10px);
    @include setStyles('12', $s-typography-sizes);
    color: $ocean-primary;
    cursor: pointer;
  }
  &__approvePhoneChange {
    color: $white;
    text-align: center;
  }
  &__approvePhoneChange-text {
    margin-top: toRem(20px);
  }
  &__approvePhoneChange-button {
    margin-top: toRem(50px);
  }
  &__approvePhoneChange-link {
    margin-top: toRem(20px);
  }
}
</style>
