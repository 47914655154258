<template lang="pug">
.c-ub4-fc-mapping
  .c-ub4-fc-mapping__top
    button.c-ub4-fc-mapping__close(@click='close')
      svg.c-ub4-fc-mapping__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-ub4-fc-mapping__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-ub4-fc-mapping__content.o-transition__content
    h2.c-ub4-fc-mapping__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ model.mappingTranslations.newMappingHeadline }}
    .c-ub4-fc-mapping__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-ub4-fc-mapping__form-section
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--6
                  c-fc-number(:model='mutableData.facilityNo' @validate='validate($event, "facilityNo")' v-model='mutableData.facilityNo.value')
                .o-grid__item.o-grid__item--6
                  c-fc-select(:model='facilityIdData' @validate='validate($event, "facility")' v-model='mutableData.facilityId.value')
                .o-grid__item.o-grid__item--12(v-if='existingMapping')
                  c-notification-inline.o-bodytext.o-bodytext--size-small(:canClose='false' justify='left' type='warning') {{ existingMappingText }}

    .c-ub4-fc-mapping__form.c-ub4-fc-mapping__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
      .o-grid__group
        .o-grid__item.o-grid__item--12.c-ub4-fc-mapping__form-section--border-top(v-if='selected.id')
          button.c-ub4-fc-mapping__delete.o-link.o-link--error(@click='handleLock') {{ selected.locked ? model.unlockUserText : model.lockUserText }}
        .o-grid__item.o-grid__item--12.c-ub4-fc-mapping__form-section--border-top
          .o-grid.o-grid--gutter-small.o-grid--align-items-center
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-link.c-ub4-fc-mapping__cancel(@click='close') {{ Dictionary.C.Cancel }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                button.o-button.c-ub4-fc-mapping__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllSelect from '@/components/form/controlls/form-select'

export default {
  name: 'c-ub4-fc-mapping',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-number': FormControllNumber,
    'c-fc-select': FormControllSelect
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      initialSelectedAdministrators: [],
      mutableData: {
        facilityNo: {
          helperText: this.model.mappingTranslations.mappingNumberHelperText,
          placeholderText: this.Dictionary.N.Number,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {}
        },
        facilityId: {
          helperText: this.model.mappingTranslations.mappingFacilityHelperText,
          placeholderText: this.Dictionary.F.Facility,
          value: null,
          options: [],
          isMandatory: true,
          readOnly: false,
          validation: {}
        }
      }
    }
  },
  computed: {
    ...mapState('ub4FacilitiesManagement', ['selected', 'facilities']),
    ...mapGetters('search', ['residenceFacilities', 'propertyFacilities']),
    ...mapState('loadingIndicator', ['loading']),
    formIsValid() {
      return this.mappingDataIsValid
    },
    facilityIdData() {
      const facilityIdData = Object.assign({}, this.mutableData.facilityId)
      facilityIdData.options = [
        { group: true, label: this.Dictionary.R.ResidenceFacilities, options: this.residenceFacilities },
        { group: true, label: this.Dictionary.P.PropertyFacilities, options: this.propertyFacilities }
      ]
      return facilityIdData
    },
    mappingDataIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key === true
        })
      )
    },
    dataChanged() {
      return this.mappingDataChanged
    },
    mappingDataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case '':
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }

        return different
      })
      // console.log(target)
      dataChanged = !!target
      return dataChanged
    },
    existingMapping() {
      let existingMapping = false
      if (this.mutableData.facilityNo.value !== null && this.mutableData.facilityNo.value !== undefined) {
        existingMapping = this.facilities.find(f => f.facilityNo === this.mutableData.facilityNo.value)
      }
      return existingMapping
    },
    existingMappingText() {
      let existingMappingText
      if (this.existingMapping && this.model.mappingTranslations.mappingNotificationText) {
        existingMappingText = this.model.mappingTranslations.mappingNotificationText
          .replace('{0}', this.existingMapping.facilityNo)
          .replace('{1}', this.existingMapping.facility.name)
      }
      return existingMappingText
    }
  },
  methods: {
    close() {
      this.$store.dispatch('ub4FacilitiesManagement/removeSelected')
    },
    handleDelete(target) {
      this.$store.dispatch('ub4FacilitiesManagement/toggleDeleteConfirmation', target)
    },
    submitChanges(closeSelection) {
      this.$store.dispatch('ub4FacilitiesManagement/postUb4Facility', { data: this.getMappingData(), closeSelection })
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getMappingData() {
      const mappingData = {}
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'facilityNo':
            if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
              mappingData[key] = parseInt(this.mutableData[key].value)
            }
            break
          default:
            if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
              mappingData[key] = this.mutableData[key].value
            }
        }
      })
      return mappingData
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-fc-mapping {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
}
</style>
