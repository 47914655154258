import apiClient from '@/_helpers/api-client'
import { toFormData } from 'axios'
export const membershipOrganizationsService = {
  getMembershipOrganizationParameters,
  getMembershipOrganizationCategories,
  setMembershipOrganizationParameters,
  validateMembershipOrganizationParameters,
  getPropertyParameters,
  setPropertyParameters,
  setResidenceApplications,
  getResidenceApplications,
  getSeniorityToPassiveMembers,
  associatePfaMembership,
  getUserAssociationStatus,
  associateMembershipOrganization,
  associateMembershipOrganizationProperty,
  getPaymentStatus,
  getPayments,
  getPaymentsUser,
  extendMembershipOrganizationPayment,
  deleteAssociation,
  setPfaAssociation
}

async function getMembershipOrganizationParameters(id) {
  return await apiClient({
    url: `/parameters/membership-organizations/${id}`,
    method: 'GET'
  })
}
async function getMembershipOrganizationCategories(id) {
  return await apiClient({
    url: `/applicant-categories/${id}`,
    method: 'GET'
  })
}
async function setMembershipOrganizationParameters({ id, data }) {
  return await apiClient({
    url: `/parameters/membership-organizations/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: toFormData(data)
  })
}
async function validateMembershipOrganizationParameters({ id, data }) {
  return await apiClient({
    url: `/parameters/membership-organizations/${id}/validate`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: toFormData(data)
  })
}
async function getPropertyParameters(id) {
  return await apiClient({
    url: `/parameters/properties/${id}`,
    method: 'GET'
  })
}
async function setPropertyParameters({ propertyId, data }) {
  return await apiClient({
    url: `/parameters/properties/${propertyId}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: toFormData(data)
  })
}
async function setResidenceApplications({ id, data }) {
  return await apiClient({
    url: `/data/residence-applications/${id}`,
    method: 'POST',
    data: { residenceIds: data }
  })
}
async function getResidenceApplications({ id }) {
  return await apiClient({
    url: `/data/residence-applications/${id}`,
    method: 'GET'
  })
}
async function getSeniorityToPassiveMembers({ id }) {
  return await apiClient({
    url: `/data/membership-organizations/${id}/is-seniority-awarded-to-passive-members`,
    method: 'GET'
  })
}
async function associatePfaMembership(data) {
  return await apiClient({
    url: '/data/membership-organizations/pfa/associate-membership',
    method: 'POST',
    data
  })
}
async function getUserAssociationStatus(id) {
  return await apiClient({
    url: `/membership-organizations/${id}/association-status`,
    method: 'GET'
  })
}
async function associateMembershipOrganization(id) {
  return await apiClient({
    url: `/membership-organizations/${id}/associate`,
    method: 'POST'
  })
}
async function associateMembershipOrganizationProperty({ id, data }) {
  return await apiClient({
    url: `/membership-organizations/properties/${id}/associate`,
    method: 'POST',
    data: { residenceIds: data }
  })
}
async function getPaymentStatus(orderId) {
  return await apiClient({
    url: `/membership-organizations/${orderId}/payment-status`,
    method: 'GET'
  })
}

async function getPayments(id) {
  return await apiClient({
    url: `/membership-organizations/${id}/payments`,
    method: 'GET'
  })
}
async function getPaymentsUser({ id, userId }) {
  return await apiClient({
    url: `/data/membership-organizations/${id}/association/payments/${userId}`,
    method: 'GET'
  })
}

async function extendMembershipOrganizationPayment(id) {
  return await apiClient({
    url: `/membership-organizations/${id}/extend`,
    method: 'POST'
  })
}
async function deleteAssociation(id) {
  return await apiClient({
    url: `/data/membership-organizations/${id}/association`,
    method: 'DELETE'
  })
}
async function setPfaAssociation(id, data) {
  return await apiClient({
    url: `/feature/membershiporganizations/pfaadvisorassociation/${id}/send`,
    method: 'POST',
    data: toFormData(data)
  })
}
