<template lang="pug">
.search-agent-menu
  .search-agent-menu__wrap.o-wrap.o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default
    .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-start
      .o-grid__group
        a.search-agent-menu__section.search-agent-menu__section--back.o-grid__item.o-grid__item--grow-0(:href='ctaUrl')
          svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
          span.o-bodytext.o-bodytext--size-medium.o-bodytext--inria {{ ctaText }}
        .o-grid__item.o-grid__item--grow-0
          .search-agent-menu__separator
        .search-agent-menu__section.search-agent-menu__section--select-text.o-grid__item.o-grid__item--grow-0(
          v-if='Dictionary.SearchAgent.SelectedSearchAgent'
        )
          label.o-label.o-bodytext.o-bodytext--size-medium.o-bodytext--700.o-bodytext--inria(:for='`agent-select-${id}`') {{ Dictionary.SearchAgent.SelectedSearchAgent }}
        .search-agent-menu__section.search-agent-menu__section--select.o-grid__item.o-grid__item--grow-0
          .o-input__wrap
            select.o-input.o-input--small.o-input--inria.o-input--placeholder-simple(
              :id='`agent-select-${id}`',
              :name='`agent-select-${id}`'
              @change='valueChanged'
              placeholder=' '
              v-model='agentId'
            )
              option(:value='agent.id' v-for='agent in searchAgents') {{ agent.name }}
        .search-agent-menu__section.search-agent-menu__section--actions.o-grid__item.o-grid__item--grow-0.o-grid__item--align-right.o-grid.o-grid--gutter-small.o-grid--align-items-center.o-grid--justify-content-start
          .o-grid__group
            .o-grid__item.o-grid__item--grow-0
              button.search-agent-menu__action(@click='toggleSearchModal')
                svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-edit' y='0')
                .o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.E.Edit }}
            .o-grid__item.o-grid__item--grow-0
              button.search-agent-menu__action(@click='showDeleteConfirmation = !showDeleteConfirmation')
                svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                .o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.SearchAgent.DeleteSearchAgent }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showDeleteConfirmation = !showDeleteConfirmation' v-if='showDeleteConfirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ deleteConfirmationHeadline }}
    template(slot='body')
      .o-bodytext {{ Dictionary.SearchAgent.DeleteConfirmationText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleDelete') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            button.o-link(@click='showDeleteConfirmation = !showDeleteConfirmation') {{ Dictionary.U.Undo }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-search-agent-menu',
  data() {
    return {
      agentId: '',

      id: this._uid,
      showDeleteConfirmation: false
    }
  },
  props: {
    ctaUrl: {
      required: true
    },
    ctaText: {
      required: true
    },
    searchAgentInitialId: {
      required: true
    }
  },
  computed: {
    ...mapState('search', ['searchAgentId', 'searchAgents']),
    ...mapGetters('search', ['activeSearchAgent']),
    deleteConfirmationHeadline() {
      return this.Dictionary.SearchAgent.DeleteConfirmationHeadline.replace('{0}', this.activeSearchAgent.name)
    }
  },
  methods: {
    toggleSearchModal() {
      this.$store.dispatch('search/toggleSearchModal', true)
    },
    valueChanged() {
      this.$store.dispatch('search/setSearchAgent', this.agentId)
    },
    handleDelete() {
      this.$store.dispatch('search/deleteSearchAgent')
      this.showDeleteConfirmation = false
    },
    handleEdit() {
      this.$store.dispatch('search/toggleSearchModal', true)
    }
  },
  created() {
    if (this.searchAgentId) {
      this.agentId = this.searchAgentId
    }
    this.$store.dispatch('search/getSearchAgents')
  },
  watch: {
    searchAgentId(newValue) {
      if (newValue !== this.agentId) this.agentId = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
.search-agent-menu {
  $root: &;
  background-color: $crab-primary;
  padding-top: 25px;
  padding-bottom: 25px;

  &__separator {
    position: relative;
    width: 50px;
    height: 24px;
    &:after {
      content: '';
      background-color: $grey-2;
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      left: 50%;
    }

    @include max('desktop', -1) {
      display: none;
    }
  }

  &__section {
    &--back {
      color: inherit;
      svg {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }

      span {
        vertical-align: middle;
        text-decoration: underline;
      }
    }
    &--select {
      @include max('desktop', -1) {
        order: 3;
        min-width: 100%;
        margin-top: 10px;
      }
      select {
        background-color: transparent;
      }

      &-text {
        margin-right: 20px;

        @include max('desktop', -1) {
          display: none;
        }
      }
    }
    &--actions {
      #{$root}__action {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
      svg {
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
      }
    }
  }
}
</style>
