import { userService, membershipOrganizationsService } from '@/_services'

// Check if user data is there and add it to state as default
let windowUser = {}
if (window.Findbolig && window.Findbolig.user) {
  windowUser = Object.assign({}, window.Findbolig.user)
}
if (window.Findbolig && window.Findbolig.profileMenu) {
  windowUser = Object.assign(windowUser, { profileMenu: window.Findbolig.profileMenu })
}

const initialState = {
  userId: windowUser.id || undefined,
  fullName: undefined,
  firstName: undefined,
  lastName: undefined,
  applicantNumber: undefined,
  socialSecurityNumber: undefined,
  guardianId: undefined,
  gdprConsent: undefined,
  guardian: {
    email: undefined,
    id: undefined,
    name: undefined,
    phoneNumber: undefined
  },
  impersonator: windowUser.impersonator || undefined,
  impersonatorAdmin: windowUser.impersonator?.isAdmin || undefined,
  countryId: undefined,
  company: undefined,
  cvr: undefined,
  country: undefined,
  address: undefined,
  zipCode: undefined,
  phoneCode: undefined,
  landlinePhone: undefined,
  phoneNoForSms: (windowUser.notifications && windowUser.notifications.phoneNoForSms) || undefined,
  newPassword: undefined,
  password: undefined,
  city: undefined,
  roles: windowUser.roles || [],
  email: windowUser.email || undefined,
  avatar: undefined,
  dataBusterKey: 0,
  approvedPhoneChange: undefined,
  favorites: {
    properties: (windowUser.favorites && windowUser.favorites.properties) || [],
    residences: (windowUser.favorites && windowUser.favorites.residences) || [],
    projects: (windowUser.favorites && windowUser.favorites.projects) || []
  },
  notifications: {
    frequency: (windowUser.notifications && windowUser.notifications.frequency) || undefined,
    locale: (windowUser.notifications && windowUser.notifications.locale) || undefined,
    newItemsInInbox: (windowUser.notifications && windowUser.notifications.newItemsInInbox) || undefined,
    newItemsInSearchAgent: (windowUser.notifications && windowUser.notifications.newItemsInSearchAgent) || undefined,
    phoneNoForSms: (windowUser.notifications && windowUser.notifications.phoneNoForSms) || undefined,
    sendEmail: (windowUser.notifications && windowUser.notifications.sendEmail) || undefined,
    sendSms: (windowUser.notifications && windowUser.notifications.sendSms) || undefined
  },
  loginModalShowing: false,
  pendingAction: undefined,
  pensionFunds: windowUser.pensionFunds || [],
  profileMenu: windowUser.profileMenu || undefined
}

export const user = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state,
    pensionFundsId: state => {
      let pensionFundsId = []
      if (state.pensionFunds) {
        pensionFundsId = state.pensionFunds.map(pensionFund => pensionFund.id)
      }
      return pensionFundsId
    },
    profileMenu: state => state.profileMenu,
    profileData: state => {
      const profileData = {}
      const targetKeys = [
        'address',
        'applicantNumber',
        'city',
        'country',
        'company',
        'cvr',
        'countryId',
        'email',
        'firstName',
        'gdprConsent',
        'guardian',
        'landlinePhone',
        'lastName',
        'phoneCode',
        'phoneNoForSms',
        'socialSecurityNumber',
        'userId',
        'zipCode'
      ]
      targetKeys.forEach(key => (profileData[key] = state[key]))
      return profileData
    },
    favoritesIds: state => {
      let favoritesIds = []

      if (state.favorites.properties && state.favorites.properties.length > 0) {
        favoritesIds = favoritesIds.concat(state.favorites.properties)
      }
      if (state.favorites.residences && state.favorites.residences.length > 0) {
        favoritesIds = favoritesIds.concat(state.favorites.residences)
      }
      if (state.favorites.projects && state.favorites.projects.length > 0) {
        favoritesIds = favoritesIds.concat(state.favorites.projects)
      }
      return favoritesIds
    },
    verificationFlow: state => {
      let verificationFlow = false
      if (state.pendingAction && (state.pendingAction.type === 'PFA-member' || state.pendingAction.type === 'PFA-family')) {
        verificationFlow = {
          pensionFund: 'PFA',
          type: state.pendingAction.type === 'PFA-member' ? 'member' : 'family'
        }
      }

      return verificationFlow
    },
    hasElevatedRights: state => {
      return state.roles.includes('Property Administrator') || state.roles.includes('System Administrator')
    },
    isApplicant: state => {
      return state.roles.indexOf('Applicant') !== -1
    }
  },
  actions: {
    async authenticateUser({ commit, state, getters }, { email, password }) {
      try {
        const response = await userService.authenticateUser({ email, password })
        if (response && response.status === 200) {
          commit('updateData', response.data)
          if (state.pendingAction) {
            if (getters.verificationFlow) {
              const responseUserData = await membershipOrganizationsService.associatePfaMembership({ token: state.pendingAction.token })

              if (responseUserData && responseUserData.status === 204) {
                commit('updateData', responseUserData.data)
                commit('setPendingAction')
              }
            } else if (state.pendingAction.action) {
              state.pendingAction.action()
              commit('setPendingAction')
            }
          } else if (response.data.landingPage) {
            window.location.replace(window.location.origin + response.data.landingPage)
          }
          commit('toggleLoginModal', false)
          this.dispatch('user/getUserMenu')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async createUser({ commit }, { createData, callback }) {
      try {
        const response = await userService.createUser(createData)
        if (response && response.status === 200) {
          commit('updateData', response.data)
          callback.$emit('showMessage')
          this.dispatch('user/getUserMenu')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async createValidatedUser({ commit, state }, { createData, userData, callback }) {
      try {
        const responseCreateData = await userService.createUser(createData)

        if (responseCreateData && responseCreateData.status === 200) {
          commit('updateData', responseCreateData.data)

          userData.userId = responseCreateData.data.id
          userData.email = responseCreateData.data.email

          const responseUserData = await userService.setProfileInformation(userData)

          if (responseUserData) {
            const responseUserAssociation = await membershipOrganizationsService.associatePfaMembership({ token: state.pendingAction.token })

            if (responseUserAssociation && responseUserAssociation.status === 204) {
              callback.$emit('showMessage')
              this.dispatch('user/getUserMenu')
            }
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async setProfileInformation({ commit }, { data, mutableData }) {
      try {
        const response = await userService.setProfileInformation(data)
        if (response) {
          mutableData.dataBusterKey = Math.floor(Math.random() * 1000)
          if (mutableData.avatar) delete mutableData.avatar

          commit('updateData', mutableData)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getProfileInformation({ commit, state }) {
      try {
        const response = await userService.getProfileInformation(state.userId)
        if (response) {
          commit('updateData', response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteProfile(id) {
      try {
        const response = await userService.deleteProfile(id)
        if (response && response.status === 204) {
          delete localStorage.userEmail
          window.location.assign('/api/signout')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getNotifications({ commit }) {
      try {
        const response = await userService.getNotifications()
        if (response) {
          commit('updateData', { notifications: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async setNotifications({ commit }, payload) {
      try {
        const response = await userService.setNotifications(payload)
        if (response) {
          commit('updateData', { notifications: payload })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getFavorites({ commit }) {
      try {
        const response = await userService.getFavorites()
        if (response) {
          commit('updateData', { favorites: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async saveFavorite({ commit }, payload) {
      try {
        let response
        switch (payload.type) {
          case 'Property':
            response = await userService.postFavoriteProperties({ entityId: payload.payload })
            break
          case 'Residence':
            response = await userService.postFavoriteResidence({ entityId: payload.payload })
            break
          case 'Project':
            response = await userService.postFavoriteProject({ entityId: payload.payload })
            break
        }

        if (response) {
          commit('saveFavorite', { type: payload.type, id: payload.payload })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async removeFavorite({ commit }, payload) {
      try {
        let response

        switch (payload.type) {
          case 'Property':
            response = await userService.deleteFavoriteProperties({ entityId: payload.payload })
            break
          case 'Residence':
            response = await userService.deleteFavoriteResidence({ entityId: payload.payload })
            break
          case 'Project':
            response = await userService.deleteFavoriteProject({ entityId: payload.payload })
            break
        }

        if (response) {
          commit('removeFavorite', { type: payload.type, id: payload.payload })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getUserMenu({ commit }) {
      try {
        const response = await userService.getUserMenu()
        if (response) {
          commit('updateData', { profileMenu: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getPensionFundsUserData({ commit }) {
      try {
        const response = await userService.getPensionFundsUserData()
        if (response) {
          commit('updateData', { pensionFunds: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    authenticateAndProceed({ commit, state }, payload) {
      if (state.userId) {
        payload.action()
      } else {
        commit('setPendingAction', payload)
        this.dispatch('user/toggleLoginModal', true)
      }
    },
    setUserData({ commit }, payload) {
      commit('updateData', payload)
    },
    toggleLoginModal({ commit }, payload) {
      commit('toggleLoginModal', payload)
    }
  },
  mutations: {
    toggleLoginModal(state, payload) {
      // If we are closing the login overlay clear the pending action
      if (state.loginModalShowing) {
        state.pendingAction = Object.assign({}, {})
      }
      if (payload !== undefined) {
        state.loginModalShowing = payload
      } else {
        state.loginModalShowing = !state.loginModalShowing
      }
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload).forEach(key => {
          const target = payload[key]
          key = key === 'id' ? 'userId' : key
          if (key === 'pensionFunds') {
            payload[key].forEach(ppf => {
              const existingPf = state[key].findIndex(spf => spf.id === ppf.id)
              if (existingPf !== -1) {
                state[key].splice(existingPf, 1, ppf)
              } else {
                state[key].push(ppf)
              }
            })
          } else if (target != null && typeof target === 'object' && typeof state[key] === 'object') {
            state[key] = state[key].length ? Object.assign(state[key], target) : target
          } else {
            if (key === 'guardian' && target === null) {
              state[key] = Object.assign(state[key], initialState.guardian)
            } else {
              state[key] = target
            }
          }
        })
      }
    },
    setPendingAction(state, payload) {
      state.pendingAction = Object.assign({}, payload)
    },
    saveFavorite(state, { type, id }) {
      if (type === 'Property') {
        state.favorites.properties.push(id)
      }
      if (type === 'Residence') {
        state.favorites.residences.push(id)
      }
      if (type === 'Project') {
        state.favorites.projects.push(id)
      }
    },
    removeFavorite(state, { type, id }) {
      if (type === 'Property' && state.favorites.properties.includes(id)) {
        state.favorites.properties.splice(state.favorites.properties.indexOf(id), 1)
      }

      if (type === 'Residence' && state.favorites.residences.includes(id)) {
        state.favorites.residences.splice(state.favorites.residences.indexOf(id), 1)
      }
      if (type === 'Project' && state.favorites.projects.includes(id)) {
        state.favorites.projects.splice(state.favorites.projects.indexOf(id), 1)
      }
    }
  }
}
