<template lang="pug">
.c-adm-administrator
  .c-adm-administrator__top
    button.c-adm-administrator__close(@click='close')
      svg.c-adm-administrator__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-adm-administrator__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-adm-administrator__content.o-transition__content
    h2.c-adm-administrator__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.name ? selected.name : model.administratorTranslations.newAdministratorHeadline }}
    .c-adm-administrator__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-adm-administrator__form-section
            .c-adm-administrator__form-heading.o-heading.o-heading--size-4 {{ model.administratorTranslations.administratorDataHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.name' @validate='validate($event, "name")' v-model='mutableData.name.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-number(:model='mutableData.cvrNumber' @validate='validate($event, "cvrNumber")' v-model='mutableData.cvrNumber.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-select(:model='mutableData.companyTypeId' @validate='validate($event, "companyTypeId")' v-model='mutableData.companyTypeId.value')
              .o-grid__group
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-12
                  c-fc-text(:model='mutableData.street' @validate='validate($event, "street")' v-model='mutableData.street.value')
                .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
                  c-fc-text(:model='mutableData.houseNumber' @validate='validate($event, "houseNumber")' v-model='mutableData.houseNumber.value')
                .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
                  c-fc-text(:model='mutableData.floor' @validate='validate($event, "floor")' v-model='mutableData.floor.value')
                .o-grid__item.o-grid__item--4.o-grid__item--desktop-4
                  c-fc-text(:model='mutableData.sideDoor' @validate='validate($event, "sideDoor")' v-model='mutableData.sideDoor.value')
              .o-grid__group
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.postalCode' @validate='validate($event, "postalCode")' v-model='mutableData.postalCode.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.city' @validate='validate($event, "city")' v-model='mutableData.city.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6(v-if='this.countries.length')
                  c-fc-select(:model='countryIdData' @validate='validate($event, "countryId")' v-model='mutableData.countryId.value')
              .o-grid__group
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-email(:model='mutableData.email' @validate='validate($event, "email")' v-model='mutableData.email.value')
                .o-grid__item.o-grid__item--6.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.phone' @validate='validate($event, "phone")' v-model='mutableData.phone.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-12
                  c-fc-checkbox(
                    :model='mutableData.canSeeApplicantData'
                    @validate='validate($event, "canSeeApplicantData")'
                    v-model='mutableData.canSeeApplicantData.value'
                  )
    .c-adm-administrator__form.c-adm-administrator__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
      .o-grid__group
        .o-grid__item.o-grid__item--12.c-pm-property__form-section--border-top(v-if='selected.id')
          button.c-pm-property__delete.o-link.o-link--error(@click='toggleDeleteConfirmation') {{ model.administratorTranslations.deleteAdministratorText || Dictionary.D.Delete }}
        .o-grid__item.o-grid__item--12.c-adm-administrator__form-section--border-top
          .o-grid.o-grid--gutter-tiny.o-grid--align-items-center
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-link.c-adm-administrator__cancel(@click='close') {{ Dictionary.C.Cancel }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                button.o-button.c-adm-administrator__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationText.headline }}
    template(slot='body')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            .o-bodytext {{ confirmationText.description }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleDelete') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllPassword from '@/components/form/controlls/form-password'
import FormControllEmail from '@/components/form/controlls/form-email'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllSelect from '@/components/form/controlls/form-select'

export default {
  name: 'c-adm-administrator',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-number': FormControllNumber,
    'c-fc-password': FormControllPassword,
    'c-fc-email': FormControllEmail,
    'c-fc-checkbox': FormControllCheckbox,
    'c-fc-select': FormControllSelect
  },
  data() {
    return {
      id: this._uid,

      initialSelectedAdministrator: null,
      validation: {},
      mutableData: {
        name: {
          helperText: this.model.administratorTranslations.nameHelperText,
          placeholderText: this.Dictionary.N.Name,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 100
          }
        },
        cvrNumber: {
          helperText: '',
          placeholderText: this.Dictionary.C.Cvr,
          value: null,
          isMandatory: false,
          readOnly: false,
          step: '1',
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0
          }
        },
        companyTypeId: {
          helperText: '',
          value: null,
          placeholderText: `${this.Dictionary.C.Company} ${this.Dictionary.T.Type}`,
          isMandatory: false,
          readOnly: false,
          options: [
            { value: '1', name: 'Other' },
            { value: '2', name: 'Advokatfirma' },
            { value: '3', name: 'RealEstateManagement' },
            { value: '4', name: 'Almennyttig boligselskab' },
            { value: '5', name: 'Forening' },
            { value: '6', name: 'Indstillingsudvalg' },
            { value: '7', name: 'Investeringfond' },
            { value: '8', name: 'Offentlig institiution' },
            { value: '9', name: 'Pensionskasse' },
            { value: '10', name: 'Privat investor' }
          ],
          validation: null
        },
        street: {
          helperText: '',
          placeholderText: this.Dictionary.S.Street,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 250
          }
        },
        houseNumber: {
          helperText: '',
          placeholderText: this.Dictionary.N.Number,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d+',
            displayRule: '',
            minValue: 0,
            maxValue: 100
          }
        },
        floor: {
          helperText: '',
          placeholderText: this.Dictionary.F.Floor,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            maxLength: 10
          }
        },
        sideDoor: {
          helperText: '',
          placeholderText: this.Dictionary.D.Door,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 15
          }
        },
        postalCode: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.P.PostalCode,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1000,
            maxValue: 10000
          }
        },
        city: {
          helperText: '',
          placeholderText: this.Dictionary.C.City,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 100
          }
        },
        countryId: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.C.Country,
          isMandatory: false,
          readOnly: false,
          options: [],
          validation: null
        },
        email: {
          helperText: '',
          placeholderText: this.Dictionary.E.Email,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 250
          }
        },
        phone: {
          helperText: '',
          placeholderText: this.Dictionary.P.Phone,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 50
          }
        },
        canSeeApplicantData: {
          helperText: this.model.administratorTranslations.canSeeApplicantDataHelperText,
          value: false,
          placeholderText: this.model.administratorTranslations.canSeeApplicantDataPlaceholderText,
          isMandatory: false,
          readOnly: false,
          validation: null
        }
      }
    }
  },
  computed: {
    ...mapState('administratorsManagement', ['selected', 'administrators', 'confirmationTarget']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('search', ['countries']),
    // ...mapGetters('administratorsManagement', ['submitmodels']),
    countryIdData() {
      const countryIdData = Object.assign({}, this.mutableData.countryId)
      countryIdData.options = this.countries.map(country => {
        return { value: country.id, name: country.name }
      })
      countryIdData.value = this.selected.countryId || null
      return countryIdData
    },
    compTypeValue() {
      const compTypeIdValue = this.mutableData.companyTypeId.value && this.mutableData.companyTypeId.value.toString()
      const compTypeOptions = this.mutableData.companyTypeId.options

      const compTypeValue = compTypeIdValue && compTypeOptions.find(option => option.value === compTypeIdValue)

      return (compTypeValue && compTypeValue.name) || null
    },
    formIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key
        })
      )
    },
    confirmationText() {
      return {
        headline:
          this.model.administratorTranslations.deleteConfirmationHeadline &&
          this.model.administratorTranslations.deleteConfirmationHeadline.replace('{administratorName}', this.selected.name),
        description:
          this.model.administratorTranslations.deleteConfirmationDescription &&
          this.model.administratorTranslations.deleteConfirmationDescription.replace('{administratorName}', this.selected.name)
      }
    },
    dataChanged() {
      return this.administratorDataChanged
    },
    administratorDataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case 'selectedAdministrator':
            different =
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== this.initialSelectedAdministrator
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }

        return different
      })
      // console.log(target)
      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {
    close() {
      this.$store.dispatch('administratorsManagement/removeSelected')
    },
    submitChanges(closeSelection) {
      if (this.selected.id) {
        if (this.administratorDataChanged)
          this.$store.dispatch('administratorsManagement/patchPropertyAdministrator', { data: this.getAdministratorData(), closeSelection })
      } else {
        this.$store.dispatch('administratorsManagement/createPropertyAdministrator', { data: this.getAdministratorData(), closeSelection })
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getAdministratorData() {
      const administratorData = {}
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          default:
            if (
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              !(Array.isArray(this.mutableData[key].value) && this.mutableData[key].value.length === 0)
            ) {
              administratorData[key] = this.mutableData[key].value
            }
        }
      })
      if (this.compTypeValue) {
        administratorData.compType = this.compTypeValue
      }
      return administratorData
    },
    setSelected() {
      Object.keys(this.mutableData).forEach(key => {
        if (this.selected[key] !== undefined && this.selected[key] !== null) {
          switch (key) {
            default:
              this.mutableData[key].value = this.selected[key]
          }
        }
        if (key === 'selectedAdministrator') {
          this.initialSelectedAdministrator = this.administrators.find(administrator => administrator.administrators.includes(this.selected.id)).id

          this.mutableData[key].value = this.initialSelectedAdministrator
        }
      })
    },
    handleDelete() {
      this.$store.dispatch('administratorsManagement/deletePropertyAdministrator')
    },
    toggleDeleteConfirmation() {
      this.$store.dispatch('administratorsManagement/toggleConfirmation', this.selected.id)
    },
    handleConfirmationClose() {
      this.$store.dispatch('administratorsManagement/toggleConfirmation')
    }
  },
  created() {
    if (this.selected.id) this.setSelected()

    if (!this.countries.lenght) {
      this.$store.dispatch('search/getCountries')
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-adm-administrator {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
}
</style>
