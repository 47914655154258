import { applicantsManagementService, searchService } from '@/_services'
import { getQueryParameters } from '@/_helpers/query-parameters'

const getInitialState = () => {
  return {
    applicants: [],
    applicantsPool: [],
    selected: getQueryParameters().get('applicantId') || undefined,
    selectedApplicantAdministratorId: undefined,
    selectedApplicantMembershipOrganizationId: undefined,
    serverParams: {
      sort: [
        {
          field: '',
          type: ''
        }
      ],
      page: 1,
      perPage: 10
    },
    totalResults: 0,
    confirmationTargetId: undefined,
    facets: {}
  }
}

export const applicantsManagement = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    applicantsCategories: state => state.facets && state.facets.categories_sm
  },
  actions: {
    async selectFromUrl({ commit, state }) {
      try {
        const responseApplicant = await applicantsManagementService.searchApplicants({
          search: `id:${state.selected}`
        })
        if (responseApplicant && responseApplicant.status === 200 && responseApplicant.data.results.length === 1) {
          const responseStatus = await applicantsManagementService.getApplicantStatus(state.selected)
          if (responseStatus && responseStatus.status === 200) {
            const selectedUser = responseApplicant.data.results[0]
            commit('updateData', { selected: Object.assign(selectedUser, responseStatus.data) })
          }
        } else {
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async setSelected({ commit, state, rootState }, { id, data }) {
      try {
        const selectedUser = data || state.applicantsPool.find(applicant => applicant.userId === id)
        if (!rootState.user.roles.includes('Publisher')) {
          const response = await applicantsManagementService.getApplicantStatus(id)
          if (response && response.status === 200) {
            commit('updateData', { selected: Object.assign(selectedUser, response.data) })
          }
        } else {
          commit('updateData', { selected: selectedUser })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchApplicantStatus({ commit, state }, { data, closeSelection }) {
      try {
        const response = await applicantsManagementService.patchApplicantStatus({ id: state.selected.userId, data })
        if (response && response.status === 204) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            this.dispatch('applicantsManagement/setSelected', { id: state.selected.userId })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },

    async reindexUser({ commit }, payload) {
      try {
        const response = await searchService.postApplicantReindex(payload)
        if (response && response.status === 204) {
          this.dispatch('applicantsManagement/searchApplicants', {})
        }
      } catch (error) {
        // console.log(error)
      }
    },

    async searchApplicants({ commit, state }, payload) {
      try {
        payload.pageSize = payload.pageSize !== undefined ? payload.pageSize : state.serverParams.perPage
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = payload.page !== undefined ? payload.page : state.serverParams.page - 1
        if (state.serverParams.sort[0].type && state.serverParams.sort[0].type !== 'none') {
          payload.orderDirection = state.serverParams.sort[0].type
          payload.orderBy = state.serverParams.sort[0].field
        }
        const responseApplicants = payload.advertId
          ? await applicantsManagementService.getAdvertApplicants({ advertId: payload.advertId, data: payload })
          : await applicantsManagementService.searchApplicants(payload)

        if (responseApplicants && responseApplicants.status === 200) {
          const updateData = { applicants: responseApplicants.data.results, totalResults: responseApplicants.data.totalResults }
          if (responseApplicants.data.totalResults === 0) {
            updateData.serverParams = Object.assign(state.serverParams, { page: 1, perPage: 10 })
          }
          commit('updateData', updateData)
          commit('updatePool', responseApplicants.data.results)
          if (payload.facets && payload.facets.length > 0) {
            commit('updateData', {
              facets: responseApplicants.data.facets
            })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async imitateApplicant({ commit, state }) {
      // TODO in another story
      try {
        const response = await applicantsManagementService.imitateApplicant(state.confirmationTargetId)
        if (response && response.status === 204) {
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    removeSelected(state) {
      state.selected = undefined
      state.selectedApplicantAdministratorId = undefined
      state.selectedApplicantMembershipOrganizationId = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    updatePool(state, payload) {
      payload.forEach(newApplicant => {
        const existingApplicant = state.applicantsPool.find(a => a.userId === newApplicant.userId)
        if (existingApplicant) {
          Object.assign(existingApplicant, newApplicant)
        } else {
          state.applicantsPool.push(newApplicant)
        }
      })
    },
    updateParams(state, payload) {
      state.serverParams = Object.assign(state.serverParams, payload)
    },
    reset(state) {
      state.applicants = []
      state.selected = undefined
      state.selectedApplicantAdministratorId = undefined
      state.selectedApplicantMembershipOrganizationId = undefined
      state.serverParams = {
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10
      }
      state.totalResults = 0
      state.confirmationTargetId = undefined
      state.facets = {}
    },
    toggleConfirmation(state, payload) {
      if (payload) {
        state.confirmationTargetId = payload
      } else {
        state.confirmationTargetId = undefined
      }
    }
  }
}
