<template lang="pug">
.c-messages(:class='[{ "related-entities": relatedEntity }]')
  .o-grid.o-grid--justify-content-start.o-grid--gutter-none.o-grid--fixed-height
    .o-grid__group
      .c-messages__inbox.o-grid__item.o-grid__item---12.o-grid__item--desktop-6
        cMessagesInbox
      transition(:name='transitionClass')
        .c-messages__thread.o-grid__item.o-grid__item---12.o-grid__item--desktop-6(v-if='showThread')
          cMessagesthread(:model='model')
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import cMessagesInbox from './messages-inbox'
import cMessagesthread from './messages-thread'
export default {
  name: 'c-messages',
  components: { cMessagesInbox, cMessagesthread },
  data() {
    return {}
  },
  props: {
    relatedEntity: {
      Type: String
    },
    model: Object
  },
  computed: {
    ...mapState('messages', ['page', 'results', 'activeThread']),
    ...mapGetters('messages', ['all']),
    ...mapGetters('user', ['hasElevatedRights']),
    showThread() {
      return this.activeThread
    }
  },
  created() {
    this.transitionClass = this.isMobile ? 'o-transition__slide-fade-right' : 'o-transition__fade'
    if (this.relatedEntity) {
      const filters = { relatedEntities: Array.isArray(this.relatedEntity) ? this.relatedEntity : [this.relatedEntity] }
      this.$store.commit('messages/setSearchFilters', filters)
    }
    if (this.hasElevatedRights) {
      this.$store.dispatch('administratorsManagement/getPropertyAdministrators')
    }
  },
  destroyed() {
    this.$store.dispatch('messages/clearActiveThread')
  }
}
</script>
<style lang="scss" scoped>
.c-messages {
  position: relative;

  margin: toRem(-(map-get($wrappadding, 'phone')));
  @include min('desktop') {
    margin: auto;
    border: 1px solid $grey-4;
  }

  > .o-grid {
    @include min('desktop') {
      height: 950px;
    }
  }

  &__inbox {
    border-right: 1px solid $grey-4;
  }

  &__thread {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('header');
    min-width: 100%;
    @include min('desktop') {
      position: relative;
      z-index: unset;
      min-width: 0;
    }
  }
}
</style>
