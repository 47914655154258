<template lang="pug">
.c-om-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
      c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
      c-fc-checkbox.c-om-search__checkbox(:model='newMessages' v-model='newMessages.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='companies.length > 0')
      c-fc-vselect(:model='company' v-model='company.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='state.options.length > 0')
      c-fc-vselect(:model='state' v-model='state.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='companies.length > 0')
      c-fc-vselect(:model='responsible' v-model='responsible.value')
    .o-grid__item.o-grid__item--desktop-2
      button.o-button.c-om-search__button(@click='doSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-om-search__button(:disabled='resetDisabled' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import { getQueryParameters } from '@/_helpers/query-parameters'
export default {
  name: 'c-om-search',
  props: {
    model: Object,
    stateModel: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      company: {
        helperText: this.model.ownerSelectHelperText,
        value: (getQueryParameters().get('companyId') && getQueryParameters().get('companyId').split(',')) || null,
        placeholderText: this.Dictionary.O.Owner,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      },
      newMessages: {
        value: getQueryParameters().get('hasUnreadMessages') || false,
        helperText: this.model.newMessagesHelperText,
        placeholderText: this.Dictionary.N.NewMessages,
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      state: {
        helperText: this.model.stateSelectHelperText,
        value: (getQueryParameters().get('state') && getQueryParameters().get('state').split(',')) || null,
        placeholderText: this.Dictionary.S.State,
        readOnly: false,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: this.getOfferStates(true)
      },
      responsible: {
        helperText: this.model.responsibleSelectHelperText,
        value: (getQueryParameters().get('responsibleUserId') && getQueryParameters().get('responsibleUserId').split(',')) || null,
        placeholderText: this.Dictionary.R.Responsible,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-vselect': FormControllVSelect,
    'c-fc-text': FormControllText,
    'c-fc-checkbox': FormControllCheckbox
  },
  computed: {
    ...mapState('search', ['companies']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapState('offersManagement', ['serverParams']),
    dataReady() {
      return this.companies.length > 0 && this.allManagedUsers.length > 0
    },
    resetDisabled() {
      return !this.search.value && !this.company.value && !this.state.value && !this.responsible.value && !this.newMessages.value
    }
  },
  methods: {
    doSearch() {
      if (this.serverParams.page === 1) {
        this.handleSearch()
      } else {
        this.$store.commit('offersManagement/updateParams', { page: 1 })
      }
    },
    handleSearch() {
      const data = {
        search: this.search.value,
        filters: {}
      }
      if (this.company.value) data.filters.companyId = this.company.value

      if (this.state.value) data.filters.state = this.state.value

      if (this.responsible.value) data.filters.responsibleUserId = this.responsible.value

      if (this.newMessages.value) data.filters.hasUnreadMessages = true

      this.$store.dispatch('offersManagement/searchOffers', data)
    },
    handleReset() {
      const that = this
      this.search.value = null
      this.company.value = null
      this.state.value = null
      this.responsible.value = null
      this.newMessages.value = null
      window.history.pushState({}, document.title, window.location.pathname)

      setTimeout(function () {
        // needed to give time for the dropdowns with 1 option to set back that option
        // that.handleSearch()
        that.$store.commit('offersManagement/updateParams', {
          page: 1,
          sort: [
            {
              field: '',
              type: ''
            }
          ]
        })
      }, 0)
    },
    setMultiSelect() {
      this.responsible.options =
        this.allManagedUsers &&
        JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
          return {
            value: responsible.id,
            name: responsible.email.split('@')[0]
          }
        })

      this.company.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    if (!this.allManagedUsers.length) this.$store.dispatch('usersManagement/getManagedUsers')
    if (this.dataReady) this.setMultiSelect()
  },
  watch: {
    serverParams: {
      deep: true,
      handler() {
        this.handleSearch()
      }
    },
    dataReady() {
      this.handleSearch()
      this.setMultiSelect()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-om-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }

  &__checkbox {
    &::v-deep {
      .o-grid {
        min-height: 53px;
      }
    }
  }
}
</style>
