<template lang="pug">
c-modal.c-messages-overlay(:cornerClose='true', :darkTheme='false' @close='closeClicked' cornerCloseColor='#fff' v-if='activeThread')
  cMessagesthread.c-messages-overlay__thread(:showActionsMenu='false' slot='body')
</template>
<script>
import { mapState } from 'vuex'
import cMessagesthread from '@/components/messages/messages-thread'
export default {
  name: 'c-messages-overlay',
  data() {
    return {
      id: this._uid
    }
  },
  components: { cMessagesthread },
  props: {},
  computed: {
    ...mapState('messages', ['activeThread'])
  },
  methods: {
    closeClicked() {
      this.$store.dispatch('messages/clearActiveThread')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-messages-overlay {
  $root: &;
  &::v-deep {
    .c-modal__container {
      padding: 0;
    }
    .c-messages-thread__back {
      display: none;
    }

    #{$root}__thread {
      height: 100vh;
      @include min('desktop') {
        height: 75vh;
        &--multiple {
          height: auto;
        }
      }
    }
  }
}
</style>
