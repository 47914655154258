import Vue from 'vue'
import VueCookies from 'vue-cookies'
import vScrollLoader from 'vue-scroll-loader'
import TextareaAutosize from 'vue-textarea-autosize'
import VueMask from 'v-mask'
import SitecoreExpEditorPlugin from 'vue-scexpeditor'
import { setupInterceptors } from './_helpers/api-interceptors'
import _ from 'lodash'
import store from './_store'
import './_directives'
import './_filters'
import './_helpers'
import './_mixins'
import './configuration'

import './components'
import '@/styles/global.scss'

Vue.use(vScrollLoader)
Vue.use(TextareaAutosize)
Vue.use(VueMask)
Vue.use(SitecoreExpEditorPlugin)
Vue.use(VueCookies)

Vue.$cookies.config('365d')

Vue.config.productionTip = false
Vue.prototype.Dictionary = window.dictionary
Vue.prototype.Language = document.documentElement.lang.toLowerCase()
Object.defineProperty(Vue.prototype, '$_', { value: _ })

if (typeof Sitecore !== 'undefined') {
  document.querySelectorAll('code[type="text/sitecore"]').forEach(el => el.setAttribute('v-pre', ''))
  Vue.use(SitecoreExpEditorPlugin)
  // eslint-disable-next-line no-undef
  if (Sitecore.PageModes.ChromeManager) {
    // eslint-disable-next-line no-undef
    Sitecore.PageModes.ChromeManager.resetChromes()
  }
}
const runApp = () => {
  if (document.getElementById('page')) {
    // Run app in site mode
    // if we are in Sitecore editor then do not run app
    // if (window.Sitecore && window.Sitecore.PageModes && window.Sitecore.PageModes.PageEditor) {
    //   return
    // }

    // eslint-disable-next-line no-new
    new Vue({
      el: '#app',
      created() {
        setupInterceptors('/api', store)
        document.body.classList.add('app-ready')
      },
      destroyed() {
        document.body.classList.remove('app-ready')
      },
      store
    })
  } else {
    // Run app in dev-server mode
    // eslint-disable-next-line no-extra-semi
    ;(async function () {
      const App = (await import('./App.vue')).default
      // eslint-disable-next-line no-new
      new Vue({
        el: '#app',
        created() {
          setupInterceptors('/api', store)
        },
        render: h => h(App)
      })
    })()
  }
}

if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
  runApp()
} else {
  document.addEventListener('DOMContentLoaded', runApp)
}
