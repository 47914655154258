import apiClient from '@/_helpers/api-client'
export const parametersService = {
  getOrganizationParameters,
  getPropertyParameters,
  getParameterProperties,
  setParametersProperties,
  setPropertiesParameters
}

async function getOrganizationParameters(id) {
  return await apiClient({
    url: `/data/management/membership-organizations/${id}/parameters`,
    method: 'GET'
  })
}

async function getPropertyParameters(id) {
  return await apiClient({
    url: `/data/management/membership-organizations/properties/${id}/parameters`,
    method: 'GET'
  })
}

async function getParameterProperties(id) {
  return await apiClient({
    url: `/data/management/membership-organizations/parameters/${id}/properties`,
    method: 'GET'
  })
}

async function setParametersProperties(data) {
  return await apiClient({
    url: '/data/management/membership-organizations/parameters/properties',
    method: 'POST',
    data
  })
}

async function setPropertiesParameters(data) {
  return await apiClient({
    url: '/data/management/membership-organizations/properties/parameters',
    method: 'POST',
    data
  })
}
