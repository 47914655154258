import apiClient from '@/_helpers/api-client'
export const companiesManagementService = {
  searchManagedCompanies,
  createCompany,
  patchCompany,
  deleteCompany,
  getCompany,
  setCompanyImage,
  deleteCompanyImage
}

async function searchManagedCompanies(data) {
  return await apiClient({
    url: '/search/managed-companies',
    method: 'POST',
    data
  })
}
async function createCompany(data) {
  return await apiClient({
    url: '/data/companies',
    method: 'POST',
    data
  })
}
async function patchCompany({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/companies/${id}`,
    method: 'PATCH',
    data
  })
}
async function deleteCompany(id) {
  return await apiClient({
    url: `/data/companies/${id}`,
    method: 'DELETE'
  })
}
async function getCompany(id) {
  return await apiClient({
    url: `/data/companies/${id}`,
    method: 'GET'
  })
}

async function setCompanyImage({ formData, id }) {
  return await apiClient({
    url: `/data/companies/${id}/logo`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function deleteCompanyImage(id) {
  return await apiClient({
    url: `/data/companies/${id}/logo`,
    method: 'DELETE'
  })
}
