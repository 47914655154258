<template lang="pug">
.c-ad-overview
  .c-ad-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-ad-overview__button(:title='Dictionary.N.NewAdvert' @click='createNew' v-if='!hasElevatedRights') {{ model.addAdvertText }}
  keep-alive
    c-ad-search(:model='model' key='advert-search')
  .o-wrap.o-wrap--relative
    .c-ad-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
    .c-ad-overview__data(v-if='!isMobile')
      vue-good-table(
        :columns='columns',
        :pagination-options='paginationSettingsData',
        :rows='adverts',
        :totalRows='totalResults'
        @on-column-filter='onColumnFilter'
        @on-page-change='onPageChange'
        @on-per-page-change='onPerPageChange'
        @on-sort-change='onSortChange'
        mode='remote'
        styleClass='o-table'
      )
        template(slot='emptystate') {{ model.noResultsText }}
        template(slot='table-row' slot-scope='props')
          template(v-if='props.column.field == "residenceNr"')
            div(v-html='formatNumberHTML(props.row.companyPropertyResidenceNumbersId)')
          template(v-if='props.column.field == "applicants"')
            span {{ props.row.totalApplicants }} {{ props.row.newApplicants ? ` (${props.row.newApplicants})` : '' }}
          template(v-if='props.column.field == "membersOnlyIcon"')
            .c-ad-overview__icon-container
              svg.o-svg-icon.o-svg-icon--table(:class='[getMembersOnlyStatus(props.row.isMembershipAdvert)]' type='image/svg+xml' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          template(v-if='props.column.field == "selectableAddress"')
            button.c-ad-overview__item--name.o-bodytext.o-bodytext--size-small(
              @click='setSelected(props.row.id)'
              v-html='props.row.address && props.row.address.replaceAll(", ", ",</br>")'
            )
          template(v-if='props.column.field == "actions"')
            .o-grid.o-grid--gutter-tiny(v-if='showExtend(props.row.expirationDate, props.row.status) || props.row.hasUnreadMessages')
              .o-grid__group
                .o-grid__item.o-grid__item--12(v-if='showExtend(props.row.expirationDate, props.row.status)')
                  button.o-button.o-button--small.o-bodytext.o-bodytext--size-small(@click='extendExpiration(props.row.id)') {{ Dictionary.E.Extend }}
                .o-grid__item.o-grid__item--12(v-if='props.row.hasUnreadMessages')
                  .c-ad-overview__mail-icon
                    svg.o-svg-icon.o-svg-icon--table(type='image/svg+xml' viewbox='0 0 42 34')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
                    .c-ad-overview__unread-count {{ props.row.unreadMessagesCount }}
          template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-ad-overview__data.c-ad-overview__data--mobile(v-else)
      .c-ad-overview__item.c-ad-overview__item--table-wrap(:key='`adverts-item-table-${key}`' v-for='(item, key) in adverts')
        table
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.NumberShort }}
            td.o-bodytext.o-bodytext--size-small {{ item.number }}
            td
              svg.o-svg-icon.o-svg-icon--table(type='image/svg+xml' v-if='item.hasUnreadMessages' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.M.MembersOnly }}
            td
              svg.o-svg-icon--table.o-svg-icon--table.o-bodytext.o-bodytext--size-small.o-svg-icon(
                :class='getMembersOnlyStatus(item.isMembershipAdvert)'
                type='image/svg+xml'
                viewbox='0 0 42 34'
              )
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Address }}
            td
              button.c-ad-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(item.id)') {{ item.address }}
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.AvailableFrom }}
            td.o-bodytext.o-bodytext--size-small {{ reFormatDate(item.availableFrom) }}
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Apply }}
            td.o-bodytext.o-bodytext--size-small {{ item.totalApplicants }}
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.ApplicantsNew }}
            td.o-bodytext.o-bodytext--size-small {{ item.newApplicants }}
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.S.Status }}
            td.o-bodytext.o-bodytext--size-small {{ getStatusLabel(item.status) }}
          tr
            td.c-ad-overview__column.c-ad-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.E.ExpirationDate }}
            td.o-bodytext.o-bodytext--size-small {{ reFormatDate(item.expirationDate) }}
      c-pagination(:server-side='true', :total-results='totalResults' @setPage='onPageChange') 
    c-loading-animation(:overlay='true' v-if='loading')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AdvertManagementSearch from '../ad-search'
import { VueGoodTable } from 'vue-good-table'
import { getQueryParameters } from '@/_helpers/query-parameters'
import moment from 'moment'
export default {
  name: 'c-ad-overview',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      showAdvertImport: false,
      showWarningsImport: false,
      advertFromResidenceId: getQueryParameters().get('advertFromResidenceId') || undefined,
      advertId: getQueryParameters().get('advertId') || undefined,
      columns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'number',
          type: 'number',
          width: '70px'
        },
        {
          label: this.Dictionary.A.Address,
          field: 'selectableAddress',
          width: '200px'
        },
        {
          label: this.Dictionary.A.AvailableFrom,
          field: 'availableFrom',
          type: 'date',
          formatFn: this.reFormatDate,
          width: '90px'
        },
        {
          label: this.Dictionary.A.Applicants,
          field: 'applicants',
          type: 'number',
          width: '70px'
        },
        {
          label: this.Dictionary.S.Status,
          field: 'status',
          sortable: false,
          formatFn: this.getStatusLabel
        },
        {
          label: this.Dictionary.F.FollowUpDate,
          field: 'followUpDate',
          type: 'date',
          formatFn: this.reFormatDate,
          width: '90px'
        },
        {
          label: this.Dictionary.E.ExpirationDate,
          field: 'expirationDate',
          type: 'date',
          formatFn: this.reFormatDate,
          width: '90px'
        },
        {
          label: this.Dictionary.A.Action,
          field: 'actions',
          sortable: false
        }
      ]
    }
  },
  components: {
    'c-ad-search': AdvertManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('advertManagement', ['adverts', 'totalResults', 'serverParams']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapGetters('user', ['hasElevatedRights']),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations', 'organizations']),
    ...mapState('loadingIndicator', ['loading']),
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    companyOptions() {
      return JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
    }
  },
  methods: {
    formatNumberHTML(number) {
      const numbers = number && number.split('-')
      return (numbers && `<span class="c-ad-overview__residenceNr">${numbers.join('-</span><span class="c-ad-overview__residenceNr">')}</span>`) || ''
    },
    showExtend(date, status) {
      return (
        !!date &&
        moment.utc().isAfter(moment.utc(date).subtract(this.model.advertsSettings.extensionAvailableBefore, 'days')) &&
        !moment.utc().isAfter(moment.utc(date)) &&
        ['Published', 'PublishedNotScreened', 'DeadlineMet', 'Reserved', 'Expired'].includes(status)
      )
    },
    extendExpiration(id) {
      this.$store.dispatch('advertManagement/toggleConfirmation', { id, action: 'extendExpiration' })
    },
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    reFormatFollowUpDate(value) {
      return value && moment.utc(value).isAfter(moment.utc()) ? this.formatDate(value) : ''
    },
    reFormatResponsible(responsibleId) {
      const responsibleUser = this.allManagedUsers.find(user => user.id === responsibleId)
      return responsibleUser && responsibleUser.email.split('@')[0]
    },
    setSelected(id) {
      this.$store.dispatch('advertManagement/setSelected', id)
    },
    createNew() {
      this.$store.dispatch('advertManagement/createNew')
    },
    getStatusLabel(key) {
      return this.getAdvertStatuses()[key] || key
    },
    getInitialData() {
      if (this.hasElevatedRights) {
        if (this.companies.length === 0) {
          this.$store.dispatch('search/getCompanies')
        }
        if (this.organizations.length === 0) {
          this.$store.dispatch('search/getOrganizations')
        }
        if (this.allManagedUsers.length === 0) {
          this.$store.dispatch('usersManagement/getManagedUsers')
        }
      }
      if (this.propertyTypes.length === 0) {
        this.$store.dispatch('propertyManagement/getTypes')
      }
      if (this.residenceTypes.length === 0) {
        this.$store.dispatch('residenceManagement/getTypes')
      }
      if (this.facilities.length === 0) {
        this.$store.dispatch('search/getFacilities')
      }
    },
    getMembersOnlyStatus(status) {
      if (status) {
        return 'c-ad-overview__icon--members-only'
      }

      return 'c-ad-overview__icon--not-members-only'
    },
    handlePriviledges() {
      if (this.hasElevatedRights) {
        this.columns.splice(7, 0, {
          label: this.Dictionary.R.Responsible,
          field: 'creator',
          formatFn: this.reFormatResponsible,
          width: '150px',
          sortable: false
        })
        this.columns.splice(1, 0, {
          label: `${this.Dictionary.R.Residence} ${this.Dictionary.N.NumberShort}`,
          field: 'residenceNr',
          sortable: false,
          width: '40px'
        })
        this.columns.splice(2, 0, {
          label: '',
          field: 'membersOnlyIcon',
          sortable: false,
          width: '20px'
        })
      }
    },
    onPageChange(params) {
      this.$store.commit('advertManagement/updateParams', { page: params.currentPage || params })
    },
    onPerPageChange(params) {
      this.$store.commit('advertManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      let field = params[0].field
      if (field === 'applicants') {
        field = 'totalApplicants'
      }

      this.$store.commit('advertManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('advertManagement/updateParams', params)
    }
  },
  created() {
    this.handlePriviledges()
    this.getInitialData()

    if (this.advertFromResidenceId && this.hasElevatedRights) {
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.$store.dispatch('advertManagement/setSelectedFromResidence', this.advertFromResidenceId)
    } else if (this.advertId) {
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.setSelected(this.advertId)
    }
  }
}
</script>
<style>
.c-ad-overview__residenceNr {
  width: 100%;
  display: inline-block;
}
</style>
<style lang="scss" scoped>
.c-ad-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }
  &__mail-icon {
    position: relative;
    width: auto;
    display: inline-block;
  }
  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }

  &__unread-count {
    background-color: $ocean-primary;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-20%) translateX(5px);
    color: $white;
    @include setStyles('12', $s-typography-sizes);
    border-radius: toRem(8px);
    padding: toRem(0px 5px);
    min-width: toRem(15px);
  }
  &__residenceNr {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__column {
    &--info {
      width: 35%;
    }
  }
  &__icon {
    fill: $ocean-darker;

    &-container {
      display: flex;
      align-content: center;
      justify-content: center;
    }
    &--members-only {
      fill: $ok-green;
    }
  }
}
</style>
