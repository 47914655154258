<template lang="pug">
#waiting-list.c-waiting-list(v-if='residences.length && (!userId || isApplicant)')
  .o-wrap.o-wrap--padding-side-default.o-wrap--max-width-content
    .c-waiting-list__top
      h2.c-waiting-list__heading.o-heading.o-heading--size-2 {{ (model.callToAction || '').replace('{0}', model.propertyTitle) }}
      .c-waiting-list__description.o-bodytext.o-bodytext--size-large {{ (model.description || '').replace('{0}', model.propertyTitle) }} {{ model.membershipOrganization ? (model.membersOnlyDescription || '').replace('{0}', model.membershipOrganization) : '' }}
      a.c-waiting-list__cta.o-link(:href='selected.detailsLink' v-if='selected && selected.detailsLink') {{ Dictionary.S.SeeWaitingListRequirements }}
    c-notification-inline.c-waiting-list__notification(:can-close='false' justify='left' size='small' type='error' v-if='disableSelection')
      .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.MembershipOrganizations.BeAware }}
      .o-bodytext.o-bodytext--small {{ selected.messageRequireExternalAssociation }}
    c-notification-inline.c-waiting-list__notification(:can-close='false' justify='left' size='small' type='warning' v-else)
      .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.MembershipOrganizations.BeAware }}
      .o-bodytext.o-bodytext--small {{ model.priceChangeAfterRenovationWarning }}
    c-notification-inline.c-waiting-list__notification(:can-close='false' justify='start' size='small' type='' v-if='selected && !!selected.orderStatus')
      .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.P.PaymentStatus }} - {{ Dictionary.PaymentStatus[selected.orderStatus] }}
    .c-waiting-list__status
      .o-grid(
        :class='[isMobile ? "o-grid--direction-column-reverse o-grid--align-items-start o-grid--align-items-stretch" : "o-grid--align-items-end"]'
        v-if='!hideSelection'
      )
        .o-grid__group
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
            .c-waiting-list__status-left.o-input__wrap
              input.o-input(
                :disabled='disableSelection',
                :id='`${id}-selectAll`'
                autocomplete='do-not-autofill'
                name='selectAll'
                placeholder=' '
                type='checkbox'
                v-model='selectAll'
                value='selectAll'
              )
              label.o-label(:for='`${id}-selectAll`') {{ Dictionary.S.SelectAllResidences }}
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
            .c-waiting-list__status-center
              .o-bodytext.o-bodytext--inria.o-bodytext--size-large.o-bodytext--700(v-if='subscribed') {{ (model.youAreOnWaitingLists || '').replace('{0}', activeWaitingListResidencies.length) }}
              c-cta.o-button(
                :cta-disabled='ctaDisabled',
                :cta-loading='processing',
                :cta-text='`${Dictionary.S.SignupToWaitingList} (${selectedResidencies.length})`'
                v-else
                v-requiresAuthentication:click.stop='{ action: handleButtonClick, createdCtaText: model.newUserCreatedCTAText }'
              )
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='subscribed')
            .c-waiting-list__status-right
              c-cta.o-button(
                :class='{ "o-button--inactive": !residenciesSelectionChanged }',
                :cta-disabled='!residenciesSelectionChanged || ctaDisabled',
                :cta-loading='processing',
                :cta-text='`${Dictionary.U.UpdateSelection} (${selectedResidencies.length})`'
                key='update-selection-cta'
                v-requiresAuthentication:click.stop='{ action: handleButtonClick, createdCtaText: Dictionary.U.UpdateSelection }'
              )
    .c-waiting-list__list
      .c-waiting-list__item-wrap(:key='`room-item-${roomSizeKey}`' v-for='(roomSize, roomSizeKey) in updatedModel.residences')
        .c-waiting-list__item.o-grid--align-items-stretch(:class='[isMobile ? "o-grid--direction-column" : "o-grid o-grid--gutter-small o-grid--wrap-nowrap"]')
          .o-grid__group
            .o-grid__item.o-grid__item--4
              .o-input__wrap.c-waiting-list__item-content.c-waiting-list__item-check
                input.o-input(
                  :checked='allSizeItemsChecked(roomSize)',
                  :disabled='disableSelection',
                  :id='`select-${roomSizeKey}`',
                  :name='`select-${roomSizeKey}`'
                  @change='toggleAllSizeItems($event, roomSize)'
                  autocomplete='do-not-autofill'
                  placeholder=' '
                  type='checkbox'
                )
                label.o-label(:for='`select-${roomSizeKey}`')
                  .c-waiting-list__item-check-wrap
                    .o-bodytext.o-bodytext--size-medium.o-bodytext--700 {{ roomSize.rooms }} {{ Dictionary.R.Rooms.toLowerCase() }}
                    .o-bodytext.o-bodytext--size-medium {{ roomSize.items.length }} {{ Dictionary.P.PropertiesOn }} {{ roomSize.area[0] !== roomSize.area[1] ? `${roomSize.area[0]} - ${roomSize.area[1]}` : roomSize.area[0] }} {{ Dictionary.Search.m2 }}
            .o-grid__item.o-grid__item--2
              .c-waiting-list__item-content
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-key {{ Dictionary.C.CurrentRentFrom }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-if='roomSize.rent[0] !== roomSize.rent[1]') {{ roomSize.rent[0] || 0, roomSize.rent[1] || 0 | money }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-else) {{ roomSize.rent[0] || 0 | money }}
            .o-grid__item.o-grid__item--2
              .c-waiting-list__item-content
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-key {{ Dictionary.D.Deposit }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-if='roomSize.deposit[0] !== roomSize.deposit[1]') {{ roomSize.deposit[0] || 0, roomSize.deposit[1] || 0 | money }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-else) {{ roomSize.deposit[0] || 0 | money }}
            .o-grid__item
              .c-waiting-list__item-content
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-key.notranslate {{ Dictionary.A.Account }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-if='roomSize.aconto[0] !== roomSize.aconto[1]') {{ roomSize.aconto[0] || 0, roomSize.aconto[1] || 0 | money }}
                .o-bodytext.o-bodytext--size-small.c-waiting-list__item-value.notranslate(v-else) {{ roomSize.aconto[0] || 0 | money }}
            .o-grid__item.o-grid__item--grow-0(v-if='!model.waitingListOnlyAllowResidenceType')
              input.u-visually-hidden(
                :id='`seeAll-${roomSizeKey}`',
                :name='`seeAll-${roomSizeKey}`',
                :value='roomSizeKey'
                autocomplete='do-not-autofill'
                placeholder=' '
                type='checkbox'
                v-model='expandedRoomSizes'
              )
              label.c-waiting-list__item-content.c-waiting-list__item-toggle(:for='`seeAll-${roomSizeKey}`')
                svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
                  use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${expandedRoomSizes.includes(roomSizeKey) ? "collapse" : "expand"}`' x='0' y='0')
                .o-link {{ Dictionary.S.SeeAll }}
        .c-waiting-list__subItem-wrap(v-if='expandedRoomSizes.includes(roomSizeKey)')
          .c-waiting-list__subItem.o-grid(
            :class='[isMobile ? "o-grid--direction-column" : "o-grid o-grid--gutter-small o-grid--align-items-center"]',
            :key='`room-subItem-${subItemKey}`'
            v-for='(subItem, subItemKey) in roomSize.items'
          )
            .o-grid__group
              .o-grid__item.o-grid__item--4
                .o-input__wrap
                  input.o-input(
                    :disabled='disableSelection',
                    :id='`select-${roomSizeKey}-${subItemKey}`',
                    :name='`select-${roomSizeKey}-${subItemKey}`',
                    :value='subItem.id'
                    autocomplete='do-not-autofill'
                    placeholder=' '
                    type='checkbox'
                    v-model='selectedResidencies'
                  )
                  label.o-label.o-bodytext--size-medium.o-bodytext--700(:for='`select-${roomSizeKey}-${subItemKey}`') {{ address(subItem) }}
              .o-grid__item.o-grid__item--2.c-waiting-list__subItem-tags
                .o-tag.o-tag--crab(v-for='residenceType in subItem.types') {{ getResidenceType(residenceType) }}
              .o-grid__item.o-grid__item--3
                .c-waiting-list__subItem-size.o-bodytext.o-bodytext--size-small
                  span {{ subItem.rooms }} {{ Dictionary.R.RoomsDiscriptive }}
                  span {{ subItem.area }} {{ Dictionary.Search.m2 }}
              .o-grid__item.o-grid__item--3.c-waiting-list__subItem-tags
                .c-waiting-list__subItem-tags-item.o-tag.o-tag--grey(v-for='(subItemTag, subItemTagKey) in subItem.facilities') {{ updatedModel.facilitiesMap[subItemTag].name }}
    .c-waiting-list__status-end(v-if='!hideSelection')
      c-cta.o-button(
        :cta-disabled='ctaDisabled',
        :cta-loading='processing',
        :cta-text='`${Dictionary.S.SignupToWaitingList} (${selectedResidencies.length})`'
        v-if='!subscribed'
        v-requiresAuthentication:click.stop='{ action: handleButtonClick, createdCtaText: model.newUserCreatedCTAText }'
      )
      c-cta.o-button(
        :class='{ "o-button--inactive": !residenciesSelectionChanged }',
        :cta-disabled='!residenciesSelectionChanged || ctaDisabled',
        :cta-loading='processing',
        :cta-text='`${Dictionary.U.UpdateSelection} (${selectedResidencies.length})`'
        key='update-selection-cta'
        v-else
        v-requiresAuthentication:click.stop='{ action: handleButtonClick, createdCtaText: Dictionary.U.UpdateSelection }'
      )

  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='cancelConfirmation' v-if='userIsAssociated && changeConfirmation !== ""')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ model.headingUpdate }}
    template(slot='body')
      .o-bodytext {{ model.descriptionUpdate }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleButtonClick') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='cancelConfirmation') {{ Dictionary.R.RegretAction }}
  c-mo-subscription(
    :callback='associateMembershipOrganizationProperty',
    :model='subscribtionModel',
    :property-application='true'
    @close='closeSubscribtion'
    v-if='showApplicationFlow'
  )
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moSubscription from '@/components/membership-organization-subscription'
import { searchService } from '../../_services'
import { getQueryParameters, deleteQueryParameter } from '@/_helpers/query-parameters'
export default {
  name: 'c-waiting-list',

  data() {
    return {
      id: this._uid,
      expandedRoomSizes: [],
      residences: [],
      changeConfirmation: '',
      disableCta: false
    }
  },
  components: {
    'c-mo-subscription': moSubscription
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('property', ['propertyId']),
    ...mapState('user', ['userId']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    ...mapState('membershipOrganizations', ['selected', 'selectedWaitingListResidencies', 'activeWaitingListResidencies', 'confirmation']),
    ...mapGetters('membershipOrganizations', ['userIsAssociated', 'paymentRequired']),
    ...mapGetters('user', ['isApplicant']),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    updatedModel() {
      const updatedModel = { ...this.model, ...{ residences: [] } }
      this.residences.forEach(item => {
        const deposit = this.deposit(item)

        if (!updatedModel.residences.some(key => key.rooms === item.rooms)) {
          updatedModel.residences.push({
            rooms: item.rooms,
            area: [item.area, item.area],
            rent: [item.rent, item.rent],
            deposit: [deposit, deposit],
            aconto: [item.aconto, item.aconto],
            items: [item],
            underRenovation: item.underRenovation
          })
        } else {
          const target = updatedModel.residences.find(key => key.rooms === item.rooms)

          if (item.area < target.area[0]) target.area[0] = item.area
          if (item.area > target.area[1]) target.area[1] = item.area
          if (item.rent < target.rent[0]) target.rent[0] = item.rent
          if (item.rent > target.rent[1]) target.rent[1] = item.rent
          if (deposit < target.deposit[0]) target.deposit[0] = deposit
          if (deposit > target.deposit[1]) target.deposit[1] = deposit
          if (item.aconto < target.aconto[0]) target.aconto[0] = item.aconto
          if (item.aconto > target.aconto[1]) target.aconto[1] = item.aconto
          target.items.push(item)
        }
      })
      updatedModel.residences.forEach(residence => {
        residence.facilities = this.sortedFacilities(residence)
      })

      updatedModel.residences.sort((a, b) => a.rooms - b.rooms)

      updatedModel.residences.forEach(size => {
        size.items.sort((a, b) => a.area - b.area || this.address(a).localeCompare(this.address(b)))
      })

      return updatedModel
    },
    disableSelection() {
      return (
        (this.selected && this.selected.requireExternalAssociation && !this.selected.associated) || this.membershipOrganizationRentalModel !== 'WaitingList'
      )
    },
    hideSelection() {
      return this.membershipOrganizationRentalModel !== 'WaitingList'
    },
    processing() {
      const waitingForApplications = this.loadingEndpoints.includes(`/data/residence-applications/${this.model.propertyId}`)
      const waitingForParameters = this.loadingEndpoints.includes(`/parameters/properties/${this.model.propertyId}`)
      const waitingForApplicationsUpdate = this.loadingEndpoints.includes(`/membership-organizations/properties/${this.model.propertyId}/associate`)
      const waitingForEndpoints = this.loading && (waitingForApplications || waitingForApplicationsUpdate || waitingForParameters)

      return waitingForEndpoints
    },
    ctaDisabled() {
      return (
        this.processing ||
        (this.selectedResidencies.length === 0 && this.activeWaitingListResidencies.length === 0) ||
        this.disableCta ||
        !this.membershipOrganizationId
      )
    },
    subscribed() {
      return this.activeWaitingListResidencies.length > 0
    },
    showApplicationFlow() {
      return (
        !!(this.selected && this.selected.sections && this.selected.sections.length > 0) ||
        (!!this.confirmation && this.userIsAssociated) ||
        this.paymentRequired
      )
    },
    subscribtionModel() {
      return {
        heading: this.model.applicationOverviewHeading,
        ctaText: this.model.applicationOverviewCTAText,
        headingConfirmation: this.model.headingConfirmation,
        descriptionConfirmation: this.model.descriptionConfirmation,
        headingConfirmationWarning: this.model.headingConfirmationWarning,
        descriptionConfirmationWarning: this.model.descriptionConfirmationWarning,
        headingConfirmationError: this.model.headingConfirmationError,
        descriptionConfirmationError: this.model.descriptionConfirmationError,
        bodyConfirmation: this.bodyConfirmation,
        ctaConfirmation: this.model.ctaConfirmation,
        headingValidation: this.model.membershipOrganizationsOverviewComponentModel.validationTitle,
        descriptionValidation: this.model.membershipOrganizationsOverviewComponentModel.validationDescription,
        qualifiedNotification: this.model.qualifiedNotification,
        applicationPaymentHeading: this.model.applicationPaymentHeading,
        propertiesOverflowNotification: this.model.propertiesOverflowNotification,
        residencesOverflowNotification: this.model.residencesOverflowNotification,
        paymentHeadline: this.selected.waitingListPaymentHeadline,
        paymentDescription: this.selected.waitingListPaymentDescription,
        paymentTermsPlaceholderText: this.selected.waitingListPaymentTermsPlaceholder,
        paymentTermsHelperText: this.selected.waitingListPaymentTermsHelper
      }
    },
    selectAll: {
      get: function () {
        return this.model ? this.residences.length === this.selectedResidencies.length : false
      },
      set: function (value) {
        const selected = []

        if (value) {
          this.residences.forEach(function (item) {
            selected.push(item.id)
          })
        }

        this.selectedResidencies = selected
      }
    },
    membershipOrganization() {
      return this.residences && this.residences[0] && this.residences[0].propertyOrganization
    },
    membershipOrganizationId() {
      return this.residences && this.residences[0] && this.residences[0].propertyOrganizationId
    },
    membershipOrganizationRentalModel() {
      return this.residences && this.residences[0] && this.residences[0].rentModel
    },
    selectedResidencies: {
      get: function () {
        return this.selectedWaitingListResidencies
      },
      set: function (value) {
        this.$store.commit('membershipOrganizations/setSelectedWaitingListResidencies', value)
      }
    },
    hasResidencesUnderRenovation() {
      return this.updatedModel.residences.some(residence => residence.underRenovation)
    },
    bodyConfirmation() {
      return (this.model.bodyConfirmation || '').replace('{0}', this.membershipOrganization)
    },
    residenciesSelectionChanged() {
      const same =
        this.selectedResidencies.length === this.activeWaitingListResidencies.length &&
        this.selectedResidencies.every(selectedId => this.activeWaitingListResidencies.includes(selectedId))
      return !same
    }
  },
  methods: {
    deposit(residence) {
      if (residence.monthsOfDeposit === null || residence.monthsOfDeposit === 0 || !residence.rents || !residence.rents.length) {
        return null
      }

      const rents = residence.rents.filter(r => r.description !== 'MigratedPrePayment' && r.description !== 'MigratedDeposit')

      const rent = rents.filter(rent => rent.isPartOfRent).reduce((acc, rent) => acc + rent.amount.amount, 0),
        notPartOfDeposit = rents.filter(rent => !rent.isPartOfDeposit).reduce((acc, rent) => acc + rent.amount.amount, 0)

      return residence.monthsOfDeposit * (rent - notPartOfDeposit)
    },
    allSizeItemsChecked(sizeItem) {
      const ids = sizeItem.items.map(item => item.id)
      return ids.every(id => this.selectedResidencies.includes(id))
    },
    toggleAllSizeItems(event, sizeItem) {
      const ids = sizeItem.items.map(item => item.id)
      if (event.target.checked) {
        this.selectedResidencies = [...this.selectedResidencies, ...ids.filter(id => !this.selectedResidencies.includes(id))]
      } else {
        this.selectedResidencies = this.selectedResidencies.filter(residence => !ids.includes(residence))
      }
    },
    closeChangeConfirmation() {
      this.changeConfirmation = ''
    },
    cancelConfirmation() {
      this.selectedResidencies = this.activeWaitingListResidencies
      this.closeChangeConfirmation()
    },
    closeSubscribtion() {
      this.selectedResidencies = this.activeWaitingListResidencies
    },
    handleButtonClick() {
      this.disableCta = true
      setTimeout(() => {
        this.disableCta = false
      }, 500)

      if (this.selected.requireExternalAssociation && !this.userIsAssociated) {
        return
      }
      if (this.userIsAssociated && !this.paymentRequired) {
        this.associateMembershipOrganizationProperty()
      } else {
        this.startApplicationFlow()
      }
    },
    associateMembershipOrganizationProperty() {
      this.$store.dispatch('membershipOrganizations/associateMembershipOrganizationProperty', {
        propertyId: this.model.propertyId,
        residencies: this.selectedResidencies
      })
    },
    startApplicationFlow() {
      this.$store.dispatch('membershipOrganizations/setSelected', {
        id: this.membershipOrganizationId,
        isApplication: true,
        propertyId: this.model.propertyId
      })
    },
    sortedFacilities(residence) {
      return (
        (residence.facilities &&
          residence.facilities.sort((a, b) => this.model.facilitiesOrder.findIndex(x => x.id === b) - this.model.facilitiesOrder.findIndex(x => x.id === a))) ||
        []
      )
    },
    address(residence) {
      return this.combineAddress(residence.street, residence.number, residence.letter, residence.floor, residence.door)
    },
    askForConfirmation() {
      // console.log('ask confirmation')
      if (this.subscribed && this.userIsAssociated) {
        this.changeConfirmation = 'update'
      } else {
        this.handleButtonClick()
      }
    },
    async getActiveWaitingListResidencies() {
      try {
        const data = {
          pageSize: 2147483647,
          page: 0,
          mixedResults: false
        }
        const response = await searchService.getPropertiesByCompaniesResidences(this.model.propertyId, data)
        if (response) {
          this.residences = response.data.results
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getOrderStatus(id) {
      const that = this
      that.$store.dispatch('membershipOrganizations/getOrderStatus', id)
      setTimeout(() => {
        const getOrderStatus = setInterval(() => {
          const alreadyLoading = this.loadingEndpoints.includes(`/membership-organizations/${id}/payment-status`)
          if (that.selected.orderStatus === 'Initiated' || that.selected.orderStatus === undefined) {
            if (!alreadyLoading) {
              that.$store.dispatch('membershipOrganizations/getOrderStatus', id)
            }
          } else {
            that.$store.dispatch('membershipOrganizations/getResidenceApplications', { propertyId: that.model.propertyId })

            clearInterval(getOrderStatus)
          }
        }, 250)
      }, 2000)
    },
    getResidenceType(residenceTypeId) {
      const type = this.residenceTypes.find(type => type.id === residenceTypeId)

      return type && type.name
    }
  },
  watch: {
    userId(newVal) {
      if (newVal && this.isApplicant) {
        this.getActiveWaitingListResidencies()
        this.$store.dispatch('membershipOrganizations/getResidenceApplications', { propertyId: this.model.propertyId })
      }
    },
    userIsAssociated(newVal) {
      if (newVal && this.selected && this.selected.sections.length === 0) {
        this.askForConfirmation()
      }
    },
    membershipOrganizationId(newVal) {
      this.$store.commit('membershipOrganizations/setSelected', {
        id: newVal,
        paymentStatus: undefined,
        orderStatus: undefined
      })
    },
    showApplicationFlow() {
      this.closeChangeConfirmation()
    },
    confirmation: {
      deep: true,
      handler() {
        this.closeChangeConfirmation()
      }
    }
  },
  created() {
    this.$store.dispatch('membershipOrganizations/setAll', this.model.membershipOrganizationsOverviewComponentModel.membershipOrganizations)
    this.getActiveWaitingListResidencies()
    if (this.userId && this.isApplicant) {
      const orderId = getQueryParameters().get('orderId') || undefined
      if (orderId) {
        this.getOrderStatus(orderId)
        deleteQueryParameter('membershipOrganizationId')
        deleteQueryParameter('orderId')
        deleteQueryParameter('paymentid')
      }
      this.$store.dispatch('membershipOrganizations/getResidenceApplications', { propertyId: this.model.propertyId })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-waiting-list {
  $item-padding: toRem(24px);
  padding: toRem(46px 0);
  background-color: $grey-5;

  @include min('desktop') {
    padding: toRem(70px 0 80px);
  }

  &__top {
    text-align: center;
  }

  &__description {
    margin-top: toRem(20px);
  }

  &__cta {
    display: inline-block;
    margin-top: toRem(16px);
  }

  &__notification,
  &__status {
    margin-top: toRem(24px);
  }

  &__status {
    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }

    &-left {
      margin-top: toRem(28px);
      padding: 14px 0 0 $item-padding;
      border-top: 1px solid $grey-4;

      @include min('desktop') {
        margin-top: 0;
        border: none;
        padding: 0 0 0 $item-padding;
      }

      label:before {
        background-color: $white;
      }
    }

    &-end {
      text-align: center;
      margin-top: toRem(32px);
    }
  }

  &__list {
    margin-top: toRem(16px);
  }

  &__item {
    outline: 1px solid $ocean-primary;
    z-index: 1;
    position: relative;

    &-wrap {
      background-color: $grey-6;
      outline: 1px solid $grey-3;

      & + & {
        margin-top: toRem(16px);
      }
    }

    &-content {
      padding: 0 toRem(16px) 0 calc(#{$item-padding} + 28px);
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      min-height: 100%;

      @include min('desktop') {
        padding: $item-padding 0;
        flex-direction: column;
        justify-content: start;
      }
    }

    &-key {
      color: $grey-2;
      margin-bottom: toRem(4px);
    }

    &-value {
      text-align: right;
      color: $grey-2;
      font-weight: 600;

      @include min('desktop') {
        text-align: left;
        color: inherit;
        font-weight: 600;
        @include setStyles('16', $s-typography-sizes);
      }
    }

    &-check {
      padding: $item-padding $item-padding 0;
      margin-bottom: toRem(8px);

      @include min('desktop') {
        margin-bottom: 0;
      }

      &-wrap {
        white-space: nowrap;
        display: inline-flex;
        flex-direction: column;
      }
    }

    &-toggle {
      padding: $item-padding;
      text-align: center;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;

      @include min('desktop') {
        background-color: $grey-5;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__subItem {
    padding: $item-padding 0;
    border-bottom: 1px solid $grey-4;

    &:last-child {
      border-bottom: none;
    }

    &-wrap {
      padding: 0 toRem(16px) 0 calc(28px + #{$item-padding});

      @include min('desktop') {
        padding: 0 calc(28px + #{$item-padding});
      }
    }

    &-size {
      padding-left: 28px;
      margin-top: toRem(5px);

      @include min('desktop') {
        margin-top: 0;
        padding-left: 0;
        @include setStyles('16', $s-typography-sizes);
      }

      span + span {
        margin-left: toRem(16px);
        padding-left: toRem(16px);

        &:before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 11px;
          background: $grey-3;
          position: relative;
          left: toRem(-16px);
        }
      }
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 28px;
      margin-top: toRem(8px);

      @include min('desktop') {
        margin-top: 0;
        margin-left: 0;
        justify-content: flex-end;
      }

      &-item {
        margin: 2px;
      }
    }
  }
}
</style>
