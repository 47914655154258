<template lang="pug">
.c-applicant-payments
  .c-applicant-payments__form.o-grid.o-grid--gutter-large
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .c-applicant-payments__form-section
          .c-applicant-payments__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.paymentsTabHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--6
                c-fc-select(:model='administratorData' @validate='validate($event, "administrator")' v-model='selectedApplicantAdministratorIdValue')
              .o-grid__item.o-grid__item--12
                c-fc-select(:model='statusForData' @validate='validate($event, "statusFor")' v-model='selectedApplicantMembershipOrganizationIdValue')
        .c-applicant-payments__form-section
          vue-good-table(
            :columns='paymentHistoryColumns',
            :pagination-options='paginationSettings',
            :rows='paymentsHistory',
            :sort-options='{ enabled: false }',
            :totalRows='0'
            styleClass='o-table'
          )
            template(slot='emptystate') {{ model.historyNoResultsText }}
            template(slot='table-row' slot-scope='props')
              template(v-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
              template(v-if='props.column.field == "message"')
                span.c-offer-history__message {{ props.row.message }}
              template(v-else-if='props.column.field == "historyNumber"')
                button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.historyNumber }}
              template(v-else) {{ props.formattedRow[props.column.field] }}
</template>
<script>
import { mapState } from 'vuex'

import { VueGoodTable } from 'vue-good-table'
// import moment from 'moment'
import { membershipOrganizationsService } from '@/_services'
import FormControllSelect from '@/components/form/controlls/form-select'
export default {
  name: 'c-applicant-payments',
  props: {
    model: {
      type: Object,
      required: true
    },
    administrator: {
      required: true
    },
    statusFor: {
      required: true
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    VueGoodTable
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      paymentHistoryColumns: [
        {
          label: this.Dictionary.D.Date,
          field: 'created',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.A.Amount,
          field: 'amount',
          formatFn: this.reFormatAmount
        }
      ],
      paymentsHistory: []
    }
  },
  computed: {
    ...mapState('applicantsManagement', ['selected', 'selectedApplicantAdministratorId', 'selectedApplicantMembershipOrganizationId']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('usersManagement', ['administrators']),
    // paginationSettingsData() {
    //   const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.userAuditsServerParams.page })
    //   return paginationSettingsData
    // },
    administratorData() {
      const administratorData = this.administrator
      // administratorData.value = this.selectedApplicantAdministratorId
      return administratorData
    },
    statusForData() {
      const statusForData = this.statusFor
      // statusForData.value = this.selectedApplicantMembershipOrganizationId
      return statusForData
    },
    selectedApplicantAdministratorIdValue: {
      get: function () {
        return this.selectedApplicantAdministratorId
      },
      set: function (value) {
        this.$store.commit('applicantsManagement/updateData', { selectedApplicantAdministratorId: value })
      }
    },
    selectedApplicantMembershipOrganizationIdValue: {
      get: function () {
        return this.selectedApplicantMembershipOrganizationId
      },
      set: function (value) {
        this.$store.commit('applicantsManagement/updateData', { selectedApplicantMembershipOrganizationId: value })
      }
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value, 'DD-MM-YYYY HH:mm:ss') : ''
    },
    reFormatAmount(value) {
      return value ? this.$options.filters.money(value.Amount) : ''
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    async getPayments() {
      try {
        const response = await membershipOrganizationsService.getPaymentsUser({
          id: this.selectedApplicantMembershipOrganizationId,
          userId: this.selected.userId
        })
        if (response && response.status === 200) {
          this.paymentsHistory = response.data
        }
      } catch (e) {
        // console.log(e)
      }
    }
  },
  created() {
    if (this.selectedApplicantMembershipOrganizationId) {
      this.getPayments()
    }
  },
  watch: {
    selectedApplicantMembershipOrganizationId(newVal) {
      if (newVal) {
        this.getPayments()
      } else {
        this.paymentsHistory = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-applicant-payments {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  &__cancel {
    text-decoration: underline;
    display: block;
  }

  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
  &__filters {
    padding: toRem(16px 20px);
    background-color: $grey-5;
    &__button {
      width: 100%;
      min-height: 52px;
    }
  }

  &__message {
    white-space: pre-wrap;
  }
}
</style>
