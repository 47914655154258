<template lang="pug">
.c-pm-property
  .c-pm-property__top
    button.c-pm-property__close(@click='close')
      svg.c-pm-property__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-pm-property__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-pm-property__content.o-transition__content
    h2.c-pm-property__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.name ? selected.name : model.propertyTranslations.newPropertyHeadline }}
    c-tabs.c-pm-property__tabs(:start-tab='0')
      c-tab(:name='model.propertyTranslations.aboutTabName')
        .c-pm-property__form.o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-8
              .c-pm-property__form-section
                .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.ownerSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-12
                      c-fc-select(:model='companyData' @validate='validate($event, "company")' v-model='mutableData.company.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                      c-fc-number(:model='mutableData.companyNumber' @validate='validate($event, "companyNumber")' v-model='mutableData.companyNumber.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                      c-fc-number(
                        :model='mutableData.propertyNumber'
                        @validate='validate($event, "propertyNumber")'
                        v-model='mutableData.propertyNumber.value'
                      )
              .c-pm-property__form-section
                .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.aboutSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12
                      c-fc-text(:model='mutableData.name' @validate='validate($event, "name")' v-model='mutableData.name.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
                      c-fc-select(:model='typeData' @validate='validate($event, "type")' v-model='mutableData.type.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
                      c-fc-number(:model='mutableData.year' @validate='validate($event, "year")' v-model='mutableData.year.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-text(:model='mutableData.title' @validate='validate($event, "title")' v-model='mutableData.title.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-wysiwyg(:model='mutableData.description' @validate='validate($event, "description")' v-model='mutableData.description.value')
              .c-pm-property__form-section
                .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.addressSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-7
                      .c-pm-property__autosuggest-wrap
                        c-fc-text(
                          :model='mutableData.street'
                          @blur='handleStreetBlur'
                          @change='handleStreetChange'
                          @focus='handleStreetFocus'
                          @validate='validate($event, "street")'
                          v-debounce.keyup='500'
                          v-model='mutableData.street.value'
                        )
                        .c-pm-property__autosuggest.o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--padding-micro(
                          ref='street-typeaheadList'
                          v-if='streetAutosuggest.show && streetAutosuggest.results.length > 0'
                        )
                          .o-grid__group
                            .o-grid__item(:key='key' v-for='(autoSuggestResult, key) in streetAutosuggest.results')
                              .c-pm-property__autosuggest-item
                                button.c-pm-property__autosuggest-item-name(@click='selectStreetAutoSuggestion(autoSuggestResult)' @mousedown.prevent) {{ streetSuggestionText(autoSuggestResult) }}
                    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3
                      c-fc-text(
                        :model='mutableData.streetNumberFrom'
                        @validate='validate($event, "streetNumberFrom")'
                        v-model='mutableData.streetNumberFrom.value'
                      )
                    .o-grid__item.o-grid__item--6.o-grid__item--desktop-2
                      c-fc-text(:model='mutableData.streetNumberTo' @validate='validate($event, "streetNumberTo")' v-model='mutableData.streetNumberTo.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-4
                      c-fc-number(:model='mutableData.postalCode' @validate='validate($event, "postalCode")' v-model='mutableData.postalCode.value')
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
                      c-fc-text(:model='mutableData.city' @validate='validate($event, "city")' v-model='mutableData.city.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-textbox(:model='mutableData.location' @validate='validate($event, "location")' v-model='mutableData.location.value')
                    .o-grid__item.o-grid__item--12
                      c-fc-text(:model='mutableData.commune' @validate='validate($event, "commune")' v-model='mutableData.commune.value')
              .c-pm-property__form-section
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--6
                      .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.facilitiesSectionHeadline }}
                      c-fc-checklist(
                        :model='facilitiesData'
                        @validate='validate($event, "facilities")'
                        v-if='facilitiesData.options.length > 0'
                        v-model='mutableData.facilities.value'
                      )
                    .o-grid__item.o-grid__item--6
                      .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.energyRankSectionHeadline }}
                      c-fc-radiolist(
                        :model='mutableData.energyAssessment'
                        @validate='validate($event, "energyAssessment")'
                        v-model='mutableData.energyAssessment.value'
                      )
              .c-pm-property__form-section
                .c-pm-property__form-heading.o-heading.o-heading--size-4 {{ model.propertyTranslations.waitingListSectionHeadline }}
                .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
                  .o-grid__group
                    .o-grid__item.o-grid__item--12
                      c-fc-checkbox(
                        :model='mutableData.excludeFromSearch'
                        @validate='validate($event, "excludeFromSearch")'
                        v-model='mutableData.excludeFromSearch.value'
                      ) 
                    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                      c-fc-date(:model='mutableData.adminStop' @validate='validate($event, "adminStop")' v-model='mutableData.adminStop.value')
        .c-pm-property__form.c-pm-property__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
          .o-grid__group
            .o-grid__item.o-grid__item--12.c-pm-property__form-section--border-top(v-if='selected.id && !selected.managedExternally')
              button.c-pm-property__delete.o-link.o-link--error(@click='handleDelete') {{ model.propertyTranslations.deletePropertyText }}
            .o-grid__item.o-grid__item--12.c-pm-property__form-section--border-top
              .o-grid.o-grid--gutter-small.o-grid--align-items-center
                .o-grid__group
                  .o-grid__item.o-grid__item--grow-0
                    button.o-link.c-pm-property__cancel(@click='close') {{ Dictionary.C.Cancel }}
                  .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                    button.o-button.c-pm-property__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(false)') {{ Dictionary.S.Save }}
                    button.o-button.c-pm-property__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
      c-tab(:disabled='!selected.id', :disabled-message='model.propertyTranslations.MediaDisableReason', :name='model.propertyTranslations.mediaTabName')
        .o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-8
              c-media-upload(:media='selected.media', :state-management='mediaStateManagement', :translations='model.media')
      c-tab(
        :disabled='!selected.id',
        :disabled-message='model.propertyTranslations.ownersTabDisableReason',
        :name='model.propertyTranslations.ownersTabName'
        v-if='selected.distribution && selected.distribution.owners && (selected.distribution.owners.length || selected.distribution.ownerTurns.length)'
      )
        .o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-12
              c-pm-distribution(
                :data='{ ownerData: selected.distribution.owners, ownerTurns: selected.distribution.ownerTurns }',
                :translations='{ headline: model.propertyTranslations.propertyOwnersHeadline, historyHeadline: model.propertyTranslations.propertyOwnersHistoryHeadline, countText: model.propertyTranslations.propertyOwnersResultsCountText, noResultsText: model.propertyTranslations.propertyOwnersNoResultsText }'
                type='owners'
              )
      c-tab(
        :disabled='!selected.id'
        name='PKA'
        v-if='selected.distribution && selected.distribution.subOrganizationOwners && (selected.distribution.subOrganizationOwners.length || selected.distribution.subOrganizationOwnerTurns.length)'
      )
        .o-grid.o-grid--gutter-large
          .o-grid__group
            .o-grid__item.o-grid__item--desktop-12
              c-pm-distribution(
                :data='{ ownerData: selected.distribution.subOrganizationOwners, ownerTurns: selected.distribution.subOrganizationOwnerTurns }',
                :translations='{ headline: model.propertyTranslations.splitOwnersHeadline, historyHeadline: model.propertyTranslations.splitOwnersHistoryHeadline, historyDescription: model.propertyTranslations.splitOwnersHistoryDescription, countText: model.propertyTranslations.propertyOwnersResultsCountText, noResultsText: model.propertyTranslations.propertyOwnersNoResultsText }'
                type='subOwners'
              )
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import FormControllChecklist from '@/components/form/controlls/form-checklist'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllRadioList from '@/components/form/controlls/form-radiolist'
import FormControllNumber from '@/components/form/controlls/form-number'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllWysiwyg from '@/components/form/controlls/form-wysiwyg'
import MediaUpload from '@/components/media-upload'
import PropertyManagementDistribution from '@/components/property-management/pm-distribution'
import { propertyManagementService } from '@/_services'
import moment from 'moment'
export default {
  name: 'c-pm-property',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-checklist': FormControllChecklist,
    'c-fc-date': FormControllDate,
    'c-fc-checkbox': FormControllCheckbox,
    'c-fc-radiolist': FormControllRadioList,
    'c-fc-number': FormControllNumber,
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-textbox': FormControllTextBox,
    'c-fc-wysiwyg': FormControllWysiwyg,
    'c-media-upload': MediaUpload,
    'c-pm-distribution': PropertyManagementDistribution
  },
  data() {
    return {
      id: this._uid,
      streetAutosuggest: {
        show: false,
        results: []
      },
      validation: {},
      mediaStateManagement: {
        store: 'propertyManagement',
        actionAdd: 'addMedia',
        actionRemove: 'removeMedia',
        actionMove: 'moveMedia'
      },
      mutableData: {
        company: {
          helperText: this.model.propertyTranslations.ownerHelperText,
          value: null,
          placeholderText: this.Dictionary.O.Owner,
          isMandatory: true,
          readOnly: false,
          options: this.companies,
          validation: null
        },
        companyNumber: {
          helperText: this.model.propertyTranslations.companyNumberHelperText,
          placeholderText: this.Dictionary.C.CompanyNumber,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        propertyNumber: {
          helperText: this.model.propertyTranslations.propertyNumberHelperText,
          placeholderText: this.Dictionary.P.PropertyNumber,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            minValue: 0,
            maxValue: 2137483647
          }
        },
        name: {
          helperText: this.model.propertyTranslations.nameHelperText,
          placeholderText: this.Dictionary.P.PropertyName,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        type: {
          helperText: this.model.propertyTranslations.typeHelperText,
          placeholderText: this.Dictionary.P.PropertyType,
          value: null,
          isMandatory: true,
          readOnly: false,
          options: [],
          validation: null
        },
        year: {
          helperText: this.model.propertyTranslations.yearHelperText,
          placeholderText: this.Dictionary.Y.YearOfConstruction,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1700,
            maxValue: moment.utc().add(5, 'y').format('YYYY')
          }
        },
        title: {
          helperText: this.model.propertyTranslations.descriptionTitleHelperText,
          placeholderText: this.Dictionary.D.DescriptionTitle,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        description: {
          helperText: this.model.propertyTranslations.descriptionHelperText,
          value: null,
          placeholderText: this.Dictionary.D.Description,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        street: {
          helperText: this.model.propertyTranslations.addressHelperText,
          value: null,
          placeholderText: this.Dictionary.S.Street,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 200
          },
          autocomplete: 'do-not-autofill'
        },
        streetNumberFrom: {
          helperText: this.model.propertyTranslations.streetNumberFromHelperText,
          value: null,
          placeholderText: this.Dictionary.N.NumberFrom,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 10
          },
          autocomplete: 'do-not-autofill'
        },
        streetNumberTo: {
          helperText: this.model.propertyTranslations.streetNumberToHelperText,
          value: null,
          placeholderText: this.Dictionary.N.NumberTo,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: '',
            maxLength: 10
          },
          autocomplete: 'do-not-autofill'
        },
        postalCode: {
          helperText: this.model.propertyTranslations.postNumberHelperText,
          value: null,
          placeholderText: this.Dictionary.P.PostalCode,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '\\d{4}',
            displayRule: '',
            minValue: 1000,
            maxValue: 10000
          }
        },
        city: {
          helperText: this.model.propertyTranslations.cityHelperText,
          value: null,
          placeholderText: this.Dictionary.C.City,
          readOnly: true,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        location: {
          helperText: this.model.propertyTranslations.locationHelperText,
          value: null,
          placeholderText: this.Dictionary.L.Location,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          },
          autocomplete: 'do-not-autofill'
        },
        commune: {
          helperText: this.model.propertyTranslations.communeHelperText,
          value: null,
          placeholderText: this.Dictionary.C.Commune,
          readOnly: true,
          validation: {
            errorMessage: '',
            displayRule: '',
            minLength: undefined,
            maxLength: undefined
          }
        },
        facilities: {
          helperText: this.model.propertyTranslations.facilitiesHelperText,
          placeholderText: this.Dictionary.P.PropertyFacilities,
          value: null,
          readOnly: false,
          isMandatory: false,
          options: [],
          validation: null
        },
        energyAssessment: {
          helperText: this.model.propertyTranslations.energyRankHelperText,
          value: null,
          placeholderText: this.Dictionary.E.EnergyMark,
          isMandatory: false,
          readOnly: false,
          options: [
            { name: 'A2020', value: 'A2020' },
            { name: 'A2015', value: 'A2015' },
            { name: 'A2010', value: 'A2010' },
            { name: 'B', value: 'B' },
            { name: 'C', value: 'C' },
            { name: 'D', value: 'D' },
            { name: 'E', value: 'E' },
            { name: 'F', value: 'F' },
            { name: 'G', value: 'G' },
            { name: this.Dictionary.N.NotSpecified, value: null }
          ],
          validation: null
        },
        excludeFromSearch: {
          value: false,
          helperText: this.model.propertyTranslations.waitingListHelperText,
          placeholderText: this.model.propertyTranslations.waitingListLabelText,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        adminStop: {
          helperText: this.model.propertyTranslations.administrationStopHelperText,
          value: null,
          placeholderText: this.Dictionary.T.To,
          isMandatory: false,
          readOnly: false,
          validation: null
        }
      }
    }
  },
  computed: {
    ...mapState('propertyManagement', ['selected', 'types']),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations']),
    ...mapState('loadingIndicator', ['loading']),
    // ...mapGetters('propertyManagement', ['submitmodels']),
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true)
    },
    companyData() {
      const companyData = Object.assign({}, this.mutableData.company)
      companyData.options = this.companies.map(company => {
        company.value = company.id
        return company
      })
      return companyData
    },
    selectedCompanyId() {
      return this.mutableData.company.value
    },
    selectedCompany() {
      return this.companies.find(c => c.id === this.companyData.value)
    },
    typeData() {
      const typeData = Object.assign({}, this.mutableData.type)
      typeData.options = this.types.map(type => {
        return { value: type.id, name: type.name }
      })
      return typeData
    },
    facilitiesData() {
      const facilitiesData = this.mutableData.facilities
      const propertyFacilities = this.facilities.filter(facility => {
        const location = facility.location || facility.facilityLocationId
        if (location === 'a39cc085-3043-48fd-a16a-7646fda3ec1a') {
          facility.value = facility.id
          return facility
        }
        return false
      })
      facilitiesData.options = propertyFacilities
      return facilitiesData
    },
    addressData() {
      const addressData = {
        postalCode: this.mutableData.postalCode.value,
        street: this.mutableData.street.value,
        streetNumberFrom: this.mutableData.streetNumberFrom.value,
        streetNumberTo: this.mutableData.streetNumberTo.value
      }

      return addressData
    },
    dataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case 'facilities':
            if (
              (this.selected[key] === undefined && (this.mutableData[key].value === null || this.mutableData[key].value.length === 0)) ||
              JSON.stringify(this.mutableData[key].value) === JSON.stringify(this.selected[key])
            ) {
              different = false
            } else {
              different = true
            }
            break
          case 'adminStop':
            different = this.mutableData[key].value && this.mutableData[key].value !== this.formatDate(this.selected[key], 'YYYY-MM-DD')
            break
          case 'city':
          case 'commune':
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }
        // console.log(different, key)
        return different
      })

      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {
    handleDelete() {
      this.$store.dispatch('propertyManagement/toggleDeleteConfirmation', this.selected.id)
    },
    close() {
      this.$store.dispatch('propertyManagement/removeSelected')
    },
    submitChanges(closeSelection) {
      if (this.selected.id) {
        this.$store.dispatch('propertyManagement/patchProperty', { data: this.getPropertyData(), closeSelection })
      } else {
        this.$store.dispatch('propertyManagement/createProperty', { data: this.getPropertyData(), closeSelection })
      }
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleStreetFocus() {
      this.streetAutosuggest.show = true
      // this.getAddressSuggestions(this.mutableData.street.value)
    },
    handleStreetChange: _.debounce(function () {
      if (this.mutableData.street.value) {
        this.getAddressSuggestions(this.mutableData.street.value)
      } else {
        this.streetAutosuggest.results = []
      }
    }, 250),
    handleStreetBlur() {
      setTimeout(() => {
        this.streetAutosuggest.show = false
      }, 100)
    },
    streetSuggestionText(suggestion) {
      const text = suggestion.street
        ? `${suggestion.street} ${suggestion.streetNumber}, ${suggestion.postalCode} ${suggestion.postalName}`
        : suggestion.suggestion

      return text
    },
    async getAddressSuggestions(address) {
      try {
        const response = await propertyManagementService.getAddressSuggestions(address)
        this.streetAutosuggest.results = response.data
      } catch (error) {
        // console.log(error)
      }
    },
    selectStreetAutoSuggestion(suggestion) {
      if (suggestion.street) {
        this.mutableData.street.value = suggestion.street
        if (suggestion.streetNumber) {
          this.mutableData.streetNumberFrom.value = suggestion.streetNumber
          this.mutableData.streetNumberTo.value = null
        }
        if (suggestion.postalCode) this.mutableData.postalCode.value = suggestion.postalCode
        this.streetAutosuggest.show = false
      } else {
        // console.log(suggestion)
        this.mutableData.street.value = suggestion.suggestion
        this.getAddressSuggestions(this.mutableData.street.value)
      }
    },
    getPrefilledData: _.debounce(async function () {
      if (this.addressData.postalCode && this.addressData.postalCode >= 1000 && this.addressData.street && this.addressData.streetNumberFrom) {
        try {
          const response = await propertyManagementService.getAddresses({
            postalCode: this.addressData.postalCode,
            streetName: this.addressData.street,
            streetNumberFrom: this.addressData.streetNumberFrom,
            streetNumberTo: this.addressData.streetNumberTo ? this.addressData.streetNumberTo : this.addressData.streetNumberFrom
          })
          if (response && response.status === 200) {
            this.mutableData.commune.value = response.data.commune
            this.mutableData.city.value = response.data.city
            if (!this.mutableData.year.value) {
              this.mutableData.year.value = response.data.year
            }
          }
        } catch (error) {
          // console.log(error)
        }
      }
    }, 250),
    getPropertyData() {
      const propertyData = {}
      Object.keys(this.mutableData).forEach(key => {
        if (key === 'companyNumber') return
        if (
          this.mutableData[key].value !== undefined &&
          this.mutableData[key].value !== null &&
          !(Array.isArray(this.mutableData[key].value) && this.mutableData[key].value.length === 0)
        ) {
          if (key === 'propertyNumber' || key === 'year' || key === 'postalCode') {
            propertyData[key] = parseFloat(this.mutableData[key].value)
          } else if (key !== 'city' && key !== 'commune') {
            propertyData[key] = this.mutableData[key].value
          }
        }
      })
      return propertyData
    },
    setSelected() {
      Object.keys(this.mutableData).forEach(key => {
        if (this.selected[key] !== undefined && this.selected[key] !== null) {
          switch (key) {
            case 'facilities':
              this.mutableData[key].value = Object.assign([], this.selected[key])
              break
            case 'adminStop':
              this.mutableData[key].value = this.formatDate(this.selected[key], 'YYYY-MM-DD')
              break
            default:
              this.mutableData[key].value = this.selected[key]
          }
        }

        if (!['title', 'description', 'commune', 'city', 'companyNumber', 'excludeFromSearch'].includes(key))
          this.mutableData[key].readOnly = this.selected.managedExternally
      })
      this.mutableMedia = JSON.parse(JSON.stringify(this.selected.media))
    }
  },
  created() {
    this.setSelected()
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  },
  watch: {
    addressData() {
      this.getPrefilledData()
    },
    selectedCompanyId(newVal, oldVal) {
      this.mutableData.companyNumber.value = oldVal === null ? this.selected.companyNumber || this.selectedCompany.number : this.selectedCompany.number
    }
  }
}
</script>
<style lang="scss" scoped>
.c-pm-property {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
