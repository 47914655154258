/* eslint-disable prettier/prettier */
<template lang="pug">
.c-vj-overview
  .c-vj-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  //- .c-vj-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  //-   .o-grid__group 
  //-     .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
  //-       c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
  //-     .o-grid__item.o-grid__item--desktop-2
  //-       button.o-button.c-vj-search__button(@click.lazy='handleSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
  //-     .o-grid__item.o-grid__item--desktop-1
  //-       button.o-link.c-vj-search__button(:disabled='!search.value' @click.lazy='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
  .c-vj-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-vj-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettings', :rows='results' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      //- template(slot='table-row' slot-scope='props')
      //-   template(v-if='props.column.field == "responsible"')
      //-     span {{ getRTesponsible(props.row) }}
      //-   template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import FormControllText from '@/components/form/controlls/form-text'
import { searchService, auditsService } from '@/_services'
export default {
  name: 'c-vj-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      resetPaging: false,
      totalResults: 0,
      // search: {
      //   helperText: this.model.searchHelperText,
      //   placeholderText: this.model.searchPlaceholderText,
      //   value: null,
      //   isMandatory: false,
      //   validation: null
      // },
      results: [],
      columns: [
        {
          label: this.Dictionary.O.Owner,
          field: 'integrationJobOwner'
        },
        {
          label: this.Dictionary.J.JobDescription,
          field: 'integrationJobDescription'
        },
        {
          label: this.Dictionary.F.Frequency,
          field: 'integrationJobFrequency'
        },
        {
          label: this.Dictionary.L.LastRun,
          field: 'integrationJobLastRun',
          formatFn: this.reFormatDate,
          width: '110px'
        }
      ]
    }
  },
  components: {
    VueGoodTable,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value, 'DD-MM-YYYY HH:mm') : ''
    },
    async handleSearch() {
      try {
        const responseJobs = await searchService.getIntegrationJobs()
        let audits = []
        if (responseJobs && responseJobs.status === 200) {
          const responseAudits = await auditsService.getAuditsIntegrationJobs()
          if (responseAudits && responseAudits.status === 200) {
            audits = responseAudits.data
          }
          this.results = responseJobs.data.map(result => {
            const lastRunItem = audits.find(audit => audit.integrationJobName === result.integrationJobName)
            result.integrationJobLastRun = (lastRunItem && lastRunItem.lastRun) || null
            return result
          })
          this.totalResults = responseJobs.data.length
        }
      } catch (error) {
        // console.log(error)
      }
    },
    handleReset() {
      this.search.value = null
      this.handleSearch()
    }
  },
  created() {
    this.handleSearch()
  }
}
</script>
<style lang="scss" scoped>
.c-vj-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
}
.c-vj-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
