import Vue from 'vue'
import Store from '../_store'
import _ from 'lodash'

Vue.directive('debounce', {
  name: 'debounce',
  inserted(el, binding) {
    if (binding.value !== binding.oldValue) {
      // change debounce only if interval has changed
      el.oninput = _.debounce(() => {
        if (Object.keys(binding.modifiers).length > 0) {
          Object.keys(binding.modifiers).forEach(key => {
            el.dispatchEvent(new Event(key))
          })
        } else {
          el.dispatchEvent(new Event('change'))
        }
      }, parseInt(binding.value) || 500)
    }
  }
})

Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

Vue.directive('requiresAuthentication', {
  name: 'requiresAuthentication',
  inserted(el, binding) {
    el.addEventListener(
      binding.arg,
      event => {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        // if (binding.modifiers['self'] && event.target !== el) {
        //   return
        // }
        // if (binding.modifiers['stop']) {
        // }

        Store.dispatch('user/authenticateAndProceed', binding.value)
      },
      true
    )
  }
})

Vue.directive('sensitiveInfo', {
  name: 'sensitiveInfo',
  inserted(el, binding) {
    if (binding.value) {
      const cta = document.createElement('button')
      cta.classList.add('o-link', 'o-link--small', 'o-sensitive__cta')
      cta.classList.add('o-sensitive__cta')
      cta.innerHTML = window.dictionary.S.ShowSensitive
      const text = document.createElement('span')

      const valueArray = binding.value.split('')
      valueArray.forEach((letter, index) => {
        if (index >= valueArray.length - parseInt(binding.arg)) {
          valueArray[index] = '•'
        }
      })

      const sensitiveValue = valueArray.join('')
      text.innerText = sensitiveValue
      el.appendChild(text)
      cta.addEventListener(
        'click',
        () => {
          text.innerText = binding.value
          el.classList.add('o-sensitive--visible')
          setTimeout(() => {
            text.innerText = sensitiveValue
            el.classList.remove('o-sensitive--visible')
          }, 5000)
        },
        true
      )
      el.appendChild(cta)
    }
  }
})

Vue.directive('charactercount', {
  name: 'charactercount',
  inserted(el) {
    const triggerEvents = ['input']
    const targetCounter = el.parentElement.getElementsByClassName('o-label--counter')
    const maxLength = el.maxLength

    if (targetCounter && maxLength > 0) {
      targetCounter[0].innerText = maxLength ? el.value.length + ' / ' + maxLength : ''

      triggerEvents.forEach(event => {
        el.addEventListener(event, () => {
          targetCounter[0].innerText = maxLength ? el.value.length + ' / ' + maxLength : ''
        })
      })
    }
  }
})

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    setTimeout(document.body.addEventListener('click', el.clickOutsideEvent), 500)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
