<template lang="pug">
.c-filter-input-number
  .o-bodytext.o-bodytext--size-medium.o-bodytext--700(v-if='model && model.headingText') {{ model.headingText }}
  .o-input__container
    label.o-label.o-label--before(:for='id' v-if='model && model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(:id='id', :name='this.model.filter', :value='value' @keyup='valueChanged' placeholder=' ' type='number' v-debounce.keyup='250')
      label.o-label.o-label--placeholder(:for='id' v-if='model && model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--helper(:for='id' v-if='model && model.helperText') {{ model.helperText }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-filter-input-number',
  data() {
    return {
      id: this._uid,
      value: ''
    }
  },
  props: {
    model: {
      type: Object
    }
  },
  computed: {
    ...mapState('search', ['filters']),
    ...mapGetters('search', ['searchFilterRent'])
  },
  methods: {
    valueChanged(event) {
      this.$store.dispatch('search/addFilter', { filter: this.model.filter, value: parseInt(event.target.value) || 0 })
    }
  },
  created() {
    this.value = this.filters[this.model.filter] > 0 ? this.filters[this.model.filter] : ''
  },
  watch: {
    searchFilterRent(newValue) {
      if (newValue !== this.value) {
        this.value = newValue === 0 ? '' : newValue
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-number {
}
</style>
