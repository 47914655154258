<template lang="pug">
.spot(:class='"spot--" + theme')
  .spot__image-wrap(v-if='imageUrl')
    img.spot__image(:src='imageUrl', :title='imageTitle')
  .spot__inner-wrap
    svg.o-svg-icon.spot__icon(
      :class='"spot__icon--" + theme',
      :style='{ height: iconSize ? iconSize : "60px" }'
      type='image/svg+xml'
      v-if='!imageUrl && iconName'
      viewbox='0 0 64 64'
    )
      use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${iconName}`' x='0' y='0')
    .spot__headline(v-if='hasHeadline')
      slot(name='headline')
    .spot__text(v-if='hasText')
      slot(name='text')
    .spot__button-wrap(v-if='linkUrl')
      a.o-button(:class='theme === "ocean" ? "o-button--inverted" : ""', :href='linkUrl') {{ linkText }}
</template>
<script>
export default {
  name: 'c-spot',
  props: {
    headline: String,
    text: String,
    imageUrl: String,
    imageTitle: String,
    iconName: String,
    iconSize: String,
    theme: String,
    linkUrl: String,
    linkText: String
  },
  computed: {
    hasHeadline() {
      return !!this.$slots.headline
    },
    hasText() {
      return !!this.$slots.text
    }
  }
}
</script>
<style lang="scss" scoped>
.spot {
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
  // margin: toRem(8px);  //TODO CHECK WITH JONAS
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__inner-wrap {
    padding: toRem(24px) toRem(24px) toRem(50px) toRem(24px);
    display: flex;
    flex-direction: column;
  }

  &__image {
    max-width: 100%;
    width: 100%;
  }

  &__link {
    font-weight: 600;
    text-decoration: underline;
    color: $ocean-primary;
  }

  &--ocean {
    background-color: $ocean-primary;
    color: $white;
  }

  &--crab {
    background-color: $crab-primary;
    color: $ocean-primary;
  }

  &--white {
    background-color: $white;
    color: $ocean-primary;
    border: 1px solid $grey-4;
  }

  &__headline {
    margin-bottom: toRem(26px);
  }

  &__text {
    margin-bottom: toRem(40px);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__image-wrap {
    display: flex;
    width: 100%;
  }
  &__icon {
    margin-bottom: toRem(20px);

    &--ocean {
      stroke: $crab-primary;
    }

    &--crab {
      stroke: $ocean-primary;
    }

    &--white {
      stroke: $crab-primary;
    }
  }
  &__button-wrap {
    margin-top: auto;
    text-align: center;
  }
}
</style>
