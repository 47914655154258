import apiClient from '@/_helpers/api-client'
export const ub4SumCodesManagementService = {
  getUb4SumCodes,
  postUb4SumCode,
  deleteUb4SumCode
}

async function getUb4SumCodes() {
  return await apiClient({
    url: '/data/ub/sum-codes',
    method: 'GET'
  })
}

async function postUb4SumCode(data) {
  return await apiClient({
    url: '/data/ub/sum-codes',
    method: 'POST',
    data
  })
}

async function deleteUb4SumCode({ sumCode }) {
  return await apiClient({
    url: `/data/ub/sum-codes/${sumCode}`,
    method: 'DELETE'
  })
}
