import { messagesService } from '@/_services'
import _ from 'lodash'

import { isMobile } from 'mobile-device-detect'
import { getQueryParameters } from '@/_helpers/query-parameters'
import { asyncForEach } from '@/_helpers'

const initialState = {
  role: 'Any',
  filters: {
    search: '',
    companyId: (getQueryParameters().get('companyId') && getQueryParameters().get('companyId').split(',')) || '',
    user: (getQueryParameters().get('user') && getQueryParameters().get('user').split(',')) || '',
    type: getQueryParameters().get('type') || '',
    status: getQueryParameters().get('status') || '',
    relatedEntityStates: (getQueryParameters().get('relatedEntityStates') && getQueryParameters().get('relatedEntityStates').split(',')) || '',
    relatedEntities: (getQueryParameters().get('relatedEntities') && getQueryParameters().get('relatedEntities').split(',')) || '',
    includeArchived: false
  },
  activeThread: undefined,
  hasApplicantRole: false,
  hasPublisherRole: false,
  orderBy: 'score',
  orderDirection: 'DESC',
  pageSize: 10,
  page: 0,
  results: [],
  totalResults: 0,
  unreadMessagesCount: undefined
}

const emptyThread = {
  archived: false,
  id: undefined,
  messages: [],
  relatedEntity: {
    id: undefined,
    type: undefined
  },
  title: '',
  users: []
}

export const messages = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state
  },
  actions: {
    getResultsPage: _.debounce(async function ({ commit, state }, { page, override }) {
      commit('setPage', page)
      try {
        const data = {
          page: state.page,
          pageSize: state.pageSize,
          // orderBy: state.orderBy,   // BE does not support this
          orderDirection: state.orderDirection
        }

        Object.keys(state.filters).forEach(filter => {
          if (state.filters[filter]) {
            data[filter] = state.filters[filter]
          }
        })
        if (data.companyId) {
          data.company = data.companyId
          delete data.companyId
        }

        const response = await messagesService.postThreads(data)
        commit('setAvailableMessageRole', { hasApplicantRole: response.data.hasApplicantRole, hasPublisherRole: response.data.hasPublisherRole })
        if (override) {
          commit('overrideResults', response.data.results)
        } else {
          commit('addResults', response.data.results)
        }
        commit('setTotalResults', response.data.totalResults)
        if (override || !state.activeThread) {
          if (!isMobile && response.data.results.length > 0) {
            this.dispatch('messages/selectThread', response.data.results[0].id)
          } else {
            commit('clearActiveThread')
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    selectThread: _.debounce(async function ({ commit }, payload) {
      try {
        const response = await messagesService.getThread(payload)

        commit('setActiveThread', response.data)

        this.dispatch('messages/getUnreadCount')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    async getUnreadCount({ commit }) {
      try {
        const response = await messagesService.getUnreadCount()
        commit('setUnreadCount', response.data)
      } catch (error) {
        // console.log(error)
      }
    },
    postSendToPropertyOwner: _.debounce(async function ({ commit }, payload) {
      try {
        const response = await messagesService.postSendToPropertyOwner(payload)
        commit('setActiveThread', response.data)
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    selectThreadRelatedToEntity: _.debounce(async function ({ commit }, payload) {
      try {
        let response
        const thread = JSON.parse(JSON.stringify(emptyThread))
        thread.relatedEntity.id = payload.id
        thread.title = payload.name
        thread.users = payload.users || []
        if (typeof payload.users !== 'undefined' && payload.users.length > 1) {
          commit('setActiveThread', thread)
        } else {
          if (typeof payload.users !== 'undefined' && payload.users[0]) {
            const data = { id: payload.id, name: payload.name, userId: payload.users[0].id }
            response = await messagesService.getThreadRelatedToEntityWithUser(data)
          } else {
            response = await messagesService.getThreadRelatedToEntity(payload.id)
          }

          if (response && response.status === 200) commit('setActiveThread', response.data)
          if (response && response.status === 204) {
            commit('setActiveThread', thread)
          }
        }

        this.dispatch('messages/getUnreadCount')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    sendMessageToThread: _.debounce(async function ({ commit }, payload) {
      try {
        let response

        if (payload.userId) {
          response = await messagesService.postSendMessageRelatedToEntityWithUser(payload)
          if (response && response.status === 200) commit('setActiveThread', response.data)
        } else {
          response = await messagesService.postSendMessageToThread(payload)
          commit('sendMessageToThread', response.data)
        }
      } catch (error) {
        // commit('clearActiveThread')
        // this.dispatch('messages/getResultsPage', { page: 0, override: true })
      }
    }, 250),
    sendMessagesToMultipleUsers: _.debounce(async function ({ state, commit }, payload) {
      try {
        await asyncForEach(state.activeThread.users, async user => {
          await messagesService.postSendMessageRelatedToEntityWithUser({
            id: state.activeThread.relatedEntity.id,
            userId: user.id,
            formData: payload
          })
        })
        commit('clearActiveThread')
      } catch (error) {
        // commit('clearActiveThread')
      }
    }, 250),
    archiveMessage: _.debounce(async function ({ commit }, payload) {
      try {
        const response = await messagesService.archiveThread(payload.id)
        commit('clearActiveThread', response)
        this.dispatch('messages/getResultsPage', { page: 0, override: true })
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    unarchiveMessage: _.debounce(async function ({ commit }, payload) {
      try {
        const response = await messagesService.unarchiveThread(payload.id)
        commit('clearActiveThread', response)
        this.dispatch('messages/getResultsPage', { page: 0, override: true })
        // console.log(response)
      } catch (error) {
        // console.log(error)
      }
    }, 250), 
    markMessageAsUnread: _.debounce(async function ({ commit }, payload) {
      try {
          const response = await messagesService.markThreadAsUnread(payload.id, payload.userId, payload.propertyAdministratorId)
          commit('overrideActiveThread', response.data)
      } catch (error) {
      }
    }, 250),
    clearActiveThread({ commit }) {
      commit('clearActiveThread')
    },
    setSorting({ commit }, payload) {
      commit('setSorting', payload)
      this.dispatch('messages/getResultsPage', { page: 0, override: true })
    },
    postSearch({ commit }, payload) {
      commit('setSearchFilters', payload)
      this.dispatch('messages/getResultsPage', { page: 0, override: true })
    }
  },
  mutations: {
    setPage(state, payload) {
      state.page = payload
    },
    setActiveThread(state, payload) {
      state.activeThread = Object.assign({}, payload)
      const activeResult = state.results.find(result => result.id === payload.id)
      if (activeResult) activeResult.hasUnreadMessages = false
    },
    setUnreadCount(state, payload) {
      state.unreadMessagesCount = payload
    },
    clearActiveThread(state) {
      state.activeThread = undefined
    },
    overrideActiveThread(state, payload) {
      state.activeThread = Object.assign({}, payload)
        const activeResult = state.results.find(result => result.id === state.activeThread.id)
        if (activeResult) {
            activeResult.lastMessage = Object.assign({}, payload.messages[payload.messages.length - 1])
            activeResult.hasUnreadMessages = true
        }
    },
    setTotalResults(state, payload) {
      state.totalResults = payload
    },
    overrideResults(state, payload) {
      state.results = Object.assign([], payload)
    },
    addResults(state, payload) {
      payload.forEach(item => state.results.push(item))
    },
    setSearchFilters(state, payload) {
      Object.keys(payload).forEach(k => {
        state.filters[k] = payload[k]
      })
    },
    setSorting(state, payload) {
      state.orderBy = payload.orderBy
      state.orderDirection = payload.orderDirection
    },
    setAvailableMessageRole(state, payload) {
      state.hasApplicantRole = payload.hasApplicantRole
      state.hasPublisherRole = payload.hasPublisherRole
    },
    sendMessageToThread(state, payload) {
      state.activeThread.messages.unshift(payload)
      const activeResult = state.results.find(result => result.id === state.activeThread.id)
      if (activeResult) activeResult.lastMessage = Object.assign({}, payload)
    }
  }
}
