import { administratorsManagementService } from '@/_services'

const initialState = {
  administrators: [],
  selected: undefined,
  confirmationTarget: undefined
}

const initialSelected = {
  id: undefined
}

export const administratorsManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {},
  actions: {
    async getPropertyAdministrators({ commit }) {
      try {
        const responseAdministrators = await administratorsManagementService.getPropertyAdministrators()

        if (responseAdministrators && responseAdministrators.status === 200) {
          commit('updateData', {
            administrators: responseAdministrators.data
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async setSelected({ commit }, { id, companyId }) {
      if (id) {
        try {
          const response = await administratorsManagementService.getPropertyAdministrator(id)
          if (response && response.status === 200) {
            response.data.companyId = companyId
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    createNew({ commit }) {
      commit('createNew')
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createPropertyAdministrator({ commit }, { data, closeSelection }) {
      try {
        const response = await administratorsManagementService.createPropertyAdministrator(data)
        if (response && response.status === 200) {
          const selected = { id: response.data }

          if (closeSelection) {
            commit('removeSelected')
          } else {
            commit('updateData', selected)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchPropertyAdministrator({ commit, state }, { data, closeSelection }) {
      try {
        const response = await administratorsManagementService.patchPropertyAdministrator({ data, id: state.selected.id })
        if (response && response.status === 204) {
          const selected = { ...state.selected, ...data }
          if (closeSelection) {
            commit('removeSelected')
          } else {
            commit('updateData', selected)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deletePropertyAdministrator({ commit, state }) {
      try {
        const response = await administratorsManagementService.deletePropertyAdministrator(state.selected.id)
        if (response && response.status === 204) {
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleConfirmation(state, payload) {
      state.confirmationTarget = payload
    }
  }
}
