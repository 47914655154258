<template lang="pug">
form.reset-password(@submit='resetPassword')
  template(v-if='!showSuccess')
    h3.reset-password__heading.o-heading--size-3 {{ Dictionary.LoginFlow.ResetPasswordHeadline }}
    p.reset-password__text {{ Dictionary.LoginFlow.ResetPasswordText }}
    .o-input__container
      .o-input__wrap
        input#passwordInputResetPassword.o-input(
          name='passwordInputResetPassword'
          placeholder=' '
          ref='passwordInputResetPassword'
          required
          type='password'
          v-model='password'
          v-on:keyup='validatePassword'
        )
        label.o-label.o-label--placeholder(for='passwordInputResetPassword') {{ Dictionary.N.NewPassword }}
    p.reset-password__help-text {{ Dictionary.LoginFlow.ResetPasswordInstruction }}
    button.o-button.o-button--inverted.o-button--full-width(:disabled='!this.valid' type='submit') {{ Dictionary.S.Send }}
    p.reset-password__message(role='alert' v-show='showError') {{ Dictionary.LoginFlow.ResetPasswordError }}
  template(v-else)
    p.reset-password__message(role='status') {{ statusText }}
    button.reset-password__link.o-link.o-link--500.o-link--opensans.o-link--crab(@click='$emit("resetFlow")' type='button' v-if='showSuccess') {{ Dictionary.LoginFlow.LoginWithNyPassword }}
</template>
<script>
import { userService } from '@/_services'
export default {
  name: 'c-reset-password',
  props: {
    token: String
  },
  data() {
    return {
      password: '',
      valid: false,
      showError: false,
      showSuccess: false
    }
  },
  computed: {
    statusText() {
      return this.showSuccess ? this.Dictionary.LoginFlow.ResetPasswordSuccess : ''
    }
  },
  methods: {
    resetPassword(e) {
      e.preventDefault()
      this.showError = false
      if (this.valid) {
        this.updatePassword()
      }
    },
    async updatePassword() {
      try {
        const response = await userService.updatePassword(this.password, this.token)
        if (response && response.status === 200) {
          this.showSuccess = true
        }
      } catch (error) {
        this.showError = true
      }
    },
    validatePassword: function (event) {
      this.showError = false
      this.valid = this.validPassword(this.password)
      if (this.valid) {
        event.target.setCustomValidity('')
      } else {
        event.target.setCustomValidity(' ')
      }
    }
  },
  mounted() {
    this.$refs.passwordInputResetPassword.focus()
  }
}
</script>

<style lang="scss" scoped>
.reset-password {
  &__link {
    @include setStyles('14', $s-typography-sizes);
    margin-top: toRem(20px);
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  &__actions-container {
    margin-bottom: toRem(20px);
  }
  &__heading {
    margin-top: toRem(20px);
    font-family: 'Inria Serif', serif;
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__text {
    @include setStyles('18', $s-typography-sizes);
    margin-bottom: toRem(43px);
    text-align: center;
  }
  &__message {
    @include setStyles('18', $s-typography-sizes);
    text-align: center;
  }
  &__help-text {
    @include setStyles('14', $s-typography-sizes);
    margin-bottom: toRem(20px);
    margin-top: toRem(-15px);
    opacity: 0.7;
    text-align: left;
  }
}
.o-input__container,
.o-button {
  margin-bottom: toRem(20px);
}
</style>
