<template lang="pug">
.c-my-page
  .c-my-page__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    .c-my-page__filters.o-grid.o-grid--gutter-small.o-grid--align-items-stretch
      .o-grid__group
        .o-grid__item.o-grid__item--6
          c-fc-vselect.c-my-page__filter(:model='responsible' v-model='responsible.value')
        .o-grid__item.o-grid__item--6
          c-fc-vselect.c-my-page__filter(:model='company' v-model='company.value')
  .c-my-page__data
    .c-my-page__section(v-for='(section, sectionIndex) in sections')
      .o-heading.o-heading--size-3.o-heading--profile-page {{ section.title }}
      .o-grid.o-grid--gutter-small.o-grid--align-items-stretch
        .o-grid__group
          .o-grid__item(:class='getColumnClass(sectionIndex)' v-for='column in section.columns')
            .o-grid.o-grid--gutter-horizontal-large.o-grid--gutter-vertical-small.o-grid--justify-content-start.c-my-page__box
              .o-grid__group
                template(v-for='(action, actionIndex) in column.actions')
                  .c-my-page__item.o-grid__item(:class='getRowClass(sectionIndex)')
                    .o-grid.o-grid--gutter-small.o-grid--wrap-nowrap.o-grid--align-items-center(
                      :class='{ "c-my-page__item-border": actionIndex === 0 || Math.abs(actionIndex % 2) == 1 }'
                    )
                      .o-grid__group
                        .o-grid__item
                          a.c-my-page__link(:href='getHref(action, null)' target='_blank')
                            .o-grid.o-grid--gutter-small.o-grid--wrap-nowrap.o-grid--align-items-center(
                              :style='{ overflow: "hidden" }'
                              v-if='action.count !== null'
                            )
                              .o-grid__group
                                .o-grid__item
                                  .o-bodytext.o-bodytext--size-medium.o-bodytext--inria {{ action.text }}
                                .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                                  span {{ action.count }}
                                .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                                  svg.o-svg-icon.c-my-page__icon(type='image/svg+xml' viewbox='0 0 64 64')
                                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
                        .o-grid__item.o-grid__item--grow-0(v-if='action.detailedCount && action.detailedCount.length > 0')
                          button.c-my-page__item-details-cta(@click='showDetails(action.name)')
                            svg.o-svg-icon.c-my-page__icon(type='image/svg+xml' viewbox='0 0 64 64')
                              use(
                                :xlink:href='`/app/img/spritemap.svg#sprite-icon-chevron-${openDetails && openDetails.includes(action.name) ? "up" : "down"}`'
                                x='0'
                                y='0'
                              )
                  .c-my-page__item.o-grid__item(
                    :class='getRowClass(sectionIndex)'
                    v-if='openDetails && openDetails.includes(action.name) && action.detailedCount && action.detailedCount.length > 0'
                  )
                    .o-grid.o-grid--direction-column.o-grid--gutter-small.o-grid--wrap-nowrap.o-grid--align-items-stretch.c-my-page__item-border
                      .o-grid__group
                        .o-grid__item(v-for='actionDetail in action.detailedCount')
                          a.c-my-page__link.c-my-page__item-border(:href='getHref(action, actionDetail.key)' target='_blank')
                            .o-grid.o-grid--gutter-small.o-grid--wrap-nowrap.o-grid--align-items-center(:style='{ overflow: "hidden" }')
                              .o-grid__group
                                .o-grid__item
                                  .o-bodytext.o-bodytext--size-medium.o-bodytext--inria {{ getCompanyName(actionDetail.key) }}
                                .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                                  span {{ actionDetail.value }}
                                .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                                  svg.o-svg-icon.c-my-page__icon(type='image/svg+xml' viewbox='0 0 64 64')
                                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
  .c-search-result__loading(v-if='loading')
    c-loading-animation(:overlay='true')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import { setQueryParameters } from '@/_helpers/query-parameters'
import { messagesService, offersManagementService, advertManagementService, residenceManagementService } from '@/_services'
export default {
  name: 'c-my-page',
  props: {
    model: Object
  },
  components: {
    'c-fc-vselect': FormControllVSelect
  },
  data() {
    return {
      myPageData: this.$cookies.get('myPageData') || { openDetails: [], selectedResponsible: [], selectedCompany: [] },
      responsible: {
        helperText: this.model.selectedUserHelperText,
        value: (this.$cookies.get('myPageData') && this.$cookies.get('myPageData').selectedResponsible) || [],
        readonly: false,
        placeholderText: this.Dictionary.R.Responsible,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      },
      company: {
        helperText: this.model.selectedCompanyHelperText,
        readonly: false,
        value: (this.$cookies.get('myPageData') && this.$cookies.get('myPageData').selectedCompany) || [],
        placeholderText: this.Dictionary.O.Owner,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      },
      sections: [
        {
          title: this.model.myPageTranslations.offersTitle,
          columns: [
            {
              actions: [
                {
                  text: this.model.myPageTranslations.offersAvailableResidencesText,
                  name: 'offersAvailableResidences',
                  count: null,
                  detailedCount: [],
                  endpoint: residenceManagementService.searchManagedResidences,
                  link: this.model.residencesPage,
                  data: {
                    filters: {
                      availableFrom: '[*;*]',
                      rentModel: 'WaitingList',
                      propertyCompanyId: '',
                      residenceAdvertId: '-[*;*]',
                      activeOfferId: '-[*;*]'
                    },
                    facets: ['PropertyCompanyId']
                  }
                },

                {
                  text: this.model.myPageTranslations.offersResidencesWith3OrMoreDaysAvailabilityText,
                  name: 'offersResidencesWith3OrMoreDaysAvailability',
                  count: null,
                  detailedCount: [],
                  endpoint: residenceManagementService.searchManagedResidences,
                  link: this.model.residencesPage,
                  data: {
                    filters: {
                      availableFrom: '[*;*]',
                      rentModel: 'WaitingList',
                      propertyCompanyId: '',
                      residenceAdvertId: '-[*;*]',
                      activeOfferId: '-[*;*]',
                      availableFromLastUpdated: '[*;NOW-3DAYS]'
                    },
                    facets: ['PropertyCompanyId']
                  }
                }
              ]
            },
            {
              actions: [
                {
                  text: this.model.myPageTranslations.offersSentOutText,
                  name: 'offersSentOut',
                  count: null,
                  detailedCount: [],
                  endpoint: offersManagementService.searchOffers,
                  link: this.model.offersPage,
                  data: {
                    filters: {
                      state: ['Published', 'Changed'],
                      companyId: '',
                      responsibleUserId: ''
                    },
                    facets: ['CompanyId']
                  }
                },
                {
                  text: this.model.myPageTranslations.offersSentOutWithUnreadMessagesText,
                  name: 'offersSentOutWithUnreadMessages',
                  count: null,
                  detailedCount: [],
                  endpoint: offersManagementService.searchOffers,
                  link: this.model.offersPage,
                  data: {
                    filters: {
                      state: ['Published', 'Changed'],
                      hasUnreadMessages: true,
                      companyId: '',
                      responsibleUserId: ''
                    },
                    facets: ['CompanyId']
                  }
                }
              ]
            },
            {
              actions: [
                {
                  text: this.model.myPageTranslations.offersFinishedText,
                  name: 'offersFinished',
                  count: null,
                  detailedCount: [],
                  endpoint: offersManagementService.searchOffers,
                  link: this.model.offersPage,
                  data: {
                    filters: {
                      state: ['Finished'],
                      companyId: '',
                      responsibleUserId: ''
                    },
                    facets: ['CompanyId']
                  }
                },
                {
                  text: this.model.myPageTranslations.offersFinishedWithUnreadMessagesText,
                  name: 'offersFinishedWithUnreadMessages',
                  count: null,
                  detailedCount: [],
                  endpoint: offersManagementService.searchOffers,
                  link: this.model.offersPage,
                  data: {
                    filters: {
                      state: ['Finished'],
                      hasUnreadMessages: true,
                      companyId: '',
                      responsibleUserId: ''
                    },
                    facets: ['CompanyId']
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.model.myPageTranslations.advertsTitle,
          columns: [
            {
              actions: [
                {
                  text: this.model.myPageTranslations.advertsAvailableResidencesText,
                  name: 'advertsAvailableResidencesText',
                  count: null,
                  detailedCount: [],
                  endpoint: residenceManagementService.searchManagedResidences,
                  link: this.model.residencesPage,
                  data: {
                    filters: {
                      availableFrom: '[*;*]',
                      rentModel: ['Advert', 'MemberAdvert'],
                      propertyCompanyId: '',
                      residenceAdvertId: '-[*;*]'
                    },
                    facets: ['PropertyCompanyId']
                  }
                },

                {
                  text: this.model.myPageTranslations.advertsResidencesWith3OrMoreDaysAvailabilityText,
                  name: 'advertsResidencesWith3OrMoreDaysAvailability',
                  count: null,
                  detailedCount: [],
                  endpoint: residenceManagementService.searchManagedResidences,
                  link: this.model.residencesPage,
                  data: {
                    filters: {
                      availableFrom: '[*;*]',
                      rentModel: ['Advert', 'MemberAdvert'],
                      propertyCompanyId: '',
                      residenceAdvertId: '-[*;*]',
                      availableFromLastUpdated: '[*;NOW-3DAYS]'
                    },
                    facets: ['PropertyCompanyId']
                  }
                }
              ]
            },
            {
              actions: [
                {
                  text: this.model.myPageTranslations.advertsWithNewApplicantsText,
                  name: 'advertsWithNewApplicants',
                  count: null,
                  detailedCount: [],
                  endpoint: advertManagementService.searchAdverts,
                  link: this.model.advertsPage,
                  data: {
                    filters: {
                      newApplicants: '[1;*]',
                      status: ['Published', 'PublishedNotScreened', 'DeadlineMet', 'Reserved'],
                      companyId: '',
                      creator: ''
                    },
                    facets: ['CompanyId']
                  }
                },
                {
                  text: this.model.myPageTranslations.advertsWithNewApplicantsAndUnreadMessagesText,
                  name: 'advertsWithNewApplicantsAndUnreadMessages',
                  count: null,
                  detailedCount: [],
                  endpoint: advertManagementService.searchAdverts,
                  link: this.model.advertsPage,
                  data: {
                    filters: {
                      status: ['Published', 'PublishedNotScreened', 'DeadlineMet', 'Reserved'],
                      hasUnreadMessages: true,
                      companyId: '',
                      creator: ''
                    },
                    facets: ['CompanyId']
                  }
                }
              ]
            },
            {
              actions: [
                {
                  text: this.model.myPageTranslations.advertsWithApplicationDeadlineMetText,
                  name: 'advertsWithApplicationDeadlineMet',
                  count: null,
                  detailedCount: [],
                  endpoint: advertManagementService.searchAdverts,
                  link: this.model.advertsPage,
                  data: {
                    filters: {
                      status: ['DeadlineMet', 'Expired'],
                      companyId: '',
                      creator: ''
                    },
                    facets: ['CompanyId']
                  }
                },
                {
                  text: this.model.myPageTranslations.advertsWithApplicationDeadlineMetAndUnreadMessagesText,
                  name: 'advertsWithApplicationDeadlineMetUnread',
                  count: null,
                  detailedCount: [],
                  endpoint: advertManagementService.searchAdverts,
                  link: this.model.advertsPage,
                  data: {
                    filters: {
                      status: ['Expired', 'RentedOut', 'RetiredFromRentedOut', 'Retired'],
                      hasUnreadMessages: true,
                      companyId: '',
                      creator: ''
                    },
                    facets: ['CompanyId']
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.model.myPageTranslations.inboxTitle,
          columns: [
            {
              actions: [
                {
                  text: this.model.myPageTranslations.inboxAdvertsMessagesText,
                  name: 'inboxAdverts',
                  count: null,
                  detailedCount: [],
                  endpoint: messagesService.postThreadsCount,
                  link: this.model.messagesPage,
                  data: {
                    filters: {
                      status: 'Unread',
                      type: 'ResidenceAdvert',
                      companyId: '',
                      user: ''
                    }
                  }
                },
                {
                  text: this.model.myPageTranslations.inboxOffersCompletedText,
                  name: 'inboxOffersCompleted',
                  count: null,
                  detailedCount: [],
                  endpoint: messagesService.postThreadsCount,
                  link: this.model.messagesPage,
                  data: {
                    filters: {
                      type: 'Offer',
                      relatedEntityStates: ['Finished'],
                      companyId: '',
                      user: '',
                      status: 'Unread'
                    }
                  }
                },
                {
                  text: this.model.myPageTranslations.inboxOffersOngoingText,
                  name: 'inboxOffersOngoing',
                  count: null,
                  detailedCount: [],
                  endpoint: messagesService.postThreadsCount,
                  link: this.model.messagesPage,
                  data: {
                    filters: {
                      type: 'Offer',
                      relatedEntityStates: ['Published', 'Changed'],
                      companyId: '',
                      user: '',
                      status: 'Unread'
                    }
                  }
                },
                {
                  text: this.model.myPageTranslations.inboxOffersAwardedText,
                  name: 'inboxOffersAwarded',
                  count: null,
                  detailedCount: [],
                  endpoint: messagesService.postThreadsCount,
                  link: this.model.messagesPage,
                  data: {
                    filters: {
                      type: 'Offer',
                      relatedEntityStates: ['Awarded', 'AwardedExternally'],
                      companyId: '',
                      user: '',
                      status: 'Unread'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('search', ['companies']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapState('membershipOrganizations', ['all']),
    dataReady() {
      return this.allManagedUsers.length > 0 && this.companies.length > 0
    },
    allActions() {
      const allActions = []

      this.sections.forEach(section => {
        section.columns.forEach(column => {
          column.actions.forEach(action => {
            const data = JSON.parse(JSON.stringify(action.data))
            const filterKeys = Object.keys(action.data.filters)
            const filterpropertyCompanyId = filterKeys.includes('propertyCompanyId')
            const filterCompanyId = filterKeys.includes('companyId')
            const filterResponsibleUserId = filterKeys.includes('responsibleUserId')
            const creator = filterKeys.includes('creator')
            const user = filterKeys.includes('user')
            const verifiedIn = filterKeys.includes('verifiedIn')
            if (filterpropertyCompanyId) {
              if (this.companyId.length) {
                data.filters.propertyCompanyId = this.companyId
              } else {
                delete data.filters.propertyCompanyId
              }
            }
            if (filterCompanyId) {
              if (this.companyId.length) {
                data.filters.companyId = this.companyId
              } else {
                delete data.filters.companyId
              }
            }
            if (filterResponsibleUserId) {
              if (this.responsibleId.length) {
                data.filters.responsibleUserId = this.responsibleId
              } else {
                delete data.filters.responsibleUserId
              }
            }
            if (creator) {
              if (this.responsibleId.length) {
                data.filters.creator = this.responsibleId
              } else {
                delete data.filters.creator
              }
            }
            if (user) {
              if (this.responsibleId.length) {
                data.filters.user = this.responsibleId
              } else {
                delete data.filters.user
              }
            }
            if (verifiedIn) {
              if (this.membershipOrganizationIds.length) {
                const moString = this.membershipOrganizationIds.toString()
                data.filters.verifiedIn = `-verified_in_any_sm:(${moString})`
              } else {
                delete data.filters.verifiedIn
              }
            }

            action.finalData = JSON.parse(JSON.stringify(data))
            allActions.push(action)
          })
        })
      })

      return allActions
    },
    responsibleData() {
      const responsibleData = Object.assign({}, this.responsible)
      responsibleData.options =
        this.allManagedUsers &&
        JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
          return {
            value: responsible.id,
            name: responsible.email.split('@')[0]
          }
        })
      return responsibleData
    },
    responsibleId() {
      return this.responsible.value
    },
    companyData() {
      const companyData = Object.assign({}, this.company)
      companyData.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
      return companyData
    },
    companyId() {
      return this.company.value
    },
    membershipOrganizationIds() {
      const membershipOrganizationIds = []

      if (this.companyId.length > 0) {
        this.companyId.forEach(id => {
          const company = this.companies.find(c => c.id === id)
          const orgId = company && company.organizationId
          if (orgId) membershipOrganizationIds.push(orgId)
        })
      } else {
        this.companies.forEach(c => c.organizationId && membershipOrganizationIds.push(c.organizationId))
      }
      return membershipOrganizationIds
    },
    openDetails() {
      return this.myPageData && this.myPageData.openDetails
    }
  },
  methods: {
    getCompanyName(id) {
      const company = this.companies.find(c => c.id === id)
      return (company && company.name) || id
    },
    showDetails(actionName) {
      const targetAction = this.allActions.find(action => action.name === actionName)
      const currentDetails = this.myPageData.openDetails
      const actionOpenIndex = currentDetails && currentDetails.indexOf(targetAction.name)
      if (actionOpenIndex !== undefined && actionOpenIndex !== -1) {
        currentDetails.splice(currentDetails.indexOf(targetAction.name), 1)
      } else {
        currentDetails.push(targetAction.name)
      }
    },
    getColumnClass(sectionIndex) {
      const columnLength = sectionIndex === 2 ? 'o-grid__item--12' : 'o-grid__item--4'
      return columnLength
    },
    getRowClass(sectionIndex) {
      const rowLength = sectionIndex === 2 ? 6 : 12
      return `o-grid__item--${rowLength}`
    },
    getHref(action, companyId) {
      let urlParameters = ''
      if (action.finalData && Object.keys(action.finalData.filters).length > 0) {
        const filters = Object.assign({}, action.finalData.filters)
        if (companyId) {
          const filterKeys = Object.keys(action.data.filters)
          const filterpropertyCompanyId = filterKeys.includes('propertyCompanyId')
          const filterCompanyId = filterKeys.includes('companyId')
          if (filterpropertyCompanyId) {
            filters.propertyCompanyId = companyId
          }
          if (filterCompanyId) {
            filters.companyId = companyId
          }
        }
        urlParameters = setQueryParameters(filters, false)
      }
      return action.link + urlParameters
    },
    async getCount(action) {
      try {
        action.count = null
        let data = JSON.parse(JSON.stringify(action.finalData))

        if (action.endpoint === messagesService.postThreadsCount) {
          data = { ...data, ...data.filters }
          data.company = data.companyId
          delete data.filters
          delete data.companyId
        } else {
          data.pageSize = 0
        }
        const response = await action.endpoint(data)
        if (response && response.status === 200) {
          if (action.endpoint === messagesService.postThreadsCount) {
            action.count = response.data
          } else {
            action.count = response.data.totalResults
          }
          action.detailedCount = response.data.facets[data.facets[0]]
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getAllCounts() {
      this.allActions.forEach(action => this.getCount(action))
    },
    setMultiSelect() {
      this.responsible.options =
        this.allManagedUsers &&
        JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
          return {
            value: responsible.id,
            name: responsible.email.split('@')[0]
          }
        })

      this.company.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    this.$store.dispatch('usersManagement/getManagedUsers')
  },
  watch: {
    dataReady() {
      this.setMultiSelect()
      this.getAllCounts()
    },
    responsibleId(newVal) {
      this.getAllCounts()
      this.myPageData.selectedResponsible = newVal
    },
    companyId(newVal) {
      this.getAllCounts()
      this.myPageData.selectedCompany = newVal
    },
    myPageData: {
      deep: true,
      handler() {
        this.$cookies.set('myPageData', this.myPageData, '1y')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-my-page {
  $sectionMargin: 25px;
  $root: &;

  position: relative;
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__filters {
    margin-left: auto;
    width: 75%;
  }
  &__data {
    position: relative;
  }
  &__section {
    & + & {
      margin-top: $sectionMargin;
    }
  }
  &__box {
    border: 1px solid;
    // height: 100%;
    padding: 20px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    display: block;
  }
  &__link {
    display: block;
    padding: 5px;
    text-decoration: none;
  }
  &__item {
    &-details-cta {
      background: transparent;
      border: none;
      padding: 15px 10px;
      margin: -15px -10px;
    }
    &-border {
      position: relative;
      &:after {
        content: '';
        width: 100%;
        display: block;
        border-bottom: 1px solid;
        position: absolute;
        bottom: -10px;
        pointer-events: none;
      }
    }

    &:last-child {
      #{$root}__item-border:after {
        display: none;
      }
    }
  }
}
</style>
