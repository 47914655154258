<template lang="pug">
form.forgot-password(@submit='submitForm')
  h3.forgot-password__heading.o-heading--size-3 {{ Dictionary.LoginFlow.ForgotPasswordHeadline }}
  p.forgot-password__text {{ Dictionary.LoginFlow.ForgotPasswordText }}
  .o-input__container
    .o-input__wrap
      input#emailInputResetPassword.o-input(
        name='emailInputResetPassword'
        placeholder=' '
        ref='emailInputResetPassword'
        required
        type='email'
        v-model='email'
        v-on:keyup='validateEmail'
      )
      label.o-label.o-label--placeholder(for='emailInputResetPassword') {{ Dictionary.E.Email }}
  button.o-button.o-button--inverted.o-button--full-width(:disabled='!this.valid' type='submit') {{ Dictionary.S.Send }}
  p.forgot-password__alert(role='alert' v-show='showError') {{ Dictionary.LoginFlow.ForgotPasswordError }}
  p.forgot-password__status(role='status') {{ statusText }}
</template>
<script>
import { userService } from '@/_services'
export default {
  name: 'c-forgot-password',
  props: {
    initialEmail: String
  },
  data() {
    return {
      email: this.initialEmail,
      valid: false,
      showError: false,
      showSuccess: false
    }
  },
  computed: {
    statusText() {
      return this.showSuccess ? this.Dictionary.LoginFlow.ForgotPasswordSuccess : ''
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      this.showError = false
      if (this.valid) {
        this.resetPassword()
      }
    },
    async resetPassword() {
      try {
        const response = await userService.resetPassword({ email: this.email })
        if (response && response.status === 204) {
          this.showSuccess = true
        }
      } catch (error) {
        this.showError = true
      }
    },
    validateEmail: function (event) {
      this.showError = false
      this.valid = this.validEmail(this.email)
      if (this.valid) {
        event.target.setCustomValidity('')
      } else {
        event.target.setCustomValidity(' ')
      }
    }
  },
  created() {
    this.valid = this.validEmail(this.email)
  },
  mounted() {
    this.$refs.emailInputResetPassword.focus()
  }
}
</script>

<style lang="scss" scoped>
.forgot-password {
  &__link {
    @include setStyles('14', $s-typography-sizes);
    margin: 0 auto;
    display: block;
  }
  &__actions-container {
    margin-bottom: toRem(20px);
  }
  &__heading {
    margin-top: toRem(20px);
    font-family: 'Inria Serif', serif;
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__text {
    @include setStyles('18', $s-typography-sizes);
    margin-bottom: toRem(43px);
    text-align: center;
  }
  &__status,
  &__alert {
    @include setStyles('18', $s-typography-sizes);
    text-align: center;
  }
}
.o-input__container,
.o-button {
  margin-bottom: toRem(20px);
}
</style>
