<template lang="pug">
.c-ub4-pm-mapping
  .c-ub4-pm-mapping__top
    button.c-ub4-pm-mapping__close(@click='close')
      svg.c-ub4-pm-mapping__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-ub4-pm-mapping__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-ub4-pm-mapping__content.o-transition__content
    h2.c-ub4-pm-mapping__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ model.mappingTranslations.newMappingHeadline }}
    .c-ub4-pm-mapping__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-ub4-pm-mapping__form-section
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  c-fc-select(:model='companyData' @validate='validate($event, "companyId")' v-model='mutableData.companyId.value')
                .o-grid__item.o-grid__item--12
                  c-fc-select(:model='propertyData' @validate='validate($event, "property")' v-model='mutableData.property.value')
                .o-grid__item.o-grid__item--6
                  c-fc-text(:model='companyNoData' @validate='validate($event, "companyNo")' v-model='mutableData.companyNo.value')
                .o-grid__item.o-grid__item--6
                  c-fc-text(:model='propertyNoData' @validate='validate($event, "propertyNo")' v-model='mutableData.propertyNo.value')
          .c-ub4-pm-mapping__form-section

    .c-ub4-pm-mapping__form.c-ub4-pm-mapping__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
      .o-grid__group
        .o-grid__item.o-grid__item--12.c-ub4-pm-mapping__form-section--border-top(v-if='selected.id')
          button.c-ub4-pm-mapping__delete.o-link.o-link--error(@click='handleLock') {{ selected.locked ? model.unlockUserText : model.lockUserText }}
        .o-grid__item.o-grid__item--12.c-ub4-pm-mapping__form-section--border-top
          .o-grid.o-grid--gutter-small.o-grid--align-items-center
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-link.c-ub4-pm-mapping__cancel(@click='close') {{ Dictionary.C.Cancel }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                button.o-button.c-ub4-pm-mapping__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllSelect from '@/components/form/controlls/form-select'

export default {
  name: 'c-ub4-pm-mapping',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-select': FormControllSelect
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      initialSelectedAdministrators: [],
      mutableData: {
        companyId: {
          helperText: this.model.mappingTranslations.mappingOwnerHelperText,
          value: null,
          placeholderText: this.Dictionary.O.Owner,
          isMandatory: true,
          readOnly: false,
          options: [],
          validation: null
        },
        property: {
          helperText: this.model.mappingTranslations.mappingPropertyHelperText,
          value: null,
          placeholderText: this.Dictionary.P.PropertyNumber,
          isMandatory: false,
          readOnly: false,
          options: [],
          validation: null
        },
        companyNo: {
          helperText: this.model.mappingTranslations.mappingOwnerNumberHelperText,
          placeholderText: `${this.Dictionary.N.New_et} ${this.Dictionary.C.CompanyNumber}`,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {}
        },
        propertyNo: {
          helperText: this.model.mappingTranslations.mappingPropertyNumberHelperText,
          placeholderText: `${this.Dictionary.N.New_et} ${this.Dictionary.P.PropertyNumber}`,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {}
        }
      }
    }
  },
  computed: {
    ...mapState('ub4PropertyManagement', ['selected', 'ownerships']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading']),
    formIsValid() {
      return this.mappingDataIsValid
    },
    propertyNumbers() {
      return this.ownerships.map(ownership => `${ownership.companyNo}-${ownership.propertyNo}`).filter((v, i, a) => a.indexOf(v) === i)
    },
    companyData() {
      const companyData = Object.assign({}, this.mutableData.companyId)
      companyData.options = this.companies.map(company => {
        company.value = company.id
        return company
      })
      return companyData
    },
    propertyData() {
      const propertyData = Object.assign({}, this.mutableData.property)
      propertyData.isMandatory = !this.mutableData.companyNo.value && !this.mutableData.propertyNo.value
      propertyData.options = this.propertyNumbers.map(property => {
        return {
          value: property,
          name: property
        }
      })
      propertyData.options.push({
        value: 'null',
        name: this.Dictionary.R.Reset,
        hidden: true
      })
      return propertyData
    },
    propertyValue() {
      return this.mutableData.property.value
    },
    companyNoData() {
      const companyNo = Object.assign({}, this.mutableData.companyNo)
      companyNo.isMandatory = !this.mutableData.property.value
      return companyNo
    },
    companyNoValue() {
      return this.mutableData.companyNo.value
    },
    propertyNoData() {
      const propertyNo = Object.assign({}, this.mutableData.propertyNo)
      propertyNo.isMandatory = !this.mutableData.property.value
      return propertyNo
    },
    propertyNoValue() {
      return this.mutableData.propertyNo.value
    },
    mappingDataIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key === true
        })
      )
    },
    dataChanged() {
      return this.mappingDataChanged
    },
    mappingDataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case '':
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }

        return different
      })
      // console.log(target)
      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {
    close() {
      this.$store.dispatch('ub4PropertyManagement/removeSelected')
    },
    submitChanges(closeSelection) {
      this.$store.dispatch('ub4PropertyManagement/postUb4Ownership', { data: this.getMappingData(), closeSelection })
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getMappingData() {
      const mappingData = {}
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'companyId':
            mappingData[key] = this.mutableData[key].value
            break
          case 'property':
            if (this.mutableData[key].value) {
              mappingData.companyNo = parseInt(this.mutableData[key].value.split('-')[0])
              mappingData.propertyNo = parseInt(this.mutableData[key].value.split('-')[1])
            }
            break
          default:
            if (this.mutableData[key].value !== undefined && this.mutableData[key].value !== null) {
              mappingData[key] = parseInt(this.mutableData[key].value)
            }
        }
      })
      return mappingData
    }
    // setSelected() {
    //   Object.keys(this.mutableData).forEach(key => {
    //     if (this.selected[key] !== undefined && this.selected[key] !== null) {
    //       switch (key) {
    //         default:
    //           this.mutableData[key].value = this.selected[key]
    //       }
    //     }
    //     if (key === 'selectedAdministrators') {
    //       this.initialSelectedAdministrators = this.administrators
    //         .filter(administrator => {
    //           if (administrator.users.includes(this.selected.id)) {
    //             return administrator.id
    //           }
    //         })
    //         .map(administrator => administrator.id)

    //       this.mutableData[key] = [...this.initialSelectedAdministrators]
    //     }
    //   })
    //   this.setReadOnly()
    // },
    // setReadOnly() {
    //   Object.keys(this.mutableData).forEach(key => {
    //     switch (key) {
    //       case 'email':
    //       case 'firstName':
    //       case 'lastName':
    //         break
    //       default:
    //         this.mutableData[key].readOnly = !!this.selected.id
    //     }
    //   })
    // }
  },
  watch: {
    propertyValue(newVal) {
      if (newVal) {
        this.mutableData.companyNo.value = null
        this.mutableData.propertyNo.value = null
        this.validate(true, 'companyNo')
        this.validate(true, 'propertyNo')
      }
    },
    companyNoValue(newVal) {
      if (newVal) {
        this.mutableData.property.value = null
        this.validate(true, 'property')
      }
    },
    propertyNoValue(newVal) {
      if (newVal) {
        this.mutableData.property.value = null
        this.validate(true, 'property')
      }
    }
  },
  created() {
    // if (this.selected.id) this.setSelected()
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-pm-mapping {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
}
</style>
