import { applicationsManagementService } from '@/_services'

const initialState = {
  applications: [],
  selected: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmationTarget: undefined
}

export const applicationsManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    applicationsAdverts: state =>
      state.applications.map(a => {
        return a.advert
      })
  },
  actions: {
    async deleteApplication({ commit, state }, payload) {
      try {
        const response = await applicationsManagementService.cancelForResidenceAdvert(payload.residenceId)
        if (response && response.status === 200) {
          commit('deleteEntry', state.confirmationTarget.id)
          commit('toggleConfirmation')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    deleteEntry(state, payload) {
      const target = state.applications.find(application => application.id === payload)
      target.status = 'Deleted'
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    addApplication(state, payload) {
      const target = state.applications.find(a => a.id === payload.id)
      if (target) {
        Object.assign(target, payload)
      } else {
        state.applications.push(payload)
      }
    },
    toggleConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    }
  }
}
