<template lang="pug">
.c-filter-input-password
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='id'
        @blur='onBlur'
        @focus='onFocus'
        @keyup='setvalue'
        placeholder=' '
        ref='passwordInput'
        type='password'
        v-debounce.keyup='250'
        v-mask='this.model.validation && this.model.validation.displayRule'
        v-model='model.value'
      )
      label.o-label.o-label--placeholder(:class='[{ "o-label--required": model.isMandatory }]', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    .c-filter-input-password__strength
      .c-filter-input-password__strength-inner
        ul.c-filter-input-password__strength-meter(:class='passwordLvl')
          li
          li
          li
          li
        span {{ passwordStrength }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-password',
  data() {
    return {
      id: this._uid,
      value: undefined,
      passwordStrength: this.Dictionary.P.PasscodeWeek,
      passwordLvl: 'lvl1'
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        isValidPassword(value) {
          return this.validPassword(value)
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    setvalue(event) {
      if (!event.isTrusted) return
      if (event.key === 'Enter') {
        this.$emit('submit', this.$v.value.$model)
      }
      this.value = event.target.value
      this.$v.value.$touch()
      this.setPasswordStrength()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null && this.model.value.length > 0) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    },
    setPasswordStrength() {
      if (this.value && this.value.length > 9 && this.$v.value.isValidPassword) {
        this.passwordLvl = 'lvl4'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl4
      } else if (this.value && this.value.length > 7 && this.$v.value.isValidPassword) {
        this.passwordLvl = 'lvl3'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl3
      } else if (this.value && this.value.length > 5) {
        this.passwordLvl = 'lvl2'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl2
      } else if (this.value && this.value.length < 5) {
        this.passwordLvl = 'lvl1'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl1
      }
    }
  },
  mounted() {
    this.setAfterModelChange()
    if (this.model.focus) {
      this.$refs.passwordInput.focus()
    }
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-password {
  &__strength {
    position: relative;
    width: 100%;
    height: toRem(34px);
    transition: 0.2s ease all;
    overflow: hidden;
    z-index: z('app');
    margin-top: toRem(3px);

    span {
      justify-self: flex-end;
      @include setStyles('13', $s-typography-sizes);
      color: $ocean-primary;
    }

    &-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      padding: 0 toRem(13px);
      height: 100%;
    }

    &-meter {
      display: flex;

      li {
        width: toRem(40px);
        height: toRem(3px);
        background-color: $grey-4;
        margin-right: toRem(3px);
        transition: 0.5s ease all;
      }

      &.lvl1 li:first-child {
        background-color: $error-red;
      }

      &.lvl2 li:nth-child(-n + 2) {
        background-color: $warning-orange;
      }

      &.lvl3 li:nth-child(-n + 3) {
        background-color: $ok-green;
      }

      &.lvl4 li {
        background-color: $ok-green;
      }
    }
  }
}
</style>
