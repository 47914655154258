/* eslint-disable prettier/prettier */
<template lang="pug">
.c-property-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-pm-overview')
        component(:is='selected && intialDataReady ? "c-pm-property" : selectedResidence ? "c-rm-residence" : "c-pm-overview"', :model='model')
  template(v-else)
    c-pm-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-pm-property.o-transition__overlay(:model='model' v-if='selected && intialDataReady')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleDeleteConfirmationClose' v-if='deleteTargetId')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ deleteConfirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ deleteConfirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleDeleteConfirmationDelete') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleDeleteConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import PropertyManagementOverview from './pm-overview'
import PropertyManagementProperty from './pm-property'
import ResidenceManagementResidence from '@/components/residence-management/rm-residence'
export default {
  name: 'c-property-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-pm-overview': PropertyManagementOverview,
    'c-pm-property': PropertyManagementProperty,
    'c-rm-residence': ResidenceManagementResidence
  },
  computed: {
    ...mapState('propertyManagement', ['selected', 'deleteTargetId', 'properties']),
    ...mapState('residenceManagement', {
      selectedResidence: 'selected'
    }),
    ...mapGetters('propertyManagement', ['intialDataReady']),
    deleteTargetData() {
      return this.properties.find(target => target.id === this.deleteTargetId)
    },
    deleteConfirmationHeadlineText() {
      return this.deleteTargetData
        ? this.model.deletePropertyConfirmationHeadline.replace('{0}', this.deleteTargetData.name)
        : this.model.deletePropertyConfirmationHeadline
    },
    deleteConfirmationDescriptionText() {
      return this.deleteTargetData
        ? this.model.deletePropertyConfirmationDescription.replace('{0}', this.deleteTargetData.name)
        : this.model.deletePropertyConfirmationDescription
    }
  },
  methods: {
    handleDeleteConfirmationDelete() {
      this.$store.dispatch('propertyManagement/deleteProperty', this.deleteTargetId)
    },
    handleDeleteConfirmationClose() {
      this.$store.dispatch('propertyManagement/toggleDeleteConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-property-management {
}
</style>
