<template lang="pug">
.c-form-select
  fieldset.o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :max='model.validation && model.validation.maxDate',
        :min='model.validation && model.validation.minDate',
        :name='model.inputName || id'
        @change='setvalue($event.target.value)'
        placeholder=' '
        type='date'
        v-model='model.value'
      )
      label.o-label.o-label--placeholder(:class='[{ "o-label--required": model.isMandatory }]', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-date',
  data() {
    return {
      id: this._uid,
      value: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value !== ''
          } else {
            return true
          }
        },
        minValue: function () {
          if (this.model.validation && this.model.validation.minDate) {
            return new Date(this.value) >= new Date(this.model.validation.minDate) || this.value === null || this.value === ''
          } else {
            return true
          }
        },
        maxValue: function () {
          if (this.model.validation && this.model.validation.maxDate) {
            return new Date(this.value) <= new Date(this.model.validation.maxDate) || this.value === null || this.value === ''
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    }
  },
  methods: {
    setvalue(value) {
      this.value = value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null && this.model.value.length > 0) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  }
}
</script>
<style lang="scss" scoped>
.c-form-select {
}
</style>
