/* eslint-disable prettier/prettier */
<template lang="pug">
.c-applications-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-am-overview')
        component(:is='selected ? "" : "c-am-overview"', :model='model')
  template(v-else)
    c-am-overview(:model='model')
    //- transition(name='o-transition__slide-fade-right')
    //-   c-pm-Application.o-transition__overlay(:model='model' v-if='selected')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleDeleteConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ deleteConfirmationHeadlineText }}
    template(slot='body')
      .o-bodytext {{ deleteConfirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleConfirmationYes') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleDeleteConfirmationClose') {{ Dictionary.C.Cancel }}
  c-messages-overlay
</template>
<script>
import { mapState } from 'vuex'

import ApplicationsManagementOverview from './am-overview'
export default {
  name: 'c-applications-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-am-overview': ApplicationsManagementOverview
  },
  computed: {
    ...mapState('applicationsManagement', ['selected', 'confirmationTarget', 'applications']),
    deleteTargetData() {
      return this.applications.find(target => target.id === this.confirmationTarget.id)
    },
    deleteConfirmationHeadlineText() {
      return this.deleteTargetData
        ? this.model.deleteApplicationConfirmationHeadline.replace('{0}', this.combinedAddress)
        : this.model.deleteApplicationConfirmationHeadline
    },
    deleteConfirmationDescriptionText() {
      return this.deleteTargetData
        ? this.model.deleteApplicationConfirmationDescription.replace('{0}', this.combinedAddress)
        : this.model.deleteApplicationConfirmationDescription
    },
    combinedAddress() {
      return this.combineAddress(
        this.deleteTargetData.advert.street,
        this.deleteTargetData.advert.number,
        this.deleteTargetData.advert.letter,
        this.deleteTargetData.advert.floor,
        this.deleteTargetData.advert.door,
        this.deleteTargetData.advert.postalCode,
        this.deleteTargetData.advert.city
      )
    }
  },
  methods: {
    handleConfirmationYes() {
      this.$store.dispatch(`applicationsManagement/${this.confirmationTarget.action}Application`, this.confirmationTarget.payload)
    },
    handleDeleteConfirmationClose() {
      this.$store.dispatch('applicationsManagement/toggleConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-applications-management {
}
</style>
