<template lang="pug">
.c-om-offer
  .c-om-offer__top
    button.c-om-offer__close(@click='close')
      svg.c-om-offer__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-om-offer__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-om-offer__content.o-transition__content
    h2.c-om-offer__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.residenceAddress }}
    .c-om-offer__info.o-grid.o-grid--gutter-small.o-grid--align-items-start
      .o-grid__group
        .o-grid__item
          .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.N.NumberShort }}
          .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ isNewOffer ? Dictionary.N.New_et : selected.number }}
        .o-grid__item
          .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.AvailableFrom }}
          .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ selected.availableFrom | formatDate }}
        //- .o-grid__item
        //-   .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ "Fraflytning" }}
        //-   .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ selected.mobilePhone || "eviction" }}
        //- .o-grid__item
        //-   .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ "Frigørelse" }}
        //-   .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ selected.phone || "Liberation" }}
        .o-grid__item
          .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.R.ResidenceNumber }}
          .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ selectedProperty && `${selectedProperty.companyNumber}-${selectedProperty.propertyNumber}-${selected.residenceNumber}` }}
        .o-grid__item
          .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.S.Status }}
          .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ getStatusLabel(selected.state) }}
        .o-grid__item
          c-fc-date(:model='deadlineData' @validate='validate($event, "deadline")' v-model='deadlineValue')
        .o-grid__item
          .c-om-offer__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.R.Replies }}
          .c-om-offer__info-data.o-bodytext.o-bodytext--size-small {{ formatAnswers(selected.recipientsState) }}
        .o-grid__item
          c-fc-select(:model='responsibleData' @validate='validate($event, "responsibleUserId")' v-model='responsibleUserIdValue')
    c-tabs.c-om-offer__tabs(:start-tab='0')
      c-tab(
        :disabled='false',
        :disabled-message='model.offerRecipientsTab.recipientsTabDisableReason',
        :name='model.offerRecipientsTab.recipientsTabText'
        v-if='!isNewOffer'
      )
        om-recipients(:model='model', :state-after='stateIsAfter', :state-before='stateIsBefore')
      c-tab(:disabled='false', :disabled-message='model.offerData.offerTabDisableReason', :name='model.offerData.offerTabText')
        om-data(:model='model.offerData', :state-after='stateIsAfter', :state-before='stateIsBefore', :validate='validate')
      c-tab(:disabled='false', :disabled-message='model.offerNoteData.noteTabDisableReason', :name='model.offerNoteData.noteTabText')
        om-notes(:model='model.offerNoteData', :state-after='stateIsAfter', :state-before='stateIsBefore', :validate='validate')
      c-tab(:disabled='false', :name='Dictionary.H.History' disabled-message='disable message' v-if='!isNewOffer')
        om-history(:model='model.offerHistoryData || {}')
      c-tab(:disabled='false', :name='messagesTabName' disabled-message='disable message' v-if='!isNewOffer')
        c-messages(:model='model', :related-entity='selected.id')
    .c-om-offer__form.c-om-offer__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0
          button.o-link.c-om-offer__cancel(@click='close') {{ Dictionary.C.Cancel }}
        .o-grid__item.o-grid__item--grow-0(v-if='selected.state === "Draft"')
          button.o-link.c-om-offer__cancel(@click='handleDelete') {{ Dictionary.D.DeleteOffer }}
        .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
          button.o-button.c-om-offer__submit(:disabled='isNewOffer || loading || offerDataChanged' @click='showExample') {{ Dictionary.S.ShowExample }}
          button.o-button.c-om-offer__submit(
            :disabled='isNewOffer ? !formIsValid || loading : !formIsValid || loading || !offerDataChanged'
            @click='saveOffer'
          ) {{ Dictionary.S.Save }}
          button.o-button.c-om-offer__submit(
            :disabled='isNewOffer || !formIsValid || loading || offerDataChanged'
            @click='publishOffer'
            v-if='stateIsBefore("Published")'
          ) {{ Dictionary.S.SendOffer }}
          button.o-button.c-om-offer__submit(
            :disabled='loading || offerDataChanged'
            @click='finishOffer'
            v-if='stateIsAfter("Draft") && stateIsBefore("Finished")'
          ) {{ Dictionary.E.EndOffer }}
          button.o-button.c-om-offer__submit(
            :disabled='loading || offerDataChanged'
            @click='retireOffer'
            v-if='stateIsAfter("Finished") && stateIsBefore("Released")'
          ) {{ Dictionary.R.RetireOffer }}
          button.o-button.c-om-offer__submit(
            :disabled='loading || offerDataChanged'
            @click='cancelAwardedOffer'
            v-if='stateIsAfter("Finished") && stateIsBefore("Released")'
          ) {{ Dictionary.C.CancelAwarded }}
          button.o-button.c-om-offer__submit(:disabled='loading || offerDataChanged' @click='releaseOffer' v-if='selected.state === "Finished"') {{ Dictionary.R.Release }}
          button.o-button.c-om-offer__submit(
            :disabled='!formIsValid || loading || !offerDataChanged'
            @click='extendOffer'
            v-if='["Published", "Changed", "Finished"].includes(selected.state)'
          ) {{ Dictionary.E.Extend }}
          button.o-button.c-om-offer__submit(:disabled='loading || offerDataChanged' @click='awardExternallyOffer' v-if='selected.state === "Finished"') {{ Dictionary.A.AssignExternal }}
          button.o-button.c-om-offer__submit(:disabled='loading' @click='handleAdvert()' v-if='selected.canCreateAdvertFromResidence') {{ Dictionary.C.CreateAdvert }}
  c-retire-awarded-rentedout(
    :data='createAdvertOrOfferConfirmation',
    :model='model'
    @close='createAdvertOrOfferConfirmation = false'
    v-if='createAdvertOrOfferConfirmation'
  )
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import omRecipients from './offer-recipients'
import omData from './offer-data'
import omNotes from './offer-notes'
import omHistory from './offer-history'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllSelect from '@/components/form/controlls/form-select'
import moment from 'moment'
import RetireAwardedRentedout from '../../retire-awarded-rentedout'

export default {
  name: 'c-om-offer',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'om-recipients': omRecipients,
    'om-data': omData,
    'om-notes': omNotes,
    'om-history': omHistory,
    'c-fc-date': FormControllDate,
    'c-fc-select': FormControllSelect,
    'c-retire-awarded-rentedout': RetireAwardedRentedout
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      createAdvertOrOfferConfirmation: false,
      responsible: {
        helperText: '',
        value: null,
        placeholderText: this.Dictionary.R.Responsible,
        readOnly: false,
        isMandatory: false,
        options: [],
        validation: null
      },
      deadline: {
        helperText: this.model.deadlineHelperText,
        value: null,
        placeholderText: this.Dictionary.R.ResponseDeadline,
        isMandatory: true,
        readOnly: false,
        validation: {},
        key: 0
      },
      statusLabels: {
        draft: this.Dictionary.OfferStates.Draft,
        published: this.Dictionary.OfferStates.Published,
        changed: this.Dictionary.OfferStates.Changed,
        finished: this.Dictionary.OfferStates.Finished,
        awarded: this.Dictionary.OfferStates.Awarded,
        awardedExternally: this.Dictionary.OfferStates.AwardedExternally,
        released: this.Dictionary.OfferStates.Released,
        retiredFromAwarded: this.Dictionary.OfferStates.RetiredFromAwarded
      },
      offerStates: ['Draft', 'Published', 'Changed', 'Finished', 'Awarded', 'AwardedExternally', 'Released', 'RetiredFromAwarded']
    }
  },
  computed: {
    ...mapState('offersManagement', ['selected', 'selectedMutable']),
    ...mapState('search', ['companies']),
    ...mapState('user', ['roles']),
    ...mapGetters('offersManagement', ['offerDataChanged']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapState('propertyManagement', { selectedProperty: 'selected', types: 'types' }),
    isNewOffer() {
      return !this.selectedMutable.id
    },
    responsibleData() {
      const responsibleData = Object.assign({}, this.responsible)
      responsibleData.value = this.responsibleUserIdValue
      responsibleData.options = JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
        return {
          value: responsible.id,
          name: responsible.email.split('@')[0]
        }
      })
      responsibleData.readOnly = this.isNewOffer || this.stateIsAfter('Finished')
      return responsibleData
    },
    messagesTabName() {
      return `${this.Dictionary.M.Messages}${this.selected.unreadThreads ? ' (' + this.selected.unreadThreads + ')' : ''}`
    },
    deadlineData() {
      const deadlineData = Object.assign({}, this.deadline)
      deadlineData.value = this.deadlineValue
      deadlineData.isMandatory = !this.stateIsAfter('Changed')
      deadlineData.readOnly = this.stateIsAfter('Finished')
      if (!this.stateIsAfter('Finished')) {
        deadlineData.validation.minDate = moment.utc().format('YYYY-MM-DD')
      }
      return deadlineData
    },
    formIsValid() {
      const allFieldsAreValid = this.validation && Object.keys(this.validation).every(key => {
        if(this.validation[key])return true
        if(key === 'deadline' && this.selected.state === 'Finished')return true
        return false
      })  
      return allFieldsAreValid
    },
    deadlineValue: {
      get: function () {
        return this.selectedMutable.deadline && this.formatDate(this.selectedMutable.deadline, 'YYYY-MM-DD')
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { deadline: new Date(value).toISOString().replace(/\.[0-9]{3}/, '') })
      }
    },
    responsibleUserIdValue: {
      get: function () {
        return this.selectedMutable.responsibleUserId
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { responsibleUserId: value })
      }
    }
  },
  methods: {
    stateIsBefore(target) {
      return this.offerStates.indexOf(target) > this.offerStates.indexOf(this.selected.state)
    },
    stateIsAfter(target) {
      return this.offerStates.indexOf(target) < this.offerStates.indexOf(this.selected.state)
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    close() {
      this.$store.dispatch('offersManagement/removeSelected')
      this.$store.commit('applicantsManagement/reset')
    },
    formatAnswers(recipients) {
      const total = recipients.length,
        responded = recipients.filter(r => r.state !== 'OfferReceived').length

      return `${responded} (${total})`
    },
    getStatusLabel(key) {
      return this.statusLabels[key.charAt(0).toLowerCase() + key.slice(1)] || key
    },
    handleDelete() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'deleteOffer' })
    },
    showExample() {
      this.$store.dispatch('offersManagement/showExample', this.selectedMutable.id)
    },
    saveOffer() {
      if (this.isNewOffer) {
        this.$store.dispatch('offersManagement/createOffer')
      } else {
        this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'changeOffer' })
      }
    },
    publishOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'publishOffer' })
    },
    cancelAwardedOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'cancelAwardedOffer' })
    },
    finishOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'finishOffer' })
    },
    retireOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'retireOffer' })
    },
    awardExternallyOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'awardExternallyOffer' })
    },
    releaseOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'releaseOffer' })
    },
    extendOffer() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'extendOffer' })
    },
    assignExternal() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { id: this.selectedMutable.id, action: 'assignExternal' })
    },
    handleAdvert() {
      if (this.selected.residenceAdvertId) {
        // TODO We never have this on model?
        window.location.assign(`${window.location.origin}${this.model.advertManagementLink}?advertId=${this.selected.residenceAdvertId}`)
      } else {
        this.createAdvertOrOfferConfirmation = {
          returnUrl: `${window.location.origin}${this.model.advertManagementLink}?advertFromResidenceId=${this.selected.residenceId}`,
          residenceId: this.selected.residenceId
        }
      }
    }
  },
  created() {
    if (this.types.length === 0) {
      this.$store.dispatch('propertyManagement/getTypes')
    }
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    if (!this.allManagedUsers.length) this.$store.dispatch('usersManagement/getManagedUsers')
    this.$store.dispatch('propertyManagement/setSelected', this.selected.propertyId)
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
    this.$store.dispatch('propertyManagement/removeSelected')
  }
}
</script>
<style lang="scss" scoped>
.c-om-offer {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__info {
    background-color: $grey-5;
    padding: 15px 30px 20px;
    margin-bottom: 20px;
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__finantial-summary {
    background-color: $grey-5;
    padding: 15px 20px;
    &-table {
      width: 100%;
      .separator {
        padding: 0;
        background-color: $grey-3;
        height: 1px;
      }
      td {
        padding: 5px 0;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          text-align: right;
          width: 40%;
        }
      }
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
