<template lang="pug">
.c-filter-input-text
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='model.inputName || id',
        :value='model.value'
        @blur='onBlur'
        @focus='onFocus'
        @keyup='setvalue'
        placeholder=' '
        type='text'
        v-debounce.keyup='250'
        v-mask='this.model.validation && this.model.validation.displayRule'
      )
      label.o-label.o-label--placeholder(:class='{ "o-label--required": model.isMandatory }', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'c-form-text',
  data() {
    return {
      id: this._uid,
      value: undefined
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        isValidRegex(value) {
          if (this.model.validation && this.model.validation.mask && value !== null) {
            const regexRule = new RegExp(this.model.validation.mask)
            return value === '' || regexRule.test(value)
          } else {
            return true
          }
        },
        minLength: this.model.validation && this.model.validation.minLength ? minLength(this.model.validation.minLength) : true,
        maxLength: this.model.validation && this.model.validation.maxLength ? maxLength(this.model.validation.maxLength) : true
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    setvalue(event) {
      if (!event.isTrusted) return
      if (event.key === 'Enter') {
        this.$emit('submit', this.$v.value.$model)
      }
      this.value = event.target.value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      if (this.value !== this.model.value) {
        this.value = this.model.value
        if (this.value !== null && this.value.length > 0) {
          this.$v.value.$touch()
        }
        this.$emit('validate', !this.$v.$invalid)
      }
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-text {
}
</style>
