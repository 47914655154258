/* eslint-disable prettier/prettier */
<template lang="pug">
.c-ap-overview
  .c-ap-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  keep-alive
    c-ap-search(:model='model')
  .c-ap-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-ap-overview__data
    vue-good-table(
      :columns='columns',
      :pagination-options='paginationSettingsData',
      :row-style-class='getApplicantsRowClass',
      :rows='applicants',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-sort-change='onSortChange'
      mode='remote'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "phoneNumber"')
          .o-bodytext.o-bodytext--size-small(v-if='props.row.mobilePhone') {{ props.row.mobilePhone }}
          .o-bodytext.o-bodytext--size-small(v-if='props.row.phone') {{ props.row.phone }}
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='!props.row.deleted')
                button.c-state-action(@click='handleImitate(props.row.userId)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.I.Imitate }}
              .o-grid__item.o-grid__item--grow-0(v-if='!props.row.deleted')
                c-cta.o-button--small(
                  :cta-disabled='syncInProgress',
                  :cta-loading='syncInProgress',
                  :cta-text='Dictionary.U.Update'
                  @click='reindexUser(props.row.userId)'
                )

        template(v-else-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
        template(v-else-if='props.column.field == "applicantNumber"')
          button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.applicantNumber }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import applicantsManagementSearch from '../ap-search'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-ap-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      isDisabledImpersonate: true,
      id: this._uid,
      showapplicantsImport: false,
      showWarningsImport: false,
      columns: [
        {
          label: this.Dictionary.N.Number,
          field: 'applicantNumber'
        },
        {
          label: this.Dictionary.N.Name,
          field: 'firstName'
        },
        {
          label: this.Dictionary.A.Address,
          field: 'address'
        },
        {
          label: this.Dictionary.E.Email,
          field: 'email'
        },
        {
          label: this.Dictionary.P.Phone,
          field: 'phoneNumber',
          sortable: false
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-ap-search': applicantsManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('applicantsManagement', ['applicants', 'page', 'pageSize', 'totalResults', 'serverParams']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    syncInProgress() {
      return this.loading && this.loadingEndpoints.some(endpoint => endpoint.includes('/search/indexes/applicant/reindex'))
    }
  },
  methods: {
    reindexUser(id) {
      this.$store.dispatch('applicantsManagement/reindexUser', id)
    },
    getApplicantsRowClass(row) {
      return row && row.deleted ? 'opaque' : ''
    },
    setSelected(id) {
      // this.$store.commit('applicantsManagement/updateData', { selected: this.applicants.find(applicant => applicant.userId === id) })
      this.$store.dispatch('applicantsManagement/setSelected', { id })
    },
    handleImitate(id) {
      this.$store.dispatch('applicantsManagement/toggleConfirmation', id)
    },
    onPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { page: params.currentPage })
    },
    onPerPageChange(params) {
      this.$store.commit('applicantsManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      this.$store.commit('applicantsManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('applicantsManagement/updateParams', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-ap-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
