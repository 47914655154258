<template lang="pug">
.c-residence-management-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
      c-fc-text(:model='search' @submit='handleSearch(0)' v-model='search.value')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
      c-fc-checkbox.c-residence-management-search__checkbox(:model='availableFromLastUpdated' v-model='availableFromLastUpdated.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='companies.length > 0')
      c-fc-vselect(:model='company' v-model='company.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-4
      c-fc-select(:model='canCreate' v-model='canCreate.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-2(v-if='status.options.length > 0')
      c-fc-select(:model='status' v-model='status.value')
    .o-grid__item.o-grid__item--desktop-2.o-grid__item--align-right
      button.o-button.c-residence-management-search__button(@click='handleSearch(0)' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-residence-management-search__button(:disabled='resetDisabled' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import { getQueryParameters } from '@/_helpers/query-parameters'
export default {
  name: 'c-residence-management-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      company: {
        helperText: this.model.ownerSelectHelperText,
        value: (getQueryParameters().get('propertyCompanyId') && getQueryParameters().get('propertyCompanyId').split(',')) || null,
        placeholderText: this.Dictionary.O.Owner,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      },
      status: {
        helperText: this.model.statusSelectHelperText,
        value: getQueryParameters().get('availableFrom') === '[*;*]' ? 'available' : null,
        placeholderText: this.Dictionary.S.Status,
        isMandatory: false,
        options: [
          {
            value: '',
            name: this.Dictionary.A.All
          },
          {
            value: 'available',
            name: this.Dictionary.A.Available
          },
          {
            group: true,
            label: `${this.Dictionary.A.Advert} ${this.Dictionary.S.Status}`,
            options: this.advertStatuses()
          },
          {
            group: true,
            label: `${this.Dictionary.O.Offer} ${this.Dictionary.S.State}`,
            options: this.offerStates()
          }
        ],
        validation: null
      },
      availableFromLastUpdated: {
        helperText: this.model.availableFromLastUpdatedHelperText,
        value: getQueryParameters().get('availableFromLastUpdated') === '[*;NOW-3DAYS]' || false,
        placeholderText: this.Dictionary.A.AvailableFrom3DaysAgo,
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      canCreate: {
        helperText: this.model.rentModelHelperText,
        value: null,
        placeholderText: this.Dictionary.R.RentModel,
        isMandatory: false,
        options: [
          {
            name: this.Dictionary.RentModelFilter.AdvertOrMemberAdvert,
            value: 'Advert'
          },
          {
            name: this.Dictionary.RentModelFilter.WaitingList,
            value: 'Offer'
          }
        ],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-checkbox': FormControllCheckbox,
    'c-fc-vselect': FormControllVSelect
  },
  computed: {
    ...mapState('search', ['companies']),
    ...mapState('residenceManagement', ['serverParams']),
    resetDisabled() {
      return !this.search.value && !this.company.value && !this.status.value && !this.availableFromLastUpdated.value && !this.canCreate.value
    },
    dataReady() {
      return this.companies.length > 0
    }
    // companyData() {
    //   const companyData = Object.assign({}, this.company)
    //   companyData.options =
    //     (this.companies &&
    //       JSON.parse(JSON.stringify(this.companies)).map(company => {
    //         company.value = company.id
    //         return company
    //       })) ||
    //     []
    //   return companyData
    // }
  },
  methods: {
    advertStatuses() {
      const statuses = this.getAdvertStatuses(true)
      return statuses.map(status => {
        return { name: status.name, value: `advert ${status.value}` }
      })
    },
    offerStates() {
      const states = this.getOfferStates(true)
      return states.map(state => {
        return { name: state.name, value: `offer ${state.value}` }
      })
    },
    handleSearch(page) {
      const data = {
        search: this.search.value,
        filters: {}
      }
      if (this.company.value) data.filters.propertyCompanyId = this.company.value
      if (this.status.value) {
        if (this.status.value === 'available') {
          data.filters.availableFrom = '[*;*]'
          data.filters.residenceAdvertId = '-[*;*]'
          data.filters.activeOfferId = '-[*;*]'
        } else {
          const valueArray = this.status.value.split(' ')

          if (valueArray[0] === 'advert') {
            data.filters.residenceAdvertStatus = valueArray[1]
          } else if (valueArray[0] === 'offer') {
            data.filters.offerState = valueArray[1]
          }
        }
      }
      if (this.availableFromLastUpdated.value) data.filters.availableFromLastUpdated = '[*;NOW-3DAYS]'
      if (this.canCreate.value === 'Advert') {
        data.filters.rentModel = ['Advert', 'MemberAdvert']
      }
      if (this.canCreate.value === 'Offer') {
        data.filters.rentModel = 'WaitingList'
      }

      if (page !== undefined) {
        data.page = page
      }

      this.$store.dispatch('residenceManagement/searchManagedResidences', data)
    },
    handleReset() {
      const that = this
      this.search.value = null
      this.company.value = null
      this.status.value = null
      this.availableFromLastUpdated.value = null
      this.canCreate.value = null
      window.history.pushState({}, document.title, window.location.pathname)
      setTimeout(function () {
        // needed to give time for the dropdowns with 1 option to set back that option
        // that.handleSearch()
        that.$store.commit('residenceManagement/updateParams', {
          page: 1,
          sort: [
            {
              field: '',
              type: ''
            }
          ]
        })
      }, 0)
    },
    setMultiSelect() {
      this.company.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
    if (getQueryParameters().get('availableFrom') === '[*;*]') {
      const rentModel = getQueryParameters().get('rentModel')
      const residenceAdvertId = getQueryParameters().get('residenceAdvertId')
      const activeOfferId = getQueryParameters().get('activeOfferId')

      if (rentModel === 'Advert,MemberAdvert' && residenceAdvertId === '-[*;*]') {
        this.canCreate.value = 'Advert'
      } else if (rentModel === 'WaitingList' && activeOfferId === '-[*;*]') {
        this.canCreate.value = 'Offer'
      }
    }
    if (this.dataReady) {
      this.setMultiSelect()
      this.handleSearch()
    }
  },
  watch: {
    serverParams: {
      deep: true,
      handler() {
        this.handleSearch()
      }
    },
    dataReady(newVal) {
      if (newVal) {
        this.setMultiSelect()
        this.handleSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-residence-management-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }

  &__checkbox {
    &::v-deep {
      .o-grid {
        min-height: 53px;
      }
    }
  }
}
</style>
