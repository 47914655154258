<template lang="pug">
ul.c-table-pagination(v-if='pager.pages && pager.pages.length > 1 && pageSize != pager.totalItems')
  li.c-table-pagination__item.previous(:class='{ disabled: pager.currentPage === 1 }')
    button.c-table-pagination__link.previous(
      :disabled='pager.currentPage === 1',
      :title='Dictionary.P.Previous'
      @click='setPage(pager.currentPage - 1)'
      tabindex='0'
    )
  li.c-table-pagination__item.page-number(:class='{ active: pager.currentPage === page }', :key='page' v-for='page in pager.pages')
    button.c-table-pagination__link.o-bodytext--size-small(:title='page' @click='setPage(page)' tabindex='0') {{ page }}
  li.c-table-pagination__item.next(:class='{ disabled: pager.currentPage === pager.totalPages }')
    button.c-table-pagination__link.next(
      :disabled='pager.currentPage === pager.totalPages',
      :title='Dictionary.N.Next'
      @click='setPage(pager.currentPage + 1)'
    )
  li.c-table-pagination__item(v-if='maxShowAll > pager.totalItems')
    button.c-table-pagination__link.show-all.o-bodytext--size-small(:title='Dictionary.S.ShowAll' @click='showAll' v-if='pageSize != pager.totalItems') {{ Dictionary.S.ShowAll }} {{ pager.totalItems }}
</template>

<script>
import paginate from 'jw-paginate'

export default {
  name: 'c-table-pagination',
  props: {
    total: {
      type: Number,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    maxPages: {
      type: Number
    },
    pageChanged: {
      type: Function
    },
    perPageChanged: {
      type: Function
    },
    resetPaging: {
      type: Boolean
    },
    maxShowAll: {
      type: Number
    }
  },
  data() {
    return {
      pager: {},
      maxPagesToShow: 10,
      maxShowAllToShow: 100
    }
  },
  methods: {
    setPage(page) {
      // get new pager object for specified page
      const pager = paginate(this.total, page, this.pageSize, this.maxPages)
      // update pager
      this.pager = pager

      this.pageChanged({ currentPage: pager.currentPage })
    },
    showAll() {
      this.perPageChanged({ currentPerPage: this.pager.totalItems })
    }
  },
  created() {
    this.maxPagesToShow = this.maxPages ? this.maxPages : this.isMobile ? 3 : 10
    this.maxShowAllToShow = this.maxShowAll ? this.maxShowAll : this.isMobile ? 20 : 100
  },
  watch: {
    total: function () {
      this.setPage(this.initialPage)
    },
    resetPaging: function () {
      this.setPage(this.initialPage)
    }
  },
  mounted() {
    this.setPage(this.initialPage)
  }
}
</script>

<style lang="scss" scoped>
.c-table-pagination {
  $self: &;

  display: flex;
  justify-content: center;
  margin: toRem(40px) 0;

  &:last-child {
    margin-bottom: 0;
  }
  &__item {
    margin: 0 toRem(4px);
    &.previous {
      margin-right: toRem(20px);
    }
    &.next {
      margin: 0 toRem(20px);
    }
  }
  &__link {
    color: $ocean-primary;
    border: toRem(1px) solid $ocean-primary;
    border-radius: toRem(30px);
    min-width: toRem(30px);
    height: toRem(30px);
    line-height: toRem(30px);
    display: block;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    position: relative;
    background-color: transparent;
    &.previous,
    &.next {
      &:before {
        content: '';
        height: toRem(10px);
        width: toRem(10px);
        border-left: toRem(1px) solid $ocean-primary;
        border-top: toRem(1px) solid $ocean-primary;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
    &.previous {
      &:before {
        transform: translate(-30%, -50%) rotate(-45deg);
      }
    }
    &.next {
      &:before {
        transform: translate(-70%, -50%) rotate(135deg);
      }
    }
    &.show-all {
      border: none;
      text-decoration: underline;
    }
  }
  .disabled {
    #{ $self }__link {
      cursor: default;
      border: toRem(1px) solid $grey-5;
      color: $grey-5;
      pointer-events: none;
      &.previous,
      &.next {
        &:before {
          border-color: $grey-5;
        }
      }
    }
  }
  .active {
    #{ $self }__link {
      background-color: $ocean-primary;
      color: $white;
      cursor: default;
    }
  }
}
</style>
