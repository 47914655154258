<template lang="pug">
.search-typeahead
  .search-typeahead__input-container
    input#searchTypeAheadInput.search-typeahead__input.o-input.o-input.o-input--placeholder-simple.o-input--search(
      :placeholder='placeholder'
      @blur='delayedCloseSuggestions'
      @focus='inputFocus = true'
      @keydown='focusResults'
      @keyup='getSuggestions'
      aria-label='Hvor vil du bo?'
      autocomplete='do-not-autofill'
      :autofocus='autofocus'
      ref='searchInput'
      type='text'
      v-debounce.keyup='250'
    )
  .search-typeahead__results.o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--padding-micro(
    :key='key'
    ref='typeaheadList'
    v-if='showTypeahead && inputFocus'
  )
    .o-grid__group(@blur='delayedCloseSuggestions' @keydown='keyNavigateFocusedResult')
      .o-grid__item.search-typeahead__result-group(:key='category.key' v-for='(category, name) in sortedSuggestions')
        .search-typeahead__results-category {{ translateCategory(name) }}
        .o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--padding-none
          .o-grid__group
            .search-typeahead__results-item.o-grid__item(:key='result.key' v-for='result in category')
              button.search-typeahead__results-item-name(@blur='delayedCloseSuggestions' @click='selectedChanged(result)') {{ result.suggestion }}
                span.search-typeahead__results-item-count ({{ result.count }})
</template>
<script>
import { searchService } from '@/_services'

export default {
  name: 'c-search-typeahead',
  data() {
    return {
      inputFocus: false,
      selectedValue: '',
      sortedSuggestions: {},
      key: 0
    }
  },
  props: {
    placeholder: {
      type: String
    },
    autofocus: {
      type: Boolean
    }
  },
  computed: {
    showTypeahead() {
      return Object.keys(this.sortedSuggestions).length > 0
    }
  },
  methods: {
    async getSuggestions(event) {
      try {
        if (event.target.value.length > 0) {
          const response = await searchService.getSuggestions(encodeURIComponent(event.target.value))
          const sortedList = {}
          Object.keys(response.data).forEach(key => {
            const suggestionCategory = response.data[key].type
            sortedList[suggestionCategory] = sortedList[suggestionCategory] || []
            sortedList[suggestionCategory].push(response.data[key])
          })
          this.sortedSuggestions = sortedList
        } else {
          this.sortedSuggestions = {}
        }
      } catch (error) {
        this.sortedSuggestions = {}
      }
    },
    selectedChanged(item) {
      if (item.type === 'Building') {
        item.type = 'BuildingName'
      }
      this.$emit('change', item)
      this.inputFocus = false
    },
    delayedCloseSuggestions() {
      setTimeout(() => {
        if (document.activeElement.className.split('search-typeahead').length === 1) {
          this.inputFocus = false
        }
      }, 100)
    },
    translateCategory(category) {
      let translation = ''
      switch (category) {
        case 'Commune':
          translation = this.Dictionary.C.Commune
          break
        case 'City':
          translation = this.Dictionary.C.City
          break
        case 'PostalCodeAndPostalCodeName':
          translation = this.Dictionary.P.PostalCode
          break
        case 'PropertyName':
          translation = this.Dictionary.P.PropertyName
          break
        case 'Street':
          translation = this.Dictionary.S.StreetName
          break
        case 'StreetAddress':
          translation = this.Dictionary.S.Street
          break
        case 'Place':
          translation = this.Dictionary.P.Place
          break
        case 'BuildingName':
          translation = this.Dictionary.B.Building
          break
        default:
          translation = category
      }
      return translation
    },
    getPrevButtonToFocus(el) {
      const prevBtnContainer = el.parentElement.previousElementSibling

      if (prevBtnContainer === null) {
        // Last in btn level
        if (el.closest('.search-typeahead__result-group').previousElementSibling !== null) {
          const focusThis = el.closest('.search-typeahead__result-group').previousElementSibling.querySelectorAll('.search-typeahead__results-item-name')
          focusThis[focusThis.length - 1].focus()
        } else {
          this.$refs.searchInput.focus()
        }
      } else {
        prevBtnContainer.querySelectorAll('.search-typeahead__results-item-name')[0].focus()
      }
    },
    getNextButtonToFocus(el) {
      const nextBtnContainer = el.parentElement.nextElementSibling

      if (nextBtnContainer === null) {
        // Last in btn level
        if (el.closest('.search-typeahead__result-group').nextElementSibling !== null) {
          el.closest('.search-typeahead__result-group').nextElementSibling.querySelectorAll('.search-typeahead__results-item-name')[0].focus()
        }
      } else {
        nextBtnContainer.querySelectorAll('.search-typeahead__results-item-name')[0].focus()
      }
    },
    keyNavigateFocusedResult(e) {
      if (e.key === 'ArrowUp' && this.showTypeahead) {
        e.preventDefault()
        e.stopPropagation()

        if (e.target.classList.contains('search-typeahead__results-item-name')) {
          this.getPrevButtonToFocus(e.target)
        }
      }

      if (e.key === 'ArrowDown' && this.showTypeahead) {
        e.preventDefault()
        e.stopPropagation()

        if (e.target.classList.contains('search-typeahead__results-item-name')) {
          this.getNextButtonToFocus(e.target)
        }
      }
    },
    focusResults(e) {
      if (e.key === 'ArrowDown' && this.showTypeahead) {
        e.preventDefault()
        e.stopPropagation()

        if (e.target.classList.contains('search-typeahead__input')) {
          this.$refs.typeaheadList.querySelectorAll('.search-typeahead__results-item-name')[0].focus()
        }
      }
    }
  },
  watch: {
    sortedSuggestions: {
      deep: true,
      handler() {
        this.key++
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-typeahead {
  position: relative;
  width: 100%;

  &__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &__results {
    position: absolute;
    z-index: z('elements');
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 2px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-category {
      background-color: $grey-5;
      // @include setStyles('14', $s-typography-sizes);
      line-height: 30px;
      font-size: 14px;
      text-align: center;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
      &-count {
        color: $grey-2;
        margin-left: 5px;
      }
    }
  }
}
</style>
