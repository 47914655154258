import { residenceManagementService, propertyManagementService, ub4SyncService } from '@/_services'
const searchConfiguration = document.getElementById('search-configuration')
  ? JSON.parse(new DOMParser().parseFromString(document.getElementById('search-configuration').text, 'text/html').documentElement.textContent)
  : false
const initialState = {
  residences: [],
  selected: undefined,
  types: searchConfiguration.residenceTypes || [],
  serverParams: {
    columnFilters: {},
    sort: [
      {
        field: '',
        type: ''
      }
    ],
    page: 1,
    perPage: 10
  },
  totalResults: 0,
  deleteTargetId: undefined
}

const initialSelected = {
  id: undefined,
  media: {
    blueprints: [],
    images: [],
    panoramas: [],
    videos: []
  }
}

export const residenceManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state
  },
  actions: {
    async setSelected({ commit }, { id, companyId }) {
      if (id) {
        try {
          const responseResidence = await residenceManagementService.getResidence(id)
          if (responseResidence && responseResidence.status === 200) {
            responseResidence.data.companyId = companyId || responseResidence.data.company

            if (responseResidence.data.managedExternally) {
              const responseOwners = await propertyManagementService.getPropertyOwners(responseResidence.data.propertyId)
              if (responseOwners && responseOwners.status === 200) {
                responseResidence.data.owners = responseOwners.data
                if (responseOwners.data.length) {
                  const responseNextOwnerTurn = await residenceManagementService.getNextOwnerTurn(id)
                  if (responseNextOwnerTurn && responseNextOwnerTurn.status === 200) {
                    responseResidence.data.nextOwnerTurn = responseNextOwnerTurn.data
                  } else if (responseNextOwnerTurn && responseNextOwnerTurn.status === 204) {
                    responseResidence.data.nextOwnerTurn = responseOwners.data.find(owner => owner.companyId === responseResidence.data.companyId)
                  }
                }
              }
            }

            commit('updateData', { selected: responseResidence.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    createNew({ commit }) {
      commit('createNew')
    },
    createNewInProperty({ commit }, payload) {
      commit('createNewInProperty', payload)
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createResidence({ commit }, { data, closeSelection }) {
      try {
        const response = await residenceManagementService.createResidence(data)
        if (response && response.status === 200) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            this.dispatch('residenceManagement/setSelected', { id: response.data })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async syncUb4({ commit }, payload) {
      try {
        const data = [payload]
        const response = await ub4SyncService.postUb4SyncResidences(data)
        if (response && response.status === 200) {
          this.dispatch('residenceManagement/searchManagedResidences', {})
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchResidence({ commit, state }, { data, closeSelection }) {
      try {
        const response = await residenceManagementService.patchResidence({ data, id: state.selected.id })
        if (response && response.status === 204) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            data.id = state.selected.id
            data.media = state.selected.media
            commit('updateData', { selected: data })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async searchManagedResidences({ commit, state }, payload) {
      try {
        // serverParams PAGE is from 1
        // payload PAGE is from 0
        payload.pageSize = state.serverParams.perPage
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = payload.page === undefined ? state.serverParams.page - 1 : payload.page
        payload.page = payload.page < 0 ? 0 : payload.page

        if (state.serverParams.sort[0].type && state.serverParams.sort[0].type !== 'none') {
          payload.orderDirection = state.serverParams.sort[0].type
          payload.orderBy = state.serverParams.sort[0].field
        } else {
          payload.orderDirection = 'desc'
          payload.orderBy = 'score'
        }
        const response = await residenceManagementService.searchManagedResidences(payload)
        if (response && response.status === 200) {
          const updateData = {
            residences: response.data.results,
            totalResults: response.data.totalResults
          }
          if (response.data.totalResults === 0) {
            updateData.serverParams = Object.assign(state.serverParams, { page: 1, perPage: 10 })
          }
          commit('updateData', updateData)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getTypes({ commit }) {
      try {
        const response = await residenceManagementService.getTypes()
        if (response && response.status === 200) {
          commit('updateData', { types: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteResidence({ commit }, payload) {
      try {
        const response = await residenceManagementService.deleteResidence(payload)
        if (response && response.status === 204) {
          commit('deleteEntry', payload)
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    },
    addMedia({ commit, state }, payload) {
      payload.residenceId = state.selected.id
      const fileTypes = ['images', 'blueprints', 'panoramas', 'panoramas360']
      const formField = !payload.panoramaLink && fileTypes.includes(payload.type) ? 'files' : 'uri'

      let formData = new FormData()

      const batches = []
      let counter = 0

      if (formField === 'uri') {
        formData.append(formField, payload.media)
      } else {
        Object.keys(payload.media).forEach(mediaKey => {
          if (counter <= 4) {
            formData.append(formField, payload.media[mediaKey])
            counter++
          } else {
            batches.push(formData)
            formData = new FormData()
            formData.append(formField, payload.media[mediaKey])
            counter = 1
          }
        })
      }
      batches.push(formData)

      function sendBatch() {
        payload.formData = batches.pop()
        residenceManagementService
          .addMedia(payload)
          .then(response => {
            if (response && response.status === 200) {
              commit('addMedia', { type: payload.type, items: response.data })
              if (batches.length) sendBatch()
            }
          })
          .catch(() => {})
      }

      sendBatch()
    },
    async removeMedia({ commit, state }, payload) {
      try {
        payload.residenceId = state.selected.id
        const response = await residenceManagementService.removeMedia(payload)
        if (response && response.status === 204) {
          commit('removeMedia', payload)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    moveMedia({ state }, payload) {
      payload.residenceId = state.selected.id
      // const orderChanged = state.selected.media[payload.mediaCategory].length - 1 !== payload.mediaIndex
      function changeOrder(payload) {
        residenceManagementService
          .moveMedia({ residenceId: state.selected.id, data: payload })
          .then(response => {
            return response
          })
          .catch(error => {
            return error
          })
      }
      function changeCategory(payload) {
        residenceManagementService
          .changeMedia(payload)
          .then(response => {
            if (response && response.status === 204) {
              // console.log(payload.mediaIndex)
              changeOrder({
                targetMedia: payload.mediaId,
                insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
                  ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
                  : null
              })
            }
          })
          .catch(error => {
            return error
          })
      }

      if (payload.mediaType) {
        changeCategory(payload)
      } else {
        changeOrder({
          targetMedia: payload.mediaId,
          insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
            ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
            : null
        })
      }
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    createNewInProperty(state, payload) {
      state.selected = Object.assign(initialSelected, payload)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    deleteEntry(state, payload) {
      state.residences.splice(state.residences.indexOf(state.residences.find(residence => residence.id === payload)), 1)
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(state)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] =
              key === 'selected'
                ? Object.assign(state.selected || JSON.parse(JSON.stringify(initialSelected)), JSON.parse(JSON.stringify(payload[key])))
                : key === 'serverParams'
                ? Object.assign(state.serverParams, payload[key])
                : payload[key]
          })
      }
    },
    updateParams(state, payload) {
      state.serverParams = Object.assign(state.serverParams, payload)
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.deleteTargetId = payload
      } else {
        state.deleteTargetId = undefined
      }
    },
    addMedia(state, payload) {
      if (Array.isArray(payload.items)) {
        payload.items.forEach(item => {
          state.selected.media[payload.type].push(item)
        })
      } else {
        state.selected.media[payload.type].push(payload.items)
      }
    },
    removeMedia(state, payload) {
      if (payload) {
        state.selected.media[payload.type].splice(
          state.selected.media[payload.type].indexOf(state.selected.media[payload.type].find(media => media.id === payload.mediaId)),
          1
        )
      }
    }
  }
}
