<template lang="pug">
.c-membership-organization-slider(:class='`c-membership-organization-slider--${theme}`' v-if='slides && slides.length > 0')
  .c-membership-organization-slider__title.o-heading.o-heading--size-2 {{ model.heading }}
  .c-membership-organization-slider__intro.o-heading.o-heading--size-4.o-richtext.o-wrap.o-wrap--max-width-content.o-wrap--padding-side-no-mobile-padding(
    v-if='model.text'
  ) {{ model.text }}
  slider(:goto='currentSlide', :theme='theme' reference='membershipOrganizationSlider' v-bind:settings='settings')
    .slick-slide-wrap(:key='slide.id' v-for='slide in randomList(slides)' v-if='slide.detailsLink && slide.detailsLink.url')
      a.c-membership-organization-slider-item(:href='slide.detailsLinkUrl' @focus='focusOnTab' target='_blank')
        .c-membership-organization-slider-image
          img(:alt='slide.logoAlt', :src='slide.logoSrc' v-if='slide.logoSrc')
          .c-membership-organization-slider__no-image(v-else)
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-image' y='0')
        .c-membership-organization-slider-heading.o-bodytext.o-bodytext--size-medium.o-bodytext--600 {{ slide.name }}
        .c-membership-organization-slider-text.o-bodytext.o-bodytext--size-medium {{ `${slide.residencesCount} ${Dictionary.R.Residences}` }}
  .c-membership-organization-slider__cta.o-wrap.o-wrap--center-content-x
    a.o-link--box.o-component-link.c-component-theme__link.c-component-theme__link__box(:href='model.linkUrl' v-if='model.linkText') {{ model.linkText }}
</template>

<script>
import slider from '@/components/slider/index'
export default {
  name: 'c-membership-organization-slider',
  props: {
    model: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    slides: Array,
    theme: {
      type: String,
      default: 'white'
    }
  },
  components: { slider },
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 6.2,
        slideFocus: false,
        infinite: true,
        centerMode: true,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1940,
            settings: {
              slidesToShow: 6.2,
              slidesToScroll: 2,
              slideFocus: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              slideFocus: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.9,
              slidesToScroll: 1,
              slideFocus: false,
              arrows: false,
              infinite: true,
              centerMode: true,
              centerPadding: '40px'
            }
          }
        ]
      },
      currentSlide: ''
    }
  },
  methods: {
    gotoTabbedSlide(page) {
      if (page < this.slides.length) {
        this.currentSlide = page
      }
    },
    focusOnTab(e) {
      if (e.target.closest('.slick-slide')) {
        this.$emit('focusedOnTab', e.target.closest('.slick-slide').dataset.index.toString())
      }
    },
    randomList: function (rand) {
      return rand.sort(function () {
        return 0.5 - Math.random()
      })
    }
  }
}
</script>

<style lang="scss">
.c-membership-organization-slider {
  $self: &;
  $rigthArrowPossitionDekstopMega: calc((((100% - 44px) / 6.2) * 6) - 2px);
  $rigthArrowPossitionDekstop: calc((((100% - 44px) / 3.2) * 3) - 8px);
  $rigthArrowPossitionTablet: calc((((100% - 44px) / 2) * 2) - 8px);
  &__title {
    text-align: center;
    margin-bottom: toRem(25px);
  }
  &__intro {
    text-align: center;
    margin-bottom: toRem(25px);
  }
  &__cta {
    text-align: center;
    margin-top: toRem(25px);
  }
  &--grey {
    background-color: $grey-5;
    padding: toRem(56px) 0 toRem(56px) 0;
  }
  &--white {
    background-color: $white;
    padding: toRem(56px) 0 toRem(56px) 0;
  }
  &-item {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    height: 100%;
    padding: toRem(25px 15px);
    border: 1px solid $grey-4;
    text-decoration: none;
    color: inherit;

    .u-theme-color--crab & {
      border-color: $ocean-primary;
    }

    &:focus {
      outline: 1px solid $ocean-primary;
      border-color: $ocean-primary;
    }
  }

  &-image {
    flex: 0 0 115px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    height: 115px;

    img {
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      flex: 0 0 auto;
    }
  }
  &__no-image {
    align-items: center;
    margin-bottom: 25px;
    height: 115px;
    overflow: hidden;
    .o-svg-icon {
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      flex: 0 0 auto;
    }
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: toRem(-22px);
    width: 100%;
    padding-right: toRem(30px);
    padding-left: toRem(30px);
    @include min('phoneplus', +1) {
      padding-right: toRem(54px);
      padding-left: toRem(54px);
    }
    @include min('desktop', +1) {
      // padding-right: calc(100% + 10px - #{$rigthArrowPossitionDekstop} - 2px);
      padding: toRem(0 54px);
    }

    li {
      flex: 1;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        height: toRem(3px);
        background-color: $grey-4;
        transition: background-color 500ms linear;
      }

      &.slick-active {
        &:before {
          background-color: $ocean-primary;
        }
      }
    }
    button {
      display: none;
    }
  }
  .slick-slider {
    margin-bottom: 40px;
    max-width: 100%;
    padding-block-end: toRem(24px);
    @include min('tablet') {
      margin: 0px auto 44px;
    }
  }
  .slick-track {
    display: flex !important;
  }

  .slick-prev {
    left: 4px;
    @include min('tablet') {
      left: 8px;
    }
  }

  .slick-next {
    right: 4px;
    @include min('tablet', +1) {
      right: 8px;
    }
    @include min('desktop', +1) {
      // left: $rigthArrowPossitionDekstop;
      right: 0;
    }
    // @include min('desktop-mega', +1) {
    //   left: $rigthArrowPossitionDekstopMega;
    //   right: auto;
    // }
  }

  .slick-slide {
    height: inherit !important;
    pointer-events: none;

    a {
      pointer-events: all;
    }
    > div,
    &-wrap {
      height: 100%;
    }
  }

  .slick-slide-wrap {
    padding: 4px;

    @include min('tablet') {
      padding: 8px;
    }
  }

  &--grey {
    .slick-dots li::before {
      background-color: $white;
    }
  }
}
</style>
