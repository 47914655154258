<template lang="pug">
.c-property-bluebox
  .c-property-bluebox__logo
    img(:src='model.companyLogo' v-if='model.companyLogo')
    img(:src='model.organizationLogo' v-else-if='model.organizationLogo')
  .c-property-bluebox__content
    template(v-if='activeWaitingListResidencies.length')
      .c-property-bluebox__body.o-bodytext.o-bodytext--size-large {{ (model.youAreOnWaitingLists || '').replace('{0}', activeWaitingListResidencies.length) }}
    template(v-else)
      h4.c-property-bluebox__heading.o-heading.o-heading--size-3 {{ model.headline.replace('{0}', model.propertyTitle) }}
      .c-property-bluebox__body.o-bodytext {{ model.description.replace('{0}', model.membershipOrganization) }}
      .c-property-bluebox__cta
        a.c-property-bluebox__cta_link.o-link(:href='model.blueBoxCtaLink1 && model.blueBoxCtaLink1.url') {{ model.blueBoxCtaLink1Text }}
      .c-property-bluebox__cta
        a.c-property-bluebox__cta_link.o-link(:href='model.blueBoxCtaLink2 && model.blueBoxCtaLink2.url') {{ model.blueBoxCtaLink2Text }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-property-bluebox',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      required: true
    }
  },
  computed: {
    ...mapState('membershipOrganizations', ['activeWaitingListResidencies'])
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.c-property-bluebox {
  $root: &;
  margin: 20px 0 40px;
  text-align: center;

  &:first-child {
    margin-top: 0;
  }
  &__logo {
    background-color: $white;
    border: 1px solid $grey-4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: toRem(20px 65px);
    img {
      max-width: 100%;
      max-height: 100px;
    }
  }
  &__content {
    background-color: $ocean-primary;
    color: $white;
    padding: toRem(40px 65px);
  }
  &__heading {
    margin-block-end: toRem(15px);
  }
  &__body {
    margin-block-end: toRem(30px);
  }
  &__cta_link {
    color: $crab-primary;
    font-size: toRem(15px);
    line-height: 22px;
  }
}
</style>
