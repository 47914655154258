<template lang="pug">
.c-search-result__wrap(:class='{ "mobile-map": mapShowing && isMobileOnly, "c-search-result__hideCards": hideCards }')
  .o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default(v-if='!loading && !mapShowing && page === 0 && results.length === 0')
    .c-search-result__count.o-heading.o-heading--size-3(v-html='noResultsText') 
  template(v-else)
    .o-wrap.o-wrap--max-width-content-wide.o-wrap--padding-side-default(:class='{ "mobile-map": mapShowing && isMobileOnly }' v-if='!hideCards')
      .property-card__container.o-grid.o-grid--direction-row.o-grid--align-items-stretch.o-grid--justify-content-start.o-grid--gutter-small(v-if='!mapShowing')
        transition-group.o-grid__group(:key='transitionTrigger' name='o-transition__fade' tag='div')
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-4(:key='property.id' v-for='property in results')
            c-property-card(:disabled='disabled', :property='property')
        .c-search-result__loading(v-if='loading && page === 0')
          c-loading-animation(:overlay='true' offset-top='100')
      template(v-else)
        PropertyMap(:model='mapResults' v-if='mapResults.length > 0')
        .c-search-result__count.o-heading.o-heading--size-3(v-else v-html='noResultsText') 
    scroll-loader(:loader-distance='loadMoreThreshold', :loader-method='getMoreProperties' v-if='!disabled && !mapShowing && resultsCount > currentResults')
      button.c-search-result__load-more(:aria-label='Dictionary.L.LoadMore' @click='getMoreProperties' type='button')
        c-loading-animation
</template>
<script>
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import { mapState, mapGetters } from 'vuex'
import PropertySpot from '@/components/search/search-result/property-spot/index'
import PropertyMap from '@/components/property-map/index'

export default {
  name: 'c-search-result',
  data() {
    return {
      loadMore: false,
      images: [],
      loadMoreThreshold: 0,
      transitionTrigger: 0,
      isMobileOnly
    }
  },
  components: { PropertySpot, PropertyMap },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    model: {
      noResultsText: {
        type: String,
        required: true
      }
    },
    hideCards: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('search', ['results', 'mapResults', 'resultsCount', 'pageSize', 'page', 'mapShowing', 'facilities', 'propertiesByCompanies']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('search', ['resultsCountDeep']),

    currentResults() {
      return this.propertiesByCompanies ? this.results.length : this.resultsCountDeep
    },
    noResultsText() {
      const dictionaryVariant = `${this.Dictionary.N.None} ${
        this.propertiesByCompanies ? this.Dictionary.P.Properties.toLowerCase() : this.Dictionary.H.Housing.toLowerCase()
      }`
      return (this.model && this.model.noResultsText) || dictionaryVariant
    }
  },
  methods: {
    getMoreProperties() {
      if (!this.mapShowing) {
        this.$store.dispatch('search/getResultsPage', this.page + 1)
      }
    }
  },
  mounted() {
    this.loadMoreThreshold = isMobileOnly ? window.outerHeight * 4 : isTablet ? window.outerHeight * 2 : window.outerHeight
  },
  watch: {
    results(newVal, oldVal) {
      if ((newVal.length > 0 && oldVal.length > 0 && newVal[0].id !== oldVal[0].id) || newVal.length === 0) {
        this.transitionTrigger = this.transitionTrigger + 1
      }
    },
    mapShowing: function () {
      if (this.mapShowing) {
        this.$store.dispatch('search/getAllResults')
      } else {
        this.$store.dispatch('search/getResults')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-search-result {
  &__wrap {
    position: relative;
    min-height: 280px;
    padding: toRem(35px 0);
    background-color: $grey-5;
    z-index: z('app');
    &.mobile-map {
      padding: 0;
    }
    .o-wrap.mobile-map {
      padding: 0;
    }
    .property-card {
      min-height: 100%;
    }

    .c-loading-animation__container {
      margin-top: 100px;
    }
  }
  &__hideCards {
    min-height: auto;
  }
  &__count {
  }
  &__load-more {
    background-color: transparent;
    border: none;
  }
}
</style>
