<template lang="pug">
.c-advert-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-ad-overview')
        component(:is='selected ? "c-ad-advert" : "c-ad-overview"', :key='selected && selected.id ? selected.id : "c-ad-overview"', :model='model')
  template(v-else)
    c-ad-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-ad-advert.o-transition__overlay(:key='selected && selected.id', :model='model' v-if='selected')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationText.headline }}
    template(slot='body')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            .o-bodytext {{ confirmationText.description }}
          .o-grid__item.o-grid__item--12(v-if='selected && selected.type === "MembershipAdvertBasedOnResidence" && confirmationTarget.action === "renew"')
            c-fc-date(:model='applyBeforeDateData' @validate='validate($event, "applyBeforeDate")' v-model='applyBeforeDate.value')
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(:disabled='!formIsValid' @click='handleConfirmationYes') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'
import AdvertManagementOverview from './ad-overview'
import AdvertManagementAdvert from './ad-advert'
import FormControllDate from '@/components/form/controlls/form-date'
import moment from 'moment'
export default {
  name: 'c-advert-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      residenceTranslations: { ...this.model.residenceTranslations, ...this.model.residence.residenceTranslations },
      applyBeforeDate: {
        helperText: this.residenceTranslations && this.residenceTranslations.advertApplyBeforeHelperText,
        value: null,
        placeholderText: this.Dictionary.A.ApplyBefore,
        isMandatory: false,
        readOnly: false,
        validation: {
          minDate: moment.utc().format('YYYY-MM-DD')
        },
        key: 0
      },
      validation: {}
    }
  },
  components: {
    'c-ad-overview': AdvertManagementOverview,
    'c-ad-advert': AdvertManagementAdvert,
    'c-fc-date': FormControllDate
  },
  computed: {
    ...mapState('advertManagement', ['selected', 'confirmationTarget', 'adverts']),
    ...mapState('applicantsManagement', ['applicantsPool']),
    applyBeforeDateData() {
      const applyBeforeDateData = Object.assign({}, this.applyBeforeDate)
      if (this.selected.type === 'MembershipAdvertBasedOnResidence') {
        applyBeforeDateData.isMandatory = true
      } else {
        applyBeforeDateData.readOnly = true
        applyBeforeDateData.isMandatory = false
      }
      return applyBeforeDateData
    },
    formIsValid() {
      const valid = this.validation && Object.values(this.validation).every(key => key === true)
      return valid
    },
    confirmationTargetData() {
      return this.adverts.find(target => target.id === this.confirmationTarget.id)
    },
    confirmationUser() {
      const confirmationUser = this.confirmationTarget.userId ? this.applicantsPool.find(a => a.userId === this.confirmationTarget.userId) : null
      return confirmationUser
    },
    confirmationText() {
      let headline = ''
      let description = ''
      let confirmationUserName = ''
      if (this.confirmationUser) {
        confirmationUserName =
          this.confirmationUser.firstName || this.confirmationUser.firstName
            ? `${this.confirmationUser.firstName} ${this.confirmationUser.lastName}`
            : this.confirmationUser.email
      }
      headline = this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationHeadline`]
        ? this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationHeadline`]
            .replace(
              '{0}',
              this.confirmationTargetData && this.confirmationTargetData.address
                ? this.confirmationTargetData.address
                : this.combineAddress(
                    this.selected.street,
                    this.selected.number,
                    this.selected.letter,
                    this.selected.floor,
                    this.selected.door,
                    this.selected.postalCode,
                    this.selected.city
                  )
            )
            .replace('{userName}', confirmationUserName)
        : ''
      description = this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationDescription`]
        ? this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationDescription`]
            .replace(
              '{0}',
              this.confirmationTargetData && this.confirmationTargetData.address
                ? this.confirmationTargetData.address
                : this.combineAddress(
                    this.selected.street,
                    this.selected.number,
                    this.selected.letter,
                    this.selected.floor,
                    this.selected.door,
                    this.selected.postalCode,
                    this.selected.city
                  )
            )
            .replace('{userName}', confirmationUserName)
        : ''

      return { headline, description }
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleConfirmationYes() {
      if (this.confirmationTarget.action === 'change') {
        this.$store.dispatch('advertManagement/patchAdvert', this.confirmationTarget.data)
      } else if (this.confirmationTarget.action === 'assignNext') {
        this.$store.dispatch('advertManagement/rentOutAdvert', this.confirmationTarget.payload)
      } else if (this.confirmationTarget.action === 'renew') {
        this.$store.dispatch(`advertManagement/${this.confirmationTarget.action}Advert`, { value: this.applyBeforeDate.value })
      } else {
        this.$store.dispatch(`advertManagement/${this.confirmationTarget.action}Advert`, this.confirmationTarget.payload)
      }
    },
    handleConfirmationClose() {
      this.$store.dispatch('advertManagement/toggleConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-advert-management {
}
</style>
