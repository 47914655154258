<template lang="pug">
.c-property-facilities(:class='{ "c-property-facilities--expanded": expanded }')
  .c-property-facilities__content-wrap
    .c-property-facilities__content
      .c-property-facilities__container-primary.o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-start.o-grid--gutter-micro
        .o-grid__group
          template(v-for='(primaryFacility, key) in sortedFacilities')
            .o-grid__item.o-grid__item--4.o-grid__item--desktop-3(:key='`primary-facility-${key}`' v-if='key + 1 <= primaryFacilitiesCount')
              .c-property-facilities__item.o-grid__item(v-if='facilities && facilities[primaryFacility]')
                object.c-property-facilities__icon.o-svg-icon(:data='facilities[primaryFacility].icon' tabindex='-1' type='image/svg+xml')
                span.o-bodytext.o-bodytext--size-xsmall {{ facilities[primaryFacility].name }}
      .c-property-facilities__container-secondary.o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-start.o-grid--gutter-small
        .o-grid__group
          template(v-for='(secondaryFacility, key) in sortedFacilities')
            .o-grid__item.o-grid__item--6(:key='`secondary-facility-${key}`' v-if='key + 1 > primaryFacilitiesCount')
              .c-property-facilities__item.o-grid__item(v-if='facilities && facilities[secondaryFacility]')
                object.c-property-facilities__icon.o-svg-icon(:data='facilities[secondaryFacility].icon' tabindex='-1' type='image/svg+xml')
                span.o-bodytext.o-bodytext--size-small {{ facilities[secondaryFacility].name }}
    .c-property-facilities__cta-wrap(v-if='sortedFacilities.length > firstRowFacilitiesCount')
      button.c-property-facilities__cta(:aria-expanded='expanded' @click='expanded = !expanded' ref='cta' role='button')
        svg.o-svg-icon(type='image/svg+xml' viewBox='0 0 64 64')
          use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${expanded ? "collapse" : "expand"}`' x='0' y='0')
        span.o-bodytext.o-bodytext--inria.o-bodytext--size-medium.o-bodytext--700 {{ ctaText }}
</template>
<script>
export default {
  name: 'c-property-facilities',
  data() {
    return {
      id: this._uid,
      primaryFacilitiesCount: 12,
      firstRowFacilitiesCount: this.isMobile ? 3 : 4,
      expanded: false
    }
  },
  props: {
    allFacilities: {
      type: Array,
      required: true
    },
    residenceFacilities: {
      type: Array,
      required: true
    },
    buildingFacilities: {
      type: Array,
      required: true
    },
    facilities: {
      type: Object,
      required: true
    }
  },
  computed: {
    ctaText() {
      return this.expanded ? this.Dictionary.S.ShowLess : this.showMorePhrase
    },
    showMorePhrase() {
      const phrase = []
      const extraCount = this.isMobile ? this.sortedFacilities.length - 3 : this.sortedFacilities.length - 4
      phrase.push(`+${extraCount}`)
      if (this.residenceFacilities.length > 0 && this.buildingFacilities.length > 0) {
        phrase.push(this.Dictionary.R.Residence, '&', this.Dictionary.B.BuildingFacilities)
      } else {
        if (this.residenceFacilities.length > 0) phrase.push(this.Dictionary.R.ResidenceFacilities)
        if (this.buildingFacilities.length > 0) phrase.push(this.Dictionary.B.BuildingFacilities)
      }

      return phrase.join(' ').toLowerCase()
    },
    sortedFacilities() {
      const totalFacilities = this.residenceFacilities.concat(this.buildingFacilities)
      const sortedFaciltiies = this.allFacilities.filter(facility => totalFacilities.includes(facility))
      const remainingFacilities = totalFacilities.filter(facility => !sortedFaciltiies.includes(facility))

      const facilitiesSorted = sortedFaciltiies.concat(remainingFacilities)

      return facilitiesSorted
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.c-property-facilities {
  $root: &;
  $transitionSpeed: 700ms;
  $maxHeight: 2000px;

  margin-top: 40px;

  @include max('desktop') {
    margin-left: -#{map-get($wrappadding, 'phone')};
    margin-right: -#{map-get($wrappadding, 'phone')};
  }

  &__content {
    text-align: left;
    max-height: toRem(100px);
    overflow: hidden;
    transition: max-height $transitionSpeed linear;
    padding: 0 4px 15px;
    max-width: 565px;
    margin: 0 auto;

    #{$root}--expanded & {
      max-height: toRem(#{$maxHeight});
    }

    @include min('desktop') {
      max-height: toRem(115px);
      padding: 0 0 15px;
    }

    #{$root}__icon {
      display: block;
      width: toRem(48px);
      height: toRem(48px);
    }

    &-wrap {
      position: relative;
    }
    &.o-richtext {
      display: table-row;
    }
  }
  &__container-primary {
  }

  &__container-secondary {
    margin-top: 15px;
    @include max('desktop') {
      padding: 0 map-get($wrappadding, 'phone');
    }
  }

  &__item {
    #{$root}__container-primary & {
      background-color: $grey-5;
      padding: 12px 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      min-height: 100%;
      text-align: center;

      @include min('desktop') {
        padding: 24px 20px 15px;
      }

      #{$root}__icon {
        margin-bottom: 7px;
      }
    }
    #{$root}__container-secondary & {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        overflow: hidden;
        max-width: 100%;
      }

      #{$root}__icon {
        flex: 0 0 16px;
        margin-right: 15px;
        width: toRem(32px);
        height: toRem(32px);
      }
    }
  }

  &__cta {
    padding: 5px;
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 auto;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background-color: transparent;

    #{$root}--expanded & {
      transform: translateY(0);
    }

    svg {
      display: block;
      margin: 0 auto;
      width: toRem(24px);
      height: toRem(24px);
    }

    &-wrap {
      max-height: unset;
      position: relative;
      margin-top: 10px;
      text-align: center;

      &:after {
        content: '';
        display: none;
        background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 50%);
        height: toRem(100px);
        margin-top: toRem(-100px);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        #{$root}--expanded & {
          opacity: 0;
        }
      }
    }

    &--hide {
      display: none;
    }
  }
}
</style>
