<template lang="pug">
.c-profile-education
  .c-profile-education__heading.o-heading.o-heading--size-2.o-heading--profile-page.o-heading--profile-page-center
    h2(v-if='data.headline') {{ data.headline }}
  .o-grid.o-grid--gutter-large
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .c-profile-education__description(v-if='data.educationGeneralDescription') {{ data.educationGeneralDescription }}
        .o-input__wrap
          input.o-input(
            :class='[{ error: $v.mutableState.name.$error }]',
            :id='`${id}-name`',
            :name='`${id}-name`'
            maxlength='255'
            name='name'
            placeholder=' '
            type='text'
            v-model='$v.mutableState.name.$model'
          )
          label.o-label.o-label--placeholder(:for='`${id}-name`') {{ data.educationNameLabel }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input.o-input(
              :class='[{ error: $v.mutableState.location.$error }]',
              :id='`${id}-location`',
              :name='`${id}-location`'
              maxlength='255'
              name='location'
              placeholder=' '
              type='text'
              v-model='$v.mutableState.location.$model'
            )
            label.o-label.o-label--placeholder(:for='`${id}-location`') {{ data.educationLocationLabel }}
        .c-profile-education__sub-description.o-bodytext--700.o-bodytext--size-medium(v-if='data.educationGeneralDescription') {{ data.educationDatesDescription }}
        .o-input__container
          .o-input__wrap
            input.o-input(
              :class='[{ error: $v.mutableState.startDate.$error }]',
              :id='`${id}-date-start`',
              :name='`${id}-date-start`'
              placeholder=' '
              type='date'
              v-model='$v.mutableState.startDate.$model'
            )
            label.o-label.o-label--placeholder(:for='`${id}-date-start`') {{ data.educationStartDateLabel }}
          .o-input__wrap.o-input__wrap--margin-top-20
            input.o-input(
              :class='[{ error: $v.mutableState.endDate.$error }]',
              :id='`${id}-date-end`',
              :name='`${id}-date-end`'
              placeholder=' '
              type='date'
              v-model='$v.mutableState.endDate.$model'
            )
            label.o-label.o-label--placeholder(:for='`${id}-date-end`') {{ data.educationEndDateLabel }}
          .c-profile-education__sub-description.o-bodytext.o-bodytext--700.o-bodytext--size-medium(v-if='data.uploadDocumentDescription') {{ data.uploadDocumentDescription }}
          .o-input__container
            .o-input__wrap
              input.o-input(
                :accept='acceptableFormats.toString()',
                :class='[{ error: $v.mutableState.document.$error }]',
                :id='`${id}-document`',
                :name='`${id}-document`'
                @change='filesChange($event.target.name, $event.target.files); inputFileCount = $event.target.files.length'
                placeholder=' '
                ref='uploadInput'
                type='file'
              )
              label.o-label.o-label--placeholder(:for='`${id}-document`' v-if='data.uploadDocumentLabel') {{ data.uploadDocumentLabel }}
            ul.c-profile-education__file-list.c-profile-education__file-list--upload(v-if='mutableState.document && mutableState.document.length > 0')
              li.c-profile-education__file(
                :class='{ "c-profile-education__file--invalid": $v.mutableState.document.$error }',
                :key='`file-${index}`'
                v-for='(file, index) in mutableState.document'
              )
                template(v-if='!$v.mutableState.document.$error')
                  svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
                    use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${fileIcon(file)}`' x='0' y='0')
                  component.c-profile-education__file-info(:is='documentIsSaved ? "a" : "div"' href='/api/users/profiles/education/document')
                    .c-profile-education__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
                    .c-profile-education__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'KB') }})
                  button.c-profile-education__file-action(:aria-label='Dictionary.D.DeleteUploadedFile' @click='fileRemoveClicked(index)')
                    svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
                template(v-else)
                  svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
                  .c-profile-education__file-info
                    .c-profile-education__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
                    .c-profile-education__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'MB') }})
                    .c-profile-education__file-error.o-bodytext.o-bodytext--size-xsmall {{ file.size > maxFileSize ? Dictionary.M.Max + ' ' + maxFileSizeClean : Dictionary.W.WrongFormat }}
                  button.c-profile-education__file-action(:aria-label='Dictionary.D.DeleteUploadedFile' @click='fileRemoveClicked(index)')
                    svg.o-svg-icon.close(type='image/svg+xml' viewbox='0 0 64 64')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
        .c-profile-education__save-actions
          button.c-profile-education__clear.o-link(@click='resetData(false)') {{ Dictionary.R.Regret }}
          button.o-button(:disabled='!formIsValid || !dataChanged' @click='validateData') {{ Dictionary.S.Save }}
    c-modal.c-signups-validation__confirmation(:cornerClose='true' @close='closeConfirmation' v-if='confirmation')
      template(slot='body')
        .c-signups-validation__confirmation-body
          h3.c-signups-validation__confirmation-heading.o-heading.o-heading--size-3(v-if='data.validationTitle') {{ data.validationTitle }}
          .c-signups-validation__confirmation-text(v-html='data.validationDescription' v-if='data.validationDescription')
          template(v-for='membershipOrganization in revalidatedApplications')
            .c-signups-validation__organization.o-grid.o-grid--align-items-center.o-grid--gutter-small
              .o-grid__group
                .o-grid__item.o-grid__item--grow-0(v-if='getLogo(membershipOrganization.membershipOrganizationId)')
                  img.c-signups-validation__confirmation-logo.c-signups-validation__confirmation-logo--small(
                    :src='getLogo(membershipOrganization.membershipOrganizationId)'
                  )
                .o-grid__item.o-grid__item--grow-0
                  span {{ membershipOrganization.membershipOrganizationName }}
            table.o-table.o-table--v-middle.c-signups-validation__confirmation-table
              thead
                tr
                  th {{ Dictionary.P.Picture }}
                  th {{ Dictionary.P.PropertyName }}
                  th {{ Dictionary.A.Address }}
                  th {{ Dictionary.W.WaitingList }}
              tbody
                tr(v-for='property in membershipOrganization.unsubscribedPropertyApplications')
                  td
                    img.c-signups-validation__confirmation-logo.c-signups-validation__confirmation-logo--small(
                      :src='property.propertyImage'
                      v-if='property.propertyImage'
                    )
                  td {{ property.propertyName }}
                  td {{ property.propertyAddress }}
                  td {{ property.numberOfUnsubscribedApplications }}
      template(slot='footer')
        .c-signups-validation__confirmation-footer
          .o-grid.o-grid--justify-content-center.o-grid--gutter-small
            .o-grid__group
              .o-grid__item.o-grid__item--12
                button.o-button.o-button--inverted(@click='confirmation.callback()') {{ Dictionary.A.Approve }}
              .o-grid__item.o-grid__item--12
                span.o-link(@click='closeConfirmation') {{ Dictionary.C.Cancel }}
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { userService } from '@/_services'
import { mapState } from 'vuex'
export default {
  name: 'c-profile-education',
  props: {
    data: Object
  },
  components: {},
  data() {
    return {
      id: this._uid,
      currentProfile: {},
      mutableState: {
        name: '',
        location: '',
        endDate: '',
        startDate: '',
        document: []
      },
      inputFileCount: 0,
      validFileSize: true,
      validFileFormat: true,
      maxFileSize: 512000,
      acceptableFormats: ['image/png', 'image/jpg', 'image/jpeg', 'text/plain', 'application/pdf']
    }
  },
  validations() {
    return {
      mutableState: {
        name: {
          required
        },
        location: {
          required
        },
        endDate: {
          required,
          minValue: value => new Date(value) >= new Date(this.mutableState.startDate)
        },
        startDate: {
          required,
          maxValue: value => new Date(value) <= new Date(this.mutableState.endDate)
        },
        document: {
          valid: value => {
            return value === null || (value && this.fileIsValid(value[0])) || this.currentProfile.document === this.mutableState.document
          }
        }
      }
    }
  },
  computed: {
    ...mapState('membershipOrganizations', ['confirmation']),
    documentIsSaved() {
      return this.currentProfile.document && this.mutableState.document && this.currentProfile.document[0] === this.mutableState.document[0]
    },
    maxFileSizeClean() {
      return this.$options.filters.prettyBytes(this.maxFileSize)
    },
    formIsValid() {
      return !this.$v.$invalid
    },
    dataChanged() {
      const changed = Object.keys(this.mutableState).some(key => {
        return JSON.stringify(this.mutableState[key]) !== JSON.stringify(this.currentProfile[key])
      })
      return changed || this.fileChanged
    },
    fileChanged() {
      const fileChanged = (this.currentProfile.document && this.currentProfile.document[0]) !== (this.mutableState.document && this.mutableState.document[0])
      return fileChanged
    },
    revalidatedApplications() {
      return this.confirmation.response.data.revalidatedApplications || null
    }
  },
  methods: {
    getLogo(moId) {
      const targetMo = this.all && this.all.find(organization => organization.id === moId)
      return targetMo && targetMo.logo
    },
    closeConfirmation() {
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
    },
    validateData() {
      if (this.dataChanged) {
        this.$store.dispatch('membershipOrganizations/validateParameters', {
          parameters: {
            '38a61e66-cc99-498b-bc3c-1c72e8d9a91b': this.mutableState.startDate,
            'd591c75f-22b0-401f-bd7f-b5028cba1c11': this.mutableState.endDate,
            'd5d591b7-1456-4de0-ac14-86865ac18828': this.mutableState.name,
            'b2f1f120-ebe4-48b0-8ff4-913dc471939e': this.mutableState.location
          },
          callback: this.submitData
        })
      } else {
        this.submitData()
      }
    },
    async submitData() {
      const data = Object.assign({}, this.mutableState)
      if (this.mutableState.document !== null && !this.fileChanged) {
        delete data.document
      } else if (this.mutableState.document === null) {
        data.deleteDocument = true
      } else {
        data.document = data.document[0]
      }

      try {
        const response = await userService.setUserEducation(data)
        if (response.status === 204) {
          this.currentProfile = Object.assign({}, this.mutableState)
          this.$v.$reset()
          this.$store.dispatch('membershipOrganizations/closeConfirmation')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    resetData(toPropData) {
      const target = toPropData ? this.data.currentProfile : this.currentProfile

      Object.keys(this.mutableState).forEach(key => {
        if (target) {
          if (key === 'document' && target[key] === undefined) {
            const file = {
              name: target.documentName,
              size: target.documentLength,
              type: target.documentMimeType
            }
            target.documentName && this.mutableState[key].push(file)
          } else if (key === 'startDate' || key === 'endDate') {
            this.mutableState[key] = this.formatDate(target[key], 'YYYY-MM-DD')
          } else {
            this.mutableState[key] = target[key]
          }
        } else {
          this.mutableState[key] = ''
        }
      })
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return
      this.mutableState.document = []
      this.mutableState.document.push(fileList[0])
      this.$v.mutableState.document.$touch()
    },
    fileRemoveClicked() {
      this.mutableState.document = null
      this.$refs.uploadInput.value = ''
      this.$v.mutableState.document.$touch()
    },
    fileIcon(file) {
      let icon = ''
      switch (file.type) {
        case 'image/jpeg':
          icon = 'paper'
          break
        case 'image/jpg':
          icon = 'paper'
          break
        case 'image/png':
          icon = 'paper'
          break
        case 'application/pdf':
          icon = 'pdf'
          break
        default:
          icon = 'paper'
      }
      return icon
    },
    fileIsValid(file) {
      if (file) {
        const typeIsValid = this.acceptableFormats.includes(file.type) && file.size <= this.maxFileSize
        return typeIsValid
      } else {
        return true
      }
    }
  },
  mounted() {
    this.resetData(true)
    this.currentProfile = Object.assign({}, this.mutableState)
  }
}
</script>
<style lang="scss" scoped>
.c-signups-validation {
  $root: &;

  &__organization {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('header');
    width: 100%;
    height: 100%;
    @include min('desktop') {
      position: relative;
      z-index: unset;
      min-width: 0;
    }
  }

  &__organization {
    margin-bottom: 10px;
  }

  &__confirmation {
    text-align: center;

    &-checkmark {
      width: toRem(29px);
      height: toRem(26px);
      fill: $white;
      display: block;
      margin: 0 auto 20px;
    }
    &-heading {
      margin-bottom: 20px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-logo {
      max-height: 80px;
      margin: 0 auto 10px;
      display: block;

      & + #{$root}__confirmation-heading {
        margin-top: 38px;
      }

      &--small {
        max-height: 50px;
        margin: 0 auto;
      }
    }
    &-text {
      margin-bottom: 25px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-box {
      background-color: $white;
      color: $ocean-primary;
      padding: toRem(22px) toRem(35px) toRem(40px);
    }

    &-footer {
      margin-top: 25px;
      &::first-child {
        margin-top: 0;
      }
      &-skip {
        background: transparent;
        border: none;
      }
    }
  }
}
.c-profile-education {
  $root: &;
  &__save-actions {
    justify-content: space-between;
    display: flex;
    margin-top: toRem(50px);
    border-top: toRem(1px) solid $grey-4;
    padding-top: toRem(20px);
  }
  &__clear {
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    line-height: toRem(54px);
    font-weight: 700;
    color: $ocean-primary;
  }
  &__delete-profile-container {
    padding: toRem(30px);
    border-top: 1px solid $grey-4;
    border-bottom: 1px solid $grey-4;
    text-align: center;
    margin-top: toRem(40px);
  }
  &__delete-profile-button {
    display: inline-block;
    color: $error-red;
    text-decoration: underline;
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    font-weight: 700;
    &:hover {
      color: $error-red;
    }
  }
  &__description {
    margin: toRem(0 0 24px);
  }
  &__sub-description {
    margin: toRem(30px 0 10px);
  }

  &__file-list {
    margin-top: 10px;
  }
  &__file {
    background-color: $grey-5;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
    }

    &--invalid {
      background-color: rgba($error-red, 0.07);
      border: 1px solid $error-red;
      &:last-child {
        border: 1px solid $error-red;
      }
    }

    #{$root}__file-list--uploaded & {
      display: inline-flex;
      border: none;
      padding: 10px 15px;
      margin: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    &-info {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      margin: 0 5px;
      max-width: calc(100% - 65px);

      #{$root}__file-list--uploaded & {
        margin: 0 0 0 5px;
        max-width: calc(100% - 25px);
      }
    }
    &-name {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(80% - 20px);

      #{$root}__file-list--uploaded & {
        max-width: unset;
      }
    }
    &-size {
      margin-left: 5px;
      flex: 0 0 20%;
    }

    &-error {
      flex: 1 0 100%;
      color: $error-red;
    }

    &-action {
      background-color: transparent;
      border: none;
      -webkit-appearance: none;
    }

    svg {
      vertical-align: middle;
      flex: 0 0 25px;
      &.type {
        width: 25px;
        height: 25px;
      }
      &.close {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      &.error {
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
        fill: $error-red;
        align-self: flex-start;
      }
    }
  }
}
</style>
