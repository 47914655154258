<template lang="pug">
a.property-card-small(:href='`/${this.Language}/residence/${model.shortId}`' target='_blank')
  .property-card-small__wrap
    .property-card-small__description.o-bodytext--size-large.o-bodytext--inria.o-bodytext--700(v-if='model.rooms && model.area') {{ model.rooms }} {{ Dictionary.R.RoomsDiscriptive }} {{ Dictionary.A.Apartment }} {{ Dictionary.A.At }} {{ model.area }} {{ Dictionary.Search.m2 }}
    .property-card-small__content-wrap
      .property-card-small__content-wrap-inner
        .property-card-small__street.o-bodytext--size-xsmall.o-bodytext--inria.notranslate(v-if='model.street') {{ model.street }} {{ model.number }}, {{ model.door }}
        .property-card-small__zip.o-bodytext--size-xsmall.o-bodytext--inria.notranslate(v-if='model.postalCode') {{ model.postalCode }} {{ model.postalCodeName }}
        .property-card-small__price-container
          .property-card-small__rent.o-bodytext--size-xsmall.o-bodytext--700(v-if='model.rent')
            span {{ Dictionary.R.RentShort }}&nbsp;
            span.notranslate {{ model.rent | money }}
          .property-card-small__account.o-bodytext--size-xsmall(v-if='model.aconto')
            span.notranslate {{ Dictionary.A.Account }}&nbsp;
            span.notranslate {{ model.aconto | money }}
      .property-card-small__image-container(v-if='isMobile && model.media && model.media.images && model.media.images[0]')
        img.property-card-small__image(:src='model.media.images[0]')
  .property-card-small__image-container(v-if='!isMobile && model.media && model.media.images && model.media.images[0]')
    img.property-card-small__image(:src='model.media.images[0]')
</template>
<script>
export default {
  name: 'c-property-card-small',
  data() {
    return {}
  },
  props: {
    forceImagesMobile: {
      type: Boolean,
      default: false
    },
    zip: String,
    rent: String,
    account: String,
    imageUrl: String,
    model: Object
  }
}
</script>

<style lang="scss" scoped>
.property-card-small {
  border: 1px solid $grey-4;
  background-color: $white;
  width: 100%;
  height: auto;
  display: flex;
  padding: toRem(16px);
  text-decoration: none;

  &__description {
    color: $ocean-darker;
    margin-bottom: toRem(4px);
    padding-right: toRem(10px);
  }

  &__street {
    color: $grey-1;
  }

  &__zip {
    color: $grey-1;
    margin-bottom: toRem(4px);
  }

  &__image-container {
    position: relative;
    max-width: toRem(110px);
    max-height: toRem(62px);
    margin-left: auto;
    overflow: hidden;
    @include min('tablet') {
      max-width: toRem(125px);
      max-height: none;
    }
  }

  &__image {
    max-width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  &__rent {
    color: $ocean-darker;
    margin-right: toRem(10px);
  }

  &__account {
    color: $ocean-darker;
  }

  &__price-container {
    display: flex;
    margin-top: toRem(4px);
  }
  &__wrap {
    width: 100%;
  }
  &__content-wrap {
    display: flex;
    width: 100%;
  }
}
</style>
