<template lang="pug">
.c-property-types-management
  .c-property-types-management__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  .c-property-types-management__search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-5(v-if='searchPropertyTypeData.options.length > 0')
        c-fc-select(:key='resetKey', :model='searchPropertyTypeData' v-model='propertyType')
      .o-grid__item.o-grid__item--desktop-1
        button.o-link.c-property-types-management__search__button(
          :disabled='!searchPropertyType.value'
          @click='searchPropertyType.value = null'
          role='button'
          v-debounce.keyup='500'
        ) {{ Dictionary.R.Reset }}
  .c-property-types-management__form-section
    c-fc-text(:key='resetKey', :model='name' v-model='name.value')
  .c-property-types-management__footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.o-link.c-property-types-management__cancel(@click='handleReset') {{ Dictionary.C.Cancel }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.c-parameters-management__submit(:disabled='!canSubmit' @click='showConfirmation = true') {{ Dictionary.S.Save }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showConfirmation = false' v-if='showConfirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 
    template(slot='body')
      .o-bodytext 
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-cta.o-button--inverted(:cta-text='Dictionary.Y.YesPlease' @click='submitChanges')
          .o-grid__item.o-grid__item--12
            span.o-link(@click='showConfirmation = false') {{ Dictionary.C.Cancel }}
</template>

<script>
import { mapGetters } from 'vuex'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllSelect from '@/components/form/controlls/form-select'
import { propertyTypesManagementService } from '@/_services'
export default {
  name: 'c-property-types-management',
  props: {
    model: Object
  },

  data() {
    return {
      searchPropertyType: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.P.PropertyType,
        isMandatory: false,
        options: [],
        validation: null
      },
      name: {
        value: '',
        isMandatory: true,
        placeholderText: this.Dictionary.N.Name,
        readOnly: false,
        labelText: this.Dictionary.P.PropertyType
      },

      showConfirmation: false,
      resetKey: 0
    }
  },

  components: {
    'c-fc-text': FormControllText,
    'c-fc-select': FormControllSelect
  },
  computed: {
    ...mapGetters('propertyManagement', ['all']),

    searchPropertyTypeData() {
      const searchPropertyTypeData = Object.assign({}, this.searchPropertyType)
      searchPropertyTypeData.value = this.searchPropertyType.value || null
      searchPropertyTypeData.options = this.all.types.map(a => {
        a.value = a.name
        return a
      })
      return searchPropertyTypeData
    },

    propertyType: {
      get() {
        return this.searchPropertyType.value
      },
      set(value) {
        this.searchPropertyType.value = value
        this.name.value = value
      }
    },

    canSubmit() {
      return this.name.value
    }
  },
  methods: {
    handleReset() {
      this.searchPropertyType.value = null
      this.name.value = null
      this.resetKey += 1
    },
    submitChanges() {
      const data = {
        name: this.name.value
      }
      if (this.searchPropertyType.value) {
        const selectedPropertyType = this.all.types.find(type => type.name === this.searchPropertyType.value)
        this.updatePropertyType(selectedPropertyType.id, data)
      } else {
        this.postPropertyType(data)
      }
    },
    async postPropertyType(data) {
      try {
        const response = await propertyTypesManagementService.createPropertyType(data)
        if (response && response.status === 200) {
          this.showConfirmation = false
          this.handleReset()
          this.$store.dispatch('propertyManagement/getTypes')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async updatePropertyType(id, data) {
      try {
        const response = await propertyTypesManagementService.updatePropertyType(id, data)
        if (response && response.status === 204) {
          this.showConfirmation = false
          this.handleReset()
          this.$store.dispatch('propertyManagement/getTypes')
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-property-types-management {
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }
  &__form {
    &--footer {
      margin-top: 25px;
      padding-top: 25px;
    }
    &-section {
      & + & {
        margin-top: 25px;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__search {
    padding: toRem(16px 20px);
    background-color: $grey-5;
    margin-bottom: 35px;

    &__button {
      width: 100%;
      min-height: 52px;
    }
  }

  &__footer {
    margin-top: 50px;
  }
}
</style>
