import apiClient from '@/_helpers/api-client'
export const advertManagementService = {
  searchAdverts,
  searchAdvertsScreening,
  getAdvert,
  getAdvertApplications,
  getAdvertStatistics,
  getAdvertPublishable,
  createAdvert,
  createAdvertFromResidence,
  patchAdvert,
  addMedia,
  removeMedia,
  moveMedia,
  changeMedia,
  getAdvertDataFromResidence,
  rejectAdvert,
  approveAdvert,
  publishAdvert,
  publishAdvertWithSubOrg,
  reserveAdvert,
  retireAdvert,
  retireRentedOutAdvert,
  rentOutToExternalAdvert,
  renewAdvert,
  releaseAdvert,
  extendExpirationAdvert,
  extendabeExpirationAdvert,
  setApplicationsState,
  getApplicantsPendingAdverts,
  declineApplicantsAdverts,
  canCreateAdvertFromResidence,
  createAdvertCopy,
  cancelRentedOut,
  getPromotions,
  postPromotion,
  getPromotionsSettings
}

async function searchAdverts(query) {
  return await apiClient({
    url: '/search/adverts',
    method: 'POST',
    data: query
  })
}
async function searchAdvertsScreening(query) {
  return await apiClient({
    url: '/search/adverts/screening',
    method: 'POST',
    data: query
  })
}
async function getAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}`,
    method: 'GET'
  })
}
async function getAdvertApplications(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/applications`,
    method: 'GET'
  })
}
async function getAdvertStatistics(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/statistics`,
    method: 'GET'
  })
}
async function getAdvertPublishable(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/publishable`,
    method: 'GET'
  })
}
async function getAdvertDataFromResidence(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/from/residence`,
    method: 'GET'
  })
}
async function createAdvert(data) {
  return await apiClient({
    url: '/data/adverts/residences',
    method: 'POST',
    data
  })
}
async function createAdvertFromResidence(data, id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/from/residence`,
    method: 'POST',
    data
  })
}
async function patchAdvert(data, id) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/adverts/residences/${id}`,
    method: 'PATCH',
    data
  })
}

async function addMedia({ advertId, type, formData, panoramaLink = false }) {
  const url = panoramaLink ? `/data/adverts/residences/${advertId}/media/${type}/link` : `/data/adverts/residences/${advertId}/media/${type}`
  return await apiClient({
    url,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function removeMedia({ advertId, mediaId }) {
  return await apiClient({
    url: `/data/adverts/residences/${advertId}/media/${mediaId}`,
    method: 'DELETE'
  })
}

async function moveMedia({ advertId, data }) {
  return await apiClient({
    url: `/data/adverts/residences/${advertId}/media/reorder`,
    method: 'PATCH',
    data
  })
}

async function changeMedia({ advertId, mediaId, mediaType }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/adverts/residences/${advertId}/media/${mediaId}`,
    method: 'PATCH',
    data: mediaType
  })
}

async function rejectAdvert({ id, data }) {
  return await apiClient({
    url: `/data/screening/adverts/residences/${id}/reject`,
    method: 'PUT',
    data
  })
}
async function approveAdvert(id) {
  return await apiClient({
    url: `/data/screening/adverts/residences/${id}/approve`,
    method: 'PUT'
  })
}
async function publishAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/publish`,
    method: 'PUT'
  })
}
async function publishAdvertWithSubOrg({ id, data }) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/publish-with-assigned-suborganization`,
    method: 'PUT',
    data
  })
}
async function reserveAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/reserve`,
    method: 'PUT'
  })
}
async function retireAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/retire`,
    method: 'PUT'
  })
}
async function retireRentedOutAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/retire-rented-out`,
    method: 'PUT'
  })
}
async function rentOutToExternalAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/rent-out`,
    method: 'PUT'
  })
}
async function renewAdvert({ id, data }) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/renew`,
    method: 'PUT',
    data
  })
}
async function releaseAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/release`,
    method: 'PUT'
  })
}
async function extendabeExpirationAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/extendable`,
    method: 'GET'
  })
}
async function extendExpirationAdvert(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/extend`,
    method: 'PUT'
  })
}
async function cancelRentedOut(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/cancel-rented-out`,
    method: 'PUT'
  })
}
async function setApplicationsState(id, data) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/applications`,
    method: 'PUT',
    data
  })
}

async function getApplicantsPendingAdverts({ id, userId }) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/applications/${userId}/other-adverts`,
    method: 'GET'
  })
}

async function declineApplicantsAdverts({ id, userId }) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/applications/${userId}/other-adverts`,
    method: 'DELETE'
  })
}

async function canCreateAdvertFromResidence(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/can-create/from/residence`,
    method: 'GET'
  })
}

async function createAdvertCopy(id) {
  return await apiClient({
    url: `/data/adverts/residences/${id}/copy`,
    method: 'POST'
  })
}
async function getPromotions(id) {
  return await apiClient({
    url: `/data/adverts/promotions/${id}`,
    method: 'GET'
  })
}
async function postPromotion(id) {
  return await apiClient({
    url: `/data/adverts/promotions/${id}`,
    method: 'POST'
  })
}
async function getPromotionsSettings() {
  return await apiClient({
    url: '/settings/advert-promotion',
    method: 'GET'
  })
}
