<template lang="pug">
.profile-created
  svg.o-svg-icon.profile-created__checkmark(type='image/svg+xml' viewbox='0 0 35 35')
    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-checkmark' y='0')
  h3.profile-created__heading.o-heading--size-3 {{ Dictionary.LoginFlow.ProfileCreatedHeadline }}
  .profile-created__text {{ Dictionary.LoginFlow.ProfileCreatedText }}
  .profile-created__box
    svg.o-svg-icon.profile-created__attention(type='image/svg+xml' viewbox='0 0 35 35')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-bell' y='0')
    .profile-created__box-text {{ createdDescription }}
    .o-button(@click='proceedAfterCreation') {{ createdCtaText }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { userService } from '@/_services'
export default {
  name: 'c-login-flow-profile-created',
  computed: {
    ...mapState('user', ['pendingAction']),
    ...mapGetters('user', ['verificationFlow']),
    createdDescription() {
      return this.pendingAction && this.pendingAction.description ? this.pendingAction.description : this.Dictionary.LoginFlow.ProfileCreatedSubText
    },
    createdCtaText() {
      return this.pendingAction && this.pendingAction.createdCtaText ? this.pendingAction.createdCtaText : this.Dictionary.LoginFlow.AddProfileInformation
    },
    createdCtaAction() {
      return this.pendingAction && this.pendingAction.action
    }
  },
  methods: {
    proceedAfterCreation() {
      if (this.createdCtaAction) {
        this.pendingAction.action(true)
      } else {
        window.location.replace(`/${document.documentElement.lang}/profile/profile-page`)
      }
      this.$store.dispatch('user/toggleLoginModal', false)
    }
  },
  created() {
    if (this.verificationFlow && this.verificationFlow.type === 'family') {
      userService.logOut(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-created {
  &__heading {
    font-family: 'Inria Serif', serif;
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__text {
    @include setStyles('18', $s-typography-sizes);
    margin-bottom: toRem(43px);
    text-align: center;
  }
  &__checkmark {
    width: toRem(29px);
    height: toRem(26px);
    stroke: $white;
    display: block;
    margin: 0 auto toRem(30px);
  }
  &__attention {
    width: toRem(28px);
    height: toRem(30px);
    stroke: $ocean-primary;
    margin-bottom: toRem(10px);
  }
  &__box {
    background-color: $white;
    color: $ocean-primary;
    padding: toRem(22px) toRem(35px) toRem(40px);
    text-align: center;
  }
  &__box-text {
    margin-bottom: toRem(22px);
  }
}
</style>
