/* eslint-disable prettier/prettier */
<template lang="pug">
.c-ub4-sc-overview
  .c-ub4-sc-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-ub4-sc-overview__button(:title='Dictionary.N.NewMapping' @click='createNew') {{ Dictionary.N.NewMapping }}
  keep-alive
    c-ub4-sc-search(:model='model')
  .c-ub4-sc-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-ub4-sc-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettings', :rows='filteredResults' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "code"')
          .o-bodytext.o-bodytext--size-small {{ props.row.sumCode }}
          .o-bodytext.o-bodytext--size-small {{ props.row.description }}
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.c-state-action(@click='handleDelete(props.row)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Delete }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Ub4SumCodesManagementSearch from '../ub4-sc-search'

import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-ub4-sc-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      pageOfItems: [],
      resetPaging: false,
      page: 1,
      columns: [
        {
          label: this.Dictionary.S.SumCode,
          field: 'code'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'companyId',
          formatFn: this.getOwnerName
        },
        {
          label: this.Dictionary.S.SubOwner,
          field: 'membershipSubOrganizationId',
          formatFn: this.getSubOwnerName
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-ub4-sc-search': Ub4SumCodesManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('ub4SumCodesManagement', ['sumcodes', 'pageSize', 'selectedCompanyId', 'selectedSumCodeNumber']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('search', ['companies']),
    ...mapGetters('search', ['subOrganizations']),
    ...mapState('loadingIndicator', ['loading']),
    totalResults() {
      return this.filteredResults.length
    },
    enablePagination() {
      return this.pageSize < this.totalResults
    },
    filteredResults() {
      const filteredResults = this.sumcodes.filter(entry => {
        let selectedCompanyIdPassed = true
        let selectedSumCodeNumberPassed = true
        if (this.selectedCompanyId) {
          selectedCompanyIdPassed = entry.companyId === this.selectedCompanyId
        }
        if (this.selectedSumCodeNumber) {
          selectedSumCodeNumberPassed = entry.sumCode === this.selectedSumCodeNumber
        }
        return selectedCompanyIdPassed && selectedSumCodeNumberPassed
      })
      return filteredResults
    },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    }
  },
  methods: {
    getOwnerName(id) {
      const company = this.companies.find(c => c.id === id)
      return company && company.name
    },
    getSubOwnerName(id) {
      const subOrganization = this.subOrganizations.find(sub => sub.id === id)
      return subOrganization && subOrganization.name
    },
    createNew() {
      this.$store.dispatch('ub4SumCodesManagement/createNew')
    },
    handleDelete(target) {
      this.$store.dispatch('ub4SumCodesManagement/toggleDeleteConfirmation', target)
    },
    getLocation(id) {
      const location = this.sumcodeLocations.find(location => location.id === id)
      return (
        location && (location.name === 'Residence' ? this.Dictionary.R.Residence : location.name === 'Building' ? this.Dictionary.P.Property : location.name)
      )
    }
  },
  created() {
    this.$store.dispatch('ub4SumCodesManagement/getUb4SumCodes')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-sc-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
