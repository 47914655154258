<template lang="pug">
.c-ad-applicants
  .c-ad-applicants__active(v-if='applicantsDataReady')
    .c-ad-applicants__top.o-grid.o-grid--align-items-start.o-grid--align-items-center.o-grid--gutter-tiny(:class='{ "o-grid--wrap-nowrap": !isMobile }')
      .o-grid__group
        .c-ad-applicants__count.o-grid__item.o-grid__item--12.o-grid__item--desktop-4(v-if='applicantsData.length > 0')
          h4.o-heading.o-heading--size-4 {{ applicantsCountText }}
          //- span.c-ad-applicants__newCount(v-if='newApplicants.length') {{ newApplicants.length }}
        .c-ad-applicants__options.o-grid__item.o-grid__item--12.o-grid__item--desktop-4
          .c-ad-applicants__option(:key='key' v-for='(option, key) in applicantsFilterOptions')
            input.o-input(
              :id='`${id}-${key}`',
              :name='option.value',
              :value='option.value'
              @change='getApplicantsData()'
              placeholder=' '
              store='messages'
              type='radio'
              v-model='onlyApplicantsWithNewMessages'
            )
            label.o-label(:for='`${id}-${key}`' v-if='option.labelText') {{ option.labelText }}
        .c-ad-applicants__actions.o-grid__item.o-grid__item--align-right.o-grid__item--12.o-grid__item--desktop-5(v-if='!isMobile')
          .o-grid.o-grid--gutter-tiny.o-grid--justify-content-end.o-grid--align-items-end
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                c-cta.o-button--small(:cta-disabled='syncInProgress', :cta-loading='syncInProgress', :cta-text='Dictionary.U.Update' @click='syncApplicants')
              .o-grid__item.o-grid__item--grow-0
                button.c-ad-applicants__action.o-button.o-button--small(
                  :disabled='activeApplicantsSelection.length === 0'
                  @click='activeApplicantsSelection.length > 1 ? (showMultiUserStatusChange = true) : setSelectedApplicant(activeApplicantsSelection[0])'
                  role='button'
                ) {{ Dictionary.C.ChangeStatus }}
              .o-grid__item.o-grid__item--grow-0
                button.c-ad-applicants__action.o-button.o-button--small(
                  :disabled='activeApplicantsSelection.length === 0'
                  @click='startSendMessageFlow()'
                  role='button'
                ) {{ Dictionary.S.SendMessage }}
              .o-grid__item.o-grid__item--grow-0(v-if='!["RentedOut", "Retired", "RetiredFromRentedOut", "Cancelled", "Deleted"].includes(selected.status)')
                button.c-ad-applicants__action.o-button.o-button--small(
                  :disabled='activeApplicantsSelection.length !== 1 || selectContainsDeleted'
                  @click='startRentOutFlow'
                  role='button'
                ) {{ Dictionary.R.RentOut }}
              .o-grid__item.o-grid__item--grow-0(v-if='selected.status === "RentedOut"')
                button.c-ad-applicants__action.o-button.o-button--small(
                  :disabled='activeApplicantsSelection.length !== 1 || selectContainsDeleted || selectContainsRentedOut'
                  @click='starAssignNextFlow'
                  role='button'
                ) {{ Dictionary.A.AssignToNext }}
              .o-grid__item.o-grid__item--grow-0(v-if='selected.status === "RentedOut"')
                button.c-ad-applicants__action.o-button.o-button--small(@click='startCancelFlow' role='button') {{ Dictionary.C.CancelRentOut }}
      .o-grid__group
        .o-grid__item.o-grid__item--12.o-grid__item--desktop-3.o-grid__item--align-right
          c-fc-vselect(:model='statusFilter' v-model='statusFilter.value') 

    .c-ad-applicants__data(v-if='!isMobile')
      vue-good-table(
        :columns='activeApplicantsColumns',
        :mode='tableMode',
        :pagination-options='paginationSettingsData',
        :row-style-class='getApplicantsRowClass',
        :rows='allApplicants',
        :select-options='{ enabled: true, selectOnCheckboxOnly: true, selectionInfoClass: "custom-class", selectionText: "rows selected", clearSelectionText: "clear", disableSelectInfo: true }',
        :sort-options='{ enabled: true, initialSortBy: initialSortField ? { field: initialSortField, type: "asc" } : "" }',
        :totalRows='totalResults'
        @on-page-change='onPageChange'
        @on-per-page-change='onPerPageChange'
        @on-row-click='multiselectRowClick($event, "applicants-table")'
        @on-selected-rows-change='activeApplicantsSelectionChanged'
        @on-sort-change='onSortChange'
        ref='table'
        styleClass='o-table'
      )
        .c-ad-applicants__empty(slot='emptystate')
          .o-heading.o-heading--size-4 {{ model.applicantsTranslations.applicantsNotFoundHeadline }}
        template(slot='table-row' slot-scope='props')
          template(v-if='props.column.field == "fullName"')
            button.c-ad-applicants__name.o-bodytext.o-bodytext--size-small(@click='setSelectedApplicant(props.row.userId)') {{ getUserName(props.row) }}
          template(v-else-if='props.column.field == "verificationStatus"')
            svg.c-ad-applicants__icon.o-svg-icon(
              :class='[props.row.verificationStatus === "Verified" ? "o-svg-icon--success" : props.row.verificationStatus === "Rejected" ? "o-svg-icon--error" : "o-svg-icon--neutral"]'
              type='image/svg+xml'
              viewbox='0 0 42 34'
            )
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-profile' y='0')
          template.test(v-else-if='props.column.field == "status"')
            span {{ getApplicantStatus(props.row) }}
          template.test(v-else-if='props.column.field == "created"')
            span {{ getApplicantCreated(props.row) }}
          template.test(v-else-if='props.column.field == "followUpDate"')
            span {{ getApplicantFollowUpDate(props.row) }}

          template.test(v-else-if='props.column.field == "sortingKey"')
            span {{ props.row.pensionFundText }}

          template.test(v-else-if='props.column.field == "actions"')
            .c-ad-applicants__applicant-action
              button.o-link(:title='props.row.note || ``' @click='setSelectedApplicant(props.row.userId)' v-if='!!getApplicantNote(props.row)')
                svg.c-ad-applicants__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-pen' y='0')
              .o-link
                svg.c-ad-applicants__icon.o-svg-icon(
                  :class='[getDocumentVerificationStatusClass(props.row)]'
                  type='image/svg+xml'
                  v-if='hasElevatedRights && !!getDocumentVerificationStatusClass(props.row)'
                  viewbox='0 0 42 34'
                )
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-list' y='0')
              button.o-link(@click='startSendMessageFlow(props.row.userId)' v-if='props.row.advertsWithUnreadMessages')
                svg.c-ad-applicants__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
          template(v-else) {{ props.formattedRow[props.column.field] }}
      .c-search-result__loading(v-if='loading')
        c-loading-animation(:overlay='true')
    .c-ad-applicants__data.c-ad-applicants__data--mobile(v-else)
      .c-ad-applicants__item.o-grid.o-grid--gutter-tiny(:key='`applicants-item-table-${key}`' v-for='(item, key) in pageOfItems')
        .o-grid__group
          .o-grid__item.o-grid__item--10
            .o-grid.o-grid--gutter-tiny
              .o-grid__group
                .o-grid__item.o-grid__item--grow-0
                  .o-tag {{ item.applicantNumber }}
                .o-grid__item.o-grid__item--grow-0(v-if='getStatusLabel(item.status)')
                  .o-tag {{ getStatusLabel(item.status) }}
          .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right.c-ad-applicants__applicant-action
            svg.c-ad-applicants__icon.o-svg-icon(@click='startSendMessageFlow(item.userId)' type='image/svg+xml' viewbox='0 0 42 34')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
          .o-grid__item.o-grid__item--10
            .o-bodytext.o-bodytext--size-small {{ item.fullName }}
            .c-ad-applicants__note.o-bodytext.o-bodytext--size-small(v-if='getApplicantState(item.userId)') {{ Dictionary.I.InternalNote }}: {{ getApplicantState(item.userId).note }}
          .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right.c-ad-applicants__applicant-action
            input.o-input.u-visually-hidden.c-ad-applicants__actions-trigger(:id='item.userId' type='checkbox')
            .c-ad-applicants__actions-menu.o-grid.o-grid--gutter-tiny
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  button.c-ad-applicants__action.o-button.o-button--small(@click='setSelectedApplicant(item.userId)') {{ Dictionary.C.ChangeStatus }}
                .o-grid__item.o-grid__item--12
                  button.c-ad-applicants__action.o-button.o-button--small(@click='startRentOutFlow' role='button') {{ Dictionary.R.RentOut }}
            label(:for='item.userId')
              svg.c-ad-applicants__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-more-full' y='0')
      c-pagination(:items='allApplicants' @changePage='onChangePage') 
  c-messages-overlay
  c-modal.c-modal--applicant(:cornerClose='!isMobile' @close='handleClose' v-if='selectedApplicant')
    template(slot='body')
      transition(mode='out-in' name='o-transition__fade')
        c-applicant-details(:applicant-data='selectedApplicant')
  c-users-status-change(
    :state-target-id='selected.id',
    :users='activeApplicantsSelection'
    @close='showMultiUserStatusChange = false'
    state-type='advert'
    v-if='showMultiUserStatusChange'
  )
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import cApplicantDetails from '@/components/applicant-details'
// import { messagesService } from '@/_services'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import { VueGoodTable } from 'vue-good-table'
import TablePagination from '@/components/table/table-pagination/index'
import { searchService } from '@/_services'
export default {
  name: 'c-ad-applicants',
  props: {
    model: {
      type: Object
    },
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      initialSortField: '',
      pageOfItems: [],
      resetPaging: false,
      tableMode: 'remote',
      showMultiUserStatusChange: false,
      onlyApplicantsWithNewMessages: false,
      activeApplicantsSelection: [],
      applicantsThreads: [],
      activeApplicantsColumns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'applicantNumber'
        },
        {
          label: this.Dictionary.N.Name,
          field: 'fullName'
        },
        {
          label: '',
          field: 'verificationStatus',
          width: '48px',
          sortable: false
        },
        {
          label: this.Dictionary.A.Age,
          field: 'age'
        },
        {
          label: this.Dictionary.S.Seniority,
          field: 'seniority'
        },
        {
          label: this.Dictionary.P.PensionFund,
          field: 'sortingKey'
        },
        {
          label: this.Dictionary.S.Status,
          field: 'status'
        },
        {
          label: this.Dictionary.A.ApplicationDate,
          field: 'created'
        },
        {
          label: this.Dictionary.F.FollowUp,
          field: 'followUpDate'
        },
        {
          label: '',
          field: 'actions',
          width: '120px',
          sortable: false
        }
      ],
      statusFilter: {
        helperText: null,
        value: null,
        placeholderText: this.Dictionary.S.Status,
        isMandatory: false,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: this.getAdvertApplicantsStatuses(true),
        validation: null
      }
    }
  },
  components: {
    VueGoodTable,
    TablePagination,
    'c-applicant-details': cApplicantDetails,
    'c-fc-vselect': FormControllVSelect
  },
  computed: {
    ...mapState('advertManagement', ['selected']),
    ...mapGetters('advertManagement', ['advertApplicantsIds']),
    ...mapState('applicantsManagement', {
      selectedApplicant: 'selected',
      applicantsData: 'applicants',
      serverParams: 'serverParams',
      totalResults: 'totalResults'
    }),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    ...mapGetters('user', ['hasElevatedRights']),
    syncInProgress() {
      return this.loading && this.loadingEndpoints.includes(`/search/indexes/advert/reindex?id=${this.selected.id}`)
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    combinedAddress() {
      return this.combineAddress(
        this.selected.street,
        this.selected.number,
        this.selected.letter,
        this.selected.floor,
        this.selected.door,
        this.selected.postalCode,
        this.selected.city
      )
    },
    applicantsCountText() {
      return this.model.applicantsTranslations && this.model.applicantsTranslations.applicantsResultsCountText
        ? this.model.applicantsTranslations.applicantsResultsCountText.replace('{0}', this.totalResults || 0)
        : this.totalResults || 0
    },
    // newApplicants() {
    //   return this.allApplicants.filter(a => a.status === 'New')
    // },
    applicantsDataReady() {
      return this.selected.applicantsData !== undefined
    },
    applicantsFilterOptions() {
      const applicantsFilterOptions = [
        { value: false, labelText: this.Dictionary.A.All },
        { value: true, labelText: this.Dictionary.W.WithUnansweredMessages }
      ]
      return applicantsFilterOptions
    },
    advertOrganization() {
      const company = this.companies.find(c => c.id === this.selected.companyId)
      return company && company.organizationId
    },
    selectContainsDeleted() {
      return this.activeApplicantsSelection.some(aId => {
        const target = this.selected.applicantsData.find(a => a.userId === aId)
        return target.status === 'Deleted'
      })
    },
    selectContainsRentedOut() {
      return this.activeApplicantsSelection.some(aId => {
        const target = this.selected.applicantsData.find(a => a.userId === aId)
        return target.status === 'RentedOut'
      })
    },
    allApplicants() {
      let allApplicants = []
      if (this.applicantsData.length > 0) {
        allApplicants = this.applicantsData.map(applicant => {
          let applicantData

          if (this.selected.type === 'MembershipAdvertBasedOnResidence') {
            const applicantApplicationData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
            if (applicantApplicationData) {
              applicantData = { ...applicant, ...applicantApplicationData }
            } else {
              applicantData = Object.assign({}, applicant || {})
            }
          } else {
            applicantData = Object.assign({}, applicant || {})
          }

          applicantData.userName = this.getUserName(applicantData)
          applicantData.vgtDisabled = !applicantData.applicantNumber || applicant.status === 'Deleted'

          return applicantData
        })
      }

      return allApplicants
    },
    statusFilterValue() {
      return this.statusFilter.value
    }
  },
  methods: {
    async syncApplicants() {
      try {
        await searchService.postAdvertReindex(this.selected.id)
      } catch (error) {
        // eslint-disable-next-line
        console.warn('Could not reindex advert', error)
      }
      try {
        const response = await searchService.postAdvertApplicantsReindex(this.selected.id)
        if (response && response.status === 204) {
          this.getApplicantsData()
        }
      } catch (error) {
        // eslint-disable-next-line
        console.warn('Could not reindex applicants of advert', error)
      }
    },
    getApplicantsRowClass(row) {
      return row && row.vgtDisabled ? 'opaque' : ''
    },
    getStatusLabel(key) {
      return (key && this.getAdvertApplicantsStatuses()[key]) || key
    },
    formatTheDate(value, format = 'DD-MM-YYYY') {
      return value && this.formatDate(value, format)
    },
    setSelectedApplicant(id) {
      const applicantData = this.allApplicants.find(a => a.userId === id)
      this.$store.dispatch('applicantsManagement/setSelected', { id, data: applicantData })
    },
    getDocumentVerificationStatusClass(applicant) {
      const isVerified = applicant.documentationVerifiedIn && applicant.documentationVerifiedIn.includes(this.advertOrganization)
      const isRejected = applicant.documentationRejectedIn && applicant.documentationRejectedIn.includes(this.advertOrganization)
      return isVerified ? 'o-svg-icon--success' : isRejected ? 'o-svg-icon--error' : null
    },
    getApplicantState(id) {
      const applicantState = this.selected.applicantsState && this.selected.applicantsState.find(applicant => applicant.userId === id)
      return applicantState
    },
    getApplicantStatus(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && this.getStatusLabel(applicantAdvertData.status)
    },
    getApplicantCreated(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && this.formatTheDate(applicantAdvertData.created, 'DD-MM-YYYY HH:mm')
    },
    getApplicantFollowUpDate(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && this.formatTheDate(applicantAdvertData.followUpDate)
    },
    getApplicantNote(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && applicantAdvertData.note
    },
    getApplicantAge(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && applicantAdvertData.note
    },
    getApplicantSeniority(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && applicantAdvertData.note
    },
    getApplicantPensionFundText(applicant) {
      const applicantAdvertData = this.selected.applicantsData.find(a => a.userId === applicant.userId)
      return applicantAdvertData && applicantAdvertData.pensionFundText
    },
    handleClose() {
      if (this.selectedApplicant) {
        this.$store.dispatch('applicantsManagement/removeSelected')
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    activeApplicantsSelectionChanged(value) {
      this.activeApplicantsSelection = (value && value.selectedRows.filter(row => !row.vgtDisabled).map(row => row.userId)) || []
    },
    startSendMessageFlow(userId) {
      const target = userId && this.applicantsThreads.find(t => t.userId === userId)
      if (target && target.thread) {
        // console.log('messages/selectThread')
        this.$store.dispatch('messages/selectThread', target.thread.id)
      } else {
        const usersIds = userId ? [userId] : this.activeApplicantsSelection
        const users = usersIds.map(id => {
          const user = this.applicantsData.find(user => user.userId === id)
          return { name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email, id: user.userId }
        })
        this.$store.dispatch('messages/selectThreadRelatedToEntity', {
          id: this.selected.id,
          users,
          name: this.combinedAddress
        })
      }
    },
    startRentOutFlow() {
      this.$store.dispatch('advertManagement/toggleConfirmation', { id: this.selected.id, action: 'rentOut', userId: this.activeApplicantsSelection[0] })
    },
    starAssignNextFlow() {
      this.$store.dispatch('advertManagement/toggleConfirmation', { id: this.selected.id, action: 'assignNext', userId: this.activeApplicantsSelection[0] })
    },
    startCancelFlow() {
      const rentedOutUser = this.selected.applicantsData.find(a => a.status === 'RentedOut')
      this.$store.dispatch('advertManagement/toggleConfirmation', { id: this.selected.id, action: 'cancelOut', userId: rentedOutUser && rentedOutUser.userId })
    },
    getApplicantsData() {
      const data = {
        advertId: this.selected.id,
        IncludeDeleted: true,
        filters: {}
      }
      if (this.onlyApplicantsWithNewMessages) {
        data.filters.HasUnreadMessages = true
      }
      if (this.statusFilterValue) {
        data.filters.status = this.statusFilterValue
      }

      if (!this.tableMode) {
        data.page = 0
        data.pageSize = 2147483647
      }
      this.$store.dispatch('applicantsManagement/searchApplicants', data)
    },
    onPageChange(params) {
      if (this.tableMode) {
        this.$store.commit('applicantsManagement/updateParams', { page: params.currentPage, perPage: params.currentPerPage })
      }
    },
    onPerPageChange(params) {
      if (this.tableMode) {
        this.$store.commit('applicantsManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
      }
    },
    onSortChange(params) {
      if (this.tableMode) {
        if (this.selected.type !== 'MembershipAdvertBasedOnResidence') {
          this.$store.commit('applicantsManagement/updateParams', {
            page: 1,
            sort: [
              {
                type: params[0].type,
                field: params[0].field
              }
            ]
          })
        }
      }
    }
  },
  created() {
    if (!this.hasElevatedRights) {
      this.activeApplicantsColumns.splice(2, 4)
    } else if (this.selected.type !== 'MembershipAdvertBasedOnResidence') {
      this.activeApplicantsColumns.splice(3, 3)
    } else {
      this.initialSortField = 'sortingKey'
      this.tableMode = ''
      this.activeApplicantsColumns.forEach(c => (c.sortable = false))
    }

    this.getApplicantsData()
    // if (this.advertApplicantsIds && this.advertApplicantsIds.length > 0) {
    //   this.getApplicantsThreads(this.advertApplicantsIds)
    // }
  },
  destroyed() {
    this.$store.commit('applicantsManagement/reset')
  },
  watch: {
    activeThread(newVal) {
      if (!newVal) return
      let target = this.applicantsThreads.find(t => t.thread.id === newVal.id)

      if (target) {
        target = Object.assign(target, { userId: target.userId, thread: newVal })
      }
    },
    allApplicants() {
      this.activeApplicantsSelectionChanged()
    },
    serverParams: {
      deep: true,
      handler() {
        if (this.totalResults > 0) {
          this.getApplicantsData()
        }
      }
    },
    totalResults(newVal) {
      if (newVal === 0) {
        this.$refs.table.currentPerPage = 10
        this.$refs.table.currentPage = 1
      }
    },
    statusFilterValue() {
      const params = {
        currentPage: this.serverParams.page,
        currentPerPage: this.serverParams.perPage
      }

      if (params.currentPage > 1) {
        params.currentPage = 1

        this.onPageChange(params)
      } else {
        this.getApplicantsData()
      }
    }
    // advertApplicantsIds(newVal) {
    //   if (newVal && newVal.length > 0) this.getApplicantsThreads(newVal)
    // }
  }
}
</script>
<style lang="scss" scoped>
.c-ad-applicants {
  $root: &;
  &__top {
    margin: toRem(20px 0);
  }
  &__action {
    white-space: nowrap;
    @include max('desktop', -1) {
      display: block;
      width: 100%;
    }

    & + & {
      margin-left: 10px;
    }
  }

  &__empty {
    margin: toRem(50px 0);
    text-align: center;
    &-button {
      margin-top: 20px;
      &:first-child:last-child {
        margin-top: 0;
      }
    }
  }
  &__count {
    order: 2;
    display: flex;

    h4 + #{$root}__newCount {
      margin-left: 10px;
    }

    @include min('desktop') {
      order: 1;
    }
  }

  &__newCount {
    background: $ocean-primary;
    border-radius: 50%;
    color: $white;
    width: 30px;
    text-align: center;
    height: 30px;
    padding: 4px 0 5px;
  }

  &__actions {
    order: 3;
  }
  &__name {
    background: transparent;
    border: none;
    text-decoration: underline;
    text-align: left;
    &:hover {
      cursor: pointer;
    }
  }
  &__item {
    padding: 15px;
    border: 1px solid $grey-4;

    & + & {
      margin-top: 10px;
    }
  }
  &__note {
    color: $ocean-2;
  }
  &__actions-trigger {
    &:checked {
      + #{$root}__actions-menu {
        display: flex;

        + label {
          &:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &__actions-menu {
    display: none;
    position: absolute;
    bottom: 100%;
    right: 100%;
    background: $white;
    border: 1px solid $grey-4;
    padding: 15px;
    z-index: z('elements');
  }
  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    order: 1;
    @include min('desktop') {
      order: 2;
    }
  }
  &__applicant-action {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include min('desktop') {
      justify-content: start;
    }
  }
  &__icon {
    width: 25px;
    height: 25px;
    @include min('desktop') {
      width: 25px;
      height: 25px;
    }

    & + & {
      margin-left: 10px;
    }
  }

  &__option {
    outline: toRem(1px) solid $ocean-primary;
    margin: 0;

    & + & {
      border-left: toRem(1px) solid $ocean-primary;
    }

    .o-input[type='radio'] {
      &:checked {
        + label {
          background-color: $crab-primary;
          color: $ocean-primary;

          @include min('desktop') {
            background-color: $ocean-primary;
            color: $white;
          }
        }
      }
      + label {
        padding: toRem(6px) toRem(14px);
        font-family: 'Inria Serif', serif;
        background-color: $white;
        color: $ocean-primary;
        min-height: 100%;
        display: flex;
        align-items: center;

        &::before,
        &:after {
          display: none;
        }
      }
    }
  }

  &__modal {
    &--search {
      ::v-deep .c-modal__container {
        width: 1040px;
      }
    }
  }
}
</style>
