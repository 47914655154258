import { searchService, userService } from '@/_services'
import { getQueryParameters, setQueryParameters, setAgentId } from '@/_helpers/query-parameters'
import _ from 'lodash'
import moment from 'moment'

const getFacetable = () => {
  return [
    'Commune',
    'City',
    'PostalCode',
    'PostalCodeName',
    'Street',
    'Number',
    'Floor',
    'Door',
    'Rooms',
    'Area',
    'Facilities',
    'Place',
    'PropertyYear',
    'Types',
    'PropertyCompanyName',
    'PropertyOrganization',
    'PropertyFacilities'
  ]
}
const getDefaultFilters = () => {
  return {
    Id: [],
    Commune: [],
    City: [],
    Place: [],
    Street: [],
    BuildingName: [],
    PostalCodeName: [],
    PostalCode: 0,
    Types: [],
    Rent: 0,
    Rooms: 0,
    Area: 0,
    PropertyType: [],
    Facilities: [],
    PropertyFacilities: [],
    Floor: [-3, 30],
    AvailableFrom: 0,
    RentalPeriod: 0,
    PropertyCompany: [],
    PropertyCompanyId: [],
    PropertyOrganization: [],
    PropertyOrganizationId: [],
    PropertyYear: null,
    Number: 0,
    Door: [],
    Letter: [],
    Created: [],
    Updated: [],
    StreetAddress: [],
    Property: undefined,
    PostalCodeAndPostalCodeName: [],
    PropertyName: []
  }
}

const getSearchAgentIdFromUrl = () => {
  const locationArray = document.location.pathname.split('/')
  if (locationArray[locationArray.length - 2] === 'search-agents') {
    return locationArray[locationArray.length - 1]
  } else {
    return undefined
  }
}

const initialFilters = Object.assign({}, getDefaultFilters())
const searchConfiguration = document.getElementById('search-configuration')
  ? JSON.parse(new DOMParser().parseFromString(document.getElementById('search-configuration').text, 'text/html').documentElement.textContent)
  : false

const initialState = {
  search: 'string',
  pageSize: 16,
  page: 0,
  orderBy: 'Created',
  orderDirection: 'DESC',
  mixedResults: true,
  maxPromotedResults: 30,
  facets: {},
  filters: initialFilters,
  results: [],
  promotedResults: [],
  mapResults: [],
  facetsResultsCount: 0,
  resultsCount: 0,
  mapInfoData: {},
  mapResultsCount: 0,
  modalShowing: false,
  mapFavorites: {
    results: []
  },
  mapShowing: false,
  searchParameters: '',
  searchPresets: {},
  searchAgentId: getSearchAgentIdFromUrl(),
  searchAgents: [],
  searchQueryParameters: true,
  companies: [],
  countries: [],
  organizations: [],
  facilities: searchConfiguration.facilities || [],
  facilityLocations: searchConfiguration.facilityLocations || [],
  propertiesByCompanies: null
}

const filterKeys = Object.keys(getDefaultFilters())

const splitEscapedString = string => {
  const result = []

  if (string === null || string === undefined) {
    return result
  }

  let acc = ''

  for (let i = 0; i < string.length; i++) {
    const c = string.charAt(i)

    if (c === ',' && (i === 0 || string.charAt(i - 1) !== '/')) {
      result.push(acc.replaceAll('/,', ','))
      acc = ''
    } else {
      acc += c
    }
  }

  result.push(acc.replaceAll('/,', ','))

  return result
}

getQueryParameters().forEach((value, key) => {
  if (filterKeys.includes(key)) {
    if (Array.isArray(initialFilters[key])) {
      if (isNaN(splitEscapedString(value)[0])) {
        Object.assign(initialFilters, { [key]: splitEscapedString(value) })
      } else {
        Object.assign(initialFilters, { [key]: splitEscapedString(value).map(x => parseFloat(x)) })
      }
    } else {
      if (isNaN(value)) {
        Object.assign(initialFilters, { [key]: value })
      } else {
        Object.assign(initialFilters, { [key]: parseFloat(value) })
      }
    }
  } else if (key === 'mapShowing') {
    initialState.mapShowing = value === 'true'
  }
  // console.log(initialFilters)
})

const filterArrayToSearch = (filter, value) => {
  let searchFrendlyValue
  switch (filter) {
    // case 'Id':
    //   searchFrendlyValue = value
    //   break
    // case 'Commune':
    //   searchFrendlyValue = value
    //   break
    // case 'City':
    //   searchFrendlyValue = value
    //   break
    // case 'PostalCode':
    //   searchFrendlyValue = value
    //   break
    // case 'Street':
    //   searchFrendlyValue = value
    //   break
    // case 'Number':
    //   searchFrendlyValue = value
    //   break
    case 'Floor':
      if (value[1] >= getDefaultFilters()[filter][1] || !value[1]) {
        searchFrendlyValue = '[' + value[0] + ';]'
      } else {
        searchFrendlyValue = '[' + value[0] + ';' + value[1] + ']'
      }
      break
    // case 'Door':
    //   searchFrendlyValue = value
    //   break
    // case 'Letter':
    //   searchFrendlyValue = value
    //   break
    case 'Rooms':
      searchFrendlyValue = '[' + value + ';]'
      break
    case 'Area':
      searchFrendlyValue = '[' + value + ';]'
      break
    // case 'Facilities':
    //   searchFrendlyValue = value
    //   break
    // case 'Created':
    //   searchFrendlyValue = value
    //   break
    // case 'Updated':
    //   searchFrendlyValue = value
    //   break
    // case 'Place':
    //   searchFrendlyValue = value
    //   break
    case 'PropertyYear':
      searchFrendlyValue = '[' + value + ';]'
      break
    // case 'Type':
    //   searchFrendlyValue = value
    //   break
    // case 'SubType':
    //   searchFrendlyValue = '[' + value + ';]'
    //   break
    case 'AvailableFrom':
      if (value === 1) {
        searchFrendlyValue = '[;NOW+14DAYS]'
      } else if (value === 2) {
        searchFrendlyValue = '[NOW+14DAYS;NOW+3MONTHS]'
      } else if (value) {
        searchFrendlyValue = `[;${moment.utc(value).format('YYYY-MM-DD')}T00:00:00Z]`
      }
      break
    // case 'RentalPeriod':
    //   searchFrendlyValue = '[' + value + ';]'
    //   break
    // case 'Company':
    //   searchFrendlyValue = value
    //   break
    // case 'PropertyFacilities':
    //   searchFrendlyValue = value
    //   break
    // case 'BuildingName':
    //   searchFrendlyValue = value
    //   break
    // case 'PostalCodeName':
    //   searchFrendlyValue = value
    //   break
    case 'Rent':
      searchFrendlyValue = `[;${value}]`
      break
    default:
      searchFrendlyValue = value
  }

  return searchFrendlyValue
}

const searchTofilterArray = (search, value) => {
  let filterFrendlyValue
  switch (search) {
    case 'Floor':
      filterFrendlyValue = value.slice(1, -1).split(';')
      break
    case 'PropertyYear':
    case 'PostalCode':
    case 'Number':
    case 'Rooms':
    case 'Area':
    case 'Rent':
      filterFrendlyValue = parseInt(value.slice(1, -1).replace(';', ''))
      break
    case 'AvailableFrom':
      if (value === '[;NOW+14DAYS]') {
        filterFrendlyValue = 1
      } else if (value === '[NOW+14DAYS;NOW+3MONTHS]') {
        filterFrendlyValue = 2
      } else if (value) {
        filterFrendlyValue = moment.utc(value.slice(1, -1).replace(';', '')).format('YYYY-MM-DD')
      }
      break
    default:
      // all Array types exept FLOOR
      filterFrendlyValue = value
  }

  // console.log(search, value, filterFrendlyValue)

  return filterFrendlyValue
}

const prepareFilters = (state, forSearch) => {
  const filters = state.filters
  const presets = state.searchPresets || {}
  const preparedFilters = {}

  Object.keys(filters)
    .filter(key => {
      if (Array.isArray(filters[key])) {
        return filters[key].length > 0 && JSON.stringify(filters[key]) !== JSON.stringify(getDefaultFilters()[key])
      } else {
        return filters[key] !== getDefaultFilters()[key]
      }
    })
    .forEach(key => {
      if (forSearch) {
        Object.assign(preparedFilters, { [key]: filterArrayToSearch(key, filters[key]) })
      } else {
        Object.assign(preparedFilters, { [key]: filters[key] })
      }
    })

  if (
    !!preparedFilters.AvailableFrom ||
    (!!preparedFilters.RentalPeriod && preparedFilters.RentalPeriod !== 'Unlimited') ||
    preparedFilters.OpenHouseDate ||
    !state.mixedResults
  ) {
    preparedFilters.Type = 'Residence'
    preparedFilters.TypeIndividualResidence = 'true'
  }

  const presetFilters = { ...preparedFilters, ...presets }
  return presetFilters
}

const prepareSearch = search => {
  const preparedSearch = {}

  Object.keys(search).forEach(key => {
    Object.assign(preparedSearch, { [key]: searchTofilterArray(key, search[key]) })
  })

  const fullFiltersWithSearch = { ...initialFilters, ...preparedSearch }

  return fullFiltersWithSearch
}

const existingFilterValue = (allFilters, filter, value) => {
  if (Array.isArray(allFilters[filter])) {
    if (allFilters[filter].includes(value)) {
      return true
    } else {
      return false
    }
  } else if (allFilters[filter] !== value) {
    return false
  } else {
    return true
  }
}

export const search = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state,
    searchFilterId: state => state.filters.Id,
    searchFilterCommune: state => state.filters.Commune,
    searchFilterCity: state => state.filters.City,
    searchFilterPostalCode: state => state.filters.PostalCode,
    searchFilterStreet: state => state.filters.Street,
    searchFilterStreetName: state => state.filters.StreetName,
    searchFilterNumber: state => state.filters.Number,
    searchFilterFloor: state => state.filters.Floor,
    searchFilterDoor: state => state.filters.Door,
    searchFilterLetter: state => state.filters.Letter,
    searchFilterRooms: state => state.filters.Rooms,
    searchFilterArea: state => state.filters.Area,
    searchFilterFacilities: state => state.filters.Facilities,
    searchFilterCreated: state => state.filters.Created,
    searchFilterUpdated: state => state.filters.Updated,
    searchFilterPlace: state => state.filters.Place,
    searchFilterPropertyYear: state => state.filters.PropertyYear,
    searchFilterTypes: state => state.filters.Types,
    searchFilterSubType: state => state.filters.SubType,
    searchFilterPensionFund: state => state.filters.PensionFund,
    searchFilterAvailableFrom: state => state.filters.AvailableFrom,
    searchFilterRentalPeriod: state => state.filters.RentalPeriod,
    searchFilterPropertyCompany: state => state.filters.PropertyCompany,
    searchFilterPropertyOrganization: state => state.filters.PropertyOrganization,
    searchFilterPropertyFacilities: state => state.filters.PropertyFacilities,
    searchFilterBuildingName: state => state.filters.BuildingName,
    searchFilterPostalCodeName: state => state.filters.PostalCodeName,
    searchFilterRent: state => state.filters.Rent,
    activeFilters: state => {
      const activeFilters = {}
      const activeFiltersKeys = Object.keys(state.filters).filter(key => {
        if (Array.isArray(state.filters[key])) {
          return JSON.stringify(state.filters[key]) !== JSON.stringify(getDefaultFilters()[key])
        } else {
          return state.filters[key] > 0 || (typeof state.filters[key] === 'string' && state.filters[key].length > 0)
        }
      })

      activeFiltersKeys.forEach(key => {
        activeFilters[key] = state.filters[key]
      })

      return activeFilters
    },
    preparedFiltersSearch: state => prepareFilters(state, true),
    preparedFilters: state => prepareFilters(state, false),
    facetable: () => getFacetable(),
    activeSearchAgent: state => state.searchAgents.find(agent => agent.id === state.searchAgentId),
    defaultFilters: () => getDefaultFilters(),
    resultsCountDeep: state => {
      let resultsCountDeep = 0
      state.results.forEach(result => {
        if (result.$type !== 'Residence') {
          resultsCountDeep = resultsCountDeep + Math.max(result.residencesCount, 1)
        } else {
          resultsCountDeep++
        }
      })
      return resultsCountDeep
    },
    residenceFacilities: state => {
      const residenceFacilities = state.facilities.filter(facility => {
        const location = facility.location || facility.facilityLocationId
        if (location === '55909e38-b10f-4126-8cc8-526268bebd54') {
          facility.value = facility.id
          return facility
        }
        return false
      })
      return residenceFacilities || []
    },
    propertyFacilities: state => {
      const propertyFacilities = state.facilities.filter(facility => {
        const location = facility.location || facility.facilityLocationId
        if (location === 'a39cc085-3043-48fd-a16a-7646fda3ec1a') {
          facility.value = facility.id
          return facility
        }
        return false
      })

      return propertyFacilities || []
    },
    subOrganizations: state => {
      let subOrganizations = []
      state.organizations.forEach(o => {
        subOrganizations = [...subOrganizations, ...o.subOrganizations]
      })
      return subOrganizations
    },
    companiesInfo: () => {
      const companiesInfo = {}
      searchConfiguration.membershipOrganizations.forEach(mo => {
        Object.assign(companiesInfo, mo.companies)
      })
      return companiesInfo
    },
    membershipOrganizationsInfo: () => {
      return searchConfiguration.membershipOrganizations
    }
  },
  actions: {
    async getCompanies({ commit }) {
      try {
        const response = await searchService.getCompanies()
        if (response && response.status === 200) {
          commit('updateData', { companies: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getOrganizations({ commit }) {
      try {
        const response = await searchService.getOrganizations()
        if (response && response.status === 200) {
          commit('updateData', { organizations: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getFacilities({ commit }) {
      try {
        this.dispatch('search/getFacilityLocations')
        const response = await searchService.getFacilities()
        if (response && response.status === 200) {
          commit('updateData', { facilities: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getFacilityLocations({ commit }) {
      try {
        const response = await searchService.getFacilityLocations()
        if (response && response.status === 200) {
          commit('updateData', { facilityLocations: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleSearchModal({ commit }, payload) {
      commit('toggleSearchModal', payload)
    },
    addFilter({ commit, state }, { filter, value, updateResults }) {
      if (!existingFilterValue(state.filters, filter, value)) {
        commit('addFilter', { filter, value })
        if (updateResults) {
          if (state.mapShowing) {
            this.dispatch('search/getAllResults')
          } else {
            this.dispatch('search/getResults')
          }
        } else {
          this.dispatch('search/getFacets')
        }
      }
    },
    addMinMaxFilter({ commit, state }, { filter, value, updateResults }) {
      if (!existingFilterValue(state.filters, filter, value[0]) || !existingFilterValue(state.filters, filter, value[1])) {
        commit('addMinMaxFilter', { filter, value })
        if (updateResults) {
          // console.log(state.mapShowing)
          if (state.mapShowing) {
            this.dispatch('search/getAllResults')
          } else {
            this.dispatch('search/getResults')
          }
        } else {
          this.dispatch('search/getFacets')
        }
      }
    },
    removeFilter({ commit, state }, { filter, value, updateResults }) {
      if (existingFilterValue(state.filters, filter, value)) {
        commit('removeFilter', { filter, value })
        if (updateResults) {
          // console.log(state.mapShowing)
          if (state.mapShowing) {
            this.dispatch('search/getAllResults')
          } else {
            this.dispatch('search/getResults')
          }
        } else {
          this.dispatch('search/getFacets')
        }
      }
    },
    setSorting({ commit }, payload) {
      commit('setSorting', payload)
      this.dispatch('search/getResults')
    },
    toggleMap({ commit, state }, payload) {
      commit('toggleMap', payload)
      commit('setSearchParameters', setQueryParameters({ mapShowing: state.mapShowing }, true))
      //  this.dispatch('search/getFacets')
    },
    getFacets: _.debounce(async function ({ commit, state }) {
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        const response = await searchService.postSearch({
          pageSize: 0,
          facets: getFacetable(),
          filters: preparedSearchFilters,
          mixedResults: state.mixedResults && !preparedSearchFilters.Type
        })
        commit('setFacets', response.data.facets)
        commit('setFacetsResultsCount', response.data.totalResults)
        commit('setSearchParameters', setQueryParameters(state.filters, false))
        // console.log('getFacets')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    getMapFavorites: _.debounce(async function ({ commit, state }, payload) {
      try {
        const mapDataResidences = Object.assign([], payload.residences)
        const mapDataProperties = Object.assign([], payload.properties)
        const preparedFavoriteIds = {
          Id: [],
          PropertyId: []
        }

        mapDataProperties.forEach(item => {
          preparedFavoriteIds.PropertyId.push(item.id)
        })

        mapDataResidences.forEach(item => {
          preparedFavoriteIds.Id.push(item.id)
        })

        const response = await searchService.postSearchLocations({
          pageSize: 2147483647,
          page: 0,
          orderBy: state.orderBy,
          orderDirection: state.orderDirection,
          // facets: getFacetable(),
          filters: preparedFavoriteIds
        })

        commit('setMapFavorites', response.data)
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    getAllResults: _.debounce(async function ({ commit, state }) {
      commit('setPage', 0)
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        const response = await searchService.postSearchLocations({
          pageSize: 2147483647,
          page: 0,
          orderBy: state.orderBy,
          orderDirection: state.orderDirection,
          // facets: getFacetable(),
          filters: preparedSearchFilters,
          mixedResults: state.mixedResults && !preparedSearchFilters.Type
        })
        commit('setFacets', response.data.facets)
        commit('setCount', response.data.totalResults)
        commit('setFacetsResultsCount', response.data.totalResults)
        commit('setResults', response.data.results)
        if (state.searchQueryParameters) commit('setSearchParameters', setQueryParameters(state.filters, true)) // TRUE
        // console.log('getResults')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    getMapInfo: _.debounce(async function ({ commit, state }, id) {
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        preparedSearchFilters.PropertyId = id
        const response = await searchService.postSearch({
          pageSize: 2147483647,
          page: state.page,
          orderBy: state.orderBy,
          orderDirection: state.orderDirection,
          // facets: getFacetable(),
          filters: preparedSearchFilters,
          mixedResults: state.mixedResults && !preparedSearchFilters.Type
        })
        commit('setMapInfoData', response.data)
      } catch (error) {
        // console.log(error)
      }
    }, 250),

    getResults: _.debounce(async function ({ commit, state }) {
      commit('setPage', 0)
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        let response
        if (state.propertiesByCompanies) {
          response = await searchService.getPropertiesByCompanies({
            companyIds: state.propertiesByCompanies.companies,
            organizationId: state.propertiesByCompanies.organization,
            pageSize: state.pageSize === -1 ? 2147483647 : state.pageSize,
            page: state.page
          })
        } else {
          const data = {
            pageSize: state.pageSize === -1 ? 2147483647 : state.pageSize,
            page: state.page,
            // facets: getFacetable(),
            filters: preparedSearchFilters,
            mixedResults: state.mixedResults && !preparedSearchFilters.Type
          }
          if (state.orderBy && state.orderDirection) {
            data.orderBy = state.orderBy
            data.orderDirection = state.orderDirection
          }
          response = await searchService.postSearch(data)
        }
        commit('setFacets', response.data.facets)
        commit('setCount', response.data.totalResults)
        commit('setFacetsResultsCount', response.data.totalResults)
        commit('setResults', response.data.results)

        this.dispatch('search/getHighlightedResults')
        if (state.searchQueryParameters) commit('setSearchParameters', setQueryParameters(state.filters, true)) // TRUE
        // console.log('getResults')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    async getHighlightedResults({ commit, state }) {
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        const response = await searchService.postSearch({
          pageSize: state.maxPromotedResults,
          page: 0,
          orderBy: '*_random',
          orderDirection: 'ASC',
          filters: preparedSearchFilters,
          mixedResults: false,
          mode: 'Promoted'
        })

        if (response && response.status === 200) {
          commit('setPromotedResults', response.data.results)
        }
      } catch (error) {}
    },
    sendToSearchPageWithFilters({ commit, state }) {
      commit('setSearchParameters', setQueryParameters(state.filters, false))
      window.location.href = '/find' + state.searchParameters
    },
    setSearchQueryParameters({ commit }, payload) {
      commit('setSearchQueryParameters', payload)
    },
    getResultsPage: _.debounce(async function ({ commit, state }, payload) {
      commit('setPage', payload)

      try {
        const preparedSearchFilters = prepareFilters(state, true)
        let response
        if (state.propertiesByCompanies) {
          response = await searchService.getPropertiesByCompanies({
            companyIds: state.propertiesByCompanies.companies,
            organizationId: state.propertiesByCompanies.organization,
            pageSize: state.pageSize === -1 ? 2147483647 : state.pageSize,
            page: state.page
          })
        } else {
          response = await searchService.postSearch({
            pageSize: state.pageSize === -1 ? 2147483647 : state.pageSize,
            page: state.page,
            orderBy: state.orderBy,
            orderDirection: state.orderDirection,
            // facets: getFacetable(),
            filters: preparedSearchFilters,
            mixedResults: state.mixedResults && !preparedSearchFilters.Type
          })
        }
        commit('addResults', response.data.results)
        // console.log('getResultsPage')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    resetFilterToDefault({ commit }, payload) {
      if (payload) {
        commit('resetFilterToDefault', payload)
        this.dispatch('search/getFacets')
      }
    },
    setSearchPresets({ commit }, payload) {
      if (payload) {
        commit('setSearchPresets', payload)
      }
    },
    setSearchAgent({ commit, state, getters }, payload) {
      if (payload && state.searchAgentId !== payload) {
        commit('setSearchAgentId', payload)
      }
      commit('setFilters', prepareSearch(getters.activeSearchAgent.filters))
      commit('setSearchQueryParameters', false)
      if (state.mapShowing) {
        this.dispatch('search/getAllResults')
      } else {
        this.dispatch('search/getResults')
      }
      setAgentId(state.searchAgentId)
    },
    getSearchAgents: _.debounce(async function ({ commit, state }) {
      try {
        const response = await searchService.getSearchAgents()
        commit('setSearchAgents', response.data)
        if (!state.searchAgentId) {
          this.dispatch('search/setSearchAgent', response.data[0].id)
        } else {
          this.dispatch('search/setSearchAgent', state.searchAgentId)
        }
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    postSearchAgent: _.debounce(async function ({ commit, state }, { searchAgentName, redirectUrl }) {
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        const response = await searchService.postSearchAgent({
          name: searchAgentName,
          filters: preparedSearchFilters
        })
        if (redirectUrl) {
          window.location = `${window.location.origin}/${redirectUrl}/${response.data}?created`
        } else {
          commit('setSearchAgentId', response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    patchSearchAgent: _.debounce(async function ({ commit, state }, { searchAgentName }) {
      try {
        const preparedSearchFilters = prepareFilters(state, true)
        const response = await searchService.patchSearchAgent(state.searchAgentId, { name: searchAgentName, filters: preparedSearchFilters })
        commit('patchSearchAgent', { id: response.data.id, name: response.data.name, filters: response.data.filters }) // TODO Recheck this
        this.dispatch('search/toggleSearchModal', false)
        this.dispatch('search/setSearchAgent')
      } catch (error) {
        // console.log(error)
      }
    }, 250),
    deleteSearchAgent: _.debounce(async function ({ commit, state }) {
      try {
        const response = await searchService.deleteSearchAgent(state.searchAgentId)

        if (response.status === 204) {
          commit('deleteSearchAgent', state.searchAgentId)
          if (state.searchAgents.length > 0) {
            this.dispatch('search/setSearchAgent', state.searchAgents[0].id)
          } else {
            commit('setSearchAgentId', undefined) // TODO what happens when we delete all search agents
            setAgentId()
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }, 250),

    async getCountries({ commit }) {
      try {
        const countryResponse = await userService.getCountries()
        if (countryResponse && countryResponse.status === 200) {
          commit('updateData', { countries: countryResponse.data })
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mutations: {
    updateData(state, payload) {
      if (payload) {
        Object.keys(state).forEach(key => {
          if (payload[key] !== undefined) {
            state[key] = payload[key]
          }
        })
      }
    },
    toggleSearchModal(state, payload) {
      if (payload) {
        state.modalShowing = payload
      } else {
        state.modalShowing = !state.modalShowing
      }
    },
    addFilter(state, { filter, value }) {
      if (filter && value !== undefined) {
        if (Array.isArray(state.filters[filter])) {
          if (Array.isArray(value)) {
            state.filters[filter] = value
          } else {
            state.filters[filter].push(value)
          }
        } else {
          state.filters[filter] = value
        }
      }
    },
    setFilters(state, payload) {
      state.filters = { ...state.filters, ...payload }
    },
    addMinMaxFilter(state, { filter, value }) {
      if (filter && value !== undefined) {
        state.filters[filter] = Object.assign([], [value[0], value[1]])
      }
    },
    removeFilter(state, { filter, value }) {
      if (filter && value !== undefined) {
        if (Array.isArray(state.filters[filter])) {
          state.filters[filter].splice(state.filters[filter].indexOf(value), 1)
        } else {
          state.filters[filter] = getDefaultFilters()[value]
        }
      }
    },
    resetFilterToDefault(state, filter) {
      state.filters[filter] = getDefaultFilters()[filter]
    },
    setResults(state, payload) {
      if (state.mapShowing) {
        state.mapResults = Object.assign([], payload)
      } else {
        state.results = Object.assign([], payload)
      }
    },
    setPromotedResults(state, payload) {
      state.promotedResults = Object.assign([], payload)
    },
    addResults(state, payload) {
      payload.forEach(item => state.results.push(item))
    },
    setFacets(state, payload) {
      state.facets = Object.assign({}, payload)
    },
    setCount(state, payload) {
      if (state.mapShowing) {
        state.mapResultsCount = payload
      } else {
        state.resultsCount = payload
      }
    },
    setFacetsResultsCount(state, payload) {
      state.facetsResultsCount = payload
    },
    setMapFavorites(state, payload) {
      state.mapFavorites = payload
    },
    setMapInfoData(state, payload) {
      state.mapInfoData = payload
    },
    setSearchParameters(state, payload) {
      state.searchParameters = payload
    },
    setSorting(state, payload) {
      state.orderBy = payload.orderBy
      state.orderDirection = payload.orderDirection
      if (payload.mixedResults !== undefined) {
        state.mixedResults = payload.mixedResults
      }
    },
    setPage(state, payload) {
      state.page = payload
    },
    toggleMap(state, payload) {
      if (payload) {
        state.mapShowing = payload
      } else {
        state.mapShowing = !state.mapShowing
      }
    },
    setSearchPresets(state, payload) {
      state.searchPresets = Object.assign({}, payload)
    },
    setSearchAgentId(state, payload) {
      state.searchAgentId = payload
    },
    setSearchQueryParameters(state, payload) {
      state.searchQueryParameters = payload
    },
    setSearchAgents(state, payload) {
      state.searchAgents = Object.assign([], payload)
    },
    patchSearchAgent(state, { id, name, filters }) {
      state.searchAgents.forEach(agent => {
        if (agent.id === id) {
          agent.name = name
          agent.filters = Object.assign({}, filters)
        }
      })
    },
    deleteSearchAgent(state, payload) {
      state.searchAgents.forEach((agent, index) => {
        if (agent.id === payload) {
          state.searchAgents.splice(index, 1)
        }
      })
    }
  }
}
