<template lang="pug">
.c-form-checklist
  fieldset.o-input__container
    legend.o-label.o-label--before(:class='[{ "o-label--required": isMandatory }]' v-if='model.labelText') {{ model.labelText }}
    .o-grid.o-grid--direction-column.o-grid--justify-content-center.o-grid--gutter-tiny
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0(:key='key' v-for='(option, key) in model.options')
          .o-input__wrap
            input.o-input(
              :aria-labelledby='`${id}-${key}-label ${ariaLabeledBy}`',
              :autocomplete='model.autocomplete || "do-not-autofill"',
              :class='[{ error: $v.value.$error }]',
              :disabled='model.readOnly',
              :id='`${id}-${key}`',
              :name='id',
              :value='option.value'
              @change='setvalue($event.target.value)'
              placeholder=' '
              type='checkbox'
              v-model='model.value'
            )
            label.o-label(:for='`${id}-${key}`', :id='`${id}-${key}-label`' v-if='option.name') {{ option.name }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-checklist',
  data() {
    return {
      id: this._uid,
      value: []
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.isMandatory) {
            const valueSlected = this.value !== null && this.value.length > 0
            const requiredOptionSelected = this.hasMandatoryOptions
              ? this.value.some(answer => this.model.options.filter(option => option.required && option.value === answer).length > 0)
              : true
            const minimumMet =
              this.model.validation && this.model.validation.minSelected ? this.value && this.value.length >= this.model.validation.minSelected : true
            return valueSlected && requiredOptionSelected && minimumMet
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    isMandatory() {
      return this.model.isMandatory || this.hasMandatoryOptions || (this.model.validation && !!this.model.validation.minSelected)
    },
    hasMandatoryOptions() {
      return this.model.options.some(option => option.required)
    },
    ariaLabeledBy() {
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue(value) {
      if (this.value.indexOf(value) > -1) {
        this.value.splice(this.value.indexOf(value), 1)
      } else {
        this.value.push(value)
      }
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      if (this.model.value === null) {
        this.$emit('change', [])
      } else if (typeof this.model.value === 'string') {
        this.value = this.model.value.split(',')
        this.$emit('change', this.model.value.split(','))
        this.$v.value.$touch()
      } else {
        this.value = this.model.value
      }

      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-checklist {
}
</style>
