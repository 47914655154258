<template lang="pug">
.c-offer-preview
  .c-offer-preview__top
    button.c-offer-preview__close(@click='close')
      svg.c-offer-preview__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-offer-preview__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-offer-preview__content(v-if='selectedResidence')
    h2.c-offer-preview__heading.o-heading.o-heading--size-2.o-heading--profile-page.o-wrap--max-width-content-wide.o-wrap--overflow-hidden(
      :class='{ "o-wrap--padding-side-default": isMobile }'
    ) {{ selectedOffer.residenceAddress }}
    .c-property(data-property-id='')
      .c-property__top
        .c-property__top-content(v-if='allMedia && allMedia.length > 0')
          c-property-hero(:media='allMedia')
        .c-property__top-sub.o-wrap.o-wrap--max-width-content-wide(:class='{ "o-wrap--padding-side-default": isMobile }')
          .o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-start.o-grid--gutter-small
            .o-grid__group
              .c-property__tags.o-grid__item.o-grid__item--grow-0
                .o-tag.o-tag--ocean(v-if='selectedResidence.entityInfo.isNew') {{ Dictionary.N.New_en }}
                .o-tag.o-tag--crab(v-for='entityType in selectedResidence.entityInfo.Types') {{ entityType }}
                .o-tag.o-tag--coral(v-if='selectedResidence.entityInfo.isOnWaitingList') {{ Dictionary.W.WaitingList }}
                .o-tag.o-tag--coral(v-if='selectedResidence.entityInfo.isOnInterestList') {{ Dictionary.I.InterestList }}
                .o-tag.o-tag--coral(v-if='selectedResidence.entityInfo.isMembersOnly') {{ Dictionary.M.MembersOnly }}
                .o-tag.o-tag--kelp(v-if='selectedResidence.entityInfo.isFreeNow') {{ Dictionary.A.AvailableNow }}
      .c-property__main.o-wrap--max-width-content-wide.o-wrap--overflow-hidden(:class='{ "o-wrap--padding-side-default": isMobile }')
        .c-property__main-top
          h2.c-property__heading.o-heading.o-heading--size-1 {{ selectedResidence.entityInfo.title }}
          .c-property__address
            .o-bodytext.o-bodytext--size-medium {{ selectedResidence.entityInfo.addressLine1 }}
            .o-bodytext.o-bodytext--size-medium {{ selectedResidence.entityInfo.addressLine2 }}
          .o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-start.o-grid--gutter-normal.o-grid--padding-none
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                a.c-state-action.o-link(href='#map')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-pin' y='0')
                  span.o-bodytext.o-bodytext--size-medium.o-bodytext--700.o-bodytext--inria {{ Dictionary.S.ShowOnMap }}
              .o-grid__item.o-grid__item--grow-0(v-if='selectedResidence.mediaCategories.blueprints.length > 0')
                c-state-action.o-link(:text='Dictionary.B.Blueprints' action='toggleHeroModalToTab' b='' icon='floorplan' payload='blueprint' store='property')
        .c-property__main-content
          .c-property-description(v-if='selectedResidence.descriptionModel.title || selectedResidence.descriptionModel.description')
            h3.c-property-description__heading.o-heading.o-heading--size-3.o-heading {{ selectedResidence.descriptionModel.title }}
            c-show-more(:description='selectedResidence.descriptionModel.description', :rich-text='true')
          .c-property-description(v-if='selectedOffer.offerText')
            h3.c-property-description__heading.o-heading.o-heading--size-3.o-heading {{ Dictionary.O.Offer }}
            .o-bodytext.o-bodytext--size-medium.o-richtext(v-html='selectedOffer.offerText')
          .c-property-description(v-if='selectedOffer.showingText')
            h3.c-property-description__heading.o-heading.o-heading--size-3.o-heading {{ Dictionary.S.Showing }}
            .o-bodytext.o-bodytext--size-medium.o-richtext(v-html='selectedOffer.showingText')
          c-property-facilities(
            :all-facilities='selectedResidence.facilitiesModel.facilitiesOrder.map(f => f.id)',
            :building-facilities='selectedResidence.facilitiesModel.propertyFacilities.map(f => f.id)',
            :facilities='selectedResidence.facilitiesModel.facilitiesMap',
            :residence-facilities='selectedResidence.facilitiesModel.residenceFacilities.map(f => f.id)'
          )
          .c-property-facts
            h3.c-property-facts__heading.o-heading.o-heading--size-3.o-heading {{ selectedResidence.factsModel.title }}
            .c-property-facts__grid.o-grid.o-grid--direction-row.o-grid--align-items-top.o-grid--justify-content-start
              .o-grid__group
                .c-property-facts__grid-item.o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  table.c-property-facts__table
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.F.FreeFrom }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.residence.availableFrom | formatDate }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.P.PropertyType }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.residenceTypes && selectedResidence.factsModel.residenceTypes.toString() }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.A.Area }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.residence.area }} m²
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.R.Rooms }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.residence.rooms }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.F.Floor }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.residence.floor }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.F.Furnished }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ getFurnishedValue(selectedResidence.factsModel.residence.furnished) }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.P.PetsAllowed }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.petsAllowed ? Dictionary.Y.Yes : Dictionary.N.No }}
                .c-property-facts__grid-item.o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  table.c-property-facts__table
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.S.SmokingAllowed }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.smokingAllowed ? Dictionary.Y.Yes : Dictionary.N.No }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.S.SharingAllowed }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.sharingAllowed ? Dictionary.Y.Yes : Dictionary.N.No }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.B.BuildingBuiltYear }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.property.year }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.E.EnergyMark }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.property.energyAssessment }}
                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.L.Landlord }}
                      td
                        .o-bodytext.o-bodytext--size-small {{ selectedResidence.factsModel.propertyOwner ? selectedResidence.factsModel.propertyOwner : selectedResidence.factsModel.owner }}
                        .o-bodytext.o-bodytext--size-small(v-if='selectedResidence.factsModel.ownerEmail') {{ selectedResidence.factsModel.ownerEmail }}
                        .o-bodytext.o-bodytext--size-small(v-if='selectedResidence.factsModel.ownerPhone') {{ selectedResidence.factsModel.ownerPhone }}

                    tr
                      td.o-bodytext.o-bodytext--size-small.o-bodytext--700
                        span {{ Dictionary.O.Owner }}
                      td.o-bodytext.o-bodytext--size-small
                        span {{ selectedResidence.factsModel.owner }}
        .c-property__main-sidebar
          .c-property-financials
            .c-property-financials__main
              table.c-property-financials__section
                tr.c-property-financials__section-first
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-large.o-bodytext--700 {{ selectedResidence.financialsModel.monthlyRentExcludingAcontoTitle }}
                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-large.o-bodytext--700.notranslate {{ selectedResidence.financialsModel.monthlyRentExcludingAconto.amount | money }}
                tr
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-small {{ selectedResidence.financialsModel.prepaidRentTitle }}

                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ selectedResidence.financialsModel.prepaidRent.amount | money }}
                tr
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-small {{ selectedResidence.financialsModel.acontoTitle }}
                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ selectedResidence.financialsModel.aconto.amount | money }}
                tr
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-small {{ selectedResidence.financialsModel.depositTitle }}
                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ selectedResidence.financialsModel.deposit.amount | money }}
                tr(
                  :key='buster'
                  v-if='selectedOffer.correctionRentDescription && selectedOffer.correctionRentAmount && selectedOffer.correctionRentAmount.Amount'
                )
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-small {{ selectedOffer.correctionRentDescription }}
                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ selectedOffer.correctionRentAmount && selectedOffer.correctionRentAmount.Amount | money }}
                tr
                  td.c-property-financials__section-key
                    span.o-bodytext.o-bodytext--size-small {{ selectedResidence.financialsModel.firstPaymentTitle }}
                  td.c-property-financials__section-value
                    span.o-bodytext.o-bodytext--size-small.notranslate {{ (selectedResidence.financialsModel.firstPayment.amount + ((selectedOffer.correctionRentAmount && selectedOffer.correctionRentAmount.Amount) || 0)) | money }}
                tr(v-if='selectedResidence.financialsModel.rentIncreaseWarning !== null')
                  td.c-property-financials__section-disclaimer(colspan='2')
                    span.o-bodytext.o-bodytext--size-xsmall.o-bodytext--italic {{ selectedResidence.financialsModel.rentIncreaseWarning }}
          .c-offer-preview__response(v-if='!isManagement')
            h3.c-offer-preview__response-headline.o-heading.o-heading--size-4(v-if='!isInvitationExpired') {{ model.offerResponseHeadline }}
            .c-offer-preview__response-description.o-bodytext.o-bodytext--size-small(v-if='isInvitationExpired') {{ offerInvitationExpiredDescription }}
            .c-offer-preview__response-description.o-bodytext.o-bodytext--size-small {{ offerResponseDescription }}
            .c-offer-preview__response-actions(v-if='!isInvitationExpired')
              button.c-offer-preview__response-action.o-button.o-button--inverted(
                :class='{ "o-button--bordered": offerUserState !== "OfferAccepted", "o-button--locked": offerUserState === "OfferAccepted" }',
                :disabled='!offerUserCanChangeAnswer'
                @click='showConfirmation'
              ) {{ Dictionary.Y.Yes }}
              button.c-offer-preview__response-action.o-button.o-button--inverted(
                :class='{ "o-button--bordered": offerUserState !== "OfferDeclined", "o-button--locked": offerUserState === "OfferDeclined" }',
                :disabled='!offerUserCanChangeAnswer'
                @click='declineOffer'
              ) {{ Dictionary.N.No }}
            .c-offer-preview__response-helper
              span.o-bodytext.o-bodytext--size-xsmall.o-bodytext--italic {{ offerUserState === 'OfferAccepted' ? Dictionary.O.OfferAcceptedHelper : offerUserState === 'OfferDeclined' ? Dictionary.O.OfferDeclinedHelper : '' }}
            .c-offer-preview__response-position.o-bodytext(v-if='offerPosition && offerUserState === "OfferAccepted"') {{ Dictionary.Y.YourPlacementIs.replace('{0}', offerPosition) }}
          .c-property-questions(v-if='selectedResidence.questionsModel && !isManagement')
            h4.c-property-questions__heading.o-heading.o-heading--size-4 {{ selectedResidence.questionsModel.title }}
            .c-property-questions__body.o-bodytext.o-bodytext--size-small {{ selectedResidence.questionsModel.description }}

            c-state-action.o-link.c-property-questions__link(
              :payload='{ id: selectedOffer.id, name: selectedOffer.residenceAddress }',
              :requires-authentication='true',
              :text='selectedResidence.questionsModel.callToAction'
              action='selectThreadRelatedToEntity'
              icon=''
              store='messages'
            ) 
          c-messages-overlay
        .c-property__main-bottom
      c-worth-to-know(:model='selectedResidence.residenceWorthToKnowModel')
      .c-property__bottom
        c-single-property-map(
          :headline='selectedResidence.mapModel.content.title',
          :lat='selectedResidence.mapModel.location.latitude',
          :lng='selectedResidence.mapModel.location.longitude'
        )
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import { residenceManagementService, offersManagementService } from '@/_services'
import WorthToKnowBox from '@/components/worth-to-know-box'
import moment from 'moment'
export default {
  name: 'c-offer-preview',
  props: {
    model: Object,
    isManagement: Boolean
  },
  data() {
    return {
      id: this._uid,

      buster: 0,
      tags: {},
      selectedResidence: undefined,
      offerPosition: null
    }
  },
  components: {
    'c-worth-to-know': WorthToKnowBox
  },
  computed: {
    ...mapState('offersManagement', { selectedOffer: 'selected' }),
    ...mapState('search', ['facilities', 'facilityLocations']),
    ...mapState('user', ['userId']),
    ...mapState('residenceManagement', { residence: 'selected', residenceTypes: 'types' }),
    ...mapGetters('offersManagement', ['intialDataReady']),
    selectedResidenceId() {
      return this.selectedResidence && this.selectedResidence.id
    },
    isInvitationExpired() {
      const recipient = this.selectedOffer.recipientsState
      return recipient[0].invitationExpiredDate != null
    },
    offerResponseDescription() {
      const offerResponseDescription =
        this.model.offerResponseDescription &&
        this.selectedOffer.deadline &&
        this.model.offerResponseDescription.replace('{0}', this.formatDate(this.selectedOffer.deadline))
      return offerResponseDescription
    },
    offerInvitationExpiredDescription() {
      const invitationExpiredDate = this.selectedOffer.recipientsState[0].invitationExpiredDate
      const offerInvitationExpiredDescription =
        this.model.offerInvitationExpiredDescription &&
        invitationExpiredDate &&
        this.model.offerInvitationExpiredDescription.replace('{0}', this.formatDate(invitationExpiredDate))
      return offerInvitationExpiredDescription
    },
    offerUserState() {
      const userState = this.selectedOffer.recipientsState.find(r => r.userId === this.userId)
      return userState && userState.state
    },
    offerAnswerDeadlinePassed() {
      return moment.utc(this.selectedOffer.deadline).isBefore(moment.utc(), 'day')
    },
    offerUserCanChangeAnswer() {
      return (this.selectedOffer.state === 'Published' || this.selectedOffer.state === 'Changed') && !this.offerAnswerDeadlinePassed
    },
    allMedia() {
      let allMedia = []
      if (this.selectedResidence.mediaCategories) {
        allMedia = [
          ...(this.selectedResidence?.mediaCategories?.images.map(i => {
            const media = {
              type: 'picture',
              url: i.path
            }

            return media
          }) ?? []),
          ...(this.selectedResidence?.mediaCategories?.blueprints?.map(i => {
            const media = {
              type: 'blueprint',
              url: i.path
            }

            return media
          }) ?? []),
          ...(this.selectedResidence?.mediaCategories?.panoramas?.map(i => {
            const media = {
              type: '360',
              url: i.path
            }

            return media
          }) ?? []),
          ...(this.selectedResidence?.mediaCategories?.videos?.map(i => {
            const media = {
              type: 'video',
              url: i.path
            }

            return media
          }) ?? [])
        ]
      }

      return allMedia
    }
  },
  methods: {
    close() {
      this.$store.dispatch('offersManagement/hideExample', !this.isManagement)
    },
    async getResidence() {
      try {
        const response = await residenceManagementService.getResidencePageModel(this.selectedOffer.residenceId)

        if (response && response.status === 200) {
          this.selectedResidence = response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getOfferPosition() {
      try {
        const response = await offersManagementService.getOfferPosition(this.selectedOffer.id)

        if (response && response.status === 200) {
          this.offerPosition = response.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    showConfirmation() {
      this.$store.dispatch('offersManagement/toggleConfirmation', { userId: this.userId, id: this.selectedOffer.id })
    },
    declineOffer() {
      this.$store.dispatch('offersManagement/declineOffer', this.userId)
    },
    getFurnishedValue(furnishedState) {
      let furnishedValue = ''

      if (furnishedState && furnishedState === 'Furnished') furnishedValue = this.Dictionary.Y.Yes
      if (furnishedState && furnishedState === 'NotFurnished') furnishedValue = this.Dictionary.N.No
      if (furnishedState && furnishedState === 'PartiallyFurnished') furnishedValue = this.Dictionary.P.PartiallyFurnished
      return furnishedValue
    }
  },
  created() {
    if (this.facilities.length === 0) {
      this.$store.dispatch('search/getFacilities')
    }
    this.getResidence()
    if (!this.isManagement) {
      this.getOfferPosition()
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  },
  watch: {
    selectedOffer() {
      this.buster++
    }
  }
}
</script>
<style lang="scss" scoped>
.c-offer-preview {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  @include min('desktop') {
    position: relative;
    z-index: unset;
    min-width: 0;
  }
  &__heading {
    @include max('desktop', -1) {
      padding-bottom: 20px;
      margin-bottom: 10px;
    }
  }
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__content {
    overflow: auto;
    height: calc(100% - 62px);
    @include min('desktop') {
      height: auto;
      overflow: initial;
    }
  }

  &__response {
    padding: 25px 15px 30px;
    margin: 0 -#{map-get($wrappadding, 'phone')};
    background-color: $ocean-primary;
    color: $white;
    text-align: center;

    @include min('desktop') {
      padding: 40px 25px;
      margin: 0;
    }

    &-headline {
      margin-bottom: 15px;
    }
    &-description {
      margin-bottom: 20px;
    }
    &-action {
      & + & {
        margin-left: 10px;
      }
    }
    &-helper,
    &-position {
      margin-top: 15px;
    }
  }
}
</style>
