import apiClient from '@/_helpers/api-client'
export const auditsService = {
  getAuditsAdverts,
  getAuditsOffers,
  getAuditsUsers,
  getAuditsIntegrationJobs,
  postAuditsAdvetNote,
  postAuditsOfferNote
}

async function getAuditsAdverts(id, data) {
  return await apiClient({
    url: `/audits/adverts/${id}`,
    method: 'POST',
    data
  })
}
async function getAuditsOffers(id, data) {
  return await apiClient({
    url: `/audits/offers/${id}`,
    method: 'POST',
    data
  })
}
async function getAuditsUsers(id, data) {
  return await apiClient({
    url: `/audits/users/${id}`,
    method: 'POST',
    data
  })
}
async function getAuditsIntegrationJobs() {
  return await apiClient({
    url: '/audits/integrationjobs',
    method: 'GET'
  })
}
async function postAuditsAdvetNote(id, data) {
  return await apiClient({
    url: `/audits/adverts/${id}/add-note`,
    method: 'POST',
    data
  })
}
async function postAuditsOfferNote(id, data) {
  return await apiClient({
    url: `/audits/offers/${id}/add-note`,
    method: 'POST',
    data
  })
}
