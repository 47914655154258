<template lang="pug">
.c-facilities-management
  .c-facilities-management__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  .c-facilities-management__search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
    .o-grid__group
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-5(v-if='searchFacilityData.options.length > 0')
        c-fc-select(:key='resetKey', :model='searchFacilityData' v-model='facility')
      .o-grid__item.o-grid__item--desktop-1
        button.o-link.c-facilities-management__search__button(
          :disabled='!searchFacility.value'
          @click='searchFacility.value = null'
          role='button'
          v-debounce.keyup='500'
        ) {{ Dictionary.R.Reset }}
  .c-facilities-management__form-section
    c-fc-text(:key='resetKey', :model='name' v-model='name.value')
  .c-facilities-management__form-section
    c-form-radiolist(:key='resetKey', :model='location' v-model='location.value')
  .c-facilities-management__form-section
    c-form-checkbox(:key='resetKey', :model='applicableByUser' v-model='applicableByUser.value')
  .c-facilities-management__footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        button.o-link.c-facilities-management__cancel(@click='handleReset') {{ Dictionary.C.Cancel }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.c-parameters-management__submit(:disabled='!canSubmit' @click='showConfirmation = true') {{ Dictionary.S.Save }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showConfirmation = false' v-if='showConfirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 
    template(slot='body')
      .o-bodytext 
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-cta.o-button--inverted(:cta-text='Dictionary.Y.YesPlease' @click='submitChanges')
          .o-grid__item.o-grid__item--12
            span.o-link(@click='showConfirmation = false') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllRadioList from '@/components/form/controlls/form-radiolist'
import FormControllCheckBox from '@/components/form/controlls/form-checkbox'
import FormControllSelect from '@/components/form/controlls/form-select'
import { facilitiesManagementService } from '@/_services'
export default {
  name: 'c-facilities-management',
  props: {
    model: Object
  },

  data() {
    return {
      searchFacility: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.F.Facility,
        isMandatory: false,
        options: [],
        validation: null
      },
      name: {
        value: '',
        isMandatory: true,
        placeholderText: this.Dictionary.N.Name,
        readOnly: false,
        labelText: this.Dictionary.F.Facility
      },
      location: {
        value: null,
        isMandatory: true,
        readOnly: false,
        labelText: this.Dictionary.L.Location,
        options: [
          {
            value: 'a39cc085-3043-48fd-a16a-7646fda3ec1a',
            name: this.Dictionary.P.Property
          },
          {
            value: '55909e38-b10f-4126-8cc8-526268bebd54',
            name: this.Dictionary.R.Residence
          }
        ]
      },
      applicableByUser: {
        value: false,
        isMandatory: false,
        readOnly: false,
        placeholderText: this.model.applicableByUserText
      },

      showConfirmation: false,
      resetKey: 0
    }
  },

  components: {
    'c-fc-text': FormControllText,
    'c-form-radiolist': FormControllRadioList,
    'c-form-checkbox': FormControllCheckBox,
    'c-fc-select': FormControllSelect
  },
  methods: {
    handleReset() {
      this.searchFacility.value = null
      this.name.value = null
      this.location.value = null
      this.applicableByUser.value = false
      this.resetKey += 1
    },
    submitChanges() {
      const locationName = this.location.value === 'a39cc085-3043-48fd-a16a-7646fda3ec1a' ? 'Building' : 'Residence'
      const postData = {
        name: this.name.value,
        location: locationName,
        applicableByUser: this.applicableByUser.value
      }
      const patchData = {
        name: this.name.value,
        facilityLocationId: this.location.value,
        applicableByUser: this.applicableByUser.value
      }
      if (this.searchFacility.value) {
        this.updateFacility(this.searchFacility.value, patchData)
      } else {
        this.postFacility(postData)
      }
    },
    async postFacility(data) {
      try {
        const response = await facilitiesManagementService.createFacility(data)
        if (response && response.status === 200) {
          this.showConfirmation = false
          this.handleReset()
          this.$store.dispatch('search/getFacilities')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async updateFacility(id, data) {
      try {
        const response = await facilitiesManagementService.updateFacility(id, data)
        if (response && response.status === 204) {
          this.showConfirmation = false
          this.handleReset()
          this.$store.dispatch('search/getFacilities')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getFacility(id) {
      try {
        const response = await facilitiesManagementService.getFacility(id)
        if (response && response.status === 200) {
          this.name.value = response.data.name
          this.applicableByUser.value = response.data.applicableByUser
          this.location.value = response.data.facilityLocationId
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters('search', ['residenceFacilities', 'propertyFacilities']),
    ...mapState('search', ['facilities']),
    searchFacilityData() {
      const searchFacilityData = Object.assign({}, this.searchFacility)
      searchFacilityData.value = this.searchFacility.value || null
      searchFacilityData.options = [
        { group: true, label: this.Dictionary.R.ResidenceFacilities, options: this.residenceFacilities },
        { group: true, label: this.Dictionary.P.PropertyFacilities, options: this.propertyFacilities }
      ]
      return searchFacilityData
    },
    canSubmit() {
      return this.name.value && this.location.value
    },
    facility: {
      get() {
        return this.searchFacility.value
      },
      set(value) {
        this.searchFacility.value = value
        if (value) {
          this.getFacility(value)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-facilities-management {
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }
  &__form {
    &--footer {
      margin-top: 25px;
      padding-top: 25px;
    }
    &-section {
      & + & {
        margin-top: 25px;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__search {
    padding: toRem(16px 20px);
    background-color: $grey-5;
    margin-bottom: 35px;

    &__button {
      width: 100%;
      min-height: 52px;
    }
  }

  &__footer {
    margin-top: 50px;
  }
}
</style>
