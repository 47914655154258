<template lang="pug">
.c-advert-management-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--6(:class='{ "o-grid__item--desktop-5": hasElevatedRights, "o-grid__item--desktop-4": !hasElevatedRights }')
      c-fc-text(:model='search' @submit='executeSearch()' v-model='search.value')
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-3(v-if='status.options.length > 0')
      c-fc-vselect(:model='status' v-model='status.value')
    .o-grid__item.o-grid__item--6(
      :class='{ "o-grid__item--desktop-2": !hasElevatedRights, "o-grid__item--desktop-3": hasElevatedRights }'
      v-if='status.options.length > 0 && hasElevatedRights'
    )
      c-fc-vselect(:model='owner' v-model='owner.value')

    .o-grid__item.o-grid__item--6(
      :class='{ "o-grid__item--desktop-3": hasElevatedRights, "o-grid__item--desktop-2": !hasElevatedRights }'
      v-if='companies.length > 0'
    )
      c-fc-vselect(:model='responsible' v-model='responsible.value')
    .o-grid__item.o-grid__item--4.o-grid__item--desktop-3(v-if='hasElevatedRights')
      c-fc-checkbox.c-advert-management-search__checkbox(:model='membershipAdvert' v-model='membershipAdvert.value')
    .o-grid__item.o-grid__item--4.o-grid__item--desktop-2
      c-fc-checkbox.c-advert-management-search__checkbox(:model='newApplicants' v-model='newApplicants.value')
    .o-grid__item.o-grid__item--4.o-grid__item--desktop-2
      c-fc-checkbox.c-advert-management-search__checkbox(:model='newMessages' v-model='newMessages.value')
  .o-grid__group
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-2.o-grid__item--align-right
      button.o-button.c-advert-management-search__button(@click='executeSearch()' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--6.o-grid__item--desktop-1
      button.o-link.c-advert-management-search__button(:disabled='resetDisabled' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllVSelect from '@/components/form/controlls/form-vselect'
import { getQueryParameters } from '@/_helpers/query-parameters'
export default {
  name: 'c-advert-management-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      },
      membershipAdvert: {
        value: false,
        helperText: this.model.membershipAdvertHelperText,
        placeholderText: this.Dictionary.M.MemberAdverts,
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      newApplicants: {
        value: getQueryParameters().get('newApplicants') === '[1;*]',
        helperText: this.model.newApplicantsHelperText,
        placeholderText: this.Dictionary.N.NewApplicants,
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      newMessages: {
        value: getQueryParameters().get('hasUnreadMessages') || false,
        helperText: this.model.newMessagesHelperText,
        placeholderText: this.Dictionary.N.NewMessages,
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      owner: {
        helperText: this.model.ownerSelectHelperText,
        value: (getQueryParameters().get('companyId') && getQueryParameters().get('companyId').split(',')) || null,
        placeholderText: this.Dictionary.O.Owner,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      },
      status: {
        helperText: this.model.statusSelectHelperText,
        value: (getQueryParameters().get('status') && getQueryParameters().get('status').split(',')) || null,
        readOnly: false,
        placeholderText: this.Dictionary.S.Status,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: this.getAdvertStatuses(true)
      },
      responsible: {
        helperText: this.model.responsibleSelectHelperText,
        value: (getQueryParameters().get('creator') && getQueryParameters().get('creator').split(',')) || null,
        placeholderText: this.Dictionary.R.Responsible,
        settings: {
          clearable: false,
          multiple: true,
          reduce: option => option.value
        },
        options: []
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-vselect': FormControllVSelect,
    'c-fc-text': FormControllText,
    'c-fc-checkbox': FormControllCheckbox
  },
  computed: {
    ...mapState('search', ['companies']),
    ...mapGetters('usersManagement', ['allManagedUsers']),
    ...mapGetters('user', ['hasElevatedRights']),
    ...mapState('advertManagement', ['serverParams']),
    dataReady() {
      return this.companies.length > 0 && this.allManagedUsers.length > 0
    },
    resetDisabled() {
      return (
        !this.search.value && !this.membershipAdvert.value && !this.newApplicants.value && !this.owner.value && !this.status.value && !this.responsible.value
      )
    }
    // ownerData() {
    //   const ownerData = Object.assign({}, this.owner)
    //   ownerData.options = JSON.parse(JSON.stringify(this.companies)).map(owner => {
    //     owner.value = owner.id
    //     return owner
    //   })
    //   return ownerData
    // },
    // responsibleData() {
    //   const responsibleData = Object.assign({}, this.responsible)
    //   responsibleData.options =
    //     (this.allManagedUsers.length &&
    //       JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
    //         return {
    //           value: responsible.id,
    //           name: responsible.email.split('@')[0]
    //         }
    //       })) ||
    //     []
    //   responsibleData.options.unshift({
    //     value: '',
    //     name: this.Dictionary.A.All
    //   })
    //   return responsibleData
    // }
  },
  methods: {
    doSearch() {
      const data = {
        search: this.search.value,
        filters: {}
      }
      if (this.newApplicants.value) data.filters.newApplicants = '[1;*]'
      if (this.newMessages.value) data.filters.hasUnreadMessages = true
      if (this.membershipAdvert.value) data.filters.isMembershipAdvert = this.membershipAdvert.value
      if (this.owner.value) data.filters.companyId = this.owner.value
      if (this.status.value && this.status.value.length) data.filters.status = this.status.value
      if (this.responsible.value) data.filters.creator = this.responsible.value

      this.$store.dispatch('advertManagement/searchManagedAdverts', data)
    },
    handleReset() {
      const that = this
      this.search.value = null
      this.membershipAdvert.value = null
      this.newApplicants.value = null
      this.newMessages.value = null
      this.owner.value = null
      this.status.value = null
      this.responsible.value = null
      window.history.pushState({}, document.title, window.location.pathname)
      setTimeout(function () {
        // needed to give time for the dropdowns with 1 option to set back that option
        // that.doSearch()
        that.$store.commit('advertManagement/updateParams', {
          page: 1,
          sort: [
            {
              field: '',
              type: ''
            }
          ]
        })
      }, 0)
    },
    executeSearch() {
      if (this.serverParams.page !== 1) {
        this.$store.commit('advertManagement/updateParams', {
          page: 1,
          sort: [
            {
              field: '',
              type: ''
            }
          ]
        })
      } else {
        this.doSearch()
      }
    },
    setFilters() {
      this.responsible.options =
        this.allManagedUsers &&
        JSON.parse(JSON.stringify(this.allManagedUsers)).map(responsible => {
          return {
            value: responsible.id,
            name: responsible.email.split('@')[0]
          }
        })

      this.owner.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        return {
          name: company.name,
          value: company.id
        }
      })
    }
  },
  created() {
    if (!this.hasElevatedRights) {
      this.doSearch()
    }
    if (this.dataReady) this.setFilters()
  },
  watch: {
    serverParams: {
      deep: true,
      handler() {
        this.doSearch()
      }
    },
    dataReady(newVal) {
      if (newVal) {
        this.doSearch()
        this.setFilters()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-advert-management-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }

  &__checkbox {
    &--newapplicants {
      order: 1;
    }
    &::v-deep {
      .o-grid {
        min-height: 53px;
      }
    }
  }
}
</style>
