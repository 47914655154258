<template lang="pug">
.c-modal__mask(:class='{ "theme-dark": darkTheme }' @mousedown.self='outsideClose ? $emit("close") : ""')
  .c-modal__wrapper
    .c-modal__container
      button.c-modal__close(:aria-label='`${Dictionary.C.Close}`' @click='$emit("close")' v-if='cornerClose')
        svg.o-svg-icon.o-button__icon(:style='{ fill: cornerCloseColor }' type='image/svg+xml' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-cross' y='0')
      .c-modal__header
        slot(name='header')
      .c-modal__body
        slot(name='body')
      .c-modal__footer(:class='{ "c-modal__footer--sticky": stickyFooter }')
        slot(name='footer')
</template>
<script>
export default {
  name: 'c-modal',
  props: {
    cornerClose: { type: Boolean, default: true },
    outsideClose: { type: Boolean, default: true },
    cornerCloseColor: { type: String },
    darkTheme: { type: Boolean, default: false },
    stickyFooter: { type: Boolean, default: false }
  },
  data() {
    return {
      bodyAlreadyHidden: false
    }
  },
  mounted() {
    this.bodyAlreadyHidden = document.body.classList.contains('u-overflow--hidden')
    if (!this.bodyAlreadyHidden) document.body.classList.add('u-overflow--hidden')
    this.$emit('mounted')
    this.$el.querySelectorAll('input, button, textarea, a')[0].focus()
  },
  destroyed() {
    if (!this.bodyAlreadyHidden) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-modal {
  $root: &;
  &__mask {
    overflow: auto;
    position: fixed;
    z-index: z('modal');
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    max-width: 100%;
    max-height: 100%;

    @include min('desktop') {
      flex: 0 0 auto;
    }
  }

  &__container {
    width: toRem(545px);
    max-width: 100%;
    margin: 0px auto;
    padding: 35px 25px;
    background-color: $white;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(52, 52, 52, 0.15);
    max-height: 100%;
    overflow: auto;

    @include min('desktop') {
      padding: 75px 45px;
    }

    .theme-dark & {
      background-color: $ocean-primary;
      box-shadow: none;
      color: $white;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 25px;
    height: 22px;
    width: 27px;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 0;
    z-index: z('elements');

    svg {
      max-width: 100%;
      max-height: 100%;

      .theme-dark & {
        stroke: $white;
      }
    }
  }
  &__footer {
    &--sticky {
      @include max('desktop', -1) {
        position: sticky;
        bottom: -35px;
        z-index: 100;
      }
    }

    .o-link {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__box {
    margin-top: toRem(34px);
    background-color: $grey-5;
    padding: toRem(22px) toRem(35px) toRem(40px);
    color: $ocean-primary;
    text-align: left;
    &::v-deep a {
      color: inherit;
    }
  }

  &--confirmation {
    text-align: center;

    .c-modal__container {
      padding-top: 35px;
    }
    #{$root}__header {
      margin-bottom: 20px;
      svg {
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
      }
    }
    #{$root}__body {
      margin-bottom: 40px;
    }

    &.theme-dark {
      #{$root}__header {
        svg {
          fill: $white;
        }
      }

      .o-label--helper {
        color: $white;
      }
    }
  }

  &--hero {
    .c-modal__container {
      width: toRem(1600px);
      padding: 0;
      overflow-y: scroll;
      @include min('desktop') {
        overflow-y: hidden;
      }
    }
    #{$root}__close {
      top: 14px;
      right: 15px;
      height: 27px;
      width: 35px;
      z-index: calc(#{z('elements')} + 1);
      position: fixed;

      @include min('desktop') {
        top: 29px;
        right: 25px;
        position: absolute;
      }
    }
    #{$root}__header {
      margin-bottom: 0;
      padding: 12px 8px;
      position: fixed;
      height: 75px;
      width: 100%;
      background-color: $white;
      z-index: z('elements');

      @include min('desktop') {
        padding: 25px 35px;
        position: static;
        height: auto;
      }
    }
    #{$root}__body {
      margin-top: 75px;

      @include min('desktop') {
        margin-top: 0;
        padding: 0 10px;
      }
    }
  }

  &--search {
    .c-modal__container {
      width: 1040px;
    }
  }
  &--applicant {
    .c-modal__container {
      width: 1040px;
      @include max('desktop', -1) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.c-modal__wrapper {
  .o-label,
  .c-search-modal__filter-item .o-bodytext--size-medium {
    @include setStyles('14', $s-typography-sizes);
  }
}
</style>
