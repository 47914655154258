<template lang="pug">
.c-media-upload(ref='media-upload')
  c-mu-section.c-media-upload__section(
    :group='key === "images" || key === "blueprints" ? "media" : key',
    :key='key',
    :list='section',
    :media-type='key',
    :ref='`section-${key}`',
    :state-management='stateManagement',
    :translations='sectionTranslations(key)'
    v-for='(section, key) in media'
  )
  c-mu-section.c-media-upload__section(
    :state-management='stateManagement',
    :translations='sectionTranslations("zip")'
    group='zip'
    key='zip'
    media-type='zip'
    scroll-to='media-upload'
  )
</template>
<script>
import MediaUploadSection from './mu-section'
export default {
  name: 'c-media-upload',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    media: {
      type: Object,
      required: true
    },
    stateManagement: {
      type: Object,
      required: true
    },
    translations: {
      type: Object
    }
  },
  components: {
    'c-mu-section': MediaUploadSection
  },
  methods: {
    sectionTranslations(key) {
      const translations = {
        title: this.translations[key][`${key}Title`],
        description: this.translations[key][`${key}Description`],
        information: this.translations[key][`${key}Information`],
        upload: this.translations[key][`${key}Add`],
        firstHelper: this.translations[key][`${key}FirstHelperText`],
        helper: this.translations[key][`${key}HelperText`],
        validationSize: this.translations.validation.validationMessageSize,
        validationFormat: this.translations.validation.validationMessageFormat,
        validationDimensions: this.translations.validation.validationMessageDimensions
      }

      if (key === 'videos') {
        translations.labelYoutube = this.translations[key][`${key}LinkToYoutube`]
        translations.placeholderYoutube = this.translations[key][`${key}LinkPlaceholderYoutube`]
        translations.labelVimeo = this.translations[key][`${key}LinkToVimeo`]
        translations.placeholderVimeo = this.translations[key][`${key}LinkPlaceholderVimeo`]
      }
      if (key === 'panoramas') {
        translations.label = this.translations[key][`${key}LinkTo`]
        translations.placeholder = this.translations[key][`${key}LinkPlaceholder`]
      }
      return translations
    }
  }
}
</script>
<style lang="scss">
.c-media-upload {
  &__section {
    & + & {
      margin-top: 30px;
    }
  }
}
</style>
