import apiClient from '@/_helpers/api-client'
export const contactManagementService = {
  sendContactForm
}

async function sendContactForm(data) {
  return await apiClient({
    url: '/communications/contact-form/send',
    method: 'POST',
    data
  })
}