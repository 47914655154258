<template lang="pug">
.c-tabs(:class='{ "c-tabs--sub-tabs": sub }')
  .c-tabs__cards.o-grid.o-grid--justify-content-start.o-grid--align-items-start.o-grid--gutter-horizontal-small
    .o-grid__group
      .c-tabs__card.o-grid__item.o-grid__item--grow-0(:class='{ "is-active": tab.isActive }' v-for='(tab, key) in tabs')
        button.c-tabs__card-link.o-link(:disabled='tab.disabled' @click='selectTab(key)')
          span.c-tabs__name.o-bodytext(:class='{ "o-bodytext--size-xsmall": sub, "o-bodytext--size-medium": !sub }') {{ tab.name }}
          span.c-tabs__message.o-bodytext(
            :class='{ "o-bodytext--size-xxxsmall": sub, "o-bodytext--size-xxsmall": !sub }'
            v-if='tab.disabled && tab.disabledMessage'
          ) ({{ tab.disabledMessage }})
  .c-tabs__content
    slot
</template>
<script>
export default {
  name: 'c-tabs',
  data() {
    return {
      id: this._uid,
      tabs: []
    }
  },
  props: {
    startTab: {
      type: Number
    },
    subTabs: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sub() {
      return !!this.subTabs
    }
  },
  methods: {
    selectTab(selectedTabKey) {
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === selectedTabKey
      })
      this.$emit('change', selectedTabKey)
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    if (this.startTab !== undefined) {
      this.selectTab(this.startTab)
    } else {
      const anchor = window.location.hash.substr(1)
      const targetTab = this.tabs.find(tab => tab.name.toLowerCase().replace(/ /g, '-') === anchor)
      if (targetTab) {
        this.selectTab(targetTab.name)
      } else {
        this.selectTab(0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-tabs {
  $root: &;
  &__cards {
    margin-bottom: toRem(30px);
    border-bottom: toRem(1px) solid $grey-4;
  }

  &--sub-tabs {
    padding-left: toRem(10px);
  }

  &__message {
    margin-left: 5px;
  }
  &__card {
    &.is-active {
      #{$root}__card-link {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #000;
        }
      }
    }

    &-link {
      text-decoration: none;
      padding: toRem(5px 10px);
      position: relative;
      display: block;

      // #{$root}--sub-tabs & {
      //   color: red;
      // }
    }
  }
}
</style>
