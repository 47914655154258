<template lang="pug">
.c-ap-applicant
  .c-ap-applicant__top
    button.c-ap-applicant__close(@click='close')
      svg.c-ap-applicant__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-ap-applicant__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-ap-applicant__content.o-transition__content
    h2.c-ap-applicant__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.firstName }} {{ selected.lastName }} ({{ selected.applicantNumber }})
    .c-ap-applicant__info.o-grid.o-grid--align-items-start
      .o-grid__group
        .o-grid__item
          .c-ap-applicant__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Address }}
          .c-ap-applicant__info-data.o-bodytext.o-bodytext--size-small {{ selected.address || '-' }}
        .o-grid__item
          .c-ap-applicant__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.E.Email }}
          .c-ap-applicant__info-data.o-bodytext.o-bodytext--size-small {{ selected.email || '-' }}
        .o-grid__item
          .c-ap-applicant__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.PhoneMobile }}
          .c-ap-applicant__info-data.o-bodytext.o-bodytext--size-small {{ selected.mobilePhone || '-' }}
        .o-grid__item
          .c-ap-applicant__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.P.Phone }}
          .c-ap-applicant__info-data.o-bodytext.o-bodytext--size-small {{ selected.phone || '-' }}
        .o-grid__item
          .c-ap-applicant__info-heading.o-bodytext.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.B.Birthdate }}
          .c-ap-applicant__info-data.o-bodytext.o-bodytext--size-small {{ selected.birthdate | formatDate }}
    c-tabs.c-ap-applicant__tabs(:start-tab='0')
      c-tab(:disabled='false', :name='model.applicanStatusTranslations.statusTabName || Dictionary.S.Status' disabled-message='disable message')
        c-applicant-status(:administrator='administrator', :model='model', :status-for='statusFor') 
      c-tab(
        :disabled='false',
        :name='model.applicanStatusTranslations.historyTabName || Dictionary.H.History'
        disabled-message='disable message'
        v-if='administrator && statusFor'
      )
        c-applicant-history(:model='model')
      c-tab(
        :disabled='false',
        :name='model.applicanStatusTranslations.paymentsTabName || Dictionary.P.Payments'
        disabled-message='disable message'
        v-if='administrator && statusFor'
      )
        c-applicant-payments(:administrator='administrator', :model='model', :status-for='statusFor')
</template>
<script>
import { mapState } from 'vuex'
import ApplicantStatus from './applicant-status'
import ApplicantHistory from './applicant-history'
import ApplicantPayments from './applicant-payments'

export default {
  name: 'c-ap-applicant',
  props: {
    model: {
      type: Object,
      required: true
    },
    residenceTranslations: {
      type: Object
    },
    mediaTranslations: {
      type: Object
    }
  },
  components: {
    'c-applicant-status': ApplicantStatus,
    'c-applicant-history': ApplicantHistory,
    'c-applicant-payments': ApplicantPayments
  },
  data() {
    return {
      id: this._uid,

      formIsValid: false,
      dataChanged: false,
      mutableData: {
        administrator: {
          helperText: this.model.applicanStatusTranslations.internalNotesForHelper,
          value: null,
          placeholderText: this.Dictionary.I.InternalNotesFor,
          isMandatory: false,
          readOnly: false,
          options: [],
          validation: null
        },
        statusFor: {
          helperText: this.model.applicanStatusTranslations.statusForHelper,
          placeholderText: this.Dictionary.S.StatusFor,
          value: null,
          isMandatory: false,
          readOnly: true,
          options: []
        }
      }
    }
  },
  computed: {
    ...mapState('applicantsManagement', ['selected', 'selectedApplicantAdministratorId', 'selectedApplicantMembershipOrganizationId']),
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('user', ['roles']),
    administrator() {
      const administrator = Object.assign({}, this.mutableData.administrator)
      administrator.options = this.administrators.map(administrator => {
        administrator.value = administrator.id
        return administrator
      })
      administrator.value = this.selectedApplicantAdministratorId || null
      return administrator
    },
    selectedAdministrator() {
      return this.administrators.find(administrator => administrator.id === this.selectedApplicantAdministratorId)
    },
    statusFor() {
      const statusFor = Object.assign({}, this.mutableData.statusFor)
      if (!this.selectedAdministrator) {
        statusFor.options = []
        statusFor.value = null
        return statusFor
      }
      statusFor.options = this.selected.membershipOrganizations
        .filter(membershipOrganization => {
          // FILTER MEMBERSHIP ORGANIZATIONS BASED ON COMPANIES that user has applied to
          return this.selectedAdministrator.companies.some(r => membershipOrganization.companies.includes(r))
        })
        .map(mo => {
          mo.value = mo.id
          return mo
        })
      statusFor.value = this.selectedApplicantMembershipOrganizationId
      statusFor.readOnly = statusFor.options.length === 0

      return statusFor
    }
  },
  methods: {
    close() {
      this.$store.dispatch('membershipOrganizations/removeSelected')
      this.$store.dispatch('applicantsManagement/removeSelected')
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  }
}
</script>
<style lang="scss" scoped>
.c-ap-applicant {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__info {
    background-color: $grey-5;
    padding: 15px 30px 20px;
    margin-bottom: 20px;
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__finantial-summary {
    background-color: $grey-5;
    padding: 15px 20px;
    &-table {
      width: 100%;
      .separator {
        padding: 0;
        background-color: $grey-3;
        height: 1px;
      }
      td {
        padding: 5px 0;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          text-align: right;
          width: 40%;
        }
      }
    }
  }

  &__autosuggest {
    position: absolute;
    z-index: z('elements');
    top: 53px;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid $ocean-primary;
    padding: 3px;
    max-height: 300px;
    overflow: auto;

    &-wrap {
      position: relative;
    }

    .theme-dark & {
      border-color: $crab-primary;
    }

    &-item {
      line-height: 30px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $ocean-primary;
        opacity: 0.2;
      }

      &:last-child:after {
        display: none;
      }

      &-name {
        appearance: none;
        width: 100%;
        display: block;
        border: none;
        background-color: transparent;
        padding: 7px 15px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: $crab-primary;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
