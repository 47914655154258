<template lang="pug">
.c-messages-thread.o-grid.o-grid--direction-column.o-grid--justify-content-start.o-grid--gutter-none.o-grid--wrap-nowrap.o-grid--fixed-height(
  :class='{ "c-messages-overlay__thread--multiple": multipleRecipients }'
)
  .o-grid__group
    .o-grid__item.o-grid__item--grow-0
      .c-messages-thread__top
        .o-grid.o-grid--justify-content-start.o-grid--gutter-tiny.o-grid--wrap-nowrap
          .o-grid__group
            button.c-messages-thread__back.c-messages-thread__menu-item.o-grid__item.o-grid__item--grow-0(@click='backClicked' aria-label='Close')
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-left' y='0')
            .o-grid__item.c-messages-thread__heading
              a.o-bodytext.o-bodytext--size-large.o-bodytext--inria(:href='messageEntityLink' target='_blank' v-if='messageEntityLink') {{ activeThread.title }}
              h2.o-bodytext.o-bodytext--size-large.o-bodytext--inria(v-else) {{ activeThread.title }}
            button.c-messages-thread__menu-item.o-grid__item.o-grid__item--grow-0(
              :aria-label='showMessageMenu ? Dictionary.T.ThreadMenuClose : Dictionary.T.ThreadMenuOpen'
              @click='menuClicked'
              v-click-outside='closeMenu'
              v-visible='showActionsMenu'
            )
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-more-full' y='0')
        ul.c-messages-thread__mini-menu(v-if='showMessageMenu')
          li
            button.o-button.o-button--secondary.o-button--small(@click='markAsUnread') {{ Dictionary.M.MarkAsUnread }}
          li
            button.o-button.o-button--secondary.o-button--small(@click='toggleArchivedStatus') {{ activeThread.archived ? Dictionary.U.UnArchive : Dictionary.A.Archive }}
    .o-grid__item.o-grid__item--overflow-auto(v-if='multipleRecipients')
      .c-messages-thread__multi
        .o-grid.o-grid--justify-content-start.o-grid--gutter-tiny
          .o-grid__group
            .o-grid__item.o-grid__item--12 {{ Dictionary.R.Recipients }}:
            .o-grid__item.o-grid__item--6(v-for='user in activeThread.users') • {{ user.name }}
    .o-grid__item.o-grid__item--overflow-auto(v-else)
      ul.c-messages-thread__content(ref='c-messages-thread__content')
        li.c-messages-thread__content-item(
          :class='{ user: message.sender.id === userId }',
          :key='`message-${index}`'
          v-for='(message, index) in activeThread.messages'
        )
          .o-grid.o-grid--justify-content-start.o-grid--gutter-tiny.o-grid--wrap-nowrap
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                .c-messages-thread__logo(:style='`background-image: url(/api/users/${message.sender.id}/avatar)`')
              .o-grid__item.o-grid__item--11
                .c-messages-thread__from.o-bodytext.o-bodytext--size-medium.o-bodytext--600 {{ message.sender.name }}
                .c-messages-thread__date.o-bodytext.o-bodytext--size-xsmall {{ reFormatDate(message.created) }}
          div
            .c-messages-thread__message.o-bodytext.o-bodytext--size-small.o-richtext(v-html='message.body')
            .c-messages-thread__file-list.c-messages-thread__file-list--uploaded
              a.c-messages-thread__file(
                :download='file.fileName',
                :href='`/api/communications/messages/attachments/${file.id}`',
                :key='`uploaded-file-${index}`'
                target='_blank'
                v-for='(file, index) in message.attachments'
              )
                svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
                  use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${fileIcon(file)}`' x='0' y='0')
                .c-messages-thread__file-info
                  .c-messages-thread__file-name.o-bodytext.o-bodytext--size-small {{ file.fileName }}
          div
    .o-grid__item.o-grid__item--grow-0(v-if='!isSystemThread')
      .c-messages-thread__bottom
        ul.c-messages-thread__file-list.c-messages-thread__file-list--upload
          li.c-messages-thread__file(
            :class='{ "c-messages-thread__file--invalid": !fileIsValid(file) }',
            :key='`file-${index}`'
            v-for='(file, index) in selectedFiles'
          )
            template(v-if='fileIsValid(file)')
              svg.o-svg-icon.type(type='image/svg+xml' viewbox='0 0 64 64')
                use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${fileIcon(file)}`' x='0' y='0')
              .c-messages-thread__file-info
                .c-messages-thread__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
                .c-messages-thread__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'KB') }})
              svg.o-svg-icon.close(@click='fileRemoveClicked(index)' type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
            template(v-else)
              svg.o-svg-icon.error(type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
              .c-messages-thread__file-info
                .c-messages-thread__file-name.o-bodytext.o-bodytext--size-small {{ file.name }}
                .c-messages-thread__file-size.o-bodytext.o-bodytext--size-xsmall ({{ file.size | prettyBytes(1, false, 'MB') }})
                .c-messages-thread__file-error.o-bodytext.o-bodytext--size-xsmall {{ file.size > maxFileSize ? Dictionary.M.Max + ' ' + maxFileSizeClean : Dictionary.W.WrongFormat }}
              svg.o-svg-icon.close(@click='fileRemoveClicked(index)' type='image/svg+xml' viewbox='0 0 64 64')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
        .o-grid.o-grid--justify-content-start.o-grid--align-items-center.o-grid--gutter-none
          .o-grid__group
            .o-grid__item.o-grid__item--9.o-grid__item--desktop-12
              .c-messages-thread__input
                textarea-autosize.o-input.o-input--placeholder-simple(
                  :disabled='inputDisabled',
                  :id='`${userId}-input`',
                  :max-height='108',
                  :placeholder='Dictionary.W.WriteHere'
                  name='textareaInput'
                  rows='1'
                  v-model='inputMessage'
                )
                input.u-visually-hidden(
                  :accept='acceptableFormats.toString()',
                  :id='`${userId}-fileUpload`'
                  @change='filesChange'
                  name='fileInput'
                  placeholder=' '
                  type='file'
                )
                label.c-messages-thread__upload.o-label(:for='`${userId}-fileUpload`' v-if='!disableUpload && !inputDisabled')
                  svg.o-svg-icon.attach(type='image/svg+xml' viewbox='0 0 64 64')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paperclip' y='0')
            .o-grid__item.o-grid__item--grow-0
              .c-messages-thread__submit
                button.o-button(:disabled='inputDisabled || disableSubmit' @click.prevent='submitMessageToThread')
                  svg.o-svg-icon.send(type='image/svg+xml' viewbox='0 0 64 64')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-send' y='0')
            .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right(v-if='resposeDeadline')
              span {{ Dictionary.R.ResponseDeadline }} : {{ resposeDeadline | formatDate }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'c-messages-thread',
  data() {
    return {
      inputDisabled: false,
      inputMessage: '',
      inputFileCount: 0,
      selectedFiles: [],
      maxFileSize: 5242880,
      acceptableFormats: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
      showMessageMenu: false
    }
  },
  props: {
    showActionsMenu: {
      type: Boolean,
      default: true
    },
    model: Object
  },
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    ...mapState('user', ['userId', 'email']),
    ...mapGetters('user', ['hasElevatedRights']),
    ...mapState('messages', ['activeThread', 'filters']),
    ...mapState('administratorsManagement', ['administrators']),
    ...mapState('advertManagement', { selectedAdvert: 'selected' }),
    ...mapState('offersManagement', { selectedOffer: 'selected' }),
    ...mapGetters('messages', ['all']),
    relatedEntityId() {
      return this.activeThread && this.activeThread.relatedEntity && this.activeThread.relatedEntity.id
    },
    multipleRecipients() {
      return Array.isArray(this.activeThread.users) && this.activeThread.users.length > 1
    },
    disableUpload() {
      return this.selectedFiles.length >= 5
    },
    disableSubmit() {
      return this.selectedFiles.length === 0 && this.inputMessage.length === 0
    },
    disableInputHelpers() {
      return this.inputMessage.length > 65
    },
    isSystemThread() {
      const isSystemMessage = this.activeThread.messages.find(m => m.sender && m.sender.id === '11111111-1111-1111-1111-111111111111'),
        noEntity = !this.activeThread.relatedEntity
      return !!isSystemMessage && noEntity
    },
    maxFileSizeClean() {
      return this.$options.filters.prettyBytes(this.maxFileSize)
    },
    messageEntityLink() {
      let link

      if (!this.activeThread.relatedEntity) return null
      if (this.selectedAdvert && (this.activeThread.relatedEntity.type === 'ResidenceAdvert' || this.activeThread.relatedEntity.type === 'ResidenceAdvert')) {
        link =
          this.hasElevatedRights || this.selectedAdvert.userId === this.userId
            ? `/management/adverts?advertId=${this.selectedAdvert.id}`
            : `/residence/${this.selectedAdvert.residenceId}`
      } else if (this.selectedOffer && this.activeThread.relatedEntity.type === 'Offer') {
        link = this.hasElevatedRights ? `/administration/offers?offerId=${this.selectedOffer.id}` : `/profile/my-offers?offerId=${this.selectedOffer.id}`
      }

      return link
    },
    resposeDeadline() {
      return this.selectedOffer && this.selectedOffer.deadline
    },
    adminSignature() {
      let adminSignature

      if (this.Dictionary.A.AdmininstratorSignature && this.administrators.length) {
        adminSignature = this.Dictionary.A.AdmininstratorSignature.replace('{Name}', this.administrators[0].name)
          .replace('{Phone}', this.administrators[0].phone)
          .replaceAll('\n', '')
      }

      return adminSignature
    }
  },
  methods: {
    reFormatDate(d) {
      return moment.utc(d).local().format('DD.MM.YYYY, HH:mm')
    },
    isSystemMessage(message) {
      const isSystemMessage = message.sender.id === '11111111-1111-1111-1111-111111111111'
      return isSystemMessage
    },
    getUserName(id) {
      return id + 'some user'
    },
    toggleArchivedStatus() {
      const action = this.activeThread.archived ? 'unarchiveMessage' : 'archiveMessage'
      this.$store.dispatch(`messages/${action}`, { id: this.activeThread.id })
    },
    markAsUnread() {
      this.$store.dispatch('messages/markMessageAsUnread', {
        id: this.activeThread.id,
        userId: this.userId,
        propertyAdministratorId: this.administrators.length ? this.administrators[0].id : null
      })
    },
    menuClicked() {
      this.showMessageMenu = !this.showMessageMenu
    },
    closeMenu() {
      this.showMessageMenu = false
    },
    backClicked() {
      this.$store.dispatch('messages/clearActiveThread')
    },
    filesChange($event) {
      this.inputFileCount = $event.target.files.length
      const fileList = $event.target.files
      if (!fileList.length) return
      this.selectedFiles.push(fileList[0])
    },
    fileRemoveClicked(index) {
      this.selectedFiles.splice(index, 1)
    },
    fileIcon(file) {
      let icon = ''
      switch (file.type) {
        case 'image/jpeg':
          icon = 'paper'
          break
        case 'image/jpg':
          icon = 'paper'
          break
        case 'image/png':
          icon = 'paper'
          break
        case 'application/pdf':
          icon = 'pdf'
          break
        default:
          icon = 'paper'
      }
      return icon
    },
    submitMessageToThread() {
      const formData = new FormData()
      const message = this.adminSignature ? this.inputMessage + '<br /><br />' + this.adminSignature : this.inputMessage

      formData.append('message', message)
      this.selectedFiles.forEach(file => {
        if (this.fileIsValid(file)) formData.append('files', file)
      })

      if (this.activeThread.id) {
        this.$store.dispatch('messages/sendMessageToThread', { id: this.activeThread.id, formData })
      } else if (this.activeThread.relatedEntity.id && this.activeThread.users.length > 0) {
        if (this.multipleRecipients) {
          this.$store.dispatch('messages/sendMessagesToMultipleUsers', formData)
        } else {
          this.$store.dispatch('messages/sendMessageToThread', {
            id: this.activeThread.relatedEntity.id,
            userId: this.activeThread.users[0].id,
            formData
          })
        }
      } else {
        this.$store.dispatch('messages/postSendToPropertyOwner', { id: this.activeThread.relatedEntity.id, formData })
      }

      this.selectedFiles = []
      this.inputMessage = ''
    },
    scrollContentStart() {
      if (!this.multipleRecipients) {
        const contentContainer = this.$refs['c-messages-thread__content']
        const lastMessage = contentContainer && contentContainer.childNodes[0]
        if (lastMessage) contentContainer.scrollTo(0, lastMessage.offsetTop)
      }
    },
    fileIsValid(file) {
      const typeIsValid = this.acceptableFormats.includes(file.type) && file.size <= this.maxFileSize
      return typeIsValid
    },
    enrichData() {
      const relatedEntity = this.activeThread && this.activeThread.relatedEntity,
        relatedEntityId = relatedEntity && relatedEntity.id,
        relatedEntityType = relatedEntityId && relatedEntity.type,
        enrichmentExists =
          (this.selectedAdvert && this.selectedAdvert.id === relatedEntityId) || (this.selectedOffer && this.selectedOffer.id === relatedEntityId)

      if (relatedEntityType && !enrichmentExists) {
        if (relatedEntityType === 'ResidenceAdvert' || relatedEntityType === 'ResidenceAdvert') {
          this.$store.dispatch('advertManagement/setSelected', relatedEntityId)
        } else if (relatedEntityType === 'Offer') {
          this.$store.dispatch('offersManagement/setSelected', relatedEntityId)
        }
      }
    }
  },
  mounted() {
    this.scrollContentStart()

    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  created() {
    if (this.relatedEntityId) this.enrichData()
    if (this.hasElevatedRights && this.administrators.length === 0) {
      this.$store.dispatch('administratorsManagement/getPropertyAdministrators')
    }
  },
  destroyed() {
    if (this.isMobile && !this.filters.relatedEntities) document.body.classList.remove('u-overflow--hidden')
  },
  watch: {
    relatedEntityId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.enrichData()
    },
    activeThread: {
      deep: true,
      handler(newVal) {
        this.$nextTick(() => {
          this.scrollContentStart()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-messages-thread {
  $root: &;

  $sidePadding: 25px;
  height: 100%;
  overflow: hidden;

  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 20px $sidePadding;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      border: 1px solid transparent;
      border-bottom-color: $grey-4;
      min-height: 71px;
    }

    svg {
      width: 25px;
      height: 20px;
      fill: $white;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  &__multi {
    padding: 20px $sidePadding;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      border-bottom-color: $grey-4;
    }
  }

  &__heading {
    position: relative;

    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 5px);
      left: 5px;
    }
  }

  &__menu-item {
    background-color: transparent;
    border: none;

    &:focus {
      outline-color: $white;
    }
  }

  &__mini-menu {
    display: block;
    position: absolute;
    top: 100%;
    right: -1px;
    text-align: right;
    z-index: z('elements');

    button {
      min-width: 100%;
      padding: 12px 20px;
      outline-offset: -2px;
    }
  }

  &__back {
    position: relative;
    svg {
      width: 28px;
      height: 24px;
      fill: $white;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }

    @include min('desktop') {
      display: none;
    }
  }

  &__content {
    padding: 0 $sidePadding;
    min-height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    position: relative;
    height: 100%;
    overflow-y: auto;

    &-item {
      padding: 20px 20% 10px 0;
      text-align: left;
      &.user {
        padding-left: 20%;
        padding-right: 0;
        text-align: right;

        .o-grid__group {
          flex-direction: row-reverse;
        }

        #{$root}__message {
          text-align: right;
          padding: 0;
        }
      }

      & + & {
        border-bottom: 1px solid $grey-4;
      }
    }
  }

  &__logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &__from {
  }
  &__date {
    color: $grey-2;
  }
  &__message {
    text-align: left;
    padding-left: 42px;
  }

  &__bottom {
    position: relative;
    @include min('desktop') {
      border-top: 1px solid $grey-4;
      margin: 0 $sidePadding;
      padding: $sidePadding 0;
    }
  }

  &__input {
    position: relative;

    textarea {
      padding: 15px 40px 15px 15px;
      @include min('desktop') {
        min-height: 108px;
        padding-right: 20px;
      }
    }
  }
  &__upload {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    @include min('desktop') {
      transform: translateY(0);
      bottom: 0;
      right: 20px;
      top: auto;
    }

    .attach {
      width: 25px;
      height: 25px;
    }
  }
  &__submit {
    width: 100%;
    text-align: left;

    @include min('desktop') {
      margin-top: 10px;
    }
    .send {
      fill: $crab-darker;
      width: 35px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      stroke: $crab-darker;
      stroke-width: 1px;
    }

    button {
      min-width: 100%;
      position: relative;
      min-height: 55px;
      &:disabled {
        .send {
          fill: $white;
          stroke: $white;
        }
      }

      @include min('desktop') {
        min-width: 90px;
      }
    }
  }
  &__file-list {
    &--upload {
      // position: absolute;
      // bottom: 100%;
      // width: 100%;
      // @include min('desktop') {
      //   bottom: calc(100% - #{$sidePadding});
      // }
    }
    &--uploaded {
      margin-top: 5px;
    }
  }
  &__file {
    background-color: $grey-5;
    padding: 15px 25px;
    border-top: 1px solid $grey-4;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid $grey-4;
    }

    &--invalid {
      background-color: $coral-5;
      border: 1px solid $error-red;
      &:last-child {
        border: 1px solid $error-red;
      }
    }

    #{$root}__file-list--uploaded & {
      display: inline-flex;
      border: none;
      padding: 10px 15px;
      margin: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    &-info {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      margin: 0 5px;
      max-width: calc(100% - 45px);

      #{$root}__file-list--uploaded & {
        margin: 0 0 0 5px;
        max-width: calc(100% - 25px);
      }
    }
    &-name {
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(80% - 20px);

      #{$root}__file-list--uploaded & {
        max-width: unset;
      }
    }
    &-size {
      margin-left: 5px;
      flex: 0 0 20%;
    }

    &-error {
      flex: 1 0 100%;
      color: $error-red;
    }

    svg {
      vertical-align: middle;
      flex: 0 0 25px;
      &.type {
        width: 25px;
        height: 25px;
      }
      &.close {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      &.error {
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
        fill: $error-red;
        align-self: flex-start;
      }
    }
  }
}
</style>
