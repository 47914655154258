import apiClient from '@/_helpers/api-client'
export const ub4PropertyManagementService = {
  getUb4Ownerships,
  postUb4Ownership,
  deleteUb4Ownership
}

async function getUb4Ownerships() {
  return await apiClient({
    url: '/data/ub/ownerships',
    method: 'GET'
  })
}

async function postUb4Ownership(data) {
  return await apiClient({
    url: '/data/ub/ownerships',
    method: 'POST',
    data
  })
}

async function deleteUb4Ownership({ companyNo, propertyNo, companyId }) {
  return await apiClient({
    url: `/data/ub/ownerships/${companyNo}/${propertyNo}/${companyId}`,
    method: 'DELETE'
  })
}
