/* eslint-disable prettier/prettier */
<template lang="pug">
.c-rm-overview
  .c-rm-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-rm-overview__button(:title='Dictionary.I.ImportResidence' @click='showResidenceImport = true') {{ Dictionary.I.ImportResidence }}
    button.o-button.c-rm-overview__button(:title='Dictionary.I.ImportWarnings' @click='showWarningsImport = true') {{ Dictionary.I.ImportWarnings }}
    button.o-button.c-rm-overview__button(:title='Dictionary.N.NewResidence' @click='createNew') {{ model.addResidenceText }}
  keep-alive
    c-rm-search(:model='model')
  .c-rm-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-rm-overview__data
    vue-good-table(
      :columns='columnsFiltered',
      :pagination-options='paginationSettingsData',
      :rows='residences',
      :totalRows='totalResults'
      @on-column-filter='onColumnFilter'
      @on-page-change='onPageChange'
      @on-per-page-change='onPerPageChange'
      @on-sort-change='onSortChange'
      mode='remote'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "status"')
          .o-bodytext.o-bodytext--size-small {{ getResidenceStatus(props.row) }}
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny.o-grid--justify-content-start
            .o-grid__group
              .o-grid__item.o-grid__item--12(v-if='offerOrAdvert(props.row).offerCreated')
                a.c-state-action.c-rm-overview__action(:href='`${model.offerManagementLink}?offerId=${props.row.activeOfferId}`')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-pen' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.E.EditOffer }}
              .o-grid__item.o-grid__item--12(v-if='offerOrAdvert(props.row).canCreateOffer')
                button.c-state-action.c-rm-overview__action(@click='handleCreateNew(props.row, "offer")')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-list' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.C.CreateOffer }}
              .o-grid__item.o-grid__item--12(v-if='offerOrAdvert(props.row).advertCreated')
                a.c-state-action.c-rm-overview__action(:href='`${model.advertManagementLink}?advertId=${props.row.residenceAdvertId}`')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-pen' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.E.EditAdvert }}
              .o-grid__item.o-grid__item--12(v-if='offerOrAdvert(props.row).canCreateAdvert')
                button.c-state-action.c-rm-overview__action(@click='handleCreateNew(props.row, "advert")')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-list' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.C.CreateAdvert }}
              .o-grid__item.o-grid__item--12(v-if='props.row.managedExternally')
                c-cta.o-button--small(
                  :cta-disabled='syncInProgress',
                  :cta-loading='syncInProgress',
                  :cta-text='Dictionary.S.Synchronize'
                  @click='syncUb4(props.row.companyPropertyResidenceNumbersId)'
                )
              .o-grid__item.o-grid__item--12(v-else)
                button.c-state-action.c-rm-overview__action(@click='handleDelete(props.row.id)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Delete }}

        template(v-else-if='props.column.field == "street"')
          button.c-rm-overview__item--name.o-bodytext.o-bodytext--size-small(
            :style='{ color: developerMode && props.row.managedExternally ? "red" : undefined }'
            @click='setSelected(props.row.id, props.row.propertyCompanyId)'
          ) {{ combineAddress(props.row.street, props.row.number, props.row.letter, props.row.floor, props.row.door, props.row.postalCode, props.row.city) }}{{ developerMode && props.row.managedExternally ? ' (UB4)' : '' }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
  c-import-data(
    :select-model='{ options: companyOptions, helper: model.importResidences.propertyImportTranslations.importSelectHelper }',
    :show='showResidenceImport',
    :translations='{ startHeadline: model.importResidences.propertyImportTranslations.importStartHeadline, startDescription: model.importResidences.propertyImportTranslations.importStartDescription, loadingHeadline: model.importResidences.propertyImportTranslations.importLoadingHeadline, loadingDescription: model.importResidences.propertyImportTranslations.importLoadingDescription, doneHeadline: model.importResidences.propertyImportTranslations.importDoneHeadline, doneDescription: model.importResidences.propertyImportTranslations.importDoneDescription, doneImported: model.importResidences.propertyImportTranslations.importDoneImportedText, doneFailed: model.importResidences.propertyImportTranslations.importDoneFailedText }',
    :upload-model='{ validationSize: model.importResidences.propertyImportTranslations.importUploadValidationSize, validationFormat: model.importResidences.propertyImportTranslations.importUploadValidationFormat }'
    @close='showResidenceImport = false'
    import-type='residence'
  )
  c-import-data(
    :select-model='{ options: companyOptions, helper: model.importWarnings.propertyImportTranslations.importSelectHelper }',
    :show='showWarningsImport',
    :translations='{ startHeadline: model.importWarnings.propertyImportTranslations.importStartHeadline, startDescription: model.importWarnings.propertyImportTranslations.importStartDescription, loadingHeadline: model.importWarnings.propertyImportTranslations.importLoadingHeadline, loadingDescription: model.importWarnings.propertyImportTranslations.importLoadingDescription, doneHeadline: model.importWarnings.propertyImportTranslations.importDoneHeadline, doneDescription: model.importWarnings.propertyImportTranslations.importDoneDescription, doneImported: model.importWarnings.propertyImportTranslations.importDoneImportedText, doneFailed: model.importWarnings.propertyImportTranslations.importDoneFailedText }',
    :upload-model='{ validationSize: model.importWarnings.propertyImportTranslations.importUploadValidationSize, validationFormat: model.importWarnings.propertyImportTranslations.importUploadValidationFormat }'
    @close='showWarningsImport = false'
    import-type='warning'
  )
  c-retire-awarded-rentedout(:data='createAdvertOrOfferConfirmation' @close='createAdvertOrOfferConfirmation = false' v-if='createAdvertOrOfferConfirmation')
</template>
<script>
import { mapState } from 'vuex'

import ResidenceManagementSearch from '../rm-search'
import RetireAwardedRentedout from '../../retire-awarded-rentedout'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
export default {
  name: 'c-rm-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      showResidenceImport: false,
      showWarningsImport: false,
      createAdvertOrOfferConfirmation: false,
      columns: [
        {
          label: this.Dictionary.N.NumberShort,
          field: 'companyPropertyResidenceNumbersId'
        },
        {
          label: this.Dictionary.A.Address,
          field: 'street'
        },
        {
          label: this.Dictionary.R.Rooms,
          field: 'rooms',
          type: 'number'
        },
        {
          label: this.Dictionary.A.Area,
          field: 'area',
          type: 'number'
        },
        {
          label: this.Dictionary.A.AvailableFrom,
          field: 'availableFrom',
          type: 'number',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.S.Status,
          field: 'status',
          sortable: false
        },
        {
          label: '',
          field: 'id',
          sortable: false
          // width: '195px'
        }
      ]
    }
  },
  components: {
    'c-rm-search': ResidenceManagementSearch,
    'c-retire-awarded-rentedout': RetireAwardedRentedout,
    VueGoodTable
  },
  computed: {
    ...mapState(['developerMode']),
    ...mapState('residenceManagement', {
      residenceTypes: 'types',
      residences: 'residences',
      totalResults: 'totalResults',
      serverParams: 'serverParams'
    }),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('user', ['roles']),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    columnsFiltered() {
      return this.columns.filter(c => {
        if (this.roles.includes('System Administrator') && c.field === 'id') {
          return false
        } else {
          return c
        }
      })
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    companyOptions() {
      return JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
    },
    syncInProgress() {
      return this.loading && this.loadingEndpoints.includes('/data/ub/sync/residences')
    }
  },
  methods: {
    syncUb4(id) {
      this.$store.dispatch('residenceManagement/syncUb4', id)
    },
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    getResidenceStatus(row) {
      if (row.activeOfferId && row.offerState) {
        return `${this.Dictionary.O.Offer} ${this.Dictionary.OfferStates[row.offerState] || row.offerState}`
      } else if (
        row.residenceAdvertId &&
        row.residenceAdvertStatus &&
        ['Retired', 'RentedOut', 'RetiredFromRentedOut'].indexOf(row.residenceAdvertStatus) === -1
      ) {
        return `${this.Dictionary.A.Advert} ${this.Dictionary.ResidenceAdvertStatus[row.residenceAdvertStatus] || row.residenceAdvertStatus}`
      } else if (row.availableFrom) {
        return this.Dictionary.A.Available
      } else if (row.residenceAdvertId && row.residenceAdvertStatus) {
        return `${this.Dictionary.A.Advert} ${this.Dictionary.ResidenceAdvertStatus[row.residenceAdvertStatus] || row.residenceAdvertStatus}`
      } else {
        return ''
      }
    },
    setSelected(id, companyId) {
      this.getInitialData()
      this.$store.dispatch('residenceManagement/setSelected', { id, companyId })
    },
    createNew() {
      this.getInitialData()
      this.$store.dispatch('residenceManagement/createNew')
    },
    handleAddResidence(id) {
      this.$store.dispatch('residenceManagement/addResidence', id)
    },
    handleDelete(id) {
      this.$store.dispatch('residenceManagement/toggleDeleteConfirmation', id)
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    getInitialData() {
      if (this.companies.length === 0) {
        this.$store.dispatch('search/getCompanies')
      }
      if (this.propertyTypes.length === 0) {
        this.$store.dispatch('propertyManagement/getTypes')
      }
      if (this.residenceTypes.length === 0) {
        this.$store.dispatch('residenceManagement/getTypes')
      }
      if (this.facilities.length === 0) {
        this.$store.dispatch('search/getFacilities')
      }
    },
    offerOrAdvert(residence) {
      const residenceIsAvailiable = residence.availableFrom && (!residence.availableTo || moment.utc(residence.availableTo).isAfter(moment.utc())),
        residenceManagedExternalyAdvertAvailable = residence.freeStatusNotTiedToOfferOrAdvert,
        canCreateAdvert =
          residence.rentModel !== 'WaitingList' &&
          residenceIsAvailiable &&
          (!residence.managedExternally || residenceManagedExternalyAdvertAvailable) &&
          (!residence.residenceAdvertId || ['Retired', 'RentedOut', 'RetiredFromRentedOut'].indexOf(residence.residenceAdvertStatus) !== -1)

      const canCreateOffer =
        residenceIsAvailiable &&
        residence.rentModel === 'WaitingList' &&
        (!residence.activeOfferId || ['Awarded', 'AwardedExternally', 'RetiredFromAwarded'].indexOf(residence.offerState) !== -1) &&
        (!residence.residenceAdvertId || ['Retired', 'RentedOut', 'RetiredFromRentedOut'].indexOf(residence.residenceAdvertStatus) !== -1)

      const result = {
        canCreateAdvert,
        advertCreated: !!residence.residenceAdvertId,
        canCreateOffer: residence.managedExternally ? residenceManagedExternalyAdvertAvailable && canCreateOffer : canCreateOffer,
        offerCreated: !!residence.activeOfferId
      }

      return result
    },
    handleCreateNew(residence, type) {
      const returnUrl =
        type === 'advert'
          ? `${window.location.origin}${this.model.advertManagementLink}?advertFromResidenceId=${residence.id}`
          : `${window.location.origin}${this.model.offerManagementLink}?offerFromResidenceId=${residence.id}`

      this.createAdvertOrOfferConfirmation = {
        returnUrl,
        residenceId: residence.id
      }
    },

    onPageChange(params) {
      this.$store.commit('residenceManagement/updateParams', { page: params.currentPage })
    },
    onPerPageChange(params) {
      this.$store.commit('residenceManagement/updateParams', { perPage: params.currentPerPage, page: 1 })
    },
    onSortChange(params) {
      this.$store.commit('residenceManagement/updateParams', {
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
    },
    onColumnFilter(params) {
      this.$store.commit('residenceManagement/updateParams', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-rm-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__action {
    margin: 0;
    display: inline-flex;
    color: inherit;
  }
}
</style>
