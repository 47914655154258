<template lang="pug">
.profile-information
  .profile-information__image.profile-information__image--mobile
    .profile-information__image-container.profile-information__image-container(v-bind:style='{ "background-image": `url(${image})` }')
      input#fileInputMobile.profile-information__image-input.o-input.u-visually-hidden(
        @change='imageUpload'
        accept='image/png, image/jpeg, capture=camera'
        name='fileInput'
        placeholder=' '
        type='file'
      )
      label.profile-information__image-input-label(:aria-label='Dictionary.U.UploadPicture' for='fileInput')
        svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-camera' y='0')
    .profile-information__image-error.o-bodytext.o-bodytext--size-xsmall.o-bodytext--700(v-if='!validFileSize || !validFileFormat') {{ !validFileSize ? Dictionary.M.Max + ' ' + maxFileSizeClean : Dictionary.W.WrongFormat }}
  .profile-information__heading.o-heading.o-heading--size-2.o-heading--profile-page.o-heading--profile-page-center
    h2(v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.formTitle }}
  .o-grid.o-grid--gutter-large.o-grid--wrap-nowrap
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .profile-information__applicant-number
          label(v-if='data.userProfile && data.userProfile.applicantNumber') {{ Dictionary.A.ApplicantNumber + ':' }} {{ data.userProfile.applicantNumber }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#firstName.o-input(autocomplete='do-not-autofill' name='firstName' placeholder=' ' type='text' v-model='mutableState.firstName')
            label.o-label.o-label--placeholder(for='firstName') {{ Dictionary.F.FirstName }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#lastName.o-input(autocomplete='do-not-autofill' name='lastName' placeholder=' ' type='text' v-model='mutableState.lastName')
            label.o-label.o-label--placeholder(for='lastName') {{ Dictionary.L.LastName }}
        c-fc-sensitive.o-input__wrap--margin-top-20(
          :model='socialSecurityNumber'
          @change='checkAge()'
          @validate='validate($event, "socialSecurityNumber")'
          v-model='socialSecurityNumber.value'
        )
        .profile-information__gaurdian-container(v-show='isUserMinor')
          .profile-information__guardian
            svg.o-svg-icon.profile-information__info-icon(type='image/svg+xml' viewbox='0 0 28 28')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-info' y='0')
            .profile-information__guardian-headline(v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.legalGuardianTitle }}
            .profile-information__guardian-text(v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.legalGuardianDescription }}
            .o-input__container
              .o-input__wrap.o-input__wrap--margin-top-20
                input#guardian-name.o-input(
                  @keyup='validateGuardianName'
                  autocomplete='do-not-autofill'
                  name='guardian-name'
                  placeholder=' '
                  ref='guardianName'
                  type='text'
                  v-if='mutableState.guardian'
                  v-model='mutableState.guardian.name'
                )
                label.o-label.o-label--placeholder(for='guardian-name') {{ Dictionary.N.Names }}
            .o-input__container
              .o-input__wrap.o-input__wrap--margin-top-20
                input#guardian-email.o-input(
                  @keyup='validateGuardianEmail'
                  autocomplete='do-not-autofill'
                  name='guardian-email'
                  placeholder=' '
                  ref='guardianEmail'
                  type='email'
                  v-if='mutableState.guardian'
                  v-model='mutableState.guardian.email'
                )
                label.o-label.o-label--placeholder(for='guardian-email') {{ Dictionary.E.Email }}
            .o-input__container
              .o-input__wrap.o-input__wrap--margin-top-20
                input#guardian-phone.o-input(
                  @keyup='validateGuardianPhone'
                  autocomplete='do-not-autofill'
                  name='guardian-phone'
                  placeholder=' '
                  ref='guardianPhone'
                  type='text'
                  v-if='mutableState.guardian'
                  v-mask='\'############\''
                  v-model='mutableState.guardian.phoneNumber'
                )
                label.o-label.o-label--placeholder(for='guardian-phone') {{ Dictionary.P.Phone }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#company.o-input(autocomplete='do-not-autofill' name='company' placeholder=' ' type='text' v-model='mutableState.company')
            label.o-label.o-label--placeholder(for='company') {{ Dictionary.C.CompanyName }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#cvr.o-input(
              @keyup='validateCVRNumber'
              autocomplete='do-not-autofill'
              max='99999999'
              min='10000000'
              name='cvr'
              placeholder=' '
              ref='cvr'
              type='number'
              v-model='mutableState.cvr'
            )
            label.o-label.o-label--placeholder(for='cvr') {{ Dictionary.C.Cvr }}
          label.o-label.o-label--helper(for='cvr' v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.cvrDescription }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            select#selectInput.o-input(autocomplete='do-not-autofill' placeholder=' ' type='text' v-model='mutableState.countryId')
              option(:key='country.id', :value='country.id' v-for='country in data.countries') {{ country.name }}
            label.o-label.o-label--placeholder(for='selectInput') {{ Dictionary.C.Country }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#adress.o-input(autocomplete='do-not-autofill' name='adress' placeholder=' ' type='text' v-model='mutableState.address')
            label.o-label.o-label--placeholder(for='adress') {{ Dictionary.A.Address }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#zip.o-input(
              @keyup='validateZip($event)'
              autocomplete='do-not-autofill'
              name='zip'
              placeholder=' '
              type='text'
              v-mask='\'####\''
              v-model='mutableState.zipCode'
            )
            label.o-label.o-label--placeholder(for='zip') {{ Dictionary.P.PostalCode }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#city.o-input(autocomplete='do-not-autofill' name='city' placeholder=' ' type='text' v-model='mutableState.city')
            label.o-label.o-label--placeholder(for='city') {{ Dictionary.C.City }}
        c-fc-email.o-input__wrap--margin-top-20(:current='profileData.email', :model='email' @validate='validate($event, "email")' v-model='email.value') 
        .o-grid.o-grid--gutter-tiny
          .o-grid__group
            .o-grid__item.o-grid__item--5
              .o-input__container
                .o-input__wrap.o-input__wrap--margin-top-20
                  select#countryCode.o-input.profile-information__country-code(
                    autocomplete='do-not-autofill'
                    name='countryCode'
                    placeholder=' '
                    type='text'
                    v-model='mutableState.phoneCode'
                  )
                    option(:key='country.name', :value='country.phoneCode' v-for='country in data.countries') {{ country.name }} {{ country.phoneCode }}
                  label.o-label.o-label--placeholder(for='countryCode') {{ Dictionary.P.PhoneCode }}
            .o-grid__item.o-grid__item--7
              .o-input__container
                .o-input__wrap.o-input__wrap--margin-top-20
                  input#phone.o-input(
                    @keyup='validatePhone($event)'
                    autocomplete='do-not-autofill'
                    name='phone'
                    placeholder=' '
                    type='text'
                    v-mask='\'############\''
                    v-model='mutableState.phoneNoForSms'
                  )
                  label.o-label.o-label--placeholder(for='phone') {{ Dictionary.P.PhoneMobile }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input#landline.o-input(
              @keyup='validatePhone2($event)'
              autocomplete='do-not-autofill'
              name='landline'
              placeholder=' '
              type='text'
              v-mask='\'############\''
              v-model='mutableState.landlinePhone'
            )
            label.o-label.o-label--placeholder(for='landline') {{ Dictionary.P.PhoneSecondary }}
        h3.profile-information__heading-3(v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.changePasswordTitle }}
        .o-input__container
          .o-input__wrap.o-input__wrap--margin-top-20
            input.profile-information__autofillFix(tabindex='-1' type='text')
            input#secret.o-input(
              @focus='showPasswordStrengthMeter = false'
              autocomplete='new-password'
              name='secret'
              placeholder=' '
              type='password'
              v-model='mutableState.newPassword'
              v-on:keyup='setPasswordStrength'
            )
            label.o-label.o-label--placeholder(for='secret') {{ Dictionary.N.NewPassword }}
          .password-strength(v-show='showPasswordStrengthMeter')
            .password-strength-inner
              ul.password-strength-meter(:class='passwordLvl')
                li
                li
                li
                li
              span {{ passwordStrength }}
          label.o-label.o-label--helper(for='secret' v-if='data.sitecoreContentModel') {{ data.sitecoreContentModel.changePasswordDescription }}
      .o-grid__item.o-grid__item--grow-0.profile-information__image
        .profile-information__image-container(v-bind:style='{ "background-image": `url(${image})` }')
          input#fileInput.profile-information__image-input.o-input.u-visually-hidden(
            @change='imageUpload'
            accept='image/png, image/jpeg, capture=camera'
            name='fileInput'
            placeholder=' '
            type='file'
          )
          label.profile-information__image-input-label(:aria-label='Dictionary.U.UploadPicture' for='fileInput')
            svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-camera' y='0')
        .profile-information__image-error.o-bodytext.o-bodytext--size-xsmall.o-bodytext--700(v-if='!validFileSize || !validFileFormat') {{ !validFileSize ? Dictionary.M.Max + ' ' + maxFileSizeClean : Dictionary.W.WrongFormat }}
  .o-grid.o-grid--gutter-large
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .profile-information__delete-profile-container(v-if='canDelete')
          button.profile-information__delete-profile-button.o-link(@click='showDelete = true') {{ Dictionary.D.DeleteProfile }}
        .profile-information__save-actions
          button.profile-information__clear.o-link(@click='resetData') {{ Dictionary.R.Regret }}
          button.o-button(:disabled='!this.formIsValid || !dataChanged' @click='validateData') {{ Dictionary.S.Save }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showDelete = false' v-if='showDelete')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ data.sitecoreContentModel.deleteProfileConfirmationHeadline }}
    template(slot='body')
      .o-bodytext {{ data.sitecoreContentModel.deleteProfileConfirmationDescription }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='deleteProfile') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='showDelete = false') {{ Dictionary.C.Cancel }}
  c-modal.c-signups-validation__confirmation(:cornerClose='true' @close='closeConfirmation' v-if='confirmation')
    template(slot='body')
      .c-signups-validation__confirmation-body
        h3.c-signups-validation__confirmation-heading.o-heading.o-heading--size-3(v-if='data.sitecoreContentModel.validationTitle') {{ data.sitecoreContentModel.validationTitle }}
        .c-signups-validation__confirmation-text(
          v-html='data.sitecoreContentModel.validationDescription'
          v-if='data.sitecoreContentModel.validationDescription'
        )
        template(v-for='membershipOrganization in revalidatedApplications')
          .c-signups-validation__organization.o-grid.o-grid--align-items-center.o-grid--gutter-small
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0(v-if='getLogo(membershipOrganization.membershipOrganizationId)')
                img.c-signups-validation__confirmation-logo.c-signups-validation__confirmation-logo--small(
                  :src='getLogo(membershipOrganization.membershipOrganizationId)'
                )
              .o-grid__item.o-grid__item--grow-0(v-if='membershipOrganization.membershipOrganizationName')
                span {{ membershipOrganization.membershipOrganizationName }}
          table.o-table.o-table--v-middle.c-signups-validation__confirmation-table
            thead
              tr
                th {{ Dictionary.P.Picture }}
                th {{ Dictionary.P.PropertyName }}
                th {{ Dictionary.A.Address }}
                th {{ Dictionary.W.WaitingList }}
            tbody
              tr(v-for='property in membershipOrganization.unsubscribedPropertyApplications')
                td
                  img.c-signups-validation__confirmation-logo.c-signups-validation__confirmation-logo--small(
                    :src='property.propertyImage'
                    v-if='property.propertyImage'
                  )
                td {{ property.propertyName }}
                td {{ property.propertyAddress }}
                td {{ property.numberOfUnsubscribedApplications }}
    template(slot='footer')
      .c-signups-validation__confirmation-footer
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12
              button.o-button.o-button--inverted(@click='confirmation.callback()') {{ Dictionary.A.Approve }}
            .o-grid__item.o-grid__item--12
              span.o-link(@click='closeConfirmation') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import cpr from 'danish-ssn'
import FormControllSensitive from '@/components/form/controlls/form-sensitive'
import FormControllEmail from '@/components/form/controlls/form-email'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'c-profile-information',
  props: {
    data: Object
  },
  components: {
    'c-fc-sensitive': FormControllSensitive,
    'c-fc-email': FormControllEmail
  },
  data() {
    return {
      validation: {},
      socialSecurityNumber: {
        helperText: this.data.sitecoreContentModel.socialSecurityDescription,
        placeholderText: this.Dictionary.C.Cpr,
        autocomplete: 'do-not-autofill',
        value: null,
        isMandatory: false,
        readOnly: false,
        validation: {
          errorMessage: '',
          displayRule: '######-####',
          hide: 4,
          customValidation: this.validCpr
        }
      },
      email: {
        helperText: '',
        placeholderText: this.Dictionary.E.Email,
        value: null,
        autocomplete: 'do-not-autofill',
        isMandatory: true,
        readOnly: false,
        validation: {
          errorMessage: '',
          mask: '',
          displayRule: '',
          unique: true
        }
      },
      image: '',
      isUserMinor: false,
      showDelete: false,
      avatar: null,
      mutableState: {
        address: null,
        applicantNumber: null,
        city: null,
        country: null,
        company: null,
        cvr: null,
        countryId: null,
        email: null,
        firstName: null,
        gdprConsent: null,
        guardian: {
          email: undefined,
          id: undefined,
          name: undefined,
          phoneNumber: undefined
        },
        landlinePhone: null,
        lastName: null,
        phoneCode: null,
        phoneNoForSms: null,
        socialSecurityNumber: null,
        userId: null,
        zipCode: null
      },
      passwordStrength: this.Dictionary.P.PasscodeWeek,
      passwordLvl: 'lvl1',
      validFileSize: true,
      validFileFormat: true,
      maxFileSize: 512000,
      acceptableFormats: ['image/png', 'image/jpg', 'image/jpeg'],
      validPass: true,
      validZip: true,
      validPhone: true,
      validPhone2: true,
      validGuardianPhone: true,
      validGuardianEmail: true,
      validGuardianName: true,
      validCVRNumber: true,
      showPasswordStrengthMeter: false
    }
  },
  computed: {
    ...mapGetters('user', ['profileData']),
    ...mapState('membershipOrganizations', ['confirmation']),
    ...mapState('user', ['loaded', 'dataBusterKey', 'impersonator', 'impersonatorAdmin']),
    maxFileSizeClean() {
      return this.$options.filters.prettyBytes(this.maxFileSize)
    },
    canDelete() {
      return !this.impersonator || this.impersonatorAdmin
    },
    dataChanged() {
      const same =
        _.isEqual(JSON.parse(JSON.stringify(this.mutableState).replaceAll('""', null)), JSON.parse(JSON.stringify(this.profileData).replaceAll('""', null))) &&
        !this.avatar
      return !same
    },
    revalidatedApplications() {
      return this.confirmation.response.data.revalidatedApplications || null
    },
    userEmail() {
      return this.email.value
    },
    cprNum() {
      return this.socialSecurityNumber.value && this.socialSecurityNumber.value.replace('-', '')
    },
    formIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key
        }) &&
        this.validPass &&
        this.validZip &&
        this.validPhone &&
        this.validPhone2 &&
        this.validCVRNumber &&
        (!this.isUserMinor || (this.validGuardianPhone && this.validGuardianEmail && this.validGuardianName))
      )
    }
  },
  methods: {
    getLogo(moId) {
      const targetMo = this.all && this.all.find(organization => organization.id === moId)
      return targetMo && targetMo.logo
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    closeConfirmation() {
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
    },
    validateData() {
      if (this.profileData.socialSecurityNumber !== this.mutableState.socialSecurityNumber) {
        this.$store.dispatch('membershipOrganizations/validateParameters', {
          parameters: { '02d3bb8d-e539-401b-95cc-66dad83818a0': this.mutableState.socialSecurityNumber },
          callback: this.submitData
        })
      } else {
        this.submitData()
      }
    },
    submitData() {
      const mutableData = {}
      Object.keys(this.mutableState).forEach(key => {
        if (JSON.stringify(this.mutableState[key]) !== JSON.stringify(this.profileData[key]) || JSON.stringify(this.mutableState[key]) !== undefined) {
          mutableData[key] = this.mutableState[key]
        }
      })

      const data = Object.assign({}, mutableData)
      if (this.avatar) data.avatar = this.avatar
      this.avatar = null
      if (!mutableData.userId) data.userId = this.profileData.userId // Mandatory data needed

      this.$store.dispatch('user/setProfileInformation', { data, mutableData })
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
    },
    resetData() {
      this.mutableState = JSON.parse(JSON.stringify(this.profileData))
      this.email.value = this.profileData.email
      this.socialSecurityNumber.value = this.profileData.socialSecurityNumber
      this.image = `/api/users/${this.profileData.userId}/avatar?cacheBuster=${this.dataBusterKey}`
      this.checkAge()
    },
    deleteProfile() {
      this.$store.dispatch('user/deleteProfile')
    },
    checkAge() {
      const cprBirthday = this.formIsValid && this.cprNum && this.cprNum.length === 10 && moment.utc(cpr(this.cprNum).date)
      const youngerThanAllowed = cprBirthday && moment.utc().subtract(18, 'years').isBefore(cprBirthday)
      if (youngerThanAllowed) {
        this.isUserMinor = true
        this.validateGuardianName()
        this.validateGuardianPhone()
        this.validateGuardianEmail()
      } else {
        this.isUserMinor = false
        this.mutableState.guardian = {
          id: undefined,
          name: undefined,
          email: undefined,
          phoneNumber: undefined
        }
      }
    },
    validateZip(e) {
      const valid = e.target.value.length === 0 || e.target.value.length === 4
      this.validZip = valid
      this.setInputState(e.target, valid)
    },
    validatePhone(e) {
      const valid = e.target.value.length === 0 || e.target.value.length >= 8
      this.validPhone = valid
      this.setInputState(e.target, valid)
    },
    validatePhone2(e) {
      const valid = e.target.value.length === 0 || e.target.value.length >= 8
      this.validPhone2 = valid
      this.setInputState(e.target, valid)
    },
    validateGuardianName() {
      const valid = this.$refs.guardianName.value.trim().length > 0
      this.validGuardianName = valid
      this.setInputState(this.$refs.guardianName, valid)
    },
    validateGuardianPhone() {
      const valid = this.$refs.guardianPhone.value.length >= 8
      this.validGuardianPhone = valid
      this.setInputState(this.$refs.guardianPhone, valid)
    },
    validateGuardianEmail() {
      const valid = this.validEmail(this.$refs.guardianEmail.value)
      this.validGuardianEmail = valid
      this.setInputState(this.$refs.guardianEmail, valid)
    },
    validateCVRNumber(e) {
      const valid = !this.mutableState.cvr || (Number.isInteger(parseInt(e.target.value)) && e.target.value.toString().length === 8)
      this.validCVRNumber = valid
      this.setInputState(this.$refs.cvr, valid)
    },
    imageUpload(e) {
      const reader = new FileReader()

      const file = e.target.files[0]
      reader.onload = function (e) {
        this.image = e.target.result
      }.bind(this)

      if (file) {
        this.validFileSize = file.size <= this.maxFileSize
        this.validFileFormat = this.acceptableFormats.includes(file.type)
        if (this.validFileSize && this.validFileFormat) {
          this.avatar = file
          reader.readAsDataURL(this.avatar)
        }
      }
    },
    setPasswordStrength: function (event) {
      this.validPass = this.validPassword(this.mutableState.newPassword)
      if (this.mutableState.newPassword === '' || !this.mutableState.newPassword) {
        this.validPass = true
      }

      if (this.validPass) {
        event.target.setCustomValidity('')
      } else {
        event.target.setCustomValidity(' ')
      }

      if (this.mutableState.newPassword && this.mutableState.newPassword.length > 9 && this.validPass) {
        this.passwordLvl = 'lvl4'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl4
      } else if (this.mutableState.newPassword && this.mutableState.newPassword.length > 7 && this.validPass) {
        this.passwordLvl = 'lvl3'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl3
      } else if (this.mutableState.newPassword && this.mutableState.newPassword.length > 5) {
        this.passwordLvl = 'lvl2'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl2
      } else if (this.mutableState.newPassword && this.mutableState.newPassword.length < 5) {
        this.passwordLvl = 'lvl1'
        this.passwordStrength = this.Dictionary.P.PasscodeLvl1
      }
    },

    setInputState: function (element, valid) {
      if (valid) {
        element.setCustomValidity('')
      } else {
        element.setCustomValidity(' ')
      }
    }
  },
  created() {
    this.$store.dispatch('user/setUserData', this.data.userProfile)
  },
  mounted() {
    this.resetData()
  },
  watch: {
    dataBusterKey() {
      this.resetData()
    },
    userEmail(newVal) {
      this.mutableState.email = newVal
    },
    cprNum(newVal) {
      this.mutableState.socialSecurityNumber = newVal
    }
  }
}
</script>
<style lang="scss" scoped>
.c-signups-validation {
  $root: &;

  &__organization {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('header');
    width: 100%;
    height: 100%;
    @include min('desktop') {
      position: relative;
      z-index: unset;
      min-width: 0;
    }
  }

  &__organization {
    margin-bottom: 10px;
  }

  &__confirmation {
    text-align: center;

    &-checkmark {
      width: toRem(29px);
      height: toRem(26px);
      fill: $white;
      display: block;
      margin: 0 auto 20px;
    }
    &-heading {
      margin-bottom: 20px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-logo {
      max-height: 80px;
      margin: 0 auto 10px;
      display: block;

      & + #{$root}__confirmation-heading {
        margin-top: 38px;
      }

      &--small {
        max-height: 50px;
        margin: 0 auto;
      }
    }
    &-text {
      margin-bottom: 25px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-box {
      background-color: $white;
      color: $ocean-primary;
      padding: toRem(22px) toRem(35px) toRem(40px);
    }

    &-footer {
      margin-top: 25px;
      &::first-child {
        margin-top: 0;
      }
      &-skip {
        background: transparent;
        border: none;
      }
    }
  }
}
.profile-information {
  &__autofillFix {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    border: none;
  }
  &__navigation {
    display: none;
    z-index: 1;

    @include min('desktop') {
      display: block;
    }
  }
  &__heading-3 {
    font-family: 'Inria Serif', serif;
    @include setStyles('20', $s-typography-sizes);
    margin-top: toRem(26px);
    text-align: center;
    font-weight: 700;
  }
  &__save-actions {
    padding: toRem(30px) 0 0;
    border-top: 1px solid $grey-4;
    justify-content: space-between;
    display: flex;
    margin-top: toRem(40px);
  }
  &__clear {
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    line-height: toRem(54px);
    font-weight: 700;
    color: $ocean-primary;
  }
  &__delete-profile-container {
    padding: toRem(30px);
    border-top: 1px solid $grey-4;
    border-bottom: 1px solid $grey-4;
    text-align: center;
    margin-top: toRem(40px);

    + .profile-information__save-actions {
      padding: 0;
      border-top: none;
      margin-top: toRem(20px);
    }
  }
  &__delete-profile-button {
    display: inline-block;
    color: $error-red;
    text-decoration: underline;
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    font-weight: 700;
    &:hover {
      color: $error-red;
    }
  }
  &__image {
    display: none;
    justify-content: center;
    flex-direction: column;

    @include min('desktop') {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &--mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      @include min('desktop') {
        display: none;
      }
    }
  }
  &__image-container {
    height: toRem(85px);
    width: toRem(85px);
    border-radius: toRem(100px);
    position: relative;
    background-size: cover;
    background-position: center;
    margin-top: toRem(10px);

    @include min('desktop') {
      height: toRem(200px);
      width: toRem(200px);
    }
  }
  &__image-container-item {
  }
  &__image-error {
    margin-top: toRem(16px);
    color: $error-red;
    text-align: center;
    width: 100%;
  }
  &__image-input-label {
    position: absolute;
    bottom: 0;
    right: 0;
    width: toRem(30px);
    height: toRem(30px);
    background-color: $ocean-4;
    border-radius: toRem(25px);
    cursor: pointer;

    @include min('desktop') {
      width: toRem(50px);
      height: toRem(50px);

      svg {
        width: 30px;
        height: 30px;
      }
    }

    button {
      background-color: transparent;
      border: none;
    }

    svg {
      width: 17px;
      height: 17px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: $ocean-primary;

      @include min('desktop') {
        width: 30px;
        height: 30px;
      }
    }
  }
  &__country-code {
    padding-right: toRem(5px);
    background-image: none;
  }
  &__country-code-wrap {
    max-width: toRem(82px) !important;
    min-width: toRem(82px);
  }
  &__guardian {
    background-color: $grey-5;
    padding: toRem(20px);
    text-align: center;
    margin-top: toRem(20px);
  }
  &__guardian-headline {
    font-family: 'Inria Serif', serif;
    @include setStyles('18', $s-typography-sizes);
    font-weight: 700;
    margin-bottom: toRem(10px);
  }
  &__guardian-text {
    @include setStyles('13', $s-typography-sizes);
    margin-bottom: toRem(10px);
  }
  &__info-icon {
    width: toRem(30px);
    height: toRem(30px);
    fill: $ocean-primary;
  }
  &__applicant-number {
    font-size: small;
    color: #2c4856;
  }
  .header__profile {
    display: block;
    position: relative;
    top: 0;
    left: 0;
  }
}
.password-strength {
  position: relative;
  width: 100%;
  height: toRem(34px);
  transition: 0.2s ease all;
  overflow: hidden;
  z-index: z('elements');
  margin-top: toRem(3px);

  span {
    justify-self: flex-end;
    @include setStyles('13', $s-typography-sizes);
    color: $ocean-primary;
  }
}

.password-strength-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 0 toRem(13px);
  height: 100%;
}

.password-strength-meter {
  display: flex;

  li {
    width: toRem(40px);
    height: toRem(3px);
    background-color: $grey-4;
    margin-right: toRem(3px);
    transition: 0.5s ease all;
  }
}

.password-strength-meter {
  &.lvl1 li:first-child {
    background-color: $error-red;
  }

  &.lvl2 li:nth-child(-n + 2) {
    background-color: $warning-orange;
  }

  &.lvl3 li:nth-child(-n + 3) {
    background-color: $ok-green;
  }

  &.lvl4 li {
    background-color: $ok-green;
  }
}
</style>
