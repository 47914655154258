/* eslint-disable prettier/prettier */
<template lang="pug">
.c-table
  .c-table__data
    vue-good-table(
      :columns='columns',
      :pagination-options='{ enabled: true, perPage: mutablePageSize }',
      :rows='rows',
      :sort-options='{ initialSortBy: { field: "advertId", type: "asc" } }'
      @on-per-page-change='setPageSize'
      @on-sort-change='resetPaging = !resetPaging'
      styleClass='o-table'
    )
      template(slot='table-row' slot-scope='props')
        slot(name='colum-before' v-if='props.column.column == "column-before"')
        slot(name='colum-after' v-if='props.column.column == "column-after"')
        template(v-else) {{ props.formattedRow[props.column.field] }}
      template(slot='pagination-bottom' slot-scope='props')
        TablePagination(
          :page-changed='props.pageChanged',
          :page-size='mutablePageSize',
          :per-page-changed='props.perPageChanged',
          :reset-paging='resetPaging',
          :total='rows.length'
        )
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import TablePagination from './table-pagination/index'

export default {
  name: 'c-table',
  props: {
    pageSize: {
      default: 4
    },
    columns: Array,
    rows: Array
  },
  components: { VueGoodTable, TablePagination },
  data() {
    return {
      id: this._uid,
      resetPaging: false,
      mutablePageSize: this.pageSize
    }
  },
  computed: {
    ...mapState('loadingIndicator', ['loading'])
  },
  methods: {
    setPageSize(params) {
      this.mutablePageSize = params.currentPerPage
    }
  }
}
</script>
<style lang="scss" scoped>
.c-table {
}
</style>
