import { ub4PropertyManagementService } from '@/_services'

const initialState = {
  ownerships: [],
  selected: undefined,
  selectedCompany: undefined,
  selectedPropertyNumber: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmationTarget: undefined
}

const initialSelected = {
  id: undefined
}

export const ub4PropertyManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    selectedCompanyNo: state =>
      state.selectedPropertyNumber && state.selectedPropertyNumber.split('-')[0] && parseInt(state.selectedPropertyNumber.split('-')[0]),
    selectedPropertyNo: state =>
      state.selectedPropertyNumber && state.selectedPropertyNumber.split('-')[1] && parseInt(state.selectedPropertyNumber.split('-')[1]),
    selectedCompanyId: state => state.selectedCompany && state.selectedCompany.id
  },
  actions: {
    async setSelected({ commit }, { id, companyId }) {
      // TODO in another story
      if (id) {
        try {
          const response = await ub4PropertyManagementService.getUser(id)
          if (response && response.status === 200) {
            response.data.companyId = companyId
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    createNew({ commit }) {
      commit('createNew')
    },
    async getUb4Ownerships({ commit }) {
      try {
        const responseOwnerships = await ub4PropertyManagementService.getUb4Ownerships()
        if (responseOwnerships && responseOwnerships.status === 200) {
          commit('updateData', {
            ownerships: responseOwnerships.data
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async postUb4Ownership({ commit }, { data }) {
      try {
        const response = await ub4PropertyManagementService.postUb4Ownership(data)
        if (response && response.status === 204) {
          this.dispatch('ub4PropertyManagement/getUb4Ownerships')
          commit('removeSelected')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteMapping({ commit }, payload) {
      try {
        const response = await ub4PropertyManagementService.deleteUb4Ownership(payload)
        if (response && response.status === 204) {
          this.dispatch('ub4PropertyManagement/getUb4Ownerships')
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    }
  }
}
