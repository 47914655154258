import apiClient from '@/_helpers/api-client'
export const usersManagementService = {
  getUsersStatus,
  createUser,
  patchUser,
  patchUserRoles,
  unLockUser,
  lockUser,
  getUser,
  createSysadminUser
}

async function getUsersStatus(data) {
  return await apiClient({
    url: '/users/status',
    method: 'POST',
    data
  })
}

async function createUser(data) {
  return await apiClient({
    url: '/users',
    method: 'POST',
    data
  })
}
async function patchUser({ data, id }) {
  return await apiClient({
    url: `/users/${id}`,
    method: 'PATCH',
    data
  })
}
async function getUser(id) {
  return await apiClient({
    url: `/users/${id}`,
    method: 'get'
  })
}
async function patchUserRoles({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/users/${id}/property-administrator`,
    method: 'PATCH',
    data
  })
}
async function unLockUser(id) {
  return await apiClient({
    url: `/users/${id}/unlock`,
    method: 'POST'
  })
}
async function lockUser(id) {
  return await apiClient({
    url: `/users/${id}/lock`,
    method: 'POST'
  })
}

async function createSysadminUser(data) {
  return await apiClient({
    url: '/users/users/administrators',
    method: 'POST',
    data
  })
}
