<template lang="pug">
.c-pm-distribution
  .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-normal
    .o-grid__group
      .c-pm-distribution__owners.o-grid__item.o-grid__item--6
        .c-pm-distribution__heading.o-heading.o-heading--size-4 {{ translations.headline }}
        vue-good-table(:columns='mainColumns', :rows='data.ownerData', :sort-options='{ enabled: false }' styleClass='o-table')
          template(slot='emptystate') {{ translations.noResultsText }}
      .o-grid__item.o-grid__item--12.c-pm-distribution__data
        .c-pm-distribution__heading.o-heading.o-heading--size-4 {{ translations.historyHeadline }}
        .c-pm-distribution__description.o-bodytext.o-bodytext--size-small(v-if='translations.historyDescription') {{ translations.historyDescription }}
        .c-pm-distribution__count.o-heading.o-heading--size-5 {{ resultsCountText }}
        vue-good-table(
          :columns='subColumns',
          :group-options='{ enabled: true }',
          :pagination-options='paginationSettingsData',
          :rows='subRows',
          :sort-options='{ enabled: false }',
          :totalRows='totalResults'
          styleClass='o-table'
        )
          template(slot='emptystate') {{ translations.noResultsText }}
          template(slot='table-row' slot-scope='props')
            template(v-if='props.column.field == "selected" || props.column.field == "suggested"')
              svg.c-pm-distribution__icon.o-svg-icon(type='image/svg+xml' v-if='props.row.selected || props.row.suggested' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-checkmark' y='0')
              span(v-else)
            template(v-else) {{ props.formattedRow[props.column.field] }}
        .c-search-result__loading(v-if='loading')
          c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'c-pm-distribution',
  props: {
    translations: Object,
    data: Object,
    type: String
  },
  data() {
    return {
      id: this._uid,
      pageOfItems: [],
      ownerColumns: [
        {
          label: this.Dictionary.N.Number,
          field: 'companyNumber'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'name'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'percentage',
          formatFn: this.reFormatPercentage
        }
      ],
      subOwnerColumns: [
        {
          label: this.Dictionary.O.Owner,
          field: 'name'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'percentage',
          formatFn: this.reFormatPercentage
        }
      ],
      dataColumns: [
        {
          label: this.Dictionary.P.PropertyNumber,
          field: 'propertyNumber'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'owner'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'distribution'
        },
        {
          label: this.Dictionary.S.Selected,
          field: 'selected'
        }
      ],
      subDataColumns: [
        {
          label: this.Dictionary.O.Owner,
          field: 'owner'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'distribution'
        },
        {
          label: this.Dictionary.S.Selected,
          field: 'selected'
        },
        {
          label: this.Dictionary.S.Suggested,
          field: 'suggested'
        },
        {
          label: this.Dictionary.N.Note,
          field: 'note'
        }
      ]
    }
  },
  components: {
    VueGoodTable
  },
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    paginationSettingsData() {
      const paginationSettingsData = this.paginationSettings
      paginationSettingsData.perPageDropdownEnabled = false
      paginationSettingsData.perPage = 50
      return paginationSettingsData
    },
    totalResults() {
      let length = 0
      this.subRows.forEach(group => {
        length += 1
        if (group.children.length) {
          length += group.children.length
        }
      })

      return length
    },
    mainColumns() {
      return this.type === 'owners' ? this.ownerColumns : this.subOwnerColumns
    },
    subColumns() {
      return this.type === 'owners' ? this.dataColumns : this.subDataColumns
    },
    subRows() {
      const subRows = []
      this.data.ownerTurns.forEach(turn => {
        const ownerTurn = {
          mode: 'span', // span means this header will span all columns
          html: false, // if this is true, label will be rendered as html
          label: this.formatDate(turn.date), // this is the label that'll be used for the header
          children: []
        }
        turn.turns.forEach(subTurn => {
          ownerTurn.children.push({
            owner: subTurn.name,
            distribution: `${subTurn.percentage.toFixed()} %`,
            selected: subTurn.chosen,
            propertyNumber: subTurn.residenceNumber,
            note: subTurn.note
          })
        })
        subRows.push(ownerTurn)
      })
      return subRows
    },
    resultsCountText() {
      const totalResultsWithoutDates = this.totalResults ? this.totalResults - this.data.ownerTurns.length : 0
      return this.translations.countText ? this.translations.countText.replace('{0}', totalResultsWithoutDates) : totalResultsWithoutDates
    }
  },
  methods: {
    reFormatPercentage(value) {
      return value ? `${value.toFixed()} %` : ''
    }
  },
  created() {}
}
</script>
<style lang="scss" scoped>
.c-pm-distribution {
  &__data {
    position: relative;
    min-height: 280px;
    margin-bottom: 40px;
  }

  &__heading,
  &__count,
  &__description {
    margin: toRem(0 0 20px);
  }

  &__icon {
    width: 14px;
    height: 14px;
  }
}
</style>
