import apiClient from '@/_helpers/api-client'
export const residenceManagementService = {
  searchManagedResidences,
  // searchResidences,
  createResidence,
  patchResidence,
  deleteResidence,
  getResidence,
  getTypes,
  addMedia,
  removeMedia,
  moveMedia,
  changeMedia,
  importResidencesFromExcel,
  importRentWarningsFromExcel,
  getResidencePageModel,
  transferTurn,
  getNextOwnerTurn,
  reportResidence
}

async function searchManagedResidences(data) {
  return await apiClient({
    url: '/search/managed-residences',
    method: 'POST',
    data
  })
}

async function createResidence(data) {
  return await apiClient({
    url: '/data/residences',
    method: 'POST',
    data
  })
}
async function patchResidence({ data, id }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/residences/${id}`,
    method: 'PATCH',
    data
  })
}
async function deleteResidence(id) {
  return await apiClient({
    url: `/data/residences/${id}`,
    method: 'DELETE'
  })
}
async function getResidence(id) {
  return await apiClient({
    url: `/data/residences/${id}`,
    method: 'GET'
  })
}
async function getTypes() {
  return await apiClient({
    url: '/data/residence-types',
    method: 'GET'
  })
}
async function addMedia({ residenceId, type, formData, panoramaLink = false }) {
  const url = panoramaLink ? `/data/residences/${residenceId}/media/${type}/link` : `/data/residences/${residenceId}/media/${type}`
  return await apiClient({
    url,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function removeMedia({ residenceId, mediaId }) {
  return await apiClient({
    url: `/data/residences/${residenceId}/media/${mediaId}`,
    method: 'DELETE'
  })
}

async function moveMedia({ residenceId, data }) {
  return await apiClient({
    url: `/data/residences/${residenceId}/media/reorder`,
    method: 'PATCH',
    data
  })
}

async function changeMedia({ residenceId, mediaId, mediaType }) {
  return await apiClient({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: `/data/residences/${residenceId}/media/${mediaId}`,
    method: 'PATCH',
    data: mediaType
  })
}

async function importResidencesFromExcel(formData) {
  return await apiClient({
    url: '/data/import/residences/from/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function importRentWarningsFromExcel(formData) {
  return await apiClient({
    url: '/data/import/rentwarnings/from/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}
async function getResidencePageModel(id) {
  return await apiClient({
    url: `/models/residence/${id}`,
    method: 'GET'
  })
}

async function transferTurn({ id, companyId }) {
  return await apiClient({
    url: `/data/residences/${id}/transfer-turn/${companyId}`,
    method: 'PUT'
  })
}

async function getNextOwnerTurn(id) {
  return await apiClient({
    url: `/data/residences/${id}/check-other-shared-owner-has-residence-free`,
    method: 'GET'
  })
}

async function reportResidence(id, data) {
  return await apiClient({
    url: `/data/residences/${id}/report`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
