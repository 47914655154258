/* eslint-disable prettier/prettier */
<template lang="pug">
.c-ads-overview
  .c-ads-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.screeningTitle }}
  .c-ads-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-ads-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettingsData', :rows='enrichedAdverts', :totalRows='totalResults' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "address"')
          a.c-ad-overview__item--name.o-bodytext.o-bodytext--size-small(
            :href='`/${notifications.locale ? notifications.locale : ""}/residence/${props.row.residenceId}`'
            target='_blank'
          ) {{ props.row.address }}
        template(v-if='props.column.field == "edit"')
          button.c-ads-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.id)') {{ Dictionary.E.Edit }}
        template(v-if='props.column.field == "actions"')
          .o-grid.o-grid--gutter-horizontal-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-button.o-button--small.o-bodytext.o-bodytext--size-small(@click='setState(props.row.id, "reject")') {{ Dictionary.R.Reject }}
              .o-grid__item.o-grid__item--grow-0
                button.o-button.o-button--small.o-bodytext.o-bodytext--size-small(@click='setState(props.row.id, "approve")') {{ Dictionary.A.Approve }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState } from 'vuex'

import { VueGoodTable } from 'vue-good-table'
import { getQueryParameters } from '@/_helpers/query-parameters'
export default {
  name: 'c-ads-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      tableKey: 0,
      showAdvertImport: false,
      showWarningsImport: false,
      hasElevatedRights: false,
      advertFromResidenceId: getQueryParameters().get('advertFromResidenceId') || undefined,
      advertId: getQueryParameters().get('advertId') || undefined,
      columns: [
        {
          label: this.Dictionary.A.Address,
          field: 'address',
          formatFn: this.addressFormat
        },
        {
          label: this.Dictionary.L.Landlord,
          field: 'responsibleName'
        },
        {
          label: '',
          field: 'edit'
        },
        {
          label: this.Dictionary.A.Action,
          field: 'actions'
        }
      ]
    }
  },
  components: {
    VueGoodTable
  },
  computed: {
    ...mapState('advertManagement', ['adverts', 'totalResults', 'serverParams']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapState('usersManagement', ['users']),
    ...mapState('user', ['roles', 'notifications']),
    ...mapState('search', ['companies', 'facilities', 'facilityLocations']),
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.screeningResultsCountText
        ? this.model.screeningResultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0)
        : this.totalResults
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    enrichedAdverts() {
      const enrichedAdverts = this.adverts.map(advert => {
        if (advert.companyId) {
          const responsibleCompany = this.companies.find(company => company.id === advert.companyId)
          const responsibleCompanyName = responsibleCompany && responsibleCompany.name
          advert.responsibleName = responsibleCompanyName || advert.companyId
        } else if (advert.creator) {
          const responsibleUser = this.users.find(user => user.id === advert.creator)
          const responsibleUserName = responsibleUser
            ? responsibleUser.firstName && responsibleUser.lastName
              ? `${responsibleUser.firstName} ${responsibleUser.lastName}`
              : responsibleUser.email
            : undefined
          advert.responsibleName = responsibleUserName || advert.creator
        }
        return advert
      })
      return enrichedAdverts
    }
  },
  methods: {
    addressFormat() {
      return ''
    },
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    setSelected(id) {
      this.getInitialData()
      this.$store.dispatch('advertManagement/setSelected', id)
    },
    setState(id, state) {
      this.$store.dispatch('advertManagement/toggleConfirmation', { id, action: state })
    },
    createNew() {
      this.getInitialData()
      this.$store.dispatch('advertManagement/createNew')
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    getInitialData() {
      if (this.hasElevatedRights && this.companies.length === 0) {
        this.$store.dispatch('search/getCompanies')
      }
      if (this.propertyTypes.length === 0) {
        this.$store.dispatch('propertyManagement/getTypes')
      }
      if (this.residenceTypes.length === 0) {
        this.$store.dispatch('residenceManagement/getTypes')
      }
      if (this.facilities.length === 0) {
        this.$store.dispatch('search/getFacilities')
      }
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')

    this.$store.dispatch('advertManagement/searchManagedAdverts', { screening: true, pageSize: 1000, page: 0 })
  },
  watch: {
    adverts() {
      const userAdverts = this.adverts.filter(advert => !advert.companyId && advert.creator).map(advert => advert.creator)
      this.$store.dispatch('usersManagement/getUsersStatus', { ids: userAdverts, keepRecords: false })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-ads-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
