import apiClient from '@/_helpers/api-client'
export const messagesService = {
  postThreads,
  postThreadsCount,
  getThread, /// Marks messages as READ
  postSendToPropertyOwner,
  postSendMessageToThread,
  postSearch,
  getAttachment,
  archiveThread,
  unarchiveThread,
  markThreadAsUnread,
  getThreadRelatedToEntity,
  getThreadRelatedToEntityWithUser,
  getThreadsRelatedToEntityWithUsers,
  postSendMessageRelatedToEntityWithUser,
  getUnreadCount
}

async function postThreads(data) {
  return await apiClient({
    url: '/communications/threads',
    method: 'POST',
    data
  })
}

async function postThreadsCount(data) {
  return await apiClient({
    url: '/communications/threads/count',
    method: 'POST',
    data
  })
}

async function getThread(id) {
  return await apiClient({
    url: `/communications/threads/${id}`,
    method: 'GET'
  })
}

async function postSendToPropertyOwner({ id, formData }) {
  return await apiClient({
    url: `/communications/messages/send/to-property-owner/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function postSendMessageToThread({ id, formData }) {
  return await apiClient({
    url: `/communications/messages/send/to-message-thread/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function postSearch(data) {
  return await apiClient({
    url: '/communications/messages/search',
    method: 'POST',
    data
  })
}

async function getAttachment(id) {
  return await apiClient({
    url: `/communications/messages/attachments/${id}`,
    method: 'GET'
  })
}

async function archiveThread(messageThreadId) {
  return await apiClient({
    url: `/communications/messages/threads/${messageThreadId}/archive`,
    method: 'PUT'
  })
}

async function unarchiveThread(messageThreadId) {
  return await apiClient({
    url: `/communications/messages/threads/${messageThreadId}/unarchive`,
    method: 'PUT'
  })
}

async function markThreadAsUnread(messageThreadId, userId, propertyAdministratorId) {    
    return await apiClient({
        url: `/communications/messages/threads/${messageThreadId}/markasunread`,
        method: 'PUT',
        data: {userId, propertyAdministratorId}
    })
}

async function getThreadRelatedToEntity(id) {
  return await apiClient({
    url: `/communications/messages/thread/related-to/${id}`,
    method: 'GET'
  })
}

async function getThreadRelatedToEntityWithUser({ id, userId }) {
  return await apiClient({
    url: `/communications/messages/threads/related-to/${id}/with/${userId}`,
    method: 'GET'
  })
}

async function getThreadsRelatedToEntityWithUsers({ id, data }) {
  return await apiClient({
    url: `/communications/messages/threads/related-to/${id}`,
    method: 'POST',
    data
  })
}

async function postSendMessageRelatedToEntityWithUser({ id, userId, formData }) {
  return await apiClient({
    url: `/communications/messages/threads/related-to/${id}/with/${userId}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

async function getUnreadCount() {
  return await apiClient({
    url: '/communications/messages/unread/count',
    method: 'GET'
  })
}
