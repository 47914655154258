<template lang="pug">
.c-state-toggle
  button.c-state-toggle__item.o-button.o-button--small.o-button--secondary(@click='actionCliked' v-if='!value')
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(:xlink:href='getIcon(options[0])' x='0' y='0')
    span {{ options[0].labelText }}
  button.c-state-toggle__item.o-button.o-button--small.o-button--secondary(@click='actionCliked' v-else)
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(:xlink:href='getIcon(options[1])' x='0' y='0')
    span {{ options[1].labelText }}
</template>
<script>
export default {
  name: 'c-state-toggle',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    store: {
      required: true
    },
    property: {
      required: true
    },
    action: {
      required: true
    },
    options: {
      required: true
    }
  },
  computed: {
    value() {
      return this.$store.state[this.store][this.property]
    }
  },
  methods: {
    actionCliked() {
      this.$store.dispatch(`${this.store}/${this.action}`)
    },
    getIcon(option) {
      return '/app/img/spritemap.svg#sprite-icon-' + option.icon
    }
  }
}
</script>
<style lang="scss" scoped>
.c-state-toggle {
  &__item {
    position: relative;
    padding: 6px 14px 6px 30px;
    @include setStyles('16', $s-typography-sizes);
    line-height: 20px;

    svg {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
    @include max('desktop', -1) {
      padding-top: 5px;
      border: none;
      line-height: 24px;

      &:focus,
      &:hover {
        outline: none;
        border: none;
      }
    }
  }
}
</style>
