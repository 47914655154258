<template lang="pug">
.c-filter-input-select
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      select.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='id'
        @change='setvalue($event.target.value)'
        placeholder=' '
        v-model='model.value'
      )
        option(disabled hidden selected value='null') {{ Dictionary.Search.SelectValue }}
        template(v-for='modelOption in model.options')
          optgroup(:label='modelOption.label' v-if='modelOption.group')
            option(:hidden='groupOption.hidden', :value='groupOption.value' v-for='groupOption in modelOption.options') {{ groupOption.name }}
          option(:hidden='modelOption.hidden', :value='modelOption.value' v-else) {{ modelOption.name }}
      label.o-label.o-label--placeholder(:class='{ "o-label--required": isMandatory }', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
export default {
  name: 'c-form-select',
  data() {
    return {
      id: this._uid,
      value: ''
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.isMandatory) {
            const valueSlected = this.value !== null && this.value.length > 0
            const requiredOptionSelected = this.hasMandatoryOptions
              ? this.model.options.filter(option => option.required && option.value === this.value).length > 0
              : true
            return valueSlected && requiredOptionSelected
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    isMandatory() {
      return this.model.isMandatory || this.hasMandatoryOptions
    },
    hasMandatoryOptions() {
      return this.model.options.some(option => option.required)
    },
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    },
    modelOptions() {
      return this.model.options
    }
  },
  methods: {
    setvalue(value, setModel = true) {
      this.value = value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      if (setModel) this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null && this.model.value.length > 0 && this.model.options.some(option => option.value === this.model.value)) {
        this.$v.value.$touch()
      } else if (this.value === null && this.model.options && this.model.options.length === 1) {
        this.setvalue(this.model.options[0].value)
      }

      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue(newVal) {
      if (newVal !== this.value) {
        this.setAfterModelChange()
      }
    },
    modelOptions() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-input-select {
}
</style>
