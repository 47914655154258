import { usersManagementService, userService, administratorsManagementService } from '@/_services'
import moment from 'moment'

const initialState = {
  users: [],
  administrators: [],
  selectedAdministrator: null,
  selected: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  confirmation: undefined
}

const initialSelected = {
  id: undefined
}

export const usersManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    allManagedUsersIds: state => {
      let allUsers = []
      state.administrators.forEach(administrator => {
        allUsers = [...allUsers, ...administrator.users.filter(d => !allUsers.includes(d))]
      })
      return allUsers
    },
    allManagedUsers: (state, getters) => {
      return getters.allManagedUsersIds.map(managedUser => state.users.find(user => user.id === managedUser)).filter(user => !!user) || []
    }
  },
  actions: {
    async setSelected({ commit }, { id, companyId }) {
      if (id) {
        try {
          const response = await usersManagementService.getUser(id)
          if (response && response.status === 200) {
            response.data.companyId = companyId
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createUser({ commit }, { data, closeSelection }) {
      try {
        const response = await usersManagementService.createUser(data)
        if (response && response.status === 200) {
          const selected = { id: response.data }

          const responseUserRoles = await usersManagementService.patchUserRoles({ data: data.selectedAdministrator, id: response.data })

          if (responseUserRoles && responseUserRoles.status === 200) {
            selected.selectedAdministrator = data.selectedAdministrator
          }

          const responseResetPassword = await userService.resetPassword(data)

          if (responseResetPassword && responseResetPassword.status === 204) {
            // console.log(responseResetPassword.data)
          }

          if (closeSelection) {
            commit('removeSelected')
          } else {
            commit('updateData', selected)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchUser({ commit, state }, { data, closeSelection }) {
      try {
        const response = await usersManagementService.patchUser({ data, id: state.selected.id })
        if (response && response.status === 204) {
          const selected = { ...state.selected }
          const responseUserRoles = await usersManagementService.patchUserRoles({
            data: data.selectedAdministrator ? `${data.selectedAdministrator}` : null,
            id: state.selected.id
          })

          if (responseUserRoles && responseUserRoles.status === 200) {
            selected.selectedAdministrator = data.selectedAdministrator
          }
          if (closeSelection) {
            commit('removeSelected')
          } else {
            commit('updateData', selected)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getPropertyAdministrators({ commit }) {
      try {
        const responseAdministrators = await administratorsManagementService.getPropertyAdministrators()

        if (responseAdministrators && responseAdministrators.status === 200) {
          commit('updateData', {
            administrators: responseAdministrators.data
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getManagedUsers({ commit, getters }) {
      try {
        const responseAdministrators = await administratorsManagementService.getPropertyAdministrators()

        if (responseAdministrators && responseAdministrators.status === 200) {
          commit('updateData', {
            administrators: responseAdministrators.data
          })
        }

        this.dispatch('usersManagement/getUsersStatus', { ids: getters.allManagedUsersIds, keepRecords: true })
      } catch (error) {
        // console.log(error)
      }
    },
    async getUsersStatus({ commit }, { ids, keepRecords }) {
      try {
        const responseUsers = await usersManagementService.getUsersStatus(ids)

        if (responseUsers && responseUsers.status === 200) {
          if (keepRecords) {
            commit('addUsers', responseUsers.data)
          } else {
            commit('updateData', {
              users: responseUsers.data,
              totalResults: responseUsers.data.length
            })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async toggleUserLock({ commit }, { id, lock }) {
      try {
        const response = lock ? await usersManagementService.lockUser(id) : await usersManagementService.unLockUser(id)
        if (response && response.status === 204) {
          commit('toggleConfirmation', false)
          commit('updateEntryLock', { id, lock })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    createNew(state, payload) {
      if (payload === 'admin') {
        state.selected = payload
      } else {
        state.selected = Object.assign({}, initialSelected)
      }
    },
    removeSelected(state) {
      state.selected = undefined
    },
    updateEntryLock(state, { id, lock }) {
      const target = state.users.find(user => user.id === id)
      target.locked = lock ? moment.utc().toDate().toISOString() : null
      if (state.selected) state.selected.locked = target.locked
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    addUsers(state, payload) {
      payload.forEach(user => {
        const targetUser = state.users.find(u => u.id === user.id)
        if (targetUser) {
          Object.assign(targetUser, user)
        } else {
          state.users.push(user)
        }
      })
    },
    toggleConfirmation(state, payload) {
      if (payload) {
        state.confirmation = payload
      } else {
        state.confirmation = undefined
      }
    }
  }
}
