<template lang="pug">
.c-cm-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
      c-fc-text(:model='search' @submit='handleSearch()' v-model='search.value')
    .o-grid__item.o-grid__item--desktop-2
      button.o-button.c-cm-search__button(@click='handleSearch' role='button' v-debounce.keyup='500') {{ Dictionary.S.Search }}
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-cm-search__button(:disabled='!search.value' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-cm-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      search: {
        helperText: this.model.searchHelperText,
        placeholderText: this.model.searchPlaceholderText,
        value: null,
        isMandatory: false,
        validation: null
      }
    }
  },
  components: {
    'c-fc-text': FormControllText
  },
  computed: {},
  methods: {
    handleSearch() {
      const filter = {
        search: this.search.value
      }
      this.$store.dispatch('companiesManagement/searchManagedCompanies', filter)
    },
    handleReset() {
      this.search.value = null
      this.handleSearch()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-cm-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
