<template lang="pug">
.c-notification-inline(:class='[size ? `c-notification-inline--${size}` : "", `c-notification-inline--${type}`]' v-if='notificationVisible')
  .c-notification-inline__wrap
    .c-notification-inline__grid.o-grid.o-grid--wrap-nowrap.o-grid--align-items-top.o-grid--gutter-tiny(:class='[`o-grid--justify-content-${justify}`]')
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0(v-if='showIcon')
          svg.c-notification-inline__icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
            use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
        .o-grid__item(:class='[type === "crab" ? "" : "o-grid__item--grow-0"]')
          template(v-if='html')
            .c-notification-inline__content.o-bodytext.o-bodytext--size-small(:role='getRole(type)' v-html='html')
          .c-notification-inline__content.o-bodytext.o-bodytext--size-small(:role='getRole(type)' v-else)
            slot
    button.c-notification-inline__close(:aria-label='Dictionary.C.Close' @click.stop='handleClose' role='button' v-if='canClose')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-cross' y='0')
</template>

<script>
export default {
  name: 'c-notification-inline',
  data() {
    return {
      notificationVisible: true
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    justify: {
      type: String,
      default: 'center'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    size: {
      type: String
    },
    canClose: {
      type: Boolean,
      default: true
    },
    html: {
      type: String
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
      this.notificationVisible = false
    },
    getRole(type) {
      let role = ''
      switch (type) {
        case 'ok':
          role = 'status'
          break
        case 'warning':
          role = 'alert'
          break
        case 'error':
          role = 'alert'
          break
        default:
          role = ''
      }
      return role
    }
  }
}
</script>

<style lang="scss">
.c-notification-inline {
  &__content {
    margin-top: 2px;
    a {
      color: inherit;
    }
  }
}
</style>
<style lang="scss" scoped>
.c-notification-inline {
  $root: &;
  background-color: $grey-5;
  padding: 15px 20px 20px;

  @include min('desktop') {
    padding: 15px 40px 20px;
  }

  &--small {
    padding: 10px 15px 15px;
  }

  &__wrap {
    position: relative;
  }

  &__grid {
    width: calc(100% - 30px);
  }

  &__content {
    margin-top: 2px;
    a {
      color: inherit;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    display: block;
  }

  &__close {
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 2px;
    right: 0;
    svg {
      width: 20px;
      height: 20px;
      display: block;
      pointer-events: none;
    }
  }
  &--ok {
    background-color: $ok-green;
    color: $white;
    #{$root}__icon {
      fill: $white;
      stroke: $white;
    }
  }

  &--warning {
    background-color: $alert-soft-yellow;
  }

  &--error {
    background-color: $coral-5;
    border: 1px solid $error-red;
    color: $ocean-darker;
    #{$root}__icon {
      fill: $error-red;
    }
  }

  &--crab {
    background-color: $crab-primary;

    #{$root}__grid {
      width: 100%;
    }
  }
}
</style>
