/* eslint-disable prettier/prettier */
<template lang="pug">
.c-parameters-management
  .c-parameters-management__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
  .c-parameters-management__section.o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
    .o-grid__group
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
        .c-parameters-management__section-heading.o-heading.o-heading--size-4 {{ model.selectCompanyHeadline }}
        v-select.c-parameters-management__select(
          :options='companyOptions',
          :placeholder='model.selectCompanyPlaceholder'
          @input='handleCompanyChange'
          label='name'
          v-model='selectedCompany'
        )
        label.o-label.o-label--helper(v-if='model.selectCompanyHelper') {{ model.selectCompanyHelper }}
  c-tabs.c-parameters-management__tabs.c-parameters-management__section(:start-tab='selectedTab' @change='tabChange')
    c-tab(:disabled='!selectedCompany', :name='model.parameter.selectParameterTabName')
      .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
        .o-grid__group
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  .c-parameters-management__section
                    .c-parameters-management__section-heading.o-heading.o-heading--size-4 {{ model.parameter.selectParameterHeadline }}
                    v-select.c-parameters-management__select(
                      :disabled='!selectedCompany',
                      :options='parametersOptions',
                      :placeholder='model.parameter.selectParameterPlaceholder'
                      @input='getParameterProperties'
                      label='question'
                      v-model='selectedParameter'
                    )
                    label.o-label.o-label--helper(v-if='model.parameter.selectParameterHelper') {{ model.parameter.selectParameterHelper }}
                .o-grid__item.o-grid__item--12
                  .c-parameters-management__section
                    .c-parameters-management__section-heading.o-heading.o-heading--size-4 {{ model.parameter.selectPropertiesHeadline }}
                    v-multiselect-listbox.c-parameters-management__list(
                      :disabled='!selectedCompany || !selectedParameter',
                      :no-options-found-text='model.parameter.selectPropertiesNoOptionsFound',
                      :no-selected-options-found-text='model.parameter.selectedPropertiesNoOptionsFound',
                      :options='propertyOptionsIds',
                      :reduce-display-property='getPropertyName',
                      :search-options-placeholder='model.parameter.selectPropertiesSearchPlaceholder',
                      :selected-options-placeholder='model.parameter.selectedPropertiesSearchPlaceholder'
                      search-input-class='o-input o-input--placeholder-simple'
                      v-model='selectedProperties'
                    )
                    label.o-label.o-label--helper(v-if='model.parameter.selectPropertiesHelper') {{ model.parameter.selectPropertiesHelper }}
      .c-parameters-management__footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            button.o-link.c-parameters-management__cancel(:disabled='loading || !canSubmit' @click='reset') {{ model.parameter.selectParameterCancel }}
          .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
            button.o-button.c-parameters-management__submit(:disabled='loading || !canSubmit' @click='submitChanges') {{ model.parameter.selectParameterSave }}
    c-tab(:disabled='!selectedCompany', :name='model.property.selectPropertyTabName')
      .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
        .o-grid__group
          .o-grid__item.o-grid__item--12.o-grid__item--desktop-8
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12
                  .c-parameters-management__section
                    .c-parameters-management__section-heading.o-heading.o-heading--size-4 {{ model.property.selectPropertyHeadline }}
                    v-select.c-parameters-management__select(
                      :disabled='!selectedCompany',
                      :options='propertyOptions',
                      :placeholder='model.property.selectPropertyPlaceholder'
                      @input='getPropertyParameters'
                      label='name'
                      v-model='selectedProperty'
                    )
                    label.o-label.o-label--helper(v-if='model.property.selectPropertyHelper') {{ model.property.selectPropertyHelper }}
                .o-grid__item.o-grid__item--12
                  .c-parameters-management__section
                    .c-parameters-management__section-heading.o-heading.o-heading--size-4 {{ model.property.selectParametersHeadline }}
                    v-multiselect-listbox.c-parameters-management__list(
                      :disabled='!selectedCompany || !selectedProperty',
                      :no-options-found-text='model.property.selectParametersNoOptionsFound',
                      :no-selected-options-found-text='model.property.selectedParametersNoOptionsFound',
                      :options='parametersOptionsIds',
                      :reduce-display-property='getParameterName',
                      :search-options-placeholder='model.property.selectParametersSearchPlaceholder',
                      :selected-options-placeholder='model.property.selectedParametersSearchPlaceholder'
                      search-input-class='o-input o-input--placeholder-simple'
                      v-model='selectedParameters'
                    )
                    label.o-label.o-label--helper(v-if='model.property.selectParametersHelper') {{ model.property.selectParametersHelper }}
      .c-parameters-management__footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            button.o-link.c-parameters-management__cancel(:disabled='loading || !canSubmit' @click='reset') {{ model.property.selectPropertyCancel }}
          .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
            button.o-button.c-parameters-management__submit(:disabled='loading || !canSubmit' @click='submitChanges') {{ model.property.selectPropertySave }}
</template>
<script>
import { mapState } from 'vuex'
import vMultiselectListbox from 'vue-multiselect-listbox'
import { parametersService, propertyManagementService } from '@/_services'

export default {
  name: 'c-parameters-management',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,

      selectedTab: 0,
      selectedCompany: null,
      parametersOptions: [],
      propertyOptions: [],
      selectedParameter: null,
      selectedParameters: [],
      initialParameters: [],
      selectedProperty: null,
      selectedProperties: [],
      initialProperties: [],
      propertyOptionsIds: [],
      parametersOptionsIds: []
    }
  },
  components: {
    'v-multiselect-listbox': vMultiselectListbox
  },
  computed: {
    ...mapState('propertyManagement', ['properties']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading']),
    companyOptions() {
      return this.companies.filter(company => company.organizationId !== null)
    },
    canSubmit() {
      if (this.selectedTab === 0) {
        return this.selectedParameter && this.selectedParameter.id && this.dataChanged
      } else if (this.selectedTab === 1) {
        return this.selectedProperty && this.selectedProperty.id && this.dataChanged
      } else {
        return false
      }
    },
    dataChanged() {
      if (this.selectedTab === 0) {
        return !this.areArraysEqualSets(this.initialProperties, this.selectedProperties)
      } else if (this.selectedTab === 1) {
        return !this.areArraysEqualSets(this.initialParameters, this.selectedParameters)
      } else {
        return false
      }
    }
  },
  methods: {
    getPropertyName(id) {
      const target = this.propertyOptions.find(property => property.id === id)
      return target && target.name
    },
    getParameterName(id) {
      const target = this.parametersOptions.find(parameter => parameter.id === id)
      return target && target.question
    },
    getInitialData() {
      if (this.companies.length === 0) {
        this.$store.dispatch('search/getCompanies')
      }
    },
    handleCompanyChange() {
      if (this.selectedCompany) {
        this.getOrganizationParameters()
        this.getOrganizationProperties()
      }
      this.selectedParameter = null
      this.selectedProperty = null
      this.selectedParameters = []
      this.selectedProperties = []
      this.parametersOptions = []
      this.propertyOptions = []
      this.propertyOptionsIds = []
      this.parametersOptionsIds = []
    },
    tabChange(tab) {
      this.selectedTab = tab
      this.reset()
    },
    async getOrganizationParameters() {
      try {
        const response = await parametersService.getOrganizationParameters(this.selectedCompany.organizationId)
        this.parametersOptions = response.data
        this.parametersOptionsIds = response.data.map(parameter => parameter.id)
      } catch (error) {
        // console.log(error)
      }
    },
    async getOrganizationProperties() {
      try {
        const filter = {
          filters: { companyId: this.selectedCompany.id },
          pageSize: 10000,
          page: 0
        }
        const response = await propertyManagementService.searchManagedProperties(filter)
        this.propertyOptions = response.data.results
        this.propertyOptionsIds = response.data.results.map(property => property.id)
      } catch (error) {
        // console.log(error)
      }
    },
    async getParameterProperties() {
      try {
        if (this.selectedParameter) {
          const response = await parametersService.getParameterProperties(this.selectedParameter.id)
          const properties = response.data.map(property => property.id).filter(property => this.propertyOptions.some(p => p.id === property))

          this.selectedProperties = properties
          this.initialProperties = properties
        } else {
          this.selectedProperties = []
          this.initialProperties = []
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getPropertyParameters() {
      try {
        if (this.selectedProperty) {
          const response = await parametersService.getPropertyParameters(this.selectedProperty.id)
          const parameters = response.data.map(parameter => parameter.id)
          this.selectedParameters = parameters
          this.initialParameters = parameters
        } else {
          this.selectedParameters = []
          this.initialParameters = []
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async submitChanges() {
      try {
        const payload = {}
        if (this.selectedTab === 0) {
          payload[this.selectedParameter.id] = this.selectedProperties
          const response = await parametersService.setParametersProperties(payload)
          if (response && response.status === 204) {
            this.initialProperties = [...this.selectedProperties]
          }
        } else {
          payload[this.selectedProperty.id] = this.selectedParameters
          const response = await parametersService.setPropertiesParameters(payload)
          if (response && response.status === 204) {
            this.initialParameters = [...this.selectedParameters]
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    reset() {
      if (this.selectedTab === 0) {
        this.getParameterProperties()
      } else if (this.selectedTab === 1) {
        this.getPropertyParameters()
      }
    }
  },
  created() {
    this.getInitialData()
  }
}
</script>
<style lang="scss" scoped>
.c-parameters-management {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  &__heading {
    svg {
      stroke: $ocean-darker;
    }
  }

  &__select {
  }

  &__footer {
    margin-top: 50px;
    padding-top: $sidePadding;
    border-top: 1px solid $grey-4;
  }

  &__section {
    &-heading {
      margin-bottom: 20px;
    }

    & + & {
      margin-top: $sectionMargin;
    }

    &--border-top {
      border-top: 1px solid $grey-4;
    }
  }
}
</style>
