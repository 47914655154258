<template lang="pug">
.c-form-wysiwyg
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap.o-richtext
      .editr(v-if='model.readOnly')
        .editr--content(:class='{ disabled: model.readOnly }' v-html='model.value')
      wysiwyg(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.value.$error, required: model.isMandatory }]',
        :disabled='model.readOnly',
        :id='id',
        :name='id',
        :options='wysiwygOptions',
        :placeholder='model.isMandatory ? model.placeholderText + " *" : model.placeholderText',
        :style='styleObject'
        @change='setvalue($event)'
        v-else
        v-model='model.value'
      )
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.value.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
// Import and use Vue Froala lib.
import { minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'c-form-wysiwyg',
  data() {
    return {
      id: this._uid,
      value: undefined,
      wysiwygOptions: {
        hideModules: {
          bold: false,
          italic: false,
          underline: false,
          justifyLeft: true,
          justifyCenter: true,
          justifyRight: true,
          headings: false,
          link: true,
          code: true,
          orderedList: true,
          unorderedList: true,
          image: true,
          table: true,
          separator: true
        },
        maxHeight: '500px'
      }
    }
  },
  model: {
    prop: 'model.value',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    styleObject: {
      type: Object
    }
  },
  validations() {
    return {
      value: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        minLength: this.model.validation.minLength ? minLength(this.model.validation.minLength) : true,
        maxLength: this.model.validation.maxLength ? maxLength(this.model.validation.maxLength) : true
      }
    }
  },
  computed: {
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.value.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    setvalue(value) {
      this.value = value
      this.$v.value.$touch()
      this.$emit('validate', !this.$v.$invalid)
      this.$emit('change', this.$v.value.$model)
    },
    setAfterModelChange() {
      this.value = this.model.value
      if (this.model.value !== null && this.model.value.length > 0) {
        this.$v.value.$touch()
      }
      this.$emit('validate', !this.$v.$invalid)
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-wysiwyg {
}
</style>
