/* eslint-disable prettier/prettier */
<template lang="pug">
.c-am-overview
  .c-am-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper-pen' y='0')
    h1 {{ model.title }}
  keep-alive
    c-am-search(:model='model', :status-options='getStatusOptions()' @status='setStatus')
  .c-am-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-am-overview__data(v-if='!isMobile')
    vue-good-table(
      :columns='columns',
      :pagination-options='{ enabled: pageSize !== totalFilteredApplications, perPage: pageSize }',
      :rows='applicationsWithStatus',
      :sort-options='{ enabled: true, initialSortBy: { field: "created", type: "desc" } }'
      @on-sort-change='resetPaging = !resetPaging'
      styleClass='o-table'
    )
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.c-state-action.c-am-overview__state-action(@click='handleMessages(props.row.advert.id, combinedAddress(props.row.advert))')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
                  span.o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.S.SeeMessages }}
              .o-grid__item.o-grid__item--grow-0(v-if='canDeleteApplication(props.row)')
                button.c-state-action.c-am-overview__state-action(@click='handleDelete(props.row)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.C.Cancel }}
        template(v-else-if='props.column.field == "created"')
          span {{ props.row.created | formatDate }}
        template(v-else-if='props.column.field == "selectableAddress"')
          a.c-am-overview__item--name.o-bodytext.o-bodytext--size-small(
            :href='`/${notifications.locale ? notifications.locale : ""}/residence/${props.row.residenceShortId}`'
            target='_blank'
            v-if='props.row.advert.residenceId && ["RentedOut", "RetiredFromRentedOut", "Retired"].indexOf(props.row.advert.status) === -1'
          ) {{ props.row.selectableAddress }}
          .c-am-overview__item.o-bodytext.o-bodytext--size-small(v-else) {{ props.row.selectableAddress }}
          .c-am-overview__item--advert-status.o-bodytext.o-bodytext--size-xxsmall(v-if='reFormatAdvertStatus(props.row.advert.status)') {{ reFormatAdvertStatus(props.row.advert.status) }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
      template(slot='pagination-bottom' slot-scope='props')
        TablePagination(
          :page-changed='props.pageChanged',
          :page-size='10',
          :per-page-changed='props.perPageChanged',
          :reset-paging='resetPaging',
          :total='totalFilteredApplications'
        )
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
  .c-am-overview__data.c-am-overview__data--mobile(v-else)
    .c-am-overview__item.c-am-overview__item--table-wrap(:key='`applications-item-table-${key}`' v-for='(item, key) in pageOfItems')
      table
        tr
          td.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Address }}
          td.o-bodytext.o-bodytext--size-small
            a.c-am-overview__item--name.o-bodytext.o-bodytext--size-small(
              :href='`/${notifications.locale ? notifications.locale : ""}/residence/${item.residenceShortId}`'
              target='_blank'
              v-if='item.advert.residenceId'
            ) {{ item.selectableAddress }}
            .c-am-overview__item.o-bodytext.o-bodytext--size-small(v-else) {{ props.row.selectableAddress }}
            .c-am-overview__item--advert-status.o-bodytext.o-bodytext--size-xsmall(v-if='reFormatAdvertStatus(item.advert.status)') {{ reFormatAdvertStatus(item.advert.status) }}
        tr
          td.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.AvailableFrom }}
          td.o-bodytext.o-bodytext--size-small {{ formatTheDate(item.advert.availableFrom) }}
        tr
          td.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.L.Landlord }}
          td.o-bodytext.o-bodytext--size-small {{ item.advert.userId }}
        tr
          td.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.O.Owner }}
          td.o-bodytext.o-bodytext--size-small {{ item.advert.companyName }}
        tr
          td.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.S.Status }}
          td.o-bodytext.o-bodytext--size-small {{ item.reFormatedStatus }}
        tr
          td
          td.c-am-overview__item--actions
            .o-grid.o-grid--gutter-tiny
              .o-grid__group
                .o-grid__item.o-grid__item--grow-0
                  button.c-state-action.c-am-overview__state-action(@click='handleMessages(item.advert.id, item.selectableAddress)')
                    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-mail' y='0')
                    span.o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.S.SeeMessages }}
                .o-grid__item.o-grid__item--grow-0(v-if='canDeleteApplication(item)')
                  button.c-state-action.c-am-overview__state-action(@click='handleDelete(item)')
                    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                    span.o-bodytext.o-bodytext--size-xxsmall {{ Dictionary.C.Cancel }}
    c-pagination(:items='applicationsWithStatus' @changePage='onChangePage')
</template>
<script>
import { mapState } from 'vuex'

import ApplicationsManagementSearch from '../am-search'
import { VueGoodTable } from 'vue-good-table'
import TablePagination from '@/components/table/table-pagination/index'
export default {
  name: 'c-am-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      pageOfItems: [],
      resetPaging: false,
      selectedStatus: null,
      columns: [
        {
          label: this.Dictionary.A.Address,
          field: 'selectableAddress',
          sortFn: this.tableSortAlphabeticaly
        },
        {
          label: this.Dictionary.A.ApplicationDate,
          field: 'created'
        },
        {
          label: this.Dictionary.A.AvailableFrom,
          field: 'advert.availableFrom',
          type: 'text',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.L.Landlord,
          field: 'advert.companyName',
          type: 'text',
          formatFn: this.reFormatOwner
        },
        {
          label: this.Dictionary.S.Status,
          field: 'reFormatedStatus',
          type: 'text'
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '220px'
        }
      ]
    }
  },
  components: {
    VueGoodTable,
    TablePagination,
    'c-am-search': ApplicationsManagementSearch
  },
  computed: {
    ...mapState('applicationsManagement', ['applications', 'pageSize']),
    ...mapState('search', ['facilities']),
    ...mapState('user', ['notifications']),
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.resultsCountText
        ? this.model.resultsCountText.replace('{0}', this.totalFilteredApplications ? this.totalFilteredApplications : 0)
        : this.totalFilteredApplications
    },
    applicationsWithStatus() {
      return this.applications.filter(application => {
        if (application.status === 'Deleted') return false
        else if (this.selectedStatus !== null) {
          let result = false
          switch (this.selectedStatus) {
            case 'New':
              result =
                ['FollowUp', 'Interested', 'ShowingBooked', 'New'].includes(application.status) && application.advert.status !== 'RentedOut'
                  ? application
                  : false
              // result = application.status === 'New' || application.status === 'Interested' || application.status === 'ShowingBooked'
              break
            case 'RentedOutToYou':
              result = application.status === 'RentedOut' && application.advert.status === 'RentedOut' ? application : false
              break
            case 'Offered':
              result = application.status === 'Offered' && application.advert.status !== 'RentedOut' ? application : false
              break
            case 'RentedOutToAnother':
              result =
                ['FollowUp', 'Interested', 'ShowingBooked', 'New', 'Offered'].includes(application.status) && application.advert.status === 'RentedOut'
                  ? application
                  : false
              break
            default:
              result = application.status === this.selectedStatus ? application : false
          }
          return result
        } else {
          return application
        }
      })
    },
    totalFilteredApplications() {
      return this.applicationsWithStatus.length
    }
  },
  methods: {
    canDeleteApplication(application) {
      return (
        !['Deleted'].includes(application.status) && application.advert.residenceId && ['PublishedNotScreened', 'Published'].includes(application.advert.status)
      )
    },
    getApplicantionRowClass(row) {
      return row.status === 'Deleted' ? 'opaque' : ''
    },
    combinedAddress(advert) {
      return this.combineAddress(advert.street, advert.number, advert.letter, advert.floor, advert.door, advert.postalCode, advert.city)
    },
    setStatus(val) {
      this.selectedStatus = val
    },
    reFormatOwner(value) {
      return value || this.Dictionary.P.PrivateLandlord
    },
    getStatusOptions() {
      const applicantStatuses = ['New', 'Offered', 'Cancelled', 'Finished', 'RentedOutToYou', 'RentedOutToAnother']
      const statusOptions = applicantStatuses.map(applicantStatus => {
        return { name: this.reFormatStatus(applicantStatus), value: applicantStatus }
      })

      return statusOptions
    },
    reFormatDate(value) {
      return value ? this.formatDate(value) : ''
    },
    reFormatAdvertStatus(value) {
      let status = false
      switch (value) {
        case 'Reserved':
        case 'RentedOut':
        case 'Retired':
        case 'DeadlineMet':
          status = this.Dictionary.ResidenceAdvertStatus[value]
          break
        default:
          status = false
      }
      return status
    },
    reFormatStatus(value, advertStatus) {
      let status = ''
      switch (value) {
        case 'FollowUp':
        case 'Interested':
        case 'ShowingBooked':
        case 'New':
          status =
            advertStatus === 'RentedOut' || advertStatus === 'RetiredFromRentedOut'
              ? this.Dictionary.ApplicationStatus.RentedOutToAnother
              : this.Dictionary.ApplicationStatus.Applied
          break
        case 'Offered':
          status =
            advertStatus === 'RentedOut' || advertStatus === 'RetiredFromRentedOut'
              ? this.Dictionary.ApplicationStatus.RentedOutToAnother
              : this.Dictionary.ApplicationStatus.OfferedToYou
          break
        case 'Cancelled':
          status = this.Dictionary.ApplicationStatus.Cancelled
          break
        case 'Finished':
          status = this.Dictionary.ApplicationStatus.RejectedByYou
          break
        case 'RentedOut':
          status = this.Dictionary.ApplicationStatus.RentedOutToYou
          break
        case 'Deleted':
          status = this.Dictionary.ApplicationStatus.Deleted
          break
        default:
          status = this.Dictionary.ApplicationStatus[value] || value
      }
      return status
    },
    handleMessages(id, name) {
      this.$store.dispatch('messages/selectThreadRelatedToEntity', { id, name })
    },
    handleDelete(application) {
      this.$store.dispatch('applicationsManagement/toggleConfirmation', {
        id: application.id,
        action: 'delete',
        payload: { residenceId: application.advert.residenceId }
      })
    },
    formatTheDate(value) {
      return this.formatDate(value)
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    }
  },
  created() {
    const applications = this.model.residenceApplications.map(application => {
      application.reFormatedStatus = this.reFormatStatus(application.status, application.advert.status)
      application.selectableAddress = this.combinedAddress(application.advert)
      return application
    })
    this.$store.commit('applicationsManagement/updateData', { applications })
  }
}
</script>
<style lang="scss" scoped>
.c-am-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: #2c4856;
      width: toRem(43px);
      height: toRem(55px);
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }

      td {
        padding-bottom: 5px;
        &:first-child {
          width: 30%;
        }
      }
    }

    &--actions {
      @include max('desktop', -1) {
        padding-top: 10px;
      }
    }

    &--advert-status {
      color: $error-red;
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__state-action {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
