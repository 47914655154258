<template lang="pug">
.c-pm-organization
  .c-pm-organization__top
    button.c-pm-organization__close(@click='close')
      svg.c-pm-organization__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-pm-organization__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-pm-organization__content
    h2.c-pm-organization__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ selected.name }}
    .c-pm-organization__form.o-grid.o-grid--gutter-small
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8.c-pm-organization__details
          c-tabs(:start-tab='0' @change='tabChange')
            c-tab(:disabled='false', :name='model.overviewTabText' key='tab1')
              .c-pm-organization__status
                span.o-bodytext.o-bodytext--700 {{ Dictionary.S.Status }}:&nbsp;
                span.o-bodytext {{ selected.verified ? Dictionary.MembershipOrganizations.Validated : Dictionary.MembershipOrganizations.NotValidated }}
              c-notification-inline.c-pm-organization__notification(:can-close='false' justify='start' size='small' type='warning' v-if='!selected.verified')
                .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.MembershipOrganizations.BeAware }}
                .c-pm-organization__payment-description.o-bodytext.o-bodytext--size-small {{ notificationText }}
              c-notification-inline.c-pm-organization__notification(
                :can-close='false'
                justify='start'
                size='small'
                type=''
                v-if='selected && !!selected.orderStatus'
              )
                .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.P.PaymentStatus }} - {{ Dictionary.PaymentStatus[selected.orderStatus] }}
              .c-pm-organization__parameters
                template(v-for='(section, sectionKey) in dynamicSections')
                  template(v-if='section.dependencyMet')
                    component.c-pm-organization__parameter(
                      :is='`c-pm-p-${transformParameterType(parameter)}`',
                      :key='`${sectionKey}-${parameterKey}`',
                      :model='prepareModel(parameter)'
                      @validate='validate($event, parameter.id)'
                      v-for='(parameter, parameterKey) in section.parameters'
                      v-if='parameter.dependencyMet'
                      v-model='selected.sections[sectionKey].parameters[parameterKey].answer'
                    )
              .c-pm-organization__payment(v-if='showPayment')
                .c-pm-organization__payment-top
                  .c-pm-organization__payment-heading.o-heading.o-heading--size-4 {{ selected.waitingListPaymentHeadline }}
                  .o-bodytext {{ selected.waitingListPaymentDescription }}
                .c-pm-organization__payment-body
                  table.o-table.c-pm-organization__payment-overview
                    tr
                      td {{ selected.paymentStatus.itemName }}
                      td.notranslate {{ selected.paymentStatus.amount.amount | money }}
                    tr.o-bodytext.o-bodytext--700
                      td {{ Dictionary.T.Total }}
                      td.notranslate {{ selected.paymentStatus.amount.amount | money }}
                    tr.o-bodytext.o-bodytext--size-small
                      td {{ Dictionary.O.OfWhichVAT }} {{ selected.paymentStatus.taxRate }}%
                      td.notranslate {{ taxAmount | money }}
                c-pm-p-checkbox.c-pm-organization__payment(:model='paymentTermsData' @validate='validate($event, "paymentTerms")' v-model='paymentTerms.value')
              .c-pm-organization__delete-container(v-if='userIsAssociated')
                button.c-pm-organization__delete-button.o-link(:disabled='deleteProcessing' @click='startRemoveAssociation') {{ Dictionary.MembershipOrganizations.Delete || Dictionary.D.Delete }}
              .c-pm-organization__submit
                button.profile-information__clear.o-link(@click='close') {{ Dictionary.R.Regret }}
                button.o-button(:disabled='!formIsValid || associationProcessing || !canSubmit' @click='submitApplication') {{ Dictionary.MembershipOrganizations.StartVerification }}
            c-tab(:disabled='false', :name='model.paymentHistoryTabText' key='tab2' v-if='selected.paymentStatus.associationStatus !== "NoPaymentNeeded"')
              .c-pm-organization__payment-heading.o-heading.o-heading--size-4 {{ model.paymentHistoryHeadline }}
              .c-pm-organization__payment-description.o-bodytext.o-bodytext--size-small(v-if='model.paymentHistoryDescription') {{ model.paymentHistoryDescription }}
              vue-good-table(
                :columns='paymentHistoryColumns',
                :pagination-options='paginationSettings',
                :rows='paymentsHistory',
                :sort-options='{ enabled: false }',
                :totalRows='0'
                styleClass='o-table'
              )
                template(slot='emptystate') {{ model.historyNoResultsText }}
                template(slot='table-row' slot-scope='props')
                  template(v-if='props.column.field == "firstName"') {{ props.row.firstName }} {{ props.row.lastName }}
                  template(v-if='props.column.field == "message"')
                    span.c-offer-history__message {{ props.row.message }}
                  template(v-else-if='props.column.field == "historyNumber"')
                    button.c-ap-overview__item--name.o-bodytext.o-bodytext--size-small(@click='setSelected(props.row.userId)') {{ props.row.historyNumber }}
                  template(v-else) {{ props.formattedRow[props.column.field] }}
        .o-grid__item.o-grid__item--desktop-4.c-pm-organization__extenstion(v-if='selected.paymentStatus.associationStatus === "PayedExpiring"')
          .c-pm-organization__payment
            .c-pm-organization__payment-top
              .c-pm-organization__payment-heading.o-heading.o-heading--size-4 {{ selected.waitingListPaymentHeadline }}
              .c-pm-organization__payment-description.o-bodytext.o-bodytext--size-small {{ selected.waitingListPaymentDescription }}
            .c-pm-organization__payment-body
              table.o-table.c-pm-organization__payment-overview
                tr
                  td {{ selected.paymentStatus.itemName }}
                  td.notranslate {{ selected.paymentStatus.amount.amount | money }}
                tr.o-bodytext.o-bodytext--700
                  td {{ Dictionary.T.Total }}
                  td {{ selected.paymentStatus.amount.amount | money }}
                tr.o-bodytext.o-bodytext--size-small
                  td {{ Dictionary.O.OfWhichVAT }} {{ selected.paymentStatus.taxRate }}%
                  td.notranslate {{ taxAmount | money }}
              c-pm-p-checkbox.c-pm-organization__payment(
                :model='extensionTermsData'
                @validate='validate($event, "extensionTerms")'
                v-model='extensionTerms.value'
              )
              button.o-button--full-width.c-pm-organization__payment-extend.o-button(
                :disabled='!extensionTerms.value || associationProcessing'
                @click='submitApplication($event, true)'
              ) {{ Dictionary.E.Extend }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ProfileMembershipsParameterChecklist from '@/components/form/controlls/form-checklist'
import ProfileMembershipsParameterCheckbox from '@/components/form/controlls/form-checkbox'
import ProfileMembershipsParameterDate from '@/components/form/controlls/form-date'
import ProfileMembershipsParameterNumber from '@/components/form/controlls/form-number'
import ProfileMembershipsParameterRadioList from '@/components/form/controlls/form-radiolist'
import ProfileMembershipsParameterSelect from '@/components/form/controlls/form-select'
import ProfileMembershipsParameterText from '@/components/form/controlls/form-text'
import ProfileMembershipsParameterUpload from '@/components/form/controlls/form-upload'
import ProfileMembershipsParameterSensitive from '@/components/form/controlls/form-sensitive'
import { membershipOrganizationsService } from '@/_services'
import { getQueryParameters, deleteQueryParameter } from '@/_helpers/query-parameters'
export default {
  name: 'c-pm-organization',

  data() {
    return {
      validation: {},

      selectedTab: 0,
      orderId: undefined,
      paymentTerms: {
        value: null,
        helperText: '',
        placeholderText: '',
        isMandatory: true,
        readOnly: false,
        validation: null
      },
      extensionTerms: {
        value: null,
        helperText: '',
        placeholderText: '',
        isMandatory: false,
        readOnly: false,
        validation: null
      },
      paymentHistoryColumns: [
        {
          label: this.Dictionary.D.Date,
          field: 'created',
          formatFn: this.reFormatDate,
          width: '110px'
        },
        {
          label: this.Dictionary.A.Amount,
          field: 'amount',
          formatFn: this.reFormatAmount
        }
      ],
      paymentsHistory: []
    }
  },
  components: {
    'c-pm-p-checklist': ProfileMembershipsParameterChecklist,
    'c-pm-p-checkbox': ProfileMembershipsParameterCheckbox,
    'c-pm-p-date': ProfileMembershipsParameterDate,
    'c-pm-p-number': ProfileMembershipsParameterNumber,
    'c-pm-p-radiolist': ProfileMembershipsParameterRadioList,
    'c-pm-p-select': ProfileMembershipsParameterSelect,
    'c-pm-p-text': ProfileMembershipsParameterText,
    'c-pm-p-upload': ProfileMembershipsParameterUpload,
    'c-pm-p-sensitive': ProfileMembershipsParameterSensitive,
    VueGoodTable
  },
  props: {
    model: {
      required: true
    }
  },
  computed: {
    ...mapState('membershipOrganizations', ['selected', 'selectedSaved']),
    ...mapGetters('membershipOrganizations', [
      'submitParameters',
      'deleteProcessing',
      'dynamicSections',
      'paymentRequired',
      'parametersAnswersChanged',
      'userIsAssociated'
    ]),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    canSubmit() {
      const paymentTermsAccepted = this.showPayment ? this.paymentTerms.value : true
      return (
        ((this.parametersAnswersChanged || !this.userIsAssociated) && paymentTermsAccepted) || // New or Change and if need payment true
        (this.userIsAssociated && this.showPayment && this.paymentTerms.value) // NoChange but not payed
      )
    },
    notificationText() {
      return (
        this.Dictionary.MembershipOrganizations.MissingVeficationData &&
        this.Dictionary.MembershipOrganizations.MissingVeficationData.replace('{0}', this.selected.name)
      )
    },
    showPayment() {
      const paymentInProgress = this.selected.orderStatus && (this.selected.orderStatus === 'Accepted' || this.selected.orderStatus === 'Initiated')
      return this.paymentRequired && !paymentInProgress
    },
    associationProcessing() {
      const associationProcessingEndpoints = [
        `/parameters/membership-organizations/${this.selected.id}/validate`,
        `/parameters/membership-organizations/${this.selected.id}`,
        `/membership-organizations/${this.selected.id}/associate`
      ]
      return this.loadingEndpoints.some(endpoint => associationProcessingEndpoints.indexOf(endpoint) >= 0)
    },
    paymentTermsData() {
      const paymentTermsData = Object.assign({}, this.paymentTerms)
      paymentTermsData.helperText = this.selected && this.selected.waitingListPaymentTermsHelper
      paymentTermsData.placeholderText = this.selected && this.selected.waitingListPaymentTermsPlaceholder
      return paymentTermsData
    },
    extensionTermsData() {
      const extensionTermsData = Object.assign({}, this.extensionTerms)
      extensionTermsData.helperText = this.selected && this.selected.waitingListPaymentTermsHelper
      extensionTermsData.placeholderText = this.selected && this.selected.waitingListPaymentTermsPlaceholder
      return extensionTermsData
    },
    dynamicSectionsParameters() {
      const dynamicSectionsParameters = []
      this.dynamicSections.forEach(section => {
        section.parameters.forEach(parameter => {
          dynamicSectionsParameters.push(parameter)
        })
      })
      return dynamicSectionsParameters
    },
    formIsValid() {
      return (
        this.validation &&
        Object.keys(this.validation).every(key => {
          const parameter = this.dynamicSectionsParameters.find(p => p.id === key)
          return this.validation[key] === true || (parameter && !parameter.dependencyMet)
        })
      )
    },
    taxAmount() {
      if (this.selected.paymentStatus) {
        const taxPercent = this.selected.paymentStatus.taxRate
        const taxtAmount =
          taxPercent > 0 ? (this.selected.paymentStatus.amount.amount * 100) / (100 + taxPercent) - this.selected.paymentStatus.amount.amount : 0

        return taxtAmount < 0 ? taxtAmount * -1 : taxtAmount
      } else {
        return null
      }
    }
  },
  methods: {
    reFormatDate(value) {
      return value ? this.formatDate(value, 'DD-MM-YYYY') : ''
    },
    reFormatAmount(value) {
      return value ? this.$options.filters.money(value.amount) : ''
    },
    tabChange(tab) {
      this.selectedTab = tab
    },
    close() {
      this.$store.dispatch('membershipOrganizations/removeSelected')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    cleanValidationFromDependencyNotMet() {
      this.dynamicSections.forEach(section => {
        section.parameters.forEach(parameter => {
          if (!parameter.dependencyMet) {
            delete this.validation[parameter.id]
          }
        })
      })
    },
    startRemoveAssociation() {
      this.$store.commit('membershipOrganizations/setConfirmation', {
        action: 'associationRemoval',
        targetId: this.selected.id
      })
    },
    transformParameterType(parameter) {
      let transformedType = ''
      switch (parameter.type) {
        case 'Text':
          if (parameter.validationConfig && parameter.validationConfig.displayRule === '######-####') {
            transformedType = 'sensitive'
          } else {
            transformedType = 'text'
          }
          break
        case 'CheckboxList':
          transformedType = 'checklist'
          break
        case 'Checkbox':
          transformedType = 'checkbox'
          break
        case 'Date':
          transformedType = 'date'
          break
        case 'Dropdown':
          transformedType = 'select'
          break
        case 'Number':
          transformedType = 'number'
          break
        case 'RadioButtonList':
          transformedType = 'radiolist'
          break
        case 'Upload':
          transformedType = 'upload'
          break
      }
      return transformedType
    },
    prepareModel(parameter) {
      const preparedModel = {
        value: parameter.answer,
        helperText: parameter.helperText,
        id: parameter.id,
        isMandatory: parameter.isMandatory,
        readOnly: parameter.isReadOnly,
        placeholderText: parameter.question,
        validation: parameter.validationConfig || {},
        type: parameter.type
      }
      if (parameter.type === 'Number' && parameter.validationConfig.ranges.length > 0) {
        let minVal = 0
        let maxVal = 0
        parameter.validationConfig.ranges.forEach(range => {
          minVal = range.minValue < minVal ? range.minValue : minVal
          maxVal = range.maxValue > maxVal ? range.maxValue : maxVal
        })

        preparedModel.validation.minValue = minVal
        preparedModel.validation.maxValue = maxVal
      }

      if (preparedModel.validation && preparedModel.validation.displayRule === '######-####') {
        preparedModel.validation.hide = 4
      }

      switch (parameter.type) {
        case 'Checkbox':
          preparedModel.returnValue = parameter.id
          break
        case 'CheckboxList':
        case 'RadioButtonList':
          preparedModel.options = parameter.options.map(option => {
            return { value: option.id, name: option.name, required: option.required }
          })
          preparedModel.validation.errorMessage = parameter.errorMessage
          preparedModel.labelText = parameter.question
          break
        case 'Upload':
          preparedModel.answer = parameter.answer ? parameter.answer : []
          preparedModel.fileUrl = this.uploadedFileUrl(parameter)
          break
        case 'Dropdown':
          preparedModel.options = parameter.options.map(option => {
            return { value: option.id, name: option.name, required: option.required }
          })
          preparedModel.validation.errorMessage = parameter.errorMessage
          break
      }
      return preparedModel
    },
    uploadedFileUrl(parameter) {
      return !this.submitParameters[parameter.id] && parameter.answer ? `/api/data/user/parameters/${parameter.id}/file` : null
    },
    async getPayments() {
      try {
        const response = await membershipOrganizationsService.getPayments(this.selected.id)
        if (response && response.status === 200) {
          this.paymentsHistory = response.data
        }
      } catch (e) {
        // console.log(e)
      }
    },
    submitApplication(e, extendingPayment) {
      this.$store.dispatch('membershipOrganizations/validateMembershipOrganizationParameters', {
        isApplication: false,
        callback: false,
        extendingPayment
      })
    },
    getOrderStatus(id) {
      const that = this
      that.$store.dispatch('membershipOrganizations/getOrderStatus', id)
      setTimeout(() => {
        const getOrderStatus = setInterval(() => {
          const alreadyLoading = this.loadingEndpoints.includes(`/membership-organizations/${id}/payment-status`)
          if (that.selected.orderStatus === 'Initiated' || that.selected.orderStatus === undefined) {
            if (!alreadyLoading) {
              that.$store.dispatch('membershipOrganizations/getOrderStatus', id)
            }
          } else {
            clearInterval(getOrderStatus)
          }
        }, 5000)
      }, 2000)
    }
  },
  mounted() {
    if (this.isMobile) document.body.classList.add('u-overflow--hidden')
  },
  destroyed() {
    if (this.isMobile) document.body.classList.remove('u-overflow--hidden')
  },
  created() {
    this.orderId = getQueryParameters().get('orderId') || undefined
    if (this.orderId) {
      this.getOrderStatus(this.orderId)
      deleteQueryParameter('orderId')
      deleteQueryParameter('paymentid')
    }
  },
  watch: {
    selectedTab(newVal) {
      if (newVal === 1) this.getPayments()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-pm-organization {
  $sectionMargin: 35px;
  $sidePadding: 25px;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }

  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }
  &__content {
    padding: 0 $sidePadding $sidePadding;
    overflow: auto;
    height: calc(100% - 62px);
    @include min('desktop') {
      padding-left: 0;
      padding-right: 0;
      height: auto;
      overflow: initial;
    }
  }

  &__extenstion {
    @include max('desktop', -1) {
      order: 1;
    }
  }
  &__details {
    @include max('desktop', -1) {
      order: 2;
    }
  }

  &__payment {
    margin-top: toRem(24px);
    &:first-child {
      margin-top: 0;
    }
    &-top {
    }
    &-body {
      margin-top: toRem(32px);
      background-color: $grey-5;
      padding: toRem(24px 36px);
    }
    &-overview {
      // margin-bottom: toRem(20px);
      td {
        padding-bottom: 10px;
      }
    }
    &-extend {
      margin-top: toRem(24px);
    }

    &-heading,
    &-description {
      margin: toRem(0 0 20px);
    }
  }

  &__delete-container {
    padding: toRem(30px);
    border-top: 1px solid $grey-4;
    border-bottom: 1px solid $grey-4;
    text-align: center;
    margin-top: toRem(40px);

    + .c-pm-organization__submit {
      padding: 0;
      border-top: none;
      margin-top: toRem(20px);
    }
  }
  &__delete-button {
    display: inline-block;
    color: $error-red;
    text-decoration: underline;
    font-family: 'Inria Serif', serif;
    @include setStyles('15', $s-typography-sizes);
    font-weight: 700;
    &:hover {
      color: $error-red;
    }
  }

  &__notification {
    margin-top: $sectionMargin;
  }
  &__status {
  }

  &__parameters {
    margin-top: $sectionMargin;
  }

  &__parameter {
    & + & {
      margin-top: toRem(16px);
    }
  }

  &__submit {
    margin-top: $sectionMargin;
    padding: toRem(30px) 0 0;
    border-top: 1px solid $grey-4;
    justify-content: space-between;
    display: flex;
  }
}
</style>
