<template lang="pug">
.c-display-company-properties
  .o-wrap.o-wrap.o-wrap--max-width-content-wide(:class='{ "o-wrap--padding-side-default": !isMobile }' v-if='model.headline')
    h2.c-display-company-properties__heading.o-heading.o-heading--size-2 {{ model.headline }}
  c-search-result
</template>
<script>
export default {
  name: 'c-display-company-properties',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    companyOrganizationModel() {
      const companies = this.model.companies.map(c => c.replace('{', '').replace('}', ''))
      const organization = this.model.organization.replace('{', '').replace('}', '')
      return {
        companies,
        organization
      }
    }
  },
  methods: {},
  created() {
    this.$store.commit('search/updateData', { propertiesByCompanies: this.companyOrganizationModel })
    this.$store.dispatch('search/getResults')
  }
}
</script>
<style lang="scss" scoped>
.c-display-company-properties {
  padding: 75px 0 0;
  background-color: $grey-5;

  &__heading {
    text-align: center;
    margin-bottom: toRem(map-get($wrappadding, 'phone'));

    @include min('desktop') {
      margin-bottom: 35px;
    }
  }
}
</style>
