<template lang="pug">
.c-om-notes
  .c-om-notes__info.o-grid.o-grid--align-items-start
    .o-grid__group
      .o-grid__item.o-grid__item--7
        c-fc-textbox(
          :model='offerNoteTextData',
          :styleObject='{ "max-width": "100%", resize: true, "min-height": "200px" }'
          @validate='validate($event, "offerNoteText")'
          v-model='offerNoteTextValue'
        )
</template>
<script>
import { mapState } from 'vuex'

import FormControllTextBox from '@/components/form/controlls/form-textbox'
export default {
  name: 'c-om-notes',
  props: {
    model: Object,
    stateBefore: {
      type: Function
    },
    stateAfter: {
      type: Function
    }
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      offerNotes: {
        offerNoteText: {
          helperText: this.model.noteHelperText,
          placeholderText: this.model.notePlaceholderText,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        }
      }
    }
  },
  components: {
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapState('offersManagement', { selectedMutableOffer: 'selectedMutable' }),
    ...mapState('loadingIndicator', ['loading']),
    offerNoteTextData() {
      const offerNoteTextData = Object.assign({}, this.offerNotes.offerNoteText)
      offerNoteTextData.value = this.offerNoteTextValue
      // offerNoteTextData.readOnly = this.stateAfter('Changed')   // Allow to eddit note always
      return offerNoteTextData
    },
    offerNoteTextValue: {
      get: function () {
        return this.selectedMutableOffer.internalNote || ''
      },
      set: function (value) {
        this.$store.commit('offersManagement/updateMutableData', { internalNote: value })
      }
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-om-notes {
}
</style>
