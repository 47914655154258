<template lang="pug">
.c-um-sysadmin
  .c-um-sysadmin__top
    button.c-um-sysadmin__close(@click='close')
      svg.c-um-sysadmin__close-icon.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-left' y='0')
      .c-um-sysadmin__close-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ Dictionary.MembershipOrganizations.BackToOverview }}
  .c-um-sysadmin__content.o-transition__content
    h2.c-um-sysadmin__heading.o-heading.o-heading--size-2.o-heading--profile-page {{ model.userTranslations.newSysadminUserHeadline }}
    form.c-um-sysadmin__form.o-grid.o-grid--gutter-large
      .o-grid__group
        .o-grid__item.o-grid__item--desktop-8
          .c-um-sysadmin__form-section
            .c-um-sysadmin__form-heading.o-heading.o-heading--size-4 {{ model.userTranslations.userDataHeadline }}
            .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
              .o-grid__group
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.firstName' @validate='validate($event, "firstName")' v-model='mutableData.firstName.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.lastName' @validate='validate($event, "lastName")' v-model='mutableData.lastName.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-email(:model='mutableData.email' @validate='validate($event, "email")' v-model='mutableData.email.value')
                .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
                  c-fc-text(:model='mutableData.locale' @validate='validate($event, "locale")' v-model='mutableData.locale.value')
                .o-grid__item.o-grid__item--12
                  c-fc-password(:model='mutableData.password' @validate='validate($event, "password")' v-model='mutableData.password.value')
    .c-um-sysadmin__form.c-um-sysadmin__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
      .o-grid__group
        .o-grid__item.o-grid__item--12.c-um-sysadmin__form-section--border-top
          .o-grid.o-grid--gutter-small.o-grid--align-items-center
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.o-link.c-um-sysadmin__cancel(@click='close') {{ Dictionary.C.Cancel }}
              .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
                button.o-button.c-um-sysadmin__submit(:disabled='!formIsValid' @click='submitChanges') {{ Dictionary.S.SaveAndClose }}
    c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='false' @close='close' v-if='submitted')
      template(slot='header')
        svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
        .o-heading.o-heading--size-2 {{ model.userTranslations.sysadminCreatedMessage }}
</template>
<script>
import { usersManagementService } from '@/_services'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllPassword from '@/components/form/controlls/form-password'
import FormControllEmail from '@/components/form/controlls/form-email'
export default {
  name: 'c-um-sysadmin',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-text': FormControllText,
    'c-fc-password': FormControllPassword,
    'c-fc-email': FormControllEmail
  },
  data() {
    return {
      id: this._uid,
      validation: {},
      submitted: false,
      mutableData: {
        email: {
          helperText: this.model.userTranslations.emailHelperText,
          placeholderText: this.Dictionary.E.Email,
          value: null,
          isMandatory: true,
          autocomplete: 'email',
          readOnly: false,
          validation: {
            errorMessage: this.model.userTranslations.userEmailExistsErrorMessage,
            displayRule: '',
            unique: true
          }
        },
        firstName: {
          helperText: this.model.userTranslations.firstNameHelperText,
          placeholderText: this.Dictionary.F.FirstName,
          value: null,
          isMandatory: true,
          autocomplete: 'given-name',
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        lastName: {
          helperText: this.model.userTranslations.lastNameHelperText,
          placeholderText: this.Dictionary.L.LastName,
          value: null,
          isMandatory: true,
          autocomplete: 'family-name',
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        locale: {
          helperText: this.model.userTranslations.localeHelperText,
          placeholderText: this.Dictionary.L.Locale,
          value: 'da-dk',
          isMandatory: true,
          readOnly: true,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        password: {
          helperText: this.model.userTranslations.passwordHelperText,
          placeholderText: this.Dictionary.P.Password,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: '',
            displayRule: ''
          }
        }
      }
    }
  },
  methods: {
    close() {
      this.$store.dispatch('usersManagement/removeSelected')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    async submitChanges() {
      try {
        const response = await usersManagementService.createSysadminUser(this.getUserData())
          if (response && response.status === 200) {
            this.submitted = true
          }
      } catch (error) {
        //  console.log(error)
      }
    },
    getUserData() {
      const userData = {}
      Object.keys(this.mutableData).forEach(key => {
        userData[key] = this.mutableData[key].value
      })
      return userData
    },
  },
  computed: {
    formIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key
        })
      )
    },
  }
}
</script>
<style lang="scss" scoped>
.c-um-sysadmin {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      box-shadow: none;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__delete {
    text-decoration: underline;
    display: block;
    margin: 0 auto;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }
}
</style>