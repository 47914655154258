<template lang="pug">
.c-rm-owners
  .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-small
    .o-grid__group
      .o-grid__item.o-grid__item--12
        .c-rm-residence__owners-heading.o-heading.o-heading--size-4 {{ model.residenceTranslations.ownersHeadline }}
        .c-rm-residence__owners-description.o-bodytext.o-bodytext--size-small {{ model.residenceTranslations.ownersDescription }}
      .o-grid__item.o-grid__item--6
        vue-good-table(:columns='ownersColumns', :rows='owners', :sort-options='{ enabled: false }' styleClass='o-table')
          template(slot='emptystate') {{ model.noResultsText }}
    template(v-if='isCurrentTurnOwner && selected.freeStatusNotTiedToOfferOrAdvert')
      .o-grid__group
        .o-grid__item.o-grid__item--12
          .c-rm-residence__owners-heading.o-heading.o-heading--size-4 {{ model.residenceTranslations.transferHeadline }}
          .c-rm-residence__owners-description.o-bodytext.o-bodytext--size-small {{ model.residenceTranslations.transferDescription }}
      .o-grid__group
        .o-grid__item.o-grid__item--6
          c-fc-select(:model='ownerTurnData' v-model='ownerTurn.value')
        .o-grid__item.o-grid__item--6
          button.o-button(
            :disabled='ownerTurn.value === null || ownerTurn.value === "null" || ownerTurn.value === this.selected.company'
            @click='showConfirmation = true'
          ) {{ Dictionary.S.Save }}
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='showConfirmation = false' v-if='showConfirmation')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ model.residenceTranslations.transferConfirmationHeadline }}
    template(slot='body')
      .o-bodytext {{ model.residenceTranslations.transferConfirmationDescription }}:
      .o-bodytext {{ ownerName }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='submitNewTurn') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='showConfirmation = false') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import FormControllSelect from '@/components/form/controlls/form-select'
import { residenceManagementService } from '@/_services'
export default {
  name: 'c-rm-owners',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      showConfirmation: false,
      ownersColumns: [
        {
          label: this.Dictionary.N.Number,
          field: 'companyNumber'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'name'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'percentage',
          formatFn: this.reFormatPercentage
        }
      ],
      ownerTurn: {
        helperText: this.model.residenceTranslations.transferHelperText,
        placeholderText: this.model.residenceTranslations.transferPlaceholderText,
        value: null,
        isMandatory: false,
        readOnly: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    VueGoodTable
  },
  computed: {
    ...mapState('residenceManagement', ['selected']),
    owners() {
      const owners = this.selected.owners.filter(owner => owner.percentage > 0)
      return owners
    },
    isCurrentTurnOwner() {
      const isCurrentTurnOwner = this.selected.nextOwnerTurn && this.selected.nextOwnerTurn.companyId === this.selected.companyId
      return isCurrentTurnOwner
    },
    ownerTurnData() {
      const ownerTurnData = Object.assign({}, this.ownerTurn)
      ownerTurnData.readOnly = !this.isCurrentTurnOwner
      ownerTurnData.options = this.owners
        .filter(owner => owner.companyId !== this.selected.company)
        .map(owner => {
          return { name: `${owner.companyNumber} ${owner.name}`, value: owner.companyId }
        })
      if (ownerTurnData.value !== 'null' && ownerTurnData.value !== null) {
        ownerTurnData.options.unshift({
          value: 'null',
          name: this.Dictionary.R.Reset
        })
      }
      return ownerTurnData
    },
    ownerName() {
      return this.owners.find(o => o.companyId === this.ownerTurn.value).name
    }
  },
  methods: {
    async submitNewTurn() {
      try {
        const response = await residenceManagementService.transferTurn({ id: this.selected.id, companyId: this.ownerTurn.value })
        if (response && response.status === 204) {
          this.$store.dispatch('residenceManagement/setSelected', { id: this.selected.id }) // REFRESH RESIDENCE DATA
          this.ownerTurn.value = null
          this.showConfirmation = false
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-rm-owners {
}
</style>
