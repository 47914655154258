<template lang="pug">
.c-verification-jobs-management
  transition(mode='out-in' name='o-transition__fade')
    c-vj-overview(:key='key', :model='model')
</template>
<script>
import VerificationJobsOverview from './vj-overview'
export default {
  name: 'c-verification-jobs-management',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      key: Math.floor(Math.random() * 9999)
    }
  },
  components: {
    'c-vj-overview': VerificationJobsOverview
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>
<style lang="scss" scoped>
.c-verification-jobs-management {
}
</style>
