import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (value, format = 'DD-MM-YYYY') {
  if (value) {
    return moment.utc(String(value)).format(format)
  }
})

Vue.filter('timeToDate', function (value, format) {
  const datetime = moment.utc(value, format)._d
  return datetime
})

Vue.filter('prettyBytes', function (bytes, decimals, kib, maxunit) {
  kib = kib || true
  if (bytes === 0) return '0 Bytes'
  if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number'
  const k = kib ? 1024 : 1000
  const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2
  const sizes = kib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB', 'BiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  if (maxunit !== undefined) {
    const index = sizes.indexOf(maxunit)
    if (index !== -1) i = index
  }
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
})

Vue.filter('money', (value, range) => {
  if (range) {
    const formatedValue = isNaN(value) || value === null || value === undefined ? '' : value.toFixed().replace(/\d(?=(\d{3})+(\.|$))/g, '$&.')
    const formatedRange = isNaN(range) || range === null || range === undefined ? '' : range.toFixed().replace(/\d(?=(\d{3})+(\.|$))/g, '$&.')

    return `${formatedValue} - ${formatedRange},-`
  } else {
    return isNaN(value) || value === null || value === undefined ? '' : value.toFixed().replace(/\d(?=(\d{3})+(\.|$))/g, '$&.') + ',-'
  }
})
