<template lang="pug">
.c-applicant-status
  .c-applicant-status__form.o-grid.o-grid--gutter-large
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.internalNotesHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--6
                c-fc-select(:model='administratorData' @validate='validate($event, "administrator")' v-model='selectedApplicantAdministratorIdValue')
              .o-grid__item.o-grid__item--12
                c-fc-textbox(:model='mutableData.note' @validate='validate($event, "note")' v-model='mutableData.note.value')
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.affiliationInformationHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--6
                c-fc-select(:model='statusForData' @validate='validate($event, "statusFor")' v-model='selectedApplicantMembershipOrganizationIdValue')
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-bodytext.o-bodytext--size-large {{ model.applicanStatusTranslations.applicantVerificationHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small.o-grid--align-items-start
            .o-grid__group
              .o-grid__item.o-grid__item--6
                c-fc-select(
                  :key='busterKey',
                  :model='verificationStatusData'
                  @validate='validate($event, "verificationStatus")'
                  v-model='mutableData.verificationStatus.value'
                )
              .o-grid__item.o-grid__item--6
                .c-applicant-status__timestamp.o-bodytext.o-bodytext--size-small(v-if='verificationApprovedDate') {{ Dictionary.V.VerificationTime }}: {{ verificationApprovedDate | formatDate('DD-MM-YYYY') }}
              .o-grid__item.o-grid__item--6
                c-fc-select(
                  :key='busterKey',
                  :model='documentStatusData'
                  @validate='validate($event, "documentStatus")'
                  v-model='mutableData.documentStatus.value'
                )
              .o-grid__item.o-grid__item--6
                .c-applicant-status__timestamp.o-bodytext.o-bodytext--size-small(v-if='documentApprovedDate') {{ Dictionary.V.VerificationTime }}: {{ documentApprovedDate | formatDate('DD-MM-YYYY') }}
              .o-grid__item.o-grid__item--6
                c-fc-select(:model='categoriesData' @validate='validate($event, "categories")' v-model='mutableData.categories.value')
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-bodytext.o-bodytext--size-large {{ model.applicanStatusTranslations.memberSeniorityHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--4
                c-fc-date(
                  :model='mutableData.memberSeniorityDate'
                  @validate='validate($event, "memberSeniorityDate")'
                  v-model='mutableData.memberSeniorityDate.value'
                )
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-bodytext.o-bodytext--size-large {{ model.applicanStatusTranslations.waitingListSeniorityHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--4
                c-fc-date(
                  :model='mutableData.listSeniorityDate'
                  @validate='validate($event, "listSeniorityDate")'
                  v-model='mutableData.listSeniorityDate.value'
                )
        .c-applicant-status__form-section
          .c-applicant-status__form-heading.o-bodytext.o-bodytext--size-large {{ model.applicanStatusTranslations.quarantineHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group
              .o-grid__item.o-grid__item--4
                c-fc-date(:model='quarantineStartDateData' @validate='validate($event, "quarantineStartDate")' v-model='mutableData.quarantineStartDate.value')
              .o-grid__item.o-grid__item--4
                c-fc-date(:model='quarantineEndDateData' @validate='validate($event, "quarantineEndDate")' v-model='mutableData.quarantineEndDate.value')
        .c-applicant-status__form-section(
          v-if='selectedMembershipOrganization && (selectedMembershipOrganization.userPointBalanceWaitingList > 0 || selectedMembershipOrganization.userPointBalanceMembershipSeniority > 0)'
        )
          .c-applicant-status__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.pointsHeadline }}
          .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
            .o-grid__group(v-if='selectedMembershipOrganization.userPointBalanceWaitingList > 0')
              .o-grid__item.o-grid__item--8 {{ model.applicanStatusTranslations.waitingListPointsText }}
              .o-grid__item.o-grid__item--4 {{ selectedMembershipOrganization.userPointBalanceWaitingList }}
            .o-grid__group(v-if='selectedMembershipOrganization.userPointBalanceMembershipSeniority > 0')
              .o-grid__item.o-grid__item--8 {{ model.applicanStatusTranslations.membershipSeniorityPointsText }}
              .o-grid__item.o-grid__item--4 {{ selectedMembershipOrganization.userPointBalanceMembershipSeniority }}

        .c-applicant-status__form-section(v-if='selectedMembershipOrganizationParameters.length > 0 && !parametersLoading')
          .c-applicant-status__form-heading.o-heading.o-heading--size-4 {{ model.applicanStatusTranslations.applicationInformationHeadline }}
          .c-applicant-status__parameters-section(
            :key='`parameter-section-${parameterSectionIndex}`'
            v-for='(parameterSection, parameterSectionIndex) in selectedMembershipOrganizationParameters'
          )
            .c-applicant-status__parameters-heading.o-bodytext.o-bodytext--size-large(v-if='parameterSection.name') {{ parameterSection.name }}
            .o-grid.o-grid--gutter-horizontal-small
              .o-grid__group(:key='`parameter-${parameterIndex}`' v-for='(parameter, parameterIndex) in parameterSection.parameters')
                .o-grid__item.o-grid__item--4
                  .o-bodytext.o-bodytext--size-small {{ parameter.question }}
                .o-grid__item.o-grid__item--4
                  .c-applicant-status__parameters-answer.o-bodytext.o-bodytext--size-small(
                    v-if='parameter.validationConfig && parameter.validationConfig.displayRule === "######-####"'
                    v-sensitiveInfo:4='getAnswerFromParameter(parameter)'
                  )
                  component.c-applicant-status__parameters-answer.o-bodytext.o-bodytext--size-small(
                    :href='`/api/data/management/applicant-status/${selected.userId}/parameters/${parameter.id}/file`',
                    :is='parameter.type === "Upload" && parameter.answer ? "a" : "div"'
                    download
                    v-else
                  ) {{ getAnswerFromParameter(parameter) }}
                .o-grid__item.o-grid__item--4
                  .c-applicant-status__parameters-answer.o-bodytext.o-bodytext--size-small {{ getPoints(parameter) }}
        .c-applicant-status__form-section(v-else-if='parametersLoading')
          c-loading-animation(:overlay='false')
  .c-applicant-status__form.c-applicant-status__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow
    .o-grid__group
      .o-grid__item.o-grid__item--12.c-applicant-status__form-section--border-top
        .o-grid.o-grid--gutter-small.o-grid--align-items-center
          .o-grid__group
            .o-grid__item.o-grid__item--grow-0
              button.o-link.c-applicant-status__cancel(@click='close') {{ Dictionary.C.Cancel }}
            .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
              button.o-button.c-applicant-status__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(false)') {{ Dictionary.S.Save }}
              button.o-button.c-applicant-status__submit(:disabled='!formIsValid || loading || !dataChanged' @click='submitChanges(true)') {{ Dictionary.S.SaveAndClose }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import FormControllDate from '@/components/form/controlls/form-date'
import vMultiselectListbox from 'vue-multiselect-listbox'
import { applicantsManagementService, membershipOrganizationsService } from '@/_services'
import moment from 'moment'

export default {
  name: 'c-applicant-status',
  props: {
    model: {
      type: Object,
      required: true
    },
    administrator: {
      type: Object,
      required: true
    },
    statusFor: {
      type: Object,
      required: true
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-textbox': FormControllTextBox,
    'c-fc-date': FormControllDate,
    'v-multiselect-listbox': vMultiselectListbox
  },
  data() {
    return {
      id: this._uid,

      busterKey: 0,
      validation: {},
      parametersLoading: false,
      selectedMembershipOrganizationParameters: [],
      selectedMembershipOrganizationCategories: [],
      mutableData: {
        // administrator: {
        //   helperText: this.model.applicanStatusTranslations.internalNotesForHelper,
        //   value: null,
        //   placeholderText: this.Dictionary.I.InternalNotesFor,
        //   isMandatory: false,
        //   readOnly: false,
        //   options: [],
        //   validation: null
        // },
        note: {
          helperText: this.model.applicanStatusTranslations.internalNoteHelper,
          placeholderText: this.Dictionary.I.InternalNote,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        // statusFor: {
        //   helperText: this.model.applicanStatusTranslations.statusForHelper,
        //   placeholderText: this.Dictionary.S.StatusFor,
        //   value: null,
        //   isMandatory: false,
        //   readOnly: true,
        //   options: []
        // },
        verificationStatus: {
          helperText: this.model.applicanStatusTranslations.verificationStatusHelper,
          placeholderText: this.Dictionary.V.VerificationStatus,
          value: null,
          isMandatory: false,
          readOnly: true,
          options: []
        },
        documentStatus: {
          helperText: this.model.applicanStatusTranslations.documentationStatusHelper,
          placeholderText: this.Dictionary.D.DocumentStatus,
          value: null,
          isMandatory: false,
          readOnly: true,
          options: []
        },
        categories: {
          helperText: this.model.applicanStatusTranslations.categoryHelper,
          placeholderText: this.Dictionary.C.Category,
          value: null,
          isMandatory: false,
          readOnly: true,
          options: []
        },
        memberSeniorityDate: {
          helperText: this.model.applicanStatusTranslations.memberSeniorityHelper,
          placeholderText: this.Dictionary.F.From,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {
            errorMessage: '',
            displayRule: ''
          }
        },
        listSeniorityDate: {
          helperText: this.model.applicanStatusTranslations.waitingListSeniorityHelper,
          placeholderText: this.Dictionary.F.From,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {
            errorMessage: '',
            displayRule: ''
          }
        },
        quarantineStartDate: {
          helperText: this.model.applicanStatusTranslations.quarantineHelper,
          placeholderText: this.Dictionary.F.From,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {}
        },
        quarantineEndDate: {
          helperText: this.model.applicanStatusTranslations.quarantineHelper,
          placeholderText: this.Dictionary.T.To,
          value: null,
          isMandatory: false,
          readOnly: true,
          validation: {}
        }
      }
    }
  },
  computed: {
    ...mapState('applicantsManagement', ['selected', 'facets', 'selectedApplicantAdministratorId', 'selectedApplicantMembershipOrganizationId']),
    ...mapState('usersManagement', ['administrators']),
    ...mapState('loadingIndicator', ['loading']),
    administratorData() {
      const administratorData = this.administrator
      // administratorData.value = this.selectedApplicantAdministratorId
      return administratorData
    },
    statusForData() {
      const statusForData = this.statusFor
      // statusForData.value = this.selectedApplicantMembershipOrganizationId
      return statusForData
    },
    selectedApplicantAdministratorIdValue: {
      get: function () {
        return this.selectedApplicantAdministratorId
      },
      set: function (value) {
        this.$store.commit('applicantsManagement/updateData', { selectedApplicantAdministratorId: value })
      }
    },
    selectedApplicantMembershipOrganizationIdValue: {
      get: function () {
        return this.selectedApplicantMembershipOrganizationId
      },
      set: function (value) {
        this.$store.commit('applicantsManagement/updateData', { selectedApplicantMembershipOrganizationId: value })
      }
    },
    selectedAdministrator() {
      return this.administrators.find(administrator => administrator.id === this.selectedApplicantAdministratorIdValue)
    },
    formIsValid() {
      return this.dataIsValid
    },
    dataIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key === true
        })
      )
    },
    dataChanged() {
      return this.selectedMembershipOrganizationDataChanged
    },
    selectedMembershipOrganizationDataChanged() {
      let dataChanged = false
      const target = Object.keys(this.mutableData).find(key => {
        let different = false
        switch (key) {
          case 'administrator':
          case 'statusFor':
            break
          case 'verificationStatus':
            different = !!this.mutableData[key].value && parseInt(this.mutableData[key].value) !== this.getInitialVerificationStatus()
            break
          case 'documentStatus':
            different = !!this.mutableData[key].value && parseInt(this.mutableData[key].value) !== this.getInitialDocumentStatus()
            break
          case 'memberSeniorityDate':
            different =
              !!this.selectedMembershipOrganization &&
              this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD') !==
                this.formatDate(this.selectedMembershipOrganization.memberSeniorityDate, 'YYYY-MM-DD')
            break
          case 'listSeniorityDate':
            different =
              !!this.selectedMembershipOrganization &&
              this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD') !==
                this.formatDate(this.selectedMembershipOrganization.listSeniorityDate, 'YYYY-MM-DD')
            break
          case 'quarantineStartDate':
          case 'quarantineEndDate':
            different =
              !!this.selectedMembershipOrganization &&
              this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD') !== this.formatDate(this.selectedMembershipOrganization[key], 'YYYY-MM-DD')
            break
          case 'categories':
            different =
              !!this.selectedMembershipOrganization &&
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== this.selectedMembershipOrganization[key][0]
            break
          case 'note':
            different =
              this.mutableData[key].value !== undefined &&
              this.mutableData[key].value !== null &&
              this.mutableData[key].value !== this.selected.notes[this.selectedApplicantAdministratorId]
            break
          default:
            different = this.mutableData[key].value !== undefined && this.mutableData[key].value !== null && this.mutableData[key].value !== this.selected[key]
        }
        return different
      })
      // console.log(target)
      dataChanged = !!target
      return dataChanged
    },
    selectedMembershipOrganization() {
      return this.selected.membershipOrganizations.find(membershipOrganization => membershipOrganization.id === this.selectedApplicantMembershipOrganizationId)
    },
    verificationStatusData() {
      const verificationStatusData = Object.assign({}, this.mutableData.verificationStatus)
      verificationStatusData.options = [
        {
          name: this.Dictionary.U.UnderVerification,
          value: 0
        },
        {
          name: this.Dictionary.V.VerifiedApproved,
          value: 1
        },
        { name: this.Dictionary.V.VerifiedRejected, value: 2 }
      ]

      return verificationStatusData
    },
    documentStatusData() {
      const documentStatusData = Object.assign({}, this.mutableData.documentStatus)
      documentStatusData.options = [
        {
          name: this.Dictionary.N.NotVerified,
          value: 0
        },
        {
          name: this.Dictionary.V.VerifiedApproved,
          value: 1
        },
        { name: this.Dictionary.V.VerifiedRejected, value: 2 }
      ]

      return documentStatusData
    },
    categoriesData() {
      const categoriesData = Object.assign({}, this.mutableData.categories)
      categoriesData.options =
        (this.selectedMembershipOrganizationCategories.length > 0 &&
          this.selectedMembershipOrganizationCategories.map(category => {
            return { value: category, name: category }
          })) ||
        []

      return categoriesData
    },
    verificationApprovedDate() {
      let date
      if (this.selectedMembershipOrganization && this.selectedMembershipOrganization.verified) {
        date = this.selectedMembershipOrganization.verifiedApprovedDate
          ? this.selectedMembershipOrganization.verifiedApprovedDate
          : this.selectedMembershipOrganization.verifiedDate
      }
      return date
    },
    documentApprovedDate() {
      let date
      if (this.selectedMembershipOrganization && this.selectedMembershipOrganization.documentationVerified) {
        date = this.selectedMembershipOrganization.documentationApprovedDate
          ? this.selectedMembershipOrganization.documentationApprovedDate
          : this.selectedMembershipOrganization.documentationVerifiedDate
      }
      return date
    },
    quarantineStartDateData() {
      const quarantineStartDateData = Object.assign({}, this.mutableData.quarantineStartDate)
      quarantineStartDateData.validation.maxDate = this.mutableData.quarantineEndDate.value
      return quarantineStartDateData
    },
    quarantineEndDateData() {
      const quarantineEndDateData = Object.assign({}, this.mutableData.quarantineEndDate)
      quarantineEndDateData.validation.minDate = this.mutableData.quarantineStartDate.value
      return quarantineEndDateData
    }
  },
  methods: {
    close() {
      this.$store.dispatch('membershipOrganizations/removeSelected')
      this.$store.dispatch('applicantsManagement/removeSelected')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    submitChanges(closeSelection) {
      this.$store.dispatch('applicantsManagement/patchApplicantStatus', { data: this.getPatchApplicantStatusData(), closeSelection })
    },
    getPatchApplicantStatusData() {
      const selectedMembershipOrganizationData = {}

      if (this.selectedMembershipOrganization) {
        selectedMembershipOrganizationData.id = this.selectedMembershipOrganization.id

        Object.keys(this.mutableData).forEach(key => {
          switch (key) {
            case 'verificationStatus':
              if (parseInt(this.mutableData[key].value) > 0) {
                selectedMembershipOrganizationData.verified = true

                selectedMembershipOrganizationData.verifiedDate = this.selectedMembershipOrganization.verifiedDate || moment.utc().toDate().toISOString()

                if (parseInt(this.mutableData[key].value) === 1) {
                  selectedMembershipOrganizationData.verifiedApprovedDate =
                    this.selectedMembershipOrganization.verifiedApprovedDate || moment.utc().toDate().toISOString()
                } else {
                  selectedMembershipOrganizationData.verifiedApprovedDate = null
                }
              } else {
                selectedMembershipOrganizationData.verified = false
                selectedMembershipOrganizationData.verifiedDate = null
                selectedMembershipOrganizationData.verifiedApprovedDate = null
              }
              break
            case 'documentStatus':
              if (parseInt(this.mutableData[key].value) > 0) {
                selectedMembershipOrganizationData.documentationVerified = true

                selectedMembershipOrganizationData.documentationVerifiedDate =
                  this.selectedMembershipOrganization.documentationVerifiedDate || moment.utc().toDate().toISOString()

                if (parseInt(this.mutableData[key].value) === 1) {
                  selectedMembershipOrganizationData.documentationApprovedDate =
                    this.selectedMembershipOrganization.documentationApprovedDate || moment.utc().toDate().toISOString()
                } else {
                  selectedMembershipOrganizationData.documentationApprovedDate = null
                }
              } else {
                selectedMembershipOrganizationData.documentationVerified = false
                selectedMembershipOrganizationData.documentationVerifiedDate = null
                selectedMembershipOrganizationData.documentationApprovedDate = null
              }
              break
            case 'categories':
              selectedMembershipOrganizationData.categories = this.mutableData[key].value ? [this.mutableData[key].value] : []
              break
            case 'memberSeniorityDate':
              selectedMembershipOrganizationData.memberSeniorityDate = this.mutableData[key].value
                ? this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD')
                : null
              break
            case 'listSeniorityDate':
              selectedMembershipOrganizationData.listSeniorityDate = this.mutableData[key].value
                ? this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD')
                : null
              break
            case 'quarantineStartDate':
            case 'quarantineEndDate':
              selectedMembershipOrganizationData[key] = this.mutableData[key].value ? this.formatDate(this.mutableData[key].value, 'YYYY-MM-DD') : null
              break
          }
        })
      }

      const patchApplicantStatusData = this.selectedMembershipOrganization ? { membershipOrganizations: [selectedMembershipOrganizationData] } : {}

      if (this.mutableData.note.value !== null && this.mutableData.note.value !== this.selected.notes[this.selectedAdministrator.id]) {
        patchApplicantStatusData.notes = { [this.selectedApplicantAdministratorId]: this.mutableData.note.value }
      }
      return patchApplicantStatusData
    },
    setReadOnly() {
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'note':
            this.mutableData[key].readOnly = !this.selectedApplicantAdministratorId
            break
          case 'verificationStatus':
          case 'documentStatus':
          case 'categories':
          case 'memberSeniorityDate':
          case 'listSeniorityDate':
          case 'quarantineStartDate':
          case 'quarantineEndDate':
            this.mutableData[key].readOnly = !this.selectedApplicantMembershipOrganizationId
            break
          default:
            this.mutableData[key].readOnly = false
        }
      })
    },
    async setDataFromSelectedMembershipOrganization() {
      Object.keys(this.mutableData).forEach(key => {
        switch (key) {
          case 'verificationStatus':
            this.mutableData[key].value = this.getInitialVerificationStatus()
            break
          case 'documentStatus':
            this.mutableData[key].value = this.getInitialDocumentStatus()
            break
          case 'categories':
            this.mutableData[key].value =
              !!this.selectedMembershipOrganization && this.selectedMembershipOrganization[key] && this.selectedMembershipOrganization[key][0]
                ? this.selectedMembershipOrganization[key][0]
                : null
            break
          case 'memberSeniorityDate':
            this.mutableData[key].value =
              (!!this.selectedMembershipOrganization &&
                this.selectedMembershipOrganization.memberSeniorityDate &&
                this.formatDate(this.selectedMembershipOrganization.memberSeniorityDate, 'YYYY-MM-DD')) ||
              null
            break
          case 'listSeniorityDate':
            this.mutableData[key].value =
              (!!this.selectedMembershipOrganization &&
                this.selectedMembershipOrganization.listSeniorityDate &&
                this.formatDate(this.selectedMembershipOrganization.listSeniorityDate, 'YYYY-MM-DD')) ||
              null
            break
          case 'quarantineStartDate':
          case 'quarantineEndDate':
            this.mutableData[key].value =
              (!!this.selectedMembershipOrganization &&
                this.selectedMembershipOrganization[key] &&
                this.formatDate(this.selectedMembershipOrganization[key], 'YYYY-MM-DD')) ||
              null
            break
        }
      })
      this.getMemberOrgCategories()
      this.getApplicantsParameters()
    },
    async getApplicantsParameters() {
      try {
        this.parametersLoading = true
        const response = await applicantsManagementService.getApplicantsParameters({
          userId: this.selected.userId,
          moId: this.selectedApplicantMembershipOrganizationId
        })
        if (response && response.status === 200) {
          this.selectedMembershipOrganizationParameters = response.data
        }
        this.parametersLoading = false
      } catch (error) {
        // console.log(error)
      }
    },
    async getMemberOrgCategories() {
      try {
        const response = await membershipOrganizationsService.getMembershipOrganizationCategories(this.selectedApplicantMembershipOrganizationId)
        if (response && response.status === 200) {
          this.selectedMembershipOrganizationCategories = response.data
        } else {
          this.selectedMembershipOrganizationCategories = []
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getPoints(parameter) {
      return this.selected.userPointBalance[parameter.id] !== undefined ? `${this.selected.userPointBalance[parameter.id]} ${this.Dictionary.P.Points}` : ''
    },
    getInitialVerificationStatus() {
      let initialVerificationStatus = null
      if (this.selectedMembershipOrganization) {
        initialVerificationStatus = !this.selectedMembershipOrganization.verified
          ? 0
          : this.selectedMembershipOrganization.verified && this.selectedMembershipOrganization.verifiedApprovedDate
          ? 1
          : 2
      }

      return initialVerificationStatus
    },
    getInitialDocumentStatus() {
      let initialDocumentStatus = null
      if (this.selectedMembershipOrganization) {
        initialDocumentStatus = !this.selectedMembershipOrganization.documentationVerified
          ? 0
          : this.selectedMembershipOrganization.documentationVerified && this.selectedMembershipOrganization.documentationApprovedDate
          ? 1
          : 2
      }
      return initialDocumentStatus
    },
    getInitialData() {
      if (this.administrators.length === 0) {
        this.$store.dispatch('usersManagement/getPropertyAdministrators')
      }
    },
    resetAfterAdminChange() {
      this.mutableData.note.value = (this.selectedAdministrator && this.selected.notes[this.selectedAdministrator.id]) || null
      this.mutableData.categories.value = null
      this.mutableData.documentStatus.value = null
      this.mutableData.quarantineStartDate.value = null
      this.mutableData.quarantineEndDate.value = null
      this.selectedApplicantMembershipOrganizationIdValue = null
      this.mutableData.verificationStatus.value = null
      this.mutableData.memberSeniorityDate.value = null
      this.mutableData.listSeniorityDate.value = null
      this.selectedMembershipOrganizationParameters = []
    }
  },
  created() {
    this.getInitialData()
  },

  watch: {
    selectedApplicantAdministratorId(newVal) {
      this.setReadOnly()
      if (newVal) {
        this.resetAfterAdminChange()
      }
    },
    selectedApplicantMembershipOrganizationId(newVal, oldVal) {
      if (!oldVal) {
        this.busterKey++
      }
      this.setReadOnly()
      if (newVal) {
        this.setDataFromSelectedMembershipOrganization()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-applicant-status {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;

  &__heading {
    border-bottom: none;
    margin-bottom: 0;
  }
  &__cancel {
    text-decoration: underline;
    display: block;
  }
  &__submit {
    & + & {
      margin-left: 20px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 20px;
    }
  }

  &__timestamp {
    margin-top: 15px;
  }

  &__parameters {
    &-heading {
      margin-bottom: 10px;
    }
    &-section {
      margin-bottom: 15px;
    }
    &-answer {
      color: $grey-3;
    }
  }
}
</style>
