<template lang="pug">
.c-favorites
  .c-favorites__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-heart' y='0')
    h1 {{ model.headline }}
  .c-favorites__filter.o-grid.o-grid--gutter-tiny.o-grid--align-items-center
    .o-grid__group
      .c-favorites__options.o-grid__item.o-grid__item--12.o-grid__item--desktop-6
        .c-favorites__option
          input#favorites-filter-1.o-input(name='favorites-filter' type='radio' v-model='filter' value='all')
          label.o-label(for='favorites-filter-1') {{ Dictionary.Filter.All }}
        .c-favorites__option(v-if='residencesCount > 0')
          input#favorites-filter-2.o-input(name='favorites-filter' type='radio' v-model='filter' value='residence')
          label.o-label(for='favorites-filter-2') {{ Dictionary.Filter.Residences }} ({{ residencesCount }})
        .c-favorites__option(v-if='propertyCount > 0')
          input#favorites-filter-3.o-input(name='favorites-filter' type='radio' v-model='filter' value='property')
          label.o-label(for='favorites-filter-3') {{ Dictionary.Filter.Properties }} ({{ propertyCount }})
        .c-favorites__option(v-if='projectsCount > 0')
          input#favorites-filter-4.o-input(name='favorites-filter' type='radio' v-model='filter' value='project')
          label.o-label(for='favorites-filter-4') {{ Dictionary.Filter.Projects }} ({{ projectsCount }})
      .o-grid__item.o-grid__item--grow-0.c-favorites__map-button
        c-state-toggle(
          :options='[ { labelText: "Kort", icon: "pin" }, { labelText: "Liste", icon: "list" }, ]'
          action='toggleMap'
          property='mapShowing'
          store='search'
        )
      .o-grid__item.o-grid__item--grow-0(v-if='!mapShowing')
        select.c-favorites__input.o-input.o-input--small.o-input--inria(:id='`${id}-sorting`' name='orderBy' placeholder=' ' v-model='sortingValue')
          option(disabled hidden selected value='') {{ Dictionary.S.Sort }}
          option(:value='option.value' v-for='option in sortingOptions') {{ option.labelText }}

  .c-favorites__container.o-grid.o-grid--direction-row.o-grid--align-items-stretch.o-grid--justify-content-start.o-grid--gutter-small(v-if='!mapShowing')
    transition-group.o-grid__group(mode='out-in' name='o-transition__fade' v-if='!loading')
      .o-grid__item.o-grid__item--12.o-grid__item--desktop-6(
        :key='`property-${key}`'
        v-for='(property, key) in favoritesDataMerged'
        v-show='favoritesMerged.includes(property.id) && filterFavorites(property)'
      )
        .c-favorites__wrap
          .c-favorites__unavailiable(v-if='!property.isAvailable')
            .o-bodytext.o-bodytext--600.o-bodytext--size-large {{ model.entityNotAvailable }}
            button.o-link(@click='removeFavorite(property.id, property.data.$type)') {{ Dictionary.R.RemoveFromMyList }}
          c-property-card(:disabled='!property.isAvailable', :facilities='facilities', :key='property.data.id', :property='property.data')
    .c-search-result__loading(v-else)
      c-loading-animation(:overlay='true')
  .property-map(v-else)
    PropertyMap(:model='mapFavorites.results' showOnlyFavorites)
</template>
<script>
// import { userService } from '@/_services'
import { mapState } from 'vuex'
import PropertyMap from '@/components/property-map/index'
import moment from 'moment'
export default {
  name: 'c-favorites',
  props: {
    model: Object
  },
  components: { PropertyMap },
  data() {
    return {
      filter: 'all',
      favoritesData: {},
      mapModel: [],
      id: this._uid,
      sortingValue: '',
      sortingOptions: [
        { value: { orderBy: '', orderDirection: 'ASC' }, labelText: this.Dictionary.Sorting.OldestFirst },
        { value: { orderBy: '', orderDirection: 'DESC' }, labelText: this.Dictionary.Sorting.NewestFirst }
      ]
    }
  },
  computed: {
    ...mapState('user', ['favorites']),
    ...mapState('search', ['mapShowing', 'mapFavorites', 'facilities']),
    ...mapState('loadingIndicator', ['loading']),
    propertyCount: function () {
      return this.favorites.properties.length
    },
    residencesCount: function () {
      return this.favorites.residences.length
    },
    projectsCount: function () {
      return this.favorites.projects && this.favorites.projects.length
    },
    favoritesDataMerged() {
      const favoritesDataMerged = [...this.model.favorites.properties, ...this.model.favorites.residences, ...this.model.favorites.projects]
      favoritesDataMerged.sort(this.compare)
      return favoritesDataMerged
    },
    mapFilteredFavorites() {
      const mapFilteredFavorites = {}

      Object.keys(this.favoritesData).forEach(category => {
        const filteredCategory = this.favoritesData[category].filter(item => this.filterFavorites(item))
        mapFilteredFavorites[category] = filteredCategory
      })
      return mapFilteredFavorites
    },
    favoritesMerged() {
      const favoritesMerged = [...this.favorites.properties, ...this.favorites.residences, ...this.favorites.projects]
      return favoritesMerged
    }
  },
  methods: {
    removeFavorite(id, type) {
      this.$store.dispatch('user/removeFavorite', {
        payload: id,
        type
      })
    },
    prepareMapData(data) {
      if (data.projects.length > 0 || data.properties.length > 0 || data.residences.length > 0) this.$store.dispatch('search/getMapFavorites', data)
    },
    getFavoritesData() {
      this.favoritesData = this.model.favorites
      this.prepareMapData(this.favoritesData)
    },
    compare(a, b) {
      const targetA = moment.utc(this.sortingValue ? a.data.created : a.created)
      const targetB = moment.utc(this.sortingValue ? b.data.created : b.created)
      if (targetA.isBefore(targetB)) {
        return this.sortingValue.orderDirection === 'ASC' ? -1 : 1
      }
      if (targetA.isAfter(targetB)) {
        return this.sortingValue.orderDirection === 'ASC' ? 1 : -1
      }
      return 0
    },
    filterFavorites(item) {
      return this.filter === 'all' || item.data.$type.toLowerCase() === this.filter
    }
  },
  created() {
    // patch model since favorites controller does not serialize models with $type attribute
    Object.values(this.model.favorites).forEach(data => data.forEach(entry => (entry.data.$type = entry.data.type)))

    this.getFavoritesData()
  },
  watch: {
    filter() {
      this.prepareMapData(this.mapFilteredFavorites)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-favorites {
  height: 100%;

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    width: 100%;
    overflow-x: auto;
    @include min('desktop') {
      width: auto;
    }
  }

  &__option {
    outline: 1px solid $ocean-primary;
    margin: 0;
    white-space: nowrap;

    & + & {
      border-left: 1px solid $ocean-primary;
    }

    .o-input[type='radio'] {
      &:checked {
        + label {
          background-color: $ocean-primary;
          color: $white;
        }
      }
      + label {
        padding: 6px 14px;
        font-family: 'Inria Serif', serif;
        background-color: $white;
        color: $ocean-primary;
        line-height: toRem(26px);
        font-weight: 700;

        &::before,
        &:after {
          display: none;
        }
      }
      &:focus + label {
        outline: 1px solid $ocean-primary;
        outline-offset: 3px;
      }
    }
  }
  &__filter {
    margin-bottom: toRem(10px);
    @include min('desktop') {
      margin-bottom: toRem(30px);
    }
  }
  &__map-button {
    @include min('desktop') {
      margin-left: auto;
    }
  }

  &__wrap {
    position: relative;
  }

  &__unavailiable {
    padding: 45px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($white, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    &-cta {
      margin-top: 20px;
      background-color: transparent;
      border: none;
      text-decoration: underline;
    }
  }

  &__container {
    min-height: 280px;
    position: relative;
  }

  &__input {
    @include max('desktop', -1) {
      border: none;
      background-image: url(map-get($sprites, 'icon-sorting'));
      background-position: 5px center;
      padding: 6px 14px 6px 30px;
      line-height: 24px;

      &:focus,
      &:hover {
        background-color: $grey-5;
        outline: none;
        border: none;
      }
    }
  }
}
</style>
