import apiClient from '@/_helpers/api-client'
export const propertyTypesManagementService={
  createPropertyType,
  updatePropertyType
}
async function createPropertyType(data) {
  return await apiClient({
    url: '/data/property-types',
    method: 'POST',
    data
  })
}

async function updatePropertyType(propertyTypeId, data){
  return await apiClient({
    url: `/data/property-types/${propertyTypeId}`,
    method: 'PATCH',
    headers:{
      'Content-Type': 'text/plain'
    },
    data
  })
}