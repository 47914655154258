<template lang="pug">
.c-share-button(:ref='`favorite-cta-${id}`' v-requiresAuthentication:click.stop='{ action: share, createdCtaText: "Proceed to share" }')
  svg.o-svg-icon.favorites-button__icon.js-favorites-icon(type='image/svg+xml' v-bind:style='{ width: `${size}px`, height: `${size}px` }' viewbox='0 0 42 34')
    use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-share`' x='0' y='0')
</template>
<script>
export default {
  name: 'c-share-button',
  data() {
    return {
      id: this._uid
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    size: {
      default: 22
    }
  },
  computed: {},
  methods: {
    share() {
      // console.log('!!!SHARE!!!') //TODO change this when we have task for it
    }
  }
}
</script>
<style lang="scss" scoped>
.c-share-button {
  display: block;
  padding: 0;
  cursor: pointer;

  &__icon {
    display: block;
  }
}
</style>
