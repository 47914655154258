import apiClient from '@/_helpers/api-client'
export const ub4SyncService = {
  getUb4Sync,
  postUb4Sync,
  postUb4SyncProperties,
  postUb4SyncResidences
}

async function getUb4Sync() {
  return await apiClient({
    url: '/data/ub/sync',
    method: 'GET'
  })
}
async function postUb4Sync() {
  return await apiClient({
    url: '/data/ub/sync',
    method: 'POST'
  })
}

async function postUb4SyncProperties(data) {
  return await apiClient({
    url: '/data/ub/sync/properties?syncResidences=true',
    method: 'POST',
    data
  })
}

async function postUb4SyncResidences(data) {
  return await apiClient({
    url: '/data/ub/sync/residences',
    method: 'POST',
    data
  })
}
