<template lang="pug">
.c-filter-typeahead(:class='{ "c-filter-typeahead--fixed": modalOnClick }' @click='showModal')
  .c-filter-typeahead__wrap
    c-search-typeahead(:placeholder='placeholder' @change='typeAheadChange' :autofocus='autofocusSearchInput')
    .c-filter-typeahead__tags.o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-start.o-grid--gutter-tiny(
      :class='{ "o-grid--wrap-nowrap": modalOnClick }'
      v-if='showTags && typeaheadItems.length > 0'
    )
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0(:key='tag.key' v-for='tag in typeaheadItems')
          button.c-filter-typeahead__tag.o-tag.o-tag--crab(@click='removeTypeaheadItem(tag)') {{ tag.value }} {{ translateTagCategory(tag.category) }}
            svg.o-svg-icon.c-filter-typeahead__tag-close(type='image/svg+xml' viewbox='0 0 64 64')
              use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-close' y='0')
</template>
<script>
import cSearchTypeahead from '@/components/search/search-typeahead/index'
import { mapState } from 'vuex'
export default {
  name: 'c-filter-typeahead',
  data() {
    return {}
  },
  props: {
    showTags: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    specialAfterSelect: {
      type: Boolean,
      default: false
    },
    modalOnClick: {
      type: Boolean,
      default: false
    },
    typeaheadFilterCategories: {
      type: Array,
      default: () => ['Commune', 'City', 'PostalCodeAndPostalCodeName', 'PropertyName', 'StreetAddress', 'Street']
    },
    autofocusSearchInput: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'c-search-typeahead': cSearchTypeahead
  },
  computed: {
    ...mapState('search', ['filters', 'modalShowing', 'searchParameters']),
    typeaheadItems() {
      const tagItems = []
      Object.keys(this.filters)
        .filter(key => this.typeaheadFilterCategories.includes(key))
        .forEach(category => {
          this.filters[category].forEach(item => {
            tagItems.push({ category, value: item })
          })
        }, {})

      return tagItems
    }
  },
  methods: {
    showModal() {
      if (this.modalOnClick) {
        this.$store.dispatch('search/toggleSearchModal', true)
      }
    },
    typeAheadChange(item) {
      if (this.specialAfterSelect) {
        this.$store.dispatch('search/addFilter', {
          filter: item.type,
          value: item.suggestion,
          updateResults: false
        })

        if (this.isMobile && !this.modalShowing) {
          this.$store.dispatch('search/toggleSearchModal', true)
        }
      } else {
        this.$store.dispatch('search/addFilter', {
          filter: item.type,
          value: item.suggestion,
          updateResults: true
        })
      }
    },
    removeTypeaheadItem(tag) {
      this.$store.dispatch('search/removeFilter', { filter: tag.category, value: tag.value, updateResults: !this.modalOnClick && !this.modalShowing })
    },
    translateTagCategory(tagCategory) {
      let translation = ''
      switch (tagCategory) {
        case 'Commune':
          translation = this.Dictionary.C.Commune
          break
        case 'City':
          translation = this.Dictionary.C.City
          break
        case 'PostalCodeAndPostalCodeName':
          translation = this.Dictionary.P.PostalCode
          break
        case 'PropertyName':
          translation = this.Dictionary.P.PropertyName
          break
        case 'Street':
          translation = this.Dictionary.S.StreetName
          break
        case 'StreetAddress':
          translation = this.Dictionary.S.Street
          break
        case 'Place':
          translation = this.Dictionary.P.Place
          break
        case 'BuildingName':
          translation = this.Dictionary.B.Building
          break
        default:
          translation = tagCategory
      }
      return translation
    }
  },
  watch: {
    searchParameters(newVal) {
      if (newVal && newVal.length > 0 && this.specialAfterSelect && !this.isMobile) this.$store.dispatch('search/sendToSearchPageWithFilters')
    }
  }
}
</script>
<style lang="scss">
.c-filter-typeahead {
  //TODO Is there a way to make this scoped?
  &--fixed {
    .o-input {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.c-filter-typeahead {
  $root: &;
  width: 100%;

  &__wrap {
    position: relative;
    cursor: pointer;
  }

  &--fixed {
    .o-input {
      background-color: red;
      &::placeholder {
        opacity: 0;
      }
    }

    #{$root}__wrap {
      pointer-events: none;
    }

    #{$root}__tags {
      position: absolute;
      top: 15px;
      left: 55px;
      margin: 0;
      flex-wrap: nowrap;
      width: calc(100% - 56px);
      overflow: hidden;

      #{$root}__tag {
        background-color: $ocean-4;
        pointer-events: none;
        cursor: initial;
        white-space: nowrap;
        &-close {
          display: none;
        }
      }
    }
  }

  &__tags {
    margin-top: 10px;
  }
  &__tag {
    border-radius: 3px;
    padding: 5px 8px;
    cursor: pointer;
    border: none;
    &-close {
      width: 10px;
      height: 10px;
      margin-left: 5px;
    }
  }
}
</style>
