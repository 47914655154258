export const loadingIndicator = {
  namespaced: true,
  state: {
    loading: false,
    loadingEndpoints: [],
    loadingTimeoutID: 0
  },
  actions: {
    show({ commit }, payload) {
      commit('show', payload)
    },
    hide({ commit }, payload) {
      commit('hide', payload)
    }
  },
  mutations: {
    show(state, payload) {
      state.loadingEndpoints.push(payload)
      clearTimeout(state.loadingTimeoutID)
      state.loadingTimeoutID = setTimeout(function () {
        state.loading = state.loadingEndpoints.length > 0
      }, 500)
    },
    hide(state, payload) {
      clearTimeout(state.loadingTimeoutID)
      state.loadingEndpoints.splice(state.loadingEndpoints.indexOf(payload), 1)
      state.loading = state.loadingEndpoints.length > 0
    }
  }
}
