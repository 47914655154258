<template lang="pug">
c-modal(
  :class='{ "c-search-modal--search-agent": searchAgent }',
  :cornerClose='true',
  :stickyFooter='true'
  @close='toggleSearchModal(false)'
  v-if='modalShowing'
)
  .c-search-modal__headline.o-heading.o-heading--size-4(slot='header' v-if='Dictionary.Search.SearchModalHeadline && !searchAgent') {{ Dictionary.Search.SearchModalHeadline }}
  template(slot='header' v-if='searchAgent')
    .c-search-modal__headline
      .o-heading.o-heading--size-2 {{ Dictionary.SearchAgent.EditSearchAgent }}
      .c-search-modal__section
        .o-input__container
          .o-input__wrap
            input#search-agent-name.o-input(maxlength='100' name='search-agent-name' placeholder=' ' type='text' v-model='searchAgentName')
            label.o-label.o-label--placeholder(for='search-agent-name' v-if='Dictionary.C.ChangeName') {{ Dictionary.C.ChangeName }}
  .c-search-modal__body(slot='body')
    .c-search-modal__section
      c-filter-typeahead(:show-tags='true' placeholder='Søg kommune, by, vejnavn')
    .c-search-modal__section
      .c-search-modal__filter-item(v-if='!model.HideRent')
        c-filter-input-number(
          :model='{ filter: "Rent", headingText: Dictionary.Search.RentMax, placeholderText: Dictionary.Search.RentPlaceholderText, labelText: Dictionary.Search.RentLabelText, helperText: Dictionary.Search.RentHelperText }'
        )
      .c-search-modal__filter-item(v-if='!model.HideRooms')
        c-filter-input-slider(
          :model='{ filter: "Rooms", headingText: Dictionary.Search.RoomsHeadingText, labelText: Dictionary.Search.RoomsLabelText, helperText: Dictionary.Search.RoomsHelperText, boundMax: 10, boundMin: 0 }',
          :reverse='true'
        )
      .c-search-modal__filter-item(v-if='!model.HideSize')
        c-filter-input-slider(
          :model='{ filter: "Area", headingText: Dictionary.Search.AreaHeadingText, labelText: Dictionary.Search.AreaLabelText, helperText: Dictionary.Search.AreaHelperText, boundMax: 500, boundMin: 0, boundInterval: 25 }',
          :reverse='true'
        )
    .c-search-modal__section(v-if='!model.HideType || !model.HideSubType || !model.HidePensionFund || !model.HideFloor || !model.HideYear')
      c-filter-section(:model='{ filters: ["PropertyType", "Types", "Floor", "PropertyYear"], headingText: Dictionary.Search.PropertyTypes }')
        .c-search-modal__filter-item(v-if='!model.HideType')
          c-filter-input-type(:model='propertyTypeModel')
        .c-search-modal__filter-item(v-if='!model.hideSubType && residenceTypesModel.options.length > 0')
          c-filter-input-checklist(:model='residenceTypesModel')
        .c-search-modal__filter-item(v-if='!model.HideFloor')
          c-filter-input-slider(
            :model='{ filter: "Floor", headingText: Dictionary.Search.FloorHeadingText, labelText: Dictionary.Search.MinMaxFloor, helperText: Dictionary.Search.FloorHelperText, boundMin: -3, boundMax: 30, boundInterval: 1 }'
          )
        .c-search-modal__filter-item(v-if='!model.HideYear')
          c-filter-input-select(:model='propertyYearModel')
    .c-search-modal__section(v-if='!model.HideTakeover || !model.HideDuration')
      c-filter-section(:model='{ filters: ["AvailableFrom", "RentalPeriod"], headingText: Dictionary.Search.Duration }')
        .c-search-modal__filter-item(v-if='!model.HideTakeover')
          c-filter-input-radiolist(
            :model='{ filter: "AvailableFrom", headingText: Dictionary.Search.TakeOverDateHeadingText, labelText: Dictionary.Search.TakeOverDateLabelText, helperText: Dictionary.Search.TakeOverDateHelperText, defaultOptionLabelText: Dictionary.E.EverythingIsOfInterest, options: [ { value: 1, labelText: Dictionary.TakeOverDate.now }, { value: 2, labelText: Dictionary.TakeOverDate.oneToThreeMonths }, { value: "", labelText: Dictionary.TakeOverDate.selectDate, specialType: "selectDate" }, ], }'
          )
        .c-search-modal__filter-item(v-if='!model.HideDuration')
          c-filter-input-radiolist(
            :model='{ filter: "RentalPeriod", headingText: Dictionary.Search.RentalPeriodHeadingText, labelText: Dictionary.Search.RentalPeriodLabelText, helperText: Dictionary.Search.RentalPeriodHelperText, defaultOptionLabelText: Dictionary.E.EverythingIsOfInterest, options: [ { value: "TwoToTwelveMonth", labelText: Dictionary.RentalPeriods.TwoToTwelveMonth }, { value: "OneToTwoYears", labelText: Dictionary.RentalPeriods.OneToTwoYears }, { value: "TwoOrMoreYears", labelText: Dictionary.RentalPeriods.TwoOrMoreYears }, { value: "Unlimited", labelText: Dictionary.RentalPeriods.Unlimited }, ], }'
          )
        .c-search-modal__filter-item(v-if='!model.hideOpenHouseDate')
          c-filter-input-checklist(:model='openHouseDateModel')
    .c-search-modal__section(v-if='!model.hideCompany')
      c-filter-section(:model='{ filters: ["PropertyOrganizationId"], headingText: Dictionary.Search.CompanySection }')
        .search-modal__filter-item(v-if='!model.hideCompany')
          c-filter-input-select(:model='propertyOrganizationModel')
    .c-search-modal__section(v-if='!model.HidePropertyFacilities || !model.HideResidenceFacilities')
      c-filter-section(:model='{ filters: ["PropertyFacilities", "Facilities"], headingText: Dictionary.Search.FacilitiesSection }')
        .c-search-modal__filter-item(v-if='!model.HidePropertyFacilities')
          c-filter-input-checklist(:model='propertyFacilitiesModel')
        .c-search-modal__filter-item(v-if='!model.HideResidenceFacilities')
          c-filter-input-checklist(:model='residenceFacilitiesModel')
  .c-search-modal__footer.o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-space.o-grid--gutter-tiny.theme-dark(slot='footer')
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0(v-if='!searchAgent')
        a.o-link(@click='resetFilters') {{ Dictionary.Search.RemoveSelection }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.o-button.o-button--inverted(:disabled='facetsResultsCount === 0' @click='sendToSearch' v-if='!searchAgent') {{ showPropertiesText }}
        button.o-button.o-button--inverted(:disabled='searchAgentName.length === 0' @click='patchSearchAgent' v-else) {{ Dictionary.SearchAgent.SaveSearchAgent }}
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'c-search-modal',
  data() {
    return {
      model: {},
      id: this._uid,
      searchAgentName: '',
      currentYear: moment.utc().format('YYYY')
    }
  },
  props: {
    searchAgent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('search', ['filters', 'modalShowing', 'facets', 'facetsResultsCount', 'facilityLocations']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapGetters('search', ['activeSearchAgent', 'preparedFiltersSearch', 'propertyFacilities', 'membershipOrganizationsInfo', 'residenceFacilities']),
    showPropertiesText() {
      if (this.Dictionary.Search.ShowXProperties) return this.Dictionary.Search.ShowXProperties.replace('{0}', this.facetsResultsCount)
      else {
        return this.count
      }
    },
    propertyTypeModel() {
      const propertyTypeModel = {
        filter: 'PropertyType',
        headingText: this.Dictionary.Search.TypeHeadingText,
        labelText: this.Dictionary.Search.TypeLabelText,
        helperText: this.Dictionary.Search.TypeHelperText,
        options: this.propertyTypes.map(pt => {
          return { labelText: pt.name, value: pt.id, icon: pt.icon }
        })
      }
      return propertyTypeModel
    },
    propertyOrganizationModel() {
      const propertyOrganizationModel = {
        filter: 'PropertyOrganizationId',
        headingText: this.Dictionary.Search.CompanyHeadingText,
        labelText: this.Dictionary.Search.CompanyLabelText,
        helperText: this.Dictionary.Search.CompanyHelperText,
        options: Object.keys(this.membershipOrganizationsInfo)
          .filter(mo => Object.keys(this.membershipOrganizationsInfo[mo].companies).length > 0)
          .map(mo => {
            return { name: this.membershipOrganizationsInfo[mo].name, value: this.membershipOrganizationsInfo[mo].id }
          }),
        settings: {
          multiple: true,
          alwaysOpen: true,
          reduce: option => option.value
        }
      }
      return propertyOrganizationModel
    },
    residenceTypesModel() {
      const residenceTypesModel = {
        filter: 'Types',
        headingText: this.Dictionary.Search.SubTypeHeadingText,
        labelText: this.Dictionary.Search.SubTypeLabelText,
        helperText: this.Dictionary.Search.SubTypeHelperText,
        options: this.residenceTypes.map(rt => {
          return { labelText: rt.name, value: rt.id }
        })
      }
      return residenceTypesModel
    },
    openHouseDateModel() {
      const OpenHouseDateModel = {
        filter: 'OpenHouseDate',
        headingText: this.Dictionary.Search.OpenHouseHeadingText,
        labelText: this.Dictionary.Search.OpenHouseLabelText,
        helperText: this.Dictionary.Search.OpenHouseHelperText,
        options: [{ labelText: this.Dictionary.O.OpenHouse, value: '[NOW;*]' }]
      }
      return OpenHouseDateModel
    },
    propertyFacilitiesModel() {
      const propertyFacilitiesModel = {
        filter: 'PropertyFacilities',
        headingText: this.Dictionary.Search.PropertyFacilitiesHeadingText,
        labelText: this.Dictionary.Search.PropertyFacilitiesLabelText,
        helperText: this.Dictionary.Search.PropertyFacilitiesHelperText,
        options:
          this.facilityLocations.length > 0 &&
          this.propertyFacilities.map(pf => {
            return { labelText: pf.name, value: pf.id }
          })
      }
      return propertyFacilitiesModel
    },
    residenceFacilitiesModel() {
      const residenceFacilitiesModel = {
        filter: 'Facilities',
        headingText: this.Dictionary.Search.FacilitiesHeadingText,
        labelText: this.Dictionary.Search.FacilitiesLabelText,
        helperText: this.Dictionary.Search.FacilitiesHelperText,
        options:
          this.facilityLocations.length > 0 &&
          this.residenceFacilities.map(rf => {
            return { labelText: rf.name, value: rf.id }
          })
      }
      return residenceFacilitiesModel
    },
    propertyYearModel() {
      const propertyYearModel = {
        filter: 'PropertyYear',
        headingText: this.Dictionary.Search.YearHeadingText,
        placeholderText: this.Dictionary.Search.YearPlaceholderText,
        labelText: this.Dictionary.Search.YearLabelText,
        helperText: this.Dictionary.Search.YearHelperText,
        boundMin: 1900,
        boundMax: this.currentYear,
        settings: {
          reduce: option => option.value
        }
      }

      return propertyYearModel
    },
    allFiltersDisabled() {
      return (
        this.model.HideLocation &&
        this.model.HideRent &&
        this.model.HideRooms &&
        this.model.HideSize &&
        this.model.HideType &&
        this.model.HideSubType &&
        this.model.HidePensionFund &&
        this.model.HideFloor &&
        this.model.HideYear &&
        this.model.HideTakeover &&
        this.model.HideDuration &&
        this.model.HidePropertyFacilities &&
        this.model.HideResidenceFacilities
      )
    }
  },
  methods: {
    toggleSearchModal(state) {
      this.$store.dispatch('search/toggleSearchModal', state)
    },
    sendToSearch() {
      if (window.location.pathname === '/find') {
        this.$store.dispatch('search/getResults')
        this.toggleSearchModal(false)
      } else {
        this.$store.dispatch('search/sendToSearchPageWithFilters')
      }
    },
    patchSearchAgent() {
      this.$store.dispatch('search/patchSearchAgent', { searchAgentName: this.searchAgentName })
    },
    resetFilters() {
      Object.entries(this.filters).forEach(filter => {
        this.$store.dispatch('search/resetFilterToDefault', filter[0])
      })
    }
  },
  watch: {
    activeSearchAgent(newValue) {
      this.searchAgentName = newValue.name
    }
  }
}
</script>
<style lang="scss" scoped>
.c-search-modal {
  $root: &;
  $itemPadding: 20px;

  &__headline {
    text-align: center;
  }

  &__body {
    margin-bottom: 35px;
  }
  &__section {
    padding: $itemPadding 0;
    & + & {
      border-top: 1px solid rgba($ocean-primary, 0.2);

      &:last-child {
        border-bottom: 1px solid rgba($ocean-primary, 0.2);
      }
    }
  }
  &__filter-item {
    & + & {
      margin-top: $itemPadding;
    }
  }

  &__footer {
    background-color: $ocean-primary;
    margin: 0 -25px -35px;
    padding: 20px 25px;

    @include min('desktop') {
      margin: 0 -45px -75px;
      padding: 20px 45px;
    }

    @include max('phoneplus', -1) {
      .o-grid__group {
        text-align: center;
        justify-content: center;

        > .o-grid__item.o-grid__item--align-right.o-grid__item--grow-0 {
          margin-left: 0;
        }

        .o-grid__item:first-child {
          order: 2;
        }
      }
    }
  }

  &--search-agent {
    #{$root}__headline {
      background-color: $grey-5;
      color: $ocean-darker;
      margin: -35px -25px 0;
      padding: 35px 25px 5px;

      @include min('desktop') {
        margin: -75px -45px 0;
        padding: 35px 45px 5px;
      }
    }
  }
}
</style>
