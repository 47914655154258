<template lang="pug">
c-modal.c-mo-subscription(:cornerClose='true' @close='closeSubscription' v-if='(totalSections > 0 && !userIsAssociated) || paymentRequired || confirmation')
  template(v-if='selected && !confirmation && (totalSections > 0 || paymentRequired)')
    c-tab-navigation.c-mo-subscription__tab-navigation(:active='selectedTab', :total='totalSections' @clicked='tabClicked' slot='header')
    .c-mo-subscription__content(slot='body')
      template(v-for='(section, sectionKey) in dynamicSections')
        template.c-mo-subscription__section(v-if='section.dependencyMet')
          template(v-if='selectedTab === sectionKey + 1')
            .c-mo-subscription__content-top
              .c-mo-subscription__heading.o-heading.o-heading--size-2 {{ section.name }}
              .c-mo-subscription__description.o-bodytext {{ section.description }}
            .c-mo-subscription__content-body
              component.c-mo-subscription__parameter(
                :is='`c-pm-p-${transformParameterType(parameter)}`',
                :key='`${sectionKey}-${parameterKey}`',
                :model='prepareModel(parameter)'
                @validate='validate($event, parameter.id)'
                v-for='(parameter, parameterKey) in section.parameters'
                v-if='parameter.dependencyMet'
                v-model='selected.sections[sectionKey].parameters[parameterKey].answer'
              )
      template.c-mo-subscription__section(v-if='showParameterSummary')
        .c-mo-subscription__content-top
          .c-mo-subscription__heading.o-heading.o-heading--size-2 {{ model.heading }}
        .c-mo-subscription__content-body.c-mo-subscription__overview
          template(v-for='answerSection in selected.sections')
            template(v-for='answerParameter in answerSection.parameters')
              .c-mo-subscription__answer(v-if='answerParameter.answer')
                .o-bodytext.o-bodytext--700 {{ answerParameter.question }}
                .o-bodytext {{ getAnswerFromParameter(answerParameter) }}
          c-cta.c-mo-subscription__submit(
            :cta-disabled='ctaDisabled',
            :cta-loading='ctaDisabled',
            :cta-text='model.ctaText'
            @click='validateApplication'
            v-if='!paymentRequired'
          )
      template.c-mo-subscription__section(v-if='selectedTab === totalSections && paymentRequired')
        .c-mo-subscription__content-top
          .c-mo-subscription__heading.o-heading.o-heading--size-2 {{ model.paymentHeadline }}
          .c-mo-subscription__description.o-bodytext {{ model.paymentDescription }}
        .c-mo-subscription__content-body.c-mo-subscription__overview
          table.o-table.c-mo-subscription__payment-overview
            tr
              td {{ selected.paymentStatus.itemName }}
              td.notranslate {{ selected.paymentStatus.amount.amount | money }}
            tr.o-bodytext.o-bodytext--700
              td {{ Dictionary.T.Total }}
              td.notranslate {{ selected.paymentStatus.amount.amount | money }}
            tr.o-bodytext.o-bodytext--size-small
              td {{ Dictionary.O.OfWhichVAT }} {{ selected.paymentStatus.taxRate }}%
              td.notranslate {{ taxAmount | money }}
          c-pm-p-checkbox(:model='paymentTerms' v-model='paymentTerms.value')
          button.c-mo-subscription__submit.o-button(:disabled='!paymentTerms.value' @click='submitApplication') {{ Dictionary.MembershipOrganizations.StartVerification }}
      .c-mo-subscription__navigation
        button.c-mo-subscription__next.o-button.o-button--full-width(
          :disabled='!selectedSectionIsValid'
          @click='selectedTab++'
          v-if='selectedTab < totalSections'
        ) {{ Dictionary.N.Next }}
        button.c-mo-subscription__previous.o-link(@click='selectedTab--' v-if='selectedTab > 1') {{ Dictionary.B.Back }}

  template(v-if='confirmation')
    template(v-if='revalidatedApplications')
      .c-mo-subscription__confirmation.c-mo-subscription__confirmation-body(slot='body')
        h3.c-mo-subscription__confirmation-heading.o-heading.o-heading--size-3(v-if='model.headingValidation') {{ model.headingValidation }}
        .c-mo-subscription__confirmation-text(v-html='validationTextFilled' v-if='validationTextFilled')
        template(v-for='membershipOrganization in revalidatedApplications')
          .c-mo-subscription__organization.o-grid.o-grid--align-items-center.o-grid--gutter-small
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                img.c-mo-subscription__confirmation-logo.c-mo-subscription__confirmation-logo--small(
                  :src='getLogo(membershipOrganization.membershipOrganizationId)'
                  v-if='getLogo(membershipOrganization.membershipOrganizationId)'
                )
              .o-grid__item.o-grid__item--grow-0
                span {{ membershipOrganization.membershipOrganizationName }}
          table.o-table.o-table--v-middle.c-mo-subscription__confirmation-table
            thead
              tr
                th {{ Dictionary.P.Picture }}
                th {{ Dictionary.P.PropertyName }}
                th {{ Dictionary.A.Address }}
                th {{ Dictionary.W.WaitingList }}
            tbody
              tr(v-for='property in membershipOrganization.unsubscribedPropertyApplications')
                td
                  img.c-mo-subscription__confirmation-logo.c-mo-subscription__confirmation-logo--small(
                    :src='property.propertyImage'
                    v-if='property.propertyImage'
                  )
                td {{ property.propertyName }}
                td {{ property.propertyAddress }}
                td {{ property.numberOfUnsubscribedApplications }}
      .c-mo-subscription__confirmation.c-mo-subscription__confirmation-footer(slot='footer' v-if='revalidatedApplications')
        .o-grid.o-grid--justify-content-center.o-grid--gutter-small
          .o-grid__group
            .o-grid__item.o-grid__item--12
              button.o-button.o-button--inverted(@click='submitApplication') {{ Dictionary.A.Approve }}
            .o-grid__item.o-grid__item--12
              span.o-link(@click='closeSubscription') {{ Dictionary.C.Cancel }}
    template(slot='body' v-else)
      .c-mo-subscription__confirmation
        svg.o-svg-icon.c-mo-subscription__confirmation-icon(type='image/svg+xml' viewbox='0 0 35 35')
          use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${confirmationQualificationIcon}`' x='0' y='0')
        .c-mo-subscription__content-top
          h3.c-mo-subscription__heading.o-heading.o-heading--size-3 {{ confirmationQualificationText.headline }}
          .c-mo-subscription__description.o-bodytext {{ confirmationQualificationText.description }}
        c-notification-inline.c-mo-subscription__notice.o-bodytext.o-bodytext--size-small(
          :canClose='false'
          justify='left'
          type='warning'
          v-if='confirmation.qualifiedResidencesCount !== confirmation.totalResidencesCount'
        )
          div {{ qualifiedNotificationText }}
          div(v-if='qualificationFailedReason') {{ qualificationFailedReason.message }}
        c-notification-inline.c-mo-subscription__notice.o-bodytext.o-bodytext--size-small(
          :canClose='false'
          justify='left'
          type='error'
          v-if='confirmation.propertiesOverflow'
        ) {{ propertiesOverflowText }}
        c-notification-inline.c-mo-subscription__notice.o-bodytext.o-bodytext--size-small(
          :canClose='false'
          justify='left'
          type='error'
          v-if='confirmation.residencesOverflow'
        ) {{ residencesOverflowText }}
        .c-mo-subscription__confirmation-box(v-if='confirmationQualification === "ok" || confirmationQualification === "warning"')
          .c-mo-subscription__confirmation-text(v-html='model.bodyConfirmation')
          button.c-mo-subscription__confirmation-cta.o-button(@click='closeSubscription') {{ model.ctaConfirmation }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProfileMembershipsParameterChecklist from '@/components/form/controlls/form-checklist'
import ProfileMembershipsParameterCheckbox from '@/components/form/controlls/form-checkbox'
import ProfileMembershipsParameterDate from '@/components/form/controlls/form-date'
import ProfileMembershipsParameterNumber from '@/components/form/controlls/form-number'
import ProfileMembershipsParameterRadioList from '@/components/form/controlls/form-radiolist'
import ProfileMembershipsParameterSelect from '@/components/form/controlls/form-select'
import ProfileMembershipsParameterText from '@/components/form/controlls/form-text'
import ProfileMembershipsParameterUpload from '@/components/form/controlls/form-upload'
import ProfileMembershipsParameterSensitive from '@/components/form/controlls/form-sensitive'
export default {
  name: 'c-mo-subscription',

  data() {
    return {
      selectedTab: 1,
      validation: {},
      paymentTerms: {
        value: false,
        helperText: this.model.paymentTermsHelperText,
        placeholderText: this.model.paymentTermsPlaceholderText,
        isMandatory: true,
        readOnly: false,
        validation: null
      }
    }
  },
  components: {
    'c-pm-p-checklist': ProfileMembershipsParameterChecklist,
    'c-pm-p-checkbox': ProfileMembershipsParameterCheckbox,
    'c-pm-p-date': ProfileMembershipsParameterDate,
    'c-pm-p-number': ProfileMembershipsParameterNumber,
    'c-pm-p-radiolist': ProfileMembershipsParameterRadioList,
    'c-pm-p-select': ProfileMembershipsParameterSelect,
    'c-pm-p-text': ProfileMembershipsParameterText,
    'c-pm-p-upload': ProfileMembershipsParameterUpload,
    'c-pm-p-sensitive': ProfileMembershipsParameterSensitive
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    callback: {
      type: Function
    },
    propertyApplication: {
      default: false
    }
  },
  computed: {
    ...mapState('membershipOrganizations', ['selected', 'all', 'confirmation']),
    ...mapState('property', ['propertyId']),
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    ...mapGetters('membershipOrganizations', ['submitParameters', 'dynamicSections', 'paymentRequired', 'userIsAssociated']),
    formIsValid() {
      return this.validation && Object.values(this.validation).every(key => key === true)
    },
    ctaDisabled() {
      const waitingForValidation = this.loadingEndpoints.includes(`/parameters/membership-organizations/${this.selected.id}/validate`)
      const waitingForParameters = this.propertyId && this.loadingEndpoints.includes(`/parameters/properties/${this.propertyId}`)
      const waitingForEndpoints = this.loading && (waitingForValidation || waitingForParameters)

      return waitingForEndpoints
    },
    noSubmitParameters() {
      return (
        (Object.keys(this.submitParameters) && Object.keys(this.submitParameters).length === 0) ||
        Object.keys(this.submitParameters).every(key => !this.submitParameters[key])
      )
    },
    totalSections() {
      const activeDynamicSections = this.dynamicSections.length && this.dynamicSections.filter(s => s.dependencyMet)
      const totaldynamicSections = activeDynamicSections.length > 0 ? activeDynamicSections.length + 1 : 0 // sections + confirmation
      const totalSections = this.paymentRequired ? totaldynamicSections + 1 : totaldynamicSections
      return totalSections
    },
    qualifiedNotificationText() {
      return (
        this.model.qualifiedNotification &&
        this.model.qualifiedNotification.replace('{0}', this.confirmation.qualifiedResidencesCount).replace('{1}', this.confirmation.totalResidencesCount)
      )
    },
    qualificationFailedReason() {
      const failedQualificationResidence = this.confirmation.qualifications.find(q => !q.isQualified),
        failedQualificationFirstReason = failedQualificationResidence && failedQualificationResidence.qualificationLog.find(ql => !ql.qualified)
      return failedQualificationFirstReason
    },
    propertiesOverflowText() {
      return (
        this.model.propertiesOverflowNotification &&
        this.model.propertiesOverflowNotification
          .replace('{maxCount}', this.confirmation.propertiesOverflow.max)
          .replace('{pensionFund}', this.selected.name)
          .replace('{currentCount}', this.confirmation.propertiesOverflow.current)
          .replace('{newCount}', this.confirmation.propertiesOverflow.new)
      )
    },
    residencesOverflowText() {
      return (
        this.model.residencesOverflowNotification &&
        this.model.residencesOverflowNotification
          .replace('{maxCount}', this.confirmation.residencesOverflow.max)
          .replace('{pensionFund}', this.selected.name)
          .replace('{currentCount}', this.confirmation.residencesOverflow.current)
          .replace('{newCount}', this.confirmation.residencesOverflow.new)
      )
    },
    showParameterSummary() {
      return (this.selectedTab === this.totalSections && !this.paymentRequired) || (this.selectedTab === this.totalSections - 1 && this.paymentRequired)
    },
    selectedSectionIsValid() {
      const selectedSection = this.dynamicSections[this.selectedTab - 1]
      return !selectedSection || selectedSection.parameters.every(parameter => this.validation[parameter.id] || !parameter.dependencyMet)
    },
    validationTextFilled() {
      let countApplications = 0
      this.revalidatedApplications.forEach(a => {
        a.unsubscribedPropertyApplications.forEach(p => (countApplications = countApplications + p.numberOfUnsubscribedApplications))
      })
      return this.model.descriptionValidation && this.model.descriptionValidation.replace('{0}', countApplications)
    },
    revalidatedApplications() {
      return (this.confirmation.response && this.confirmation.response.data.revalidatedApplications) || null
    },
    confirmationQualification() {
      let confirmationQualification = false
      if (this.confirmation) {
        if (this.confirmation.qualifiedResidencesCount) {
          if (this.confirmation.qualifiedResidencesCount === this.confirmation.totalResidencesCount) {
            confirmationQualification = 'ok'
          } else if (this.confirmation.qualifiedResidencesCount > 0) {
            confirmationQualification = 'warning'
          }
        } else if (this.confirmation.response && this.confirmation.response.data === 'Cancel') {
          confirmationQualification = 'ok'
        } else {
          confirmationQualification = 'error'
        }
      }
      return confirmationQualification
    },
    confirmationQualificationIcon() {
      return this.confirmationQualification === 'ok' ? 'correct' : this.confirmationQualification === 'warning' ? 'circle-warning' : 'cross'
    },
    confirmationQualificationText() {
      let confirmationQualificationText
      switch (this.confirmationQualification) {
        case 'ok':
          confirmationQualificationText = { headline: this.model.headingConfirmation, description: this.model.descriptionConfirmation }
          break
        case 'warning':
          confirmationQualificationText = { headline: this.model.headingConfirmationWarning, description: this.model.descriptionConfirmationWarning }
          break
        case 'error':
          confirmationQualificationText = { headline: this.model.headingConfirmationError, description: this.model.descriptionConfirmationError }
          break
      }
      return confirmationQualificationText
    },
    taxAmount() {
      const taxPercent = this.selected.paymentStatus.taxRate
      const taxtAmount = taxPercent > 0 ? (this.selected.paymentStatus.amount.amount * 100) / (100 + taxPercent) - this.selected.paymentStatus.amount.amount : 0

      return taxtAmount < 0 ? taxtAmount * -1 : taxtAmount
    }
  },
  methods: {
    getLogo(moId) {
      const targetMo = this.all && this.all.find(organization => organization.id === moId)
      return targetMo && targetMo.logo
    },
    closeSubscription() {
      this.$emit('close', this.confirmation)
      this.$store.dispatch('membershipOrganizations/closeConfirmation')
      if (this.totalSections > 0 && !this.userIsAssociated) {
        this.$store.commit('membershipOrganizations/setSelected', {
          selected: undefined,
          id: this.selected.id,
          paymentStatus: undefined,
          orderStatus: undefined
        })
      }
      this.validation = {}
    },
    transformParameterType(parameter) {
      let transformedType = ''
      switch (parameter.type) {
        case 'Text':
          if (parameter.validationConfig && parameter.validationConfig.displayRule === '######-####') {
            transformedType = 'sensitive'
          } else {
            transformedType = 'text'
          }
          break
        case 'CheckboxList':
          transformedType = 'checklist'
          break
        case 'Checkbox':
          transformedType = 'checkbox'
          break
        case 'Date':
          transformedType = 'date'
          break
        case 'Dropdown':
          transformedType = 'select'
          break
        case 'Number':
          transformedType = 'number'
          break
        case 'RadioButtonList':
          transformedType = 'radiolist'
          break
        case 'Upload':
          transformedType = 'upload'
          break
      }
      return transformedType
    },
    tabClicked(newTab) {
      this.selectedTab = newTab
    },
    validateApplication() {
      this.$store.dispatch('membershipOrganizations/validateMembershipOrganizationParameters', {
        isApplication: this.propertyApplication,
        callback: this.callback
      })
    },
    submitApplication() {
      // console.log('Submit Application')
      this.$store.dispatch('membershipOrganizations/submitApplication', { isApplication: this.propertyApplication, callback: this.callback })
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    prepareModel(parameter) {
      const preparedModel = {
        value: parameter.answer,
        helperText: parameter.helperText,
        id: parameter.id,
        isMandatory: parameter.isMandatory,
        readOnly: parameter.isReadOnly,
        placeholderText: parameter.question,
        validation: parameter.validationConfig || {},
        type: parameter.type
      }
      if (parameter.type === 'Number' && parameter.validationConfig.ranges.length > 0) {
        let minVal = 0
        let maxVal = 0
        parameter.validationConfig.ranges.forEach(range => {
          minVal = range.minValue < minVal ? range.minValue : minVal
          maxVal = range.maxValue > maxVal ? range.maxValue : maxVal
        })

        preparedModel.validation.minValue = minVal
        preparedModel.validation.maxValue = maxVal
      }

      if (preparedModel.validation && preparedModel.validation.displayRule === '######-####') {
        preparedModel.validation.hide = 4
      }
      switch (parameter.type) {
        case 'Checkbox':
          preparedModel.returnValue = parameter.id
          break
        case 'CheckboxList':
        case 'RadioButtonList':
          preparedModel.options = parameter.options.map(option => {
            return { value: option.id, name: option.name, required: option.required }
          })
          preparedModel.validation.errorMessage = parameter.errorMessage
          preparedModel.labelText = parameter.question
          break
        case 'Upload':
          preparedModel.answer = parameter.answer ? parameter.answer : []
          preparedModel.fileUrl = `/api/data/user/parameters/${preparedModel.id}/file`
          break
        case 'Dropdown':
          preparedModel.options = parameter.options.map(option => {
            return { value: option.id, name: option.name, required: option.required }
          })
          preparedModel.validation.errorMessage = parameter.errorMessage
          break
      }
      return preparedModel
    }
  },
  created() {
    if (!this.totalSections && !this.confirmation) {
      this.submitApplication()
    }
  },
  watch: {
    selected(newVal) {
      if (!newVal) {
        this.selectedTab = 1
      }
    },
    showParameterSummary(newVal) {
      if (newVal && this.noSubmitParameters) {
        this.validateApplication()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-mo-subscription {
  $root: &;
  &__tab-navigation {
    width: 80%;
    margin: 0 auto;
  }
  &__content {
    margin-top: toRem(24px);
    &-top {
      text-align: center;
    }
    &-body {
      margin-top: toRem(32px);
    }
  }
  &__description,
  &__notice,
  &__confirmation-box {
    margin-top: toRem(12px);
  }
  &__parameter {
    & + & {
      margin-top: toRem(16px);
    }
  }
  &__overview {
    background-color: $grey-5;
    padding: toRem(24px 36px 40px);
  }
  &__submit {
    margin: toRem(24px) auto 0;
    display: block;
  }

  &__navigation {
    margin-top: toRem(24px);
  }
  &__next {
    margin-top: toRem(12px);
  }
  &__previous {
    margin: toRem(20px) auto 0;
    display: block;
  }

  &__organization {
    margin-bottom: 10px;
  }

  &__confirmation {
    text-align: center;
    &-body {
      text-align: center;
    }
    &-icon {
      width: toRem(50px);
      height: toRem(45px);
      display: block;
      margin: 0 auto toRem(20px);
    }
    &-box {
      background-color: $grey-5;
      padding: toRem(22px) toRem(35px) toRem(40px);
    }
    &-text {
      ::v-deep a {
        color: inherit;
      }

      margin-bottom: 25px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-cta {
      margin-top: toRem(24px);
    }
    &-heading {
      margin-bottom: 20px;
      &::last-child {
        margin-bottom: 0;
      }
    }
    &-logo {
      max-height: 80px;
      margin: 0 auto 10px;
      display: block;

      & + #{$root}__confirmation-heading {
        margin-top: 38px;
      }

      &--small {
        max-height: 50px;
        margin: 0 auto;
      }
    }

    &-footer {
      margin-top: 25px;
      &::first-child {
        margin-top: 0;
      }
      &-skip {
        background: transparent;
        border: none;
      }
    }
  }

  &__payment-overview {
    margin-bottom: toRem(20px);
    td {
      padding-bottom: 10px;
    }
  }
}
</style>
