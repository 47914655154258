<template lang="pug">
.c-ub4-pm-search.o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
  .o-grid__group
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-6
      c-fc-text(:model='propertyNumberData' v-model='propertyNumber')
    .o-grid__item.o-grid__item--12.o-grid__item--desktop-5(v-if='companyData.options.length > 0')
      c-fc-select(:model='companyData' v-model='company')
    .o-grid__item.o-grid__item--desktop-1
      button.o-link.c-ub4-pm-search__button(:disabled='!selectedCompany && !selectedPropertyNumber' @click='handleReset' role='button' v-debounce.keyup='500') {{ Dictionary.R.Reset }}
</template>
<script>
import { mapState } from 'vuex'
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
export default {
  name: 'c-ub4-pm-search',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid,
      searchPropertyNumber: {
        helperText: this.model.searchHelperText,
        value: null,
        placeholderText: this.model.searchPlaceholderText,
        isMandatory: false,
        validation: {
          errorMessage: '',
          mask: '',
          displayRule: ''
        }
      },
      searchCompany: {
        helperText: this.model.ownerSelectHelperText,
        value: null,
        placeholderText: this.Dictionary.O.Owner,
        isMandatory: false,
        options: [],
        validation: null
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText
  },
  computed: {
    ...mapState('ub4PropertyManagement', ['selectedCompany', 'selectedPropertyNumber']),
    ...mapState('search', ['companies']),
    companyData() {
      const companyData = Object.assign({}, this.searchCompany)
      companyData.options = JSON.parse(JSON.stringify(this.companies)).map(company => {
        company.value = company.id
        return company
      })
      return companyData
    },
    company: {
      get() {
        return this.selectedCompany
      },
      set(value) {
        const company = value && this.companies.find(company => company.id === value)
        this.$store.commit('ub4PropertyManagement/updateData', { selectedCompany: company })
      }
    },
    propertyNumberData() {
      const propertyNumber = Object.assign({}, this.searchPropertyNumber)
      propertyNumber.value = this.propertyNumber || null
      return propertyNumber
    },
    propertyNumber: {
      get() {
        return this.selectedPropertyNumber
      },
      set(value) {
        this.$store.commit('ub4PropertyManagement/updateData', { selectedPropertyNumber: value })
      }
    }
  },
  methods: {
    handleReset() {
      this.company = null
      this.propertyNumber = null
    }
  },
  created() {
    if (!this.companies.length) this.$store.dispatch('search/getCompanies')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-pm-search {
  padding: toRem(16px 20px);
  background-color: $grey-5;

  &__button {
    width: 100%;
    min-height: 52px;
  }
}
</style>
