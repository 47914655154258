<template lang="pug">
c-modal.c-messages-overlay(:cornerClose='true', :darkTheme='false' @close='close')
  .c-users-status-change(slot='body')
    .c-users-status-change__data
      .c-users-status-change__form-section
        .c-users-status-change__form-heading.o-heading.o-heading--size-4 {{ Dictionary.R.Recipients }}
        .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
          .o-grid__group
            .o-grid__item.o-grid__item--grow-0(v-for='(userId, key) in users') {{ `${getUserName(userId)}${users.length - 1 > key ? ',' : ''}` }}
      .c-users-status-change__form-section
        .c-users-status-change__form-heading.o-heading.o-heading--size-4 {{ Dictionary.S.Status }}
        .o-grid.o-grid--direction-row.o-grid--align-items-start.o-grid--gutter-tiny
          .o-grid__group
            .o-grid__item.o-grid__item--12
              c-fc-select(:model='newStateData' @validate='validate($event, "newState")' v-model='details.newState.value')
            .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
              c-fc-date(:model='newFollowUpDateData' @validate='validate($event, "newFollowUpDate")' v-model='details.newFollowUpDate.value')
            .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
              c-fc-checkbox(:model='details.removeFollowUpDate' @validate='validate($event, "removeFollowUpDate")' v-model='details.removeFollowUpDate.value')
            .o-grid__item.o-grid__item--12.o-grid__item--desktop-9
              c-fc-textbox(:model='newNoteData' @validate='validate($event, "newNote")' v-model='details.newNote.value')
            .o-grid__item.o-grid__item--12.o-grid__item--desktop-3
              c-fc-checkbox(:model='details.removeNote' @validate='validate($event, "removeNote")' v-model='details.removeNote.value')
    .c-users-status-change__form.c-users-status-change__form--footer.o-grid.o-grid--gutter-normal.o-grid--no-overflow.o-grid--align-items-center.o-grid--justify-content-center
      .o-grid__group
        .o-grid__item.o-grid__item--grow-0
          button.o-link.c-users-status-change__cancel(@click='close') {{ Dictionary.C.Cancel }}
        .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
          button.o-button.c-users-status-change__submit(:disabled='loading || !dataChanged' @click='submitChanges') {{ Dictionary.S.Save }}
</template>
<script>
import { mapState } from 'vuex'

import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllDate from '@/components/form/controlls/form-date'
import FormControllCheckbox from '@/components/form/controlls/form-checkbox'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
import { advertManagementService, offersManagementService } from '@/_services'
import moment from 'moment'
export default {
  name: 'c-users-status-change',
  props: {
    users: {
      type: Array,
      required: true
    },
    stateType: {
      type: String,
      required: true
    },
    stateTargetId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: this._uid,

      validation: {},
      usersData: [],
      applicantOfferStatesOptions: [
        {
          name: '',
          value: ''
        },
        {
          name: this.Dictionary.RecipientStateInternal.Offered,
          value: 'Offered'
        },
        {
          name: this.Dictionary.RecipientStateInternal.Ended,
          value: 'Ended'
        }
      ],
      applicantAdvertStatusOptions: [
        { value: 'New', name: this.Dictionary.ApplicationStatus.New },
        { value: 'Interested', name: this.Dictionary.ApplicationStatus.Interested },
        { value: 'FollowUp', name: this.Dictionary.ApplicationStatus.FollowUp },
        { value: 'ShowingBooked', name: this.Dictionary.ApplicationStatus.ShowingBooked },
        { value: 'Finished', name: this.Dictionary.ApplicationStatus.Finished },
        { value: 'Offered', name: this.Dictionary.ApplicationStatus.Offered }
      ],
      details: {
        newState: {
          helperText: '',
          placeholderText: this.Dictionary.S.Status,
          value: null,
          isMandatory: false,
          options: [],
          validation: null
        },
        newFollowUpDate: {
          helperText: '',
          value: null,
          placeholderText: this.Dictionary.F.FollowUpDate,
          isMandatory: false,
          readOnly: false,
          validation: {
            minDate: moment.utc().format('YYYY-MM-DD')
          },
          key: 0
        },
        removeFollowUpDate: {
          value: false,
          helperText: '',
          placeholderText: `${this.Dictionary.R.Reset} ${this.Dictionary.F.FollowUpDate}`,
          isMandatory: false,
          readOnly: false,
          validation: null
        },
        newNote: {
          helperText: '',
          placeholderText: this.Dictionary.N.Note,
          value: null,
          isMandatory: false,
          readOnly: false,
          validation: {
            errorMessage: '',
            mask: '',
            displayRule: ''
          }
        },
        removeNote: {
          value: false,
          helperText: '',
          placeholderText: `${this.Dictionary.R.Reset} ${this.Dictionary.N.Note}`,
          isMandatory: false,
          readOnly: false,
          validation: null
        }
      }
    }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-date': FormControllDate,
    'c-fc-checkbox': FormControllCheckbox,
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapState('usersManagement', ['administrators']),
    ...mapState('applicantsManagement', ['applicants', 'applicantsPool']),
    ...mapState('search', ['companies']),
    ...mapState('loadingIndicator', ['loading']),
    newStateData() {
      const newStateData = Object.assign({}, this.details.newState)
      newStateData.options = this.stateType === 'advert' ? this.applicantAdvertStatusOptions : this.applicantOfferStatesOptions
      return newStateData
    },
    newFollowUpDateData() {
      const newFollowUpDateData = Object.assign({}, this.details.newFollowUpDate)
      newFollowUpDateData.readOnly = this.details.removeFollowUpDate.value
      return newFollowUpDateData
    },
    newNoteData() {
      const newNoteData = Object.assign({}, this.details.newNote)
      newNoteData.readOnly = this.details.removeNote.value
      return newNoteData
    },
    dataChanged() {
      let dataChanged = false
      const target = Object.keys(this.details).find(key => {
        let different = false
        switch (key) {
          case 'removeFollowUpDate':
          case 'removeNote':
            different = this.details[key].value
            break
          case 'newState':
            different = this.details[key].value !== undefined && this.details[key].value !== null
            break
          default:
            different = this.details[key].value !== undefined && this.details[key].value !== null && this.details[key].value !== ''
        }
        // console.log(different, key)
        return different
      })

      dataChanged = !!target
      return dataChanged
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getUserName(id) {
      const target = this.applicantsPool.find(a => a.userId === id)
      const name = target && (!!target.firstName || !!target.lastName) ? `${target.firstName || ''} ${target.lastName || ''}` : target && target.email
      return name
    },
    async submitChanges() {
      try {
        let response
        const data = {
          users: this.users,
          removeFollowUpDate: this.details.removeFollowUpDate.value,
          removeNote: this.details.removeNote.value
        }

        if (this.details.newState.value !== null) {
          if (this.stateType === 'advert') {
            data.newStatus = this.details.newState.value
          }
          if (this.stateType === 'offer') {
            data.newState = this.details.newState.value
          }
        }
        if (this.details.newFollowUpDate.value !== null) {
          data.newFollowUpDate = this.details.newFollowUpDate.value
        }
        if (this.details.newNote.value !== null) {
          data.newNote = this.details.newNote.value
        }

        if (this.stateType === 'advert') {
          response = await advertManagementService.setApplicationsState(this.stateTargetId, data)
          if (response.status === 204) {
            this.$store.dispatch('advertManagement/getAdvertApplicantsData')
            this.close()
          }
        }
        if (this.stateType === 'offer') {
          response = await offersManagementService.setRecipientsState(this.stateTargetId, data)
          if (response.status === 200) {
            this.$store.dispatch('offersManagement/setSelected', this.stateTargetId)
            this.close()
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-users-status-change {
  $sectionMargin: 25px;
  $sidePadding: 25px;
  $root: &;
  &__top {
    background-color: $ocean-primary;
    color: $white;
    padding: 18px $sidePadding 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    position: relative;

    @include min('desktop') {
      background-color: transparent;
      padding: 0;
      min-height: 0;
      margin-bottom: 25px;
      box-shadow: none;
    }

    @include max('desktop', -1) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;

      & + #{$root}__info {
        margin-top: 62px;
      }
    }
  }
  &__close {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &-text {
      color: $white;
      @include min('desktop') {
        color: $ocean-darker;
      }
    }
    &-icon {
      width: 28px;
      height: 24px;
      margin: 0 10px 0 0;
      fill: $white;
      @include min('desktop') {
        width: 20px;
        height: 20px;
        fill: $ocean-darker;
      }
    }
  }
  &__icon {
    width: 24px;
    height: 20px;
    margin: 0 10px 0 0;
    fill: $white;
    @include min('desktop') {
      width: 20px;
      height: 20px;
      fill: $ocean-darker;
    }

    @include max('desktop', -1) {
      margin: 0 auto;
      display: block;
    }

    &--verified {
      fill: $ok-green;
    }
    &--denied {
      fill: $error-red;
    }
  }
  &__info {
    padding: toRem(16px 25px);
    background-color: $grey-5;
  }
  &__data {
    margin-top: 20px;

    @include max('desktop', -1) {
      padding: 0 25px;
    }
  }
  &__form {
    &--footer {
      margin-top: $sectionMargin;
      padding-top: $sectionMargin;
      border-top: 1px solid $grey-4;

      @include max('desktop', -1) {
        padding: $sectionMargin 25px 0;
      }
    }
    &-section {
      & + & {
        margin-top: $sectionMargin;
      }

      &--border-top {
        border-top: 1px solid $grey-4;
      }
    }
    &-heading {
      margin-bottom: 10px;
      @include min('desktop') {
        margin-bottom: 20px;
      }
    }
  }
  &__table {
    margin-bottom: 10px;
  }

  &__parameters {
    &-heading {
      margin-bottom: 10px;
    }
    &-section {
      margin-bottom: 15px;
    }
    &-answer {
      color: $grey-3;
    }
  }
}
</style>
