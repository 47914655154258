import { advertManagementService, messagesService, propertyManagementService } from '@/_services'
import _ from 'lodash'

const initialState = {
  adverts: [],
  selected: undefined,
  types: [],
  promotionSettings: undefined,
  serverParams: {
    columnFilters: {},
    sort: [
      {
        field: '',
        type: ''
      }
    ],
    page: 1,
    perPage: 10
  },
  totalResults: 0,
  confirmationTarget: undefined
}

const initialSelected = {
  id: undefined,
  openHouses: [],
  media: {
    blueprints: [],
    images: [],
    panoramas: [],
    videos: []
  }
}

export const advertManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    all: state => state,
    haveActiveApplicants: state => {
      return !!state.selected && !!state.selected.applicantsData && state.selected.applicantsData.some(a => a.status !== 'Deleted')
    },
    advertApplicantsIds: state => {
      return !!state.selected && !!state.selected.applicantsData && state.selected.applicantsData.map(a => a.userId)
    }
  },
  actions: {
    async setSelected({ commit, state, rootState }, id) {
      if (id) {
        try {
          if (!state.promotionSettings) {
            const promotionSettingsResponse = await advertManagementService.getPromotionsSettings(id)
            if (promotionSettingsResponse && promotionSettingsResponse.status === 200) {
              commit('updateData', { promotionSettings: promotionSettingsResponse.data })
            }
          }
          const response = await advertManagementService.getAdvert(id)
          if (response && response.status === 200) {
            const selectedAdvert = response.data
            const advertCompany = rootState.search.companies.find(c => c.id === selectedAdvert.companyId)
            if (advertCompany && advertCompany.rentModel === 'MemberAdvert') {
              const subOrgResponse = await propertyManagementService.getPropertyMembershipSuborganizationOwners(
                selectedAdvert.propertyId,
                selectedAdvert.residenceId
              )
              if (subOrgResponse && subOrgResponse.status === 200) {
                selectedAdvert.subOrganizations = subOrgResponse.data
              }
            }
            if (!['PublishedNotScreened', 'Published'].includes(selectedAdvert.status)) {
              const responsePublishadble = await advertManagementService.getAdvertPublishable(id)
              if (responsePublishadble && responsePublishadble.status === 200) {
                selectedAdvert.publishable = responsePublishadble.data
              }
            }

            const responsePromotions = await advertManagementService.getPromotions(id)
            if (responsePromotions && responsePromotions.status === 200) {
              selectedAdvert.promotions = responsePromotions.data
            }

            const responseStatistics = await advertManagementService.getAdvertStatistics(id)
            if (responseStatistics && responseStatistics.status === 200) {
              selectedAdvert.statistics = responseStatistics.data
            }

            const responseStatisticsThreads = await messagesService.postThreadsCount({
              relatedEntities: [id]
            })
            if (responseStatisticsThreads && responseStatisticsThreads.status === 200) {
              selectedAdvert.statistics.totalThreads = responseStatisticsThreads.data
            }
            if (selectedAdvert.statistics.totalThreads > 0) {
              const responseStatisticsThreadsUnread = await messagesService.postThreadsCount({
                relatedEntities: [id],
                status: 'Unread'
              })
              selectedAdvert.statistics.unreadThreads = responseStatisticsThreadsUnread.data || 0
            }
            commit('updateData', { selected: selectedAdvert })

            this.dispatch('advertManagement/getAdvertApplicantsData')
          }
        } catch (error) {
          if (error.response.status === 409) {
            this.dispatch('toast/show', { data: { errorCode: 'AdvertDuplicateAddress' }, status: error.response.status })
          }

          this.dispatch('toast/remove', { url: `/data/adverts/residences/${id}` })
        }
      }
    },
    async setSelectedFromResidence({ commit, rootState }, id) {
      if (id) {
        try {
          const response = await advertManagementService.getAdvertDataFromResidence(id)
          if (response && response.status === 200) {
            const selectedAdvert = response.data
            const advertCompany = rootState.search.companies.find(c => c.id === selectedAdvert.companyId)
            if (advertCompany && advertCompany.rentModel === 'MemberAdvert') {
              const subOrgResponse = await propertyManagementService.getPropertyMembershipSuborganizationOwners(
                selectedAdvert.propertyId,
                selectedAdvert.residenceId
              )
              if (subOrgResponse && subOrgResponse.status === 200) {
                selectedAdvert.subOrganizations = subOrgResponse.data
              }
            }
            selectedAdvert.advertFromResidence = true
            selectedAdvert.openHouses = []
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    createNew({ commit }) {
      commit('createNew')
    },
    async createAdvertCopy(_, payload) {
      try {
        const response = await advertManagementService.createAdvertCopy(payload)
        if (response && response.status === 200) {
          this.dispatch('advertManagement/setSelected', response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createAdvert({ commit, state }, { data, closeSelection }) {
      try {
        let response
        data.openHouses = state.selected.openHouses
        if (state.selected.advertFromResidence) {
          response = await advertManagementService.createAdvertFromResidence(data, state.selected.residenceId)
        } else {
          response = await advertManagementService.createAdvert(data)
        }
        if (response && response.status === 200) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            this.dispatch('advertManagement/setSelected', response.data)
          }
        }
      } catch (error) {
        if (error.response.status === 409) {
          this.dispatch('toast/show', { data: { errorCode: 'AdvertDuplicateAddress' }, status: error.response.status })
          this.dispatch('toast/remove', { url: '/data/adverts/residences' })
        }
      }
    },
    async patchAdvert({ commit, state }, { data, closeSelection }) {
      try {
        const residenceAdvertData = data

        if (data.type === 'MembershipAdvertBasedOnResidence') {
          residenceAdvertData.applyBeforeDate = data.applyBeforeDate
        }

        if (data.type !== 'AdvertBasedOnResidence' && data.type !== 'MembershipAdvertBasedOnResidence') {
          delete residenceAdvertData.type
        }

        delete residenceAdvertData.excludeFromSearch

        Object.keys(residenceAdvertData).forEach(key => {
          if (state.selected[key] === data[key] || _.isEqual(state.selected[key], data[key])) delete residenceAdvertData[key]
        })
        const response = await advertManagementService.patchAdvert(residenceAdvertData, state.selected.id)
        if (response && response.status === 204) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            const newSelected = Object.assign(state.selected, data)
            if (!['PublishedNotScreened', 'Published'].includes(state.selected.status)) {
              const responsePublishadble = await advertManagementService.getAdvertPublishable(state.selected.id)
              newSelected.publishable = responsePublishadble.data
            }

            commit('updateData', { selected: newSelected })
            commit('toggleConfirmation', false)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async searchManagedAdverts({ commit, state }, payload) {
      try {
        let response
        payload.pageSize = payload.pageSize || state.serverParams.perPage
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = (payload.page !== undefined && payload.page) || state.serverParams.page - 1
        if (state.serverParams.sort[0].type && state.serverParams.sort[0].type !== 'none') {
          payload.orderDirection = state.serverParams.sort[0].type
          payload.orderBy = state.serverParams.sort[0].field === 'selectableAddress' ? 'address' : state.serverParams.sort[0].field
        }
        if (payload.screening) {
          delete payload.screening
          response = await advertManagementService.searchAdvertsScreening(payload)
        } else {
          response = await advertManagementService.searchAdverts(payload)
        }

        if (response && response.status === 200) {
          const updateData = { adverts: response.data.results, totalResults: response.data.totalResults }
          if (response.data.totalResults === 0) {
            updateData.serverParams = Object.assign(state.serverParams, { page: 1, perPage: 10 })
          }
          commit('updateData', updateData)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    },
    async rejectAdvert({ commit, state }, payload) {
      try {
        const response = await advertManagementService.rejectAdvert({ id: state.confirmationTarget.id, data: { reason: payload.reason } })
        if (response && response.status === 204) {
          commit('deleteEntry', state.confirmationTarget.id)
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async approveAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.approveAdvert(state.confirmationTarget.id)
        if (response && response.status === 204) {
          commit('deleteEntry', state.confirmationTarget.id)
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    addMedia({ commit, state }, payload) {
      payload.advertId = state.selected.id

      const fileTypes = ['images', 'blueprints', 'panoramas', 'panoramas360']
      const formField = !payload.panoramaLink && fileTypes.includes(payload.type) ? 'files' : 'uri'

      let formData = new FormData()

      const batches = []
      let counter = 0

      if (formField === 'uri') {
        formData.append(formField, payload.media)
      } else {
        Object.keys(payload.media).forEach(mediaKey => {
          if (counter <= 4) {
            formData.append(formField, payload.media[mediaKey])
            counter++
          } else {
            batches.push(formData)
            formData = new FormData()
            formData.append(formField, payload.media[mediaKey])
            counter = 1
          }
        })
      }
      batches.push(formData)

      function sendBatch() {
        payload.formData = batches.pop()
        advertManagementService
          .addMedia(payload)
          .then(response => {
            if (response && response.status === 200) {
              commit('addMedia', { type: payload.type, items: response.data })
              if (batches.length) sendBatch()
            }
          })
          .catch(() => {})
      }

      sendBatch()
    },
    async removeMedia({ commit, state }, payload) {
      try {
        payload.advertId = state.selected.id
        const response = await advertManagementService.removeMedia(payload)
        if (response && response.status === 204) {
          commit('removeMedia', payload)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    moveMedia({ state }, payload) {
      payload.advertId = state.selected.id
      // const orderChanged = state.selected.media[payload.mediaCategory].length - 1 !== payload.mediaIndex
      function changeOrder(payload) {
        advertManagementService
          .moveMedia({ advertId: state.selected.id, data: payload })
          .then(response => {
            return response
          })
          .catch(error => {
            return error
          })
      }
      function changeCategory(payload) {
        advertManagementService
          .changeMedia(payload)
          .then(response => {
            if (response && response.status === 204) {
              // console.log(payload.mediaIndex)
              changeOrder({
                targetMedia: payload.mediaId,
                insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
                  ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
                  : null
              })
            }
          })
          .catch(error => {
            return error
          })
      }

      if (payload.mediaType) {
        changeCategory(payload)
      } else {
        changeOrder({
          targetMedia: payload.mediaId,
          insertBefore: state.selected.media[payload.mediaCategory][payload.mediaIndex + 1]
            ? state.selected.media[payload.mediaCategory][payload.mediaIndex + 1].id
            : null
        })
      }
    },
    async publishAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.publishAdvert(state.confirmationTarget.id)
        if (response && (response.status === 200 || response.status === 304)) {
          commit('toggleConfirmation', false)
          if (response.status === 200) {
            const newSelected = { ...state.selected, ...response.data }
            commit('updateData', { selected: newSelected })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async publishAdvertWithSubOrg({ commit, state }, { id, data }) {
      try {
        const response = await advertManagementService.publishAdvertWithSubOrg({ id, data })
        if (response && response.status === 200) {
          commit('toggleConfirmation', false)
          const newSelected = { ...state.selected, ...response.data }
          commit('updateData', { selected: newSelected })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async reserveAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.reserveAdvert(state.confirmationTarget.id)
        if (response && (response.status === 200 || response.status === 304)) {
          commit('toggleConfirmation', false)
          if (response.status === 200) {
            const newSelected = { ...state.selected, ...response.data }
            commit('updateData', { selected: newSelected })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async rentOutAdvert({ commit, state }) {
      try {
        const stateResponse = await advertManagementService.setApplicationsState(state.confirmationTarget.id, {
          users: [state.confirmationTarget.userId],
          newStatus: 'RentedOut'
        })

        if (stateResponse && stateResponse.status === 204) {
          this.dispatch('advertManagement/setSelected', state.selected.id)
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async rentOutToExternalAdvert({ commit, state }) {
      try {
        const rentOutToExternalAdvertResponse = await advertManagementService.rentOutToExternalAdvert(state.confirmationTarget.id)

        if (rentOutToExternalAdvertResponse && rentOutToExternalAdvertResponse.status === 200) {
          this.dispatch('advertManagement/setSelected', state.selected.id)
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async cancelOutAdvert({ commit, state }) {
      try {
        const stateResponse = await advertManagementService.cancelRentedOut(state.confirmationTarget.id)

        if (stateResponse && stateResponse.status === 200) {
          this.dispatch('advertManagement/setSelected', state.selected.id)
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async retireAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.retireAdvert(state.confirmationTarget.id)
        if (response && (response.status === 200 || response.status === 304)) {
          commit('toggleConfirmation', false)
          if (response.status === 200) {
            const newSelected = { ...state.selected, ...response.data }
            commit('updateData', { selected: newSelected })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async renewAdvert({ commit, state }, payload) {
      try {
        const response = await advertManagementService.renewAdvert({ id: state.confirmationTarget.id, data: payload })
        if (response && (response.status === 200 || response.status === 304)) {
          commit('toggleConfirmation', false)
          if (response.status === 200) {
            const newSelected = { ...state.selected, ...response.data }
            commit('updateData', { selected: newSelected })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async releaseAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.releaseAdvert(state.confirmationTarget.id)
        if (response && (response.status === 200 || response.status === 304)) {
          commit('toggleConfirmation', false)
          if (response.status === 200) {
            const newSelected = { ...state.selected, ...response.data }
            commit('updateData', { selected: newSelected })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async extendExpirationAdvert({ commit, state }) {
      try {
        const response = await advertManagementService.extendExpirationAdvert(state.confirmationTarget.id)
        if (response && response.status === 200) {
          if (response.data) {
            commit('updateEntry', { id: state.confirmationTarget.id, expirationDate: response.data.expiresAt })
          }
          commit('toggleConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getAdvertApplicantsData({ commit, state }) {
      try {
        const advertAplicantsDataResponse = await advertManagementService.getAdvertApplications(state.selected.id)
        if (advertAplicantsDataResponse && advertAplicantsDataResponse.status === 200) {
          if (advertAplicantsDataResponse.data) {
            commit('updateAdvertApplicantsData', advertAplicantsDataResponse.data)
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async setApplicantState({ state }, payload) {
      try {
        const stateResponse = await advertManagementService.setApplicantState(payload)

        if (stateResponse && stateResponse.status === 204) {
          this.dispatch('advertManagement/setSelected', state.selected.id)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    updateAdvertOpenHousesData({ state }, payload) {
      if (state.selected && state.selected.id) {
        this.dispatch('advertManagement/patchAdvert', { data: { openHouses: payload }, closeSelection: false })
      }
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    deleteEntry(state, payload) {
      state.adverts.splice(state.adverts.indexOf(state.adverts.find(advert => advert.id === payload)), 1)
      state.selected = undefined
      state.totalResults = --state.totalResults
    },
    updateEntry(state, payload) {
      const targetAdvert = state.adverts.find(advert => advert.id === payload.id)
      if (targetAdvert) {
        Object.keys(payload).forEach(key => {
          if (key !== 'id') {
            targetAdvert[key] = payload[key]
          }
        })
      }
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    updateParams(state, payload) {
      state.serverParams = Object.assign(state.serverParams, payload)
    },
    updateAdvertApplicantsData(state, payload) {
      if (state.selected) {
        state.selected = { ...state.selected, applicantsData: payload }
      }
    },
    updateAdvertOpenHousesData(state, payload) {
      if (state.selected) {
        if (payload.length === 0) {
          state.selected.openHouses = []
        } else {
          Object.assign(state.selected.openHouses, payload)
        }
      }
    },
    toggleConfirmation(state, payload) {
      state.confirmationTarget = payload
    },
    addMedia(state, payload) {
      if (Array.isArray(payload.items)) {
        payload.items.forEach(item => {
          state.selected.media[payload.type].push(item)
        })
      } else {
        state.selected.media[payload.type].push(payload.items)
      }
    },
    removeMedia(state, payload) {
      if (payload) {
        state.selected.media[payload.type].splice(
          state.selected.media[payload.type].indexOf(state.selected.media[payload.type].find(media => media.id === payload.mediaId)),
          1
        )
      }
    }
  }
}
