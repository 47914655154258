/* eslint-disable prettier/prettier */
<template lang="pug">
.c-ub4-pm-overview
  .c-ub4-pm-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.title }}
    button.o-button.c-ub4-pm-overview__button(:title='Dictionary.N.NewMapping' @click='createNew') {{ Dictionary.N.NewMapping }}
  keep-alive
    c-ub4-pm-search(:model='model')
  .c-ub4-pm-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
  .c-ub4-pm-overview__data
    vue-good-table(:columns='columns', :pagination-options='paginationSettings', :rows='filteredResults' styleClass='o-table')
      template(slot='emptystate') {{ model.noResultsText }}
      template(slot='table-row' slot-scope='props')
        template(v-if='props.column.field == "number"') {{ props.row.companyNo }}-{{ props.row.propertyNo }}
        template(v-if='props.column.field == "id"')
          .o-grid.o-grid--gutter-tiny
            .o-grid__group
              .o-grid__item.o-grid__item--grow-0
                button.c-state-action(@click='handleDelete(props.row)')
                  svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                    use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-delete' y='0')
                  span.o-bodytext.o-bodytext--size-small {{ Dictionary.D.Delete }}
        template(v-else) {{ props.formattedRow[props.column.field] }}
    .c-search-result__loading(v-if='loading')
      c-loading-animation(:overlay='true')
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import Ub4PropertyManagementSearch from '../ub4-pm-search'

import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'c-ub4-pm-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,
      pageOfItems: [],
      resetPaging: false,
      page: 1,
      columns: [
        {
          label: this.Dictionary.P.PropertyNumber,
          field: 'propertyNumber'
        },
        {
          label: this.Dictionary.L.Landlord,
          field: 'propertyAdministrators'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'owner'
        },
        {
          label: this.Dictionary.D.Distribution,
          field: 'percentage'
        },
        {
          label: '',
          field: 'id',
          sortable: false,
          width: '195px'
        }
      ]
    }
  },
  components: {
    'c-ub4-pm-search': Ub4PropertyManagementSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('ub4PropertyManagement', ['ownerships', 'pageSize']),
    ...mapState('usersManagement', ['administrators']),
    ...mapGetters('ub4PropertyManagement', ['selectedCompanyNo', 'selectedPropertyNo', 'selectedCompanyId']),
    ...mapState('loadingIndicator', ['loading']),
    totalResults() {
      return this.filteredResults.length
    },
    enablePagination() {
      return this.pageSize < this.totalResults
    },
    ownershipsData() {
      return this.ownerships.map(ownership => {
        ownership.propertyNumber = `${ownership.companyNo}-${ownership.propertyNo}`
        ownership.owner = ownership.company.name
        ownership.propertyAdministrators = this.administrators
          .filter(administrator => administrator.companies.includes(ownership.company.id))
          .map(administrator => administrator.name)
          .toString()
        return ownership
      })
    },
    filteredResults() {
      const filteredResults = this.ownershipsData.filter(ownership => {
        let companyNoPassed = true
        let propertyNoPassed = true
        let companyIdPassed = true
        if (this.selectedCompanyNo) {
          companyNoPassed = ownership.companyNo === this.selectedCompanyNo
        }

        if (this.selectedPropertyNo) {
          propertyNoPassed = ownership.propertyNo === this.selectedPropertyNo
        }
        if (this.selectedCompanyId) {
          companyIdPassed = ownership.company.id === this.selectedCompanyId
        }
        return companyNoPassed && propertyNoPassed && companyIdPassed
      })
      return filteredResults
    },
    // concatinatedResults() {
    //   const concatinatedResults = []
    //   this.filteredResults.forEach(result => {
    //     const existingCategorty = concatinatedResults.find(includedResult => includedResult.number === `${result.companyNo}-${result.propertyNo}`)
    //     if (existingCategorty) {
    //       existingCategorty.children.push(result)
    //     } else {
    //       concatinatedResults.push({
    //         number: `${result.companyNo}-${result.propertyNo}`,
    //         children: [result]
    //       })
    //     }
    //   })
    //   return concatinatedResults
    // },
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    }
  },
  methods: {
    createNew() {
      this.$store.dispatch('ub4PropertyManagement/createNew')
    },
    getRowOwner(value) {
      return value.name
    },
    handleDelete(target) {
      this.$store.dispatch('ub4PropertyManagement/toggleDeleteConfirmation', target)
    }
  },
  created() {
    this.$store.dispatch('ub4PropertyManagement/getUb4Ownerships')
  }
}
</script>
<style lang="scss" scoped>
.c-ub4-pm-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
