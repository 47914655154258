<template lang="pug">
.c-mo-overview
  .c-mo-overview__heading.o-heading.o-heading--size-2.o-heading--profile-page
    svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
      use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-brandicon-3' y='0')
    h1 {{ model.headline }}
  slider(:goto='currentSlide', :slidesCount='offersResidences.length' reference='actionSlider' v-bind:settings='sliderSetup' v-if='offersResidences.length')
    c-property-card(
      :facilities='facilities',
      :key='slide.id',
      :link-action='true',
      :property='slide'
      @action='setSelected(slide.userOffer.id)'
      @focusedOnTab='gotoTabbedSlide'
      v-for='slide in offersResidences'
    )
      .property-card__actions(slot='cardActions' v-if='slide.userOffer')
        .o-grid.o-grid--wrap-nowrap.o-grid--justify-content-space.o-grid--align-items-center
          .o-grid__group
            .o-grid-item
              .o-bodytext(v-html='Dictionary.A.AnswerBeforeDeadline.replace("{deadline}", `</br>${formatDate(slide.userOffer.deadline)}`)')
            .o-grid-item
              button.o-button(@click.prevent='setSelected(slide.userOffer.id)') {{ Dictionary.S.SeeOffer }}
  keep-alive
    c-mo-search(:model='model') 
  .o-wrap.o-wrap--relative(v-if='offersData.length')
    .c-mo-overview__count.o-heading.o-heading--size-4 {{ searchResultsCountText }}
    .c-mo-overview__data(v-if='!isMobile')
      vue-good-table(
        :columns='columns',
        :pagination-options='paginationSettingsData',
        :rows='offersData',
        :sort-options='{ enabled: true, initialSortBy: { field: "receivedDate", type: "desc" } }',
        :totalRows='totalResults'
        styleClass='o-table'
      )
        template(slot='emptystate') {{ model.noResultsText }}
        template(slot='table-row' slot-scope='props')
          template(v-if='props.column.field == "actions"')
            button.c-state-action(@click='setSelected(props.row.id)')
              svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-paper' y='0')
              span.o-bodytext.o-bodytext--size-small {{ Dictionary.S.SeeOffer }}
          template(v-else) {{ props.formattedRow[props.column.field] }}
      .c-search-result__loading(v-if='loading')
        c-loading-animation(:overlay='true')
    .c-mo-overview__data.c-mo-overview__data--mobile(v-else)
      .c-mo-overview__item.c-mo-overview__item--table-wrap(:key='`offers-item-table-${key}`' v-for='(item, key) in pageOfItems')
        table
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.A.Address }}
            td
              span.c-mo-overview__item--name.o-link.o-link--small {{ item.residenceAddress }}
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.O.Owner }}
            td.o-bodytext.o-bodytext--size-small {{ item.company }}
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.R.Received }}
            td.o-bodytext.o-bodytext--size-small {{ item.receivedDate | formatDate }}
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.S.Status }}
            td.o-bodytext.o-bodytext--size-small {{ item.status }}
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.D.Deadline }}
            td.o-bodytext.o-bodytext--size-small {{ item.deadline | formatDate }}
          tr
            td.c-mo-overview__column.c-mo-overview__column--info.o-bodytext--size-small.o-bodytext--700 {{ Dictionary.M.MyAnswer }}
            td.o-bodytext.o-bodytext--size-small {{ item.answer }}
          tr
            td.o-bodytext.o-bodytext--size-small.c-mo-overview__column.c-mo-overview__column--actions(colspan='2')
              button.c-state-action.o-button.o-button--small(@click='setSelected(item.id)') {{ Dictionary.S.SeeOffer }}
      c-pagination(:items='offersData' @changePage='onChangePage')
</template>
<script>
import { mapState } from 'vuex'

import MyOffersSearch from '../mo-search'
import { getQueryParameters } from '@/_helpers/query-parameters'
import { VueGoodTable } from 'vue-good-table'
import slider from '@/components/slider/index'
import { searchService } from '../../../_services'
import moment from 'moment'
export default {
  name: 'c-mo-overview',
  props: {
    model: Object,
    perPage: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      pageOfItems: [],
      resetPaging: false,
      offerFromResidenceId: getQueryParameters().get('offerFromResidenceId') || undefined,
      offerId: getQueryParameters().get('offerId') || undefined,
      offersResidences: [],
      currentSlide: '',
      columns: [
        {
          label: this.Dictionary.A.Address,
          field: 'residenceAddress'
        },
        {
          label: this.Dictionary.O.Owner,
          field: 'company'
        },
        {
          label: this.Dictionary.R.Received,
          field: 'receivedDate',
          width: '110px',
          formatFn: this.reFormatDate
        },
        {
          label: this.Dictionary.S.Status,
          field: 'status'
        },
        {
          label: this.Dictionary.D.Deadline,
          field: 'deadline',
          width: '110px',
          formatFn: this.reFormatDate
        },
        {
          label: this.Dictionary.M.MyAnswer,
          field: 'answer',
          sortable: false
        },
        {
          label: this.Dictionary.O.Offer,
          field: 'actions',
          sortable: false
        }
      ]
    }
  },
  components: {
    slider,
    'c-mo-search': MyOffersSearch,
    VueGoodTable
  },
  computed: {
    ...mapState('offersManagement', ['offers', 'totalResults', 'serverParams']),
    ...mapState('user', ['userId']),
    ...mapState('search', ['companies', 'facilities']),
    ...mapState('loadingIndicator', ['loading']),
    searchResultsCountText() {
      return this.model.resultsCountText ? this.model.resultsCountText.replace('{0}', this.totalResults ? this.totalResults : 0) : this.totalResults
    },
    paginationSettingsData() {
      const paginationSettingsData = Object.assign({}, this.paginationSettings, { setCurrentPage: this.serverParams.page, enabled: this.totalResults > 0 })
      return paginationSettingsData
    },
    sliderSetup() {
      const sliderSetup = this.sliderSettings
      sliderSetup.slidesToShow = 2.3
      return sliderSetup
    },
    companyOptions() {
      return JSON.parse(JSON.stringify(this.offers)).map(company => {
        company.value = company.id
        return company
      })
    },
    offersData() {
      const offersData =
        (this.offers.length > 0 &&
          this.offers.map(o => {
            const offerData = { ...o }
            const labels = this.getLabels(offerData)
            offerData.receivedDate = offerData.recipients[0].received || offerData.recipients[0].created
            offerData.status = labels.status
            offerData.answer = labels.answer

            return offerData
          })) ||
        []
      return offersData
    },
    missingResponseResidences() {
      const residencesIds = this.offers
        .filter(offer => {
          const recipient = offer.recipients[0]
          const noAnswer = recipient.state === 'OfferReceived' && !recipient.accepted && !recipient.declined
          const deadlinePassed = moment.utc(offer.deadline).isBefore(moment.utc(), 'day')

          return noAnswer && !deadlinePassed
        })
        .map(o => o.residenceId)

      return residencesIds
    }
  },
  methods: {
    reFormatDate(value) {
      return this.formatDate(value)
    },
    gotoTabbedSlide(page) {
      if (page < this.offersResidences.length) {
        this.currentSlide = page
      }
    },
    setSelected(id, propertyId) {
      this.$store.dispatch('offersManagement/setSelected', id)
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    getLabels(item) {
      const accepted = item.recipients[0] && item.recipients[0].state === 'OfferAccepted',
        declined = item.recipients[0] && item.recipients[0].state === 'OfferDeclined',
        noAnswer = item.recipients[0] && item.recipients[0].state === 'OfferReceived',
        deadlinePassed = moment.utc(item.deadline).isBefore(moment.utc(), 'day'),
        labels = { answer: '', status: '' }

      if (noAnswer && deadlinePassed) {
        labels.status = this.Dictionary.RecipientState.OfferResponseDeadlineExpired
        return labels
      }
      if (declined) {
        labels.answer = this.Dictionary.RecipientState.OfferDeclined
      }
      if (accepted) {
        labels.answer = this.Dictionary.RecipientState.OfferAccepted
      }

      switch (item.state) {
        case 'Published':
        case 'Changed':
          labels.status = accepted ? this.Dictionary.RecipientState.OfferWaitingForPropAdmin : ''
          break
        case 'Finished':
          labels.status = accepted
            ? this.Dictionary.RecipientState.OfferWaitingForPropAdmin
            : noAnswer
            ? this.Dictionary.RecipientState.OfferResponseDeadlineExpired
            : ''
          break
        case 'Awarded':
        case 'RetiredFromAwarded':
          labels.status =
            item.winnerId === item.recipients[0].userId
              ? this.Dictionary.RecipientState.OfferAwardedToYou
              : item.recipients[0].internalState === 'JumpedOff'
              ? this.Dictionary.RecipientState.OfferJumpedOff
              : this.Dictionary.RecipientState.OfferAwardedToSomeoneElse
          break
        case 'AwardedExternally':
          labels.status = this.Dictionary.RecipientState.OfferAwardedToSomeoneElse
          break
        case 'Released':
          labels.status = this.Dictionary.RecipientState.OfferReleased
          break
        default:
          labels.status = declined ? '' : item.state
          break
      }

      return labels
    },
    async getOffersResidences() {
      try {
        const data = {
          filters: {
            id: this.missingResponseResidences
          },
          pageSize: 2147483647,
          page: 0,
          mixedResults: false,
          mode: 'ResidencesWithoutAdvertRequirement'
        }
        const response = await searchService.postSearch(data)
        if (response && response.status === 200) {
          const mergedResidenceOffers = response.data.results.map(residence => {
            const offer = this.offers.find(o => o.residenceId === residence.id)
            residence.userOffer = offer
            return residence
          })

          this.offersResidences = mergedResidenceOffers
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mounted() {
    if (this.offerId) {
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
      this.setSelected(this.offerId)
    } else {
      this.$store.commit('offersManagement/updateParams', {
        page: 1,
        perPage: 2147483647,
        sort: [
          {
            type: 'desc',
            field: 'created'
          }
        ]
      })
    }
  },
  watch: {
    missingResponseResidences(newVal, oldVal) {
      if (newVal.filter(id => !oldVal.includes(id)).length > 0 || newVal.length < oldVal.length) {
        this.getOffersResidences()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-mo-overview {
  &__data {
    position: relative;
    min-height: 280px;
  }
  &__heading {
    padding-bottom: toRem(20px);
    flex-wrap: wrap;
    svg {
      stroke: $ocean-darker;
    }
  }

  &__count {
    font-family: 'Inria Serif', serif;
    margin: toRem(20px 0);
    font-weight: 700;
  }
  &__button {
    margin-left: auto;

    & + & {
      margin-left: 10px;
    }
    @include max('desktop', -1) {
      margin-top: toRem(10px);
      width: 100%;

      & + & {
        margin-left: 0px;
      }
    }
  }
  &__item {
    &-wrap {
      position: relative;
    }
    &--table-wrap {
      position: relative;
      border-bottom: toRem(1px) solid $grey-4;
      padding: 15px;

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        background-color: $grey-6;
      }

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }
    }

    &--name {
      text-align: left;
    }
    &--logo {
      width: 100%;
      height: 40px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      display: block;
    }
  }
  &__column {
    &--info {
      width: 35%;
    }
    &--actions {
      text-align: right;
    }
  }
}
</style>
