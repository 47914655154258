<template lang="pug">
.c-zip.o-grid.o-grid--gutter-small.o-grid--align-items-end
  .o-grid__group
    .o-grid__item.o-grid__item--8(v-show='showSelect')
      .o-input__container
        .o-input__container
          label.o-label.o-label--before(:for='`${id}-unZip`' v-if='model && model.labelText') {{ model.labelText }}
          .o-input__wrap
            input.o-input(
              :accept='acceptableFormats.toString()',
              :id='`${id}-unZip`',
              :name='`${id}-unZip`'
              @change='filesChange($event.target.name, $event.target.files); inputFileCount = $event.target.files.length'
              placeholder=' '
              ref='uploadInput'
              type='file'
            )
            label.o-label.o-label--placeholder(:id='`${id}-unZip`' v-if='model && model.placeholderText') {{ model.placeholderText }}
    .o-grid__item.o-grid__item--4
      label.c-zip__upload.o-button(:class='{ "o-button--inactive": loading }', :for='`${id}-unZip`')
        svg.o-svg-icon(type='image/svg+xml' v-if='!loading' viewbox='0 0 64 64')
          use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-arrow-up' y='0')
        c-loading-animation(:inline='true' offsetTop='0' v-else)
    .o-grid__item.o-grid__item--12(v-if='showDownload')
      a(:download='file.name', :href='fileToURL(file)', :key='key' v-for='(file, key) in zipFiles')
        p {{ file.name }}
        img(:src='zipFiles64[key]' style='width: 200px')
</template>
<script>
import JSZip from 'jszip'
export default {
  name: 'c-zip',
  data() {
    return {
      id: this._uid,
      inputFileCount: 0,
      maxFileSize: 5242880,
      answer: null,
      zipFiles64: [],
      zipFiles: [],
      initialAnswer: undefined,
      acceptableFormats: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip'],
      zipPending: 0,
      loading: false
    }
  },
  props: {
    model: {
      type: Object
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async filesChange(name, files) {
      const newZip = new JSZip()
      this.loading = true
      try {
        const data = await newZip.loadAsync(files[0])
        const dataFiles = data.files
        const that = this
        Object.keys(dataFiles).forEach(async function (key) {
          try {
            that.zipPending++
            const fileFromZip = await data.file(key).async('Uint8Array')
            const type = that.getFileType(fileFromZip)

            const blob = new Blob([fileFromZip], {
              type
            })

            blob.name = key
            blob.lastModifiedDate = new Date()

            that.zipFiles.push(blob)
            that.zipPending--
            if (that.showDownload) that.fileToBase64(blob)
          } catch (error) {
            // console.log(error)
          }
        })
      } catch (error) {
        //   console.log(error)
      }

      this.loading = false
    },
    getFileType(sequence) {
      const arr = sequence.subarray(0, 4)
      let header = ''
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }

      // Check the file signature against known types
      let type = 'unknown'
      switch (header) {
        case '89504e47':
          type = 'image/png'
          break
        case '47494638':
          type = 'image/gif'
          break
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          type = 'image/jpeg'
          break
        case '25504446':
          type = 'application/pdf'
          break
      }

      return type
    },
    fileToBase64(file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        this.zipFiles64.push(e.target.result)
      }.bind(this)

      reader.readAsDataURL(file)
    },
    fileToURL(file) {
      return URL.createObjectURL(file)
    }
  },
  mounted() {},
  watch: {
    zipPending(newVal) {
      if (newVal === 0) {
        this.$refs.uploadInput.value = ''
        this.$emit('unziped', this.zipFiles)
        this.zipFiles = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-zip {
  &__upload {
    // background: $ocean-primary;
    color: $white;
    position: relative;
    height: 55px;
    width: 100%;
    margin-top: 5px;
    svg {
      width: 40px;
      height: 40px;
      fill: $white;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
