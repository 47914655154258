<template lang="pug">
.c-form-sensitive
  .o-input__container
    label.o-label.o-label--before(:for='id', :id='`${id}-label`' v-if='model.labelText') {{ model.labelText }}
    .o-input__wrap
      input.o-input(
        :aria-labelledby='ariaLabeledBy',
        :autocomplete='model.autocomplete || "do-not-autofill"',
        :class='[{ error: $v.rawValue.$error }]',
        :disabled='model.readOnly',
        :id='id',
        :name='model.inputName || id'
        @blur='onBlur'
        @focus='onFocus'
        @keyup='handleKeyUp'
        placeholder=' '
        type='text'
        v-debounce.valuechange='250'
        v-mask='protectedMask'
        v-model='value'
      )
      label.o-label.o-label--placeholder(:class='{ "o-label--required": model.isMandatory }', :id='`${id}-placeholder`' v-if='model.placeholderText') {{ model.placeholderText }}
    label.o-label.o-label--error(:id='`${id}-error`' v-if='model.validation && model.validation.errorMessage && $v.rawValue.$error') {{ model.validation.errorMessage }}
    label.o-label.o-label--helper(:id='`${id}-helper`' v-else-if='model.helperText') {{ model.helperText }}
</template>
<script>
import { userService } from '@/_services'
// import _ from 'lodash'
export default {
  name: 'c-form-sensitive',
  data() {
    return {
      id: this._uid,
      value: undefined,
      protected: true
    }
  },
  model: {
    prop: 'model.rawValue',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    current: {
      type: String
    }
  },
  validations() {
    return {
      rawValue: {
        required: function () {
          if (this.model.isMandatory) {
            return this.value !== null && this.value.length > 0
          } else {
            return true
          }
        },
        isValidRegex(value) {
          if (this.model.validation && this.model.validation.mask && value !== null) {
            const regexRule = new RegExp(this.model.validation.mask)

            return value === '' || regexRule.test(value)
          } else {
            return true
          }
        },
        isValidCustom(value) {
          if (this.model.validation && !!this.model.validation.customValidation && value !== null) {
            return this.model.validation.customValidation(value)
          } else {
            return true
          }
        }
      }
    }
  },
  computed: {
    protectedMask() {
      let mask = this.model.validation.displayRule
      if (this.protected && mask) {
        const maskArray = mask.split('')
        maskArray.forEach((letter, index) => {
          if (index >= maskArray.length - this.model.validation.hide) {
            maskArray[index] = '•'
          }
        })

        mask = maskArray.join('')
      }
      return mask
    },
    ariaLabeledBy() {
      const label = this.model.labelText ? `${this.id}-label ` : ''
      const placeholder = this.model.placeholderText ? `${this.id}-placeholder ` : ''
      const error = this.model.validation && this.model.validation.errorMessage && this.$v.rawValue.$error ? `${this.id}-error ` : ''
      const helper = this.model.helperText ? `${this.id}-helper` : ''

      return label + placeholder + error + helper
    },
    rawValue() {
      return this.protected ? this.model.value && this.model.value.replace('-', '') : this.value && this.value.replace('-', '')
    },
    modelValue() {
      return this.model.value
    }
  },
  methods: {
    onFocus() {
      this.protected = false
      this.value = this.modelValue
      this.$emit('focus')
    },
    onBlur() {
      this.value = this.rawValue
      this.protected = true
      this.$emit('blur')
    },
    async checkEmail() {
      try {
        const response = await userService.validateUser({ email: this.value })
        return !response.data
      } catch (error) {
        // console.log(error)
      }
    },
    handleKeyUp(event) {
      if (!event.isTrusted) return
      if (event.key === 'Enter') {
        this.$emit('submit', this.rawValue)
      }
    },
    handleValueChange() {
      if (!this.protected) {
        this.$v.rawValue.$touch()
        this.$emit('change', this.rawValue)
        this.$emit('validate', !this.$v.$invalid)
      }
    },
    setAfterModelChange() {
      if (this.value !== this.model.value) {
        this.value = this.model.value
        if (this.value !== null && this.value.length > 0) {
          this.$v.rawValue.$touch()
        }
        this.$emit('validate', !this.$v.$invalid)
      }
    }
  },
  mounted() {
    this.setAfterModelChange()
  },
  watch: {
    modelValue() {
      this.setAfterModelChange()
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleValueChange()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-form-sensitive {
  &__toggle {
    position: absolute;
    color: $grey-2;
    @include setStyles('16', $s-typography-sizes);
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms linear;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 30px);
  }
}
</style>
