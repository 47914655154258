<template lang="pug">
.c-filter-section(:class='{ "c-filter-section--dropdown": dropdown }' v-click-outside='searchIfDropdown')
  button.c-filter-section__top.o-grid.o-grid--wrap-nowrap.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-space.o-grid--gutter-tiny(
    :class='{ "c-filter-section__top--has-tags": tags.length > 0 }'
    @click='handleOpenCose'
  )
    .o-grid__group
      .o-grid__item.o-grid__item--4.o-grid__item--desktop-10
        .c-filter-section__heading.o-bodytext.o-bodytext--size-medium.o-bodytext--700(
          :class='{ "o-bodytext--inria ": !dropdown }'
          v-if='model && model.headingText'
        ) {{ model.headingText }}
      .c-filter-section__tags.o-grid__item.o-grid__item--grow-0.o-grid__item--7(v-if='!dropdown && !isOpen')
        .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-end.o-grid--gutter-micro
          .o-grid__group
            template(v-for='(tag, index) in tags')
              .o-grid__item.o-grid__item--grow-0(:key='index' v-if='index <= 1')
                .c-filter-section__tag {{ nameWithComma(index) }}
            .o-grid__item.o-grid__item--grow-0(v-if='tags.length > 2')
              .c-filter-section__tag +{{ tags.length - 2 }}
      .c-filter-section__toggle.o-grid__item.o-grid__item--grow-0
        svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
          use(:xlink:href='`/app/img/spritemap.svg#sprite-icon-${iconClass}`' v-if='!dropdown' x='0' y='0')
          use(v-else x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-bottom' y='0')
  .c-filter-section__content(v-if='isOpen')
    slot
    .c-filter-section__actions(v-if='dropdown')
      .o-grid.o-grid--direction-row.o-grid--align-items-center.o-grid--justify-content-space.o-grid--gutter-normal
        .o-grid__group
          .o-grid__item.o-grid__item--grow-0
            .c-filter-section__remove(@click='removeClick') {{ Dictionary.R.Remove }}
          .o-grid__item.o-grid__item--grow-0
            .o-button.o-button--small(@click='searchIfDropdown') {{ showPropertiesText }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'c-filter-section',
  data() {
    return {
      id: this._uid,
      isOpen: false
    }
  },
  props: {
    model: {
      type: Object
    },
    dropdown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('search', ['filters', 'modalShowing', 'facets', 'facetsResultsCount', 'facilityLocations', 'mapShowing']),
    ...mapState('propertyManagement', {
      propertyTypes: 'types'
    }),
    ...mapState('residenceManagement', {
      residenceTypes: 'types'
    }),
    ...mapGetters('search', ['activeSearchAgent', 'preparedFiltersSearch', 'propertyFacilities', 'residenceFacilities', 'membershipOrganizationsInfo']),
    tags() {
      let tags = []
      if (this.model.filters) {
        this.model.filters.forEach(filter => {
          const selectedFilter = this.filters[filter]
          if (Array.isArray(selectedFilter) && filter !== 'Floor') {
            const translatedTags = selectedFilter.map(filterId => this.translateTag(filter, filterId))
            tags = tags.concat(translatedTags)
          } else if (
            selectedFilter > 0 ||
            (typeof selectedFilter === 'string' && selectedFilter.length > 0) ||
            (filter === 'Floor' && JSON.stringify(this.filters[filter]) !== '[-3,30]')
          ) {
            const translatedTag = this.translateTag(filter, selectedFilter)
            tags.push(translatedTag)
          }
        })
      }

      return tags
    },
    iconClass() {
      return this.isOpen ? 'collapse' : 'expand'
    },
    showPropertiesText() {
      return this.Dictionary.Search.ShowXProperties.replace('{0}', this.facetsResultsCount)
    },
    showDropdownHeading() {
      return this.isOpen || !(this.dropdown && this.tags.length > 0)
    }
  },
  methods: {
    translateTag(filter, filterValue) {
      let translation = ''
      // console.log(filter, filterValue)
      switch (filter) {
        case 'PropertyFacilities':
          translation =
            (this.propertyFacilities.find(facility => facility.id === filterValue) &&
              this.propertyFacilities.find(facility => facility.id === filterValue).name) ||
            filterValue
          break
        case 'Facilities':
          translation =
            (this.residenceFacilities.find(facility => facility.id === filterValue) &&
              this.residenceFacilities.find(facility => facility.id === filterValue).name) ||
            filterValue
          break
        case 'PropertyOrganizationId':
          translation = this.membershipOrganizationsInfo.find(mo => mo.id === filterValue).name || filterValue
          break
        case 'AvailableFrom':
          translation = filterValue === 1 ? this.Dictionary.TakeOverDate.now : filterValue === 2 ? this.Dictionary.TakeOverDate.oneToThreeMonths : filterValue
          break
        case 'RentalPeriod':
          translation =
            filterValue === 'TwoToTwelveMonth'
              ? this.Dictionary.RentalPeriods.TwoToTwelveMonth
              : filterValue === 'OneToTwoYears'
              ? this.Dictionary.RentalPeriods.OneToTwoYears
              : filterValue === 'TwoOrMoreYears'
              ? this.Dictionary.RentalPeriods.TwoOrMoreYears
              : filterValue === 'Unlimited'
              ? this.Dictionary.RentalPeriods.Unlimited
              : filterValue
          break
        case 'PropertyType':
          translation =
            (this.propertyTypes.find(type => type.id === filterValue) && this.propertyTypes.find(type => type.id === filterValue).name) || filterValue
          break
        case 'Types':
          translation =
            (this.residenceTypes.find(type => type.id === filterValue) && this.residenceTypes.find(type => type.id === filterValue).name) || filterValue
          break
        case 'Floor':
          translation = (filterValue && `${this.Dictionary.F.Floor} ${filterValue[0]}/${filterValue[1]}`) || filterValue
          break
        case 'PropertyYear':
          translation = filterValue
          break
      }
      return translation
    },
    nameWithComma(index) {
      if (index !== this.tags.length - 1) {
        return `${this.tags[index]},`
      } else {
        return this.tags[index]
      }
    },
    removeClick() {
      this.model.filters.forEach(filter => {
        this.$store.dispatch('search/resetFilterToDefault', filter)
      })
      this.searchIfDropdown()
    },
    handleOpenCose() {
      if (this.isOpen) {
        this.searchIfDropdown()
      } else {
        this.isOpen = true
      }
    },
    searchIfDropdown() {
      if (this.dropdown && this.isOpen) {
        if (this.mapShowing) {
          this.$store.dispatch('search/getAllResults')
        } else {
          this.$store.dispatch('search/getResults')
        }
      }
      this.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-filter-section {
  $root: &;

  position: relative;

  &__top {
    display: block;
    width: 100%;
    appearance: none;
    border: none;
    text-align: left;
    background-color: transparent;
    font-family: 'Open Sans';
    outline-offset: 0;
  }

  &--dropdown {
    #{$root} {
      &__heading {
        font-weight: 600;
      }
      &__top {
        color: $white;
        border: 1px solid $white;
        padding: 6px 8px 7px;
        white-space: nowrap;
        display: block;
        cursor: pointer;

        &--has-tags {
          color: $crab-primary;
          border-color: $crab-primary;
          #{$root}__toggle {
            svg {
              fill: $crab-primary;
            }
          }
        }
      }

      &__toggle {
        svg {
          fill: $white;
        }
      }

      &__content {
        width: 375px;
        border: 1px solid $crab-darker;
        background-color: $white;
        padding: 20px 25px;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        z-index: z('elements');
      }

      &__tag {
        @include setStyles('15', $s-typography-sizes);
        color: $crab-primary;
        font-weight: 700;
      }
    }
  }
  &__heading {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__toggle {
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      fill: $ocean-primary;
      display: block;
    }
  }
  &__tag {
    @include setStyles('13', $s-typography-sizes);
    color: $grey-2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__content {
    padding: 15px 0 0;
  }

  &__actions {
    margin-top: 20px;
  }

  &__remove {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
