<template lang="pug">
.c-article-component.c-component-theme.u-theme-color--ocean
  .c-faq-accordion.o-wrap.o-wrap--max-width-xxlarge.o-wrap--padding-side-default
    .c-faq-accordion__icon.o-wrap.o-wrap--center-content-x(v-if='model.componentIcon != "no-icon"')
      svg.o-svg-icon.o-component-icon.c-component-theme__icon.c-worth-to-know__icon(viewbox='0 0 64 64' type='image/svg+xml')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-info'  y='0')
    h4.c-faq-accordion__title.o-heading.o-heading--size-2.o-wrap.o-wrap--center-content-x(v-if='model.heading') {{ model.heading }}
    .c-faq-accordion__intro.o-richtext.o-richtext--small-heading.o-wrap.o-wrap--padding-side-default.o-wrap--center-content-x(v-if='model.text') {{ mdoel.text }}
    .c-faq-accordion__content-panes
      .c-content-pane(v-for='faqItem in model.faqItems')
        input.c-content-pane-input(type='checkbox' :id='faqItem.id')
        label.c-content-pane-label.o-heading.o-heading--size-4(:for='faqItem.id') {{faqItem.itemTitle}}
        .c-content-pane__content.o-richtext.o-richtext--small-headings {{ faqItem.text }}
    .c-faq-accordion__link.o-wrap.o-wrap--center-content-x.o-link.o-component-link.c-component-theme__link(v-if='model.linkText') {{ model.linkText }}
</template>

<script>


export default {
  name: 'c-worth-to-know',
  props: {
    model: Object
  },
}

</script>

<style lang="scss" scoped>
.c-worth-to-know {

  &__icon {
    height: 2.125rem;
  }
}
</style>

