import { companiesManagementService } from '@/_services'

const initialState = {
  companies: [],
  selected: undefined,
  page: 0,
  pageSize: 10,
  totalResults: 0,
  deleteTargetId: undefined
}

const initialSelected = {
  id: undefined
}

export const companiesManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    intialDataReady: (state, _, rootState) => {
      return rootState.search.organizations.length > 0
    }
  },
  actions: {
    async setSelected({ commit }, id) {
      if (id) {
        try {
          const response = await companiesManagementService.getCompany(id)
          if (response && response.status === 200) {
            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    createNew({ commit }) {
      commit('createNew')
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createCompany({ commit }, { data, closeSelection }) {
      try {
        const response = await companiesManagementService.createCompany(data)
        if (response && response.status === 200) {
          if (closeSelection) {
            const xhttp = new XMLHttpRequest()
            xhttp.onreadystatechange = function () {
              if (this.readyState === 4 && this.status === 200) {
                window.location.reload()
              }
            }
            xhttp.open('GET', '/api/signout', true)
            xhttp.send()
          } else {
            commit('updateData', { selected: { ...data } })
            commit('updateData', {
              selected: {
                id: response.data
              }
            })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async addMedia({ commit, state }, { media }) {
      try {
        const formData = new FormData()
        formData.append('file', media[0])

        const response = await companiesManagementService.setCompanyImage({ id: state.selected.id, formData })
        if (response && response.status === 200) {
          const selected = state.selected
          selected.logo = response.data.path
          commit('updateData', selected)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async removeMedia({ commit, state }) {
      try {
        const response = await companiesManagementService.deleteCompanyImage(state.selected.id)
        if (response && response.status === 204) {
          commit('removeMedia')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async patchCompany({ commit, state }, { data, closeSelection }) {
      try {
        const response = await companiesManagementService.patchCompany({ data, id: state.selected.id })
        if (response && response.status === 204) {
          if (closeSelection) {
            commit('removeSelected')
          } else {
            data.id = state.selected.id
            data.logo = state.selected.logo
            commit('updateData', { selected: data })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async searchManagedCompanies({ commit }, payload) {
      try {
        payload.pageSize = payload.pageSize || 10000
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = 0
        const response = await companiesManagementService.searchManagedCompanies(payload)
        if (response && response.status === 200) {
          commit('updateData', {
            companies: response.data.results,
            page: response.data.page,
            pageSize: response.data.pageSize,
            totalResults: response.data.totalResults
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteCompany({ commit }, payload) {
      try {
        const response = await companiesManagementService.deleteCompany(payload)
        if (response && response.status === 204) {
          commit('deleteEntry', payload)
          commit('toggleDeleteConfirmation', false)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleDeleteConfirmation({ commit }, payload) {
      commit('toggleDeleteConfirmation', payload)
    }
  },
  mutations: {
    createNew(state) {
      state.selected = Object.assign({}, initialSelected)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    deleteEntry(state, payload) {
      state.companies.splice(state.companies.indexOf(state.companies.find(company => company.id === payload)), 1)
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            state[key] = key === 'selected' ? JSON.parse(JSON.stringify(payload[key])) : payload[key]
          })
      }
    },
    toggleDeleteConfirmation(state, payload) {
      if (payload) {
        state.deleteTargetId = payload
      } else {
        state.deleteTargetId = undefined
      }
    },
    addMedia(state, payload) {
      if (Array.isArray(payload.items)) {
        payload.items.forEach(item => {
          state.selected.media[payload.type].push(item)
        })
      } else {
        state.selected.media[payload.type].push(payload.items)
      }
    },
    removeMedia(state) {
      state.selected.logo = null
    }
  }
}
