<template lang="pug">
button.o-button(:disabled='disabledCta || loading' @click='ctaClicked')
  c-loading-animation(:inline='true', :showText='false' offsetTop='0' v-if='ctaLoading || loading')
  span(v-else) {{ ctaText }}
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'c-cta-button',

  data() {
    return {
      disable: false
    }
  },
  props: {
    ctaLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    ctaDisabled: {
      default: false
    },
    ctaText: {
      default: false
    }
  },
  computed: {
    ...mapState('loadingIndicator', ['loading', 'loadingEndpoints']),
    disabledCta() {
      return this.disable || this.ctaDisabled
    }
  },
  methods: {
    ctaClicked() {
      const that = this
      this.disable = true
      this.$emit('click')
      setTimeout(function () {
        that.disable = false
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped></style>
