import { offersManagementService, residenceManagementService, advertManagementService, messagesService } from '@/_services'
import _ from 'lodash'
const initialState = {
  offers: [],
  selected: undefined,
  selectedMutable: undefined,
  serverParams: {
    columnFilters: {},
    sort: [
      {
        field: '',
        type: ''
      }
    ],
    page: 1,
    perPage: 10
  },
  totalResults: 0,
  confirmationTarget: undefined,
  showExample: false
}

export const offersManagement = {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    intialDataReady: () => {
      return true
    },
    offerDataChanged: state => {
      return !_.isEqual(state.selected, state.selectedMutable)
    }
  },
  actions: {
    async setSelected({ commit, rootState }, id) {
      if (id) {
        try {
          const response = await offersManagementService.getOffer(id)
          if (response && response.status === 200) {
            if (response.data.state === 'Released' && rootState.user.roles.includes('Property Administrator')) {
              const canCreateAdvertFromResidenceResponse = await advertManagementService.canCreateAdvertFromResidence(response.data.residenceId)
              if (canCreateAdvertFromResidenceResponse && canCreateAdvertFromResidenceResponse.status === 200) {
                response.data.canCreateAdvertFromResidence = canCreateAdvertFromResidenceResponse.data
              }
            }

            const responseStatisticsThreadsUnread = await messagesService.postThreadsCount({
              relatedEntities: [id],
              status: 'Unread'
            })
            response.data.unreadThreads = responseStatisticsThreadsUnread.data || 0

            commit('updateData', { selected: response.data })
          }
        } catch (error) {
          this.dispatch('toast/remove', { url: `/data/offers/${id}` })
        }
      }
    },
    async showExample({ commit }, payload) {
      if (payload) {
        this.dispatch('offersManagement/setSelected', payload)
      }
      commit('updateData', { showExample: true })
    },
    async hideExample({ commit }, payload) {
      if (payload) {
        commit('removeSelected')
      }
      commit('updateData', { showExample: false })
    },
    async createNew({ commit, rootState }, payload) {
      try {
        const residenceResponse = await residenceManagementService.getResidence(payload)

        if (residenceResponse && residenceResponse.status === 200) {
          const data = {
            residenceId: payload,
            internalNote: '',
            recipients: [],
            recipientsState: [],
            offerText: '',
            showingText: '',
            correctionRentDescription: null,
            correctionRentAmount: null,
            deadline: null,
            state: 'Draft',
            companyId: residenceResponse.data.company,
            residenceNumber: residenceResponse.data.residenceNumber,
            availableFrom: residenceResponse.data.availableFrom,
            number: 0,
            responsibleUserId: rootState.user.userId
          }

          const offersCompanyResponse = await offersManagementService.getOffersCompany({
            companyId: residenceResponse.data.company,
            residenceId: residenceResponse.data.id
          })

          if (rootState.user.roles.includes('Property Administrator')) {
            const canCreateAdvertFromResidenceResponse = await advertManagementService.canCreateAdvertFromResidence(residenceResponse.data.id)
            if (canCreateAdvertFromResidenceResponse && canCreateAdvertFromResidenceResponse.status === 200) {
              data.canCreateAdvertFromResidence = canCreateAdvertFromResidenceResponse.data
            }
          }

          if (offersCompanyResponse && offersCompanyResponse.status === 200) {
            data.offerText = offersCompanyResponse.data.offerText || ''
            data.showingText = offersCompanyResponse.data.showingText || ''
          }

          commit('updateData', { selected: data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    removeSelected({ commit }) {
      commit('removeSelected')
    },
    async createOffer({ commit, state }) {
      try {
        const response = await offersManagementService.createOffer(state.selectedMutable)
        if (response && response.status === 200) {
          commit('updateData', { selected: { ...response.data } })
          commit('addNewOffer', response.data)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async changeOffer({ commit, state }) {
      try {
        let data

        if (['Awarded', 'AwardedExternally', 'Released', 'RetiredFromAwarded'].includes(state.selectedMutable.state)) {
          data = {
            internalNote: state.selectedMutable.internalNote,
            financialAndContractualNotes: state.selectedMutable.financialAndContractualNotes
          }
        } else if (state.selectedMutable.state === 'Finished') {
          data = {
            responsibleUserId: state.selectedMutable.responsibleUserId,
            internalNote: state.selectedMutable.internalNote,
            financialAndContractualNotes: state.selectedMutable.financialAndContractualNotes
          }
        } else {
          data = {
            internalNote: state.selectedMutable.internalNote,
            recipients: state.selectedMutable.recipients,
            offerText: state.selectedMutable.offerText,
            showingText: state.selectedMutable.showingText,
            deadline: state.selectedMutable.deadline,
            correctionRentDescription: state.selectedMutable.correctionRentDescription,
            correctionRentAmount: state.selectedMutable.correctionRentAmount,
            responsibleUserId: state.selectedMutable.responsibleUserId,
            financialAndContractualNotes: state.selectedMutable.financialAndContractualNotes
          }
        }

        const response = await offersManagementService.patchOffer({ data, id: state.selected.id })
        if (response && response.status === 204) {
          const responseOffer = await offersManagementService.getOffer(state.selected.id)
          if (responseOffer && responseOffer.status === 200) {
            const updateData = responseOffer.data

            // const updateData = JSON.parse(JSON.stringify(state.selectedMutable))
            // if (updateData.state === 'Published') {
            //   updateData.state = 'Changed'
            // }

            if (updateData.state === 'Released') {
              const canCreateAdvertFromResidenceResponse = await advertManagementService.canCreateAdvertFromResidence(state.selectedMutable.residenceId)
              if (canCreateAdvertFromResidenceResponse && canCreateAdvertFromResidenceResponse.status === 200) {
                updateData.canCreateAdvertFromResidence = canCreateAdvertFromResidenceResponse.data
              }
            }

            commit('updateData', { selected: updateData })
            commit('toggleConfirmation')
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async searchOffers({ commit, state }, payload) {
      try {
        payload.pageSize = state.serverParams.perPage
        payload.pageSize = payload.pageSize === -1 ? 2147483647 : payload.pageSize
        payload.page = state.serverParams.page - 1
        if (state.serverParams.sort[0].type && state.serverParams.sort[0].type !== 'none') {
          payload.orderDirection = state.serverParams.sort[0].type
          payload.orderBy = state.serverParams.sort[0].field
        }
        const response = await offersManagementService.searchOffers(payload)
        if (response && response.status === 200) {
          const updateData = { offers: response.data.results, totalResults: response.data.totalResults }
          if (response.data.totalResults === 0) {
            updateData.serverParams = Object.assign(state.serverParams, { page: 1, perPage: 10 })
          }
          commit('updateData', updateData)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteOffer({ commit, state }) {
      try {
        const response = await offersManagementService.deleteOffer(state.confirmationTarget.id)
        if (response && response.status === 204) {
          commit('removeSelected')
          commit('deleteEntry', state.confirmationTarget.id)
          commit('toggleConfirmation')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async publishOffer({ commit, state }) {
      try {
        const data = {
          internalNote: state.selectedMutable.internalNote,
          recipients: state.selectedMutable.recipients,
          offerText: state.selectedMutable.offerText,
          showingText: state.selectedMutable.showingText
        }
        const patchResponse = await offersManagementService.patchOffer({ data, id: state.selected.id })

        if (patchResponse && patchResponse.status === 204) {
          const publishResponse = await offersManagementService.publishOffer(state.selected.id)
          if (publishResponse && publishResponse.status === 200) {
            commit('updateData', { selected: publishResponse.data })
          }
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async finishOffer({ commit, state }) {
      try {
        const response = await offersManagementService.finishOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async retireOffer({ commit, state }) {
      try {
        const response = await offersManagementService.retireAwardedOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async awardOffer({ commit, state }, payload) {
      try {
        const response = await offersManagementService.awardOffer({ id: state.selected.id, userId: payload })
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async awardExternallyOffer({ commit, state }) {
      try {
        const response = await offersManagementService.awardExternallyOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async cancelAwardedOffer({ commit, state }) {
      try {
        const response = await offersManagementService.cancelAwardedOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async releaseOffer({ commit, state }) {
      try {
        const response = await offersManagementService.releaseOffer(state.selected.id)
        if (response && response.status === 200) {
          const canCreateAdvertFromResidenceResponse = await advertManagementService.canCreateAdvertFromResidence(state.selectedMutable.residenceId)
          if (canCreateAdvertFromResidenceResponse && canCreateAdvertFromResidenceResponse.status === 200) {
            response.data.canCreateAdvertFromResidence = canCreateAdvertFromResidenceResponse.data
          }
          commit('updateData', { selected: response.data })
          commit('toggleConfirmation')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async extendOffer({ commit, state }) {
      try {
        const date = state.selectedMutable.deadline
        const response = await offersManagementService.extendOffer({ data: date, id: state.selected.id })
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
        commit('toggleConfirmation')
      } catch (error) {
        // console.log(error)
      }
    },
    async acceptOffer({ commit, state }) {
      try {
        const response = await offersManagementService.acceptOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
          commit('toggleConfirmation')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async declineOffer({ commit, state }) {
      try {
        const response = await offersManagementService.declineOffer(state.selected.id)
        if (response && response.status === 200) {
          commit('updateData', { selected: response.data })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    toggleConfirmation({ commit }, payload) {
      commit('toggleConfirmation', payload)
    }
  },
  mutations: {
    addNewOffer(state, payload) {
      state.offers.unshift(payload)
    },
    removeSelected(state) {
      state.selected = undefined
    },
    deleteEntry(state, payload) {
      state.offers.splice(state.offers.indexOf(state.offers.find(Offer => Offer.id === payload)), 1)
      state.selected = undefined
    },
    updateData(state, payload) {
      if (payload) {
        Object.keys(state)
          .filter(key => payload[key] !== undefined)
          .forEach(key => {
            if (key === 'selected') {
              state[key] = JSON.parse(JSON.stringify(payload[key]))
              state.selectedMutable = JSON.parse(JSON.stringify(payload[key]))
            } else if (key === 'selectedMutable') {
              state[key] = JSON.parse(JSON.stringify(payload[key]))
            } else {
              state[key] = payload[key]
            }
          })
      }
    },
    updateParams(state, payload) {
      state.serverParams = Object.assign(state.serverParams, payload)
    },
    updateMutableData(state, payload) {
      if (payload) {
        Object.keys(payload)
          .filter(key => Object.keys(state.selectedMutable).includes(key))
          .forEach(key => (state.selectedMutable[key] = payload[key]))
      }
    },
    toggleConfirmation(state, payload) {
      if (payload) {
        state.confirmationTarget = payload
      } else {
        state.confirmationTarget = undefined
      }
    },
    addMedia(state, payload) {
      if (Array.isArray(payload.items)) {
        payload.items.forEach(item => {
          state.selected.media[payload.type].push(item)
        })
      } else {
        state.selected.media[payload.type].push(payload.items)
      }
    },
    removeMedia(state) {
      state.selected.logo = null
    },
    changeSelectedOfferUserState(state, payload) {
      const targetUser = state.selected.recipientsState.find(r => r.userId === payload.userId)
      if (targetUser) targetUser.state = payload.state
    }
  }
}
