<template lang="pug">
.c-offers-management
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-om-overview')
        component(:is='activeComponent', :is-management='true', :model='model')
  template(v-else)
    c-om-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      component.o-transition__overlay(:is='showExample ? "c-offer-preview" : "c-om-offer"', :model='model' v-if='selected && intialDataReady')
  c-modal.c-modal--confirmation(:cornerClose='true', :darkTheme='true' @close='handleConfirmationClose' v-if='confirmationTarget')
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationText.headline }}
    template(slot='body')
      .o-bodytext {{ confirmationText.description }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-cta.o-button--inverted(:cta-text='Dictionary.Y.YesPlease' @click='handleConfirmationYes')
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import OffersManagementOverview from './om-overview'
import OffersManagementOffer from './om-offer'
import OfferPreview from '@/components/offer-preview'
export default {
  name: 'c-offers-management',
  props: {
    model: Object
  },
  data() {
    return {
      id: this._uid
    }
  },
  components: {
    'c-om-overview': OffersManagementOverview,
    'c-om-offer': OffersManagementOffer,
    'c-offer-preview': OfferPreview
  },
  computed: {
    ...mapState('offersManagement', ['selected', 'confirmationTarget', 'offers', 'showExample']),
    ...mapGetters('offersManagement', ['intialDataReady']),
    confirmationTargetData() {
      return this.offers.find(target => target.id === this.confirmationTarget.id)
    },
    confirmationUser() {
      return (this.confirmationTarget && this.confirmationTarget.user) || false
    },
    activeComponent() {
      let activeComponent = 'c-om-overview'
      if (this.selected && this.intialDataReady) {
        activeComponent = this.showExample ? 'c-offer-preview' : 'c-om-offer'
      }
      return activeComponent
    },
    confirmationText() {
      let headline = ''
      let description = ''
      let confirmationUserName = ''
      if (this.confirmationUser) {
        confirmationUserName =
          this.confirmationUser.firstName || this.confirmationUser.firstName
            ? `${this.confirmationUser.firstName} ${this.confirmationUser.lastName}`
            : this.confirmationUser.email
      }
      if (this.confirmationTargetData) {
        headline = this.model.offerConfirmations[`${this.confirmationTarget.action}ConfirmationHeadline`]
          ? this.model.offerConfirmations[`${this.confirmationTarget.action}ConfirmationHeadline`]
              .replace('{0}', this.confirmationTargetData.residenceAddress)
              .replace('{userName}', confirmationUserName)
          : ''
        description = this.model.offerConfirmations[`${this.confirmationTarget.action}ConfirmationDescription`]
          ? this.model.offerConfirmations[`${this.confirmationTarget.action}ConfirmationDescription`]
              .replace('{0}', this.confirmationTargetData.residenceAddress)
              .replace('{userName}', confirmationUserName)
          : ''
      }
      return { headline, description }
    }
  },
  methods: {
    handleConfirmationYes() {
      if (this.confirmationTarget.action === 'assignNextOffer') {
        this.$store.dispatch('offersManagement/awardOffer', this.confirmationTarget.payload)
      } else {
        this.$store.dispatch(`offersManagement/${this.confirmationTarget.action}`, this.confirmationTarget.payload)
      }
    },
    handleConfirmationClose() {
      this.$store.dispatch('offersManagement/toggleConfirmation')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-offers-management {
}
</style>
