<template lang="pug">
form.c-login(@submit='authenticateUser')
  c-notification-inline.c-login__error.o-bodytext.o-bodytext--size-small(:canClose='false' size='small' type='error' v-if='loginError') {{ errorText }}
  .o-input__container
    .o-input__wrap
      input#loginEmailInput.o-input(autocomplete='username' disabled name='loginEmailInput' placeholder=' ' required type='email' v-model='email')
      label.o-label.o-label--placeholder(for='loginEmailInput') {{ Dictionary.E.Email }}
  .o-input__container
    .o-input__wrap
      input#loginPasswordInputxt.o-input(
        autocomplete='current-password'
        name='loginPasswordInputxt'
        placeholder=' '
        ref='loginPasswordInputxt'
        required
        type='password'
        v-model='password'
        v-on:keyup='validateForm'
      )
      label.o-label.o-label--placeholder(for='loginPasswordInputxt') {{ Dictionary.P.Password }}
  .o-grid.o-grid--gutter-tiny.c-login__actions-container
    .o-grid__group
      .o-grid__item.o-grid__item--grow-0
        .o-input__wrap
          input#loginRememberMe.o-input(name='checkbox' placeholder=' ' type='checkbox' v-model='saveUser')
          label.o-label(for='loginRememberMe') {{ Dictionary.LoginFlow.RememberMe }}
      .o-grid__item.o-grid__item--grow-0.o-grid__item--align-right
        button.c-login__link.o-link.o-link--500.o-link--opensans.o-link--crab(@click='$emit("showResetPassword")' type='button') {{ Dictionary.LoginFlow.ForgotPassword }}
  button.o-button.o-button--inverted.o-button--full-width(:disabled='!this.validForm' type='submit') {{ Dictionary.L.Login }}
  .c-login__verification(v-if='verificationFlow && verificationFlow.pensionFund === "PFA"')
    .c-login__heading.o-heading--size-3.login-flow__heading {{ verificationFlow.type === 'member' ? model.pfaAuthentication.pfaCreateProfileHeadline : model.pfaAuthentication.pfaCreateFamilyProfileHeadline }}
    .c-login__text.o-bodytext {{ verificationFlow.type === 'member' ? model.pfaAuthentication.pfaCreateProfileDescription : model.pfaAuthentication.pfaCreateFamilyProfileDescription }}
    button.o-button.o-button--inverted.o-button--full-width(@click='$emit("showCreateUser")' type='button') {{ verificationFlow.type === 'member' ? Dictionary.LoginFlow.CreateUser : Dictionary.LoginFlow.CreateFamilyUser }}
  button.c-login__link.o-link.o-link--500.o-link--opensans.o-link--crab(@click='$emit("resetFlow")' type='button') {{ Dictionary.LoginFlow.LoginWithDifferentUser }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'c-login',
  props: {
    initialEmail: String,
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: this.initialEmail,
      password: '',
      validForm: false,
      saveUser: false,
      loginError: undefined
    }
  },
  computed: {
    ...mapGetters('user', ['verificationFlow']),
    rememberMe() {
      return !!localStorage.userEmail
    },
    errorText() {
      return this.loginError && this.loginError.response.status === 403 ? this.Dictionary.LoginFlow.InvalidCredentials : this.Dictionary.LoginFlow.GenericError
    }
  },
  methods: {
    async authenticateUser(e) {
      e.preventDefault()
      if (this.password !== '') {
        if (this.saveUser) {
          // Save user to locale storage. Should we move this to after user has been authenticated?
          localStorage.userEmail = this.email
        } else {
          // Remove if a user was alredy stored
          localStorage.removeItem('userEmail')
        }

        this.loginError = false

        this.$store
          .dispatch('user/authenticateUser', { email: this.email, password: this.password })
          .then(() => (this.loginError = false))
          .catch(e => (this.loginError = e))
      }
    },
    validateForm: function () {
      this.validForm = this.password !== ''
      return this.validForm
    }
  },
  created() {
    if (this.rememberMe) {
      this.saveUser = true
    }
  },
  mounted() {
    this.$refs.loginPasswordInputxt.focus()
  }
}
</script>

<style lang="scss" scoped>
.c-login {
  &__heading {
    font-family: 'Inria Serif', serif;
    margin-bottom: toRem(20px);
    text-align: center;
  }
  &__text {
    @include setStyles('18', $s-typography-sizes);
    margin-bottom: toRem(43px);
    text-align: center;
  }
  &__link {
    @include setStyles('14', $s-typography-sizes);
    display: block;
    margin: 0 auto;
  }
  &__actions-container {
    margin-bottom: toRem(20px);
  }
  &__error {
    margin-bottom: toRem(15px);
  }
  &__verification {
    margin-top: 10px;
  }
  .o-input__container,
  .o-button {
    margin-bottom: toRem(10px);
  }
}
</style>
