import apiClient from '@/_helpers/api-client'
export const facilitiesManagementService = {
  getFacility,
  createFacility,
  updateFacility
}
async function getFacility(facilityId) {
  return await apiClient({
    url: `/data/facilities/${facilityId}`,
    method: 'GET'
  })
}

async function createFacility(data) {
  return await apiClient({
    url: '/data/facilities',
    method: 'POST',
    data
  })
}

async function updateFacility(facilityId, data) {
  return await apiClient({
    url: `/data/facilities/${facilityId}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'text/plain'
    },
    data
  })
}