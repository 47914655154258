<template lang="pug">
.c-advert-screening
  template(v-if='!isMobile')
    transition(mode='out-in' name='o-transition__fade')
      keep-alive(include='c-ads-overview')
        component(:is='selected ? "c-ad-advert" : "c-ads-overview"', :model='model')
  template(v-else)
    c-ads-overview(:model='model')
    transition(name='o-transition__slide-fade-right')
      c-ad-advert.o-transition__overlay(:model='model' v-if='selected')
  c-modal.c-modal--confirmation(
    :cornerClose='true',
    :darkTheme='true'
    @close='handleConfirmationClose'
    v-if='confirmationTarget && confirmationTarget.action === "approve"'
  )
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2(v-html='approveConfirmationHeadlineText')
    template(slot='body')
      .o-bodytext {{ approveConfirmationDescriptionText }}
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(@click='handleApproveConfirmationApprove') {{ Dictionary.A.Approve }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
  c-modal.c-modal--confirmation(
    :cornerClose='true',
    :darkTheme='true'
    @close='handleConfirmationClose'
    v-if='confirmationTarget && confirmationTarget.action === "change"'
  )
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2 {{ confirmationText.headline }}
    template(slot='body')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            .o-bodytext {{ confirmationText.description }}
          .o-grid__item.o-grid__item--12(v-if='selected && selected.type === "MembershipAdvertBasedOnResidence" && confirmationTarget.action === "renew"')
            c-fc-date(:model='applyBeforeDateData' @validate='validate($event, "applyBeforeDate")' v-model='applyBeforeDate.value')
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(:disabled='!formIsValid' @click='handleConfirmationYes') {{ Dictionary.Y.YesPlease }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
  c-modal.c-modal--confirmation(
    :cornerClose='true',
    :darkTheme='true'
    @close='handleConfirmationClose'
    v-if='confirmationTarget && confirmationTarget.action === "reject"'
  )
    template(slot='header')
      svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 64 64')
        use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-circle-warning' y='0')
      .o-heading.o-heading--size-2(v-html='rejectConfirmationHeadlineText')
    template(slot='body')
      .o-grid.o-grid--gutter-horizontal-small.o-grid--gutter-vertical-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            c-fc-textbox(:model='rejectComment' @validate='validate($event, "rejectComment")' v-model='rejectComment.value')
    template(slot='footer')
      .o-grid.o-grid--justify-content-center.o-grid--gutter-small
        .o-grid__group
          .o-grid__item.o-grid__item--12
            button.o-button.o-button--inverted(:disabled='!validation.rejectComment' @click='handleRejectConfirmationReject') {{ Dictionary.R.Reject }}
          .o-grid__item.o-grid__item--12
            span.o-link(@click='handleConfirmationClose') {{ Dictionary.C.Cancel }}
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import AdvertsScreeningOverview from './ads-overview'
import AdvertManagementAdvert from '../advert-management/ad-advert'
import FormControllTextBox from '@/components/form/controlls/form-textbox'
export default {
  name: 'c-advert-screening',
  props: {
    model: Object,
    pageSize: {
      default: 4
    }
  },
  data() {
    return {
      id: this._uid,

      key: Math.floor(Math.random() * 9999),
      validation: {},
      applyBeforeDate: {
        helperText: this.residenceTranslations && this.residenceTranslations.advertApplyBeforeHelperText,
        value: null,
        placeholderText: this.Dictionary.A.ApplyBefore,
        isMandatory: false,
        readOnly: false,
        validation: {
          minDate: moment.utc().format('YYYY-MM-DD')
        },
        key: 0
      },
      rejectComment: {
        helperText: this.model.rejectConfirmationCommentHelperText,
        value: null,
        placeholderText: this.Dictionary.C.Comment,
        isMandatory: true,
        readOnly: false,
        validation: {
          errorMessage: '',
          displayRule: '',
          minLength: undefined,
          maxLength: undefined
        }
      }
    }
  },
  components: {
    'c-ads-overview': AdvertsScreeningOverview,
    'c-ad-advert': AdvertManagementAdvert,
    'c-fc-textbox': FormControllTextBox
  },
  computed: {
    ...mapState('advertManagement', ['selected', 'confirmationTarget', 'adverts']),
    applyBeforeDateData() {
      const applyBeforeDateData = Object.assign({}, this.applyBeforeDate)
      if (this.selected.type === 'MembershipAdvertBasedOnResidence') {
        applyBeforeDateData.isMandatory = true
      } else {
        applyBeforeDateData.readOnly = true
        applyBeforeDateData.isMandatory = false
      }
      return applyBeforeDateData
    },
    actionTargetData() {
      return this.adverts.find(target => target.id === this.confirmationTarget.id)
    },
    approveConfirmationHeadlineText() {
      return this.actionTargetData && this.model.advertConfirmations.approveConfirmationHeadline
        ? this.model.advertConfirmations.approveConfirmationHeadline.replace('{0}', this.actionTargetData.address + '</br>')
        : ''
    },
    approveConfirmationDescriptionText() {
      return this.actionTargetData && this.model.advertConfirmations.approveConfirmationDescription
        ? this.model.advertConfirmations.approveConfirmationDescription.replace('{0}', this.actionTargetData.address)
        : ''
    },
    rejectConfirmationHeadlineText() {
      return this.actionTargetData && this.model.advertConfirmations.rejectConfirmationHeadline
        ? this.model.advertConfirmations.rejectConfirmationHeadline.replace('{0}', this.actionTargetData.address + '</br>')
        : ''
    },
    formIsValid() {
      const valid = this.validation && Object.values(this.validation).every(key => key === true)
      return valid
    },
    confirmationText() {
      let headline = ''
      let description = ''
      let confirmationUserName = ''
      if (this.confirmationUser) {
        confirmationUserName =
          this.confirmationUser.firstName || this.confirmationUser.firstName
            ? `${this.confirmationUser.firstName} ${this.confirmationUser.lastName}`
            : this.confirmationUser.email
      }
      headline = this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationHeadline`]
        ? this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationHeadline`]
            .replace(
              '{0}',
              this.confirmationTargetData && this.confirmationTargetData.address
                ? this.confirmationTargetData.address
                : this.combineAddress(
                    this.selected.street,
                    this.selected.number,
                    this.selected.letter,
                    this.selected.floor,
                    this.selected.door,
                    this.selected.postalCode,
                    this.selected.city
                  )
            )
            .replace('{userName}', confirmationUserName)
        : ''
      description = this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationDescription`]
        ? this.model.advertConfirmations[`${this.confirmationTarget.action}AdvertConfirmationDescription`]
            .replace(
              '{0}',
              this.confirmationTargetData && this.confirmationTargetData.address
                ? this.confirmationTargetData.address
                : this.combineAddress(
                    this.selected.street,
                    this.selected.number,
                    this.selected.letter,
                    this.selected.floor,
                    this.selected.door,
                    this.selected.postalCode,
                    this.selected.city
                  )
            )
            .replace('{userName}', confirmationUserName)
        : ''

      return { headline, description }
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    handleApproveConfirmationApprove() {
      this.$store.dispatch('advertManagement/approveAdvert')
    },
    handleRejectConfirmationReject() {
      this.$store.dispatch('advertManagement/rejectAdvert', { reason: this.rejectComment.value })
    },
    handleConfirmationYes() {
      if (this.confirmationTarget.action === 'change') {
        this.$store.dispatch('advertManagement/patchAdvert', this.confirmationTarget.data)
      }
    },
    handleConfirmationClose() {
      this.$store.dispatch('advertManagement/toggleConfirmation')
    }
  },
  watch: {
    selected() {
      this.key = Math.floor(Math.random() * 9999999999)
    },
    confirmationTarget() {
      this.rejectComment.value = null
    }
  }
}
</script>
<style lang="scss" scoped>
.c-advert-screening {
}
</style>
