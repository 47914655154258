<template lang="pug">
.c-pm-overview
  slot(name='pm-overview-heading')
  .o-grid
    .o-grid__group
      .o-grid__item.o-grid__item--desktop-8
        slot(name='pm-overview-description')
        template(v-for='(organization, key) in associated')
          c-notification-inline.c-pm-overview__notification(
            :can-close='false'
            justify='start'
            size='small'
            type='warning'
            v-if='organization.hasUnansweredMandatoryQuestions'
          )
            .o-bodytext.o-bodytext--medium.o-bodytext--600 {{ Dictionary.MembershipOrganizations.BeAware }}
            .o-bodytext.o-bodytext--small {{ notificationText(organization.name) }}
        .c-pm-overview__list(v-if='associated.length > 0')
          h4.o-heading.o-heading--size-4 {{ Dictionary.MembershipOrganizations.MemberOf }}
          ul.c-pm-overview__list-wrapper
            li.c-pm-overview__list-item(:key='`organization-${key}`' v-for='(organization, key) in associated')
              button.c-pm-overview__list-item-button(:aria-label='organization.name' @click='valueChanged(organization.id)')
                .c-pm-overview__list-item-wrap
                  .c-pm-overview__list-item-text.o-bodytext.o-bodytext--size-medium.o-bodytext--inria.o-bodytext--700 {{ organization.name }}
                  .c-pm-overview__list-item-tag.o-tag(v-if='organization.isVerificationSetup') {{ isOrganizationVerified(organization.verified) }}
                  .c-pm-overview__list-item-tag.o-tag(v-if='!organization.isVerificationSetup && organization.verified') {{ Dictionary.MembershipOrganizations.Validated }}
                svg.o-svg-icon(type='image/svg+xml' viewbox='0 0 42 34')
                  use(x='0' xlink:href='/app/img/spritemap.svg#sprite-icon-chevron-right' y='0')
        .c-pm-overview__new.o-input__container(v-if='notAssociated.length > 0')
          label.o-label.o-label--before.o-bodytext.o-bodytext--size-medium.o-bodytext--600 {{ Dictionary.MembershipOrganizations.AddMembership }}
          .o-input__wrap
            select.o-input(:disabled='loading', :id='id' @change='valueChanged($event.target.value)' name='add-membership-organization' placeholder=' ')
              option(disabled hidden selected value='default') {{ Dictionary.S.SelectFromList }}
              template(v-for='option in notAssociated')
                option(:value='option.id' v-if='!option.requireExternalAssociation') {{ option.name }}
            label.o-label.o-label--placeholder(:for='id' v-if='Dictionary.P.PensionFund') {{ Dictionary.P.PensionFund }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getQueryParameters, deleteQueryParameter } from '@/_helpers/query-parameters'
export default {
  name: 'c-pm-overview',
  data() {
    return {
      id: this._uid
    }
  },
  props: {},
  computed: {
    ...mapState('loadingIndicator', ['loading']),
    ...mapGetters('membershipOrganizations', ['associated', 'notAssociated'])
  },
  methods: {
    notificationText(organization) {
      return (
        this.Dictionary.MembershipOrganizations.UnansweredMandatoryQuestions &&
        this.Dictionary.MembershipOrganizations.UnansweredMandatoryQuestions.replace('{0}', organization)
      )
    },
    isOrganizationVerified(verified){
      const verifiedText = verified ? this.Dictionary.MembershipOrganizations.Validated : this.Dictionary.MembershipOrganizations.NotValidated
      return verifiedText
    },
    valueChanged(id) {
      this.$store.dispatch('membershipOrganizations/setSelected', { id: id.toUpperCase(), isApplication: false })
    }
  },
  created() {
    const moId = getQueryParameters().get('membershipOrganizationId')
    if (moId) {
      this.valueChanged(moId)
      deleteQueryParameter('membershipOrganizationId')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-pm-overview {
  &__list {
    margin: 30px 0 10px;

    &-wrapper {
      margin-top: 10px;
    }

    &-item {
      border-bottom: toRem(1px) solid $grey-4;

      &:first-child {
        border-top: toRem(1px) solid $grey-4;
      }

      &-button {
        cursor: pointer;
        padding: toRem(13px 0);
        background: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &-wrap {
        max-width: 90%;
        display: flex;
        align-items: center;
      }
      &-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 0 1 auto;
        overflow: hidden;
      }
      &-tag {
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 20px;
        flex: 0 1 auto;
        overflow: hidden;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__new {
    margin: 30px 0 10px;
  }

  &__notification {
    margin-top: 20px;
    &-icon {
      width: 22px;
      height: 22px;
    }
  }
}
</style>
